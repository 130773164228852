import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Grid } from 'semantic-ui-react';
import styles from './style.module.scss';
import { CenteredContentLayout } from '../../components/layouts/CenteredContentLayout';
import { DeployWizardStepProps } from '../../interfaces';
import { AppState } from '@app/reducers';
import { PriceBar, PriceBarGroup } from '../../components/PriceBar';
import bandwidthIcon from '@icons/bandwidth.icon.svg';
import diskIcon from '@icons/disc.icon.svg';
import { ResourceItem } from './ResourceItem';
import { ApiSize } from '@app/data-modules/sizes';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { resourcesStepMeta, RESOURCES_STEP_ID } from './meta';
import { BillingInfoSuscriptionStatus } from '@app/modules/account/state/interfaces';
import { AppButton } from '@app/shared/buttons/AppButton';
import { changeBillingInformationWizardModalApi } from '@app/modals';

export const ResourcesStep: React.FC<DeployWizardStepProps> = ({
	step,
	stepValues,
	getNextStep,
	getPrevStep,
	prev,
	next,
}) => {
	const { initialSize, size } = stepValues[RESOURCES_STEP_ID] || {};
	const [activeResourceIndex, setActiveResourceIndex] = useState<number | null>(null);
	const [currentResource, setCurrentResource] = useState<ApiSize | null>(null);
	const { resources, subscriptionStatus } = useSelector((state: AppState) => ({
		resources: state.sizes.sizes,
		subscriptionStatus: state.account.billingInfo.subscriptionStatus,
	}));

	const handleSelectPlan = (planIndex: number) => () => {
		if (activeResourceIndex !== planIndex) {
			setActiveResourceIndex(planIndex);
		} else {
			setActiveResourceIndex(null);
		}
	};

	useEffect(() => {
		let activeResourceIndex;
		if (initialSize !== undefined) {
			activeResourceIndex = initialSize;
		} else {
			activeResourceIndex = resources.findIndex(i => i.code === size);
		}
		setActiveResourceIndex(activeResourceIndex);
	}, [initialSize, resources, size]);

	useEffect(() => {
		if (activeResourceIndex !== null) {
			setCurrentResource(resources[activeResourceIndex]);
		} else {
			setCurrentResource(null);
		}
	}, [activeResourceIndex, resources]);

	const prevStep = getPrevStep();
	const nextStep = getNextStep();

	return (
		<CenteredContentLayout
			title={resourcesStepMeta.title}
			subtitle={resourcesStepMeta.subtitle}
			controls={
				<div className={classnames(styles.controls, { [styles.controls_singleBtn]: !prevStep })}>
					{prevStep && <StepNavigationButton type="prev" onClick={prev} stepName={prevStep.meta.btnText} />}
					{nextStep && (
						<StepNavigationButton
							disabled={!currentResource?.code}
							stepName={nextStep.meta.btnText}
							formId={step.name}
							onClick={() => next(step.name, { size: currentResource?.code })}
							type="next"
						/>
					)}
				</div>
			}
		>
			<div className={styles.wrapper}>
				<div className={styles.resourcesRow}>
					{subscriptionStatus === BillingInfoSuscriptionStatus.TRIAL && (
						<div className={styles.notAvailableBanner}>
							<span>Unavailable to trial users.</span>
							<AppButton
								theme={AppButton.themes.regular}
								type={'button'}
								onClick={changeBillingInformationWizardModalApi.openModal}
								className={styles.addBillingButton}
							>
								{'Add a payment method'}
							</AppButton>
						</div>
					)}
					{resources.map(({ isAllowedOnTrial, ram, disk, cpu, monthlyPrice, price, name }, index) => (
						<ResourceItem
							key={index}
							notAvailable={
								subscriptionStatus === BillingInfoSuscriptionStatus.TRIAL && !isAllowedOnTrial
							}
							memory={Number(ram)}
							disk_capacity={disk}
							cpu={Number(cpu)}
							price={{ monthly: monthlyPrice, hourly: price }}
							name={name}
							isPlanSelected={activeResourceIndex === index}
							handleSelectPlan={handleSelectPlan(index)}
						/>
					))}
				</div>
				<Grid.Row className={styles.gridRow}>
					<Grid.Column className={styles.resourcesStep__priceBarGroup}>
						<PriceBarGroup>
							<PriceBar
								className={styles.resourcesStep__priceBarGroup_bar}
								iconSrc={bandwidthIcon}
								title={<span className={styles.titleText}>Bandwidth</span>}
								price={
									<span className={styles.priceText}>{`$${
										resources[0].bandwidthPrice / 100
									} per GB`}</span>
								}
							/>
							<PriceBar
								className={styles.resourcesStep__priceBarGroup_bar}
								iconSrc={diskIcon}
								title={<span className={styles.titleText}>Permanent Disk</span>}
								price={<span className={styles.priceText}>Coming soon</span>}
							/>
						</PriceBarGroup>
					</Grid.Column>
				</Grid.Row>
			</div>
		</CenteredContentLayout>
	);
};
