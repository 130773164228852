import * as React from 'react';

export interface AnchorContextInterface {
	registerSection: (instance: HTMLElement, anchorId: string) => void;
	removeSection: (id: string) => void;
	activeAnchorId: string | null;
	transiteTo: (anchorId: string) => void;
}

export interface AnchorContextProps {
	context: AnchorContextInterface;
}

export const AnchorContext = React.createContext<AnchorContextInterface>({} as AnchorContextInterface);
