import React from 'react';
import styles from './styles.module.scss';
import searchIcon from '@icons/icon.search.svg';
import searchWhiteIcon from '@icons/icon.search.white.svg';
import searchClear from '@icons/icon.search.clear.svg';
import classnames from 'classnames';
import { useOutsideElementClick } from '../../hooks/useOutsideElementClick';
import { useFocusOut } from '../../hooks/useFocusOut';
import { SvgIcon } from '../SvgIcon';

interface SearchInputProps {
	value: any;
	searchName: string;
	placeholder: string;
	className?: string;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleClear?: (_: any) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
	value,
	searchName,
	placeholder,
	handleChange,
	className = '',
	handleClear,
}) => {
	const [isFocused, setIsFocused] = React.useState(false);
	const wrapRef = React.useRef(null);
	useOutsideElementClick(wrapRef, () => setIsFocused(false));
	useFocusOut(wrapRef, () => setIsFocused(false));

	return (
		<div
			ref={wrapRef}
			className={classnames(
				styles.searchWrap,
				{ [styles.searchWrap_focused]: isFocused },
				{ [className]: !!className }
			)}
			onClick={() => setIsFocused(true)}
		>
			<div className={styles.search__icon}>
				<SvgIcon iconSrc={isFocused ? searchWhiteIcon : searchIcon} />
			</div>

			<input
				placeholder={placeholder}
				type="text"
				onChange={handleChange}
				onBlur={() => {}}
				value={value}
				name={searchName}
			/>

			{isFocused ? (
				<SvgIcon iconSrc={searchClear} className={styles.searchWrap__clear} onClick={handleClear} />
			) : null}
		</div>
	);
};
