import * as React from 'react';
import styles from './style.module.scss';
import { DeployWizardHeader } from '../../forms/components/DeployWizardHeader';
import classnames from 'classnames';

interface Props {
	image?: string;
	title: string;
	customSubtitle?: JSX.Element | React.ReactNode;
	subtitle?: string;
	children: React.ReactNode;
	controls?: JSX.Element;
	imgBlockClassName?: string;
	imgClassName?: string;
	contentClassName?: string;
	additionalHeaderComponent?: JSX.Element | React.ReactNode;
}

export const StepLayoutWithLogo = (props: Props) => {
	return (
		<div className={classnames(styles.stepLayoutWithLogo, 'firstContnentElem')}>
			<div className={styles.stepLayoutWithLogo__contentWrapper}>
				<div className={classnames(styles.stepLayoutWithLogo_imageBlock, props.imgBlockClassName)}>
					<img className={props.imgClassName} src={props.image} alt="" />
				</div>
				<div className={classnames(styles.stepLayoutWithLogo__contentBlock, props.contentClassName)}>
					<div className={styles.stepLayoutWithLogo__contentHeader}>
						<DeployWizardHeader
							title={props.title}
							additionalHeaderComponent={props.additionalHeaderComponent}
							subtitle={props.subtitle}
							customSubtitle={props.customSubtitle}
						/>
					</div>
					<div className={styles.stepLayoutWithLogo__content}>{props.children}</div>
				</div>
			</div>
			{props.controls && (
				<div className={styles.stepLayoutWithLogo__controlsRow}>
					<div>{props.controls}</div>
				</div>
			)}
		</div>
	);
};
