import { call, put, take } from "redux-saga/effects";
import { getClusterRegionsApi } from "../api";
import { getClusterRegions, getClusterRegionsSuccess, getClusterRegionsFail } from "../actions";

export function* watchGetClusterRegions() {
    while (true) {
        const action = yield take("*");
        if (getClusterRegions.is(action)) {
            yield call(getClusterRegionsWorker);
        }
    }
}

function* getClusterRegionsWorker() {
    try {
        const result = yield call(getClusterRegionsApi);
        yield put(getClusterRegionsSuccess({ result }));
    } catch (e) {
        yield put(getClusterRegionsFail({ error: { message: e.message } }));
    }
}
