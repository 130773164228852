import api from '@app/apis/api';
import { ApiActivityLog } from './state';

export const getClusterActivityLogRequest = async (clusterName: string): Promise<ApiActivityLog[]> =>
	await api.get(`/api/cluster/${clusterName}/activity`);

export const rollbackClusterRequest = async (clusterName: string, versionId: number): Promise<void> =>
	await api.post(`/api/cluster/${clusterName}/rollback`, {}, {
		params: {
			versionId,
		},
	});
