import { put, call } from 'redux-saga/effects';
import {
	getLatestClusterMetrics,
	getLatestClusterMetricsSuccess,
	getLatestClusterMetricsFail,
} from '../actions';
import { getClusterNodesMetricRequest } from '../api';
import { ApiNodeMetricName, MetricValue } from '../entity';

export function* getLatestClusterMetricsSaga(action: ReturnType<typeof getLatestClusterMetrics>) {
	const { clusterName } = action;
	try {
		const metric: Record<ApiNodeMetricName, MetricValue> = yield call(getClusterNodesMetricRequest, clusterName);
		yield put(getLatestClusterMetricsSuccess({ clusterName, metric }));
	} catch (e) {
		yield put(getLatestClusterMetricsFail({ clusterName, error: { message: e.message } }));
	}
}
