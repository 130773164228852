import { Action } from 'redux';
import { setSizesAction, getSizesAction } from './actions';
import { ApiSize } from '@app/data-modules/sizes';

const INITIAL_STATE = {
	sizes: [] as ApiSize[],
	isLoaded: false,
};

interface SizesState {
	sizes: ApiSize[];
	isLoaded: boolean;
}

export function sizesReducer(state = INITIAL_STATE, action: Action): SizesState {
	if (setSizesAction.is(action)) {
		return {
			...state,
			isLoaded: true,
			sizes: action.payload,
		};
	}

	if (getSizesAction.is(action)) {
		return {
			...state,
			isLoaded: false,
		};
	}

	return state;
}
