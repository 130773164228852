import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import memo from 'memoize-one';
import styles from './style.module.scss';
import { AppCard, Head, Title, Section, SectionContainer } from '@app/shared/AppCard';
import { MapComponent } from '@app/shared/MapComponent';
import { ApiRegion } from '@app/data-modules/regions';
import { NodeLocationMapPoint } from './components/NodeLocationMapPoint';
import { ICluster } from '../../cluster';
import { ApiNode } from '@app/data-modules/cluster-nodes';
import { LdsSpinner } from '@app/shared/ldsSpinner';
import { ClusterMetric } from '@app/data-modules/cluster-metrics';
import { LabelWithTooltip } from '@app/shared/text/LabelWithTooltip';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import iconInfo from '@icons/icon.info.svg';

interface InputProps {
	regions: ApiRegion[];
	cluster: ICluster;
	nodes: ApiNode[];
	renderTooltipFooter?: boolean;
	isFetching?: boolean;
	latestMetric?: ClusterMetric['latest']['metric'];
}

const mapNodeToRegion = memo((node: ApiNode, regions: ApiRegion[]) => {
	return regions.find(r => r?.code === node?.region) || ({} as ApiRegion);
});

export const NodeLocationsCard: React.FC<InputProps> = ({
	regions,
	nodes,
	cluster,
	isFetching,
	renderTooltipFooter,
	latestMetric,
}) => {
	const { lastUpdate, isLoaded, metric } = latestMetric || {};
	const [nodesGroups, setNodesGroups] = useState<ApiNode[][]>([]);
	const [metricsGroups, setMetricsGroups] = useState<any>([]);

	useEffect(() => {
		if (nodes.length && regions.length) {
			const grouped = regions.map(region => nodes.filter(node => region.code === node.region));
			setNodesGroups(grouped);
		}
	}, [nodes, regions]);

	useEffect(() => {
		if (metric) {
			const metricsGroups = nodesGroups.map(group => group.map(node => metric[node.hostname]));
			setMetricsGroups(metricsGroups);
		}
	}, [metric, nodesGroups]);

	return (
		<AppCard className={styles.nodeLocationsCard}>
			<Head className={styles.nodeLocationsCard__cardHead}>
				<Title text="Deployed regions" />
				{isFetching ? (
					<LdsSpinner />
				) : (
					<div className={styles.nodeLocationsCard__cardHead__availabilityBadge}>
						<i
							className={classnames(styles.nodeLocationsCard__cardHead__availabilityBadge__status, {
								[styles.nodeLocationsCard__cardHead__availabilityBadge__status_green]:
									nodesGroups.length > 1,
							})}
						/>
						<LabelWithTooltip
							className={styles.nodeLocationsCard__cardHead__availabilityBadge__label}
							iconComponent={
								<IconWithTooltip
									labelClassname={
										styles.nodeLocationsCard__cardHead__availabilityBadge__label__tooltip
									}
									imageSrc={iconInfo}
									tooltipText={
										'To take advantage of our smart failover you need to have at least 2 nodes globally. And to withstand region downtime you should select more than 2 regions.'
									}
								/>
							}
							labelText="Global High-Availability"
						/>
					</div>
				)}
			</Head>
			<SectionContainer>
				<Section>
					<div className={styles.nodeLocationsCard__mapWrapper}>
						<MapComponent
							points={nodesGroups.map<any>((nodesGroup: ApiNode[], nodeIdx: number) => {
								const region = mapNodeToRegion(nodesGroup[0], regions);
								return {
									lat: region.latitude,
									lng: region.longitude,
									component: NodeLocationMapPoint,
									componentProps: {
										countryCode: region.country,
										nodes: nodesGroup,
										cluster: cluster,
										renderFooter: renderTooltipFooter,
										latestMetric: {
											lastUpdate,
											isLoaded,
											metric: metricsGroups[nodeIdx],
										},
									},
								};
							})}
							zoom={true}
						/>
					</div>
				</Section>
			</SectionContainer>
		</AppCard>
	);
};
