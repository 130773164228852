import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { Label } from 'semantic-ui-react';
import { LdsSpinner } from '@app/shared/ldsSpinner';
import { capitalize } from '@app/utils/capitalizeFirstLetter';
import { DotElement } from '../dotElement';
import { CLUSTER_STASUSES } from '@app/data-modules/healthStatuses';

export interface Props {
	status: CLUSTER_STASUSES;
}

export const ClusterStatusDot: React.FC<Props> = ({ status }) => {
	const defClassName = createClassName(status, styles.clusterStatusDot__definition);
	const [isShowStatus, setIsShowStatus] = useState(false);

	return (
		<div className={styles.clusterStatusDot}>
			{status === CLUSTER_STASUSES.rebuilding ? (
				<LdsSpinner
					onMouseOver={() => setIsShowStatus(true)}
					onMouseLeave={() => setIsShowStatus(false)}
					className={styles.clusterStatusDot__ldsSpinner}
				/>
			) : (
				<div className={styles.clusterStatusDot__iconContainer}>
					<DotElement
						status={status}
						className={styles.clusterStatusDot__dotEl}
						onMouseLeave={() => setIsShowStatus(false)}
						onMouseOver={() => setIsShowStatus(true)}
					/>
				</div>
			)}
			{isShowStatus && (
				<Label basic pointing="below" className={defClassName}>
					{status === CLUSTER_STASUSES.rebuilding ? (
						<LdsSpinner inverted={true} />
					) : (
						<DotElement status={status} className={styles.clusterStatusDot__definitionDot} />
					)}
					{capitalize(status)}
				</Label>
			)}
		</div>
	);
};

const createClassName = (status: CLUSTER_STASUSES, baseClass: string) => {
	switch (status) {
		case CLUSTER_STASUSES.rebuilding:
			return classnames(styles.clusterStatusDot__rebuilding, baseClass);
		default:
			return classnames(baseClass);
	}
};
