import { Action } from 'redux';
import {
	setFetchingActivityLogAction,
	setClusterActivityLogAction,
	getClusterActivityLogAction,
	rollbackClusterDoneAction,
	rollbackClusterAction,
	setClusterActivityLogPage,
} from './actions';
import { ActivityLogState } from './state';

const INITIAL_STATE: ActivityLogState = {};

export function activityLogReducer(state = INITIAL_STATE, action: Action) {
	if (setFetchingActivityLogAction.is(action)) {
		return {
			...state,
			[action.clusterName]: {
				...state[action.clusterName],
				isFetching: action.state,
			},
		};
	}

	if (setClusterActivityLogPage.is(action)) {
		return {
			...state,
			[action.clusterName]: {
				...state[action.clusterName],
				page: action.page,
			},
		};
	}

	if (setClusterActivityLogAction.is(action)) {
		return {
			...state,
			[action.clusterName]: {
				...state[action.clusterName],
				log: action.log,
				page: 1,
				isLoaded: true,
			},
		};
	}

	if (getClusterActivityLogAction.is(action)) {
		return {
			...state,
			[action.clusterName]: {
				...state[action.clusterName],
				isFetching: true,
				isLoaded: false,
			},
		};
	}

	if (rollbackClusterAction.is(action)) {
		return {
			...state,
			[action.clusterName]: {
				...state[action.clusterName],
				rollbackRequestProcessing: true,
			},
		};
	}

	if (rollbackClusterDoneAction.is(action)) {
		return {
			...state,
			[action.clusterName]: {
				...state[action.clusterName],
				rollbackRequestProcessing: false,
			},
		};
	}

	return state;
}
