import React from 'react';
import styles from './styles.module.scss';
import { UserAvatar } from '@app/shared/userAvatar';

interface ClusterSettingsUserCellProps {
	userName: string;
	userEmail: string;
	avaColor: string;
}

export const ClusterSettingsUserCell: React.FC<ClusterSettingsUserCellProps> = ({ userName, userEmail, avaColor }) => {
	return (
		<div className={styles.clusterSettings__content_wrapped__body__permissions__table__userCell}>
			<UserAvatar
				email={userEmail}
				ownAvatarColor={avaColor}
				className={styles.clusterSettings__content_wrapped__body__permissions__table__userCell__avatar}
			/>
			<div>
				<div>{userName}</div>
				<div>{userEmail}</div>
			</div>
		</div>
	);
};
