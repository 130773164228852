import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { AppState } from '@app/reducers';
import { signinUser, RESET_PASS_URL, clearSigninError } from '../../state';
import { navigateTo } from '@app/utils/navigate-to';
import { ErrorMessage, IErrorMessage } from '@app/shared/errorMessage';
import { withFormik, FormikProps, Form } from 'formik';
import { compose } from 'recompose';
import ValidationSchema from './validation-schema';
import { ACCOUNT_SIGNUP_URL } from '../../../account';
import { LabeledInputRegular } from '@app/shared/labeledInputRegular';
import { PageHeader } from '@app/shared/pageHeader';
import styles from './styles.module.scss';
import classnames from 'classnames';
import logoSvg from '@images/logo.appfleet.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { BtnProgress } from '@app/shared/btnProgress';

export interface SigninValues {
	email: string;
	password: string;
}
interface SigninComponentProps extends SigninValues {
	error: IErrorMessage;
	signinUser: (values: SigninValues) => void;
	clearSigninError: (_: any) => void;
}

const SigninComponent: React.FC<SigninComponentProps & FormikProps<SigninValues>> = props => {
	const {
		values,
		error,
		handleChange,
		handleSubmit,
		handleBlur,
		errors,
		touched,
		isSubmitting,
		setSubmitting,
		clearSigninError,
		setFieldValue,
	} = props;
	const { email, password } = values;

	useEffect(() => {
		setSubmitting(false);
	}, [error, setSubmitting]);

	useEffect(() => {
		return () => clearSigninError({});
	}, [clearSigninError]);

	return (
		<div className={styles.login}>
			<PageHeader
				btnComponent={
					<Button className="btn-one btn-create-account" onClick={navigateTo(ACCOUNT_SIGNUP_URL.urlTemplate)}>
						Create an account
					</Button>
				}
				className={styles.login__header}
			/>

			<div className={classnames(styles.loginContent, 'firstContnentElem')}>
				<div className={styles.loginContent__logo}>
					<div />
				</div>

				<div className={styles.loginContent__form}>
					<div className={styles.logoAppfleet}>
						<SvgIcon iconSrc={logoSvg} />
					</div>

					<div className={styles.loginContent__form__header}>Dashboard Login</div>
					<div className={styles.loginContent__form__subheader}>
						Fill in your credentials below to access appfleet
					</div>

					<Form onSubmit={handleSubmit}>
						<div>
							<LabeledInputRegular
								inputType="text"
								handleChange={handleChange}
								handleBlur={handleBlur}
								inputValue={email}
								inputName="email"
								inputPlaceholder="Email"
								errorMsg={errors.email}
								isTouched={touched.email}
								labelText="Your email"
								setFieldValue={setFieldValue}
							/>
						</div>

						<div>
							<LabeledInputRegular
								inputType="password"
								handleChange={handleChange}
								handleBlur={handleBlur}
								inputValue={password}
								inputName="password"
								inputPlaceholder="Password"
								errorMsg={errors.password}
								isTouched={touched.password}
								labelText="Your password"
								setFieldValue={setFieldValue}
							/>
						</div>

						<div className={styles.loginContent__ctrls}>
							<div className={styles.loginContent__ctrls__btns}>
								<Button
									className={classnames(
										'btn-three',
										'btn-create-account',
										'btn-create-account_mobile'
									)}
									onClick={navigateTo(ACCOUNT_SIGNUP_URL.urlTemplate)}
									type="button"
								>
									Create an account
								</Button>

								<BtnProgress text={'Log in'} isSubmitting={isSubmitting} isDisabled={false} />

								<span
									className={styles.loginContent__ctrls__btns__reset}
									onClick={navigateTo(RESET_PASS_URL.urlTemplate)}
								>
									Reset my password
								</span>
							</div>
							<ErrorMessage error={error} withinPortal={false} useDefaultMsg={false} />

							<span
								className={styles.loginContent__ctrls__btns__reset_mobile}
								onClick={navigateTo(RESET_PASS_URL.urlTemplate)}
							>
								Reset my password
							</span>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
};
export const Signin = compose<SigninComponentProps & FormikProps<SigninValues>, {}>(
	connect(
		(state: AppState) => ({
			error: state.signin.error,
		}),
		{ signinUser, clearSigninError }
	),
	withFormik({
		mapPropsToValues: () => ({
			email: '',
			password: '',
		}),
		validationSchema: ValidationSchema,

		handleSubmit: (values, { setSubmitting, props }) => {
			setSubmitting(true);
			(props as SigninComponentProps).signinUser(values);
		},

		displayName: 'SigninUser',
	})
)(SigninComponent);
