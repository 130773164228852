import { call, take, put } from "redux-saga/effects";
import { deleteClusterNodeApi } from "../api";
import { deleteNodeFromCluster, deleteClusterNodeSuccess, deleteClusterNodeFail } from "../actions";

export function* watchDeleteClusterNode() {
    while (true) {
        const action = yield take("*");
        if (deleteNodeFromCluster.is(action)) {
            yield call(deleteClusterNodeWorker, { id: action.id, hostName: action.hostName });
        }
    }
}

function* deleteClusterNodeWorker(data: { id: number; hostName: string }) {
    try {
        const result = yield call(deleteClusterNodeApi, data);
        yield put(deleteClusterNodeSuccess({ TODO: result }));
    } catch (e) {
        yield put(deleteClusterNodeFail({ error: { message: e.message } }));
    }
}
