import { call, put } from 'redux-saga/effects';
import { getClusterNodesRequest } from '../api';
import { setClusterNodesErrorAction, setClusterNodesItemFetchingAction, setClusterNodesAction } from '../actions';

export function* getClusterNodesSaga(clusterName: string) {
	try {
		yield put(setClusterNodesItemFetchingAction({ clusterName, isFetching: true }));
		const request = yield call(getClusterNodesRequest, clusterName);
		yield put(setClusterNodesAction({ clusterName, nodes: request }));
	} catch (e) {
		const { status } = e;

		if (status) {
			let error = 'Failed to get cluster nodes.';

			if (status === 404) {
				error = `Can't retrieve nodes for cluster "${clusterName}"`;
			}

			yield put(setClusterNodesErrorAction({ clusterName, error }));
		}
	} finally {
		yield put(setClusterNodesItemFetchingAction({ clusterName, isFetching: false }));
	}
}
