import { put, call } from 'redux-saga/effects';
import { getClusterActivityLogAction, setFetchingActivityLogAction, setClusterActivityLogAction } from '../actions';
import { getClusterActivityLogRequest } from '../api';
import { ApiActivityLog } from '../state';

const sortLogs = (logs: ApiActivityLog[]) =>
	logs.sort((a, b) => {
		const timestampA = +new Date(a.createdAt);
		const timestampB = +new Date(b.createdAt);

		return timestampB - timestampA;
	});

export function* fetchLogsSaga(action: ReturnType<typeof getClusterActivityLogAction>) {
	try {
		yield put(setFetchingActivityLogAction({ clusterName: action.clusterName, state: true }));
		const request: ApiActivityLog[] = yield call(getClusterActivityLogRequest, action.clusterName);
		yield put(setClusterActivityLogAction({ clusterName: action.clusterName, log: sortLogs(request) }));
	} catch (e) {
		console.error('failed to fetch logs');
	} finally {
		yield put(setFetchingActivityLogAction({ clusterName: action.clusterName, state: false }));
	}
}
