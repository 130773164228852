import { call, put, take } from "redux-saga/effects";
import { getNodeSizesApi } from "../api";
import { getNodeSizes, getNodeSizesSuccess, getNodeSizesFail } from "../actions";

export function* watchGetNodeSizes() {
    while (true) {
        const action = yield take("*");
        if (getNodeSizes.is(action)) {
            yield call(getNodeSizesWorker);
        }
    }
}

function* getNodeSizesWorker() {
    try {
        const result = yield call(getNodeSizesApi);
        yield put(getNodeSizesSuccess({ result }));
    } catch (e) {
        yield put(getNodeSizesFail({ error: { message: e.message } }));
    }
}
