import React, { useRef, useState, useEffect } from 'react';
import styles from './styles.module.scss';
import globalStyles from '../../styles/global.module.scss';
import classnames from 'classnames';
import selectedIcon from '@icons/icon.checked.svg';
import arrowDown from '@icons/icon.select.arrow.svg';
import { SvgIcon } from '../SvgIcon';
import { useOutsideElementClick } from '../../hooks/useOutsideElementClick';

export interface SelectWithSearchOption {
	text: string | any;
	value: string;
	key?: number;
	customOpt?: JSX.Element;
}

interface SelectWithSearchProps {
	selectValue: string;
	selectName: string;
	handleChange: (fieldName: string, value: any) => void;
	options: SelectWithSearchOption[];
	selectLabel?: string;
	placeholder?: string;
	wrapClassname?: string;
	selectClassname?: string;
	labelClassname?: string;
	fakeInputClassname?: string;
	optionsClassname?: string;
	optionClassname?: string;
	showSelectedSign?: boolean;
	disabled?: boolean;
	disabledInput?: boolean;
	error?: boolean;
	onBlur?: (_: any) => void;
}

export const SelectWithSearch: React.FC<SelectWithSearchProps> = ({
	selectValue = '',
	selectName,
	selectLabel,
	handleChange,
	options,
	placeholder = '',
	wrapClassname = '',
	selectClassname = '',
	labelClassname = '',
	fakeInputClassname = '',
	optionsClassname = '',
	optionClassname = '',
	showSelectedSign = false,
	disabled = false,
	disabledInput = false,
	error = false,
	onBlur = () => {},
}) => {
	const scrollRef = useRef(null);
	const scrollContainerRef: any = useRef(null);
	const scrollBoxRef: any = useRef(null);
	const selectWrapprerRef = useRef(null);

	const [showOptions, setShowOptions] = useState(false);
	const [chosenOption, setChosenOption] = useState<number | null>(null);
	const [inputVal, setInputVal] = useState<string>('');

	useEffect(() => {
		// input value should be equal to the selectValue passed as prop from parent
		setInputVal(selectValue);
	}, [selectValue]);

	const handleOutsideClick = () => {
		// since more than one select can be on the page at the moment
		// we shouldn't allow to invoke select ouside clisk handler for the one that doesn't opened now
		if (showOptions === false) return;
		setShowOptions(false);
		if (chosenOption !== null) {
			const option = options.find(opt => opt.text.toLowerCase() === inputVal.toLowerCase());
			if (option) {
				setChosenOption(chosenOption);
				setInputVal(option.value);
				handleChange(selectName, option.value);
			}
		} else {
			const optionDefault = options.find(opt => opt.value.toLowerCase() === selectValue.toLowerCase());

			if (optionDefault) {
				setInputVal(optionDefault.text);
				handleChange(selectName, optionDefault.value);
			}
		}
	};

	useOutsideElementClick(selectWrapprerRef, handleOutsideClick);

	useEffect(() => {
		if (selectValue) {
			setChosenOption(options.findIndex(opt => opt.value.toLowerCase() === selectValue.toLowerCase()));
		}
	}, [options, selectValue]);

	useEffect(() => {
		return () => {
			setChosenOption(null);
		};
	}, []);

	useEffect(() => {
		if (showOptions) {
			detectOverlap();
		}
	}, [showOptions]);

	const scrollToRef = (ref: any) => scrollBoxRef && scrollBoxRef?.current?.scrollTo(0, ref.current.offsetTop);

	const detectOverlap = () => {
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		const optionsBox = scrollContainerRef?.current?.getBoundingClientRect() || null;
		const isOutside = optionsBox ? vh - optionsBox.height - optionsBox.top < 0 : null;

		if (isOutside === null) {
			return;
		}

		if (isOutside) {
			scrollContainerRef?.current.style.setProperty('top', `${-optionsBox.height - 14}px`, 'important');
		} else if (!isOutside) {
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		if (val.match(/[ЇїІіЁёА-Яа-я]/u)) {
			return;
		}
		setInputVal(val);

		const existedOptionIndex = options.findIndex(opt => opt.text.toLowerCase() === val.toLowerCase());

		if (existedOptionIndex >= 0) {
			setChosenOption(existedOptionIndex);
		} else {
			setChosenOption(null);
		}

		setTimeout(() => {
			scrollRef?.current && scrollToRef(scrollRef);
		}, 100);
	};

	const optionRefIndex = !chosenOption
		? options.findIndex(opt => opt.text.toLowerCase().startsWith(inputVal.toLowerCase()))
		: -1;

	return (
		<div
			className={classnames(globalStyles.noTextSelection, styles.selectWithSearch, {
				[wrapClassname]: !!wrapClassname,
				[styles.disabled]: disabled,
			})}
			ref={selectWrapprerRef}
		>
			{selectLabel ? (
				<div className={classnames(styles.selectWithSearch__label, { [labelClassname]: !!labelClassname })}>
					{selectLabel}
				</div>
			) : null}

			<div
				tabIndex={0}
				className={classnames(
					styles.selectWithSearch__select,
					{ [selectClassname]: !!selectClassname },
					{ [styles.selectWithSearch__select_active]: showOptions },
					{ [styles.selectWithSearch__select_error]: error }
				)}
				onClick={() => !disabled && setShowOptions(!showOptions)}
			>
				{disabledInput ? (
					<div
						className={classnames(
							styles.selectWithSearch__select__fakeInput,
							{
								[fakeInputClassname]: !!fakeInputClassname,
							},
							{
								[styles.selectWithSearch__select__fakeInput_disabled]: disabled,
							}
						)}
					>
						{chosenOption !== null && options[chosenOption]
							? options[chosenOption].text
							: inputVal
							? inputVal
							: placeholder}
					</div>
				) : (
					<input
						type="text"
						className={styles.selectWithSearch__select__input}
						placeholder={placeholder}
						value={chosenOption !== null && options[chosenOption] ? options[chosenOption].text : inputVal}
						onChange={handleInputChange}
						disabled={disabledInput}
						autoComplete={'new-password'}
						onBlur={onBlur}
						name={selectName}
					/>
				)}

				<SvgIcon iconSrc={arrowDown} />

				{showOptions ? (
					<div
						ref={scrollContainerRef}
						className={classnames(styles.selectWithSearch__select__options, optionsClassname)}
					>
						<div ref={scrollBoxRef}>
							{options.map((option, optionIdx) => (
								<div
									key={option.key || optionIdx}
									ref={chosenOption === optionIdx || optionRefIndex === optionIdx ? scrollRef : null}
									onClick={() => {
										handleChange(selectName, option.value);
										setInputVal(option.text);
										setShowOptions(!showOptions);
									}}
									className={classnames(
										styles.selectWithSearch__select__options__item,
										{
											[styles.selectWithSearch__optionSelectedWithoutSign]:
												chosenOption === optionIdx && !showSelectedSign,
										},
										{
											[styles.selectWithSearch__select__options__item_selected]:
												chosenOption === optionIdx,
										},
										{ [optionClassname]: !!optionClassname }
									)}
								>
									{option.customOpt || option.text}
									{showSelectedSign && chosenOption === optionIdx ? (
										<div className={styles.selectWithSearch__selectedIcon}>
											<SvgIcon iconSrc={selectedIcon} />
										</div>
									) : null}
								</div>
							))}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};
