import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';

interface PasswordProgressProps {
	password: string;
	errorMsg?: string;
	isTouched?: boolean;
	className?: string;
}

export const PasswordProgress: React.FC<PasswordProgressProps> = ({ password, errorMsg, isTouched, className }) => {
	let strength = 0;
	let progressBlocks = [];

	if (password.length > 0 || (errorMsg && isTouched)) {
		strength = 1;
	}
	if (password.length > 3 && password.match(/[a-zA-Z!@#$%^&*_()]/) && password.match(/^[^А-ЯЁІЇЄа-яёєії]+$/)) {
		strength = 2;
	}
	if (
		password.length > 5 &&
		password.match(/[a-zA-Z!@#$%^&*_()]/) &&
		password.match(/\d/) &&
		password.match(/^[^А-ЯЁІЇЄа-яёєії]+$/)
	) {
		strength = 3;
	}
	if (
		password.length > 7 &&
		password.match(/\d/) &&
		password.match(/[a-zA-Z]/) &&
		password.match(/^[^А-ЯЁІЇЄа-яёєії]+$/)
	) {
		strength = 4;
	}

	for (let i = 0; i < strength; i++) {
		progressBlocks.push(
			<div
				key={i}
				className={classnames(styles.psswrdProgressBlock, { [styles.psswrdProgressBlock_weak]: strength < 4 })}
			></div>
		);
	}

	return <div className={classnames(styles.psswrdProgress, className)}>{progressBlocks}</div>;
};
