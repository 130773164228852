import { put, take } from 'redux-saga/effects';
import { getRegionsAction } from '@app/data-modules/regions';
import { getSizesAction } from '@app/data-modules/sizes';
import { setSigninToken } from '@app/modules/user-sign-in';
import { getRegistries } from '@app/modules/registries';

export function* fetchWizardData() {
	while (true) {
		const action = yield take('*');
		if (setSigninToken.is(action)) {
			yield put(getRegionsAction({}));
			yield put(getSizesAction({}));
			yield put(getRegistries({}));
		}
	}
}
