import { call, take, put } from 'redux-saga/effects';
import { createUserApi } from '../api';
import { AccountValues } from '../../components';
import { createUser, setAccountError } from '../actions';
import { navigateTo } from 'src/utils/navigate-to';
import { SIGNIN_URL } from '@app/modules/user-sign-in';

export function* watchCreateUser() {
	while (true) {
		const action = yield take('*');
		if (createUser.is(action)) {
			yield call(createUserWorker, { values: action.values, onFinal: action.onFinal });
		}
	}
}

function* createUserWorker({ values, onFinal }: { values: AccountValues; onFinal: () => void }) {
	try {
		yield call(createUserApi, values);
		yield call(navigateTo(SIGNIN_URL.urlTemplate));
	} catch (error) {
		yield put(setAccountError({ error }));
	} finally {
		onFinal();
  }
}
