import { all } from 'redux-saga/effects';
import { watchGetTeamOnLocChange } from './getTeam';
import { watchInviteMembers } from './inviteMembers';
import { watchUpdateAccountMemberRole } from './updateAccountMemberRole';
import { watchGetAccountMembers } from './getAccountMembers';
import { watchRemoveAccountMember } from './removeAccountMember';
import { watchSaveAccountSeats } from './saveAccountSeats';
import { watchGetAccountSeats } from './getAccountSeats';

export function* teamSaga() {
	yield all([
		watchGetTeamOnLocChange(),
		watchInviteMembers(),
		watchUpdateAccountMemberRole(),
		watchGetAccountMembers(),
		watchRemoveAccountMember(),
		watchGetAccountSeats(),
		watchSaveAccountSeats(),
	]);
}
