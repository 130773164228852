import { defineActionGroup, defineAction } from 'rd-redux-utils';
import { MetaError } from '@app/types/metaError';
import { IErrorMessage } from '@app/shared/errorMessage';
import { AccountSettingsValues } from '@app/modules/account';
import {
	UserInfo,
	PaymentInfo,
	AccountBillingInfo,
	AccountInfo,
	AccounBillingCoupon,
} from './interfaces';
import { IInvoiceItem } from '../components/accountBilling/components/invoiceItem';
import type { ClusterListItem } from '@app/modules/clusters-list/state/interfaces';
import { AccountBandwidth } from '../components/accountBilling/components/billingBandwidthChart/billingBandwidthChart';

// CREATE ACCOUNT(SIGN UP ACCOUNT)
export const createAccount = defineAction<{
	values: { firstName: string; lastName: string; email: string; password: string; companyName?: string };
	onSuccess: () => void;
}>('MAKE REQUEST');

// SET ACCOUNT ERROR
export const setAccountError = defineAction<{ error: MetaError }>('ACCOUNT: ERROR');

// CLEAR STATE
export const clearAccountError = defineAction('ACCOUNT: CLEAR STATE');

// GET ACCOUNT INFO
export const setAccountInfo = defineAction<{ payload: AccountInfo }>('ACCOUNT: SET ACCOUNT INFO');

// ACCOUNT SETTINGS
export const saveAccountSettings = defineAction<{
	onSuccess: () => void;
	onPassError: (errMsg: string) => void;
	onUserInfoError: (errMsg: string) => void;
	formValues: AccountSettingsValues;
}>(`ACCOUNT SETTINGS: SAVE CHANGES`);

export const getAccountSettingsUserInfo = defineAction<{}>('ACCOUNT SETTINGS: GET USER INFO');
export const setAccountSettingsUserInfo = defineAction<UserInfo>('ACCOUNT SETTINGS: SET USER INFO');

// ACCOUNT BILLING
export const setAccountBillingInformation = defineActionGroup('SET_ACCOUNT_BILLING_INFORMATION_ACTION');

export const setAccountBillingInformationAction = setAccountBillingInformation.defineAction<{
	firstName: string;
	lastName: string;
	company: string;
	vatId: string;
	address: string;
	city: string;
	zipcode: number;
	country: string;

	nameOnCard: string;
	cardDetails: string;
	dateAndCVC: string;
}>('CREATE_ACCOUNT_BILLING_INFORMATION');

export const accountBillingInformationStartedAction = setAccountBillingInformation.defineAction(
	'ACCOUNT_BILLING_INFORMATION_STARTED'
);
export const accountBillingInformationCompletedAction = setAccountBillingInformation.defineAction(
	'ACCOUNT_BILLING_INFORMATION_COMPLETED'
);
export const accountBillingInformationErrorAction = setAccountBillingInformation.defineAction<{ error: string }>(
	'ACCOUNT_BILLING_INFORMATION_ERROR'
);

export const resetAccountBillingInformationAction = defineAction('RESET_ACCOUNT_BILLING_INFORMATION');

// PAYMENT INFO
export const getPaymentInfo = defineAction<{}>('ACCOUNT BILLING: GET PAYMENT INFO');
export const setPaymentInfo = defineAction<{ paymentInfo: PaymentInfo }>('ACCOUNT BILLING: SET PAYMENT INFO');
export const removePaymentCard = defineAction<{ id: number; onFinal: () => void }>(
	'ACCOUNT BILLING: REMOVE PAYMENT CARD'
);
// GET/SET ACCOUNT BILLING INFO
export const getAccountBillingInfo = defineAction<{}>('ACCOUNT BILLING: GET BILLING INFO');
export const setAccountBillingInfo = defineAction<{ billingInfo: AccountBillingInfo }>(
	'ACCOUNT BILLING:: SET BILLING INFO'
);
export const setAccountBillingInfoHasPayment = defineAction<{ hasPaymentMethod: boolean }>(
	'ACCOUNT BILLING: SET BILLING INFO HAS PAYMENT INFO'
);

// SET BILLING PAGE ERROR
export const setAccountBillingError = defineAction<{ error: MetaError }>('ACCOUNT BILLING: SET ERROR');
// CLEAR BILLING PAGE ERROR
export const clearAccountBillingError = defineAction('ACCOUNT BILLING: CLEAR ERROR');

// SAVE ACCOUNT BILLING
export const saveAccountBillingInfo = defineAction<{
	onSuccess: () => void;
	onFinal: () => void;
	formValues: AccountBillingInfo;
}>(`ACCOUNT BILLING: SAVE BILLING INFO`);

// ACCOUNT BILLING INVOICES
export const setAccountBillingInvoices = defineAction<{ billingInvoices: IInvoiceItem[] }>(
	'ACCOUNT BILLING: SET BILLING INVOICES'
);

export const setAccountBillingInvoicesError = defineAction<{ error: IErrorMessage }>('ACCOUNT BILLING: INVOICES ERROR');

// PROMOCODE FLOW (COUPON)
export const redeemCoupon = defineAction<{ code: string }>('ACCOUNT BILLING: REDEEM COUPON');
export const redeemCouponSucceed = defineAction<{ coupon: AccounBillingCoupon }>(
	'ACCOUNT BILLING: REDEEM COUPON SUCCEED'
);
export const redeemCouponFailed = defineAction<{ error: IErrorMessage }>('ACCOUNT BILLING: REDEEM COUPON FAILED');


// User creation flow
export const createUser = defineAction<{
	values: { firstName: string; lastName: string; email: string; password: string; inviteId: string; accountId?: number };
	onFinal: () => void;
}>('REGISTER: CREATE USER');


export const setAccountClusters = defineAction<{accountClusters: Array<Partial<ClusterListItem>>
}>('ACCOUNT BILLING: SET CLUSTERS');


export const setAccountBandwidthMetrics = defineAction<{accountBandwidth: AccountBandwidth}
>('ACCOUNT BILLING: SET METRICS BANDWIDTH');


export const getAccountBillingInfoForWizard = defineAction('GET BILLING INFO FOR BILLING INFO WIZARD STEP');

export const setDefaultBillingCard = defineAction<{cardId: number; onFinal?: () => void}>("ACCOUNT BILLING: SET DEFAULT CARD");