import React from 'react';
import styles from './style.module.scss';
import infoIcon from '@icons/icon.info.svg';
import { IconWithTooltip } from '@app/shared/iconWithTooltip/IconWithTooltip';
import { AccountBillingInfo } from '../../../../state/interfaces';
import { formatterUSDCurrency } from '@app/utils/formatterUSDCurrency';
import { AppSkeleton } from '@app/shared/AppSkeleton';

const TOOLTIP_ESTIMATED_COST_TEXT =
	'This is an overview of your expenses. The estimated cost is based on your current usage and will change based on your actions.';

interface BillingEstimatedCostTableProps {
	accountBillingInfo: AccountBillingInfo;
	loading: boolean;
}

export const BillingEstimatedCostTable = (props: BillingEstimatedCostTableProps) => {
	const { estimatedMonthlyCharge = 0, currentExpenses = 0, prevPeriodExpenses = 0 } = props.accountBillingInfo;
	const { loading } = props;

	return (
		<div className={styles.estimatedCostTable}>
			<div className={styles.estimatedCostTable__header}>
				<div className={styles.estimatedCostTable__headerTitle}>Expenses overview</div>
				<IconWithTooltip imageSrc={infoIcon} tooltipText={TOOLTIP_ESTIMATED_COST_TEXT} />
			</div>
			<div className={styles.estimatedCostTable__content}>
				<div className={styles.estimatedCostTable__col}>
					<div className={styles.estimatedCostTable__colLabel}>{'Previously'}</div>
					<div className={styles.estimatedCostTable__colValue}>
						{loading ? (
							<AppSkeleton className={styles.estimatedCostTable__skeleton} />
						) : (
							formatterUSDCurrency().format(prevPeriodExpenses / 100)
						)}
					</div>
				</div>
				<div className={styles.estimatedCostTable__col}>
					<div className={styles.estimatedCostTable__colLabel}>{'To Date'}</div>
					<div className={styles.estimatedCostTable__colValue}>
						{loading ? (
							<AppSkeleton className={styles.estimatedCostTable__skeleton} />
						) : (
							formatterUSDCurrency().format(currentExpenses / 100)
						)}
					</div>
				</div>
				<div className={styles.estimatedCostTable__col}>
					<div className={styles.estimatedCostTable__colLabel}>{'Estimated'}</div>
					<div className={styles.estimatedCostTable__colValue}>
						{loading ? (
							<AppSkeleton className={styles.estimatedCostTable__skeleton} />
						) : (
							formatterUSDCurrency().format(estimatedMonthlyCharge / 100)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
