import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { inviteMembers } from '@app/modules/team';
import TagsInput from 'react-tagsinput';
import { IErrorMessage } from '@app/shared/errorMessage';
import errorIcon from '@icons/icon-16-cross-circle.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { AppState } from '@app/reducers';
import { setInviteMembersError } from '../../state/actions';

interface InviteMembersInputProps {
	error: IErrorMessage;
	// only Owner can invite new members, Member and Biller not allowed
	isReadonlyUser: boolean;
}

export const InviteMembersInput: React.FC<InviteMembersInputProps> = ({ error, isReadonlyUser }) => {
	const [tagsArr, setTagsArr] = React.useState<string[]>([]);
	const dispatch = useDispatch();
	const { membersList } = useSelector((state: AppState) => ({
		membersList: state.team.membersList,
	}));

	const handleInput = (arr: string[]) => {
		dispatch(setInviteMembersError({ error: { message: '' } }));
		setTagsArr(arr);
	};

	React.useEffect(() => {
		setTagsArr([]);
	}, [membersList]);

	React.useEffect(() => {
		const tagContainerElem = document.getElementById('invite-member-tags')!;
		const inputContainerElem = document.getElementById('invite-member-input')!;
		const mvRight = tagContainerElem.offsetWidth - 280 > 0 ? tagContainerElem.offsetWidth - 280 : 0;

		tagContainerElem.style.right = `${mvRight}px`;
		inputContainerElem.style.right = `${mvRight}px`;
	}, [tagsArr]);

	const handleInviteMembers = () => {
		dispatch(inviteMembers({ listForInvite: tagsArr.map(tag => ({ email: tag })) }));
		setTagsArr([]);
	};

	return (
		<div className={classnames(styles.inviteInput, { [styles.inviteInput_error]: error.message })}>
			<TagsInput
				inputProps={{ placeholder: 'coworker@company.com', id: 'inviteMembersInput' }}
				onlyUnique={true}
				addOnBlur={true}
				value={tagsArr}
				onChange={handleInput}
				addKeys={[9, 13, 32, 188]}
				validationRegex={
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				}
				renderLayout={(tagComponents, inputComponent) => {
					return (
						<div className={styles.container}>
							<div
								id="invite-member-tags"
								className={classnames(styles.container__tags, {
									[styles.container__tags_grow]: tagsArr.length,
								})}
							>
								{tagComponents}
							</div>
							<div
								id="invite-member-input"
								className={classnames(styles.container__input, {
									[styles.container__input_grow]: !tagsArr.length,
								})}
							>
								{inputComponent}
							</div>
						</div>
					);
				}}
				disabled={isReadonlyUser}
			/>

			{error.message ? (
				<div className={styles.inviteInput__errorSign}>
					<SvgIcon iconSrc={errorIcon} />
					{'Error'}
				</div>
			) : (
				<Button
					className={classnames('btn-one', styles.inviteMemberBtn, { disabled: !tagsArr.length })}
					onClick={isReadonlyUser ? () => {} : handleInviteMembers}
					disabled={isReadonlyUser}
				>
					<span>{'Invite a member'}</span>
				</Button>
			)}
		</div>
	);
};
