import { call, take, put, delay, fork } from 'redux-saga/effects';
import { refreshDomainApi } from '../api';
import { refreshDomain, setClusterError } from '../actions';
import { MetaError } from '@app/types/metaError';
import { getClusterDomainsWorker } from './getClusterDomains';
import { ClusterDomainStatus } from '../../components/clusterSettings/clusterSettingsDomain/domainsTable';
import { watchCancelTaskOnClusterSettingsLeaveWorker } from './watchCancelTaskOnClusterSettingsLeaveWorker';


export const REQUEST_DELAY = 3000;

export function* watchRefreshDomain() {
	while (true) {
		const action = yield take('*');
		if (refreshDomain.is(action)) {
			const task = yield fork(refreshDomainWorker, { ...action });
			yield fork(watchCancelTaskOnClusterSettingsLeaveWorker, {task, delayValue: REQUEST_DELAY});
		}
	}
}

function* refreshDomainWorker({clusterName, domainId, onFinal}: {clusterName: string; domainId: number; onFinal: () => void}) {
	try {
		yield call(refreshDomainApi, { clusterName, domainId });
		// loops request to get domains until the currently refreshed domain is returns with status VALID or FAILED
		while (true) {
			// we have no enpoint to get single domain, only to get the list of
			const res = yield call(getClusterDomainsWorker, { clusterName });
			// so we should fine the one we just refreshed
			const refreshedDomain = yield res.find((domain: { id: number }) => domain.id === domainId);
			// and then check the status, if it is neither failed nor valid we should keep calling domains
			if (
				refreshedDomain.status === ClusterDomainStatus.VALID ||
				refreshedDomain.status === ClusterDomainStatus.FAILED
			) {
				break;
			}
			yield delay(REQUEST_DELAY);
		}
	} catch (e) {
		const meta = e?.response?.data?.errors;
		const MetaErr = new MetaError(meta ? e : { message: e.message }, meta);
		yield put(setClusterError({ error: MetaErr}));
	} finally {
    yield call(onFinal);
  }
}
