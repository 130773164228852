import React from 'react';
import moment from 'moment';
import styles from './styles.module.scss';
import removeIcon from '@icons/icon.remove.svg';
import iconRefresh from '@icons/icon.refresh.svg';
import greenLock from '@images/lock.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { AppTable } from '@app/shared/AppTable';
import { ClusterDomain } from './DomainsTable';
import { DomainTableItemStatusCell } from './DomainTableItemStatusCell';
import { DomainTableItemRemoveCell } from './DomainTableItemRemoveCell';

interface DomainTableItemProps {
	item: ClusterDomain;
	clusterName: string;
	httpsPort: number | null;
	isReadonlyCluster: boolean;
}

export const DomainTableItem: React.FC<DomainTableItemProps> = ({
	item,
	clusterName,
	httpsPort,
	isReadonlyCluster,
}) => {
	const { status, expireAt, isDnsTarget, name } = item;
	const [dateToShow, setDateToShow] = React.useState<null | string>(null);

	React.useEffect(() => {
		if (isDnsTarget) {
			setDateToShow('Managed');
		} else {
			if (expireAt) {
				setDateToShow(moment(expireAt).format('DD-MM-YYYY'));
			}
		}
	}, [isDnsTarget, expireAt]);

	return (
		<AppTable.Row>
			<AppTable.Col>
				<SvgIcon iconSrc={greenLock} className={styles.domainTableItem__lock} />
				{name}
			</AppTable.Col>

			<AppTable.Col>{httpsPort}</AppTable.Col>

			<AppTable.Col>{dateToShow}</AppTable.Col>

			<AppTable.Col>
				<DomainTableItemStatusCell
					text={status}
					btnText={'Retry'}
					iconSrc={iconRefresh}
					domainId={item.id}
					clusterName={clusterName}
					isReadonlyCluster={isReadonlyCluster}
				/>
			</AppTable.Col>

			<AppTable.Col>
				{isDnsTarget || isReadonlyCluster ? null : (
					<DomainTableItemRemoveCell domain={item} iconSrc={removeIcon} clusterName={clusterName} />
				)}
			</AppTable.Col>
		</AppTable.Row>
	);
};
