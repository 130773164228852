import React from 'react';
import styles from './styles.module.scss';
import emptyRegistries from '@images/empty.registries.png';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import { useCheckerForAppReadonlyUser } from '@app/hooks';

interface EmptyRegistriesProps {
	handleCtrlClick: () => void;
}

export const EmptyRegistries: React.FC<EmptyRegistriesProps> = ({ handleCtrlClick }) => {
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	return (
		<div className={styles.emptyRegistries}>
			<div className={styles.emptyRegistries__logoWrap}>
				<img className={styles.emptyRegistries__logoWrap__logo} src={emptyRegistries} alt="empty-registries" />
			</div>

			<div className={styles.emptyRegistries__header}>{`No private registries`}</div>

			<div
				className={styles.emptyRegistries__subHeader}
			>{`Authenticate your registries to deploy private containers`}</div>

			<Button
				onClick={isReadonlyUser ? () => {} : handleCtrlClick}
				className={classnames('btn-one', styles.emptyRegistries__ctrl)}
				disabled={isReadonlyUser}
			>{`Add a new registry`}</Button>
		</div>
	);
};
