import { call, take, put } from 'redux-saga/effects';
import { setDefaultBillingCardApi } from '../api';
import { setDefaultBillingCard, getPaymentInfo, setAccountBillingError } from '../actions';
import { MetaError } from '@app/types/metaError';

export function* watchSetDefaultBillingCard() {
	while (true) {
		const action = yield take('*');
		if (setDefaultBillingCard.is(action)) {
			yield call(setDefaultBillingCardWorker, action);
		}
	}
}

function* setDefaultBillingCardWorker({
  cardId,
	onFinal,
}: {
  cardId: number,
	onFinal?: () => void;
}) {
	try {
    yield call(setDefaultBillingCardApi, cardId);
    yield put(getPaymentInfo({}));
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(setAccountBillingError({ error: new MetaError(meta ? { message: '' } : e, meta) }));
	} finally {
		if(onFinal) {
			yield call(onFinal);
		}
  }
}
