import { UrlPath } from "rd-url-utils";

interface MenuItemCheck {
    activeItem: string;
    activeFor?: UrlPath<{}, {}>[];
    url: string;
}

export const checkIfMenuItemActive = (params: MenuItemCheck): boolean => {
    const { activeItem, activeFor = [], url } = params;
    if (activeFor.length) {
        return activeFor.some(url => url.match(activeItem, true).isMatched);
    } else {
        return activeItem === url;
    }
};
