import { AccountValues } from '../components';
import { MetaError } from '@app/types/metaError';
import api, { AuthResponse } from '@app/apis/api';
import { AccountSettingsValues } from '@app/modules/account';
import { AccountInfo, AccountBillingInfo, UserInfo } from './interfaces';
import type { ClusterListItem } from '@app/modules/clusters-list/state/interfaces';
import { AccountBandwidth } from '@app/modules/account/components/accountBilling/components/billingBandwidthChart/billingBandwidthChart';

// POST /api/account
export async function createAccountApi(formValues: AccountValues): Promise<AccountInfo> {
	try {
		return await api.post('/api/account', formValues);
	} catch (e) {
		const meta = e?.response?.data?.errors;
		throw new MetaError(meta ? { message: '' } : e, meta);
	}
}

// GET /api/account
export const getAccountInfoApi = async (): Promise<AccountInfo> => api.get('/api/account');

// GET /api/billing/info
export const getAccountBillingInfoApi = async (): Promise<AccountInfo> => api.get('/api/billing/info');

// GET /api/account/verify/:token
export const verifyEmailApi = async (token: string): Promise<AccountInfo> => api.get(`/api/user/verify/${token}`);

// PUT /api/user
export const saveAccountInfoApi = async (
	data: Partial<AccountSettingsValues>
): Promise<Partial<AccountSettingsValues>> => api.put('/api/user', { ...data });

// PUT /api/user/password
export const saveAccountNewPasswordApi = async (
	data: Partial<AccountSettingsValues>
): Promise<Partial<AccountSettingsValues>> => api.put('/api/user/password', { ...data });

// POST /api/user/regenerate-token
export const regenAPIToken = async (): Promise<{ token: string }> => {
	const result: AuthResponse = await api.post('/api/user/regenerate-token', {});
	const token = result.authToken;
	await api.setToken(token);
	return { token };
};

// GET /api/user
export const getUserInfoApi = async (): Promise<UserInfo> => api.get('/api/user');

// GET /api/billing/payment-method
export const getPaymentInfoApi = async (): Promise<AccountInfo> =>
	api.get('/api/billing/payment-method');

// DELETE /api/billing/payment-method/{id}
export const deleteBillingPaymentInfoCardApi = async ({ id }: { id: number }) =>
	api.delete(`/api/billing/payment-method/${encodeURIComponent(id)}`);

// POST /api/billing/info
export const saveAccountBillingInfoApi = async (data: AccountBillingInfo): Promise<AccountBillingInfo> =>
	api.post('/api/billing/info', { ...data });

// GET /api/billing/invoices
export const getAccountBillingInvoicesApi = async (): Promise<AccountInfo> => api.get('/api/billing/invoices');

// POST ​/api​/billing​/redeem-coupon
export const redeemCouponApi = async ({ code }: { code: string }): Promise<{ code: string }> =>
	api.post('/api/billing/redeem-coupon', { code });

// POST /api/user
export async function createUserApi(formValues: AccountValues): Promise<AccountInfo> {
	try {
		return await api.post('/api/user', formValues);
	} catch (e) {
		const meta = e?.response?.data?.errors;
		throw new MetaError(meta ? { message: '' } : e, meta);
	}
}

// GET /api/cluster
export const getAccountClustersApi = async (): Promise<Array<Partial<ClusterListItem>>> => api.get('/api/cluster?nodes=true');

// GET /metrics/bandwidth
export const getAccountBandwidthApi = async (startDate: string, endDate: string): Promise<AccountBandwidth> => api.get(`/metrics/bandwidth?start_date=${startDate}&end_date=${endDate}`);

// POST /api/billing/payment-method/{id}/default
export const setDefaultBillingCardApi = async (cardId: number): Promise<{}> =>
	api.post(`/api/billing/payment-method/${cardId}/default`);
