import React, { useEffect, useState } from 'react';
import { LabeledInputRegular } from '@app/shared/labeledInputRegular';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import iconInfo from '@icons/icon.info.svg';
import styles from './styles.module.scss';
import { MetaError } from '@app/types/metaError';

interface ClusterSettingsDockerProps {
	image: string;
	tag: string;
	handleChange: (e: React.ChangeEvent<any>) => void;
	loading: boolean;
	isSubmitting: boolean;
	errors: { image: string; tag: string };
	reqError: MetaError;
	isReadonlyCluster?: boolean;
	setFieldValue?: (name: string, value: string) => void;
}

export const ClusterSettingsDocker: React.FC<ClusterSettingsDockerProps> = ({
	image = '',
	tag = '',
	handleChange,
	loading,
	isSubmitting,
	errors,
	reqError,
	isReadonlyCluster,
	setFieldValue = () => {},
}) => {
	const [onRequestErr, setOnRequestErr] = useState<MetaError | null>(null);
	useEffect(() => {
		// if err occured during request set it
		if (reqError) {
			setOnRequestErr(reqError);
		} else {
			// on successful request we will have not error so it wil be cleared from the form
			setOnRequestErr(null);
		}
	}, [reqError]);

	// clear error from server response on changing input if err exists
	const handleRequestErr = () => {
		if (onRequestErr) {
			setOnRequestErr(null);
		}
	};

	return (
		<div id="docker" className={styles.clusterSettings__content_wrapped__body__dockerImage}>
			<div className={styles.clusterSettings__content_wrapped__body__blockHead}>{'Docker Image'}</div>
			<div className={styles.clusterSettings__content_wrapped__body__dockerImage__inputBlock}>
				<LabeledInputRegular
					className={styles.clusterSettings__content_wrapped__body__dockerImage__inputBlock__imageUrl}
					handleChange={
						isSubmitting || isReadonlyCluster
							? () => {}
							: e => {
									handleRequestErr();
									handleChange(e);
							  }
					}
					inputValue={image}
					inputName="currentVersion.image"
					labelText="Docker Image URL"
					inputPlaceholder="Docker image"
					errorMsg={errors.image || onRequestErr?.get('image')}
					isTouched={(!loading && !!errors.image) || !!onRequestErr?.get('image')}
					iconComponent={
						<IconWithTooltip
							imageSrc={iconInfo}
							tooltipText={
								'The path to your Docker image. By default we pull from Docker Hub, unless a different registry is specified. e.g appfleet/http-headers or quay.io/dockercloud/hello-world'
							}
						/>
					}
					loading={loading}
					skeletonClassname={
						styles.clusterSettings__content_wrapped__body__dockerImage__inputBlock__imageUrl__skeleton
					}
					disabled={isSubmitting || isReadonlyCluster}
					setFieldValue={setFieldValue}
				/>
				<LabeledInputRegular
					className={styles.clusterSettings__content_wrapped__body__dockerImage__inputBlock__imageTag}
					handleChange={isSubmitting || isReadonlyCluster ? () => {} : handleChange}
					inputValue={tag}
					inputName="currentVersion.tag"
					inputPlaceholder="tag e.g. latest"
					errorMsg={errors.tag || onRequestErr?.get('tag')}
					isTouched={(!loading && !!errors.tag) || !!onRequestErr?.get('tag')}
					loading={loading}
					skeletonClassname={
						styles.clusterSettings__content_wrapped__body__dockerImage__inputBlock__imageTag__skeleton
					}
					errorLabelClassName={
						styles.clusterSettings__content_wrapped__body__dockerImage__inputBlock__imageTag__errorLabelClassName
					}
					disabled={isSubmitting || isReadonlyCluster}
					setFieldValue={setFieldValue}
				/>
			</div>
		</div>
	);
};
