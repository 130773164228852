import React from 'react';
import styles from './styles.module.scss';
import { SvgIcon } from '@app/shared/SvgIcon';
import removeIcon from '@icons/icon.remove.svg';
import addIcon from '@icons/icon-16-add-blue.svg';
import { TeamMember } from '@app/modules/team/state/interfaces';
import { PermissionsRole } from '../ClusterSettingsPermissions';
import { UserAvatar } from '@app/shared/userAvatar';

export interface ComparedAvailableMember extends TeamMember {
	hasClusterPermission?: boolean;
	modified?: boolean;
}

interface AddPermissionRowProps {
	member: ComparedAvailableMember;
	updateHasClusterPermission: (member: ComparedAvailableMember) => void;
}

export const AddPermissionRow: React.FC<AddPermissionRowProps> = ({ member, updateHasClusterPermission }) => {
	const { color, firstName, lastName, permission, hasClusterPermission, email } = member;
	const handleRemove = () => {
		updateHasClusterPermission({ ...member, hasClusterPermission: false });
	};

	const handleAdd = () => {
		updateHasClusterPermission({ ...member, hasClusterPermission: true });
	};

	return (
		<div className={styles.addPerms__tableWrap__table__row}>
			<UserAvatar
				email={email}
				ownAvatarColor={color}
				className={styles.addPerms__tableWrap__table__row__avatar}
			/>

			<span>{`${firstName} ${lastName}${
				permission.toLowerCase() === PermissionsRole.OWNER ? ' (owner)' : ''
			}`}</span>
			{permission.toLowerCase() !== PermissionsRole.OWNER ? (
				<SvgIcon
					iconSrc={hasClusterPermission ? removeIcon : addIcon}
					className={styles.addPerms__tableWrap__table__row__ctrl}
					onClick={hasClusterPermission ? handleRemove : handleAdd}
				/>
			) : null}
		</div>
	);
};
