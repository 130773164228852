import { call, put } from 'redux-saga/effects';
import { deleteClusterNodeRequest } from '../api';
import { deleteClusterNodeSuccessAction, deleteClusterNodeFailedAction } from '../actions';
import { MetaError } from '@app/types/metaError';

export function* deleteClusterNodeSaga(clusterName: string, nodeHostname: string) {
	try {
		yield call(deleteClusterNodeRequest, clusterName, nodeHostname);
		yield put(deleteClusterNodeSuccessAction({clusterName, nodeHostname}));
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(deleteClusterNodeFailedAction({ error: new MetaError(meta ? { message: e.message } : e, meta) }));
	}
}
