
export enum CLUSTER_STASUSES {
	new = 'new',
	healthy = 'healthy',
	unhealthy = 'unhealthy',
	degraded = 'degraded',
	rebuilding = 'rebuilding',
	down = 'down',
	inactive = 'inactive',
}

export enum NODE_STATUSES {
	new = 'new',
	deploying = 'deploying',
	healthy = 'healthy',
	unhealthy = 'unhealthy',
	down = 'down',
	unknown = 'unknown',
}

export interface ClusterHealthStatus {
	id: number;
	status: CLUSTER_STASUSES;
}

export interface NodeHealthStatus {
	id: number;
	status: NODE_STATUSES;
}

export interface HealthStatuses {
	clusters: ClusterHealthStatus[],
	nodes: NodeHealthStatus[],
}