import { call, take, put } from "redux-saga/effects";
import { addNodeToClusterApi } from "../api";
import { addNodeToCluster, addNodeToClusterSuccess, addNodeToClusterFail } from "../actions";

export function* watchAddNodeToCluster() {
    while (true) {
        const action = yield take("*");
        if (addNodeToCluster.is(action)) {
            yield call(addNodeToClusterWorker, { nodes: action.nodes, id: action.id });
        }
    }
}

function* addNodeToClusterWorker({ id, nodes }: { id: number; nodes: { region: string; size: string } }) {
    try {
        const result = yield call(addNodeToClusterApi, { id, nodes });
        yield put(addNodeToClusterSuccess({ TODO: result }));
    } catch (e) {
        yield put(addNodeToClusterFail({ error: { message: e.message } }));
    }
}
