import { customYup as Yup, yupExceptions } from '@app/utils/customYup';

export default Yup.object().shape({
	newPassword: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isPasswordValid.regex, yupExceptions.isPasswordValid.message)
		.matches(yupExceptions.isAllowedSymbolsInPassword.regex, yupExceptions.isAllowedSymbolsInPassword.message)
		.required('Password is required'),
	token: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message),
});
