import React from 'react';
import styles from './styles.module.scss';
import { AppTable } from '@app/shared/AppTable/AppTable';
import downloadIcon from '@icons/icon.download.file.svg';
import classnames from 'classnames';
import moment from 'moment';
import { formatterUSDCurrency } from '@app/utils/formatterUSDCurrency';
import { IconWithTooltip } from '../../../../../../shared/iconWithTooltip/IconWithTooltip';

export interface IInvoiceItem {
	id: number;
	accountId: number;
	number: string;
	createdAt: string;
	updatedAt: string;
	paidAt: string;
	paid: boolean;
	pdfUrl: string;
	amountDue: number;
	amountPaid: number;
	amountRemaining: number;
	paymentMethod: string;
}

interface InvoiceItemProps {
	invoice: IInvoiceItem;
}

export const InvoiceItem: React.FC<InvoiceItemProps> = ({
	invoice: { number, createdAt, paymentMethod, paid, amountDue, pdfUrl },
}) => {
	return (
		<AppTable.Row className={styles.invoiceItem}>
			<AppTable.Col>{number}</AppTable.Col>

			<AppTable.Col>{moment(createdAt).format('DD-MM-YYYY')}</AppTable.Col>

			<AppTable.Col>{getPaymentMethod(paymentMethod)}</AppTable.Col>

			<AppTable.Col className={styles.invoiceItem__amount}>{`${formatterUSDCurrency().format(
				amountDue / 100
			)}`}</AppTable.Col>

			<AppTable.Col className={classnames(styles.invoiceItem__status, getStatusClass(paid))}>
				{paid ? 'Paid' : 'Pending'}
			</AppTable.Col>

			<AppTable.Col>
				<a href={pdfUrl}>
					<IconWithTooltip
						wrapClassname={styles.invoiceItem__download}
						labelClassname={styles.invoiceItem__download__label}
						tooltipText="Download PDF"
						imageSrc={downloadIcon}
					/>
				</a>
			</AppTable.Col>
		</AppTable.Row>
	);
};

const getStatusClass = (paid: boolean) => {
	switch (paid) {
		case false:
			return styles.invoiceItem__status_pending;

		case true:
			return styles.invoiceItem__status_paid;
		default:
			return styles.invoiceItem__status_paid;
	}
};

const getPaymentMethod = (paymentMethod: string) => {
	switch (paymentMethod && paymentMethod.toLowerCase()) {
		case 'card':
			return 'Credit card';
		default:
			return 'Custom';
	}
};
