import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@app/reducers';
import { PermissionsRole } from '@app/modules/cluster/components/clusterSettings/clusterSettingsPermissions';

export const useCheckerIsBiller = () => {
  const loggedInUser = useSelector((state: AppState) => state.account.settings);
  const [isBiller, setIsBiller] = React.useState(false);

  React.useEffect(() => {
    if (loggedInUser?.permission === PermissionsRole.BILLER) {
      setIsBiller(true);
    } else {
      setIsBiller(false);
    }
  }, [loggedInUser]);

  return {isBiller};
};
