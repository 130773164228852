import React from 'react';
import styles from './styles.module.scss';

export const NoInvoices: React.FC<{}> = () => {
	return (
		<div className={styles.noInvoices}>
			<div className={styles.noInvoices__mainImg} />
			<div className={styles.noInvoices__title}>There are no invoices yet</div>
			<div className={styles.noInvoices__subTitle}>
				Check back at the end of the month
			</div>
		</div>
	);
};
