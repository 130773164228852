import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { FormikProps, withFormik, Form } from 'formik';
import { BillingInfoWizardForm, BillingInfoFormValues } from '../../../state/interfaces';
import { schema } from './schema';
import { BasicInput } from '@inputs/BasicInput';
import { FormLayout, Col, RowWithDoubleCol } from '@app/shared/layouts/FormLayout';
import { InputErrorText } from '@app/shared/text/InputErrorText';
import { InputLabel } from '@app/shared/text/InputLabel';
import { countries } from 'countries-list';
import { SelectWithSearch } from '@app/shared/selectWithSearch';
import {
	checkVAT,
	austria,
	belgium,
	bulgaria,
	croatia,
	cyprus,
	czechRepublic,
	denmark,
	estonia,
	finland,
	france,
	germany,
	greece,
	hungary,
	ireland,
	italy,
	latvia,
	lithuania,
	luxembourg,
	malta,
	netherlands,
	poland,
	portugal,
	romania,
	slovakiaRepublic,
	slovenia,
	spain,
	sweden,
} from 'jsvat';
import postalCodes from 'postal-codes-js';

type Props = BillingInfoWizardForm & FormikProps<BillingInfoFormValues>;

const BillingInformationFormDumb: React.FC<Props> = props => {
	const [countyList] = useState(
		(
			countries && Object.keys(countries).map(key => ({ text: (countries as any)[key].name, value: key }))
		).sort((el1, el2) => (el1.text < el2.text ? -1 : 1)) || []
	);
	const [isValidVAT, setIsValidVAT] = useState(true);
	const [invalidVATErrorMsg, setInvalidVATErrorMsg] = useState('');
	const {
		errors,
		touched,
		values,
		handleChange,
		formId,
		handleBlur,
		setFieldValue,
		isSubmitting,
		handleSubmit,
		onDisable,
	} = props;
	const [zipcodeErrText, setZipcodeErrText] = useState<string>('');
	const [showZipcodeErr, setShowZipcodeErr] = useState<boolean>(false);

	const getSaveChangesBtnStatus = (errors: any, touched: any) => {
		let found = false;
		const touchedKeys = Object.keys(touched);

		for (let key in touchedKeys) {
			found = Object.keys(errors).some((errorsKey: string) => errorsKey === touchedKeys[key]);
			if (found) {
				return found;
			}
		}

		return found;
	};

	useEffect(() => {
		if (getSaveChangesBtnStatus(errors, touched) || !isValidVAT) {
			onDisable(true);
		} else {
			onDisable(false);
		}
	}, [errors, touched, isValidVAT, onDisable]);

	const handleVATChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// check for Valid VAT only is value is exists since it is an optional field
		const isValidVAT = e.target.value
			? checkVAT(e.target.value, [
					austria,
					belgium,
					bulgaria,
					croatia,
					cyprus,
					czechRepublic,
					denmark,
					estonia,
					finland,
					france,
					germany,
					greece,
					hungary,
					ireland,
					italy,
					latvia,
					lithuania,
					luxembourg,
					malta,
					netherlands,
					poland,
					portugal,
					romania,
					slovakiaRepublic,
					slovenia,
					spain,
					sweden,
			  ]).isValid
			: true;
		setIsValidVAT(isValidVAT);
		setInvalidVATErrorMsg(isValidVAT ? '' : 'Please enter a valid EU VAT');
		handleChange(e);
	};

	const customHandleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		const { country, postalCode } = values;
		const zipcodeValidationResult = postalCodes.validate(country, postalCode);

		let showZipcodeErr = false;
		let zipcodeErrText = '';

		if (errors['postalCode'] || typeof zipcodeValidationResult === 'string') {
			showZipcodeErr = true;
			zipcodeErrText = errors['postalCode']
				? errors['postalCode']
				: zipcodeValidationResult !== true
				? postalCode
					? 'Invalid zipcode'
					: 'Zipcode is required'
				: '';
		}
		setZipcodeErrText(zipcodeErrText);
		setShowZipcodeErr(showZipcodeErr);
		if (!showZipcodeErr) {
			setZipcodeErrText('');
			setShowZipcodeErr(false);
			handleSubmit();
		}
		e.preventDefault();
		e.stopPropagation();
	};

	const handleZipcodeChange = (e: React.ChangeEvent<any>) => {
		setZipcodeErrText('');
		setShowZipcodeErr(false);
		setFieldValue('postalCode', e.target.value);
	};

	return (
		<Form onSubmit={customHandleSubmit} id={formId} className={styles.dockerImageForm}>
			<FormLayout rowGap={14}>
				<RowWithDoubleCol>
					<Col type="label">
						<InputLabel htmlFor="firstName" text="First Name" />
					</Col>
					<Col
						type="input"
						subContent={<InputErrorText errorText={touched['firstName'] ? errors['firstName'] : ''} />}
					>
						<BasicInput
							id="firstName"
							name="firstName"
							placeholder="First Name"
							value={values['firstName']}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched['firstName'] && !!errors['firstName']}
							disabled={isSubmitting}
							setFieldValue={setFieldValue}
						/>
					</Col>
					<Col type="label">
						<InputLabel htmlFor="lastName" text="Last Name" />
					</Col>
					<Col
						type="input"
						subContent={<InputErrorText errorText={touched['lastName'] ? errors['lastName'] : ''} />}
					>
						<BasicInput
							id="lastName"
							name="lastName"
							placeholder="Last Name"
							value={values['lastName']}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched['lastName'] && !!errors['lastName']}
							disabled={isSubmitting}
							setFieldValue={setFieldValue}
						/>
					</Col>
				</RowWithDoubleCol>
				<RowWithDoubleCol>
					<Col type="label">
						<InputLabel htmlFor="company" text="Company" />
					</Col>
					<Col
						type="input"
						subContent={<InputErrorText errorText={touched['company'] ? errors['company'] : ''} />}
					>
						<BasicInput
							id="company"
							name="company"
							placeholder="Company (optional)"
							value={values['company']}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched['company'] && !!errors['company']}
							disabled={isSubmitting}
							setFieldValue={setFieldValue}
						/>
					</Col>
					<Col type="label">
						<InputLabel htmlFor="vatId" text="EU VAT" />
					</Col>
					<Col
						type="input"
						subContent={<InputErrorText errorText={touched['vatId'] ? invalidVATErrorMsg : ''} />}
					>
						<BasicInput
							id="vatId"
							name="vatId"
							placeholder="EU VAT (optional)"
							autoComplete="off"
							value={values['vatId']}
							onChange={handleVATChange}
							onBlur={handleBlur}
							error={touched['vatId'] && !isValidVAT}
							disabled={isSubmitting}
							setFieldValue={setFieldValue}
						/>
					</Col>
				</RowWithDoubleCol>
				<RowWithDoubleCol>
					<Col type="label">
						<InputLabel htmlFor="address" text="Address" />
					</Col>
					<Col
						type="input"
						subContent={<InputErrorText errorText={touched['address'] ? errors['address'] : ''} />}
					>
						<BasicInput
							id="address"
							name="address"
							placeholder="Address"
							value={values['address']}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched['address'] && !!errors['address']}
							disabled={isSubmitting}
							setFieldValue={setFieldValue}
						/>
					</Col>
					<Col type="label">
						<InputLabel htmlFor="city" text="City" />
					</Col>
					<Col type="input" subContent={<InputErrorText errorText={touched['city'] ? errors['city'] : ''} />}>
						<BasicInput
							id="city"
							name="city"
							placeholder="City"
							value={values['city']}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched['city'] && !!errors['city']}
							disabled={isSubmitting}
							setFieldValue={setFieldValue}
						/>
					</Col>
				</RowWithDoubleCol>
				<RowWithDoubleCol>
					<Col type="label">
						<InputLabel htmlFor="postalCode" text="Zipcode" />
					</Col>
					<Col type="input" subContent={<InputErrorText errorText={zipcodeErrText} />}>
						<BasicInput
							id="postalCode"
							name="postalCode"
							placeholder="Zipcode"
							value={values['postalCode']}
							onChange={handleZipcodeChange}
							error={showZipcodeErr}
							disabled={isSubmitting}
							setFieldValue={setFieldValue}
						/>
					</Col>
					<Col type="label">
						<InputLabel htmlFor="country" text="Country" />
					</Col>
					<Col
						type="input"
						subContent={<InputErrorText errorText={touched['country'] ? errors['country'] : ''} />}
					>
						<SelectWithSearch
							selectName="country"
							handleChange={setFieldValue}
							options={countyList}
							wrapClassname={styles.billingInformationForm__selectWrapper}
							selectClassname={styles.billingInformationForm__select}
							optionsClassname={styles.billingInformationForm__selectOptions}
							showSelectedSign={true}
							selectValue={values.country}
							disabled={isSubmitting}
							disabledInput={isSubmitting}
							error={touched['country'] && !!errors['country']}
							onBlur={handleBlur}
						/>
					</Col>
				</RowWithDoubleCol>
			</FormLayout>
		</Form>
	);
};

export const BillingInformationForm = withFormik<BillingInfoWizardForm, any>({
	mapPropsToValues: props => {
		if (props.initialValues) {
			return props.initialValues;
		}

		return {
			firstName: '',
			lastName: '',
			company: '',
			vatId: '',
			address: '',
			city: '',
			postalCode: '',
			country: '',
		};
	},
	handleSubmit: (values, { setSubmitting, props: componentProps }) => {
		setSubmitting(true);
		componentProps.onDisable(true);

		componentProps.sendBillingInfo({
			formValues: values,
			onSuccess: () => componentProps.onSubmit(values),
			onFinal: () => {
				setSubmitting(false);
				componentProps.onDisable(false);
			},
		});
	},
	validationSchema: schema,
})(BillingInformationFormDumb);
