import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { BtnWithIcon } from '@app/shared/btnWithIcon';
import styles from './styles.module.scss';
import { capitalize } from '@app/utils/capitalizeFirstLetter';
import { refreshDomain } from '../../../../state/actions';
import { ClusterDomainStatus } from './DomainsTable';

interface DomainTableItemStatusCellProps {
	iconSrc: string;
	text: string;
	btnText: string;
	domainId: number;
	clusterName: string;
	isReadonlyCluster: boolean;
}

export const DomainTableItemStatusCell: React.FC<DomainTableItemStatusCellProps> = ({
	iconSrc,
	text,
	btnText,
	domainId,
	clusterName,
	isReadonlyCluster,
}) => {
	const [isProcessing, setIsProcessing] = React.useState(false);
	const dispatch = useDispatch();

	const handleRefreshBtnClick = () => {
		setIsProcessing(true);
		dispatch(refreshDomain({ clusterName, domainId, onFinal: () => setIsProcessing(false) }));
	};

	return (
		<div className={styles.domainStatusCell}>
			<div className={classnames(styles.domainStatusCell__text, getStatusStyles(text))}>{capitalize(text)}</div>
			{text.toLowerCase() === ClusterDomainStatus.FAILED ? (
				<BtnWithIcon
					btnText={btnText}
					iconSrc={iconSrc}
					handleClick={isProcessing || isReadonlyCluster ? () => {} : handleRefreshBtnClick}
					disabled={isProcessing || isReadonlyCluster}
				/>
			) : null}
		</div>
	);
};

const getStatusStyles = (text: string) => {
	switch (text.toLowerCase()) {
		case ClusterDomainStatus.FAILED:
			return styles.domainStatusCell__text_negative;
		case ClusterDomainStatus.PENDING:
		case ClusterDomainStatus.ISSUING:
		case ClusterDomainStatus.APPLYING:
			return styles.domainStatusCell__text_warning;
		default:
			return '';
	}
};
