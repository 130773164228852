import { take, call } from 'redux-saga/effects';
import { getClusterNodesAction } from '../actions';
import { getClusterNodesSaga } from './getClusterNodes';

export function* watchGetClusterNodesSaga() {
	while (true) {
		const action = yield take('*');

		if (getClusterNodesAction.is(action)) {
			yield call(getClusterNodesSaga, action.clusterName);
		}
	}
}
