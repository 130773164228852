import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@app/reducers';


// check if Cluster is readonly for current loggedIn user
export const useCheckerIsClusterReadonly = (clusterIdToCheck: number) => {
  const {loggedInUser} = useSelector((state: AppState) => ({loggedInUser: state.account.settings}));
  const [isReadonlyCluster, setIsReadonlyCluster] = React.useState(true);

  React.useEffect(() => {
    const permittedClusters = loggedInUser.permissions?.Cluster;
    if (permittedClusters) {
      const amongPermitted = permittedClusters.some((permittedClusterId) => permittedClusterId === clusterIdToCheck);
      if (amongPermitted) {
        setIsReadonlyCluster(false);
      } else {
        setIsReadonlyCluster(true);
      }
    }
  }, [loggedInUser, clusterIdToCheck]);

  return {isReadonlyCluster};
};
