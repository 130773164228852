import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { AnchorLink } from '@app/shared/AnchorSectionNavigation';

interface ClusterSettingsNavMenuProps {
	additionalClass?: string;
}

export const ClusterSettingsNavMenu: React.FC<ClusterSettingsNavMenuProps> = ({ additionalClass }) => {
	return (
		<div className={classnames(styles.clusterSettings__content_wrapped__navMenu, additionalClass)}>
			{navMenuItems.map(item => (
				<AnchorLink key={item.elemId} sectionId={item.elemId}>
					{(active, id, handleClick) => {
						return (
							<div
								key={id}
								onClick={handleClick as any}
								className={classnames(styles.clusterSettings__content_wrapped__navMenu__item, {
									[styles.active]: active,
								})}
							>
								{item.itemText}
							</div>
						);
					}}
				</AnchorLink>
			))}
		</div>
	);
};

const navMenuItems = [
	{
		itemText: 'Docker image',
		elemId: 'docker',
	},
	{
		itemText: 'Local Cache',
		elemId: 'cache',
	},
	{
		itemText: 'Environment Variables',
		elemId: 'env-vars',
	},
	{
		itemText: 'Port Mapping',
		elemId: 'port',
	},
	{
		itemText: 'HTTPS & Domains',
		elemId: 'domain',
	},
	{
		itemText: 'Webhook',
		elemId: 'webhook',
	},
	{
		itemText: 'Permissions',
		elemId: 'permissions',
	},
	{
		itemText: 'Rebuild or delete',
		elemId: 'rebuildOrDelete',
	},
];
