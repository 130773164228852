import * as React from 'react';
import { ModalProps } from '@app/utils/createModals';
import { WideScreenModalLayout } from '@app/shared/layouts/WideScreenModalLayout';
import { DeployWizard } from '@app/modules/deploy-wizard';
import { wizardSteps } from './wizard-steps';
import { connect } from 'react-redux';
import { ComposedWizardData } from '@app/modules/deploy-wizard/interfaces';
import { deployCluster } from '@app/modules/deploy-wizard/state/actions';

type InputProps = ModalProps;

interface Props extends InputProps {
	deployCluster: (data: ComposedWizardData) => void;
}

class ClusterDeployWizardModalDumb extends React.Component<Props> {
	handleDone = (composedData: ComposedWizardData) => {
		this.props.deployCluster(composedData);
	};

	render() {
		const { open, close } = this.props;

		return (
			<WideScreenModalLayout onOuterClick={close}>
				<DeployWizard open={open} close={close} steps={wizardSteps} onDone={this.handleDone} />
			</WideScreenModalLayout>
		);
	}
}

export const ClusterDeployWizardModal = connect(null, dispatch => {
	return {
		deployCluster: (payload: ComposedWizardData) => dispatch(deployCluster(payload)),
	};
})(ClusterDeployWizardModalDumb);
