import React, { useState, useEffect } from 'react';
import { SvgIcon } from '@app/shared/SvgIcon/SvgIcon';
import iconCopy from '@icons/icon.copy.svg';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface CopyToClipboardBtnProps {
	refToCopyFrom: React.RefObject<HTMLElement>;
	className?: string;
}

export const CopyToClipboardBtn: React.FC<CopyToClipboardBtnProps> = ({ refToCopyFrom, className = '' }) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleCopy = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		if (refToCopyFrom && refToCopyFrom.current) {
			setIsFocused(true);
			const textContent = refToCopyFrom.current.textContent!;
			const textField = document.createElement('textarea');
			textField.innerText = textContent;
			document.body.appendChild(textField);
			textField.select();
			document.execCommand('copy');
			textField.remove();
			e.preventDefault();
		}
	};

	useEffect(() => {
		if (isFocused === true) {
			setTimeout(() => {
				setIsFocused(false);
			}, 400);
		}
	}, [isFocused]);

	return (
		<SvgIcon
			onClick={handleCopy}
			iconSrc={iconCopy}
			className={classnames(
				styles.copyBtn,
				{ [styles.copyBtn_focused]: isFocused },
				{ [className]: !!className }
			)}
		/>
	);
};
