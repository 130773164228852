import * as React from 'react';
import styles from './style.module.scss';
import { Grid } from 'semantic-ui-react';
import { BillingWizardHeader } from '../../BillingWizardHeader';
import classnames from 'classnames';

interface Props {
	image?: string;
	title: string;
	subtitle: string;
	children: React.ReactNode;
	controls?: JSX.Element;
}

export const StepLayoutWithLogo = (props: Props) => {
	return (
		<div className={classnames(styles.stepLayoutWithLogo, 'firstContnentElem')}>
			<Grid className={styles.stepLayoutWithLogo_wrapper}>
				<Grid.Row className={styles.imageRow}>
					<Grid.Column className={styles.imageCol}>
						<img src={props.image} alt="" />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column className={styles.contentCol}>
						<Grid className={styles.contentWrapper}>
							<Grid.Row className={styles.header}>
								<Grid.Column>
									<BillingWizardHeader
										title={props.title}
										subtitle={props.subtitle}
										className={styles.titles}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row className={styles.content}>
								<Grid.Column>{props.children}</Grid.Column>
							</Grid.Row>
						</Grid>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{props.controls && (
				<div className={styles.stepLayoutWithLogo__controlsRow}>
					<div>{props.controls}</div>
				</div>
			)}
		</div>
	);
};
