import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { AppButton } from '@app/shared/buttons/AppButton/AppButton';
import { InputWithInnerButton } from '@app/shared/inputs/InputWithInnerButton';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import iconInfo from '@icons/icon.info.svg';
import { ClusterSettingsSection } from './ClusterSettingsSection';

interface ClusterSettingsWebhookProps {
	url: string;
	isReadonlyCluster: boolean;
}

export const ClusterSettingsWebhook: React.FC<ClusterSettingsWebhookProps> = ({ url, isReadonlyCluster }) => {
	const [refUrl, setRefUrl] = React.useState(React.createRef<HTMLInputElement>().current);
	const [copied, setCopied] = React.useState(false);

	const copyToClipboard = () => {
		refUrl && refUrl.select();
		document.execCommand('copy');
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	return (
		<ClusterSettingsSection id="webhook" title="Webhook">
			<div className={classnames(styles.clusterSettings__content_wrapped__body__webhook)}>
				<div className={classnames(styles.clusterSettings__content_wrapped__body__webhook__label)}>
					<span>Webhook URL</span>
					<IconWithTooltip
						imageSrc={iconInfo}
						tooltipText={
							'Sending any kind of HTTP request to this URL will result in an automated Pull & Deploy action. Use this to automate your deployments.'
						}
					/>
				</div>

				<InputWithInnerButton
					className={styles.clusterSettings__content_wrapped__body__webhook__urlInput}
					btnClass={styles.clusterSettings__content_wrapped__body__webhook__btnCopy}
					placeholder="URL"
					btnOnClick={copyToClipboard}
					btnText={copied ? 'Copied' : 'Copy'}
					value={url}
					readOnly={true}
					ref={setRefUrl}
					btnDisabled={isReadonlyCluster}
					disabled={isReadonlyCluster}
					btnTheme={AppButton.themes.regular}
				/>
			</div>
		</ClusterSettingsSection>
	);
};
