import * as React from "react";
import * as ReactDOM from "react-dom";
import { Injectable } from "../entities/Injectable";

export interface PortalRendererProps {
    elements: Injectable[];
    nodeId: string;
}

export class PortalRenderer extends React.Component<PortalRendererProps> {
    private node: HTMLElement;

    constructor(props: PortalRendererProps) {
        super(props);
        const node = document.getElementById(this.props.nodeId);

        if (!node) throw new Error(`HTML node with id: ${this.props.nodeId} not found in tree.`);

        this.node = node;
    }

    render() {
        return ReactDOM.createPortal(
            this.props.elements.map((elem, index) => {
                const Component = elem.component;

                return elem.render ? <Component key={`node-id-${index}`} /> : null;
            }),
            this.node
        );
    }
}
