import React, { ReactNode } from 'react';
import { Button } from 'semantic-ui-react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '../SvgIcon';
import iconWarning from '@icons/icon.warning.svg';

interface AlertProps {
	msg: string;
	btnDismissText?: string;
	btnAgreeText?: string;
	htmlMsg?: ReactNode;
	className?: string;
	onDismiss: () => void;
	onAgree: () => void;
}

export const Alert: React.FC<AlertProps> = ({
	msg,
	onDismiss,
	onAgree,
	btnAgreeText = 'Remove',
	btnDismissText = 'Keep',
	htmlMsg,
	className,
}) => {
	return (
		<div className={classnames(styles.alert, className)}>
			<div className={styles.alert__msgBlock}>
				<div className={styles.alert__msgBlock__iconBlock}>
					<SvgIcon iconSrc={iconWarning} className={styles.alert__msgBlock__icon} />
				</div>
				<div className={styles.alert__msgBlock__msg}>{htmlMsg || msg}</div>
			</div>

			<div className={styles.alert__ctrls}>
				<Button className={classnames('btn', 'btn-three', styles.alert__ctrls__dismiss)} onClick={onDismiss}>
					{btnDismissText}
				</Button>
				<Button className={classnames('btn', 'btn-six', styles.alert__ctrls__agree)} onClick={onAgree}>
					{btnAgreeText}
				</Button>
			</div>
		</div>
	);
};
