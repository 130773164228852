import { customYup as yup, yupExceptions } from '@app/utils/customYup';
import { selectOptions } from '@app/shared/PortMappingFieldList/selectOptions';
import { PortMappingFormValue } from '@app/shared/PortMappingFieldList/PortMappingFieldList';

export const portsSchema = yup.array().of(
	yup
		.object()
		.shape(
			{
				public: yup
					.string()
					.matches(yupExceptions.isNotNumber.regex, yupExceptions.isNotNumber.message)
					.matches(yupExceptions.isNotAvailablePort.regex, yupExceptions.isNotAvailablePort.message)
					.matches(
						yupExceptions.isNotAvailablePublicPort.regex,
						yupExceptions.isNotAvailablePublicPort.message
					)
					.when('private', {
						is: (v:string) => v && v.length,
						then: yup.string().required("Public port can't be empty when private is set."),
					}),
				private: yup
					.string()
					.matches(yupExceptions.isNotNumber.regex, yupExceptions.isNotNumber.message)
					.matches(yupExceptions.isNotAvailablePort.regex, yupExceptions.isNotAvailablePort.message)
					.when('public', {
						is: (v:string) => v && v.length,
						then: yup.string().required("Private port can't be empty when public is set."),
					}),
				protocol: yup
					.string()
					.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
					.oneOf(selectOptions.map(option => option.value))
					.default('tcp'),
			},
			[['public', 'private']]
		)
		.uniquePort({
			msg: 'Field must be unique.',
			fieldName: 'public',
			valuePicker: (value: PortMappingFormValue) => value.public
		})
);