import * as React from 'react';
import { NodeOptionInputProps } from '../../interface';
import { NodeOptionItem } from '../../NodeOptionItem';

import icon from '@icons/console-icon.svg';
import { CLUSTER_CONSOLE_URL } from '@app/modules/cluster/state';
import { navigateTo } from '@app/utils/navigate-to';

interface Props extends NodeOptionInputProps {}

export const ConsoleOption = (props: Props) => {
	return (
		<NodeOptionItem
			iconSrc={icon}
			title="Console"
			onClick={navigateTo(CLUSTER_CONSOLE_URL.format({ name: props.cluster.name }))}
		/>
	);
};
