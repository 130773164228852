import React from 'react';
import styles from './style.module.scss';
import { PaymentInfoObject } from '../../../../state/interfaces';
import { SvgIcon } from '@app/shared/SvgIcon';
import whiteDot from '@icons/dot.white.9.svg';
import emvcoIcon from '@icons/icon.emcvo.svg';
import cardLayoutBig from '@layouts/card.layout.big.svg';
import visaIcon from '@icons/icon.visa.svg';
import mastercardIcon from '@icons/icon.mastercard.svg';
import amexIcon from '@icons/icon.amex.png';
import removeIcon from '@icons/icon.remove.option.svg';
import billingCardMenu from '@icons/billing.card.menu.svg';
import defaultCardIcon from '@icons/icon.default.card.svg';
import { RemoveItem } from '@app/shared/removeItem/RemoveItem';
import classnames from 'classnames';
import { ContextMenu } from '@app/shared/contextMenu/ContextMenu';
import { useOutsideElementClick } from '@app/hooks/useOutsideElementClick';
import { useCheckerForAppReadonlyUser, useCheckerIsBiller } from '@app/hooks';

interface PaymentInfoCardProps {
	paymentInfoCard: PaymentInfoObject;
	handleRemove: ({ id, onFinal }: { id: number; onFinal: () => void }) => void;
	handleMakeDefault: ({ cardId, onFinal }: { cardId: number; onFinal?: () => void }) => void;
}

enum CardStatus {
	Expired = 'EXPIRED',
	Default = 'DEFAULT',
}

enum CardBrands {
	Visa = 'visa',
	Amex = 'amex',
	Mastercard = 'mastercard',
}

export const PaymentInfoCard: React.FC<PaymentInfoCardProps> = ({
	paymentInfoCard: { default: isDefault, brand, last4, expMonth, expYear, id },
	handleRemove,
	handleMakeDefault,
}) => {
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const { isBiller } = useCheckerIsBiller();
	const cardMenuRef = React.useRef(null);
	useOutsideElementClick(cardMenuRef, () => setShowCardMenu(false));
	const [showRemoveModal, setShowRemoveModal] = React.useState(false);
	const [isExpired, setIsExpired] = React.useState(false);
	const [renderCardMenuBtn, setRenderCardMenuBtn] = React.useState(false);
	const [renderRemoveCardBtn, setRenderRemoveCardBtn] = React.useState(false);
	const [showCardMenu, setShowCardMenu] = React.useState(false);
	const [menuIconActive, setMenuIconActive] = React.useState(false);

	React.useEffect(() => {
		const currDate = new Date();
		const currMonth = currDate.getMonth();
		const currYear = currDate.getFullYear();

		if (currYear >= expYear && currMonth > Number(expMonth)) {
			setIsExpired(true);
		}
	}, [expMonth, expYear]);

	React.useEffect(() => {
		if (!isDefault && !isExpired) {
			setRenderCardMenuBtn(true);
		} else if (isExpired) {
			setRenderRemoveCardBtn(true);
		} else {
			setRenderCardMenuBtn(false);
			setRenderRemoveCardBtn(false);
		}
	}, [isDefault, isExpired, id]);

	return (
		<div className={styles.paymentInfoCard}>
			<div className={classnames({ [styles.expiredDate]: isExpired })}>
				<SvgIcon iconSrc={cardLayoutBig} className={styles.paymentInfoCard__cardLayout} />
			</div>

			<div
				className={classnames(styles.paymentInfoCard__headerRow, {
					[styles.paymentInfoCard__headerRow_withCardStatus]: isDefault || isExpired,
				})}
			>
				{isDefault || isExpired ? (
					<div
						className={classnames(styles.paymentInfoCard__headerRow__cardStatus, {
							[styles.paymentInfoCard__headerRow__cardStatus_expired]: isExpired,
						})}
					>
						{isExpired ? CardStatus.Expired : CardStatus.Default}
					</div>
				) : null}

				<SvgIcon iconSrc={emvcoIcon} />
			</div>

			<div className={styles.paymentInfoCard__numberRow}>
				<div className={styles.paymentInfoCard__numberRow__dots}>
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
				</div>

				<div className={styles.paymentInfoCard__numberRow__dots}>
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
				</div>

				<div className={styles.paymentInfoCard__numberRow__dots}>
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
					<SvgIcon iconSrc={whiteDot} />
				</div>

				<div className={styles.paymentInfoCard__numberRow__last4}>{last4}</div>
			</div>

			<div className={styles.paymentInfoCard__footerRow}>
				<div className={styles.paymentInfoCard__footerRow__expDate}>
					<span>Expires</span>
					<span>{`${String(expMonth).length === 2 ? expMonth : `0${expMonth}`}/${expYear}`}</span>
				</div>
				<div
					className={classnames(styles.paymentInfoCard__footerRow__cardLogo, {
						[styles.expiredDate]: isExpired,
					})}
				>
					{brand === 'amex' ? (
						<img
							src={amexIcon}
							className={classnames(styles.paymentInfoCard__footerRow__cardLogo_amex)}
							alt="amex-icon"
						></img>
					) : (
						<SvgIcon iconSrc={getBrandIcon(brand)} />
					)}
				</div>
			</div>

			{renderRemoveCardBtn ? (
				<div className={styles.paymentInfoCard__removeBtn} onClick={() => setShowRemoveModal(true)}>
					<SvgIcon iconSrc={removeIcon} />
				</div>
			) : null}

			{renderCardMenuBtn && (!isReadonlyUser || isBiller) ? (
				<div
					ref={cardMenuRef}
					className={classnames(
						styles.cardMenu,
						{ [styles.cardMenu_opened]: showCardMenu },
						{ [styles.cardMenu_active]: menuIconActive }
					)}
					onClick={() => setShowCardMenu(!showCardMenu)}
				>
					<SvgIcon
						iconSrc={billingCardMenu}
						className={classnames(styles.cardMenu__icon, {
							[styles.cardMenu__icon_opened]: showCardMenu,
						})}
					/>

					{showCardMenu ? (
						<ContextMenu
							contextMenuWrapClassName={styles.cardMenu__contextMenuWrapper}
							menuClassName={styles.cardMenu__contextMenu}
							menuItemClassName={styles.cardMenu__contextMenu__item}
							menuItems={[
								{
									text: 'Make Default',
									handleClick: () => {
										setMenuIconActive(true);
										handleMakeDefault({ cardId: id, onFinal: () => setMenuIconActive(false) });
									},
									icon: defaultCardIcon,
								},
								{
									text: 'Delete Card',
									handleClick: () => {
										setShowRemoveModal(true);
									},
									icon: removeIcon,
									menuItemSpecificClassName: styles.cardMenu__contextMenu__item_remove,
								},
							]}
						/>
					) : null}
				</div>
			) : null}

			{showRemoveModal ? (
				<RemoveItem
					msg={`Are you sure you want to remove **** **** **** ${last4} card?`}
					onDismiss={() => setShowRemoveModal(false)}
					onAgree={() => handleRemove({ id, onFinal: () => setShowRemoveModal(false) })}
					btnAgreeText="Remove"
					btnDismissText="Keep"
				/>
			) : null}
		</div>
	);
};

const getBrandIcon = (brand: string): string => {
	switch (brand.toLowerCase()) {
		case CardBrands.Visa:
			return visaIcon;
		case CardBrands.Mastercard:
			return mastercardIcon;
		case CardBrands.Amex:
			return amexIcon;
		default:
			return visaIcon;
	}
};
