import { Task } from "redux-saga";
import { CLUSTER_SETTINGS_URL } from '../url';
import { select, cancel, delay } from 'redux-saga/effects';
import { AppState } from '@app/reducers';

// check if location was changed to cancel passed task to avoid redundant requests
export function* watchCancelTaskOnClusterSettingsLeaveWorker({task, delayValue} :{task: Task, delayValue: number}) {
	while(true) {
		// if task is already done then break the loop
		if(!task.isRunning()) {
			break;
		}

		const currLocation = yield select((state: AppState) => state.router.location);
		const isMatched = yield CLUSTER_SETTINGS_URL.match(currLocation).isMatched;
		// if location was changed then cancel the task to avoid unnecessary requests
		if(!isMatched) {
			yield cancel(task);
			break;
		}
		yield delay(delayValue);
	}
}