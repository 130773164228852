import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import iconDeploying from '@icons/deploying-status-icon.svg';
import iconInfo from '@icons/icon.info.svg';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import { CLUSTER_STASUSES } from '@app/data-modules/healthStatuses';
import { useSelector } from 'react-redux';
import { AppState } from '@app/reducers';

interface Props {
	clusterId: number;
	className?: string;
	renderIcon?: boolean;
}

export const ClusterStatus: React.FC<Props> = ({ clusterId, className, renderIcon = true }) => {
	const clusterStatuses = useSelector((state: AppState) => state.healthStatuses.clusters);
	const [clusterStatus, setClusterStatus] = useState<CLUSTER_STASUSES>(CLUSTER_STASUSES.inactive);

	// get Cluster status via data-module healthStatuses
	useEffect(() => {
		if (clusterStatuses.length && clusterId) {
			const clusterStatus = clusterStatuses.find(clusterStatus => clusterStatus.id === clusterId);
			if (clusterStatus) {
				setClusterStatus(clusterStatus.status);
			}
		}
	}, [clusterStatuses, clusterId]);

	const statusStyle = getStatusStyle(clusterStatus);

	return (
		<div className={classnames(styles.clusterStatus, statusStyle, { [styles.noIcon]: !renderIcon }, className)}>
			<span className={styles.clusterStatus__text}>{getStatusText(clusterStatus)}</span>
			{renderIcon && (
				<IconWithTooltip
					iconWrapClassname={classnames(styles.clusterStatus__statusIcon, statusStyle)}
					imageSrc={getIcon(clusterStatus)}
					tooltipText={getStatusTooltipText(clusterStatus)}
					labelClassname={styles.clusterStatus__statusLabel}
				/>
			)}
		</div>
	);
};

const getIcon = (status: CLUSTER_STASUSES) => {
	if (status === CLUSTER_STASUSES.rebuilding) {
		return iconDeploying;
	} else {
		return iconInfo;
	}
};

export const getStatusText = (status: CLUSTER_STASUSES) => {
	switch (status) {
		case CLUSTER_STASUSES.new:
			return 'New';
		case CLUSTER_STASUSES.healthy:
			return 'Healthy';
		case CLUSTER_STASUSES.unhealthy:
			return 'Unhealthy';
		case CLUSTER_STASUSES.degraded:
			return 'Degraded';
		case CLUSTER_STASUSES.rebuilding:
			return 'Rebuilding';
		case CLUSTER_STASUSES.down:
			return 'Down';
		case CLUSTER_STASUSES.inactive:
			return 'Inactive';
		default:
			return 'Unknown';
	}
};

export const getStatusStyle = (status: CLUSTER_STASUSES) => {
	switch (status) {
		case CLUSTER_STASUSES.new:
			return styles.new;
		case CLUSTER_STASUSES.healthy:
			return styles.healthy;
		case CLUSTER_STASUSES.unhealthy:
			return styles.unhealthy;
		case CLUSTER_STASUSES.degraded:
			return styles.degraded;
		case CLUSTER_STASUSES.rebuilding:
			return styles.rebuilding;
		case CLUSTER_STASUSES.down:
			return styles.down;
		case CLUSTER_STASUSES.inactive:
			return styles.inactive;
		default:
			return styles.unknown;
	}
};

export const getStatusTooltipText = (status: string) => {
	switch (status) {
		case CLUSTER_STASUSES.new:
			return 'New';
		case CLUSTER_STASUSES.healthy:
			return 'Operating normally';
		case CLUSTER_STASUSES.unhealthy:
			return 'Cluster unhealthy';
		case CLUSTER_STASUSES.degraded:
			return 'Degraded';
		case CLUSTER_STASUSES.rebuilding:
			return 'Rebuilding';
		case CLUSTER_STASUSES.down:
			return 'Failed and disconnected';
		case CLUSTER_STASUSES.inactive:
			return 'Inactive';
		default:
			return 'Status unknown';
	}
};
