import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@app/reducers';
import { changeBillingInformationWizardModalApi, addPaymentCardWizardModalApi } from '@app/modals';

export const useBillingInfoWizardFullPartialOpener = () => {
  const {addressLine1, postalCode, country, city} = useSelector((state: AppState) => state.account.billingInfo);
  const [returnFullOpener, setReturnFullOpener] = React.useState(true);

	React.useEffect(() => {
    // if these fields are filled we can say user has full billing info already
		if (addressLine1 && postalCode && country && city) {
			setReturnFullOpener(false);
		} else {
			setReturnFullOpener(true);
		}
  }, [addressLine1, postalCode, country, city]);

  return returnFullOpener
    ? changeBillingInformationWizardModalApi.openModal
    : addPaymentCardWizardModalApi.openModal
};
