import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { AppCard, Head, Section, SectionContainer, Title } from '@app/shared/AppCard';
import { ApiSize } from '@app/data-modules/sizes';
import { AppSkeleton } from '@app/shared/AppSkeleton';

interface InputProps {
	className?: string;
	onChangePlanClick: () => void;
	size: ApiSize;
	loading: boolean;
}

export const ResourcesCard = (props: InputProps) => {
	const { ram, cpu, disk, monthlyPrice, name } = props.size;
	const { loading } = props;

	return (
		<AppCard className={classnames(styles.resourcesCard, props.className)}>
			<Head className={styles.resourcesCard__head}>
				<Title text="Summary per instance" className={styles.resourcesCard__headTitle} />
				{/*coming soon*/}
				{/*<AppButton*/}
				{/*	onClick={props.onChangePlanClick}*/}
				{/*	className={classnames(styles.btn, styles.resourcesCard__headBtn)}*/}
				{/*>*/}
				{/*	Change plan*/}
				{/*</AppButton>*/}
			</Head>
			<SectionContainer className={styles.planPriceBar}>
				<Section>
					<div className={styles.planPriceBar__item}>
						<span className={styles.label}>PLAN</span>
						<span className={classnames(styles.value, styles.regular)}>
							{loading ? <AppSkeleton className={styles.resourcesCard__topBarSkeleton} /> : name}
						</span>
					</div>
				</Section>
				<Section>
					<div className={styles.planPriceBar__item}>
						<span className={styles.label}>PRICE</span>
						<span className={classnames(styles.value, styles.price)}>
							{loading ? (
								<AppSkeleton className={styles.resourcesCard__topBarSkeleton} />
							) : (
								<>${monthlyPrice / 100}</>
							)}
						</span>
					</div>
				</Section>
			</SectionContainer>
			<SectionContainer className={styles.hardwareList}>
				<Section className={styles.hardwareList__item}>
					<span className={styles.type}>Memory</span>
					<span className={styles.value}>
						{loading ? <AppSkeleton className={styles.resourcesCard__skeleton} /> : <>{ram}gb</>}
					</span>
				</Section>
				<Section className={styles.hardwareList__item}>
					<span className={styles.type}>vcpu</span>
					<span className={styles.value}>
						{loading ? <AppSkeleton className={styles.resourcesCard__skeleton} /> : cpu}
					</span>
				</Section>
				<Section className={styles.hardwareList__item}>
					<span className={styles.type}>Ephemeral SSD</span>
					<span className={styles.value}>
						{loading ? <AppSkeleton className={styles.resourcesCard__skeleton} /> : <>{disk}gb</>}
					</span>
				</Section>
			</SectionContainer>
		</AppCard>
	);
};
