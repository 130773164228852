import * as React from 'react';
import styles from './style.module.scss';
import { withFormik, Form, FormikProps } from 'formik';
import { BillingCardWizardForm } from '../../../state/interfaces';
import { schema } from './schema';
import { FormLayout } from '@app/shared/layouts/FormLayout';
import { StripeCard } from '../../../../stripe/components/stripeCard';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { getStripeClientSecret } from '../../../state/actions';
import { AppState } from '../../../../../reducers/index';
import { setAccountBillingInfoHasPayment } from '@app/modules/account';

const BillingCardFormDumb: React.FC<BillingCardWizardForm & FormikProps<any>> = ({
	formId,
	onSubmit,
	isSubmitting,
	setSubmitting,
	onDisable,
	setStripeError,
	nameOnCard,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const { clientSecret, nameOnCardFromState } = useSelector((state: AppState) => ({
		clientSecret: state.billingWizard.clientSecret,
		nameOnCardFromState: state.account.billingInfo.name,
	}));

	React.useEffect(() => {
		if (!clientSecret) {
			dispatch(getStripeClientSecret({}));
		}
	}, [clientSecret, dispatch]);

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		if (!stripe || !elements || !clientSecret || isSubmitting) {
			return;
		}

		setSubmitting(true);
		onDisable(true);
		const result = await stripe.confirmCardSetup(clientSecret, {
			payment_method: {
				card: elements.getElement(CardNumberElement)!,
				billing_details: {},
			},
		});

		if (result.error) {
			setStripeError(result.error.message as string);
			setSubmitting(false);
			onDisable(false);
		} else {
			dispatch(setAccountBillingInfoHasPayment({ hasPaymentMethod: true }));
			setSubmitting(false);
			onDisable(false);
			onSubmit();
		}
	};

	return (
		<Form onSubmit={handleSubmit} id={formId} className={styles.dockerImageForm}>
			<FormLayout rowGap={14}>
				<StripeCard nameOnCard={nameOnCard || nameOnCardFromState} />
			</FormLayout>
		</Form>
	);
};

export const BillingCardForm = withFormik<BillingCardWizardForm, any>({
	mapPropsToValues: props => {
		if (props.initialValues) {
			return props.initialValues;
		}
	},
	handleSubmit: (values, { props: componentProps }) => {
		componentProps.onSubmit(values);
	},
	validationSchema: schema,
})(BillingCardFormDumb);
