import { AppState } from '@app/reducers';
import { ActivityLogPagination, ApiActivityLog } from './state';

const PAGE_SIZE = 10;

export const getActivityLogStateSelector = (store: AppState) => store.activity;

export const getActivityLogItemsSelector = (store: AppState, clusterName: string): ApiActivityLog[] | null => {
	const logs = getActivityLogStateSelector(store)[clusterName];

	if (!logs) return null;
	if (!logs.log) return [];

	// pagination
	const startIndex = (logs.page - 1) * PAGE_SIZE;
	const logsPage = logs.log.slice(startIndex, PAGE_SIZE * logs.page);

	return logsPage;
};

export const getActivityLogFetchingStateSelector = (store: AppState, clusterName: string): null | boolean => {
	const logs = getActivityLogStateSelector(store)[clusterName];

	if (!logs) return null;

	return logs.isFetching;
};

export const getActivityLogLoadedStateSelector = (store: AppState, clusterName: string): null | boolean => {
	const logs = getActivityLogStateSelector(store)[clusterName];

	return logs ? logs.isLoaded : false;
};

export const getRollbackRequestProcessStateSelector = (store: AppState, clusterName: string): null | boolean => {
	const logs = getActivityLogStateSelector(store)[clusterName];

	return logs ? logs.rollbackRequestProcessing : false;
};

export const getActivityLogPageSelector = (store: AppState, clusterName: string): null | ActivityLogPagination => {
	const logs = getActivityLogStateSelector(store)[clusterName];

	if (!logs) return null;
	if (!logs.log) return null;

	const totalPages = Math.ceil(logs.log.length / PAGE_SIZE);

	return { pages: totalPages, current: logs.page, next: logs.page + 1, prev: logs.page - 1 };
};
