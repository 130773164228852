import { takeEvery, call, put, take } from 'redux-saga/effects';
import { getUserInfoApi } from '../api';
import { ACCOUNT_SETTINGS_URL } from '../url';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { getAccountSettingsUserInfo, setAccountSettingsUserInfo, setAccountError } from '../actions';

// GET USER INFO FOR ACCOUNT SETTINGS PAGE
export function* watchGetUserInfoOnLocChange() {
	yield takeEvery(LOCATION_CHANGE, function* onNavigateAccountSettingsPage(a: LocationChangeAction) {
		const urlMatch = ACCOUNT_SETTINGS_URL.match(a.payload.location, true);
		if (!urlMatch.isMatched) {
			return;
		}

		yield call(getUserInfoWorker);
	});
}

export function* watchGetUserInfo() {
	while (true) {
			const action = yield take("*");
			if (getAccountSettingsUserInfo.is(action)) {
					yield call(getUserInfoWorker);
			}
	}
}

function* getUserInfoWorker() {
	try {
		const result = yield call(getUserInfoApi);
		yield put(setAccountSettingsUserInfo(result));
	} catch (error) {
		yield put(setAccountError({ error }));
	}
}
