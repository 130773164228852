import { customYup as yup, yupExceptions } from '@app/utils/customYup';

export const envVarSchema = yup.array().of(
	yup
		.object()
		.shape(
			{
				key: yup
					.string()
					.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
					.matches(yupExceptions.isStartsWithNumber.regex, yupExceptions.isStartsWithNumber.message)
					.matches(yupExceptions.isNotValidEnvironmentKey.regex, yupExceptions.isNotValidEnvironmentKey.message)
					.when('value', {
						is: (v:string) => v && v.length,
						then: yup
							.string()
							.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
							.required("Key property can't be empty when value is set."),
					}),
				value: yup
					.string()
					.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
					.when('key', {
						is: (v:string) => v && v.length,
						then: yup
							.string()
							.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
							.required("Value property can't be empty when key is set."),
					}),
			},
			[['key', 'value']]
		)
		.unique({
			msg: 'Key must be unique',
			fieldName: 'key',
			valuePicker: (value: {key:string, value:string}) => value.key,
		})
);