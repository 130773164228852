import * as React from 'react';
import styles from './style.module.scss';
import { BillingWizardHeader } from '../../BillingWizardHeader';
import classnames from 'classnames';

interface Props {
	children: React.ReactNode;
	title: string;
	subtitle: string;
	controls?: JSX.Element;
}

export const CenteredContentLayout = (props: Props) => {
	return (
		<div className={classnames(styles.centeredLayout, 'firstContnentElem')}>
			<BillingWizardHeader
				title={props.title}
				subtitle={props.subtitle}
				className={styles.centeredLayout__header}
			/>
			<div className={styles.centeredLayout__content}>{props.children}</div>
			{props.controls && (
				<div className={styles.centeredLayout__controlsRow}>
					<div>{props.controls}</div>
				</div>
			)}
		</div>
	);
};
