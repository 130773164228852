import { RESOURCES_STEP_ID, resourcesStepMeta, ResourcesStep } from '@app/modules/deploy-wizard/steps/ResourcesStep';
import {
	CONFIRMATION_STEP_ID,
	confirmationStepMeta,
	ConfirmationStep,
} from '@app/modules/deploy-wizard/steps/ConfirmationStep';
import { DEPLOY_STEP_ID, deployStepMeta, DeployStep } from '@app/modules/deploy-wizard/steps/DeployStep';

export const wizardSteps = [
	{
		name: RESOURCES_STEP_ID,
		meta: resourcesStepMeta,
		step: ResourcesStep,
	},
	{
		name: CONFIRMATION_STEP_ID,
		meta: confirmationStepMeta,
		step: ConfirmationStep,
	},
	{
		name: DEPLOY_STEP_ID,
		meta: deployStepMeta,
		step: DeployStep,
	},
];
