import * as React from 'react';
import { ModalProps } from '@app/utils/createModals';
import { WideScreenModalLayout } from '@app/shared/layouts/WideScreenModalLayout';
import { DeployWizard } from '@app/modules/deploy-wizard';
import { wizardSteps } from './wizard-steps';
import { compose } from 'redux';
import { ApiSize } from '@app/data-modules/sizes';
import { AppState } from '@app/reducers';
import { getSizes } from '@app/data-modules/sizes/selectors';
import { connect } from 'react-redux';
import { ICluster, updateClusterAction } from '@app/modules/cluster';
import memo from 'memoize-one';
import { RESOURCES_STEP_ID } from '@app/modules/deploy-wizard/steps/ResourcesStep';

type InputProps = ModalProps;

interface Props extends InputProps {
	sizes: ApiSize[];
	cluster: ICluster;
	updateCluster: (cluster: ICluster) => void;
}

class ChangePlanDeployWizardModalDumb extends React.Component<Props> {
	handleDone = (composedData: { size: string }) => {
		// const { cluster } = this.props;

		// const updatePayload: ICluster = {
		// 	...cluster,
		// 	currentVersion: {
		// 		...cluster.currentVersion,
		// 		...composedData,
		// 	},
		// };

		// this.props.updateCluster(updatePayload);

		setTimeout(() => alert('Endpoint not implemented.'), 0);
	};

	getActivePlanIndex = memo((cluster: ICluster, sizes: ApiSize[]) => {
		return sizes.findIndex(size => cluster.size === size.code);
	});

	getInitialWizardValues = memo((sizeIndex: number) => {
		return {
			[RESOURCES_STEP_ID]: {
				initialSize: sizeIndex,
			},
		};
	});

	render() {
		const { open, close, cluster, sizes } = this.props;

		const initialValues = this.getInitialWizardValues(this.getActivePlanIndex(cluster, sizes));

		return (
			<WideScreenModalLayout onOuterClick={close}>
				<DeployWizard
					open={open}
					close={close}
					steps={wizardSteps}
					onDone={this.handleDone}
					initialValues={initialValues}
				/>
			</WideScreenModalLayout>
		);
	}
}

export const ChangePlanDeployWizardModal = compose<React.ComponentType<InputProps>>(
	connect(
		(state: AppState) => {
			return {
				sizes: getSizes(state),
				cluster: state.cluster.cluster,
			};
		},
		dispatch => ({
			updateCluster: (cluster: ICluster) => dispatch(updateClusterAction({ cluster })),
		})
	)
)(ChangePlanDeployWizardModalDumb);
