import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { AppState } from '@app/reducers';
import { formatterUSDCurrency } from '@app/utils/formatterUSDCurrency';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { ErrorMessage } from '@app/shared/errorMessage/ErrorMessage';
import { BillingBandwidthChart } from '../billingBandwidthChart/billingBandwidthChart';
import { BillingEstimatedCostTable } from '../billingEstimatedCostTable/BillingEstimatedCostTable';
// temporary hidden by 436 task
// import { BillingPriceTable } from '../billingPriceTable/BillingPriceTable';
import { BillingPaymentInfo } from '../billingPaymentInfo/BillingPaymentInfo';
import { BillingPromoInfo, BillingCouponState } from '../billingPromoInfo/BillingPromoInfo';
import { BillingInformationInfo } from '../billingInformationInfo/BillingInformationInfo';
import {
	removePaymentCard,
	saveAccountBillingInfo,
	redeemCoupon,
	setDefaultBillingCard,
	clearAccountBillingError,
} from '../../../../state/actions';
import { PaymentInfo, AccountBillingInfo } from '../../../../state/interfaces';
import type { ClusterListItem } from '@app/modules/clusters-list/state/interfaces';
import { ApiRegion } from '@app/data-modules/regions/entity';
import { MetaError } from '@app/types/metaError';

interface AccountBillingProps {
	paymentInfo: PaymentInfo;
	isPaymentInfoLoaded: boolean;
	accountBillingInfo: AccountBillingInfo;
	removePaymentCard: ({ id, onFinal }: { id: number; onFinal: () => void }) => void;
	saveAccountBillingInfo: ({
		formValues,
		onSuccess,
		onFinal,
	}: {
		onSuccess: () => void;
		onFinal: () => void;
		formValues: AccountBillingInfo;
	}) => void;
	redeemCoupon: ({ code }: { code: string }) => void;
	billingCoupon: BillingCouponState;
	accountClusters: Array<Partial<ClusterListItem>>;
	accountBandwidth: any;
	regions: ApiRegion[];
	setDefaultBillingCard: ({ cardId, onFinal }: { cardId: number; onFinal?: () => void }) => void;
	clearAccountBillingError: (_: any) => void;
	accountBillingError?: MetaError;
}

const BillingMainComponent: React.FC<AccountBillingProps> = ({
	paymentInfo,
	isPaymentInfoLoaded,
	removePaymentCard,
	accountBillingError,
	accountBillingInfo,
	saveAccountBillingInfo,
	redeemCoupon,
	billingCoupon,
	accountClusters,
	accountBandwidth,
	regions,
	setDefaultBillingCard,
	clearAccountBillingError,
}) => {
	const [modAccountBillingInfo, setModAccountBillingInfo] = useState({
		firstName: '',
		lastName: '',
		company: '',
		vatId: '',
		address: '',
		city: '',
		zipcode: '',
		country: '',
	});

	useEffect(() => {
		if (accountBillingInfo && accountBillingInfo.name) {
			// if company name presents it wrapped with parentheses
			const company = accountBillingInfo.name.substring(
				accountBillingInfo.name.indexOf('(') + 1,
				accountBillingInfo.name.indexOf(')')
			);
			// assumed that firstName is one word without spaces always
			const firstName = accountBillingInfo.name.split(' ')[0];
			// so the lastName starts right after first space in the name string
			const lastNameStarts = accountBillingInfo.name.indexOf(' ');
			// get all to the end of the name string if no companyName presents
			// or till the parenthesis, if companyName presents
			const lastNameEnds =
				accountBillingInfo.name.indexOf('(') !== -1 ? accountBillingInfo.name.indexOf('(') : undefined;
			const lastName = accountBillingInfo.name.substring(lastNameStarts, lastNameEnds).trim();

			setModAccountBillingInfo({
				firstName,
				lastName,
				company,
				vatId: accountBillingInfo.vatId,
				address: accountBillingInfo.addressLine1,
				city: accountBillingInfo.city,
				zipcode: accountBillingInfo.postalCode,
				country: accountBillingInfo.country,
			});
		}
	}, [accountBillingInfo]);

	return (
		<div className={styles.billingMain}>
			<div className={classnames(styles.billingMain__header, styles.billingMain__contentWrapper)}>
				<div className={styles.billingMain__headerTitle}>Billing</div>
				<div className={styles.billingMain__headerRightElem}>
					<span className={styles.billingMain__headerRightElemTitle}>{'Balance'}</span>
					<span className={styles.billingMain__headerRightElemValue}>
						{accountBillingInfo.isLoaded ? (
							formatterUSDCurrency().format(accountBillingInfo.balance / 100)
						) : (
							<AppSkeleton className={styles.billingMain__headerRightElemValue__skeleton} />
						)}
					</span>
				</div>
			</div>
			<div className={classnames(styles.billingMain__content, styles.billingMain__contentWrapper)}>
				<div className={classnames(styles.billingMain__contentBlock, styles.billingMain__doubleTables)}>
					<div className={styles.billingMain__halfBlockTable}>
						<BillingBandwidthChart
							accountClusters={accountClusters}
							accountBandwidth={accountBandwidth}
							regions={regions}
						/>
					</div>
					<div className={styles.billingMain__halfBlockTable}>
						<BillingEstimatedCostTable
							accountBillingInfo={accountBillingInfo}
							loading={!accountBillingInfo.isLoaded}
						/>
					</div>
				</div>
				{/* temporary hidden by 436 task */}
				{/* <div className={styles.billingMain__contentBlock}>
					<BillingPriceTable />
					<ErrorMessage error={{ message: '' }} />
				</div> */}
				<div className={styles.billingMain__contentBlock}>
					<BillingPaymentInfo
						paymentInfo={paymentInfo}
						handleRemove={removePaymentCard}
						loading={!isPaymentInfoLoaded}
						setDefaultBillingCard={setDefaultBillingCard}
					/>
				</div>
				<div className={styles.billingMain__contentBlock}>
					<BillingPromoInfo redeemCoupon={redeemCoupon} billingCoupon={billingCoupon} />
				</div>
				<div className={styles.billingMain__contentBlock}>
					<BillingInformationInfo
						onSubmit={saveAccountBillingInfo}
						initialValues={modAccountBillingInfo}
						loading={!accountBillingInfo.isLoaded}
						accountBillingError={accountBillingError}
						clearAccountBillingError={clearAccountBillingError}
					/>
				</div>
			</div>
			<ErrorMessage
				className={styles.billingMain__error}
				useDefaultMsg={false}
				error={{ message: accountBillingError?.message || '' }}
				withinPortal={false}
			/>
		</div>
	);
};

export const BillingMain = connect(
	(state: AppState) => {
		return {
			paymentInfo: state.account.paymentInfo,
			isPaymentInfoLoaded: state.account.isPaymentInfoLoaded,
			accountBillingError: state.account.accountBillingError,
			accountBillingInfo: state.account.billingInfo,
			billingCoupon: state.account.billingCoupon,
			accountClusters: state.account.accountClusters,
			accountBandwidth: state.account.accountBandwidth,
			regions: state.regions.regions,
		};
	},
	{ removePaymentCard, saveAccountBillingInfo, redeemCoupon, setDefaultBillingCard, clearAccountBillingError }
)(BillingMainComponent);
