import { AppState } from '@app/reducers';
import { ApiSize } from './entity';

export const getSizes = (state: AppState): ApiSize[] => {
	return state.sizes.sizes;
};

export const getSizeByCode = (state: AppState, code: string): ApiSize | null => {
	const matchedSize = getSizes(state).find(size => size.code === code);

	return matchedSize ?? null;
};

export const getSizesLoadState = (state: AppState): boolean => state.sizes.isLoaded;
