import { call, take, put } from 'redux-saga/effects';
import { getAvailableMembersApi } from '../api';
import { getAvailableMembers, setAvailableMembers } from '../actions';

export function* watchGetAvailableMembers() {
	while (true) {
		const action = yield take('*');
		if (getAvailableMembers.is(action)) {
			yield call(getAvailableMembersWorker);
		}
	}
}

export function* getAvailableMembersWorker() {
	try {
		const result = yield call(getAvailableMembersApi);
		yield put(setAvailableMembers({ availableMembers: result }));
	} catch (e) {
		console.log('+++ getAccountMembers e', e);
	}
}
