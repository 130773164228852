import * as React from 'react';
import { InputProps, Input } from 'semantic-ui-react';

interface State {
	value: string;
}

export class NumberInput extends React.Component<InputProps, State> {
	constructor(props: InputProps) {
		super(props);

		this.state = {
			value: this.isValidValue(this.props.value) ? this.props.value : '',
		};
	}

	handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.persist();
		const { onChange } = this.props;
		const value = e.target.value;
		if (!this.isValidValue(value)) {
			return;
		}

		this.setState({ value: e.target.value }, () => {
			onChange && onChange(e, { value });
		});
	};

	isValidValue = (value: any) => {
		if (value === '') return true;

		return !isNaN(value);
	};

	componentDidUpdate(prevProps: InputProps) {
		if (prevProps.value !== this.props.value && this.isValidValue(this.props.value)) {
			this.setState({ value: this.props.value });
		}
	}

	ownHandleBlur = (name: string, value: string | number | undefined) => (e: React.FocusEvent<HTMLInputElement>) => {
		const { setFieldValue, onBlur } = this.props;
		if (setFieldValue) {
			// trim and replace multiwhitespaces with single one
			const prepValue = String(value).trim().replace(/\s\s+/g, ' ');
			setFieldValue(name, prepValue);
		}
		return onBlur(e);
	};

	render() {
		const { onChange, value, type, name, ...rest } = this.props;
		return (
			<Input
				{...rest}
				name={name}
				onBlur={this.ownHandleBlur(name, value)}
				onChange={this.handleChange}
				value={this.state.value}
			/>
		);
	}
}
