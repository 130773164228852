import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import logoClusterRegions from '@icons/icon.cluster.regions.svg';
import { ClusterLogsTab } from './ClusterLogsTab';
import { ClusterLogsTabCombined } from './ClusterLogsTabCombined';
import { ClusterPageLayout } from '../layouts/ClusterPageLayout';
import { SvgIcon } from '@app/shared/SvgIcon';
import { getFlagForNode } from '@app/utils/getFlagClass';
import { useClusterNodes } from '@app/hooks';

export const ClusterLogs: React.FC<{}> = () => {
	const clusterNodes = useClusterNodes();
	const [activeNodeId, setActiveOrigin] = React.useState<'combined' | number>('combined');

	const CombinedTab = () => (
		<div
			className={classnames(
				styles.clusterLogs__content__head__nodeRegion,
				styles.clusterLogs__content__head__nodeRegion_combined,
				{
					[styles.clusterLogs__content__head__nodeRegion_active]: 'combined' === activeNodeId,
				}
			)}
			onClick={() => setActiveOrigin('combined')}
		>
			<div className={styles.clusterLogs__content__head__nodeRegion_combined__icon}>
				<SvgIcon iconSrc={logoClusterRegions} />
			</div>
			{'Combined'}
		</div>
	);

	return (
		<ClusterPageLayout className={styles.clusterLogs}>
			<ClusterPageLayout.Head titleSection={<ClusterPageLayout.Title text="Logs" />} />

			<ClusterPageLayout.Content className={styles.clusterLogs__content}>
				<div className={styles.clusterLogs__content__head}>
					<CombinedTab />
					{clusterNodes &&
						clusterNodes.map((clusterNode, idx) => {
							return (
								<div
									key={idx}
									className={classnames(styles.clusterLogs__content__head__nodeRegion, {
										[styles.clusterLogs__content__head__nodeRegion_active]:
											clusterNode.id === activeNodeId,
									})}
									onClick={() => setActiveOrigin(clusterNode.id)}
								>
									<i className={classnames('flag-icons', getFlagForNode(clusterNode.region))} />
									{clusterNode.name}
								</div>
							);
						})}
				</div>
				<div className={styles.clusterLogs__content__divider} />
				<div className={styles.clusterLogs__content__body}>
					<div id={'log-container'} className={styles.clusterConsole__logContainer}>
						{activeNodeId === 'combined' && clusterNodes && clusterNodes.length ? (
							<ClusterLogsTabCombined
								nodes={clusterNodes}
								initTab={'combined' === activeNodeId}
								id={`log-combined`}
								className={classnames(styles.clusterLogs__content__body__logWrapper, {
									[styles.clusterLogs__content__body__logWrapper_hidden]: 'combined' !== activeNodeId,
								})}
							/>
						) : null}

						{clusterNodes &&
							clusterNodes.map(clusterNode => {
								if (clusterNode.id === activeNodeId) {
									return (
										<ClusterLogsTab
											key={clusterNode.id}
											hostName={clusterNode.hostname}
											initTab={clusterNode.id === activeNodeId}
											id={`log-${clusterNode.id}`}
											className={classnames(styles.clusterLogs__content__body__logWrapper, {
												[styles.clusterLogs__content__body__logWrapper_hidden]:
													clusterNode.id !== activeNodeId,
											})}
										/>
									);
								}
								return null;
							})}
					</div>
				</div>
			</ClusterPageLayout.Content>
		</ClusterPageLayout>
	);
};
