import React from 'react';
import { Button } from 'semantic-ui-react';
import classnames from 'classnames';
import styles from '@app/modules/team/components/team/styles.module.scss';
import { SvgIcon } from '@app/shared/SvgIcon';
import angleRightDark from '@icons/icon-12-arrow-right-dark.svg';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import infoIcon from '@icons/icon.info.svg';
import { FREE_SEATS_AMOUNT, TAX_PER_SEAT } from '@app/modules/team';
import { BuyNewSeats } from '@app/modules/team/components/buyNewSeats';
import { TeamMember } from '@app/modules/team/state/interfaces';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { useBillingInfoSubscriptionStatus, useBillingInfoWizardFullPartialOpener } from '@app/hooks';
import { BillingInfoSuscriptionStatus } from '@app/modules/account/state/interfaces';
import { WarningDialog } from '@app/shared/warningDialog';
import { useCheckerForAppReadonlyUser, useCheckerIsBiller } from '@app/hooks';

interface InputProps {
	membersList: TeamMember[];
	accountSeatsAmount: number;
	loading: boolean;
}

export const TeamStatsCard = (props: InputProps) => {
	const { subscriptionStatus } = useBillingInfoSubscriptionStatus();
	const billingOpener = useBillingInfoWizardFullPartialOpener();
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const { isBiller } = useCheckerIsBiller();
	const { membersList, accountSeatsAmount, loading } = props;
	const [showBuyNewSeats, setShowBuyNewSeats] = React.useState(false);
	const [showSubscrWarning, setShowSubscrWarning] = React.useState(false);

	const handleOpenBuyNewSeats = () => {
		// user not allowed to buy new seats when he has TRIAL subscription status
		if (subscriptionStatus === BillingInfoSuscriptionStatus.TRIAL) {
			setShowSubscrWarning(true);
			return null;
		} else {
			setShowBuyNewSeats(true);
		}
	};

	return (
		<div className={styles.team__utilityTables__teamStatus}>
			<div className={styles.team__utilityTables__teamStatus__head}>
				<span>{'Team summary'}</span>
				<Button
					className={classnames(styles.btnArrow, 'btn-three', styles.team__btnWithSvg)}
					onClick={isReadonlyUser && !isBiller ? () => {} : handleOpenBuyNewSeats}
					disabled={isReadonlyUser && !isBiller}
				>
					<span>{'Buy or Remove seats'}</span>
					<SvgIcon iconSrc={angleRightDark} className={styles.angleRightDark} />
				</Button>
			</div>

			<div className={styles.team__utilityTables__teamStatus__body}>
				<div className={styles.team__utilityTables__teamStatus__body__members}>
					<div>{'MEMBERS'}</div>
					<div>
						<span>
							{loading ? (
								<AppSkeleton className={styles.team__utilityTables__teamStatus__skeleton} />
							) : (
								membersList.length
							)}
						</span>
					</div>
				</div>

				<div className={styles.team__utilityTables__teamStatus__body__available}>
					<div>
						{'AVAILABLE'}
						<IconWithTooltip
							imageSrc={infoIcon}
							labelClassname={styles.team__utilityTables__teamStatus__body__available__tooltipLabel}
							tooltipText={`You have ${accountSeatsAmount - membersList.length} free team member left`}
						/>
					</div>
					<div>
						<span>
							{loading ? (
								<AppSkeleton className={styles.team__utilityTables__teamStatus__skeleton} />
							) : (
								`${accountSeatsAmount - membersList.length}/${accountSeatsAmount}`
							)}
						</span>
					</div>
				</div>

				<div className={styles.team__utilityTables__teamStatus__body__total}>
					<div>{'Total'}</div>
					<div>
						<span>
							{loading ? (
								<AppSkeleton className={styles.team__utilityTables__teamStatus__skeleton} />
							) : (
								`$${((accountSeatsAmount - FREE_SEATS_AMOUNT) * TAX_PER_SEAT).toFixed(2)}`
							)}
						</span>
					</div>
				</div>
			</div>

			{showBuyNewSeats ? (
				<BuyNewSeats onDismiss={() => setShowBuyNewSeats(false)} membersAmount={membersList.length} />
			) : null}

			{showSubscrWarning ? (
				<WarningDialog
					msg={
						'Before you buy extra seats for your team you first need to fill out your billing details and add a payment method.'
					}
					onDismiss={() => {
						setShowSubscrWarning(false);
					}}
					onAgree={() => {
						setShowSubscrWarning(false);
						billingOpener();
					}}
					btnAgreeText={'Finish account setup'}
					btnDismissText={'Close'}
					btnAgreeCustomStyle={styles.team__utilityTables__teamStatus__warning__btn_agree}
				/>
			) : null}
		</div>
	);
};
