import { useSelector } from 'react-redux';
import { AppState } from '../reducers/index';
import { ApiNode } from '../data-modules/cluster-nodes/entity';

export const useClusterNodes = (): ApiNode[] => {
	const clusterName = useSelector((state: AppState) => state.cluster.cluster.name);
	const clusterNodes = useSelector((state: AppState) =>
		state.clusterNodes.nodesMap[clusterName] ? state.clusterNodes.nodesMap[clusterName].nodes : []
	);

	return clusterNodes;
};
