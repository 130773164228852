import * as React from 'react';
import { AnchorContextProps } from '../../context';
import { withAnchorContext } from '../../context/withAnchorContext';
import memo from 'memoize-one';

type ChildrenFn = (active: boolean, id: string, onClick: (id: string) => void) => JSX.Element;

export type AnchorLinkChildren = ChildrenFn;

interface InputProps {
	sectionId: string;
	className?: string;
	children: ChildrenFn;
}

type Props = InputProps & AnchorContextProps;

class AnchorLinkDumb extends React.PureComponent<Props> {
	buildClickHandler = memo((id: string) => {
		const handler = (e: any) => {
			e.preventDefault();
			this.props.context.transiteTo(id);
		};

		return handler;
	});

	isActive = () => this.props.context.activeAnchorId === this.props.sectionId;

	render() {
		const { children, sectionId } = this.props;
		const isActive = this.isActive();

		return children(isActive, sectionId, this.buildClickHandler(sectionId));
	}
}

export const AnchorLink = withAnchorContext<InputProps, Props>(AnchorLinkDumb);
