import * as React from 'react';
import { ModalProps } from '@app/utils/createModals';
import { WideScreenModalLayout } from '@app/shared/layouts/WideScreenModalLayout';
import { BillingWizard } from '@app/modules/billing-wizard';
import { wizardSteps } from './wizard-steps';
import { compose } from 'redux';
import { AppState } from '@app/reducers';
import { connect } from 'react-redux';
import memo from 'memoize-one';
import { ApiNodeDTO } from '@app/data-modules/cluster-nodes';

type InputProps = ModalProps;

interface Props extends InputProps {}

class ChangeBillingInformationWizardModalDumb extends React.Component<Props> {
	handleDone = (composedData: { size: string; nodes: ApiNodeDTO[] }) => {};

	getInitialWizardValues = memo(() => {
		return {};
	});

	render() {
		const { open, close } = this.props;
		const initialValues = this.getInitialWizardValues();

		return (
			<WideScreenModalLayout onOuterClick={close}>
				<BillingWizard
					open={open}
					close={close}
					steps={wizardSteps}
					onDone={this.handleDone}
					initialValues={initialValues}
				/>
			</WideScreenModalLayout>
		);
	}
}

export const ChangeBillingInformationWizardModal = compose<React.ComponentType<InputProps>>(
	connect(
		(state: AppState) => {
			return {};
		},
		(dispatch, ownProps: Props) => ({})
	)
)(ChangeBillingInformationWizardModalDumb);
