import * as React from 'react';
import { PortalRendererProps } from '../components/PortalRenderer';
import { Injectable } from '../entities/Injectable';
import { InjectorObserver } from '../observers/InjectorObserver';

export function createInjectorContainer(
	observer: InjectorObserver,
	container: React.ComponentType<PortalRendererProps>
) {
	class InjectableContainerWrapper extends React.Component<
		Omit<PortalRendererProps, 'elements'>,
		{ injectables: Injectable[] }
	> {
		state = {
			injectables: [],
		};

		componentDidMount() {
			observer.registerBinder(this.update);
		}

		update = (injectables: Injectable[]) => {
			this.setState({ injectables: [...injectables] });

			if (Object.values(injectables).some(i => i.render === true)) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
			}
		};

		render() {
			const InjectorContainer = container;

			return <InjectorContainer elements={this.state.injectables} nodeId={this.props.nodeId} />;
		}
	}

	return InjectableContainerWrapper;
}
