import { customYup as Yup, yupExceptions } from '@app/utils/customYup';

export default Yup.object().shape({
	firstName: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isFirstNameValid.regex, yupExceptions.isFirstNameValid.message)
		.required('This field can’t be empty'),
	lastName: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isLastNameValid.regex, yupExceptions.isLastNameValid.message)
		.required('This field can’t be empty'),
	email: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.max(320)
		.matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, `Please enter a valid email`)
		.required('This field can’t be empty'),
	password: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isPasswordValid.regex, yupExceptions.isPasswordValid.message)
		.matches(yupExceptions.isAllowedSymbolsInPassword.regex, yupExceptions.isAllowedSymbolsInPassword.message)
		.required('This field can’t be empty'),
	companyName: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isValidCompanyName.regex, yupExceptions.isValidCompanyName.message),
	repeatPassword: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('This field can’t be empty')
		.oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
