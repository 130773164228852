import * as React from 'react';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { WizardProgressBar, WizardProgressBarStep, StepChild } from '@app/shared/wizardProgressBar';
import { DeployWizardStep, ComposedWizardData } from './interfaces';
import { createSelector } from 'reselect';
import styles from './style.module.scss';
import classnames from 'classnames';
import brandIcon from '@icons/icon.appfleet.logo.svg';
import { ModalProps } from '@app/utils/createModals';
import { AppButton } from '@app/shared/buttons/AppButton';
import { resetWizardAction } from './state/actions';
import { compose } from 'redux';
import { SvgIcon } from '@app/shared/SvgIcon';

interface InputProps extends ModalProps {
	steps: DeployWizardStep[];
	initialValues?: Record<string, any>;
	isHeaderStepHidden?: boolean;
	onDone: (composedData: any) => void;
}

interface Props extends InputProps {
	onUnmount: () => void;
}

interface State {
	stepIndex: number;
	steps: DeployWizardStep[];
	stepValues: Record<string, any>;
	stepCompletion: Record<string, boolean>;
	farStepIndex: number;
}

class DeployWizardDumb extends React.Component<Props, State> {
	state = {
		stepIndex: 0,
		steps: this.props.steps,
		stepValues: this.props.initialValues || ({} as Record<string, any>),
		stepCompletion: {} as Record<string, boolean>,
		farStepIndex: 0,
	};

	handleStepClick = (stepIndex: number) => {
		// guard to prevent step back from deploying step
		if (this.state.farStepIndex === this.state.steps.length - 1 && stepIndex < this.state.farStepIndex) return;
		const step = this.state.steps[stepIndex];

		if (!step) throw new Error('Step not exists.');

		const isNextStep = stepIndex <= this.state.farStepIndex;

		if (isNextStep) {
			const prevStep = this.state.steps[this.state.stepIndex];

			if (this.state.stepCompletion[prevStep.name]) {
				return this.setActiveStep(stepIndex);
			}
		}

		const isStepCompleted = this.state.stepCompletion[step.name];

		if (isStepCompleted) {
			this.setActiveStep(stepIndex);
		}
	};

	handlePrevSwitch = () => {
		if (this.getPrevStep()) {
			this.prev();
		}
	};

	setStepValues = (values: Record<string, any>) => {
		Object.keys(values).forEach(stepId => {
			if (!this.state.steps.find(step => step.name === stepId)) {
				throw new Error('Something went wrong.');
			}
		});
		this.setState({ stepValues: { ...this.state.stepValues, ...values } });
	};

	handleNextSwitch = (stepId: string, values: Record<string, any>) => {
		if (this.getNextStep()) {
			const step = this.state.steps[this.state.stepIndex];

			if (!step) throw new Error('Something went wrong.');

			this.setState(
				{
					stepValues: {
						...this.state.stepValues,
						[step.name]: values,
					},
					stepCompletion: {
						...this.state.stepCompletion,
						[step.name]: true,
					},
				},
				this.next
			);
		} else {
			// steps completed, lift up data.
		}
	};

	getNextStep = (): DeployWizardStep | undefined => {
		return this.state.steps[this.state.stepIndex + 1];
	};

	getPrevStep = (): DeployWizardStep | undefined => {
		return this.state.steps[this.state.stepIndex - 1];
	};

	prev = () => {
		this.setActiveStep(this.state.stepIndex - 1);
	};

	next = () => {
		this.setActiveStep(this.state.stepIndex + 1);
		this.setFarStepIndex(this.state.stepIndex + 1);
	};

	setFarStepIndex = (newFarStepIndex: number) => {
		if (newFarStepIndex > this.state.farStepIndex) {
			this.setState({ farStepIndex: newFarStepIndex });
		}
	};

	setActiveStep = (step: number, cb?: Function) => {
		this.setState({ stepIndex: step }, () => cb && cb());
	};

	onDone = () => {
		const composedData = this.composeStepsData();
		this.props.onDone(composedData);
	};

	composeStepsData = (): ComposedWizardData => {
		let values: Record<string, any> = {};

		this.state.steps.forEach(({ name, processValues }) => {
			const stepValues = this.state.stepValues[name];

			if (!stepValues) return;

			values = {
				...values,
				...(typeof processValues === 'function' ? processValues(stepValues) : stepValues),
			};
		});

		return values as ComposedWizardData;
	};

	mapStepsToProgressBar = createSelector(
		() => this.props.steps,
		(steps: DeployWizardStep[]): WizardProgressBarStep[] =>
			steps.map(step => ({ tooltipText: step.meta.tooltipText }))
	);

	componentWillUnmount() {
		this.props.onUnmount();
	}

	renderHeader = () => {
		const steps = this.mapStepsToProgressBar(this.state.steps);

		return (
			<WizardProgressBar
				className={styles.progressBar}
				progressBarBackgroundFill={'#eaf6ff'}
				progressBarBackgroundHeight={'8px'}
				progressBarFill={'linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%)'}
				progressBarHeight={'8px'}
				onStepChange={this.handleStepClick}
				steps={steps.length}
				borderRadius={'6px'}
				initialStep={this.state.stepIndex}
				activeStep={this.state.stepIndex}
				children={steps.map((step, stepIndex) => {
					const renderer: StepChild = ({ isPassed, isActive }) => {
						return (
							<Popup
								className={styles.tooltip}
								key={step.tooltipText}
								inverted
								content={step.tooltipText}
								position={'bottom center'}
								trigger={
									<i
										className={classnames(styles.dot, {
											[styles.dot__active]: isActive,
											[styles.dot__not_reached]: !isActive,
											[styles.dot__passed]: isPassed && !isActive,
											[styles.dot__accessible]:
												stepIndex <= this.state.farStepIndex &&
												this.state.farStepIndex !== this.props.steps.length - 1,
										})}
									/>
								}
							/>
						);
					};
					return renderer;
				})}
			/>
		);
	};

	render() {
		const { isHeaderStepHidden, close } = this.props;
		const { steps, stepIndex, stepValues } = this.state;
		const step = steps?.[stepIndex];
		if (!step) throw new Error(`Step list doesn't have step at index: ${stepIndex - 1}`);

		const stepValue = stepValues[step.name];
		const StepComponent = step.step;

		return (
			<div className={styles.clusterDeployWizard}>
				<div className={styles.headerContentWrapper}>
					<div className={styles.header}>
						<div className={styles.headerLogo}>
							<SvgIcon iconSrc={brandIcon} />
						</div>
						<div className={styles.headerContent}>{!isHeaderStepHidden && this.renderHeader()}</div>
						<AppButton theme={AppButton.themes.action} onClick={close} className={styles.headerBtn}>
							Close
						</AppButton>
					</div>
				</div>
				<div className={styles.content}>
					<StepComponent
						prev={this.handlePrevSwitch}
						next={this.handleNextSwitch}
						getNextStep={this.getNextStep}
						getPrevStep={this.getPrevStep}
						composeStepsData={this.composeStepsData}
						step={step}
						value={stepValue}
						stepValues={stepValues}
						key={step.name}
						done={this.onDone}
						setStepValues={this.setStepValues}
					/>
				</div>
			</div>
		);
	}
}

export const DeployWizard = compose<React.ComponentType<InputProps>>(
	connect(null, dispatch => ({
		onUnmount: () => dispatch(resetWizardAction({})),
	}))
)(DeployWizardDumb);
