import * as React from 'react';
import styles from './style.module.scss';
import { DeployWizardHeader } from '../../forms/components/DeployWizardHeader';
import classnames from 'classnames';

interface Props {
	children: React.ReactNode;
	title: string;
	subtitle: string;
	controls?: JSX.Element;
}

export const CenteredContentLayout = (props: Props) => {
	return (
		<div className={classnames(styles.centeredLayout, 'firstContnentElem')}>
			<DeployWizardHeader title={props.title} subtitle={props.subtitle} className={styles.header} />
			<div className={styles.content}>{props.children}</div>
			{props.controls && <div className={styles.centeredLayout__controlsRow}>{props.controls}</div>}
		</div>
	);
};
