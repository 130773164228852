import React from 'react';
import styles from './styles.module.scss';
import buttons from '../../../../styles/buttons.module.scss';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import { LabeledInputRegular } from '@app/shared/labeledInputRegular';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { FormikProps, withFormik, Form } from 'formik';
import { AppState } from '@app/reducers';
import { PasswordReveal } from '@app/shared/passwordReveal';
// subscribe temporary hidden
// import { RegularToggle } from '@app/shared/regularToggle';
import ValidationSchema from './validation-schema';
import { saveAccountSettings, clearAccountError } from '../../state/actions';
import { resetAPIToken } from '@app/modules/user-sign-in/state/actions';
import { UserInfo } from '../../state/interfaces';
import globalStyles from '../../../../styles/global.module.scss';
import { MetaError } from '@app/types/metaError';
import { WarningDialog } from '@app/shared/warningDialog';
import { LdsSpinner } from '@app/shared/ldsSpinner';
import api from '@app/apis/api';

interface AccountSettingsProps {
	error: MetaError;
	settings: AccountSettingsValues;
	saveAccountSettings: ({
		onPassError,
		formValues,
	}: {
		onSuccess: () => void;
		onPassError: (errMsg: string) => void;
		formValues: Partial<AccountSettingsValues>;
	}) => void;
}

export interface AccountSettingsValues extends UserInfo {
	oldPassword: string;
	newPassword: string;
	// subscribe temporary hidden
	// subscribe: boolean;
	editMode: boolean;
}

const AccountSettingsComponent: React.FC<AccountSettingsProps & FormikProps<AccountSettingsValues>> = props => {
	const [showNewPass, setShowNewPass] = React.useState(false);
	const [showPass, setShowPass] = React.useState(false);
	const [showRegenWarning, setShowRegenWarning] = React.useState(false);

	const {
		values,
		handleChange,
		handleBlur,
		handleSubmit,
		error,
		errors,
		touched,
		setValues,
		setFieldValue,
		settings,
	} = props;
	// subscribe temporary hidden
	// const { email, firstName, lastName, oldPassword, newPassword, subscribe, editMode } = values;
	const { email, firstName, lastName, oldPassword, newPassword, editMode } = values;

	const apiKeyInputRef = React.useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();
	const token = api.getToken();

	const discardChanges = () => {
		// subscribe temporary hidden
		// setValues({ ...settings, subscribe });
		dispatch(clearAccountError({}));
		setFieldValue('editMode', false);
		setValues({ ...settings });
	};

	const handleRegenerate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setShowRegenWarning(true);
		e.preventDefault();
	};

	const handleCopy = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (apiKeyInputRef && apiKeyInputRef.current) {
			apiKeyInputRef.current.select();
			document.execCommand('copy');
			e.preventDefault();
		}
	};

	const handlePassReveal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setShowPass(!showPass);
		e.preventDefault();
	};
	const handleNewPassReveal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setShowNewPass(!showNewPass);
		e.preventDefault();
	};

	const ApiKeyCtrls = () => {
		return (
			<div className={styles.apiKeyCtrls}>
				<div
					onClick={handleCopy}
					className={classnames(globalStyles.noTextSelection, buttons.btnEight, styles.apiKeyCtrls__copyBtn)}
				>{`Copy`}</div>
				<div
					onClick={handleRegenerate}
					className={classnames(globalStyles.noTextSelection, buttons.btnEight, styles.apiKeyCtrls__regenBtn)}
				>{`Re-generate`}</div>
			</div>
		);
	};

	return (
		<div className={styles.accountSettings}>
			{showRegenWarning && (
				<WarningDialog
					msg={
						'Are you sure you want to generate a new API key? ' +
						'Make sure you update all places where you might be using this key.'
					}
					onDismiss={() => {
						setShowRegenWarning(false);
					}}
					onAgree={() => {
						setShowRegenWarning(false);
						dispatch(resetAPIToken({}));
					}}
				/>
			)}
			<Form onSubmit={handleSubmit}>
				<div className={classnames(styles.accountSettings__head)}>
					<div className={styles.accountSettings__head__header}>Settings</div>
					<div className={styles.accountSettings__head__ctrls}>
						{JSON.stringify(settings) === '{}' && (
							<LdsSpinner className={styles.accountSettings__head__ctrls__spinner} />
						)}
						{editMode ? (
							<>
								<Button
									type="button"
									onClick={() => {
										discardChanges();
									}}
									className={classnames('btn-three', styles.accountSettings__head__ctrls__discard)}
								>
									{'Discard'}
								</Button>
								<Button
									type="submit"
									className={classnames('btn-one', styles.accountSettings__head__ctrls__save)}
								>
									{'Save changes'}
								</Button>
							</>
						) : (
							<Button
								type="button"
								onClick={() => setFieldValue('editMode', true)}
								className={classnames('btn-three', styles.accountSettings__head__ctrls__edit)}
							>
								{'Edit'}
							</Button>
						)}
					</div>
				</div>

				<div className={styles.accountSettings__content}>
					<div className={styles.accountSettings__content__block}>
						<div className={styles.accountSettings__content__block__head}>{`Personal information`}</div>
						<div className={styles.accountSettings__content__block__body}>
							<LabeledInputRegular
								className={classnames(styles.accountSettings__content__block__body__input, {
									[styles.accountSettings__content__block__body__input_editMode]: editMode,
								})}
								handleChange={editMode ? handleChange : () => {}}
								inputValue={firstName}
								inputName={'firstName'}
								labelText="First Name"
								errorMsg={error?.get('firstName') || errors.firstName}
								isTouched={touched.firstName}
								disabled={!editMode}
								validateOnBlur={true}
								handleBlur={handleBlur}
								loading={JSON.stringify(settings) === '{}'}
								skeletonClassname={styles.accountSettings__content__block__body__skeleton}
								setFieldValue={setFieldValue}
							/>

							<LabeledInputRegular
								className={classnames(styles.accountSettings__content__block__body__input, {
									[styles.accountSettings__content__block__body__input_editMode]: editMode,
								})}
								handleChange={editMode ? handleChange : () => {}}
								inputValue={lastName}
								inputName={'lastName'}
								labelText="Last Name"
								errorMsg={error?.get('lastName') || errors.lastName}
								isTouched={touched.lastName}
								disabled={!editMode}
								validateOnBlur={true}
								handleBlur={handleBlur}
								loading={JSON.stringify(settings) === '{}'}
								skeletonClassname={styles.accountSettings__content__block__body__skeleton}
								setFieldValue={setFieldValue}
							/>
						</div>

						<div className={styles.accountSettings__content__block__body}>
							<LabeledInputRegular
								className={classnames(styles.accountSettings__content__block__body__input, {
									[styles.accountSettings__content__block__body__input_editMode]: editMode,
								})}
								handleChange={editMode ? handleChange : () => {}}
								inputValue={email}
								inputName={'email'}
								labelText="Email"
								errorMsg={error?.get('email') || errors.email}
								isTouched={touched.email}
								disabled={!editMode}
								validateOnBlur={true}
								handleBlur={handleBlur}
								loading={JSON.stringify(settings) === '{}'}
								skeletonClassname={styles.accountSettings__content__block__body__skeleton}
								setFieldValue={setFieldValue}
							/>
						</div>
					</div>

					<div className={styles.accountSettings__content__block}>
						<div className={styles.accountSettings__content__block__head}>{`Security`}</div>
						<div className={styles.accountSettings__content__block__body}>
							{editMode ? (
								<>
									<LabeledInputRegular
										autoComplete="off"
										key={'oldPassword'}
										className={classnames(styles.accountSettings__content__block__body__input, {
											[styles.accountSettings__content__block__body__input_editMode]: editMode,
										})}
										inputType={showPass ? 'text' : 'password'}
										handleChange={editMode ? handleChange : () => {}}
										inputValue={oldPassword}
										inputName={'oldPassword'}
										labelText="Current password"
										errorMsg={error?.get('oldPassword') || errors.oldPassword}
										isTouched={touched.oldPassword}
										inputAdditional={
											editMode ? <PasswordReveal handleClick={handlePassReveal} /> : null
										}
										disabled={!editMode}
										validateOnBlur={true}
										handleBlur={handleBlur}
										setFieldValue={setFieldValue}
									/>
									<LabeledInputRegular
										autoComplete="new-password"
										key={'newPassword'}
										className={classnames(styles.accountSettings__content__block__body__input, {
											[styles.accountSettings__content__block__body__input_editMode]: editMode,
										})}
										inputType={showNewPass ? 'text' : 'password'}
										handleChange={editMode ? handleChange : () => {}}
										inputValue={newPassword}
										inputName={'newPassword'}
										labelText="New password"
										errorMsg={error?.get('newPassword') || errors.newPassword}
										isTouched={touched.newPassword}
										inputAdditional={
											editMode ? <PasswordReveal handleClick={handleNewPassReveal} /> : null
										}
										disabled={!editMode}
										validateOnBlur={true}
										handleBlur={handleBlur}
										setFieldValue={setFieldValue}
									/>
								</>
							) : (
								<LabeledInputRegular
									key={'mockPassword'}
									className={classnames(styles.accountSettings__content__block__body__input, {
										[styles.accountSettings__content__block__body__input_editMode]: editMode,
									})}
									inputType={'password'}
									handleChange={() => {}}
									inputValue={'12345678'}
									inputName={'oldPassword'}
									labelText="Current password"
									showErrStyles={false}
									disabled={true}
									setFieldValue={setFieldValue}
								/>
							)}
						</div>
					</div>

					<div className={styles.accountSettings__content__block}>
						<div className={styles.accountSettings__content__block__head}>{`API`}</div>
						<div className={styles.accountSettings__content__block__body}>
							<LabeledInputRegular
								className={classnames(
									styles.accountSettings__content__block__body__apiKeyInput,
									styles.accountSettings__content__block__body__input
								)}
								inputValue={token}
								inputName={'apiKey'}
								labelText="API Key"
								showErrLabel={false}
								showErrStyles={false}
								inputAdditional={<ApiKeyCtrls />}
								inputRef={apiKeyInputRef}
								useFocusSelection={true}
								setFieldValue={setFieldValue}
							/>
						</div>
					</div>

					{/* <div className={styles.accountSettings__content__block}>
						<div className={styles.accountSettings__content__block__head}>{`Other`}</div>
						<div className={styles.accountSettings__content__block__body}>
							<div className={styles.accountSettings__content__block__body__newsSubscribe}>
								<span>{`Get emails about new features and platform updates`}</span>

								<RegularToggle toggleValue={subscribe} handleClick={setFieldValue} />
							</div>
						</div>
					</div> */}
				</div>
			</Form>
		</div>
	);
};

export const AccountSettings = compose<AccountSettingsProps & FormikProps<AccountSettingsValues>, {}>(
	connect(
		(state: AppState) => ({
			settings: state.account.settings,
			error: state.account.error,
		}),
		{ saveAccountSettings }
	),
	withFormik({
		enableReinitialize: true,
		mapPropsToValues: ({ settings }: { settings: AccountSettingsValues }) => ({
			email: settings.email || '',
			firstName: settings.firstName || '',
			lastName: settings.lastName || '',
			oldPassword: settings.oldPassword || '',
			newPassword: settings.newPassword || '',
			// subscribe temporary hidden
			// subscribe: settings.subscribe || false,
			editMode: false,
		}),
		validationSchema: ValidationSchema,

		handleSubmit: (values, { props, setFieldError, setFieldValue }) => {
			(props as AccountSettingsProps).saveAccountSettings({
				onSuccess: () => setFieldValue('editMode', false),
				onPassError: (errMsg: string) => setFieldError('oldPassword', errMsg),
				formValues: {
					email: props.settings.email !== values.email ? values.email : undefined,
					firstName: props.settings.firstName !== values.firstName ? values.firstName : undefined,
					lastName: props.settings.lastName !== values.lastName ? values.lastName : undefined,
					oldPassword: values.oldPassword,
					newPassword: values.newPassword,
				},
			});
		},

		displayName: 'AccountSettings',
	})
)(AccountSettingsComponent);
