import api, { AuthResponse } from '@app/apis/api';
import type { SigninValues } from '../components';
import type { ResetPassValues } from '../components/resetPass';
import type { NewPasswordValues } from '../components/newPassword';


// POST /auth/login
export const signinUserApi = async (formValues: SigninValues) =>
  await api.authenticate(formValues);

// POST /api/user/reset-password-request
export const resetPasswordApi = async (formValues: ResetPassValues) =>
  await api.post('/api/user/reset-password-request', formValues);

// POST /api/user/reset-password
export const newPasswordApi = async (formValues: NewPasswordValues) =>
  await api.post('/api/user/reset-password', formValues);

// POST /api/user/regenerate-token
export const regenAPIToken = async (): Promise<{ token: string }> => {
	const result: AuthResponse = await api.post('/api/user/regenerate-token', {});
	const token = result.authToken;
	api.setToken(token);
	return { token };
};
