import { take, call, put } from 'redux-saga/effects';
import { createClusterSaga } from './createCluster';
import {
	deployCluster,
	deployClusterSuccess,
	deployClusterFail,
	createCluster,
	createClusterSuccess,
	createClusterNodes,
	createClusterNodesSuccess,
} from '../actions';
import { createClusterNodesSaga } from './createClusterNodes';
import { getClustersList } from '../../../clusters-list';
import { getClusterNodesAction } from '@app/data-modules/cluster-nodes';
import { getDeployWizardClusterSaga } from './getDeployWizardClusterSaga';
import { getAccountSettingsUserInfo } from '@app/modules/account';

export function* deployWatcher() {
	while (true) {
		const action = yield take('*');

		if (deployCluster.is(action)) {
			const { name, description, image, tag, size, env, ports, nodes } = action;
			const clusterParams = {	name, image, tag, size,	env, ports, ...(description ? { description } : {}) };

			try {
				// create cluster
				yield put(createCluster(action));
				yield call(createClusterSaga, clusterParams);
				yield put(createClusterSuccess({}));

				// create cluster nodes
				yield put(createClusterNodes({ clusterName: name, nodes }));
				yield call(createClusterNodesSaga, name, { nodes });
				yield put(createClusterNodesSuccess({}));

				// get cluster (it will be used in the DeployStep to show logs)
				yield call(getDeployWizardClusterSaga, { name });

				// set that deploy is completed
				yield put(deployClusterSuccess({}));

				// get updated clusters list
				yield put(getClustersList({}));

				yield put(getClusterNodesAction({ clusterName: name }));

				// get account info again to get updated Permissions for ClustersList
				yield put(getAccountSettingsUserInfo({}));
			} catch ({ message }) {
				yield put(deployClusterFail({ error: { message } }));
			}
		}
	}
}
