import * as React from 'react';
import { FormikProps, Form, withFormik } from 'formik';
import { DeployWizardForm } from '../../../interfaces';
import { schema } from './schema';
import styles from './style.module.scss';
import globalStyles from '@app/styles/global.module.scss';
import { LabeledInput } from '@inputs/LabeledInput';
import { FormLayout, Row, Col } from '@app/shared/layouts/FormLayout';
import { InputErrorText } from '@app/shared/text/InputErrorText';
import { AppState } from '@app/reducers';
import { connect } from 'react-redux';
import { InputLabel } from '@app/shared/text/InputLabel';
import { getClustersListApi } from '@app/modules/clusters-list';
import { ClusterListItem } from '@app/modules/clusters-list/state/interfaces';
import classnames from 'classnames';

interface FormValues {
	name: string;
	description: string;
}

interface Props extends FormikProps<FormValues> {
	publicId: string;
}

interface State {
	disabled: boolean;
}

class NameAndDescriptionFormDumb extends React.Component<DeployWizardForm & Props, State> {
	state = {
		disabled: false,
	};

	handleSubmit = async (e: any) => {
		e.preventDefault();
		const { props } = this;

		if (props.isSubmitting) return;
		props.setSubmitting(true);
		this.setDisabled(true);

		const errors = await props.validateForm();
		if (Object.keys(errors).length) {
			this.setDisabled(false);
			props.setSubmitting(false);
			return props.handleSubmit();
		}

		const clusterList: ClusterListItem[] = await getClustersListApi();
		const isUnique = !clusterList.find(cluster => cluster.name === props.values['name']);
		if (!isUnique) {
			props.setFieldError('name', `Cluster with provided name already exists.`);
			props.setTouched({ name: true }, false);
		}
		this.setDisabled(false);
		props.setSubmitting(false);
		if (isUnique) props.handleSubmit();
	};

	setDisabled = (disabled: boolean) => {
		this.setState({ disabled }, () => this.props.onDisable?.(this.state.disabled));
	};

	render() {
		const { errors, touched, publicId, formId, handleChange, values, handleBlur, setFieldValue } = this.props;

		return (
			<Form onSubmit={this.handleSubmit} id={formId}>
				<FormLayout rowGap={10}>
					<Row>
						<Col type="label">
							<InputLabel text="Cluster name" htmlFor="name" />
						</Col>
						<Col
							type="input"
							subContent={
								<div className={styles.inputHintWrapper}>
									<InputErrorText errorText={touched['name'] ? errors['name'] : ''} />
									<p className={styles.hint}>
										This will be used as part of your hostname where you will have to point your
										CNAME
									</p>
								</div>
							}
						>
							<LabeledInput
								placeholder={'Cluster name'}
								labelText={`-${publicId ? publicId : '*****'}.${process.env.REACT_APP_HOSTNAME_DOMAIN}`}
								name={'name'}
								value={values['name']}
								error={touched['name'] && !!errors['name']}
								className={styles.nameInput}
								onChange={handleChange}
								onBlur={handleBlur}
								id="name"
								setFieldValue={setFieldValue}
							/>
						</Col>
					</Row>
					<Row>
						<Col type="label">
							<InputLabel text="Description" htmlFor="name" />
						</Col>
						<Col
							type="input"
							subContent={
								<div className={styles.textareaErrorLabel}>
									<InputErrorText errorText={touched['description'] ? errors['description'] : ''} />
								</div>
							}
						>
							<textarea
								placeholder={'Description'}
								name={'description'}
								className={classnames(styles.textareaInput, globalStyles.rmDefaultTextareaStyles, {
									[styles.textareaInput_error]: touched['description'] && !!errors['description'],
								})}
								onChange={handleChange}
								onBlur={handleBlur}
								value={values['description']}
								id="description"
							/>
						</Col>
					</Row>
				</FormLayout>
			</Form>
		);
	}
}

const defaultValues = { name: '', description: '' };

export const NameAndDescriptionForm = withFormik<DeployWizardForm, any>({
	mapPropsToValues: ({ initialValues }) => initialValues || defaultValues,
	handleSubmit: (values, { props: componentProps }) => {
		componentProps.onSubmit(values);
	},
	validationSchema: schema,
})(
	connect((state: AppState) => {
		return {
			publicId: state.account.info ? state.account.info.publicId : '',
		};
	})(NameAndDescriptionFormDumb)
);
