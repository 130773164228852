import { AddPaymentCardWizardStep } from '@app/modules/billing-wizard/state/interfaces';
import {
	AddPaymentCardConfirmationStep,
	ADD_PAYMENT_CARD_CONFIRMATION_STEP_ID,
	addPaymentCardConfirmationStepMeta,
} from '../../modules/billing-wizard/steps/AddPaymentCardConfirmation';
import {
	addPaymentCardStepMeta,
	AddPaymentCardStep,
	ADD_PAYMENT_CARD_STEP_ID,
} from '../../modules/billing-wizard/steps/AddPaymentCard';

export const wizardSteps: AddPaymentCardWizardStep[] = [
	{
		name: ADD_PAYMENT_CARD_STEP_ID,
		meta: addPaymentCardStepMeta,
		step: AddPaymentCardStep,
	},
	{
		name: ADD_PAYMENT_CARD_CONFIRMATION_STEP_ID,
		meta: addPaymentCardConfirmationStepMeta,
		step: AddPaymentCardConfirmationStep,
	},
];
