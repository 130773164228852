import { ICluster } from '../components';
import { AddDomainValues } from '../components/clusterSettings/clusterSettingsDomain/addDomain';
import { ComparedAvailableMember } from '../components/clusterSettings/clusterSettingsPermissions/addPermissions/AddPermissionsRow';
import api from '@app/apis/api';
import { MetaError } from '@app/types/metaError';

// GET /api/cluster/{id}
export const getClusterByIdApi = async (name: string) => {
	try {
		return await api.get(`/api/cluster/${encodeURIComponent(name)}`);
	} catch (e) {
		const meta = e?.response?.data?.errors;
		throw new MetaError(meta ? { message: '' } : e, meta);
	}
};

// PUT /api/cluster/{clusterName}
export const updateClusterApi = async (cluster: ICluster) => {
	try {
		return await api.put(`/api/cluster/${encodeURIComponent(cluster.name)}`, cluster.currentVersion);
	} catch (e) {
		const meta = e?.response?.data?.errors;
		throw new MetaError(meta ? { message: '' } : e, meta);
	}
};

// DELETE /api/cluster/{clusterId}/node/{hostname}
export const deleteClusterNodeApi = async ({ id, hostName }: { id: number; hostName: string }) =>
	api.delete(`/api/cluster/${encodeURIComponent(id)}/node/${encodeURIComponent(hostName)}`);

// POST /api/cluster/{clusterId}/nodes
export const addNodeToClusterApi = async ({ id, nodes }: { id: number; nodes: { region: string; size: string } }) =>
	api.post(`/api/cluster/${encodeURIComponent(id)}/node`, nodes);

/// GET api/cluster/{clusterName}/domain
export const getClusterDomainsApi = async (clusterName: string) =>
	api.get(`api/cluster/${encodeURIComponent(clusterName)}/domain`);

// POST /api/cluster/{clusterName}/domain
export async function addDomainApi({ values, clusterName }: { values: AddDomainValues; clusterName: string }) {
	return api.post(`/api/cluster/${clusterName}/domain`, { name: values.customDomain });
}

// DELETE /api/cluster/{clusterName}/domain/{id}
export const deleteDomainApi = async ({ clusterName, domainId }: { clusterName: string; domainId: number }) =>
	api.delete(`/api/cluster/${encodeURIComponent(clusterName)}/domain/${encodeURIComponent(domainId)}`);

// DELETE DEL /api/cluster/clusterName
export const destroyClusterApi = async (name: string) => {
	try {
		return await api.delete(`/api/cluster/${encodeURIComponent(name)}`);
	} catch(e) {
		const meta = e?.response?.data?.errors;
		throw new MetaError(meta ? { message: '' } : e, meta);
	}};

// POST /api/cluster/clusterName/rebuild
export const postRebuildClusterByNameApi = async (name: string) =>
	api.post(`/api/cluster/${encodeURIComponent(name)}/rebuild`, {});

// POST /api/cluster/clusterName/pull
export const postPullAndDeployClusterApi = async (name: string) =>
	api.post(`/api/cluster/${encodeURIComponent(name)}/pull`, {});

// GET /api/cluster/{clusterName}/members
export const getClusterMembersApi = async (name: string) =>
	api.get(`/api/cluster/${encodeURIComponent(name)}/members`);

// GET /api/account/members
export const getAvailableMembersApi = async () => api.get(`/api/account/members`);

// POST /api/user/{id}/grants
export async function updateClusterMembersPermApi({
	member,
	clusterId,
}: {
	member: ComparedAvailableMember;
	clusterId: number;
}) {
	const method = member.hasClusterPermission ? 'post' : 'delete';
	return api[method](`/api/user/${encodeURIComponent(member.id)}/grants`, { Cluster: [clusterId] });
}

// POST /api/user/{id}/grants
export async function removeClusterPermissionsUserApi({
	userId,
	clusterId,
}: {
	userId: number;
	clusterId: number;
}) {
	return api.delete(`/api/user/${encodeURIComponent(userId)}/grants`, { Cluster: [clusterId] });
}

// POST /api/cluster/{clusterName}/https
export const enableHttpsPortApi = async ({httpsPort, clusterName}: {httpsPort: number; clusterName: string;}) =>
	api.post(`/api/cluster/${encodeURIComponent(clusterName)}/https`, {port: httpsPort});

// DELETE /api/cluster/{clusterName}/https
export const disableHttpsPortApi = async ({clusterName}: {clusterName: string}) =>
	api.delete(`/api/cluster/${encodeURIComponent(clusterName)}/https`, {});

// PUT /api/clusterName/domain/:id/status
export const refreshDomainApi = async ({clusterName, domainId}: {clusterName: string; domainId: number}) =>
api.put(`/api/cluster/${encodeURIComponent(clusterName)}/domain/${encodeURIComponent(domainId)}/status`, {});
