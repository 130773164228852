import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

interface LdsSpinnerProps {
    className?: string;
    inverted?: boolean;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
}

export const LdsSpinner: React.FC<LdsSpinnerProps> = ({ className, inverted = false, onMouseOver, onMouseLeave }) => {
    return (
        <div
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            className={classnames(styles.ldsSpinner, { [styles.ldsSpinnerInverted]: inverted }, className)}
        >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
