import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@app/reducers';
import { PermissionsRole } from '@app/modules/cluster/components/clusterSettings/clusterSettingsPermissions';

export const useCheckerForAppReadonlyUser = () => {
  const loggedInUser = useSelector((state: AppState) => state.account.settings);
  // treat user as Not ReadOnly by default to avoid UX issues for the account owners
  // for example do not showing em 404 while they are trying to get /account/team page
  // while request for permissions is not completed yet
  const [isReadonlyUser, setIsReadonlyUser] = React.useState(false);

  React.useEffect(() => {
    // logged in user with permission MEMBER or Biller should be considered as Readonly user for the App actions
    // Biller should considered as Readonly however he can work with BillingInfo, and he can visit Team page to work buy-remove seats
    if (Object.keys(loggedInUser).length && (loggedInUser.permission === PermissionsRole.MEMBER || loggedInUser.permission === PermissionsRole.BILLER)) {
      setIsReadonlyUser(true);
    } else if (Object.keys(loggedInUser).length) {
      setIsReadonlyUser(false);
    }
  }, [loggedInUser]);

  return {isReadonlyUser};
};
