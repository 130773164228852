import { call, takeEvery, put } from "redux-saga/effects";
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { getReferralApi } from "../api";
import { REFERRAL_URL } from "../url";
import { getReferralSuccess, getReferralFail } from "../actions";

export function* watchGetReferralOnLocChange() {
    yield takeEvery(LOCATION_CHANGE, function* onNavigateReferralPage(a: LocationChangeAction) {
        const urlMatch = REFERRAL_URL.match(a.payload.location, true);
        if (!urlMatch.isMatched) {
            return;
        }

        yield call(getReferralWorker);
    });
}

function* getReferralWorker() {
    try {
        const result = yield call(getReferralApi);
        yield put(getReferralSuccess({ result: result }));
    } catch (e) {
        yield put(getReferralFail({ error: { message: e.message } }));
    }
}
