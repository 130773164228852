import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import classnames from 'classnames';
import { FormikErrors } from 'formik';
import styles from '../styles.module.scss';
import { CustomModal } from '@app/shared/customModal';
import { useDNSTargetName } from '@app/hooks';
import { ICluster } from '@app/modules/cluster';
import { MetaError } from '@app/types/metaError';
import { DomainsTable, ClusterDomain } from './domainsTable';
import { NoDomains } from './noDomains';
import { PortMapping } from './PortMapping';
import { ClusterSettingsInterface, ClusterVersion } from '../clusterSettings/ClusterSettings';

interface ClusterSettingsDomainProps {
	clusterDomains: ClusterDomain[];
	cluster: ICluster;
	handleChange: (e: React.ChangeEvent<any>) => void;
	isSubmitting: boolean;
	currentVersionValues: ClusterVersion;
	formErrors: FormikErrors<ClusterSettingsInterface>;
	handleBlur: (e: React.FocusEvent<any>) => void;
	enableHttpsPort: ({
		httpsPort,
		clusterName,
		onSuccess,
	}: {
		httpsPort: number;
		clusterName: string;
		onSuccess: () => void;
	}) => void;
	disableHttpsPort: ({ clusterName, onSuccess }: { clusterName: string; onSuccess: () => void }) => void;
	httpsError: MetaError;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	clearHttpsPortError: () => void;
	httpsReqInProgress: boolean;
	settingsUnsaved: boolean;
	isReadonlyCluster: boolean;
	onHttpsChanged: (isChanged: boolean) => void;
}

export const ClusterSettingsDomain: React.FC<ClusterSettingsDomainProps> = ({
	clusterDomains,
	cluster,
	handleChange,
	currentVersionValues,
	formErrors,
	handleBlur,
	enableHttpsPort,
	disableHttpsPort,
	httpsError,
	setFieldValue,
	clearHttpsPortError,
	httpsReqInProgress,
	settingsUnsaved,
	isReadonlyCluster,
	onHttpsChanged,
}) => {
	const [showPortMapping, setShowPortMapping] = useState(false);
	const dnsTargetName = useDNSTargetName(cluster.name);

	const handlePortMapingClose = () => {
		setShowPortMapping(false);
		// if httpsPort was changed through backend then we will change nothing
		// if it was changed only on the client side and not applied then we will set previous value from cluster
		setFieldValue('currentVersion.httpsPort', cluster.currentVersion.httpsPort);
		// clear errors occurred whithin PortMapping during request
		clearHttpsPortError();
	};

	return (
		<div id="domain" className={styles.clusterSettings__content_wrapped__body__domain}>
			<div
				className={classnames([
					styles.clusterSettings__content_wrapped__body__blockHead,
					styles.clusterSettings__content_wrapped__body__blockHead_domains,
				])}
			>
				<span>{'HTTPS & Domains'}</span>
				<div className={styles.clusterSettings__content_wrapped__body__blockHead__btnWrapper}>
					<Button
						onClick={isReadonlyCluster ? () => {} : () => setShowPortMapping(true)}
						className={classnames(
							'btn-three',
							styles.clusterSettings__content_wrapped__body__blockHead__btn,
							styles.clusterSettings__content_wrapped__body__blockHead__btn_configHttps
						)}
						disabled={isReadonlyCluster}
					>
						{cluster?.currentVersion?.httpsPort ? 'Configure HTTPS' : 'Enable HTTPS'}
					</Button>
				</div>
			</div>

			<div className={classnames('btn-three', styles.clusterSettings__content_wrapped__body__pointDomains)}>
				Point all your domains using CNAME to: <span>{dnsTargetName}</span>
			</div>

			{!!cluster.currentVersion?.httpsPort ? (
				<DomainsTable
					clusterDomains={clusterDomains}
					clusterName={cluster.name}
					httpsPort={cluster.currentVersion?.httpsPort}
					dnsTargetName={dnsTargetName}
					isReadonlyCluster={isReadonlyCluster}
				/>
			) : (
				<NoDomains dnsTargetName={dnsTargetName} />
			)}

			{showPortMapping && (
				<CustomModal>
					<PortMapping
						onDismiss={handlePortMapingClose}
						httpsPortClusterValue={cluster?.currentVersion?.httpsPort}
						httpsPortFormCurrValue={currentVersionValues?.httpsPort}
						handleChange={handleChange}
						httpsPortValidationError={formErrors.currentVersion?.httpsPort}
						handleBlur={handleBlur}
						enableHttpsPort={enableHttpsPort}
						disableHttpsPort={disableHttpsPort}
						clusterName={cluster.name}
						httpsError={httpsError}
						setShowPortMapping={setShowPortMapping}
						httpsReqInProgress={httpsReqInProgress}
						settingsUnsaved={settingsUnsaved}
						onHttpsChanged={onHttpsChanged}
					/>
				</CustomModal>
			)}
		</div>
	);
};
