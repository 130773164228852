import { defineAction } from 'rd-redux-utils';
import { IErrorMessage } from '@app/shared/errorMessage/ErrorMessage';
import { MetaError } from '@app/types/metaError';
import { TeamMember } from './interfaces';

// INVITE MEMBERS
export const inviteMembers = defineAction<{ listForInvite: { email: string }[] }>('TEAM: INVITE MEMBERS');

export const setInviteMembersError = defineAction<{ error: IErrorMessage }>('TEAM: SET INVITE MEMBERS ERROR');

export const setMembersList = defineAction<{ membersList: TeamMember[] }>('TEAM: SET MEMBERS LIST');

export const updateAccountMemberRole = defineAction<Partial<TeamMember>>('TEAM: UPDATE ACCOUNT MEMBER ROLE');

export const getAccountMembersList = defineAction('TEAM: GET MEMBERS LIST');

export const removeAccountMember = defineAction<{
	memberData: Partial<TeamMember>;
	onSuccess: () => void;
	onFailure: () => void;
}>('TEAM: REMOVE ACCOUNT MEMBER');

export const getAccountSeats = defineAction<{}>('TEAM: GET ACCOUNT SEATS');
export const setAccountSeats = defineAction<{ totalSeatsAmount: number }>('TEAM: SET ACCOUNT SEATS');
export const saveAccountSeats = defineAction<{ totalSeatsAmount: number; onSuccess: () => void; onFinal: () => void }>(
	'TEAM: SAVE ACCOUNT SEATS'
);
export const setAccountSeatsError = defineAction<{ error: MetaError }>('TEAM: SAVE SEATS ERROR');
export const clearAccountSeatsError = defineAction<{}>('TEAM: CLEAR SEATS ERROR');
