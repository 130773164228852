import api from '@app/apis/api';
import type { ClusterListItem } from '@app/modules/clusters-list/state/interfaces';

// GET /api/cluster
export const getClustersListApi = async (): Promise<ClusterListItem[]> => await api.get('/api/cluster?nodes=true');

// DELETE /api/cluster/{clusterId}
export const deleteClusterByIdApi = async ({ id }: { id: number }) => await api.delete(`/api/cluster/${id}`);

// GET /api/cluster/regions
export const getClusterRegionsApi = async () => await api.get('/api/cluster/regions');

// GET /api/cluster/sizes
export const getNodeSizesApi = async () => await api.get('/api/cluster/sizes');

// GET /metrics/clusters
export const getClustersMetricsApi = async () => await api.get('/metrics/clusters');

// POST /api/cluster/clusterName/pull
export const pullNDeployClustersListItemApi = (name: string) => api.post(`/api/cluster/${encodeURIComponent(name)}/pull`, {});