import * as React from 'react';
import styles from './style.module.scss';
import { DeployWizardStepComponent } from '../../interfaces';
import { StepLayoutWithLogo } from '../../components/layouts/DefaultStepLayout/StepLayoutWithLogo';
import { NameAndDescriptionForm } from '@app/modules/deploy-wizard/components/forms';
import img from '@images/name-description.png';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { nameAndDescriptionStepMeta } from './meta';

export const NameAndDescriptionStep: DeployWizardStepComponent = props => {
	const [disabled, setDisabled] = React.useState(false);
	const { getNextStep } = props;
	const nextStep = getNextStep();

	return (
		<StepLayoutWithLogo
			image={img}
			title={nameAndDescriptionStepMeta.title}
			subtitle={nameAndDescriptionStepMeta.subtitle}
			imgClassName={styles.mainImg}
			controls={
				<div className={styles.controls}>
					{nextStep && (
						<StepNavigationButton
							formId={props.step.name}
							type="next"
							stepName={nextStep.meta.btnText}
							disabled={disabled}
						/>
					)}
				</div>
			}
		>
			<div className={styles.wrapper}>
				<div className={styles.content}>
					<NameAndDescriptionForm
						onSubmit={values => {
							props.next(props.step.name, values);
						}}
						initialValues={props.value}
						formId={props.step.name}
						onDisable={setDisabled}
					/>
				</div>
			</div>
		</StepLayoutWithLogo>
	);
};
