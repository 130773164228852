import { AppState } from '@app/reducers';
import { ClusterNodesState, ClusterNodesItem } from './state';
import { ApiNode } from './entity';

export const getClusterNodes = (state: AppState): ClusterNodesState => state.clusterNodes;

export const getClusterNodeMap = (state: AppState): Record<string, ClusterNodesItem> => getClusterNodes(state).nodesMap;

export const getClusterListItemByName = (state: AppState, clusterName: string): undefined | ClusterNodesItem =>
	getClusterNodeMap(state)[clusterName];

export const getClusterNodesList = (state: AppState, clusterName: string): ApiNode[] => {
	const cluster = getClusterListItemByName(state, clusterName);
	return cluster?.nodes || [];
};

export const getClusterNodesLoadedStatus = (state: AppState, clusterName: string): boolean => {
	const cluster = getClusterListItemByName(state, clusterName);

	return cluster ? cluster.isLoaded : false;
};
