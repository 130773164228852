import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styles from './style.module.scss';
import { DeployWizardStepComponent, DeployWizardStepProps } from '../../interfaces';
import { StepLayoutWithLogo } from '../../components/layouts/DefaultStepLayout/StepLayoutWithLogo';
import { RepositoriesList } from './components/RepositoriesList';
import { DockerImageForm } from '@app/modules/deploy-wizard/components/forms';
import { PORT_MAPPING_STEP_ID, portMappingStepMeta } from '@app/modules/deploy-wizard/steps/PortMappingStep';
import { ENVIRONMENT_STEP_ID, environmentStepMeta } from '@app/modules/deploy-wizard/steps/EnvironmentStep';
import { AppState } from '@app/reducers';
import containerImage from '@images/container.png';
import { EditRegistry } from '@app/modules/registries/components/editRegistry';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { ApiRegistry } from '@app/modules/registries/state/dto/registry';
import { dockerImageStepMeta } from './meta';

interface Props extends DeployWizardStepProps {
	registries: ApiRegistry[];
	isRegistriesFetching: boolean;
}

interface State {
	opened: boolean;
	disabled: boolean;
}

class DockerImageStepDumb extends React.Component<Props, State> {
	state = {
		opened: false,
		disabled: false,
	};

	setOpened = (opened: boolean) => this.setState({ opened });

	setDisabled = (disabled: boolean) => this.setState({ disabled });

	handleSubmit = (values: Record<string, string>) => {
		this.props.next(this.props.step.name, { ...values });
	};

	handleDemoDeploy = () => {
		const { setStepValues } = this.props;
		setStepValues({
			[`${PORT_MAPPING_STEP_ID}`]: portMappingStepMeta.demoValues,
			[`${ENVIRONMENT_STEP_ID}`]: environmentStepMeta.demoValues,
		});
	};

	render() {
		const { opened, disabled } = this.state;
		const { getPrevStep, getNextStep, step, registries, isRegistriesFetching, prev, value } = this.props;
		const prevStep = getPrevStep();
		const nextStep = getNextStep();

		return (
			<StepLayoutWithLogo
				image={containerImage}
				title={dockerImageStepMeta.title}
				subtitle={dockerImageStepMeta.subtitle}
				imgClassName={styles.mainImg}
				controls={
					<div className={styles.controls}>
						{prevStep && (
							<StepNavigationButton
								onClick={prev}
								disabled={disabled}
								stepName={prevStep.meta.btnText}
								type="prev"
							/>
						)}
						{nextStep && (
							<StepNavigationButton
								type="next"
								formId={step.name}
								disabled={disabled}
								status={disabled ? 'loading' : 'initial'}
								stepName={nextStep.meta.btnText}
							/>
						)}
					</div>
				}
			>
				<div className={styles.wrapper}>
					<div className={styles.content}>
						<div className={styles.formWrapper}>
							<DockerImageForm
								onSubmit={this.handleSubmit}
								formId={step.name}
								initialValues={value}
								onDisable={this.setDisabled}
								handleDemoDeploy={this.handleDemoDeploy}
							/>
						</div>
						<div className={styles.repositoriesWrapper}>
							<RepositoriesList
								items={registries}
								isFetching={isRegistriesFetching}
								onAddClick={() => this.setOpened(true)}
							/>
						</div>
					</div>
				</div>
				{opened && <EditRegistry onDismiss={() => this.setOpened(false)} />}
			</StepLayoutWithLogo>
		);
	}
}

export const DockerImageStep = compose<DeployWizardStepComponent>(
	connect((state: AppState) => {
		return {
			registries: state.registries.registries,
			isRegistriesFetching: state.registries.isFetching,
		};
	})
)(DockerImageStepDumb);
