import { TeamMember } from './interfaces';
import api from '@app/apis/api';

// POST /api/user/invite
export const inviteMembersApi = async (inviteMember: { email: string }) =>
	await api.post(`/api/user/invite`, inviteMember);

// GET /api/account/members
export const getAccountMembersApi = async () =>
	await api.get(`/api/account/members`);

// PUT /api/user/{id}
export const updateAccountMemberRoleApi = async ({ id, permission }: Partial<TeamMember>) =>
	await api.put(`/api/user/${id}`, { permission });

// DELETE /api/user/{id}
export const removeAccountMemberApi = async ({ id }: Partial<TeamMember>) =>
	await api.delete(`/api/user/${id}`);

// GET /api/account/seats-amount
export const getAccountSeatsApi = async () =>
	await api.get(`/api/account/seats-amount`);

// POST /api/account/seats-amount
export const saveAccountSeatsApi = async (amount: number) =>
	await api.post(`/api/account/seats-amount`, { amount });
