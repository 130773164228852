import { call, take, put } from 'redux-saga/effects';
import { removeClusterPermissionsUserApi } from '../api';
import { removeClusterPermissionsUser, getClusterMembers, setClusterError } from '../actions';
import { MetaError } from '@app/types/metaError';

export function* watchRemoveClusterPermissionsUser() {
	while (true) {
		const action = yield take('*');
		if (removeClusterPermissionsUser.is(action)) {
			yield call(removeClusterPermissionsUserWorker, action);
		}
	}
}

function* removeClusterPermissionsUserWorker({
	userId,
	clusterId,
	clusterName,
	onFinal,
}: {
	userId: number;
	clusterId: number;
	clusterName: string;
	onFinal: () => void;
}) {
	try {
		yield call(removeClusterPermissionsUserApi, { userId, clusterId });
		yield put(getClusterMembers({ name: clusterName }));
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(setClusterError({ error: new MetaError(meta ? { message: '' } : e, meta) }));
	} finally {
		yield call(onFinal);
	}
}
