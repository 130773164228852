import { call, take, put } from 'redux-saga/effects';
import { destroyClusterApi } from '../api';
import { destroyCluster, setClusterError } from '../actions';
import { navigateTo } from '../../../../utils/navigate-to';
import { CLUSTERS_LIST_URL } from '../../../clusters-list';

export function* watchDestroyCluster() {
	while (true) {
		const action = yield take('*');
		if (destroyCluster.is(action)) {
			yield call(destroyClusterWorker, action);
		}
	}
}

function* destroyClusterWorker({ name, handleResponse }: { name: string; handleResponse: () => void }) {
	try {
		yield call(destroyClusterApi, name);
		yield call(handleResponse);
		yield call(navigateTo(CLUSTERS_LIST_URL.urlTemplate));
	} catch (e) {
		yield call(handleResponse);
		yield put(setClusterError({ error: e }));
	}
}
