import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import styles from './style.module.scss';
import { Registry } from '../Registries';
import { IRegistryIcon, RegistryIcon } from '../../../../shared/icons/RegistryIcon';
import { RemoveItem } from 'src/shared/removeItem';
import { deleteRegistry } from '../../state';
import { useCheckerForAppReadonlyUser } from '@app/hooks';

interface RegistryItemProps extends Registry {
	onEdit: () => void;
}

export const RegistryItem: React.FC<RegistryItemProps> = ({ id, type, onEdit }) => {
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const dispatch = useDispatch();
	const [isShowRemove, setIsShowRemove] = React.useState(false);

	return (
		<div className="registry">
			<div className="registry-main">
				<RegistryIcon type={toLogoName(type)} className={styles.registryIcon} disableTooltip={true} />
				<div className="registry-main-title">{toDisplayName(type)}</div>
			</div>

			<div className="registry-ctrls">
				<Button
					className="btn-three registry-ctrls-edit"
					onClick={isReadonlyUser ? () => {} : onEdit}
					disabled={isReadonlyUser}
				>
					Edit
				</Button>
				<Button
					className="btn-seven registry-ctrls-remove"
					onClick={isReadonlyUser ? () => {} : () => setIsShowRemove(true)}
					disabled={isReadonlyUser}
				>
					Remove
				</Button>
			</div>

			{isShowRemove ? (
				<RemoveItem
					msg={`Are you sure you want to remove ${type}? You can’t undo this action.`}
					onDismiss={() => setIsShowRemove(false)}
					onAgree={() => dispatch(deleteRegistry({ id, handleResponse: () => setIsShowRemove(false) }))}
				/>
			) : null}
		</div>
	);
};

const toLogoName = (type: string): IRegistryIcon => {
	return type.toLowerCase() as IRegistryIcon;
};

const toDisplayName = (type: string): string => {
	switch (type) {
		case 'docker':
			return 'Docker Hub';
		case 'quay':
			return 'Quay.io';
		default:
			return type
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
	}
};
