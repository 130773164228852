import { call, take, put } from 'redux-saga/effects';
import { getClientSecretApi } from '../api';
import { getStripeClientSecret, setStripeClientSecret } from '../actions';

export function* watchGetClientSecret() {
	while (true) {
		const action = yield take('*');
		if (getStripeClientSecret.is(action)) {
			yield call(getClientSecretWorker);
		}
	}
}

function* getClientSecretWorker() {
	try {
		const result = yield call(getClientSecretApi);
		yield put(setStripeClientSecret({ clientSecret: result.clientSecret }));
	} catch (e) {}
}
