import memo from 'memoize-one';

export interface ChartElement {
	datasetIndex: number;
	dataIndex: number;
	color: string;
}

export interface DrawPositions {
	x: number;
	y: number;
	top: number;
	bottom: number;
}

export interface AppearanceParams {
	pointerWidth: number;
	pointerColor: string;
}

interface PluginAppearanceParams extends AppearanceParams {
	draw?: (ctx: HTMLCanvasElement, position: DrawPositions, appearance: AppearanceParams, chart: any) => void;
}

interface MousePositionInChart {
	x: number;
	y: number;
}

export type PointerPosition = MousePositionInChart;

interface PluginHandlers {
	onMove: (chart: any, elements: ChartElement[]) => void;
	onMouseLeave: (chartId: number) => void;
	onMouseIn: (chartId: number) => void;
	afterDraw: (chart: any, drawPosition: MousePositionInChart) => void;
}

interface PluginParams {
	id: string;
	handlers: PluginHandlers;
	appearance: PluginAppearanceParams;
	disabled?: boolean;
}

export class LinePointerPlugin {
	private activeChartId = 0;
	private disabled: boolean;
	private mouseX = 0;
	private mouseY = 0;
	private pointerPosX = 0;
	private chart: any = {};

	constructor(private readonly params: PluginParams) {
		this.disabled = params.disabled || false;
	}

	enable = () => {
		this.disabled = false;
	};

	disable = () => {
		this.disabled = true;
	};

	getPlugin = () => {
		const { id } = this.params;

		return {
			id,
			afterDraw: (chart: any) => {
				this.afterDrawHandler(chart);
			},
			afterInit: (chart: any) => {
				chart.canvas.addEventListener('mousemove', this.mouseMoveHandler(chart));
				chart.canvas.addEventListener('mouseleave', this.mouseLeaveHandler(chart));
				chart.canvas.addEventListener('mouseover', this.mouseOverHandler(chart));
			},
		};
	};

	private mouseOverHandler = (chart: any) => (e: MouseEvent) => {
		this.setActiveChart(chart.id);
		this.params.handlers.onMouseIn(chart.id);
	};

	private setActiveChart = (id: number) => (this.activeChartId = id);

	private mouseLeaveHandler = (chart: any) => (e: MouseEvent) => {
		this.params.handlers.onMouseLeave(chart.id);
	};

	private mouseMoveHandler = (chart: any) => (e: MouseEvent) => {
		if (this.disabled) return;
		this.mouseX = e.clientX;
		this.mouseY = e.clientY;

		if (chart) {
			const elements = (chart as any).getElementsAtXAxis(e);
			this.params.handlers.onMove(chart, this.getActiveDatasetsMetadata(elements));
		}
	};

	private afterDrawHandler = (chart: any) => {
		if (this.disabled) return;

		let { x, y } = this.calculateMousePositionInChart(chart);
		const { top, bottom } = chart.chartArea;

		const { id } = chart;
		if (this.activeChartId === id) {
			if (x >= chart.chartArea.right) {
				x = chart.chartArea.right;
			}

			if (x <= chart.chartArea.left) {
				x = chart.chartArea.left;
			}

			this.pointerPosX = x;
		} else {
			x = this.pointerPosX;
		}

		const { pointerColor, pointerWidth, draw } = this.params.appearance;
		if (draw) {
			draw(chart.canvas, { top, bottom, x, y }, { pointerColor, pointerWidth }, chart);
		} else {
			this.drawPointer(chart.canvas, { top, bottom, x });
		}

		if (chart.id === this.activeChartId) {
			this.params.handlers.afterDraw(chart, { x, y });
		}
		this.chart = chart;
	};

	private getActiveDatasetsMetadata = memo(
		(items: { _datasetIndex: number; _index: number; _options: { borderColor: string } }[]): ChartElement[] => {
			return items.map(item => ({
				datasetIndex: item._datasetIndex,
				dataIndex: item._index,
				color: item._options.borderColor,
			}));
		}
	);

	private drawPointer = (canvas: any, drawPosition: { top: number; bottom: number; x: number }): number | void => {
		const { top, bottom, x } = drawPosition;
		if (x === 0) return;

		const { pointerColor, pointerWidth } = this.params.appearance;
		const ctx = canvas.getContext('2d');

		ctx.save();
		ctx.beginPath();
		ctx.moveTo(x, top);
		ctx.lineTo(x, bottom);
		ctx.lineWidth = pointerWidth;
		ctx.strokeStyle = pointerColor;
		ctx.stroke();
		ctx.restore();
	};

	private calculateMousePositionInChart = (chart: any) => {
		const { left, top } = chart.canvas.getBoundingClientRect();

		const x = this.mouseX - left;
		const y = this.mouseY - top;

		return { x, y };
	};
}
