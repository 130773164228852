export const PORT_MAPPING_STEP_ID = 'PORT_MAPPING_STEP';

export const portMappingStepMeta = {
	title: 'Port Mapping',
	subtitle: 'Point Appfleet to your Docker repository',
	btnText: 'Port Mapping',
	tooltipText: 'Port Mapping',
	demoValues: {
		ports: [
			{ private: '8000', public: '80', protocol: 'tcp' },
		],
	},
};
