import React, { useState, useEffect, useCallback } from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ICluster } from '../cluster';
import styles from './styles.module.scss';
import btnsStyles from '@app/styles/buttons.module.scss';
import { Grid } from 'semantic-ui-react';
import { FormikProps } from 'formik';
import classnames from 'classnames';
import { ApiNode, getClusterNodesList, getClusterNodesLoadedStatus } from '@app/data-modules/cluster-nodes';
import { ApiRegion } from '@app/data-modules/regions';
import { AppState } from '@app/reducers';
import { getRegionsByNodes, getRegionsLoadState, getRegionsSelector } from '@app/data-modules/regions/selectors';
import { ClusterMetrics } from './components/ClusterMetrics';
import { metricPeriodOptions } from './metricPeriodOptions';
import { CLUSTER_URL, pullAndDeployClusterAction, resetPullAndDeployClusterAction } from '../../state';
import { ClusterPageLayout } from '../layouts/ClusterPageLayout';
import { LdsSpinner } from '@app/shared/ldsSpinner';
import { ClusterInfoTitle } from './components/ClusterInfoTitle';
import {
	ApiActivityLog,
	getActivityLogItemsSelector,
	getActivityLogLoadedStateSelector,
} from '@app/data-modules/activity-log';
import {
	ClusterMetric,
	getClusterNodesMetricAction,
	LoadingStatus,
	MetricPeriods,
} from '@app/data-modules/cluster-metrics';
import { NodeLocationsCard } from '../shared/NodeLocationsCard';
import { SelectWithSearch } from '@app/shared/selectWithSearch';
import { AppRequestButton } from '@app/shared/AppRequestButton';
import { getWizardStatus } from '@app/utils/getWizardStatus';
import { PullAndDeployState } from '@app/modules/deploy-wizard/state/state';
import { OverviewCard } from '@app/modules/cluster/components/clusterInfo/cards/OverviewCard';
import { ImageCard } from '@app/modules/cluster/components/clusterInfo/cards/ImageCard';
import { ApiNodeMetricName, MetricValue } from '@app/data-modules/cluster-metrics/entity';

interface InputProps {
	cluster: ICluster;
	isReadonlyCluster: boolean;
}

interface ClusterInfoProps extends InputProps {
	nodes: ApiNode[];
	regions: ApiRegion[];
	metric: ClusterMetric['metricForPeriod']['metric'];
	metricMaxValues: ClusterMetric['metricForPeriod']['metricMaxValues'];
	getMetrics: (clusterName: string, period: MetricPeriods) => void;
	logs: ApiActivityLog[];
	isLogsLoaded: boolean;
	isNodesLoaded: boolean;
	isRegionsLoaded: boolean;
	pullAndDeploy: (clusterName: string) => void;
	resetPullAndDeployState: (clusterName: string) => void;
	pullAndDeployState: PullAndDeployState;
	nodeMetricLoadingStatus: LoadingStatus | null;
	latestMetric: ClusterMetric['latest']['metric'];
}

interface State {
	metricPeriod: MetricPeriods;
	showRecreateWarning: boolean;
}

export const DEFAULT_METRIC_PERIOD = '6h' as MetricPeriods;

const defaultClusterDescription =
	'Welcome to your cluster. You can use "Pull and Deploy" to deploy new versions of your software.';

const ClusterInfoComponent: React.FC<ClusterInfoProps & FormikProps<State>> = ({
	cluster,
	getMetrics,
	resetPullAndDeployState,
	nodes,
	regions,
	metric,
	logs,
	isLogsLoaded,
	isNodesLoaded,
	isRegionsLoaded,
	pullAndDeploy,
	pullAndDeployState,
	nodeMetricLoadingStatus,
	latestMetric,
	metricMaxValues,
	isReadonlyCluster,
}) => {
	const [metricPeriod, setMetricPeriod] = useState(DEFAULT_METRIC_PERIOD);
	const [clusterMetric, setClusterMetric] = useState<Record<string, Record<ApiNodeMetricName, MetricValue>> | null>(
		null
	);

	const handleSelect = (_: any, value: MetricPeriods) => {
		if (metricPeriod !== value) {
			getMetrics(cluster.name, value);
		}
		setMetricPeriod(value);
	};

	const onResetState = useCallback(() => {
		resetPullAndDeployState(cluster.name);
	}, [resetPullAndDeployState, cluster.name]);

	useEffect(() => {
		const clusterMetric = metric ? metric[metricPeriod] : null;
		setClusterMetric(clusterMetric);
	}, [metric, metricPeriod]);

	return (
		<ClusterPageLayout className={styles.clusterInfo}>
			<ClusterPageLayout.Head
				className={styles.clusterHead}
				titleSection={
					<ClusterInfoTitle
						accColor={cluster.color}
						title={cluster.name}
						subtitle={cluster.description || defaultClusterDescription}
						errorMsg={pullAndDeployState[cluster.name]?.errorMessage || ''}
					/>
				}
				actionSection={
					<AppRequestButton
						className={classnames(btnsStyles.btnOne, styles.clusterInfo__dropdownBtn)}
						statusText={{
							initial: 'Pull and Deploy',
							loading: 'Deploying',
							success: 'Deployed',
							error: 'Failed',
						}}
						status={getWizardStatus(pullAndDeployState[cluster.name]?.deploy) || 'initial'}
						onClick={e => {
							e.preventDefault();
							pullAndDeploy(cluster.name);
						}}
						onResetState={onResetState}
						disabled={isReadonlyCluster}
					/>
				}
			/>
			<ClusterPageLayout.Content>
				<Grid columns={2}>
					<Grid.Column>
						<div className={styles.cardsWrapper}>
							<OverviewCard
								clusterId={cluster.id}
								nodes={nodes}
								regions={regions}
								loading={!isLogsLoaded || !isNodesLoaded || !isRegionsLoaded}
							/>
							<ImageCard
								cluster={cluster}
								loading={!cluster.name || !cluster.currentVersion}
								logs={logs}
							/>
						</div>
					</Grid.Column>
					<Grid.Column>
						<NodeLocationsCard
							regions={regions}
							cluster={cluster}
							nodes={nodes}
							latestMetric={latestMetric}
							isFetching={!isRegionsLoaded || !isNodesLoaded}
						/>
					</Grid.Column>
				</Grid>
				<div className={styles.metricsSection}>
					<div className={styles.metricsSection__head}>
						<span className={styles.title}>Analytics</span>
						<SelectWithSearch
							disabledInput={true}
							selectValue={metricPeriod}
							selectName={'metricPeriod'}
							handleChange={handleSelect}
							options={metricPeriodOptions}
							wrapClassname={styles.selectWrapper}
							selectClassname={classnames(styles.selectWrapper__select, {
								[styles.hidden]: nodeMetricLoadingStatus !== 'FINISHED',
							})}
							fakeInputClassname={styles.selectWrapper__fakeInput}
							labelClassname={styles.selectWrapper__selectLabel}
							optionsClassname={styles.selectWrapper__option}
						/>

						{nodeMetricLoadingStatus === 'LOADING' && <LdsSpinner />}
					</div>
					<ClusterMetrics
						nodes={nodes}
						regions={regions}
						metric={clusterMetric}
						metricMaxValues={metricMaxValues?.[metricPeriod]}
						period={metricPeriod}
						disabled={nodeMetricLoadingStatus !== 'FINISHED' || clusterMetric === null}
						nodeMetricLoadingStatus={nodeMetricLoadingStatus}
					/>
				</div>
			</ClusterPageLayout.Content>
		</ClusterPageLayout>
	);
};

export const ClusterInfo = compose<React.ComponentType<InputProps>>(
	connect(
		(state: AppState, ownProps: InputProps) => {
			const { cluster } = ownProps;

			const nodes = getClusterNodesList(state, cluster.name) || [];
			const regions = getRegionsSelector(state);
			const clusterMetric = state.metric[cluster.name];

			return {
				nodes,
				regions: nodes && nodes.length && regions && regions.length ? getRegionsByNodes(state, nodes) : [],
				metric: clusterMetric?.metricForPeriod?.metric,
				metricMaxValues: clusterMetric?.metricForPeriod?.metricMaxValues,
				latestMetric: clusterMetric?.latest,
				logs: getActivityLogItemsSelector(state, cluster.name),
				isLogsLoaded: getActivityLogLoadedStateSelector(state, cluster.name),
				isNodesLoaded: getClusterNodesLoadedStatus(state, cluster.name),
				isRegionsLoaded: getRegionsLoadState(state),
				nodeMetricLoadingStatus: clusterMetric?.metricForPeriod?.loadingStatus,
				pullAndDeployState: state.wizard.pullAndDeployState,
			};
		},
		(dispatch: Dispatch) => {
			return {
				getMetrics: (clusterName: string, period: MetricPeriods) => {
					dispatch(getClusterNodesMetricAction({ clusterName, period }));
				},
				pullAndDeploy: (clusterName: string) =>
					dispatch(
						pullAndDeployClusterAction({ clusterName, url: CLUSTER_URL.format({ name: clusterName }) })
					),
				resetPullAndDeployState: (clusterName: string) =>
					dispatch(resetPullAndDeployClusterAction({ clusterName })),
			};
		}
	)
)(ClusterInfoComponent);
