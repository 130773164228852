import api from '@app/apis/api';
import { CreateClusterDTO, CreateNodesDTO } from '@app/modules/deploy-wizard/interfaces';

// POST /api/cluster
export const createClusterRequest = async (payload: CreateClusterDTO) =>
	await api.post('/api/cluster', payload);

	// POST /api/cluster/{clusterName}/node
export const createClusterNodesRequest = async (clusterName: string, payload: CreateNodesDTO) =>
	await api.post(`/api/cluster/${clusterName}/node`, payload);


export enum ImageValidationStatus {
	SUCCESS = 'success',
	NOT_FOUND = 'not-found',
	UNAUTHORIZED = 'unauthorized',
}

// GET /api/registry/image/validate
export const validateImageRequest = async (
	image: string,
	tag?: string,
): Promise<{ status: ImageValidationStatus }> =>
	await api.get('/api/registry/image/validate', {
		params: {
			image,
			tag,
		},
	});

// GET /api/cluster/{name}
export const getClusterRequest = async (name: string) =>
	await api.get(`/api/cluster/${encodeURIComponent(name)}`);
