import { customYup as yup, yupExceptions } from '@app/utils/customYup';

export const schema = yup.object().shape({
	name: yup
		.string()
		.max(50)
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isInvalidHostname.regex, yupExceptions.isInvalidHostname.message('Cluster name'))
		.required('Cluster name is required.'),
	description: yup.string().matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message),
});
