import React from 'react';
import { Button, Icon, SemanticICONS } from 'semantic-ui-react';
import { deployWizardModalApi } from '@app/modals';
import { useBillingInfoSubscriptionStatus, useCheckerForAppReadonlyUser } from '@app/hooks';

interface BtnDeployNewClusterProps {
	className?: string;
	btnText?: string;
	iconName?: SemanticICONS;
}

export const BtnDeployNewCluster: React.FC<BtnDeployNewClusterProps> = ({
	className = 'deploy-cluster-btn btn-one',
	btnText = 'Deploy a new cluster',
	iconName,
}) => {
	const { subscriptionValid } = useBillingInfoSubscriptionStatus();
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	return (
		<Button
			className={className}
			onClick={isReadonlyUser ? () => {} : deployWizardModalApi.openModal}
			disabled={!subscriptionValid || isReadonlyUser}
		>
			{btnText}
			{iconName && <Icon name={iconName} />}
		</Button>
	);
};
