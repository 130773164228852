import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import '../styles/flag-icons.min.css';
import '../styles/global.module.scss';
import history from '../history';
import { NavMenu } from './nav-menu';
import { NEW_PASSWORD_URL, NewPassword, RESET_PASS_URL, ResetPass, Signin, SIGNIN_URL } from './user-sign-in';
import {
	// registraion is temporary disabled (gh-928)
	// Account,
	ACCOUNT_BILLING_URL,
	ACCOUNT_SETTINGS_URL,
	ACCOUNT_SIGNUP_URL,
	USER_SIGNUP_URL,
	AccountSettings,
	CHECK_EMAIL_URL,
	CHECK_EMAIL_AFTER_RESET_URL,
	CheckEmail,
} from './account';
import { CLUSTERS_LIST_URL, ClustersList } from './clusters-list';
import { Cluster, CLUSTER_URL } from './cluster';
import { Registries, REGISTRIES_URL } from './registries';
import { NOT_FOUND_URL, NotFound } from './not-found';
import { Referral, REFERRAL_URL } from './referral';
import { AppRoute } from '../routes';
import { ModalContainer } from '@app/modals';
import { VERIFY_EMAIL_URL } from './account/state/url';
import { IntercomNotificationDisabled } from './intercom-notification-disabled';
import { Team, TEAM_URL } from './team';
import styles from './style.module.scss';
import { AccountBilling } from './account/components/accountBilling';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import type { AppState } from '@app/reducers';
import api from '@app/apis/api';
import { ROOT_URL } from '../urls';
import queryString from 'query-string';
import { setSigninToken } from '@app/modules/user-sign-in';
import { useCheckerForAppReadonlyUser, useCheckerIsBiller } from '@app/hooks';
import { DisabledRegistration } from '@app/modules/disabledRegistration';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

interface AppComponentProps {}

interface RouteDescription {
	path: string;
	auth: boolean;
	Content?: any;
	ReadonlyContent?: any;
	props?: any;
	exact?: boolean;
}

const routes: RouteDescription[] = [
	// { path: SIGNIN_URL.urlTemplate, Content: Signin, exact: true },
	{ path: RESET_PASS_URL.urlTemplate, Content: ResetPass, exact: true, auth: false },
	// registraion is temporary disabled (gh-928)
	// { path: ACCOUNT_SIGNUP_URL.urlTemplate, Content: Account, exact: true, auth: false },
	// { path: USER_SIGNUP_URL.urlTemplate, Content: Account, exact: true, auth: false },
	{ path: ACCOUNT_SIGNUP_URL.urlTemplate, Content: DisabledRegistration, exact: true, auth: false },
	{ path: USER_SIGNUP_URL.urlTemplate, Content: DisabledRegistration, exact: true, auth: false },
	{ path: CHECK_EMAIL_URL.urlTemplate, Content: CheckEmail, exact: true, auth: false },
	{ path: CHECK_EMAIL_AFTER_RESET_URL.urlTemplate, Content: CheckEmail, exact: true, auth: false },
	{ path: VERIFY_EMAIL_URL.urlTemplate, Content: () => null, exact: true, auth: false },
	{ path: NEW_PASSWORD_URL.urlTemplate, Content: NewPassword, exact: true, auth: false },

	{ path: CLUSTERS_LIST_URL.urlTemplate, Content: ClustersList, auth: true },
	{ path: CLUSTER_URL.urlTemplate, Content: Cluster, auth: true },
	{ path: REGISTRIES_URL.urlTemplate, Content: Registries, auth: true, exact: true },
	{ path: REFERRAL_URL.urlTemplate, Content: Referral, auth: true, exact: true },
	{ path: ACCOUNT_SETTINGS_URL.urlTemplate, Content: AccountSettings, auth: true, exact: true },
	{ path: ACCOUNT_BILLING_URL.urlTemplate, Content: AccountBilling, auth: true },
	{
		path: NOT_FOUND_URL.urlTemplate,
		Content: NotFound,
		auth: true,
		exact: true,
		props: { className: styles.notFoundLayout },
	},
	{ path: TEAM_URL.urlTemplate, Content: Team, ReadonlyContent: NotFound, auth: true, exact: true },
];

export const App: React.FC<AppComponentProps> = () => {
	const dispatch = useDispatch();
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const { isBiller } = useCheckerIsBiller();
	// allow admin to bypass login page with proper queryString supportk
	const location = useSelector((state: AppState) => state.router.location);
	const match = ROOT_URL.match(location, true);
	if (match.isMatched) {
		const query = queryString.parse(location.search);
		if (query.supportk) {
			api.setToken(query.supportk as string);
			api.setSupportk(query.supportk as string);
		}
	}

	const token = window.localStorage.getItem('token');
	const supportk = window.localStorage.getItem('supportk');

	if (token) {
		api.setToken(token);
	}
	if (supportk) {
		api.setSupportk(supportk);
	}

	useEffect(() => {
		if (token) {
			dispatch(setSigninToken({ token }));
		}
	}, [token, dispatch]);

	return (
		<>
			<Elements
				stripe={stripePromise}
				options={{
					fonts: [
						{
							cssSrc:
								'https://fonts.googleapis.com/css?family=Raleway:400,500,600,700&display=swap&subset=latin',
						},
					],
				}}
			>
				<Router history={history}>
					<ConnectedRouter history={history}>
						<Switch>
							<AppRoute auth={false} path={'/'} exact={true}>
								<Redirect to={token ? CLUSTERS_LIST_URL.urlTemplate : SIGNIN_URL.urlTemplate} />
							</AppRoute>

							<AppRoute auth={false} path={SIGNIN_URL.urlTemplate} exact={true}>
								{token ? <Redirect to={CLUSTERS_LIST_URL.urlTemplate} /> : <Signin />}
							</AppRoute>

							{routes.map((route, key) => {
								const { Content, ReadonlyContent, props = {}, path, exact, auth } = route;
								return (
									<AppRoute key={key} auth={auth} path={path} exact={exact}>
										<>
											<Grid.Column>{auth ? <NavMenu /> : null}</Grid.Column>

											<Grid style={{ flex: 1, marginTop: 0, marginBottom: '100px' }}>
												<Grid.Row style={{ padding: 0 }}>
													<Grid.Column style={{ flexGrow: 100, padding: '0 auto' }}>
														{isReadonlyUser && !isBiller && ReadonlyContent ? (
															<ReadonlyContent />
														) : (
															<Content {...props} />
														)}
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</>
									</AppRoute>
								);
							})}
							<Route path="*" render={() => <Redirect to={NOT_FOUND_URL.urlTemplate} />} />
						</Switch>
					</ConnectedRouter>
				</Router>
				<ModalContainer />
				<IntercomNotificationDisabled />
			</Elements>
		</>
	);
};
