import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { baseURL } from '@app/apis/api';
import md5 from 'md5';

interface Props {
	email: string;
	className: string;
	ownAvatarColor?: string | null;
}

const BASE_AVATAR_COLOR = '0c5ce5';

export const UserAvatar: React.FC<Props> = ({ email, className, ownAvatarColor = BASE_AVATAR_COLOR }) => {
	const ownAvatarURL = encodeURIComponent(`${baseURL}/api/avatar/cluster-${ownAvatarColor}.png`);
	const emailHash = md5(email.trim().toLowerCase());

	return (
		<div className={classnames(styles.userAvatar, className)}>
			<img
				src={`https://www.gravatar.com/avatar/${emailHash}?d=${ownAvatarURL}`}
				alt=""
				className={styles.userAvatar__img}
			/>
		</div>
	);
};
