import { call, take } from 'redux-saga/effects';
import { resetPasswordApi } from '../api';
import { resetPassword } from '../actions';
import { ResetPassValues } from '@app/modules/user-sign-in';
import { navigateTo } from '@app/utils/navigate-to';
import { CHECK_EMAIL_AFTER_RESET_URL } from '@app/modules/account';

export function* watchResetPassword() {
	while (true) {
		const action = yield take('*');
		if (resetPassword.is(action)) {
			yield call(resetPasswordWorker, {
				email: action.email,
			});
		}
	}
}

function* resetPasswordWorker(formValues: ResetPassValues) {
	try {
		yield call(resetPasswordApi, formValues);
	} catch (e) {
	} finally {
		yield call(navigateTo(CHECK_EMAIL_AFTER_RESET_URL.urlTemplate, { email: formValues.email, from: 'reset' }));
	}
}
