import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { Table, TableBodyProps } from 'semantic-ui-react';

interface Props extends TableBodyProps {}

export const Body = (props: Props) => {
	const { className, ...rest } = props;

	return <Table.Body className={classnames(styles.appTable__body, props.className)} {...rest}></Table.Body>;
};
