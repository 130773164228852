import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import moment from 'moment';
import { Grid } from 'semantic-ui-react';
import styles from './style.module.scss';
import { AppCard, Head, Title, SectionContainer, Section } from '@app/shared/AppCard';
import { DeployWizardStepProps, ComposedWizardData } from '../../interfaces';
import { StepLayoutWithLogo } from '@app/modules/deploy-wizard/components/layouts/DefaultStepLayout/StepLayoutWithLogo';
import deploymentImg from '@images/deployment.png';
import { AppState } from '@app/reducers';
import { createTerminal } from '@app/utils/createTerminal';
import { ICluster } from '@app/modules/cluster';
import { AppInfoBlock } from '@app/shared/AppInfoBlock';
import { baseURL } from '@app/apis/api';
import { REGIONS_STEP_ID } from '@app/modules/deploy-wizard/steps/RegionsStep';
import api from '@app/apis/api';
import { WizardProgressBar } from '@app/shared/wizardProgressBar';

interface Props extends DeployWizardStepProps {
	onMount: (payload: ComposedWizardData) => void;
	completed: boolean;
	token: string;
	logs: {
		id: string;
		hostName: string;
	};
	selectedCluster?: ICluster;
	accountPublicId: string;
}

type SocketDataModuleType = 'node' | 'router' | 'docker';

interface SocketData {
	id: string;
	timestamp: number;
	type: string;
	data: {
		timestamp: number;
		clusterId: number;
		nodeId: number;
		version: number;
		module: SocketDataModuleType;
		failed: boolean;
		message: string;
	};
}

enum TerminalColors {
	Green = '\x1b[0;32m',
	Blue = '\x1b[0;34m',
	Purple = '\x1b[0;35m',
	Black = '\x1b[0;30m',
	Red = '\x1b[31m',
}

interface StreamLogsResponse {
	node: number;
	router: number;
	docker: number;
}

interface ExpectedResponsesAmount extends StreamLogsResponse {
	total: number;
}

interface NodeStateMap extends ExpectedResponsesAmount {
	nodeFailed: boolean;
}

interface NodesMap {
	[nodeId: string]: NodeStateMap;
}

export type ProgressBarStatus = 'Deploying' | 'Done' | 'Incomplete' | 'Failed';

const CustomProgressBar: React.FC<{ totalSteps: number; step: number }> = ({ totalSteps, step }) => {
	return (
		<div className={styles.progressBarWrapper}>
			<WizardProgressBar
				progressBarFillAnimation={styles.progressBarFill}
				progressBarFill="repeating-linear-gradient( -45deg,#1A77E8, #1A77E8 10px, #0853d3 10px,#0853d3 20px )"
				progressBarHeight="12px"
				steps={totalSteps}
				initialStep={0}
				activeStep={step}
				progressBarBackgroundFill="#eaf6ff"
				progressBarBackgroundHeight="12px"
				className={styles.progressBar}
			/>
		</div>
	);
};

const DeployStepDumb: React.FC<Props> = ({
	selectedCluster,
	stepValues,
	step: { meta, name: stepName },
	logs: { id } = { id: 'TEST-ID' },
	completed,
	accountPublicId,
}) => {
	const token = api.getToken();
	const [nodesMap, setNodesMap] = useState<NodesMap>({});
	const [terminal, setTerminal] = useState<Terminal | null>(null);
	const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);
	const [title, setTitle] = useState('');
	const [progressBarStatus, setProgressBarStatus] = useState<ProgressBarStatus>('Deploying');
	const [progressBarStep, setProgressBarStep] = useState(0);
	const [responseExpected, setResponseExpected] = useState<ExpectedResponsesAmount>();
	const [progressBarTotalSteps, setProgressBarTotalSteps] = useState(0);
	const [clusterName] = useState(stepValues.NAME_AND_DESCRIPTION_STEP?.name || selectedCluster?.name || '');
	const [nodeSocketData, setNodeSocketData] = useState<SocketData | null>(null);

	// calculate current progressBarStep and check deploy status, harvest failed nodes
	useEffect(() => {
		const { step, nodeStates } = Object.entries(nodesMap).reduce(
			({ step, nodeStates }, nodeMap) => {
				const updStep = step + (nodeMap[1].node ?? 0) + (nodeMap[1].router ?? 0) + (nodeMap[1].docker ?? 0);
				const updNodeStates = [...nodeStates, { id: nodeMap[0], failed: nodeMap[1].nodeFailed }];
				return {
					step: updStep,
					nodeStates: updNodeStates,
				};
			},
			{ step: 0, nodeStates: [] as any[] }
		);

		// setup current step
		setProgressBarStep(step);
		// setup ProgressBarStatus one of type ProgressBarStatus
		if (
			nodeStates.length &&
			Object.values(nodeStates).every(node => node.failed === true) &&
			progressBarTotalSteps &&
			step === progressBarTotalSteps
		) {
			setProgressBarStatus('Failed');
		} else if (
			nodeStates.length &&
			Object.values(nodeStates).some(node => node.failed === true) &&
			progressBarTotalSteps &&
			step === progressBarTotalSteps
		) {
			setProgressBarStatus('Incomplete');
		} else if (
			nodeStates.length &&
			Object.values(nodeStates).every(node => node.failed === false) &&
			progressBarTotalSteps &&
			step === progressBarTotalSteps
		) {
			setProgressBarStatus('Done');
		}
	}, [nodesMap, progressBarTotalSteps]);

	// set total expected steps
	useEffect(() => {
		if (responseExpected) {
			let result = 0;
			const values: { amount: number }[] = Object.values(stepValues[REGIONS_STEP_ID].nodes);
			for (const { amount } of values) {
				result += amount;
			}
			setProgressBarTotalSteps(result * responseExpected.total);
		}
	}, [responseExpected, stepValues]);

	// setup title
	useEffect(() => {
		if (selectedCluster?.currentVersion) {
			const { tag, image } = selectedCluster.currentVersion;
			const title = meta.title.replace(/@tag@/, tag).replace(/@dockerImage@/, image);
			setTitle(title);
		}
	}, [meta, selectedCluster]);

	// handle data received from socket per node
	useEffect(() => {
		if (nodeSocketData && responseExpected) {
			if (nodeSocketData.data.failed === false) {
				// if node is not failed then increment by 1 related module counter of current node
				setNodesMap(nodesMap => ({
					...nodesMap,
					[nodeSocketData.data.nodeId]: {
						...(nodesMap[nodeSocketData.data.nodeId] || {}),
						[nodeSocketData.data.module]:
							((nodesMap[nodeSocketData.data.nodeId] || {})[nodeSocketData.data.module] || 0) + 1,
						nodeFailed: nodeSocketData.data.failed,
					},
				}));
			} else if (nodeSocketData.data.failed === true) {
				// if failed consider as all steps are done for this node
				setNodesMap(nodesMap => ({
					...nodesMap,
					[nodeSocketData.data.nodeId]: {
						...(nodesMap[nodeSocketData.data.nodeId] || {}),
						node: responseExpected.node,
						router: responseExpected.router,
						docker: responseExpected.docker,
						total: responseExpected.total,
						nodeFailed: nodeSocketData.data.failed,
					},
				}));
			}
		}
	}, [nodeSocketData, responseExpected]);

	// handle socket response, write to term
	useEffect(() => {
		if (completed && terminal && socket) {
			socket.on('appfleet.api.v1.cluster.Log', (data: SocketData) => {
				if (!data) return;
				// set received socket data to handle it within separate useEffect
				setNodeSocketData(data);

				terminal.writeln(
					`${getTerminalColor({ module: data.data.module, failed: data.data.failed })}${moment(
						Number(data.data.timestamp * 1000)
					).format('YYYY-MM-DD HH:mm:ss')}:[${data.data.module || ''}] ${TerminalColors.Black} ${
						data.data.message || ''
					}`
				);
			});
		}
	}, [socket, terminal, completed]);

	// open socket for HostName
	useEffect(() => {
		if (clusterName && completed && !socket) {
			const socketBaseAddr = (
				process.env.REACT_APP_API_URL || 'wss://api-appfleet-com-staging.herokuapp.com'
			).replace('https://', 'wss://');

			// 2 is used by default because when we create a new cluster it have a version 1.
			// After that we add new nodes and this action bumps version to 2.
			// For new cluster version=1 will be empty and deployment logs will be written to v2
			const clusterVersion = selectedCluster?.currentVersion?.version;
			const socket = io(`${socketBaseAddr}/api/v1/ws/cluster/${clusterName}`, {
				transports: ['websocket'],
				query: { token, version: clusterVersion },
			});
			socket.emit('appfleet.api.v1.cluster.StreamLogs', (response: StreamLogsResponse) => {
				setResponseExpected({
					node: response.node,
					router: response.router,
					docker: response.docker,
					total: response.node + response.router + response.docker,
				});
			});
			setSocket(socket);
		}
	}, [socket, setSocket, token, selectedCluster, completed, clusterName]);

	// create terminal instance on did mount
	useEffect(() => {
		if (id && !terminal) {
			const terminal = createTerminal();
			const fitAddon = new FitAddon();
			terminal.loadAddon(fitAddon);
			let terminalElem = document.getElementById(`${id}-container`)!;
			terminal.open(terminalElem);
			fitAddon.fit();
			setTerminal(terminal);
		}
	}, [id, terminal]);

	// close socket on unmount
	useEffect(() => {
		return () => {
			if (socket) {
				socket.close();
			}
		};
	}, [socket]);

	return (
		<StepLayoutWithLogo
			title={title}
			subtitle={meta.subtitle}
			image={deploymentImg}
			imgClassName={styles.stepLayoutImg}
			imgBlockClassName={styles.stepLayoutImgBlock}
			contentClassName={styles.stepLayoutContentBlock}
		>
			<Grid columns={1} className={styles.gridWrapper}>
				<Grid.Row>
					<Grid.Column>
						<div className={styles.wrapper}>
							{/* progressBarTotalSteps || 20 - to avoid first iteration when  progressBarTotalSteps and progressBarStep is 0 and equal*/}
							<CustomProgressBar totalSteps={progressBarTotalSteps || 20} step={progressBarStep} />
							<AppCard className={styles.card}>
								<Head>
									<Title text="" />
									<AppInfoBlock
										status={progressBarStatus}
										icon={
											<img
												src={`${baseURL}/api/avatar/cluster-${
													selectedCluster?.color || '0c5ce5'
												}.png`}
												alt="avatar"
											/>
										}
										clusterName={`${clusterName}-${accountPublicId}.${process.env.REACT_APP_HOSTNAME_DOMAIN}`}
									/>
								</Head>
								<SectionContainer>
									<Section className={styles.cardSection}>
										<div id={`${id}-container`} className={styles.logsContainer} />
									</Section>
								</SectionContainer>
							</AppCard>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</StepLayoutWithLogo>
	);
};

export const DeployStep = compose<React.ComponentType<DeployWizardStepProps>>(
	connect((store: AppState) => {
		const { completed, cluster: selectedCluster } = store.wizard.deploy;
		const token = api.getToken();
		const { publicId: accountPublicId } = store.account.info;
		return { completed, token, accountPublicId, selectedCluster };
	})
)(DeployStepDumb);

const getTerminalColor = ({ module, failed }: { module?: SocketDataModuleType; failed?: boolean }) => {
	if (failed === true) {
		return TerminalColors.Red;
	}
	switch (module) {
		case 'node':
			return TerminalColors.Green;
		case 'router':
			return TerminalColors.Blue;
		case 'docker':
			return TerminalColors.Purple;
		default:
			return TerminalColors.Black;
	}
};
