import { AccountState } from './state';
import { Action } from 'redux';
import {
	clearAccountError,
	setAccountBillingInfo,
	setAccountError,
	setAccountSettingsUserInfo,
	setAccountBillingInfoHasPayment,
	redeemCoupon,
	redeemCouponFailed,
	redeemCouponSucceed,
	saveAccountBillingInfo,
	clearAccountBillingError,
} from './actions';
import { AccountInfo, UserInfo, PaymentInfo, AccountBillingInfo, AccounBillingCoupon } from './interfaces';
import { setAccountClusters, setAccountBandwidthMetrics } from './actions';
import { AccountBandwidth } from '../components/accountBilling/components/billingBandwidthChart/billingBandwidthChart';
import {
	setPaymentInfo,
	setAccountInfo,
	setAccountBillingError,
	setAccountBillingInvoices,
	setAccountBillingInvoicesError,
} from './actions';

const INITIAL_STATE = {
	info: {} as AccountInfo,
	settings: {} as UserInfo,
	paymentInfo: [] as PaymentInfo,
	isPaymentInfoLoaded: false,
	billingInfo: {
		hasPaymentMethod: true,
		isLoaded: false,
	} as AccountBillingInfo,
	billingInvoices: [],
	billingInvoicesError: { message: '' },
	billingInvoicesLoading: true,
	token: '',
	billingCoupon: {
		coupon: {} as AccounBillingCoupon,
		request: {
			started: false,
			finished: false,
		},
		error: {
			message: '',
		},
	},
	accountClusters: [],
	accountBandwidth: {} as AccountBandwidth,
};

export function accountReducer(state: AccountState = INITIAL_STATE, action: Action): AccountState {
	if (setAccountInfo.is(action)) {
		return {
			...state,
			info: action.payload,
		};
	}

	// clear error when save request starts
	if (saveAccountBillingInfo.is(action)) {
		return {
			...state,
			accountBillingError: undefined,
		};
	}

	if (setAccountBillingInfo.is(action)) {
		return {
			...state,
			billingInfo: {
				...state.billingInfo,
				...action.billingInfo,
				isLoaded: true,
			},
			accountBillingError: undefined,
		};
	}

	if (setAccountBillingInfoHasPayment.is(action)) {
		return {
			...state,
			billingInfo: {
				...state.billingInfo,
				hasPaymentMethod: action.hasPaymentMethod,
			},
		};
	}

	if (setAccountError.is(action)) {
		return {
			...state,
			error: action.error,
		};
	}

	if (setAccountSettingsUserInfo.is(action)) {
		return {
			...state,
			settings: {
				...state.settings,
				...action,
			},
		};
	}

	if (setAccountBillingError.is(action)) {
		return { ...state, accountBillingError: action.error, billingInfo: { ...state.billingInfo, isLoaded: true } };
	}

	if (setPaymentInfo.is(action)) {
		return { ...state, paymentInfo: action.paymentInfo, isPaymentInfoLoaded: true };
	}

	if (setAccountBillingInvoices.is(action)) {
		return { ...state, billingInvoices: action.billingInvoices, billingInvoicesLoading: false };
	}

	if (setAccountBillingInvoicesError.is(action)) {
		return {
			...state,
			billingInvoicesError: action.error,
		};
	}

	if (redeemCoupon.is(action)) {
		return {
			...state,
			billingCoupon: {
				...state.billingCoupon,
				coupon: INITIAL_STATE.billingCoupon.coupon,
				request: {
					...state.billingCoupon.request,
					started: true,
					finished: INITIAL_STATE.billingCoupon.request.finished,
				},
				error: INITIAL_STATE.billingCoupon.error,
			},
		};
	}

	if (redeemCouponFailed.is(action)) {
		return {
			...state,
			billingCoupon: {
				...state.billingCoupon,
				request: {
					...state.billingCoupon.request,
					started: INITIAL_STATE.billingCoupon.request.started,
					finished: true,
				},
				error: action.error,
			},
		};
	}

	if (redeemCouponSucceed.is(action)) {
		return {
			...state,
			billingCoupon: {
				...state.billingCoupon,
				coupon: action.coupon,
				request: {
					...state.billingCoupon.request,
					started: true,
					finished: true,
				},
			},
		};
	}

	if (setAccountClusters.is(action)) {
		return {
			...state,
			accountClusters: action.accountClusters
		}
	}

	if (setAccountBandwidthMetrics.is(action)) {
		return {
			...state,
			accountBandwidth: action.accountBandwidth
		}
	}


	if (clearAccountBillingError.is(action)) {
		return { ...state, accountBillingError: undefined };
	}

	if (clearAccountError.is(action)) {
		return { ...state, error: undefined };
	}

	return state;
}
