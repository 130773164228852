import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { InputsList, InputsListRenderableProps } from '../inputs/InputsList';
import { FormikTouched, FormikErrors, getIn, FormikHelpers } from 'formik';
import { selectOptions as persistentSelectedOptions, ProtocolOptions } from './selectOptions';
import { SelectWithSearch } from '../selectWithSearch';
import { InputLabel } from '@app/shared/text/InputLabel';
import { InputErrorText } from '@app/shared/text/InputErrorText';
import { BasicInput } from '@app/shared/inputs/BasicInput';
import { MetaError } from '@app/types/metaError';
import { capitalize } from '@app/utils/capitalizeFirstLetter';

export interface PortMappingFormValue {
	protocol: string;
	public: string;
	private: string;
}

interface Props {
	values: any;
	changeHandler: (e: React.ChangeEvent<any>) => void;
	blurHandler: (e: React.ChangeEvent<any>) => void;
	setFieldValue: FormikHelpers<PortMappingFormValue>['setFieldValue'];
	touched: FormikTouched<{ ports: PortMappingFormValue[] }>;
	errors: FormikErrors<{ ports: PortMappingFormValue[] }>;
	pathToValues: string;
	className?: string;
	disabled?: boolean;
	requestError?: MetaError;
}

interface State {
	availableSelectOptions: typeof persistentSelectedOptions;
}

const defaultProtocol = ProtocolOptions.TCP;

export class PortMappingFieldList extends React.Component<Props, State> {
	state = {
		availableSelectOptions: persistentSelectedOptions,
	};

	renderer = (rendererProps: InputsListRenderableProps<PortMappingFormValue>) => {
		const [publicName, privateName, protocolName] = [
			this.buildInputName(rendererProps.index, 'public'),
			this.buildInputName(rendererProps.index, 'private'),
			this.buildInputName(rendererProps.index, 'protocol'),
		];

		const [publicTouched, privateTouched] = [this.getTouched(publicName), this.getTouched(privateName)];

		const [publicError, privateError] = [
			publicTouched ? this.getError(publicName) : '',
			privateTouched ? this.getError(privateName) : '',
		];

		let [publicValue, privateValue] = [this.getValue(publicName), this.getValue(privateName)];
		const protocolValue = this.getValue(protocolName) || defaultProtocol;

		const reqPublicErr = this.getRequestError(rendererProps.index, 'public');
		const reqPrivateErr = this.getRequestError(rendererProps.index, 'private');

		return (
			<div className={styles.inputsRow}>
				<div className={styles.inputs}>
					<div className={styles.section}>
						<InputLabel text="Public" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput
								className={styles.input}
								onChange={rendererProps.onChange}
								onBlur={rendererProps.onBlur}
								name={publicName}
								value={publicValue}
								error={publicTouched && (!!publicError || !!reqPublicErr)}
								placeholder={'80'}
								disabled={this.props.disabled}
								setFieldValue={this.props.setFieldValue}
							/>
							<InputErrorText className={styles.errorText} errorText={publicError || reqPublicErr} />
						</div>
					</div>

					<div className={styles.section}>
						<InputLabel text="Private" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput
								className={styles.input}
								onChange={rendererProps.onChange}
								onBlur={rendererProps.onBlur}
								name={privateName}
								value={privateValue}
								error={privateTouched && (!!privateError || !!reqPrivateErr)}
								placeholder={'8000'}
								disabled={this.props.disabled}
								setFieldValue={this.props.setFieldValue}
							/>
							<InputErrorText className={styles.errorText} errorText={privateError || reqPrivateErr} />
						</div>
					</div>

					<div className={styles.section}>
						<InputLabel text="Protocol" className={styles.label} />
						<div className={styles.section_inputCol}>
							<SelectWithSearch
								wrapClassname={styles.selectWrapper}
								optionsClassname={styles.selectOptions}
								selectClassname={classnames(styles.select, styles.selectWithSvg)}
								selectName={protocolName}
								selectValue={protocolValue}
								handleChange={(fieldName, value) =>
									rendererProps.onChange({
										target: { name: fieldName, value },
									} as React.ChangeEvent<any>)
								}
								options={persistentSelectedOptions}
								disabledInput={true}
								disabled={this.props.disabled}
							/>
						</div>
					</div>
				</div>

				<div
					className={classnames(styles.trashBtn, {
						[styles.hidden]: this.checkIfTrashBtnHidden(publicValue, privateValue),
					})}
				>
					{rendererProps.trashButton}
				</div>
			</div>
		);
	};

	getRequestError = (index: number, portType: string) => {
		let err = this.props.requestError
			?.get(`ports.${index}.${portType}`)
			?.replace(`"ports[${index}].${portType}" `, '');
		if (err) return capitalize(err);
	};

	checkIfTrashBtnHidden = (publicValue: string, privateValue: string): boolean =>
		this.props.disabled ||
		publicValue === null ||
		typeof publicValue === 'undefined' ||
		publicValue === '' ||
		privateValue === null ||
		typeof privateValue === 'undefined' ||
		privateValue === '';

	buildInputName = (index: number, inputName: string): string => {
		return `${this.props.pathToValues}[${index}].${inputName}`;
	};

	getTouched = (fullName: string): boolean => {
		return !!getIn(this.props.touched, fullName);
	};

	getError = (fullName: string): string | undefined => {
		return getIn(this.props.errors, fullName);
	};

	getValue = (fullName: string): any => {
		return getIn(this.props.values, fullName);
	};

	getTemplateForExtend = (): PortMappingFormValue => {
		return { private: '', public: '', protocol: defaultProtocol };
	};

	shouldComponentUpdate(prevProps: Props) {
		let shouldUpdate = false;

		for (let propKey in prevProps) {
			const prevProp = prevProps[propKey as keyof Props];
			const currentProp = this.props[propKey as keyof Props];

			shouldUpdate = prevProp === currentProp;
		}
		return shouldUpdate;
	}

	extendChecker = (values: PortMappingFormValue[]): boolean => {
		const latestPos = values.length - 1;
		return latestPos > -1 && !!values[latestPos].public && !!values[latestPos].private;
	};

	getValuesIndexesToPrune = (values: PortMappingFormValue[]): number[] => {
		const lastIdx = values.length - 1;
		const emptyPairsIndexesArr = values.reduce((a: number[], v, i: number) => {
			if (v.public === '' && v.private === '') a.push(i);
			return a;
		}, []);

		return emptyPairsIndexesArr.filter(item => item !== lastIdx);
	};

	render() {
		const { className, values, changeHandler, blurHandler, pathToValues } = this.props;
		return (
			<div className={classnames(styles.portMappingFieldList, className)}>
				<InputsList
					values={getIn(values, pathToValues)}
					name={pathToValues}
					renderer={this.renderer}
					blurHandler={blurHandler}
					changeHandler={changeHandler}
					extendable={this.getTemplateForExtend}
					shouldExtendChecker={this.extendChecker}
					shouldPruneChecker={this.getValuesIndexesToPrune}
				/>
			</div>
		);
	}
}
