import React from 'react';
import { CLUSTERS_LIST_URL } from '../modules/clusters-list';
import { SIGNIN_URL, RESET_PASS_URL, NEW_PASSWORD_URL } from '../modules/user-sign-in';
import {
	ACCOUNT_SIGNUP_URL,
	CHECK_EMAIL_URL,
	CHECK_EMAIL_AFTER_RESET_URL,
	ACCOUNT_URL,
	ACCOUNT_BILLING_URL,
	VERIFY_EMAIL_URL,
	USER_SIGNUP_URL
} from '../modules/account';
import { REGISTRIES_URL } from '../modules/registries';
import { REFERRAL_URL } from '../modules/referral';
import { NOT_FOUND_URL } from '../modules/not-found';
import { ACCOUNT_SETTINGS_URL, ACCOUNT_BILLING_INVOICES_URL } from '../modules/account';
import { TEAM_URL } from '../modules/team';
import {
	CLUSTER_URL,
	CLUSTER_SETTINGS_URL,
	CLUSTER_NODES_URL,
	CLUSTER_LOGS_URL,
	CLUSTER_CONSOLE_URL,
	CLUSTER_HEALTHCHECK_URL,
	CLUSTER_ACITVITY_LOG_URL,
} from '../modules/cluster/state/url';

export const usePageTitle = (url: any): void => {
	React.useEffect(() => {
		document.title = createTitle(url);
	}, [url]);
};

const createTitle = (url: any) => {
	const map = {
		[CLUSTERS_LIST_URL.urlTemplate]: 'My Clusters',
		[CLUSTER_URL.urlTemplate]: url.computedMatch.params.name,
		[CLUSTER_SETTINGS_URL.urlTemplate]: url.computedMatch.params.name,
		[CLUSTER_NODES_URL.urlTemplate]: url.computedMatch.params.name,
		[CLUSTER_LOGS_URL.urlTemplate]: url.computedMatch.params.name,
		[CLUSTER_CONSOLE_URL.urlTemplate]: url.computedMatch.params.name,
		[CLUSTER_HEALTHCHECK_URL.urlTemplate]: url.computedMatch.params.name,
		[SIGNIN_URL.urlTemplate]: 'Login',
		[VERIFY_EMAIL_URL.urlTemplate]: 'Login',
		[RESET_PASS_URL.urlTemplate]: 'Reset password',
		[CHECK_EMAIL_URL.urlTemplate]: 'Check email',
		[CHECK_EMAIL_AFTER_RESET_URL.urlTemplate]: 'Check email',
		[ACCOUNT_SIGNUP_URL.urlTemplate]: 'Register',
		[REGISTRIES_URL.urlTemplate]: 'Registries',
		[REFERRAL_URL.urlTemplate]: 'Referral',
		[ACCOUNT_URL.urlTemplate]: 'Account',
		[NOT_FOUND_URL.urlTemplate]: '404',
		[NEW_PASSWORD_URL.urlTemplate]: 'New Password',
		[CLUSTER_ACITVITY_LOG_URL.urlTemplate]: url.computedMatch.params.name,
		[ACCOUNT_SETTINGS_URL.urlTemplate]: 'Account settings',
		[TEAM_URL.urlTemplate]: 'Account team',
		[ACCOUNT_BILLING_URL.urlTemplate]: 'Billing',
		[ACCOUNT_BILLING_INVOICES_URL.urlTemplate]: 'Invoices',
		[USER_SIGNUP_URL.urlTemplate]: "Register",
	};

	return `${map[url.path]} | appfleet`;
};
