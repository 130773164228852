import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { CLUSTER_STASUSES } from '@app/data-modules/healthStatuses';

interface DotElementProps {
	status: CLUSTER_STASUSES;
	onMouseOver?: () => void;
	onMouseLeave?: () => void;
	className?: string;
}

const getDotColor = (status: CLUSTER_STASUSES) => {
	switch (status) {
		case CLUSTER_STASUSES.healthy:
		case CLUSTER_STASUSES.new:
			return styles.dotElement_green;
		case CLUSTER_STASUSES.degraded:
		case CLUSTER_STASUSES.unhealthy:
			return styles.dotElement_orange;
		case CLUSTER_STASUSES.down:
			return styles.dotElement_red;
		default:
			return styles.dotElement_grey;
	}
};

export const DotElement: React.FC<DotElementProps> = ({
	status,
	onMouseOver = () => {},
	onMouseLeave = () => {},
	className = '',
}) => {
	return (
		<div
			className={classnames(getDotColor(status), styles.dotElement, className)}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
		/>
	);
};
