import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	className?: string;
	text: string;
	subtitle: string;
}

export const TextWithSubtitle = (props: Props) => {
	return (
		<div className={classnames(styles.subtitleText, props.className)}>
			<span className={styles.subtitleText__text}>{props.text}</span>
			<span className={styles.subtitleText__subtitle}>{props.subtitle}</span>
		</div>
	);
};
