import React from 'react';
import styles from './styles.module.scss';
import noDomainsIcon from '@icons/icon.no.domains.svg';
import { SvgIcon } from '@app/shared/SvgIcon';

interface NoDomainProps {
	dnsTargetName: string;
}

export const NoDomains: React.FC<NoDomainProps> = ({ dnsTargetName }) => {
	return (
		<div className={styles.noDomains}>
			<SvgIcon iconSrc={noDomainsIcon} className={styles.noDomains__icon} />
			<div className={styles.noDomains__content}>
				<div className={styles.noDomains__content__header}>
					{'Get a free SSL certificate by clicking "Enable HTTPS" above.'}
				</div>

				<div className={styles.noDomains__content__msg}>
					<div>
						{
							'Your cluster will automatically get HTTPS enabled which will overwrite your 443 and 80 ports.'
						}
					</div>

					<div>{`${dnsTargetName} and all added domains will all get a certificate installed and auto renewed.`}</div>
				</div>
			</div>
		</div>
	);
};
