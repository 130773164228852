import * as React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props {
	className?: string;
	children: any;
}

export const Section = (props: Props) => {
	return <div className={classnames(styles.appCardSection, props.className)}>{props.children}</div>;
};
