import * as React from 'react';
import styles from './style.module.scss';
import { MeasurementDataBar } from '@app/modules/cluster/components/MeasurementDataBar';

export interface MetricsListItem {
	title: string;
	measurement: string;
	value: string;
	digitsAfterDot?: number;
}

interface Props {
	list: MetricsListItem[];
}

export const MetricsDataList = (props: Props) => {
	const { list } = props;
	return (
		<div className={styles.layout}>
			{list.map((item, key) => (
				<div className={styles.row} key={key}>
					<div className={[styles.item, styles.title].join(' ')}>{item.title}</div>
					<div className={[styles.item, styles.value].join(' ')}>
						<MeasurementDataBar
							measurement={item.measurement}
							data={item.value}
							digitsAfterDot={item.digitsAfterDot}
						/>
					</div>
				</div>
			))}
		</div>
	);
};
