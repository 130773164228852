import { defineActionGroup, defineAction } from "rd-redux-utils";
import { IErrorMessage } from "../../../shared/errorMessage";

// GET REFERRAL
export const getReferralAG = defineActionGroup("GET REFERRAL");
export const getReferral = getReferralAG.defineAction("MAKE REQUEST");
export const getReferralSuccess = getReferralAG.defineAction<{ result: any }>("REQUEST SUCCEED");
export const getReferralFail = getReferralAG.defineAction<{ error: IErrorMessage }>("REQUEST FAILED");

// CLEAR ERROR
export const clearReferralError = defineAction("REFERRAL: CLEAR ERROR");
