import { defineActionGroup, defineAction } from 'rd-redux-utils';
import { IErrorMessage } from '@app/shared/errorMessage';
import { ComposedWizardData, clusterNodesWizardData, clusterWizardData } from '../interfaces';
import { ICluster } from '../../cluster/components/cluster/Cluster';

export const deployClusterAG = defineActionGroup('CLUSTER DEPLOY');
export const deployCluster = deployClusterAG.defineAction<ComposedWizardData>('STARTED');
export const deployClusterSuccess = deployClusterAG.defineAction('COMPLETED');
export const deployClusterFail = deployClusterAG.defineAction<{ error: IErrorMessage }>('FAILED');

export const updateCluster = defineActionGroup('CLUSTER_UPDATE');
export const updateClusterStartedAction = updateCluster.defineAction<ComposedWizardData>('STARTED');
export const updateClusterCompletedAction = updateCluster.defineAction('COMPLETED');
export const updateClusterErrorAction = updateCluster.defineAction<{ error: IErrorMessage }>('ERROR');

export const createClusterAG = defineActionGroup('CREATE CLUSTER');
export const createCluster = createClusterAG.defineAction<clusterWizardData>('STARTED');
export const createClusterSuccess = createClusterAG.defineAction('COMPLETED');

export const createClusterNodesAG = defineActionGroup('CREATE CLUSTER NODES');
export const createClusterNodes = createClusterNodesAG.defineAction<clusterNodesWizardData>('MAKE REQUEST');
export const createClusterNodesSuccess = createClusterNodesAG.defineAction('REQUEST SUCCEED');
export const createClusterNodesFail = createClusterNodesAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');

export const resetWizardAction = defineAction('RESET_WIZARD');

export const getClusterAction = defineAction<{ name: string }>('GET CLUSTER BY NAME');

export const setDeployWizardCluster = defineAction<ICluster>('DEPLOY WIZARD: GET CLUSTER');

export const clearWizardErrorAction = defineAction('DEPLOY WIZARD: CLEAR WIZARD ERROR');
