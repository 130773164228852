import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '@app/shared/SvgIcon';
import trash from '@icons/icon.remove.svg';

interface Props {
	className?: string;
	onClick: () => void;
}

export const TrashIcon = (props: Props) => {
	return <SvgIcon iconSrc={trash} className={classnames(styles.icon, props.className)} onClick={props.onClick} />;
};
