import {
	BILLING_INFORMATION_STEP_ID,
	billingInformationStepMeta,
	BillingInformationStep,
} from '@app/modules/billing-wizard/steps/BillingInformation';
import {
	BILLING_CARD_STEP_ID,
	billingCardStepMeta,
	BillingCardStep,
} from '@app/modules/billing-wizard/steps/BillingCard';
import {
	BILLING_CONFIRMATION_STEP_ID,
	billingConfirmationStepMeta,
	BillingConfirmationStep,
} from '@app/modules/billing-wizard/steps/BillingConfirmation';
import { BillingWizardStep } from '@app/modules/billing-wizard/state/interfaces';

export const wizardSteps: BillingWizardStep[] = [
	{
		name: BILLING_INFORMATION_STEP_ID,
		meta: billingInformationStepMeta,
		step: BillingInformationStep,
	},
	{
		name: BILLING_CARD_STEP_ID,
		meta: billingCardStepMeta,
		step: BillingCardStep,
	},
	{
		name: BILLING_CONFIRMATION_STEP_ID,
		meta: billingConfirmationStepMeta,
		step: BillingConfirmationStep,
	},
];
