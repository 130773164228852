import * as React from 'react';
import { createInjector } from '@app/libs/react-portal-injector';

export interface ModalProps {
	open: () => void;
	close: () => void;
}

export function createModals(portalId: string) {
	const { InjectorContainer: ModalContainer, removeInjectable: unregisterModal, createInjectable } = createInjector(
		portalId
	);

	const registerModal = function (Component: React.ComponentType<ModalProps>) {
		const { inject, eject } = createInjectable((props: any) => {
			return <Component open={props.binder.eject} close={props.binder.eject} />;
		});

		return {
			openModal: inject,
			closeModal: eject,
		};
	};

	return {
		ModalContainer,
		unregisterModal,
		registerModal,
	};
}
