import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { TableHeaderProps, Table } from 'semantic-ui-react';

interface Props extends TableHeaderProps {
	className?: string;
	children: any;
}

export const Head = (props: Props) => {
	const { className, children, ...rest } = props;

	return (
		<Table.Header className={classnames(styles.appTable__head, props.className)} {...rest}>
			{props.children}
		</Table.Header>
	);
};
