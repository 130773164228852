import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import filesize from 'filesize';
import { Line } from 'react-chartjs-2';
import { ChartTooltipModel } from 'chart.js';
import { LinePointerPlugin, ChartElement, PointerPosition } from './chartjs-plugins/line-pointer-plugin';
import { ApiNode } from '@app/data-modules/cluster-nodes';
import { LineChart, LineChartMetric } from './LineChart';
import memo from 'memoize-one';
import { AdaptableChartTooltip, TooltipData } from './AdaptableChartTooltip';
import { LoadingWrapperInputProps } from '@app/shared/hocs/enhancers/withLoadingSpinner/LoadingWrapper';
import { toUnixTimestamp } from '@app/modules/cluster/components/clusterInfo/utils/timeUtils';
import { MetricPeriods, ClusterMetricNodes, LoadingStatus } from '@app/data-modules/cluster-metrics';
import { SvgIcon } from '@app/shared/SvgIcon';
import { LdsSpinner } from '@app/shared/ldsSpinner';
import charLoadingIcon from '@icons/chart-loading.icon.svg';
import { ApiRegion } from '@app/data-modules/regions';

interface InputProps extends LoadingWrapperInputProps {
	nodes: ApiNode[];
	regions: ApiRegion[];
	metric: ClusterMetricNodes | null;
	metricMaxValues: Record<string, number>;
	period: MetricPeriods;
	disabled?: boolean;
	nodeMetricLoadingStatus: LoadingStatus | null;
}

type Props = InputProps;

interface State {
	displayTooltips: boolean;
	selectedChartId: number;
	tooltipPosition: number;
	activeElements: ChartElement[];
	currentLine: string;
	unitsOfMeasurement: string;
}

interface ChartProps {
	dataset: any;
	chartIndex: number;
	tickMeasurementSuffix: string;
	maxTickValue?: number;
	stepSize?: number;
	tooltipMeasurement: string;
	cutMeasurement?: boolean;
}

export class ClusterMetrics extends React.PureComponent<Props, State> {
	private charts = [
		React.createRef<Line>(),
		React.createRef<Line>(),
		React.createRef<Line>(),
		React.createRef<Line>(),
	];
	private plugin: LinePointerPlugin;
	private readonly plugins: any[] = [];
	private regionCount: KeyValue<number> = {};
	private readonly handleNearestValue: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			displayTooltips: false,
			selectedChartId: -1,
			tooltipPosition: 0,
			activeElements: [],
			currentLine: '',
			unitsOfMeasurement: 'B',
		};

		this.plugin = new LinePointerPlugin({
			id: 'line-pointer-plugin',
			appearance: {
				pointerWidth: 1,
				pointerColor: '#0c5ce5',
			},
			handlers: {
				onMove: this.handlePointerMove,
				onMouseLeave: this.handleMouseLeave,
				onMouseIn: this.handleMouseIn,
				afterDraw: this.handleDraw,
			},
			disabled: props.disabled,
		});

		this.plugins = [this.plugin.getPlugin()];

		this.handleNearestValue = (tooltipModel: ChartTooltipModel) => {
			const lines = tooltipModel?.body?.map(bodyItem => bodyItem.lines);
			const data: string = (lines && lines[0] && (lines[0][0] as any)) || '';
			const [title] = data.split(':');
			if (title) {
				this.setState({ currentLine: title.trim() });
			}
		};
	}

	handleDraw = (_: any, pointerPosition: PointerPosition) => {
		this.setState({ tooltipPosition: pointerPosition.x });
	};

	handleMouseIn = (chartId: number) => {
		this.setState({ selectedChartId: chartId, displayTooltips: true }, () => {
			if (this.props.disabled) return;
			this.plugin.enable();
		});
	};

	hasMetricData = (): boolean => {
		for (const node in this.props.metric) {
			if (typeof node === 'object' && !Object.keys(node[this.props.period]).length) {
				return false;
			}
		}
		return true;
	};

	handleMouseLeave = () => {
		this.setState({ selectedChartId: -1, displayTooltips: false }, () => {
			this.plugin.disable();
			this.redrawCharts();
		});
	};

	handlePointerMove = (_: any, positions: ChartElement[]) => {
		this.redrawCharts();
		this.setState({ activeElements: positions });
	};

	redrawCharts = () => {
		this.charts.forEach(chart => {
			const instance = this.getChartInstance(chart);
			instance?.draw({ lazy: true });
		});
	};

	getChartInstance = (chart: any) => chart?.current?.chartInstance;

	clearRegionCount = () => (this.regionCount = {});

	buildLabelsForChart = (metric: LineChartMetric[]): number[] => {
		if (!metric?.length) return [];
		return metric[0].data.map(m => toUnixTimestamp(m.date));
	};

	cutMeasurementForDataset = memo((dataset: any, maxValue: number) => {
		if (!maxValue || !dataset) return { dataset, maxValue, measurement: 'B' };

		const [cutMaxValue, cutMeasurement] = filesize(maxValue).split(' ');
		const factor = +cutMaxValue / maxValue;
		dataset.forEach((node: any) => {
			node.data = node.data.map((point: any) => {
				return { date: point.date, value: Number((point.value * factor).toFixed(2)) };
			});
		});
		return { dataset, maxValue: +cutMaxValue, measurement: cutMeasurement };
	});

	mapDatasetToTooltip = memo((dataset: any, positions: ChartElement[]): TooltipData[] => {
		if (!positions?.length) return [];
		const { dataIndex } = positions[0];
		return dataset.map((line: any) => {
			return { data: line.data[dataIndex], title: line.label, country: line.country, color: line.borderColor };
		});
	});

	componentDidUpdate(prevProps: Props) {
		const { disabled } = this.props;
		if (prevProps.disabled !== disabled) {
			if (disabled) this.plugin.disable();
			else this.plugin.enable();
		}
	}

	renderChart = (chartProps: ChartProps) => {
		const {
			dataset,
			chartIndex,
			tickMeasurementSuffix,
			maxTickValue,
			stepSize,
			tooltipMeasurement,
			cutMeasurement,
		} = chartProps;
		const { nodeMetricLoadingStatus, period } = this.props;
		if (nodeMetricLoadingStatus === 'LOADING') {
			return (
				<div className={styles.metric__loadingBlock}>
					<LdsSpinner inverted={false} />
				</div>
			);
		}

		if (nodeMetricLoadingStatus === 'FINISHED' && !dataset?.length) {
			return (
				<div className={styles.metric__loadingBlock}>
					<SvgIcon iconSrc={charLoadingIcon} className={styles.metric__loadingIcon} />
					<span className={styles.metric__loadingText}>There’s no data yet</span>
				</div>
			);
		}

		const chart = this.charts[chartIndex];
		const { displayTooltips, tooltipPosition, activeElements, selectedChartId, currentLine } = this.state;
		const chartInstance = this.getChartInstance(chart);
		const isHoveredChart = chartInstance?.id === selectedChartId;

		return (
			<div className={styles.metricWrapper}>
				<LineChart
					canvasRef={chart}
					labels={this.buildLabelsForChart(dataset)}
					plugins={this.plugins}
					datasets={dataset || []}
					tickMeasurementSuffix={tickMeasurementSuffix}
					maxTickValue={maxTickValue}
					stepSize={stepSize}
					options={options => ({
						...options,
						tooltips: {
							enabled: false,
							mode: 'y',
							intersect: false,
							custom: this.handleNearestValue,
						},
						elements: {
							// line: { tension : 0.1 }, // smooth or sharp
							point: {
								hoverRadius: 3,
								radius: 1.5,
							},
						},
						scales: {
							...(options.scales || {}),
							xAxes: [
								{
									...(options.scales?.xAxes?.[0] || {}),
									gridLines: { display: true },
									type: 'time',
									time: {
										unit: ['6h', '24h'].includes(period) ? 'hour' : 'day',
										stepSize: period === '24h' ? 2 : 1,
									},
								},
							],
						},
					})}
				/>
				{chartInstance && displayTooltips && (
					<AdaptableChartTooltip
						xPosition={tooltipPosition}
						yPosition={chartInstance.chartArea.top || 0}
						adaptTo={chartInstance.canvas as HTMLElement}
						hidden={!displayTooltips}
						data={this.mapDatasetToTooltip(chartInstance.data.datasets, activeElements)}
						measurement={tooltipMeasurement}
						currentLine={isHoveredChart ? currentLine : ''}
						cutMeasurement={cutMeasurement}
					/>
				)}
			</div>
		);
	};

	render() {
		const datasetsMap = this.props.metric || {};
		const { metricMaxValues = {} } = this.props;

		const {
			dataset: bandwidthDataset,
			maxValue: bandwidthMaxValue,
			measurement: bandwidthTickMeasurement,
		} = this.cutMeasurementForDataset(datasetsMap['bandwidth'], metricMaxValues['bandwidth']);

		return (
			<div className={classnames(styles.metricLayout)}>
				<div className={styles.metric}>
					<span className={styles.metric__title}>CPU Usage</span>
					{this.renderChart({
						dataset: datasetsMap['cpu.load'],
						chartIndex: 0,
						tickMeasurementSuffix: '%',
						maxTickValue: +(metricMaxValues['cpu.load'] * 1.1).toFixed(1),
						tooltipMeasurement: '%',
					})}
				</div>
				<div className={styles.metric}>
					<span className={styles.metric__title}>RAM Usage</span>
					{this.renderChart({
						dataset: datasetsMap['mem.usage'],
						chartIndex: 1,
						tickMeasurementSuffix: '%',
						maxTickValue: +(metricMaxValues['mem.usage'] * 1.1).toFixed(1),
						tooltipMeasurement: '%',
					})}
				</div>
				<div className={styles.metric}>
					<span className={styles.metric__title}>Bandwidth</span>
					{this.renderChart({
						dataset: bandwidthDataset,
						chartIndex: 2,
						tickMeasurementSuffix: bandwidthTickMeasurement,
						maxTickValue: bandwidthMaxValue,
						tooltipMeasurement: bandwidthTickMeasurement,
						cutMeasurement: false,
					})}
				</div>
				<div className={styles.metric}>
					<span className={styles.metric__title}>Disk Usage</span>
					{this.renderChart({
						dataset: datasetsMap['disk.used'],
						chartIndex: 3,
						tickMeasurementSuffix: '%',
						maxTickValue: +(metricMaxValues['disk.used'] * 1.1).toFixed(1),
						tooltipMeasurement: '%',
					})}
				</div>
			</div>
		);
	}
}
