interface Values {
	ports: { private: string; public: string; protocol: string }[];
}

export const portMappingProcessor = (values: Values) => {
	const processedPorts = values.ports.map((portData: Record<string, number | string>) => {
		for (const key in portData) {
			portData[key] = isNaN(+portData[key]) ? portData[key] : +portData[key];
		}
		return portData;
	});

	return { ports: processedPorts };
};
