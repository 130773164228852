import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	errorText?: string;
	className?: string;
}

interface State {
	maxWidth: string;
}

export class InputErrorText extends React.Component<Props, State> {
	private readonly ref = React.createRef<HTMLElement>();

	state = {
		maxWidth: '',
	};

	componentDidMount() {
		this.getParentWidth();

		window.addEventListener('resize', this.getParentWidth);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getParentWidth);
	}

	getParentWidth = () => {
		if (!this.ref.current) return;
		const parent = this.ref.current.parentElement;
		if (!parent) return this.setState({ maxWidth: '100%' });

		const parentRect = parent?.getBoundingClientRect();

		this.setState({ maxWidth: `${parentRect?.width}px` });
	};

	render() {
		const { className, errorText } = this.props;

		return (
			<span
				className={classnames(styles.inputErrorText, className)}
				title={errorText}
				ref={this.ref}
				style={{ maxWidth: this.state.maxWidth }}
			>
				{this.state.maxWidth ? errorText : ''}
			</span>
		);
	}
}
