import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	className?: string;
}

export const AppSpinner = (props: Props) => {
	const { className } = props;

	return <div className={classnames(styles.appSpinner, className)}></div>;
};
