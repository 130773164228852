import { call, take, put } from 'redux-saga/effects';
import { createAccountApi } from '../api';
import { AccountValues } from '../../components';
import { createAccount, setAccountError } from '../actions';
import { CHECK_EMAIL_URL } from '../url';
import { navigateTo } from 'src/utils/navigate-to';

export function* watchCreateAccount() {
	while (true) {
		const action = yield take('*');
		if (createAccount.is(action)) {
			yield call(createAccountWorker, { values: action.values, onSuccess: action.onSuccess });
		}
	}
}

function* createAccountWorker({ values, onSuccess }: { values: AccountValues; onSuccess: () => void }) {
	try {
		yield call(createAccountApi, values);
		yield call(navigateTo(CHECK_EMAIL_URL.urlTemplate, { email: values.email, from: 'create-account' }));
		onSuccess();
	} catch (error) {
		yield put(setAccountError({ error }));
		onSuccess();
	}
}
