import * as React from 'react';
import styles from './style.module.scss';
import { baseURL } from '@app/apis/api';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { ErrorMessage } from '@app/shared/errorMessage';

interface Props {
	accColor: string;
	title: string;
	errorMsg: string;
	subtitle?: string;
}

export const ClusterInfoTitle: React.FC<Props> = ({ accColor, title, subtitle, errorMsg }) => {
	return (
		<div className={styles.titleContainer}>
			<div className={styles.titleContainer__imageWrapper}>
				{accColor ? (
					<img src={`${baseURL}/api/avatar/cluster-${accColor || '0c5ce5'}.png`} alt="avatar" />
				) : (
					<div className={styles.titleContainer__imageWrapper_placeholder} />
				)}
			</div>
			<div className={styles.titleContainer__textContainer}>
				<h1 className={styles.titleContainer__textContainer_title}>
					{title ? title : <AppSkeleton className={styles.titleContainer__textContainer_title_skeleton} />}
				</h1>
				<h2 className={styles.titleContainer__textContainer_subtitle}>
					{subtitle ? (
						subtitle
					) : (
						<AppSkeleton className={styles.titleContainer__textContainer_subtitle_skeleton} />
					)}
				</h2>
			</div>
			<ErrorMessage
				useDefaultMsg={false}
				error={{ message: errorMsg }}
				withinPortal={false}
				className={styles.titleContainer__errorMessage}
			/>
		</div>
	);
};
