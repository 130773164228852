import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { InputsList, InputsListRenderableProps } from '../inputs/InputsList';
import { InputLabel } from '../text/InputLabel';
import { BasicInput } from '@app/shared/inputs/BasicInput';
import { InputErrorText } from '../text/InputErrorText';
import { getIn, FormikErrors, FormikTouched, FormikHelpers } from 'formik';

export interface EnvironmentFormValue {
	key: string;
	value: string;
}

interface Props {
	onChange: (e: React.SyntheticEvent<HTMLInputElement, Event>) => void;
	onBlur: (e: React.SyntheticEvent<HTMLInputElement>) => void;
	errors: FormikErrors<{ env: EnvironmentFormValue[] }>;
	touched: FormikTouched<{ env: EnvironmentFormValue[] }>;
	values: any;
	pathToValues: string;
	setFieldValue: FormikHelpers<EnvironmentFormValue>['setFieldValue'];
	className?: string;
	disabled?: boolean;
}

export class EnvironmentFieldList extends React.Component<Props> {
	renderer = (props: InputsListRenderableProps<EnvironmentFormValue>) => {
		const keyName = `${this.props.pathToValues}[${props.index}].key`;
		const valueName = `${this.props.pathToValues}[${props.index}].value`;

		const touchedKeyInput = getIn(this.props.touched, keyName);
		const touchedValueInput = getIn(this.props.touched, valueName);

		const keyError = getIn(this.props.touched, keyName) ? getIn(this.props.errors, keyName) : '';
		const valueError = getIn(this.props.touched, valueName) ? getIn(this.props.errors, valueName) : '';

		const keyValue = getIn(this.props.values, keyName);
		const valueValue = getIn(this.props.values, valueName);

		return (
			<div className={styles.listItem}>
				<div className={styles.sections}>
					<div className={styles.section}>
						<InputLabel text="Key" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput
								className={styles.input}
								onChange={props.onChange}
								onBlur={props.onBlur}
								name={keyName}
								value={keyValue}
								error={touchedKeyInput && !!keyError}
								placeholder={'Key'}
								disabled={this.props.disabled}
								setFieldValue={this.props.setFieldValue}
							/>
							<InputErrorText errorText={keyError} />
						</div>
					</div>

					<div className={styles.section}>
						<InputLabel text="Value" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput
								className={styles.input}
								onChange={props.onChange}
								onBlur={props.onBlur}
								name={valueName}
								value={valueValue}
								error={touchedValueInput && !!valueError}
								placeholder={'Value'}
								disabled={this.props.disabled}
								setFieldValue={this.props.setFieldValue}
							/>
							<InputErrorText errorText={valueError} />
						</div>
					</div>
				</div>

				<div
					className={classnames(styles.trashBtn, {
						[styles.trashBtn_hidden]: !(valueValue && keyValue) || this.props.disabled,
					})}
				>
					{props.trashButton}
				</div>
			</div>
		);
	};

	extendChecker = (values: EnvironmentFormValue[]): boolean => {
		const latestPos = values.length - 1;

		return latestPos > -1 && !!values[latestPos].key && !!values[latestPos].value;
	};

	getValuesIndexesToPrune = (values: EnvironmentFormValue[]): number[] => {
		const lastIdx = values.length - 1;
		const emptyPairsIndexesArr = values.reduce((a: number[], v, i: number) => {
			if (v.key === '' && v.value === '') a.push(i);
			return a;
		}, []);

		return emptyPairsIndexesArr.filter(item => item !== lastIdx);
	};

	getTemplateForExtend = (): EnvironmentFormValue => {
		return { key: '', value: '' };
	};

	render() {
		const { values = [], onChange, onBlur, className, pathToValues } = this.props;

		return (
			<div className={classnames(styles.environmentFieldList, className)}>
				<InputsList
					name={pathToValues}
					values={getIn(values, pathToValues)}
					changeHandler={onChange}
					blurHandler={onBlur}
					extendable={this.getTemplateForExtend}
					renderer={this.renderer}
					shouldExtendChecker={this.extendChecker}
					shouldPruneChecker={this.getValuesIndexesToPrune}
				/>
			</div>
		);
	}
}
