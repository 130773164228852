import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface ClusterSettingsSectionProps {
	children: JSX.Element;
	id: string;
	title: string;
}

export const ClusterSettingsSection: React.FC<ClusterSettingsSectionProps> = ({ children, id, title }) => {
	return (
		<div id={id} className={styles.clusterSettings__content_wrapped__body}>
			<div className={classnames([styles.clusterSettings__content_wrapped__body__blockHead])}>
				<span>{title}</span>
			</div>
			{children}
		</div>
	);
};
