import React from 'react';
import { BtnDeployNewCluster } from '@app/shared/BtnDeployNewCluster';
import styles from './styles.module.scss';

import noClustersImage from '@images/my-clusters@2x.png';

export const NoClusters: React.FC<{}> = () => {
	return (
		<div className={styles.noClusters}>
			<img src={noClustersImage} alt="Empty cluster list." className={styles.noClusters__image} />
			<div className={styles.noClusters__title}>There are no clusters</div>
			<div className={styles.noClusters__descr}>
				Deploy a new cluster, add a Docker image and place your containers on the edge
			</div>

			<BtnDeployNewCluster />
		</div>
	);
};
