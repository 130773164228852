import React from 'react';
import classnames from 'classnames';

import styles from './style.module.scss';
import { AppButton } from '@app/shared/buttons/AppButton';
import arrowRigthIcon from '@icons/arrow.right.icon.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { BasicInput } from '@app/shared/inputs/BasicInput';

interface PaginationProps {
	count: number;
	active: number;
	onChangePage?: (page: number) => void;
	className?: string;
}

Pagination.defaultProps = {
	active: 1,
};

const PAGINATION_LENGTH = 4;

export function Pagination({ count, active, onChangePage, className }: PaginationProps) {
	const [inputPage, setInputPage] = React.useState(active);

	React.useEffect(() => {
		setInputPage(active >= count ? 1 : active + 1);
	}, [active, count]);

	const pages = React.useMemo((): number[] => {
		const pages: number[] = [];

		for (let i = 0; i < count; i++) {
			pages.push(i + 1);
		}

		return pages;
	}, [count]);

	const onChange = React.useCallback(
		(page: number) => () => {
			onChangePage && onChangePage(page > 0 && page <= pages.length ? page : active);
		},
		[active, pages.length, onChangePage]
	);

	const onInputChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => setInputPage(parseInt(e.target.value, 10) || active),
		[active]
	);

	if (count <= 1) {
		return null;
	}

	return (
		<div className={classnames(styles.paginationContainer, className)}>
			<AppButton
				disabled={active <= 1}
				theme="regular"
				className={classnames(styles.paginationButton, styles.arrow)}
				onClick={onChange(active - 1)}
			>
				<SvgIcon iconSrc={arrowRigthIcon} className={styles.arrowLeft} />
			</AppButton>
			{pages.slice(0, PAGINATION_LENGTH + 1 === count ? PAGINATION_LENGTH + 1 : PAGINATION_LENGTH).map(page => (
				<AppButton
					key={page}
					onClick={onChange(page)}
					theme={page === active ? 'action' : 'regular'}
					className={classnames(styles.paginationButton)}
				>
					{page}
				</AppButton>
			))}
			{count > PAGINATION_LENGTH + 1 && (
				<>
					<div className={classnames(styles.dropdownButton)}>
						<AppButton
							theme={active > PAGINATION_LENGTH && active !== pages.length ? 'action' : 'regular'}
							className={classnames(styles.paginationButton)}
						>
							{active > PAGINATION_LENGTH && active !== pages.length ? `... ${active}` : '...'}
						</AppButton>
						<div className={classnames(styles.pageDropdown)}>
							<span>Go to page</span>
							<BasicInput
								focus={true}
								value={inputPage}
								onChange={onInputChange}
								type="number"
								className={styles.pageInput}
							/>
							<AppButton onClick={onChange(inputPage)} theme="action">
								Go
							</AppButton>
						</div>
					</div>
					<AppButton
						onClick={onChange(pages.length)}
						theme={pages.length === active ? 'action' : 'regular'}
						className={classnames(styles.paginationButton)}
					>
						{pages.length}
					</AppButton>
				</>
			)}
			<AppButton
				disabled={active >= pages.length}
				theme="regular"
				className={classnames(styles.paginationButton, styles.arrow)}
				onClick={onChange(active + 1)}
			>
				<SvgIcon iconSrc={arrowRigthIcon} />
			</AppButton>
		</div>
	);
}
