import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import '../../../../../node_modules/xterm/css/xterm.css';
import { NoConnection } from './NoConnection';
import { ClusterConsoleTab } from './ClusterConsoleTab';
import { ClusterPageLayout } from '../layouts/ClusterPageLayout';
import { getFlagForNode } from '@app/utils/getFlagClass';
import { useClusterNodes } from '@app/hooks';

interface ClusterConsoleProps {
	isReadonlyCluster: boolean;
}

export const ClusterConsole: React.FC<ClusterConsoleProps> = ({ isReadonlyCluster }) => {
	const clusterNodes = useClusterNodes();
	const [activeNodeId, setActiveNodeID] = React.useState<string | number>();

	return (
		<ClusterPageLayout className={styles.clusterConsole}>
			<ClusterPageLayout.Head titleSection={<ClusterPageLayout.Title text="Console" />} />

			<ClusterPageLayout.Content className={styles.clusterConsole__content}>
				{isReadonlyCluster ? (
					<div className={styles.clusterConsole__content__noPermissionMsg}>
						{'Write permissions are required to use the console. Please contact the account owner.'}
					</div>
				) : (
					<>
						<div className={styles.clusterConsole__content__head}>
							{clusterNodes &&
								clusterNodes.map(clusterNode => {
									return (
										<div
											key={clusterNode.id}
											className={classnames(styles.clusterConsole__content__head__nodeRegion, {
												[styles.clusterConsole__content__head__nodeRegion_active]:
													clusterNode.id === activeNodeId,
											})}
											onClick={() => setActiveNodeID(clusterNode.id)}
										>
											<i
												className={classnames('flag-icons', getFlagForNode(clusterNode.region))}
											/>
											{clusterNode.name}
										</div>
									);
								})}
						</div>
						<div className={styles.clusterConsole__content__divider} />
						<div className={styles.clusterConsole__content__body}>
							{activeNodeId && clusterNodes ? (
								<div id={'terminal-container'}>
									{clusterNodes.map(clusterNode => (
										<ClusterConsoleTab
											key={clusterNode.id}
											termIsActive={clusterNode.id === activeNodeId}
											id={`terminal-${clusterNode.id}`}
											hostName={clusterNode.hostname}
											className={classnames(
												styles.clusterConsole__content__body__terminalWrapper,
												{
													[styles.clusterConsole__content__body__terminalWrapper_hidden]:
														clusterNode.id !== activeNodeId,
												}
											)}
										/>
									))}
								</div>
							) : (
								<NoConnection />
							)}
						</div>
					</>
				)}
			</ClusterPageLayout.Content>
		</ClusterPageLayout>
	);
};
