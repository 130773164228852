import {
	NameAndDescriptionStep,
	nameAndDescriptionStepMeta,
	NAME_AND_DESCRIPTION_STEP_ID,
} from '@app/modules/deploy-wizard/steps/NameAndDescriptionStep';
import {
	DockerImageStep,
	DOCKER_IMAGE_STEP_ID,
	dockerImageStepMeta,
} from '@app/modules/deploy-wizard/steps/DockerImageStep';
import {
	EnvironmentStep,
	ENVIRONMENT_STEP_ID,
	environmentStepMeta,
} from '@app/modules/deploy-wizard/steps/EnvironmentStep';
import {
	PORT_MAPPING_STEP_ID,
	portMappingStepMeta,
	PortMappingStep,
	portMappingProcessor,
} from '@app/modules/deploy-wizard/steps/PortMappingStep';
import { RESOURCES_STEP_ID, ResourcesStep, resourcesStepMeta } from '@app/modules/deploy-wizard/steps/ResourcesStep';
import {
	REGIONS_STEP_ID,
	regionsStepMeta,
	RegionsStep,
	regionsProcessor,
} from '@app/modules/deploy-wizard/steps/RegionsStep';
import {
	CONFIRMATION_STEP_ID,
	confirmationStepMeta,
	ConfirmationStep,
} from '@app/modules/deploy-wizard/steps/ConfirmationStep';
import { DEPLOY_STEP_ID, deployStepMeta, DeployStep } from '@app/modules/deploy-wizard/steps/DeployStep';

export const wizardSteps = [
	{
		name: NAME_AND_DESCRIPTION_STEP_ID,
		meta: nameAndDescriptionStepMeta,
		step: NameAndDescriptionStep,
	},
	{
		name: DOCKER_IMAGE_STEP_ID,
		meta: dockerImageStepMeta,
		step: DockerImageStep,
	},
	{
		name: ENVIRONMENT_STEP_ID,
		meta: environmentStepMeta,
		step: EnvironmentStep,
	},
	{
		name: PORT_MAPPING_STEP_ID,
		meta: portMappingStepMeta,
		step: PortMappingStep,
		processValues: portMappingProcessor,
	},
	{
		name: RESOURCES_STEP_ID,
		meta: resourcesStepMeta,
		step: ResourcesStep,
	},
	{
		name: REGIONS_STEP_ID,
		meta: regionsStepMeta,
		step: RegionsStep,
		processValues: regionsProcessor,
	},
	{
		name: CONFIRMATION_STEP_ID,
		meta: confirmationStepMeta,
		step: ConfirmationStep,
	},
	{
		name: DEPLOY_STEP_ID,
		meta: deployStepMeta,
		step: DeployStep,
	},
];
