import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { withFormik, FormikProps, Form } from 'formik';
import { compose } from 'recompose';
import { Button } from 'semantic-ui-react';
import classnames from 'classnames';
import { AppState } from '@app/reducers';
import ValidationSchema from './validation-schema';
import { setNewPassword } from '../../state/actions';
import { IErrorMessage } from '@app/shared/errorMessage';
import { PageHeader } from '@app/shared/pageHeader';
import { navigateTo } from '@app/utils/navigate-to';
import { ACCOUNT_SIGNUP_URL } from '../../../account/state/url';
import { LabeledInputRegular } from '@app/shared/labeledInputRegular/LabeledInputRegular';
import { ErrorMessage } from '@app/shared/errorMessage/ErrorMessage';
import { PasswordProgress } from '@app/shared/passwordProgress/PasswordProgress';
import history from '../../../../history';
import { NEW_PASSWORD_URL } from '../../state/url';

export interface NewPasswordValues {
	newPassword: string;
	token: string;
}
interface NewPasswordProps {
	newPassError: IErrorMessage;
	setNewPassword: (values: NewPasswordValues) => void;
	clearSigninError: (_: any) => void;
}

export const NewPasswordComponent: React.FC<NewPasswordProps & FormikProps<NewPasswordValues>> = props => {
	const {
		values,
		newPassError,
		handleChange,
		handleSubmit,
		handleBlur,
		errors,
		touched,
		isSubmitting,
		setFieldValue,
	} = props;
	const { newPassword } = values;

	useEffect(() => {
		const urlMatch = NEW_PASSWORD_URL.match(history.location, true);
		if (urlMatch.isMatched) {
			setFieldValue('token', (urlMatch.params as { token: string }).token);
		}
	}, [setFieldValue]);

	return (
		<div className={styles.newPass}>
			<PageHeader
				btnComponent={
					<Button
						className={classnames('btn-one', styles.btnCreateAccount)}
						onClick={navigateTo(ACCOUNT_SIGNUP_URL.urlTemplate)}
					>
						{'Create an account'}
					</Button>
				}
				className={styles.newPass__header}
			/>
			<div className={classnames(styles.newPass__content, 'firstContnentElem')}>
				<div className={styles.newPass__content__header}>{'Set new password'}</div>
				<div className={styles.newPass__content__subHeader}>
					{'Your password must be at least 8 characters'}
				</div>

				<Form onSubmit={token => handleSubmit(token)} className={styles.newPass__content__form}>
					<LabeledInputRegular
						inputType="password"
						handleChange={handleChange}
						handleBlur={handleBlur}
						inputValue={newPassword}
						inputName="newPassword"
						inputPlaceholder="New password"
						errorMsg={errors.newPassword}
						isTouched={touched.newPassword}
						labelText="Password"
						setFieldValue={setFieldValue}
						detailComponent={
							<PasswordProgress
								className={styles.newPass__content__passwordProgress}
								password={newPassword}
								errorMsg={errors.newPassword}
								isTouched={touched.newPassword}
							/>
						}
					/>

					<Button
						disabled={isSubmitting}
						type="submit"
						className={classnames('btn-one', styles.newPass__content__btnSavePass)}
					>
						{'Save password'}
					</Button>
				</Form>

				<ErrorMessage
					error={newPassError}
					withinPortal={false}
					className={styles.newPass__content__form__error}
				/>
			</div>
		</div>
	);
};

export const NewPassword = compose<NewPasswordProps & FormikProps<NewPasswordValues>, {}>(
	connect(
		(state: AppState) => ({
			newPassError: state.signin.error,
		}),
		{ setNewPassword }
	),
	withFormik({
		mapPropsToValues: () => ({
			newPassword: '',
			token: '',
		}),
		validationSchema: ValidationSchema,

		handleSubmit: (
			values,
			{ setSubmitting, props }: { setSubmitting: (isSubmitting: boolean) => void; props: NewPasswordProps }
		) => {
			props.setNewPassword(values);
			setSubmitting(false);
		},

		displayName: 'NewPassword',
	})
)(NewPasswordComponent);
