import * as React from 'react';
import styles from './style.module.scss';
import { InputProps, Input as SemanticInput } from 'semantic-ui-react';
import classnames from 'classnames';
import { NumberInput } from '../NumberInput';
import { AppButton, AppButtonThemes } from '@app/shared/buttons/AppButton';
import { SvgIcon } from '@app/shared/SvgIcon';
import iconError from '@icons/icon-error.svg';

interface Props extends InputProps {
	inputClassName?: string;
	btnText: string;
	btnClass?: string;
	btnOnClick: (props?: any) => void;
	btnDisabled?: boolean;
	btnHidden?: boolean;
	btnTheme?: AppButtonThemes;
}

export const InputWithInnerButton: React.FC<Props> = React.forwardRef<any, Props>(
	(
		{
			className,
			inputClassName,
			type: inputType,
			error,
			btnText,
			btnOnClick,
			btnClass,
			btnDisabled,
			btnHidden,
			btnTheme,
			name,
			value,
			onBlur,
			setFieldValue,
			...rest
		},
		ref
	) => {
		const Input = inputType === 'number' ? NumberInput : SemanticInput;

		const ownHandleBlur = (name: string, value: string | number | undefined) => (
			e: React.FocusEvent<HTMLInputElement>
		) => {
			if (setFieldValue) {
				// trim and replace multiwhitespaces with single one
				const prepValue = String(value).trim().replace(/\s\s+/g, ' ');
				setFieldValue(name, prepValue);
			}
			return onBlur(e);
		};

		return (
			<div className={classnames(styles.inputWithInnerButton, className)}>
				<Input
					ref={ref}
					className={classnames(
						styles.inputWithInnerButton_input,
						{ [styles.inputWithInnerButton_input__withError]: error },
						inputClassName
					)}
					type="text"
					{...rest}
					error={error}
					name={name}
					value={value}
					onBlur={onBlur ? ownHandleBlur(name, value) : () => {}}
				/>
				{!btnHidden && (
					<AppButton
						theme={btnTheme ? btnTheme : AppButton.themes.action}
						className={classnames(styles.inputWithInnerButton__btn, btnClass)}
						type={'button'}
						onClick={btnOnClick}
						disabled={btnDisabled}
					>
						{btnText}
					</AppButton>
				)}
				{error && <SvgIcon iconSrc={iconError} className={styles.errorHint} />}
			</div>
		);
	}
);
