import * as yup from 'yup';

function hasDuplicates<TItem>(arr: TItem[], pickedValue: any, valuePicker: (item: TItem) => any) {
	let repetitionsAmount = 0;

	for (let i = 0; i < arr.length; i++) {
		const item = arr[i];
		const val = valuePicker(item);

		if (val === pickedValue) {
			repetitionsAmount++;
		}

		if (repetitionsAmount > 1) {
			return true;
		}
	}

	return false;
}

yup.addMethod(yup.array, 'validatePortMappingForHttps', function({tests}: {tests: string[]}) {
	return this.test('validatePortMappingForHttps', '', function() {
		for (const test of tests) {
			if (test === 'public') {
				const httpsPort = this.parent.httpsPort;
				const ports = this.parent.ports;

				ports?.forEach((portsPair: any, idx: number) => {
					if (httpsPort && (httpsPort === portsPair.public || portsPair.public === '443' || portsPair.public === '80')) {
						throw this.createError({ path: `${this.path}[${idx}].public`, message: 'You can`t use this port since HTTPS is enabled' });
					}
				});
			}
		}

		return true;
	})
});

yup.addMethod(yup.object, 'unique', function({msg = '', fieldName, valuePicker} : {
	msg?: string,
	fieldName: string;
	valuePicker: (value: any) => any
}) {
	return this.test('unique', msg, function(value: any) {
		const list = [...this.parent];
		const pickedValue = valuePicker(value);
		const filteredList = list.filter(item => valuePicker(item));

		if (typeof pickedValue !== 'undefined' && hasDuplicates(filteredList, pickedValue, valuePicker)) {
			throw this.createError({ path: `${this.path}.${fieldName}`, message: msg });
		}

		return true;
	});
});

yup.addMethod(yup.object, 'uniquePort', function({msg = '', fieldName, valuePicker} : {
	msg?: string,
	fieldName: string;
	valuePicker: (value: any) => any
}) {
	return this.test('uniquePort', msg, function(value: any) {
		const list = [...this.parent];
		const pickedValue = valuePicker(value);
		// since we have two protocols - UDP and TCP and pair with the same ports but different protocols is considered valid
		const filteredList = list.filter(item => valuePicker(item)).filter((item) => item.protocol === value.protocol);

		if (typeof pickedValue !== 'undefined' && hasDuplicates(filteredList, pickedValue, valuePicker)) {
			throw this.createError({ path: `${this.path}.${fieldName}`, message: msg });
		}

		return true;
	});
});

export const customYup = yup;

export const yupExceptions = {
	isNotNumber: {
		regex: /^[0-9]*$/,
		message: 'You must specify a number.',
	},
	isCyrillic: {
		regex: /^[^А-ЯЁІЇЄа-яёєії]+$/,
		message: 'Cyrillic is not allowed.',
	},
	isInvalidDockerImage: {
		regex: /^[a-z0-9]+(?:[._\-/]{1,2}[a-z0-9]+)*$/,
		message: 'Invalid format of docker image or tag.',
	},
	isInvalidHostname: {
		regex: /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])$/,
		message: (name: string) => `${name} must be a valid hostname.`,
	},
	isNotAvailablePublicPort: {
		regex: /^(?!(?:22|23|25|111|5404|5405|16514|(400[0-4][0-9]|40050)|(4915[2-9]|491[6-9][0-9]|492[0-5][0-9]|4926[01]))$)\d+/,
		message: 'Port reserved by appfleet. Please use a different port.'
	},
	isNotAvailablePort: {
		regex: /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
		message: 'Invalid port. Valid ports include 1-65535.'
	},
	isStartsWithNumber: {
		regex: /^[^0-9]{1}\S{0,}$/,
		message: "Env keys can't start with numbers or contain spaces."
	},
	isNotValidEnvironmentKey: {
		regex: /^((?!NUL|=|nul).)*$/,
		message: "Invalid env format. NUL and = are not allowed in env keys."
	},
	isValidCompanyName: {
		regex: /^(?!.*([-_#%*^"'@$!(),.?\\/-])\1{2})(?=.*([a-zA-Z]){2,})[A-Za-z0-9\-_ #%*^"'$!(),.?\\/-]{3,}$/,
		message: 'Please enter a valid company name or leave it empty'
	},
	isFirstNameValid: {
		regex: /^[a-zA-Z']+$/,
		message: 'Use latin letters or apostrophe'
	},
	isLastNameValid: {
		regex: /^[a-zA-Z\s']+$/,
		message: 'Use latin letters or apostrophe'
	},
	isPasswordValid: {
		regex: /(?=^.{8,}$)(?=.*\d)(?=.*[A-Za-z]).*$/,
		message: 'Your password is too weak'
	},
	isAllowedSymbolsInPassword: {
		regex: /^[0-9a-zA-Z~!@?#$%^&*\-_{}()]*$/,
		message: 'Allowed symbols : 0-9, a-z, A-Z, ~!@?#$%^&*-_{}()'
	}
};
