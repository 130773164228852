import { navigateTo } from '@app/utils/navigate-to';
import { SIGNIN_URL } from '@app/modules/user-sign-in';
import type { SigninValues } from '@app/modules/user-sign-in';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const baseURL = (process.env.REACT_APP_API_URL ?? 'https://api-appfleet-com-staging.herokuapp.com').replace(
	/\/$/,
	''
);

export interface AuthResponse {
	authToken: string;
	[x: string]: any;
}

class Api {
	private token: string = '';
	private api: AxiosInstance;
	private supportk: string = '';

	public constructor(config?: AxiosRequestConfig) {
		this.api = axios.create(config);

		this.api.interceptors.request.use((param: AxiosRequestConfig) => ({
			...param,
			baseURL,
			headers: { common: { Authorization: `Bearer ${this.token}` } },
		}));

		this.api.interceptors.response.use(this.success.bind(this), this.error.bind(this));
	}

	public async request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
		return await this.api.request(config);
	}

	public async authenticate(data: SigninValues): Promise<string> {
		const result: AuthResponse = await this.api.post('/auth/login', data);
		this.setToken(result.authToken);
		return this.token;
	}

	public async get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
		return this.api.get(url, config);
	}

	public async post<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
		return this.api.post(url, data, config);
	}

	public async put<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
		return this.api.put(url, data, config);
	}

	public async delete<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
		return this.api.delete(url, { ...config, data: data });
	}

	public success<T>(response: AxiosResponse<T>): T {
		const token = this.getTokenFromRequestHeader(response.config?.headers);
		if (token) this.storeToken(token);
		return response.data;
	}

	public error(error: AxiosError<Error>): AxiosResponse<Error> {
		if (error.response?.status === 401) {
			this.removeToken();
		}

		const message = error.response?.data?.message;
		throw message ? new Error(message) : error;
	}

	public getToken(): string {
		return this.token;
	}

	public getSupportk(): string {
		return this.supportk;
	}

	public setToken(value: string): void {
		this.token = value;
		this.storeToken();
	}

	public setSupportk(value: string): void {
		this.supportk = value;
		this.storeSupportk();
	}

	public removeToken(): void {
		this.token = '';
		this.supportk = '';
		this.removeStoredToken();
		this.removeStoredSupportk();
		navigateTo(SIGNIN_URL.urlTemplate)();
	}

	private storeToken(value?: string): void {
		window.localStorage.setItem('token', value ?? this.token);
	}

	private storeSupportk(value?: string): void {
		window.localStorage.setItem('supportk', value ?? this.supportk);
	}

	private removeStoredToken(): void {
		window.localStorage.removeItem('token');
	}

	private removeStoredSupportk(): void {
		window.localStorage.removeItem('supportk');
	}

	private getTokenFromRequestHeader(headers: { [x: string]: any }) {
		return headers?.Authorization.split(' ')?.pop()?.trim();
	}
}

export default new Api();
