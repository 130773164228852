import { ClustersListState } from './state';
import { Action } from 'redux';
import {
	getClusterListSuccess,
	getClustersListFail,
	deleteClusterByIdFail,
	clearClusterListError,
	getClustersMetricsSuccess,
	getClustersMetricsFail,
	pullNDeployClustersListItem,
	pullNDeployClustersListItemFailed,
	pullNDeployClustersListItemSucceed,
	resetPullNDeployClustersListItem,
} from './actions';
import { ClusterListItem } from './interfaces';

const INITIAL_STATE = {
	clustersList: [] as ClusterListItem[],
	isClustersListLoaded: false,
	clustersMetrics: {},
	nodesList: {},
	error: {
		message: '',
	},
	loading: true,
	deploy: {
		started: false,
		clusterDeployed: false,
		nodesCreated: false,
		completed: false,
		failed: false,
	},
	pullAndDeployState: {},
};

export function clustersListReducer(state: ClustersListState = INITIAL_STATE, action: Action): ClustersListState {
	if (getClustersMetricsSuccess.is(action)) {
		return {
			...state,
			clustersMetrics: { ...action.clustersMetrics },
			error: INITIAL_STATE.error,
			loading: false,
		};
	}
	if (getClusterListSuccess.is(action)) {
		return {
			...state,
			clustersList: action.clustersList,
			error: INITIAL_STATE.error,
			loading: false,
			isClustersListLoaded: true,
		};
	}
	if (getClustersListFail.is(action) || deleteClusterByIdFail.is(action) || getClustersMetricsFail.is(action)) {
		return {
			...state,
			error: action.error,
			loading: false,
		};
	}

	if (pullNDeployClustersListItem.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.clusterName]: {
					deploy: {
						...INITIAL_STATE.deploy,
						started: true,
					},
				},
			},
		};
	}

	if (pullNDeployClustersListItemFailed.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.clusterName]: {
					deploy: {
						...INITIAL_STATE.deploy,
						failed: true,
						completed: true,
					},
					errorMessage: action.error,
				},
			},
		};
	}

	if (pullNDeployClustersListItemSucceed.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.clusterName]: {
					deploy: {
						...state.deploy,
						started: false,
						completed: true,
						clusterDeployed: true,
					},
				},
			},
		};
	}

	if (resetPullNDeployClustersListItem.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.clusterName]: {
					deploy: INITIAL_STATE.deploy,
					errorMessage: '',
				},
			},
		};
	}

	if (clearClusterListError.is(action)) {
		return { ...state, error: INITIAL_STATE.error };
	}

	return state;
}
