import React from 'react';
import styles from '../../styles.module.scss';
import { AppCard, Head, Section, SectionContainer, Title } from '@app/shared/AppCard';
import { SvgIcon } from '@app/shared/SvgIcon';
import regionsLogo from '@icons/icon.cluster.regions.svg';
import nodesLogo from '@icons/icon.cluster.nodes.svg';
import { ApiNode } from '@app/data-modules/cluster-nodes';
import { ApiRegion } from '@app/data-modules/regions';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { GlobalHostname } from '@app/shared/globalHostname';
import { ClusterStatus } from '../../components/clusterStatus';

interface InputProps {
	nodes: ApiNode[];
	regions: ApiRegion[];
	loading: boolean;
	clusterId: number;
}

export const OverviewCard: React.FC<InputProps> = ({ nodes, regions, loading, clusterId }) => {
	return (
		<AppCard className={styles.overviewCard}>
			<Head className={styles.cardHead}>
				<Title text="Overview" />
				<GlobalHostname />
			</Head>
			<SectionContainer>
				<Section className={styles.overviewCard__item}>
					<span className={styles.title}>Regions</span>
					<div className={styles.content}>
						<div className={styles.content__icon}>
							<SvgIcon iconSrc={regionsLogo} />
						</div>
						<span className={styles.content__text}>
							{loading ? <AppSkeleton className={styles.cardTextSkeleton} /> : regions.length}
						</span>
					</div>
				</Section>
				<Section className={styles.overviewCard__item}>
					<span className={styles.title}>Nodes</span>
					<div className={styles.content}>
						<div className={styles.content__icon}>
							<SvgIcon iconSrc={nodesLogo} />
						</div>

						<span className={styles.content__text}>
							{loading ? <AppSkeleton className={styles.cardTextSkeleton} /> : nodes.length}
						</span>
					</div>
				</Section>
				<Section className={styles.overviewCard__item}>
					<span className={styles.title}>Status</span>
					<div className={styles.content}>
						{loading ? (
							<AppSkeleton className={styles.overviewCard__statusSkeleton} />
						) : (
							<ClusterStatus clusterId={clusterId} />
						)}
					</div>
				</Section>
			</SectionContainer>
		</AppCard>
	);
};
