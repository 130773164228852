import { takeEvery, call, put, take } from 'redux-saga/effects';
import { getPaymentInfoApi } from '../api';
import { ACCOUNT_BILLING_URL } from '../url';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { setAccountError, setPaymentInfo, getPaymentInfo } from '../actions';

// GET USER'S PAYMENT INFO ON LOC CHANGE
export function* watchGetPaymentInfoOnLocChange() {
	yield takeEvery(LOCATION_CHANGE, function* onNavigatePage(a: LocationChangeAction) {
		const urlMatch = ACCOUNT_BILLING_URL.match(a.payload.location, true);
		if (!urlMatch.isMatched) {
			return;
		}

		yield call(getPaymentInfoWorker);
	});
}

// GET USER'S PAYMENT INFO ON ACTION
export function* watchGetPaymentInfo() {
	while (true) {
		const action = yield take('*');
		if (getPaymentInfo.is(action)) {
			yield call(getPaymentInfoWorker);
		}
	}
}

function* getPaymentInfoWorker() {
	try {
		const result = yield call(getPaymentInfoApi);
		yield put(setPaymentInfo({ paymentInfo: result }));
	} catch (error) {
		yield put(setAccountError({ error }));
	}
}
