import React from 'react';
import styles from './style.module.scss';

interface BillinginfoBlockLayoutProps {
	title: string;
	children: JSX.Element;
	rightElem?: JSX.Element;
}

export const BillinginfoBlockLayout = ({ title, rightElem, children }: BillinginfoBlockLayoutProps) => (
	<div className={styles.billingInfoBlockLayout}>
		<div className={styles.billingInfoBlockLayout__header}>
			<div className={styles.billingInfoBlockLayout__headerTitle}>{title}</div>
			<div className={styles.billingInfoBlockLayout__rightElem}>{rightElem}</div>
		</div>
		<div className={styles.billingInfoBlockLayout__content}>{children}</div>
	</div>
);
