import { customYup as Yup, yupExceptions } from '@app/utils/customYup';

export default Yup.object().shape({
	email: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.email('Please enter a valid email')
		.max(320)
		.matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, `Please enter a valid email`)
		.required('This field can’t be empty'),
	firstName: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isFirstNameValid.regex, yupExceptions.isFirstNameValid.message)
		.required('First name is required!'),
	lastName: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isLastNameValid.regex, yupExceptions.isLastNameValid.message)
		.required('Last name is required!'),
	oldPassword: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isPasswordValid.regex, 'Old password need to be at least 8 characters long, contain at least 1 number')
		.matches(yupExceptions.isAllowedSymbolsInPassword.regex, yupExceptions.isAllowedSymbolsInPassword.message),
	newPassword: Yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isPasswordValid.regex, 'New password need to be at least 8 characters long, contain at least 1 number')
		.matches(yupExceptions.isAllowedSymbolsInPassword.regex, yupExceptions.isAllowedSymbolsInPassword.message),
});
