import { defineAction, defineActionGroup } from 'rd-redux-utils';
import { IErrorMessage } from '@app/shared/errorMessage';
import { NewPasswordValues } from '../components/newPassword';
import { MetaError } from '@app/types/metaError';

// SIGNIN USER
export const signinUser = defineAction<{
	email: string;
	password: string;
}>('SIGNIN: SIGNIN USER');
export const setSigninToken = defineAction<{ token: string }>('SIGNIN: SET TOKEN');
export const signoutUser = defineAction<{}>('SIGNIN: SIGNOUT USER');
export const setSigninError = defineAction<{ error: IErrorMessage }>('SIGNIN: SET ERROR');
export const clearSigninError = defineAction<{}>('SIGNIN: CLEAR ERROR');

// REGEN API TOKEN
export const resetAPITokenAG = defineActionGroup('RESET TOKEN');
export const resetAPIToken = resetAPITokenAG.defineAction('MAKE REQUEST');
export const resetAPITokenSuccess = defineAction<{ token: string }>('REQUEST SUCCEED');
export const resetAPITokenFail = defineAction<{ error: MetaError }>('REQUEST FAILED');

// RESET PASSWORD
export const resetPassword = defineAction<{
	email: string;
}>('SIGNIN: RESET PASSWORD');

// NEW PASSWORD
export const setNewPassword = defineAction<NewPasswordValues>('SIGNIN: SET NEW PASSWORD');
