import * as React from 'react';
import { AnchorContext, AnchorContextInterface } from './context';
import memo from 'memoize-one';

interface Props {
	children: any;
	initialActive?: string;
	scrollDuration?: number;
}

interface State {
	activeAnchor: string | null;
}

export class AnchorSectionNavigation extends React.Component<Props, State> {
	static defaultProps = {
		scrollDuration: 100,
		offset: 0,
	};

	private anchorMap: Map<string, HTMLElement> = new Map();

	state = {
		activeAnchor: this.props.initialActive || null,
	};

	buildContext = memo((anchorId: string | null) => {
		const context: AnchorContextInterface = {
			activeAnchorId: anchorId,
			registerSection: this.registerSection,
			removeSection: this.removeSection,
			transiteTo: this.transiteTo,
		};

		return context;
	});

	transiteTo = (id: string) => {
		if (!this.anchorMap.has(id)) throw new Error(`Section with id: ${id} not found.`);

		this.removeListener();

		const section = this.anchorMap.get(id);

		const offset_Y = this.getOffsetY(section as HTMLElement);
		const startY = window.scrollY;
		this.scroll(startY, offset_Y).then(this.setListener);

		this.setState({ activeAnchor: id });
	};

	scroll = (from: number, to: number): Promise<void> => {
		return new Promise(resolve => {
			const maximumScrollY = this.getMaximumScrollY();

			if (maximumScrollY < to && window.scrollY === maximumScrollY) return resolve();

			window.scrollTo({ top: to - 100, behavior: 'smooth' });

			const resolver = () => {
				if (to === window.scrollY || window.scrollY === maximumScrollY) {
					resolve();
					window.removeEventListener('scroll', resolver);
				}
			};
			window.addEventListener('scroll', resolver);
		});
	};

	getOffsetY = (element: HTMLElement): number => {
		return this.getOffset(element).top;
	};

	getOffset = (element: HTMLElement): { top: number; left: number } => {
		return getOffset(element);
	};

	getMaximumScrollY = (): number => document.documentElement.scrollHeight - document.documentElement.clientHeight;

	removeSection = (id: string) => {
		if (this.anchorMap.has(id)) {
			return this.anchorMap.delete(id);
		}
	};

	registerSection = (ref: HTMLElement, id: string) => {
		if (!this.anchorMap.has(id)) {
			this.anchorMap.set(id, ref);
		} else {
			throw new Error(`Anchor section with id:${id} already registered!`);
		}
	};

	scrollHandler = () => {
		this.setState({ activeAnchor: this.getActiveAnchorId(window.scrollY) });
	};

	getActiveAnchorId = (scrollPosition: number): string => {
		const registeredSections = Array.from(this.anchorMap);
		const scrollY = scrollPosition + 50;
		let latestPassedSectionId = this.state.activeAnchor || '';
		for (const [id, section] of registeredSections) {
			if (scrollY >= section.getBoundingClientRect().top && scrollY < section.offsetTop + section.offsetHeight) {
				latestPassedSectionId = id;
				break;
			}
		}
		return latestPassedSectionId;
	};

	componentWillUnmount() {
		this.removeListener();
	}

	removeListener = () => {
		window.removeEventListener('scroll', this.scrollHandler);
	};

	componentDidMount() {
		this.setListener();
	}

	setListener = () => {
		window.addEventListener('scroll', this.scrollHandler);
	};

	render() {
		const { children } = this.props;
		const { activeAnchor } = this.state;
		const context = this.buildContext(activeAnchor);
		return <AnchorContext.Provider value={context}>{children}</AnchorContext.Provider>;
	}
}

function getOffset(el: any) {
	let _x = 0;
	let _y = 0;
	while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return { top: _y, left: _x };
}
