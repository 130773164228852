import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	className?: string;
	text: string;
	uppercase?: boolean;
}

export const Title = (props: Props) => (
	<h1 className={classnames(styles.title, { [styles.title_uppercase]: props.uppercase }, props.className)}>
		{props.text}
	</h1>
);
