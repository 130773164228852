import { call, put } from 'redux-saga/effects';
import { addClusterNodes, addClusterNodesSuccess, addClusterNodesFail } from '../actions';
import { addClusterNodeRequest } from '../api';
import { getClusterNodesSaga } from './getClusterNodes';
import { getDeployWizardClusterSaga } from '@app/modules/deploy-wizard/state/saga/getDeployWizardClusterSaga';

export function* addClusterNodeSaga(action: ReturnType<typeof addClusterNodes>) {
	try {
		yield call(addClusterNodeRequest, action.clusterName, action.nodes);
		yield call(getClusterNodesSaga, action.clusterName);
		// get new version of cluster (used for logs on the last step of wizard)
		yield call(getDeployWizardClusterSaga, { name: action.clusterName });
		// set that getting cluster completed
		yield put(addClusterNodesSuccess({}));
	} catch ({ message }) {
		const errorMessage = `Failed to add cluster's nodes. Error: ${message}`;
		yield put(addClusterNodesFail({ error: { message: errorMessage } }));
	}
}
