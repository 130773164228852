import { take, call } from 'redux-saga/effects';
import { getRegionsAction } from '../actions';
import { getRegionsSaga } from './getRegionsSaga';

export function* watcherSaga() {
	while (true) {
		const action = yield take('*');
		if (getRegionsAction.is(action)) {
			yield call(getRegionsSaga);
		}
	}
}
