import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { SvgIcon } from '@app/shared/SvgIcon';
import { navigateTo } from '@app/utils/navigate-to';
import { ACCOUNT_SETTINGS_URL, ACCOUNT_BILLING_URL } from '@app/modules/account';
import { signoutUser } from '@app/modules/user-sign-in';
import api from '@app/apis/api';
import iconBilling from '@icons/icon.billing.svg';
import iconSettings from '@icons/icon.settings.svg';
import iconLogout from '@icons/icon.logout.svg';
import arrowDown from '@icons/icon.select.arrow.svg';
import { AppState } from '@app/reducers';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { UserAvatar } from '@app/shared/userAvatar';

export const AccountMenu: React.FC<{}> = () => {
	const [isActive, setIsActive] = useState(false);
	const dispatch = useDispatch();
	const { firstName, email, color } = useSelector((state: AppState) => state.account.settings);

	// show skeleton while no data received yet
	if (!firstName) {
		return <AppSkeleton className={styles.accountMenu__skeleton} />;
	}

	return (
		<div
			className={classnames(styles.accountMenu, { [styles.accountMenu_active]: isActive })}
			onMouseOver={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
		>
			<UserAvatar email={email} ownAvatarColor={color} className={styles.accountMenu__avatar} />
			{firstName}
			<SvgIcon iconSrc={arrowDown} className={styles.accountMenu__icon} />

			{isActive ? (
				<div className={styles.accountMenu__optionsWrap}>
					<div className={styles.accountMenu__optionsWrap__options}>
						<div
							className={styles.accountMenu__optionsWrap__options__option}
							onClick={() => {
								setIsActive(false);
								navigateTo(ACCOUNT_BILLING_URL.urlTemplate)();
							}}
						>
							<div className={styles.accountMenu__optionsWrap__options__option__icon}>
								<SvgIcon iconSrc={iconBilling} />
							</div>
							Billing
						</div>
						<div
							className={styles.accountMenu__optionsWrap__options__option}
							onClick={() => {
								setIsActive(false);
								navigateTo(ACCOUNT_SETTINGS_URL.urlTemplate)();
							}}
						>
							<div className={styles.accountMenu__optionsWrap__options__option__icon}>
								<SvgIcon iconSrc={iconSettings} />
							</div>
							Settings
						</div>
						<div
							className={styles.accountMenu__optionsWrap__options__option}
							onClick={() => {
								dispatch(signoutUser({}));
								api.removeToken();
							}}
						>
							<div
								className={classnames(
									styles.accountMenu__optionsWrap__options__option__icon,
									styles.accountMenu__optionsWrap__options__option__icon_danger
								)}
							>
								<SvgIcon iconSrc={iconLogout} />
							</div>
							Log out
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};
