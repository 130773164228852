import { call, take, put } from 'redux-saga/effects';
import { removeAccountMemberApi } from '../api';
import { removeAccountMember, getAccountMembersList } from '../actions';
import { TeamMember } from '../interfaces';

export function* watchRemoveAccountMember() {
	while (true) {
		const action = yield take('*');
		if (removeAccountMember.is(action)) {
			yield call(removeAccountMemberWorker, action);
		}
	}
}

function* removeAccountMemberWorker({
	memberData: { id },
	onSuccess,
	onFailure,
}: {
	memberData: Partial<TeamMember>;
	onSuccess: () => void;
	onFailure: () => void;
}) {
	try {
		yield call(removeAccountMemberApi, { id });
		yield put(getAccountMembersList({}));
		yield call(onSuccess);
	} catch (e) {
		yield call(onFailure);
	}
}
