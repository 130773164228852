import * as React from 'react';
import styles from './style.module.scss';
import { FormikProps, withFormik, Form } from 'formik';
import { DeployWizardForm, DeployWizardPortMapping } from '@app/modules/deploy-wizard/interfaces';
import { schema } from './schema';
import { PortMappingFieldList } from '@app/shared/PortMappingFieldList';
import { WarningDialog, BtnStyles } from '@app/shared/warningDialog/WarningDialog';

interface FormValues {
	ports: DeployWizardPortMapping[];
}

interface Props extends DeployWizardForm {
	initialValues: DeployWizardPortMapping[];
}

class PortMappingFormDumb extends React.Component<Props & FormikProps<FormValues>> {
	state = {
		showWarning: false,
	};

	checkPortsAndSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined): void => {
		const { values, handleSubmit, errors } = this.props;
		const isPorts =
			values.ports.length > 0 &&
			typeof values.ports[0].private !== 'undefined' &&
			values.ports[0].private !== null &&
			values.ports[0].private !== '' &&
			typeof values.ports[0].public !== 'undefined' &&
			values.ports[0].public !== null &&
			values.ports[0].public !== '';

		if (isPorts || errors.ports) {
			handleSubmit(e);
		} else {
			e?.preventDefault();
			this.setState({ showWarning: true });
		}
	};

	render() {
		const { formId, values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit } = this.props;
		return (
			<Form onSubmit={this.checkPortsAndSubmit} id={formId} className={styles.form}>
				<PortMappingFieldList
					blurHandler={handleBlur}
					touched={touched}
					errors={errors}
					changeHandler={handleChange}
					values={values}
					setFieldValue={setFieldValue}
					pathToValues="ports"
				/>
				{this.state.showWarning ? (
					<WarningDialog
						msg={`Are you sure you want to proceed? If you don’t add any ports your cluster won’t be accessible!`}
						btnAgreeText={'Proceed'}
						btnDismissText={'Go Back'}
						btnAgreeStyle={BtnStyles.AGREE_REGULAR}
						onAgree={e => handleSubmit(e as React.FormEvent<HTMLFormElement>)}
						onDismiss={() => {
							this.setState({ showWarning: false });
						}}
					/>
				) : null}
			</Form>
		);
	}
}

const newItemTemplate = { private: '', public: '', protocol: 'tcp' };
export const PortMappingForm = withFormik<DeployWizardForm, any>({
	mapPropsToValues: props => ({ ports: [...(props.initialValues?.ports || []), newItemTemplate] }),
	handleSubmit: (values: FormValues, { props: componentProps }) => {
		const filteredValues = values.ports.filter(port => port.private && port.public && port.protocol);
		componentProps.onSubmit({ ports: filteredValues });
	},
	validationSchema: schema,
})(PortMappingFormDumb);
