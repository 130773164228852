import { defineActionGroup, defineAction } from 'rd-redux-utils';
import { BillingWizardPortMapping, BillingInfoFormValues } from './interfaces';
import { IErrorMessage } from '@app/shared/errorMessage';

export const setBilling = defineActionGroup('SET_BILLING_ACTION');

export const setBillingAction = setBilling.defineAction<{
	firstName: string;
	lastName: string;
	company: string;
	vatId: string;
	address: string;
	city: string;
	postalCode: number;
	country: string;

	nameOnCard: string;
	cardDetails: string;
	dateAndCVC: string;

	ports: BillingWizardPortMapping[];
	registry?: { url: string; login: string; password: string };
}>('CREATE');

export const resetBillingWizardAction = defineAction('RESET_BILLING_WIZARD');

// send biling info to back for Stripe
export const sendBillingInfo = defineAction<{
	formValues: BillingInfoFormValues;
	onSuccess: () => void;
	onFinal: () => void;
}>('BILLING_WIZARD: SEND BILLING INFO');
export const setBillingInfoError = defineAction<{ error: IErrorMessage }>('BILLING_WIZARD: SET BILLING INFO ERROR');
// get/set client secret for stripe
export const getStripeClientSecret = defineAction<{}>('BILLING_WIZARD: GET CLIENT SECRET');
export const setStripeClientSecret = defineAction<{ clientSecret: string }>('BILLING_WIZARD: SET CLIENT SECRET');
