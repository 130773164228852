import { TeamState } from './state';
import { Action } from 'redux';
import {
	setInviteMembersError,
	setMembersList,
	setAccountSeats,
	setAccountSeatsError,
	clearAccountSeatsError,
} from './actions';
import { TeamMember } from './interfaces';

export const TAX_PER_SEAT = 5.0;
export const FREE_SEATS_AMOUNT = 3;

const INITIAL_STATE: TeamState = {
	membersList: [] as TeamMember[],
	inviteMembersError: { message: '' },
	accountSeats: { totalSeatsAmount: FREE_SEATS_AMOUNT, tax: TAX_PER_SEAT, totalDue: 0 },
	isMembersListLoaded: false,
	error: {
		message: '',
	},
};

export function teamReducer(state: TeamState = INITIAL_STATE, action: Action): TeamState {
	if (setMembersList.is(action)) {
		return {
			...state,
			membersList: action.membersList,
			isMembersListLoaded: true,
		};
	}

	if (setAccountSeats.is(action)) {
		return {
			...state,
			accountSeats: {
				...state.accountSeats,
				totalSeatsAmount: action.totalSeatsAmount,
				totalDue: (action.totalSeatsAmount - FREE_SEATS_AMOUNT) * TAX_PER_SEAT,
			},
		};
	}

	if (setAccountSeatsError.is(action)) {
		return {
			...state,
			accountSeats: {
				...state.accountSeats,
				error: action.error,
			},
		};
	}

	if (setInviteMembersError.is(action)) {
		return {
			...state,
			inviteMembersError: action.error,
		};
	}

	if (clearAccountSeatsError.is(action)) {
		return {
			...state,
			accountSeats: {
				...state.accountSeats,
				error: INITIAL_STATE.accountSeats.error,
			},
		};
	}

	return state;
}
