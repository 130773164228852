import React from 'react';
import styles from './style.module.scss';
import { useSelector } from 'react-redux';
import { UrlPath } from 'rd-url-utils';
import { Switch, Route } from 'react-router-dom';

import { SubMenuItem } from '@app/shared/subNavigation';
import { AppState } from '@app/reducers';
import { SubNavigation } from '@app/shared/subNavigation/SubNavigation';
import { AppRoute } from '@app/routes';
import { NotFound } from '@app/modules/not-found/components/NotFound';

import { InvoicesList } from './components/invoicesList';
import { BillingMain } from './components/billingMain';
import { ACCOUNT_BILLING_URL, ACCOUNT_BILLING_INVOICES_URL } from '../../state';

export const AccountBilling: React.FC<{}> = () => {
	const { location } = useSelector((state: AppState) => ({ location: state.router.location.pathname }));
	const [menuItems, setMenuItems] = React.useState<SubMenuItem[]>([]);
	const [activeItemIdx, setActiveItemIndex] = React.useState(0);

	React.useEffect(() => {
		const menuItems = createMenuItems();
		menuItems.forEach((item, itemIdx) => {
			const urlMatch = item.urlPath.match(location, true);
			if (urlMatch.isMatched) {
				setActiveItemIndex(itemIdx);
			}
		});

		setMenuItems(menuItems);
	}, [location]);

	return (
		<div className={styles.accountBilling}>
			<div className={styles.accountBilling__subNavWrapper}>
				{menuItems.length ? (
					<SubNavigation
						className={styles.accountBilling__subNavWrapper__subNav}
						items={menuItems}
						activeItemIdx={activeItemIdx}
					/>
				) : null}
			</div>

			<div className={styles.accountBilling__content}>
				<Switch>
					<AppRoute path={ACCOUNT_BILLING_URL.urlTemplate} exact={true} auth={true}>
						<BillingMain />
					</AppRoute>

					<AppRoute path={ACCOUNT_BILLING_INVOICES_URL.urlTemplate} exact={true} auth={true}>
						<InvoicesList />
					</AppRoute>

					<Route path="*">
						<NotFound className={styles.notFoundLayout} />
					</Route>
				</Switch>
			</div>
		</div>
	);
};

interface AccountBillingSubMenuItem extends SubMenuItem {
	urlPath: UrlPath<{}, {}>;
}

const createMenuItems = (): AccountBillingSubMenuItem[] => {
	return [
		{
			name: 'Billing',
			url: ACCOUNT_BILLING_URL.urlTemplate,
			urlPath: ACCOUNT_BILLING_URL,
		},
		{
			name: 'Invoices',
			url: ACCOUNT_BILLING_INVOICES_URL.urlTemplate,
			urlPath: ACCOUNT_BILLING_INVOICES_URL,
		},
	];
};
