import * as React from 'react';
import styles from './style.module.scss';
import { AppTable } from '@app/shared/AppTable';
import { AppButton } from '@app/shared/buttons/AppButton';
import { Pagination } from '@app/shared/Pagination';
import { connect } from 'react-redux';
import { AppState } from '@app/reducers';
import { AppSpinner } from '@app/shared/AppSpinner';
import { ICluster } from '../cluster';
import { Dispatch, compose } from 'redux';
import { NodeStatus } from '@app/shared/text/NodeStatus';
import { TextWithSubtitle } from '@app/shared/text/TextWithSubtitle';
import moment from 'moment';
import classnames from 'classnames';
import { ClusterPageLayout } from '../layouts/ClusterPageLayout';
import {
	getActivityLogItemsSelector,
	getActivityLogFetchingStateSelector,
	getActivityLogLoadedStateSelector,
	ApiActivityLog,
	getRollbackRequestProcessStateSelector,
	getActivityLogPageSelector,
	ActivityLogPagination,
} from '@app/data-modules/activity-log';
import { rollbackClusterAction, setClusterActivityLogPage } from '@app/data-modules/activity-log/actions';

type logActionType = [
	string, // UI name
	boolean // is rollback allowed
];

const logActionTypeToViewMap: Record<string, logActionType> = {
	'create': ['Create', true],
	'update': ['Deploy Settings', true],
	'deploy': ['Pull and Deploy', true],
	'rollback': ['Rollback', false],
	'rebuild': ['Rebuild', false],
	'node-add': ['Add Node', false],
	'node-remove': ['Remove Node', false],
	'node-remove-all': ['Remove All Nodes', false],
	'domain-add': ['Add Domain', false],
	'domain-remove': ['Remove Domain', false],
	'https-add': ['Enable HTTPS', false],
	'https-remove': ['Disable HTTPS', false],
	'https-renew': ['Renew HTTPS', false],
};

interface InputProps {
	cluster: ICluster;
	isReadonlyCluster: boolean;
}

interface Props extends InputProps {
	logs: ApiActivityLog[];
	isFetching: boolean;
	isLoaded: boolean;
	rollBack: (version: number) => void;
	setPage: (page: number) => void;
	rollbackInProcess: boolean;
	pagination: ActivityLogPagination | null;
}

class ClusterActivityLogDumb extends React.PureComponent<Props> {
	formatDigest = (digest: string) => {
		return digest.replace('sha256:', '').split('').slice(0, 10).join('');
	};

	render() {
		const {
			isLoaded,
			isFetching,
			logs = [],
			cluster,
			rollbackInProcess,
			setPage,
			pagination,
			isReadonlyCluster,
		} = this.props;

		return (
			<ClusterPageLayout className={styles.activityLog}>
				<ClusterPageLayout.Head titleSection={<ClusterPageLayout.Title text="Activity" />} />
				<ClusterPageLayout.Content>
					{isFetching ? <AppSpinner /> : null}
					{isLoaded && logs.length ? (
						<AppTable roundBorders={true}>
							<AppTable.Head>
								<AppTable.Row>
									<AppTable.Col className={styles.cell}>Action</AppTable.Col>
									<AppTable.Col className={classnames(styles.cell)} textAlign="center">
										Docker Image
									</AppTable.Col>
									<AppTable.Col className={styles.cell}>Cluster Version</AppTable.Col>
									<AppTable.Col className={styles.cell}>Status</AppTable.Col>
									<AppTable.Col className={styles.cell} />
								</AppTable.Row>
							</AppTable.Head>
							<AppTable.Body>
								{logs.map((log, index) => {
									const [title, isRollbackEnabled] = logActionTypeToViewMap[log.action];
									const rollbackToVersion =
										log.action === 'rollback' && log.rollbackTo?.version
											? ` to v.${log.rollbackTo?.version}`
											: '';
									return (
										<AppTable.Row key={index}>
											<AppTable.Col className={styles.cell}>
												<TextWithSubtitle
													text={`${title}${rollbackToVersion}`}
													subtitle={moment(log.createdAt).format('DD-MM-YYYY HH:mm:ss')}
												/>
											</AppTable.Col>
											<AppTable.Col className={classnames(styles.cell, styles.cell__dockerImage)}>
												<TextWithSubtitle
													text={log.version.image}
													subtitle={`tag: ${log.version.tag} digest:${this.formatDigest(
														log.version.digest
													)}`}
												/>
											</AppTable.Col>
											<AppTable.Col
												className={styles.cell}
											>{`Version ${log.version.version}`}</AppTable.Col>
											<AppTable.Col className={styles.cell}>
												<NodeStatus renderIcon={false} nodeId={0} tempPropRmMe={true} />
											</AppTable.Col>
											<AppTable.Col className={classnames(styles.cell, styles.cell__btn)}>
												<AppButton
													className={styles.btn}
													onClick={() => this.props.rollBack(log.version.version)}
													disabled={
														cluster.currentVersion.version === log.version.version ||
														rollbackInProcess ||
														!isRollbackEnabled ||
														isReadonlyCluster
													}
												>
													Roll back to here
												</AppButton>
											</AppTable.Col>
										</AppTable.Row>
									);
								})}
							</AppTable.Body>
						</AppTable>
					) : null}
					{isLoaded && !logs.length ? (
						<div className={styles.emptyListMessage}>You don't have any activity logs yet.</div>
					) : null}
					{pagination && (
						<Pagination
							count={pagination.pages}
							active={pagination.current}
							onChangePage={setPage}
							className={styles.paginationContainer}
						/>
					)}
				</ClusterPageLayout.Content>
			</ClusterPageLayout>
		);
	}
}

export const ClusterActivityLog = compose<React.ComponentType<InputProps>>(
	connect(
		(state: AppState, ownProps: InputProps) => {
			const { cluster } = ownProps;
			return {
				logs: getActivityLogItemsSelector(state, cluster.name),
				pagination: getActivityLogPageSelector(state, cluster.name),
				isFetching: getActivityLogFetchingStateSelector(state, cluster.name),
				isLoaded: getActivityLogLoadedStateSelector(state, cluster.name),
				rollbackInProcess: getRollbackRequestProcessStateSelector(state, cluster.name),
			};
		},
		(dispatch: Dispatch, ownProps: InputProps) => {
			return {
				setPage: (page: number) =>
					dispatch(setClusterActivityLogPage({ clusterName: ownProps.cluster.name, page })),
				rollBack: (version: number) =>
					dispatch(rollbackClusterAction({ clusterName: ownProps.cluster.name, versionId: version })),
			};
		}
	)
)(ClusterActivityLogDumb);
