import { call, take, put } from 'redux-saga/effects';
import { regenAPIToken } from '../api';
import { resetAPIToken, resetAPITokenFail } from '../actions';
import api from '@app/apis/api';

export function * watchResetAPIToken() {
	while (true) {
		const action = yield take('*');
		if (resetAPIToken.is(action)) {
			yield call(resetAPITokenWorker);
		}
	}
}

function * resetAPITokenWorker() {
	try {
		let token = yield call(regenAPIToken);
		yield call(api.setToken, token);
	} catch (error) {
		yield put(resetAPITokenFail( { error }));
	}
}
