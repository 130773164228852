import { createPath } from 'rd-url-utils';

export const ACCOUNT_URL = createPath('/account');
export const ACCOUNT_SIGNUP_URL = createPath('/register');
export const USER_SIGNUP_URL = createPath('/register/:inviteId/:hash');
export const CHECK_EMAIL_URL = createPath('/check-email');
export const CHECK_EMAIL_AFTER_RESET_URL = createPath('/check-reset-email');
export const VERIFY_EMAIL_URL = createPath('/verify-email/:token');
export const ACCOUNT_SETTINGS_URL = createPath('/account/settings');
export const ACCOUNT_BILLING_URL = createPath('/account/billing');
export const ACCOUNT_BILLING_INVOICES_URL = createPath('/account/billing/invoices');
