import api from '@app/apis/api';
import { BillingInfoFormStripeValues } from './interfaces';

// POST /api/billing/info
export const sendBillingInfoApi = async (formValues: BillingInfoFormStripeValues): Promise<any> =>
	await api.post('/api/billing/info', formValues);

// POST /api/billing/payment-method to create new client-secret and get it back
export const getClientSecretApi = async (): Promise<any> =>
	await api.post('/api/billing/payment-method', {});
