import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { DeployWizardStepProps } from '../../interfaces';
import { CenteredContentLayout } from '../../components/layouts/CenteredContentLayout';
import Scrollbars, { positionValues as scrollbarsPositionValues } from 'react-custom-scrollbars';
import { ScrollThumb, ScrollTrackHorizontal } from './ScrollFeatures';
import { DeployWizardIncrementInput } from '../../components/forms/components/inputs/DeployWizardIncrementInput';
import { RegionBar } from '../../components/RegionBar';
import { MapComponent } from '@app/shared/MapComponent';
import { points } from './points';
import { RegionMapPoint } from './components/RegionMapDot';
import { LabelWithTooltip } from '@app/shared/text/LabelWithTooltip';
import { compose } from 'redux';
import { AppState } from '@app/reducers';
import { connect } from 'react-redux';
import { mapSizeToRegions } from './mapUtils/mapSizeToRegions';
import { ApiRegion } from '@app/data-modules/regions';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { regionsStepMeta, REGIONS_STEP_ID } from './meta';
import { RESOURCES_STEP_ID } from '../ResourcesStep';
import memo from 'memoize-one';
import { BillingInfoSuscriptionStatus } from '@app/modules/account/state/interfaces';

type Point = typeof points[number];

export type Node = Point;

export interface RegionStepNode {
	amount: number;
	region: ApiRegion;
}

export type RegionsStepValues = Record<string, RegionStepNode>;

interface Props extends DeployWizardStepProps {
	regions: ApiRegion[];
	subscriptionStatus: BillingInfoSuscriptionStatus;
}

interface State {
	initAmount: number;
	init: Record<string, RegionStepNode>;
	values: Record<string, RegionStepNode>;
}

const MAX_NODES_AMOUNT_PER_REGION = 9;
const MAX_NODES_AMOUNT_FOR_TRIAL = 2;
const getAmountOfNodes = (nodes: any) => Object.values(nodes).filter((i: any) => i.amount).length;

let scrollFrameTimeout: NodeJS.Timeout;

class RegionsStepDumb extends React.Component<Props, State> {
	private scrollRef = React.createRef<Scrollbars>();
	constructor(props: Props) {
		super(props);
		const value = this.props.stepValues[REGIONS_STEP_ID] || {};
		const { nodes, init: initValue } = value;
		const init = initValue || value || ({} as State['init']);
		this.state = {
			initAmount: getAmountOfNodes(init),
			init,
			values: nodes || ({} as State['values']),
		};
	}

	handleChange = (region: ApiRegion, value: number) => {
		this.setState({
			values: {
				...this.state.values,
				[region.code]: {
					amount: value,
					region: region,
				},
			},
		});
	};

	handleNextClick = () => {
		const { values, init } = this.state;
		let nodes = {};
		Object.keys(values).forEach(region => {
			if (values[region].amount) {
				nodes = {
					...nodes,
					[region]: values[region],
				};
			}
		});
		this.props.next(this.props.step.name, { nodes, init });
	};

	getSelectedRegionsAmount = memo((memoizedState: State): number => {
		return getAmountOfNodes(memoizedState.values);
	});

	getNodesAmount = (): number => {
		let nodesAmount = 0;
		for (const node of Object.values(this.state.values)) nodesAmount += node.amount;
		return nodesAmount;
	};

	handleScrollFrame = (values: scrollbarsPositionValues) => {
		scrollFrameTimeout && clearTimeout(scrollFrameTimeout);
		scrollFrameTimeout = setTimeout(() => {
			const { length: itemsAmount = 0 } = this.props.regions;
			if (!itemsAmount) return;
			const { scrollTop = 0, scrollHeight = 0 } = values;
			const itemHeight = scrollHeight / itemsAmount;
			const hiddenItems = Math.round(scrollTop / itemHeight);
			this.scrollRef.current?.scrollTop(hiddenItems * itemHeight);
		}, 35);
	};

	render() {
		const { values, init, initAmount } = this.state;
		const { getPrevStep, getNextStep, step, prev, regions, subscriptionStatus } = this.props;
		const prevStep = getPrevStep();
		const nextStep = getNextStep();
		const selectedRegionsAmount = this.getSelectedRegionsAmount(this.state);
		const overLimit =
			subscriptionStatus === BillingInfoSuscriptionStatus.TRIAL &&
			this.getNodesAmount() >= MAX_NODES_AMOUNT_FOR_TRIAL;

		return (
			<CenteredContentLayout
				title={regionsStepMeta.title}
				subtitle={regionsStepMeta.subtitle}
				controls={
					<div className={classnames(styles.controls, { [styles.controls_singleBtn]: !prevStep })}>
						{prevStep && (
							<StepNavigationButton stepName={prevStep.meta.btnText} onClick={prev} type="prev" />
						)}
						{nextStep && (
							<StepNavigationButton
								formId={step.name}
								type="next"
								onClick={this.handleNextClick}
								disabled={!selectedRegionsAmount}
								stepName={nextStep.meta.btnText}
							/>
						)}
					</div>
				}
			>
				<div className={styles.regionsStep}>
					<div className={styles.regionListWrapper}>
						<Scrollbars
							ref={this.scrollRef}
							renderThumbVertical={ScrollThumb}
							renderTrackHorizontal={ScrollTrackHorizontal}
							onScrollFrame={this.handleScrollFrame}
						>
							<ul className={styles.list}>
								{regions.map((point, index) => {
									const currentValue = values[point.code]?.amount || 0;
									const initValue = init[point.code]?.amount || 0;
									return (
										<li className={styles.list_item} key={index}>
											<RegionBar
												countryCode={point.country}
												label={point.code}
												className={classnames({ [styles.active]: currentValue })}
											>
												<DeployWizardIncrementInput
													value={currentValue}
													init={initValue}
													overLimit={overLimit}
													max={overLimit ? currentValue : MAX_NODES_AMOUNT_PER_REGION}
													className={styles.input}
													onChange={value => this.handleChange(point, value)}
												/>
											</RegionBar>
										</li>
									);
								})}
							</ul>
						</Scrollbars>
					</div>
					<div className={styles.mapWrapper}>
						<div className={styles.hint}>
							<i
								className={classnames(
									styles.status,
									selectedRegionsAmount + initAmount > 1 ? styles.status__good : styles.status__warn
								)}
							/>
							<LabelWithTooltip
								className={styles.hintLabel}
								iconClassName={styles.hintLabelIcon}
								popupProps={{
									inverted: true,
									wide: true,
								}}
								labelText="Global High-Availability"
								tooltipText="To take advantage of our smart failover you need to have at least 2 nodes globally. And to withstand region downtime you should select more than 2 regions."
							/>
						</div>
						<MapComponent
							className={styles.map}
							points={regions.map(region => {
								const nodeAmount = values[region.code]?.amount || 0;
								return {
									lat: +region.latitude,
									lng: +region.longitude,
									component: RegionMapPoint,
									componentProps: {
										open: true,
										active: !!nodeAmount,
										regionName: region.code,
										flagName: region.country,
										onClick: () => {
											if (overLimit) return;
											if (nodeAmount + 1 > MAX_NODES_AMOUNT_PER_REGION) return;
											if (Number.isNaN(nodeAmount) && String(nodeAmount).length) return;
											this.handleChange(region, nodeAmount + 1);
										},
									},
								};
							})}
						/>
					</div>
				</div>
			</CenteredContentLayout>
		);
	}
}

// @ts-ignore
export const RegionsStep = compose<React.ComponentType<DeployWizardStepProps>>(
	connect((state: AppState, ownProps: DeployWizardStepProps) => {
		const { size } = ownProps.stepValues[RESOURCES_STEP_ID];
		return {
			regions: size ? mapSizeToRegions(state, size)(state) : [],
			subscriptionStatus: state.account.billingInfo.subscriptionStatus,
		};
	})
)(RegionsStepDumb);
