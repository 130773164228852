import { RegistriesState } from './state';
import { Action } from 'redux';
import { setRegistries, setRegistriesError, clearRegistriesError, getRegistries } from './actions';

const INITIAL_STATE = {
	registries: [],
	error: {
		message: '',
		snack: true,
	},
	isFetching: true,
};

export function registriesReducer(state: RegistriesState = INITIAL_STATE, action: Action): RegistriesState {
	if (setRegistries.is(action)) {
		return {
			...state,
			registries: action.registries,
			error: INITIAL_STATE.error,
			isFetching: false,
		};
	}
	if (setRegistriesError.is(action)) {
		return {
			...state,
			error: {
				...state.error,
				...action.error,
				snack: action.snack,
			},
		};
	}

	if (getRegistries.is(action)) {
		return {
			...state,
			isFetching: true,
		};
	}

	if (clearRegistriesError.is(action)) {
		return { ...state, error: INITIAL_STATE.error };
	}
	return state;
}
