import React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { formatterUSDCurrency } from '@app/utils/formatterUSDCurrency';

interface Props {
	name: string;
	memory: number;
	cpu: number;
	disk_capacity: number;
	price: {
		hourly: number;
		monthly: number;
	};
	handleSelectPlan: () => void;
	isPlanSelected: boolean;
	className?: string;
	notAvailable?: boolean;
}

export const ResourceItem: React.FC<Props> = ({
	memory,
	cpu,
	price,
	className,
	name,
	handleSelectPlan,
	isPlanSelected,
	notAvailable,
}) => {
	return (
		<div
			className={classnames(
				styles.resourceItem,
				className,
				{ [styles.resourceItem_selected]: isPlanSelected },
				{ [styles.resourceItem_notAvailable]: notAvailable }
			)}
			onClick={notAvailable ? () => {} : handleSelectPlan}
		>
			<div className={styles.resourceItem__name}>{name}</div>

			<div className={styles.resourceItem__memory}>
				<span className={styles.resourceItem__memory_text}>{`Memory / `}</span>
				<span className={styles.resourceItem__memory_value}>{`${memory}GB`}</span>
			</div>

			<div className={styles.resourceItem__cpuCores}>
				<span className={styles.resourceItem__cpuCores_text}>{'vCores / '}</span>
				<span className={styles.resourceItem__cpuCores_value}>{cpu}</span>
			</div>

			<div className={styles.resourceItem__separator} />

			<div className={styles.resourceItem__price}>{`$${Number(price.monthly / 100).toFixed(0)}/mo`}</div>

			<div className={styles.resourceItem__perHour}>
				{`${formatterUSDCurrency({ minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(
					price.hourly / 100
				)}/h`}
			</div>
		</div>
	);
};
