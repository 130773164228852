import React from 'react';
import styles from './styles.module.scss';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { LabeledInputRegular } from '../../../../shared/labeledInputRegular';

const CARD_NUMBER_ELEMENT_OPTIONS = {
	showIcon: true,
	classes: {
		base: styles.stripeCard__number,
		focus: styles.stripeCard__number_focus,
		invalid: styles.stripeCard__number_invalid,
	},
	style: {
		base: {
			color: '#000000',
			fontWeight: '400',
			fontSize: '16px',
			letterSpacing: '0.16px',
			fontFamily: 'Raleway',
		},
	},
};
const CARD_CVC_ELEMENT_OPTIONS = {
	classes: {
		base: styles.stripeCard__cvc,
		focus: styles.stripeCard__cvc_focus,
		invalid: styles.stripeCard__cvc_invalid,
	},
	style: {
		base: {
			color: '#000000',
			fontWeight: '400',
			fontSize: '16px',
			letterSpacing: '0.16px',
			fontFamily: 'Raleway',
		},
	},
};
const CARD_EXPIRY_ELEMENT_OPTIONS = {
	classes: {
		base: styles.stripeCard__expiry,
		focus: styles.stripeCard__expiry_focus,
		invalid: styles.stripeCard__expiry_invalid,
	},
	style: {
		base: {
			color: '#000000',
			fontWeight: '400',
			fontSize: '16px',
			letterSpacing: '0.16px',
			fontFamily: 'Raleway',
		},
	},
};

export const StripeCard: React.FC<{ nameOnCard: string }> = ({ nameOnCard }) => {
	return (
		<div className={styles.stripeCard}>
			<LabeledInputRegular
				className={styles.stripeCard__name}
				inputValue={nameOnCard}
				inputName="name"
				labelText={'Name on card'}
				disabled={true}
			/>
			<div className={styles.stripeCard__numberWrap}>
				<span>Card details</span>
				<CardNumberElement options={CARD_NUMBER_ELEMENT_OPTIONS} />
			</div>

			<div className={styles.stripeCard__cvcExpiryWrap}>
				<CardExpiryElement options={CARD_EXPIRY_ELEMENT_OPTIONS} />

				<CardCvcElement options={CARD_CVC_ELEMENT_OPTIONS} />
			</div>
		</div>
	);
};
