import { call, takeEvery, put, take } from "redux-saga/effects";
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { getClustersListApi } from "../api";
import { CLUSTERS_LIST_URL } from "../url";
import { getClusterListSuccess, getClustersListFail, getClustersList } from "../actions";

export function* watchGetClustersListOnLocChange() {
    yield takeEvery(LOCATION_CHANGE, function* onNavigateClustersListPage(a: LocationChangeAction) {
        const urlMatch = CLUSTERS_LIST_URL.match(a.payload.location, true);
        if (!urlMatch.isMatched) {
            return;
        }

        yield call(getClustersListWorker);
    });
}

export function* watchGetClustersList() {
    while (true) {
        const action = yield take("*");
        if (getClustersList.is(action)) {
            yield call(getClustersListWorker);
        }
    }
}

function* getClustersListWorker() {
    try {
        const result = yield call(getClustersListApi);
        yield put(getClusterListSuccess({ clustersList: result }));
    } catch (e) {
        yield put(getClustersListFail({ error: { message: e.message } }));
    }
}
