import { Injectable } from "../entities/Injectable";

export class InjectorObserver {
    private binder: ((listeners: Injectable[]) => void) | null = null;
    private injectables: Set<Injectable> = new Set();

    register = (item: Injectable) => {
        this.injectables.add(item);
        this.notifyContainer();
    };

    remove = (item: Injectable) => {
        this.injectables.delete(item);
        this.notifyContainer();
    };

    update = (item: Injectable) => {
        if (this.injectables.has(item)) {
            this.notifyContainer();
        }
    };

    registerBinder = (binder: (listeners: Injectable[]) => void) => {
        this.binder = binder;
    };

    notifyContainer = () => {
        this.binder && this.binder(Array.from(this.injectables));
    };
}
