import React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { ProgressBarStatus } from '@app/modules/deploy-wizard/steps/DeployStep';

interface Props {
	clusterName: string;
	status: ProgressBarStatus;
	icon: any;
}

export const AppInfoBlock: React.FC<Props> = ({ clusterName, status, icon }) => (
	<div className={styles.appInfoBlock}>
		<div className={styles.appInfoBlock__info}>
			<span className={styles.appInfoBlock__infoIcon}>{icon}</span>
			<span>{clusterName}</span>
		</div>
		<div className={classnames(styles.appInfoBlock__status, getStatusClassName(status))}>{status}</div>
	</div>
);

const getStatusClassName = (status: ProgressBarStatus) => {
	switch (status) {
		case 'Deploying':
			return styles.appInfoBlock__status_deploying;
		case 'Failed':
			return styles.appInfoBlock__status_failed;
		case 'Incomplete':
			return styles.appInfoBlock__status_incomplete;
		case 'Done':
			return styles.appInfoBlock__status_done;
		default:
			return styles.appInfoBlock__status_unknown;
	}
};
