import React from 'react';
import { CustomModal } from '../customModal';
import { Button } from 'semantic-ui-react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '../SvgIcon';
import warningIcon from '@icons/icon.oval.orange.info.svg';

export enum BtnStyles {
	DISMISS_REGULAR = 'btn btn-three',
	AGREE_REGULAR = 'btn btn-one',
	AGREE_WARNING = `btn btn-six`,
}

interface Props {
	msg: string;
	onDismiss: () => void;
	onAgree: (
		e?: React.FormEvent<HTMLFormElement> | undefined | React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void;
	btnDismissText?: string;
	btnAgreeText?: string;
	btnAgreeStyle?: BtnStyles;
	btnDismissStyle?: BtnStyles;
	btnAgreeCustomStyle?: string;
	btnDismissCustomStyle?: string;
}

export const WarningDialog: React.FC<Props> = ({
	msg,
	onDismiss,
	onAgree,
	btnAgreeText = 'Confirm',
	btnDismissText = 'Cancel',
	btnAgreeStyle = BtnStyles.AGREE_WARNING,
	btnDismissStyle = BtnStyles.DISMISS_REGULAR,
	btnAgreeCustomStyle = '',
	btnDismissCustomStyle = '',
}) => {
	return (
		<CustomModal>
			<div className={styles.warningDialog}>
				<div className={styles.warningDialog__msgBlock}>
					<div className={styles.warningDialog__msgBlock__iconBlock}>
						<SvgIcon iconSrc={warningIcon} className={styles.warningDialog__msgBlock__icon} />
					</div>
					<div className={styles.warningDialog__msgBlock__msg}>{msg}</div>
				</div>

				<div className={styles.warningDialog__ctrls}>
					<Button
						type="button"
						className={classnames(btnDismissStyle, styles.warningDialog__ctrls__dismiss, {
							[btnDismissCustomStyle]: !!btnDismissCustomStyle,
						})}
						onClick={onDismiss}
					>
						{btnDismissText}
					</Button>
					<Button
						type="submit"
						className={classnames(
							btnAgreeStyle,
							styles.warningDialog__ctrls__agree,
							{
								[styles.warningDialog__ctrls__agree_warning]: btnAgreeStyle === BtnStyles.AGREE_WARNING,
							},
							{ [btnAgreeCustomStyle]: !!btnAgreeCustomStyle }
						)}
						onClick={onAgree}
					>
						{btnAgreeText}
					</Button>
				</div>
			</div>
		</CustomModal>
	);
};
