import React from 'react';
// import { LabeledInputRegular } from '@app/shared/labeledInputRegular';
import styles from './styles.module.scss';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { CopyToClipboardBtn } from '@app/shared/copyToClipboardBtn';

interface ClusterSettingsLocalCacheProps {
	directory: string;
	handleChange?: (e: React.ChangeEvent) => void;
	loading: boolean;
	isSubmitting: boolean;
}

export const ClusterSettingsLocalCache: React.FC<ClusterSettingsLocalCacheProps> = ({
	directory = '',
	loading,
	isSubmitting,
}) => {
	const directoryRef = React.useRef<HTMLElement>(null);

	return (
		<div id="cache" className={styles.localCache}>
			<div className={styles.localCache__blockHead}>{'Local Cache'}</div>
			<div className={styles.localCache__sections}>
				<div className={styles.localCache__sections__section}>
					<div className={styles.localCache__sections__section__directory}>
						<div className={styles.localCache__sections__section__directory__label}>{'Directory'}</div>
						<div className={styles.localCache__sections__section__directory__content}>
							{loading ? (
								<AppSkeleton
									className={styles.localCache__sections__section__directory__content__skeleton}
								/>
							) : (
								<>
									<span ref={directoryRef}>{directory}</span>
									<CopyToClipboardBtn refToCopyFrom={directoryRef} />
								</>
							)}
						</div>
					</div>
				</div>
				<div className={styles.localCache__sections__section}>
					<div className={styles.localCache__infoBox}>
						<p>
							You can use the available ephemeral storage for temporary caching of data to improve the
							performance of your application{' '}
							<a
								href="https://learn.appfleet.com/en/articles/4579816-local-cache"
								target="_blank"
								rel="noopener noreferrer"
							>
								More information
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
