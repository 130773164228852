import { call, take, put } from 'redux-saga/effects';
import { deleteRegistryApi } from '../api';
import { deleteRegistry, setRegistriesError, getRegistries } from '../actions';

export function* watchDeleteRegistry() {
	while (true) {
		const action = yield take('*');
		if (deleteRegistry.is(action)) {
			yield call(deleteRegistryWorker, { ...action });
		}
	}
}

function* deleteRegistryWorker({ id, handleResponse }: { id: number; handleResponse: () => void }) {
	try {
		yield call(deleteRegistryApi, id);

		yield put(getRegistries({}));
		yield call(handleResponse);
	} catch (e) {
		yield put(setRegistriesError({ error: { message: e.message } }));
		yield call(handleResponse);
	}
}
