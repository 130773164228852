import { defineActionGroup } from 'rd-redux-utils';
import { ApiNodeMetric, ApiNodeMetricName, MetricValue, MetricPeriods } from './entity';
import { LoadingStatus } from './state';
import { IErrorMessage } from '@app/shared/errorMessage';

interface BaseType {
	clusterName: string;
}

const clusterMetricActionGroup = defineActionGroup<BaseType>('CLUSTER METRIC');

export const getClusterNodesMetricAction = clusterMetricActionGroup.defineAction<{ period: MetricPeriods }>(
	'CLUSTER NODES METRIC'
);

export const setClusterNodeMetricForPeriodAction = clusterMetricActionGroup.defineAction<{
	metric: ApiNodeMetric | null;
	metricMaxValues: Record<string, number>;
	period: MetricPeriods;
}>('SET CLUSTER NODE METRIC FOR PERIOD');

export const setClusterMetricsErrorAction = clusterMetricActionGroup.defineAction<{ error: string }>('SET ERROR');

export const setClusterNodesMetricForPeriodLoadedAction = clusterMetricActionGroup.defineAction<{
	loadingStatus: LoadingStatus;
}>('SET CLUSTER NODES METRIC LOADED FLAG');

export const initClusterMetricAction = clusterMetricActionGroup.defineAction('INIT CLUSTER METRIC DATA');

// GET LATEST METRICS OF THE CLUSTER
export const getLatestClusterMetricsAG = defineActionGroup('GET LATEST METRICS OF CLUSTER');
export const getLatestClusterMetrics = getLatestClusterMetricsAG.defineAction<{
	clusterName: string;
}>('MAKE REQUEST');
export const getLatestClusterMetricsSuccess = getLatestClusterMetricsAG.defineAction<{
	clusterName: string;
	metric: Record<ApiNodeMetricName, MetricValue>;
}>('REQUEST SUCCEED');
export const getLatestClusterMetricsFail = getLatestClusterMetricsAG.defineAction<{
	clusterName: string;
	error: IErrorMessage;
}>('REQUEST FAILED');
