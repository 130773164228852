import * as React from 'react';
import { ModalProps } from '@app/utils/createModals';
import { WideScreenModalLayout } from '@app/shared/layouts/WideScreenModalLayout';
import { BillingWizard } from '@app/modules/billing-wizard';
import { wizardSteps } from './wizard-steps';
import { compose } from 'redux';
import { connect } from 'react-redux';
import memo from 'memoize-one';

type InputProps = ModalProps;

interface Props extends InputProps {}

class AddPaymentCardWizardModalDumb extends React.Component<Props> {
	getInitialWizardValues = memo(() => {
		return {};
	});

	render() {
		const { open, close } = this.props;
		const initialValues = this.getInitialWizardValues();

		return (
			<WideScreenModalLayout onOuterClick={close}>
				<BillingWizard
					open={open}
					close={close}
					steps={wizardSteps}
					onDone={() => {}}
					initialValues={initialValues}
				/>
			</WideScreenModalLayout>
		);
	}
}

export const AddPaymentCardWizardModal = compose<React.ComponentType<InputProps>>(connect(null, null))(
	AddPaymentCardWizardModalDumb
);
