import { MetricPeriods } from '@app/data-modules/cluster-metrics';

export const metricPeriodOptions: { value: MetricPeriods; text: string }[] = [
	{
		value: '6h',
		text: 'Last 6 hours',
	},
	{
		value: '24h',
		text: 'Last 24 hours',
	},
	{
		value: '7d',
		text: 'Last 7 days',
	},
	{
		value: '30d',
		text: 'Last 30 days',
	},
];
