import { all } from "redux-saga/effects";
import { watchGetClustersListOnLocChange, watchGetClustersList } from "./getClustersList";
import { watchDeleteClusterById } from "./deleteClusterById";
import { watchGetClusterRegions } from "./getClusterRegions";
import { watchGetNodeSizes } from "./getNodeSizes";
import { watchGetClustersMetrics } from './getClustersMetrics';
import { watchPullNDeployClustersListItem } from "./pullNDeployClustersListIem";

export function* clustersListSaga() {
    yield all([
        watchGetClustersListOnLocChange(),
        watchGetClustersList(),
        watchDeleteClusterById(),
        watchGetClusterRegions(),
        watchGetNodeSizes(),
        watchGetClustersMetrics(),
        watchPullNDeployClustersListItem(),
    ]);
}
