import * as React from 'react';
import { FieldArray, ArrayHelpers } from 'formik';
import _ from 'lodash';
import { TrashIcon } from './components/TrashIcon';

export interface InputsListRenderableProps<TValue> {
	onChange: (e: React.ChangeEvent<any>) => void;
	onBlur: (e: React.SyntheticEvent<any>) => void;
	value: TValue;
	values: TValue[];
	trashButton: JSX.Element;
	index: number;
}

interface Props<TValue> {
	values?: TValue[];
	changeHandler: (e: React.SyntheticEvent<HTMLInputElement, Event>) => void;
	blurHandler: (e: React.SyntheticEvent<HTMLInputElement>) => void;
	extendable: () => TValue;
	name: string;
	renderer: React.ComponentType<InputsListRenderableProps<TValue>>;
	shouldExtendChecker: (values: TValue[], changedValue: TValue, changedIndex: number) => boolean;
	shouldPruneChecker: (values: TValue[]) => number[];
	checkerDelay?: number;
}

export class InputsList<TValue> extends React.Component<Props<TValue>> {
	private readonly DEFAULT_CHECKER_DELAY = 350;
	private arrayHelpers: ArrayHelpers = {} as ArrayHelpers;

	renderArray = (arrayHelpers: ArrayHelpers): JSX.Element => {
		this.arrayHelpers = arrayHelpers;

		const items = this.props.values || [];

		return (
			<React.Fragment>
				{items.map((item, index) => {
					const { renderer: RenderableComponent } = this.props;

					const renderableProps = this.buildRenderableProps(items, item, index);

					return (
						<React.Fragment key={index}>
							<RenderableComponent {...renderableProps} />
						</React.Fragment>
					);
				})}
			</React.Fragment>
		);
	};

	buildRenderableProps = (values: TValue[], value: TValue, index: number): InputsListRenderableProps<TValue> => {
		return {
			onChange: this.handleChange,
			onBlur: this.handleBlur,
			value,
			values,
			trashButton: <TrashIcon onClick={() => this.arrayHelpers.remove(index)} />,
			index,
		};
	};

	handleBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
		if (!this.props.values) return;
		const shouldPruneValuesArray = this.props.shouldPruneChecker(this.props.values);

		shouldPruneValuesArray.forEach(idxToPrune => {
			this.arrayHelpers.remove(idxToPrune);
		});
		this.props.blurHandler(e);
	};

	handleChange = (e: React.ChangeEvent<any>) => {
		this.props.changeHandler(e);
		this.extendHandler(e);
	};

	extendHandler = _.debounce((e: React.ChangeEvent<any>) => {
		if (!this.props.values) return;

		const changedItemIndex = this.getInputIndex(e.target.name);
		const changedValueChunk = this.props.values[changedItemIndex];

		const shouldExtendValuesArray = this.props.shouldExtendChecker(
			this.props.values,
			changedValueChunk,
			changedItemIndex
		);

		if (shouldExtendValuesArray) {
			this.extendValues();
		}
	}, this.props.checkerDelay || this.DEFAULT_CHECKER_DELAY);

	extendValues = () => this.arrayHelpers.push(this.props.extendable());

	getInputIndex = (name: string): number => {
		const regex = /\[(\d+)\]/;
		const match = name.match(regex);

		if (!match) throw new Error(`Failed to parse index from values array.Something went wrong.`);

		return +match[1];
	};

	render() {
		return <FieldArray name={this.props.name} render={this.renderArray} />;
	}
}
