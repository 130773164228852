export const ENVIRONMENT_STEP_ID = 'ENVIRONMENT_STEP';

export const environmentStepMeta = {
	title: 'Environment variables',
	subtitle: 'Set your env vars to configure passwords and secrets',
	btnText: 'Environment variables',
	tooltipText: 'Environment variables',
	demoValues: {
		env: {
			NODE_ENV: 'production',
			PORT: '8000',
			IP_API: 'http://ip-api.com/json',
			REGIONS_API: 'https://api.appfleet.com/regions',
			MAPBOX_STYLE: 'mapbox://styles/jimaek/ckh2ja3hg1qml19r0pqjefvqv',
			MAPBOX_TOKEN: 'pk.eyJ1IjoiamltYWVrIiwiYSI6ImNrZzZ2b29weDAxZXYycW40YmRvZTY3aWkifQ.osESkOA-XJ11sphOFzJiHA',
		},
	},
};
