import React from 'react';

export const useOutsideElementClick = (ref: any, cb: () => void) => {
	React.useEffect(() => {
		function handleClickOutside(event: Event) {
			if (ref.current && !ref.current.contains(event.target)) {
				cb();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, cb]);
};
