import React from "react";

const DEFAULT_VIEWPORT = 'width=device-width, initial-scale=1.0';
const DESKTOP_VIEWPORT = 'width=device-width, initial-scale=0.3';

export const useViewport = (pageRequiredAuth: boolean) => {
  React.useEffect(() => {
    let metaTagVieportEl = document.querySelector('meta[name=viewport]');
    if (pageRequiredAuth) {
      metaTagVieportEl?.setAttribute('Content', DESKTOP_VIEWPORT);
    } else {
      metaTagVieportEl?.setAttribute('Content', DEFAULT_VIEWPORT);
    }
  }, [pageRequiredAuth]);
};
