import { ChartTooltipModel, ChartOptions } from 'chart.js';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
const { Blue6 } = require('chartjs-plugin-colorschemes/src/colorschemes/colorschemes.office');

export const staticChartOptions: ChartOptions = {
	animation: {
		duration: 0,
	},
	tooltips: {
		custom: (model: ChartTooltipModel) => {
			model.opacity = 0;
		},
		mode: 'index',
		intersect: false,
	},
	legend: {
		display: false,
	},
	hover: {
		mode: 'x',
	},
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
					padding: 20,
				},
				gridLines: {
					display: false,
					zeroLineColor: 'transparent',
				},
			},
		],
		xAxes: [
			{
				gridLines: {
					display: true,
				},
			},
		],
	},
	plugins: {
		colorschemes: {
			scheme: Blue6,
		},
	},
};
