import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { Table, TableRowProps } from 'semantic-ui-react';

interface Props extends TableRowProps {
	className?: string;
	children: any;
}

export const Footer = (props: Props) => {
	const { className, ...rest } = props;

	return (
		<Table.Footer className={classnames(styles.appTable__row, styles.appTable__footer, props.className)} {...rest}>
			{props.children}
		</Table.Footer>
	);
};
