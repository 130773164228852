import * as React from 'react';
import { PortalRenderer } from './components/PortalRenderer';
import { createInjectorRegisterer } from './utils/createRegisterer';
import { createInjectorContainer } from './utils/createInjectorContainer';
import { InjectorObserver } from './observers/InjectorObserver';

export function createInjector(nodeId: string) {
	const observer = new InjectorObserver();
	const InjectorContainer = createInjectorContainer(observer, PortalRenderer);

	return {
		InjectorContainer: () => <InjectorContainer nodeId={nodeId} />,
		createInjectable: createInjectorRegisterer(observer),
		removeInjectable: observer.remove,
	};
}
