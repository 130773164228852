import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	text: string;
	className?: string;
}

export const Title = (props: Props) => {
	return <span className={classnames(styles.appCardTitle, props.className)}>{props.text}</span>;
};
