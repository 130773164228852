import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';

interface VerticalThumbStyle {
	width?: string;
	height?: string;
	borderRadius?: string;
	backgroundColor?: string;
	opacity?: string;
}

interface VerticalScrollbarProps {
	visible?: boolean;
	children: JSX.Element | JSX.Element[];
	topBorderClassName?: string;
	bottomBorderClassName?: string;
	shiftInPixels?: number;
	thumbStyle?: VerticalThumbStyle;
	scrollRef?: any;
}

const defaultThumbStyle = {
	width: '3px',
	height: '120px',
	borderRadius: '1px',
	backgroundColor: '#000000',
	opacity: '0.2',
};

const thumbVertical = ({ style, props, thumbStyle = {} }: any) => {
	return <div style={{ ...style, ...defaultThumbStyle, ...thumbStyle }} {...props} />;
}

export const VerticalScroll: React.FC<VerticalScrollbarProps> = (props: VerticalScrollbarProps) => {
	const {
		visible, children, topBorderClassName, bottomBorderClassName, thumbStyle = {}, shiftInPixels = 0, scrollRef,
	} = props;

	if (!visible)
		return (<>{children}</>);

	return (
		<>
			{topBorderClassName ? <div className={topBorderClassName} /> : null}
			<Scrollbars style={{ width: `calc(100% + ${shiftInPixels}px)` }}
						ref={scrollRef}
						renderThumbVertical={(style, ...props) => {
							return thumbVertical({ style, props, thumbStyle });
						}}>
				{children}
			</Scrollbars>
			{bottomBorderClassName ? <div className={bottomBorderClassName} /> : null}
		</>
	);
};
