import React, { useState } from 'react';
import { Menu, Icon, MenuItemProps } from 'semantic-ui-react';
import { navigateTo } from '@app/utils/navigate-to';
import { checkIfMenuItemActive } from '@app/utils/checkIfMenuItemActive';
import { MenuItem, SUPPORT_URL } from './NavMenu';
import { SupportSubMenu } from './supportSubMenu';

interface NavMenuItemProps {
	setActiveItem: (url: string) => void;
	item: MenuItem;
	activeItem: string;
}

export const NavMenuItem: React.FC<NavMenuItemProps> = ({ item, setActiveItem, activeItem }) => {
	const [isShow, setIsShow] = useState(false);
	const handleClick = (_: any, data: MenuItemProps) => {
		if (item.clickable) {
			setActiveItem(data.url);
			navigateTo(data.url)();
		}
	};

	return (
		<Menu.Item
			className="nav-menu-item btn-four"
			name={item.name}
			active={checkIfMenuItemActive({ activeItem, activeFor: item.activeFor, url: item.url })}
			onClick={handleClick}
			url={item.url}
			onMouseOver={() => setIsShow(true)}
			onMouseLeave={() => setIsShow(false)}
		>
			<span className={item.iconType ? 'nav-menu-item-icon' : ''}>{item.name}</span>
			{item.iconType ? <Icon name={item.iconType} /> : null}
			{isShow && getSubMenu(item.url)}
		</Menu.Item>
	);
};

const getSubMenu = (urlTemplate: string) => {
	switch (urlTemplate) {
		case SUPPORT_URL.urlTemplate:
			return <SupportSubMenu />;
		default:
			return null;
	}
};
