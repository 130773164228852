import React from 'react';
import styles from './styles.module.scss';
import { SortingCtrlsProps } from './interfaces';
import iconAngleDownBlack from '@icons/icon.angle.down.black.svg';
import classnames from 'classnames';
import { SvgIcon } from '../SvgIcon';
import globalStyles from '../../styles/global.module.scss';

export const SortingCtrls: React.FC<SortingCtrlsProps> = ({ sortAsc, sortDesc }) => {
	return (
		<div className={classnames(globalStyles.noTextSelection, styles.sortingCtrls)}>
			<SvgIcon
				onClick={sortAsc}
				iconSrc={iconAngleDownBlack}
				className={classnames(styles.sortingCtrls__angle, styles.sortingCtrls__angle_up)}
			/>
			<SvgIcon
				onClick={sortDesc}
				iconSrc={iconAngleDownBlack}
				className={classnames(styles.sortingCtrls__angle, styles.sortingCtrls__angle_down)}
			/>
		</div>
	);
};
