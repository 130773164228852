import moment from 'moment';

export const API_METRIC_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export function buildDateFromScale<T extends {subtractAmount: number, subtractMeasurement:moment.DurationInputArg2}>(scaleParams: T) {
	const dateNow = moment().toDate();
	const start_date = moment(dateNow)
		.utc()
		.subtract(scaleParams.subtractAmount, scaleParams.subtractMeasurement)
		.utc()
		.format(API_METRIC_DATE_FORMAT);
	const end_date = moment()
		.utc()
		.format(API_METRIC_DATE_FORMAT);

	return {
		start_date: start_date,
		end_date: end_date,
	};
}
