export enum ProtocolOptions {
	TCP = 'tcp',
	UDP = 'udp',
}

export const selectOptions = [
	{
		value: ProtocolOptions.TCP,
		text: 'TCP',
	},
	{
		value: ProtocolOptions.UDP,
		text: 'UDP',
	},
];
