import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { SvgIcon } from '../SvgIcon';
import iconClose from '@icons//icon-error.svg';

export interface IErrorMessage {
	message: string;
}
interface ErrorMessageProps {
	error: IErrorMessage;
	withinPortal?: boolean;
	className?: string;
	useDefaultMsg?: boolean;
	showHeader?: boolean;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
	error,
	withinPortal = true,
	className = '',
	useDefaultMsg = true,
	showHeader = true,
}) => {
	const errorMessageRoot: Element = document.querySelector('#error-message')!;
	const [open, setIsOpen] = React.useState(false);

	const onClose = () => {
		setIsOpen(false);
	};

	React.useEffect(() => {
		if (error?.message) {
			setIsOpen(true);

			return () => {
				setIsOpen(false);
			};
		}
	}, [error]);

	const render = (
		<div
			className={classnames(
				styles.errorMessage,
				{ [className]: !!className },
				{ [styles.errorMessage_absolute]: withinPortal }
			)}
		>
			<div className={styles.errorMessage__close}>
				<SvgIcon iconSrc={iconClose} className={styles.errorMessage__closeIcon} onClick={onClose} />
			</div>
			<div className={styles.errorMessage__content}>
				{showHeader ? <div className={styles.errorMessage__content__header}>Something went wrong!</div> : null}
				<div className={styles.errorMessage__content__msg}>
					{useDefaultMsg
						? 'There was an error in the backend, please try again in a few hours'
						: error?.message}
				</div>
			</div>
		</div>
	);

	if (withinPortal) {
		return open ? ReactDOM.createPortal(render, errorMessageRoot) : null;
	}

	return open ? render : null;
};
