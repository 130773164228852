import { defineActionGroup, defineAction } from 'rd-redux-utils';
import { IErrorMessage } from '@app/shared/errorMessage';
import { ICluster } from '../components';
import { AddDomainValues } from '../components/clusterSettings/clusterSettingsDomain/addDomain';
import { ClusterMember } from './interfaces';
import { TeamMember } from '../../team/state/interfaces';
import { ComparedAvailableMember } from '../components/clusterSettings/clusterSettingsPermissions/addPermissions/AddPermissionsRow';
import { MetaError } from '@app/types/metaError';
import { IClusterVersion } from '../components/cluster/Cluster';

// GET CLUSTER
export const setCluster = defineAction<{ cluster: ICluster }>('CLUSTER: SET CLUSTER INFO');
export const setClusterError = defineAction<{ cluster?: ICluster; error: MetaError }>('CLUSTER: SET ERROR');
export const clearClusterError = defineAction<{}>('CLUSTER: CLEAR ERROR');
export const clearCluster = defineAction('CLUSTER: CLEAR STATE');
export const destroyCluster = defineAction<{ name: string; handleResponse: () => void }>(
	'CLUSTER SETTINGS: DESTROY CLUSTER'
);

// DELETE NODE FROM CLUSTER
export const deleteClusterNodeAG = defineActionGroup('DELETE NODE FROM CLUSTER');
export const deleteNodeFromCluster = deleteClusterNodeAG.defineAction<{ id: number; hostName: string }>('MAKE REQUEST');
export const deleteClusterNodeSuccess = deleteClusterNodeAG.defineAction<{ TODO: any }>('REQUEST SUCCEED');
export const deleteClusterNodeFail = deleteClusterNodeAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');
// ADD NODE TO CLUSTER
export const addNodeToClusterAG = defineActionGroup('ADD NODE TO CLUSTER');
export const addNodeToCluster = addNodeToClusterAG.defineAction<{
	id: number;
	nodes: {
		region: string;
		size: string;
	};
}>('MAKE REQUEST');
export const addNodeToClusterSuccess = addNodeToClusterAG.defineAction<{ TODO: any }>('REQUEST SUCCESS');
export const addNodeToClusterFail = addNodeToClusterAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');

// CLUSTER DOMAINS
export const addDomain = defineAction<{
	values: AddDomainValues;
	clusterName: string;
	handleSuccess: () => void;
	handleFinally: () => void;
}>('CLUSTER: ADD DOMAIN');
export const removeDomain = defineAction<{ clusterName: string; domainId: number; handleResponse: () => void }>(
	'CLUSTER: REMOVE DOMAIN'
);
export const getDomains = defineAction<{ clusterName: string }>('CLUSTER: GET DOMAINS');
export const getDomainsFailed = defineAction<{}>('CLUSTER: FAILED TO GET DOMAINS');
export const setDomains = defineAction<{ clusterDomains: any }>('CLUSTER: SET DOMAINS');
export const setDomainsError = defineAction<{ error: MetaError }>('CLUSTER: DOMAIN ERROR');
export const refreshDomain = defineAction<{clusterName: string; domainId: number; onFinal: () => void}>('CLUSTER: REFRESH DOMAIN');
export const clearDomains = defineAction('CLUSTER: CLEAR DOMAINS');
export const clearDomainsError = defineAction<{}>('CLUSTER: CLEAR DOMAIN ERROR');

//REBUILD CLUSTER
export const pullAndDeployClusterAG = defineActionGroup('PULL AND DEPLOY CLUSTER');

export const pullAndDeployClusterAction = pullAndDeployClusterAG.defineAction<{
	clusterName: string;
	url?: string;
}>('MAKE REQUEST');

export const pullAndDeployClusterSuccessAction = pullAndDeployClusterAG.defineAction<{ clusterName: string }>(
	'REQUEST SUCCEED'
);
export const pullAndDeployClusterFailedAction = pullAndDeployClusterAG.defineAction<{
	clusterName: string;
	error: string;
}>('REQUEST FAILED');

export const updateClusterAG = defineActionGroup('UPDATE CLUSTER');
export const updateClusterAction = updateClusterAG.defineAction<{ cluster: ICluster }>('MAKE REQUEST');
export const updateClusterSuccessAction = updateClusterAG.defineAction<{ cluster: ICluster }>('REQUEST SUCCEED');
export const updateClusterFailedAction = updateClusterAG.defineAction<{ cluster: ICluster }>('REQUEST FAILED');

export const resetPullAndDeployClusterAction = defineAction<{ clusterName: string }>('DEPLOY NEW CLUSTER: RESET STATE');

// GET CLUSTER MEMBERS
export const getClusterMembers = defineAction<{ name: string }>('CLUSTER: GET MEMBERS');
export const setClusterMembers = defineAction<{ clusterMembers: ClusterMember[] }>('CLUSTER: SET MEMBERS');

// GET/SET AVAILABLE MEMBERS (SO YOU CAN ADD THEM TO CLUSTER)
export const getAvailableMembers = defineAction<{}>('CLUSTER: GET AVAILABLE MEMBERS');
export const setAvailableMembers = defineAction<{ availableMembers: TeamMember[] }>('CLUSTER: SET AVAILABLE MEMBERS');
// SAVE MODIFIED AVAILABLE MEMBERS WITH CLUSTER PERMISSION
export const updateMemberSClusterPermission = defineAction<{
	members: ComparedAvailableMember[];
	clusterId: number;
	clusterName: string;
	onDismiss: () => void;
}>('CLUSTER: UPDATE MEMBERS PERMISSION');

export const removeClusterPermissionsUser = defineAction<{
	userId: number;
	clusterId: number;
	clusterName: string;
	onFinal: () => void;
}>('CLUSTER: REMOVE MEMBER PERMISSION');

// Rebuild
export const rebuildClusterAG = defineActionGroup('REBUILD CLUSTER');
export const rebuildClusterAction = rebuildClusterAG.defineAction<{ clusterName: string; url?: string; }>('MAKE REQUEST');
export const rebuildClusterSuccessAction = rebuildClusterAG.defineAction<{ clusterName: string }>('REQUEST SUCCEED');
export const rebuildClusterFailedAction = rebuildClusterAG.defineAction<{ clusterName: string; error: string }>(
	'REQUEST FAILED'
);

// Enable/disable https
export const enableHttpsPort = defineAction<{httpsPort: number; clusterName: string; onSuccess: () => void;}>("CLUSTER SETTINGS: ENABLE HTTPS PORT");
export const enableHttpsPortSucceed = defineAction<{currentVersion: IClusterVersion}>("CLUSTER SETTINGS: ENABLE HTTPS PORT SUCCEED");
export const enableHttpsPortFailed = defineAction<{error: MetaError}>("CLUSTER SETTINGS: ENABLE HTTPS PORT FAILED");

export const disableHttpsPort = defineAction<{clusterName: string; onSuccess: () => void;}>("CLUSTER SETTINGS: DISABLE HTTPS PORT");
export const disableHttpsPortSucceed = defineAction<{currentVersion: IClusterVersion}>("CLUSTER SETTINGS: DISABLE HTTPS PORT SUCCEED");
export const disableHttpsPortFailed = defineAction<{error: MetaError}>("CLUSTER SETTINGS: DISABLE HTTPS PORT FAILED");

export const clearHttpsPortErrorAction = defineAction<{}>("CLUSTER SETTINGS: CLEAR HTTPS PORT ERROR");

