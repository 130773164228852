import { ClusterState } from './state';
import { Action } from 'redux';
import {
	setClusterError,
	setCluster,
	clearClusterError,
	clearCluster,
	setClusterMembers,
	updateClusterSuccessAction,
	enableHttpsPortSucceed,
	disableHttpsPortSucceed,
	enableHttpsPortFailed,
	disableHttpsPortFailed,
	clearHttpsPortErrorAction,
	enableHttpsPort,
	disableHttpsPort,
	getDomains,
	getDomainsFailed,
} from './actions';
import { ICluster } from '../components';
import { ClusterMember } from './interfaces';
import {
	setAvailableMembers,
	setDomains,
	setDomainsError,
	updateClusterAction,
	pullAndDeployClusterAction,
	clearDomains,
	clearDomainsError,
} from './actions';
import { TeamMember } from '../../team/state/interfaces';
import { ClusterDomain } from '../components/clusterSettings/clusterSettingsDomain/domainsTable';

const INITIAL_STATE: ClusterState = {
	cluster: {} as ICluster,
	clusterMembers: [] as ClusterMember[],
	availableMembers: [] as TeamMember[],
	clusterDomains: [] as ClusterDomain[],
	httpsReqInProgress: false,
	getDomainsReqDone: false,
};

export function clusterReducer(state: ClusterState = INITIAL_STATE, action: Action): ClusterState {
	if (setCluster.is(action)) {
		return {
			...state,
			cluster: action.cluster,
			error: INITIAL_STATE.error,
		};
	}

	if (setClusterError.is(action)) {
		return {
			...state,
			error: action.error,
		};
	}

	if (setClusterMembers.is(action)) {
		return {
			...state,
			clusterMembers: action.clusterMembers,
		};
	}

	if (setAvailableMembers.is(action)) {
		return {
			...state,
			availableMembers: action.availableMembers,
		};
	}

	if (getDomains.is(action)) {
		return {
			...state,
			getDomainsReqDone: false,
		}
	}

	if (setDomains.is(action)) {
		return {
			...state,
			clusterDomains: action.clusterDomains,
			getDomainsReqDone: true,
		};
	}

	if (setDomainsError.is(action)) {
		return {
			...state,
			domainsError: action.error,
		};
	}

	if (updateClusterSuccessAction.is(action)) {
		return { ...state, cluster: action.cluster };
	}

	if (enableHttpsPort.is(action) || disableHttpsPort.is(action)) {
		return {
			...state,
			httpsReqInProgress: true,
		}
	}

	if (enableHttpsPortFailed.is(action) || disableHttpsPortFailed.is(action)) {
		return {
			...state,
			httpsError: action.error,
			httpsReqInProgress: false,
		};
	}

	if (enableHttpsPortSucceed.is(action) || disableHttpsPortSucceed.is(action)) {
		return {
			...state,
			cluster: {
				...state.cluster,
				currentVersion: {
					...state.cluster.currentVersion, ...action.currentVersion
				}
			},
			httpsReqInProgress: false,
		};
	}

	if (clearDomains.is(action)) {
		return {
			...state,
			clusterDomains: INITIAL_STATE.clusterDomains,
		}
	}

	if (clearHttpsPortErrorAction.is(action)) {
		return {...state, httpsError: INITIAL_STATE.httpsError}
	}

	if (clearClusterError.is(action) || updateClusterAction.is(action) || pullAndDeployClusterAction.is(action)) {
		return { ...state, error: INITIAL_STATE.error };
	}

	if (clearDomainsError.is(action)) {
		return {
			...state,
			domainsError: INITIAL_STATE.domainsError,
		};
	}

	if (clearCluster.is(action)) {
		return INITIAL_STATE;
	}

	if (getDomainsFailed.is(action)) {
		return {
			...state,
			getDomainsReqDone: true,
		};
	}

	return state;
}
