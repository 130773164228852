import React from 'react';
import { Label } from 'semantic-ui-react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '@app/shared/SvgIcon';

export interface SubMenuItem {
	name: string;
	iconSrc: string;
	handleClick?: () => void;
	iconType?: 'danger';
	disabled?: boolean;
	isReadonlyAvailable?: boolean;
}

interface NavSubMenuProps {
	subMenuDescr: SubMenuItem[];
	className?: string;
}

export const NavSubMenu: React.FC<NavSubMenuProps> = ({ subMenuDescr, className = '' }) => {
	return (
		<div className={styles.navSubMenuWrap}>
			<Label pointing="above" className={classnames(styles.navSubMenu, { [className]: !!className })}>
				{subMenuDescr.map((menuItem: SubMenuItem, menuItemIdx: number) => (
					<div
						key={menuItemIdx}
						className={classnames(styles.navSubMenu__item, {
							[styles.navSubMenu__item_disabled]: menuItem.disabled,
						})}
						onClick={menuItem.handleClick}
					>
						<div
							className={classnames(styles.navSubMenu__item__iconWrap, {
								[styles.navSubMenu__item__iconWrap_danger]: menuItem.iconType === 'danger',
							})}
						>
							<SvgIcon
								iconSrc={menuItem.iconSrc}
								className={classnames(styles.navSubMenu__item__iconWrap__icon)}
							/>
						</div>

						<div>{menuItem.name}</div>
					</div>
				))}
			</Label>
		</div>
	);
};
