import * as React from 'react';
import classnames from 'classnames';
import style from './styles.module.scss';
import Draggable from 'react-draggable';

export interface WizardProgressBarStep {
	tooltipText: string;
}

export interface ProgressBarStepProps {
	isPassed: boolean;
	isActive: boolean;
	index: number;
}

export type StepChild = (props: ProgressBarStepProps) => JSX.Element;

interface Props {
	steps: number;
	initialStep: number;
	progressBarFill: string;
	progressBarHeight: string;
	progressBarBackgroundFill: string;
	progressBarBackgroundHeight: string;
	activeStep?: number;
	className?: string;
	borderRadius?: string;
	children?: StepChild[];
	progressBarFillAnimation?: string;
	onStepChange?: (index: number) => void;
	disableDotClickHandler?: boolean;
}

interface State {
	isDragging: boolean;
	dragOffset: number;
	activeStep: number;
	draggableIndex: number | null;
}

export class WizardProgressBar extends React.Component<Props, State> {
	state = {
		isDragging: false,
		dragOffset: 0,
		activeStep: this.props.initialStep || 0,
		draggableIndex: null,
		disableDotClickHandler: this.props.disableDotClickHandler || false,
	};

	dots: Record<number, HTMLDivElement> = {};

	componentDidUpdate(prevProps: Props) {
		const { activeStep, onStepChange } = this.props;
		if (prevProps.activeStep !== activeStep && activeStep !== undefined && activeStep !== this.state.activeStep) {
			this.setState({ activeStep });
		}
		const { draggableIndex } = this.state;
		if (draggableIndex !== null && activeStep !== draggableIndex) {
			onStepChange?.(draggableIndex || 0);
		}
	}

	handleDotClick = (index: number) => {
		if (this.state.disableDotClickHandler) return;
		if (this.state.draggableIndex === index) return;
		this.setState({ activeStep: index }, () => {
			this.props.onStepChange?.(this.state.activeStep);
		});
	};

	render() {
		const {
			initialStep,
			steps,
			progressBarFill,
			progressBarBackgroundFill,
			progressBarHeight,
			progressBarBackgroundHeight,
			borderRadius = '0px',
			children,
			className,
			progressBarFillAnimation,
		} = this.props;

		const { isDragging, dragOffset, draggableIndex, activeStep: activeStepState } = this.state;
		const activeStep = (draggableIndex !== null ? draggableIndex : activeStepState) || 0;

		if (initialStep > steps) {
			throw new Error(`initialStep is out of range: ${steps}`);
		}
		if (initialStep < 0) {
			throw new Error(`initialStep can't be a negative value. Provided: ${initialStep}`);
		}

		const acomplishedStepIndex =
			this.props.activeStep !== undefined ? this.props.activeStep : this.state.activeStep;
		const barWidthInPercent = `${steps < 2 ? 100 : (100 / (steps - 1)) * acomplishedStepIndex}%`;

		return (
			<div className={classnames(style.progressbar, { [style.progressbar_singleStep]: steps === 1 }, className)}>
				<div
					className={classnames(style.progressbar_bar, steps > activeStep && progressBarFillAnimation, {
						[style.progressbar_bar_isDragging]: isDragging,
					})}
					style={{
						width: isDragging ? dragOffset + 'px' : barWidthInPercent,
						background: progressBarFill,
						height: progressBarHeight,
						borderRadius: borderRadius,
					}}
				/>
				<div
					className={style.progressbar_background}
					style={{
						background: progressBarBackgroundFill,
						height: progressBarBackgroundHeight,
						borderRadius: borderRadius,
					}}
				/>
				{children?.map((child, index) => {
					const isActive = index === acomplishedStepIndex;
					const isPassed = index < acomplishedStepIndex;
					return (
						<Draggable
							key={`dot-wrapper-${index}`}
							axis="x"
							bounds="parent"
							// disable drag handlers
							disabled={true}
							position={{ x: 0, y: 0 }}
							grid={[1, 0]}
						>
							<div
								onClick={isActive ? () => {} : () => this.handleDotClick(index)}
								className={classnames(style.progressbar_dot, {
									[style.progressbar_dot_isDragging]: isDragging,
								})}
								data-index={index}
								ref={ref => (this.dots[index] ? null : (this.dots[index] = ref as HTMLDivElement))}
							>
								{child({ isPassed, isActive, index })}
							</div>
						</Draggable>
					);
				})}
			</div>
		);
	}
}
