import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import memo from 'memoize-one';
import filesize from 'filesize';
import styles from './style.module.scss';
import { LoadingSpinnerInjectedProps, withLoadingSpinner } from '@app/shared/hocs/enhancers/withLoadingSpinner';
import { ApiNode } from '@app/data-modules/cluster-nodes';
import { ApiSize } from '@app/data-modules/sizes';
import { ApiRegion } from '@app/data-modules/regions';
import { NodeStatus } from '@app/shared/text/NodeStatus';
import { AppButton } from '@app/shared/buttons/AppButton';
import { NodeOptions } from './components/NodeOptions';
import { optionsList } from './components/NodeOptions/options/optionsList';
import { ICluster } from '../../../cluster';
import { addNodesWizardModalApi } from '@app/modals';
import { FlagIcon } from '@app/shared/icons/FlagIcon';
import { AppTable } from '@app/shared/AppTable';
import { ClusterMetric, getLatestClusterMetrics } from '@app/data-modules/cluster-metrics';
import angleRightDark from '@icons/icon-12-arrow-right-dark.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { AppSpinner } from '@app/shared/AppSpinner';
import { useBillingInfoSubscriptionStatus } from '@app/hooks/useBillingInfoSubscriptionStatus';

interface NodesListProps {
	cluster: ICluster;
	isReadonlyCluster: boolean;
	latestMetric?: ClusterMetric['latest']['metric'];
	nodes: ApiNode[];
}

interface InputProps extends NodesListProps {
	regions: ApiRegion[];
	sizes: ApiSize[];
	size: string;
	isReadonlyCluster: boolean;
	getLatestClusterMetrics?: (clusterName: string) => void;
}

type Props = InputProps & LoadingSpinnerInjectedProps;

interface CountryWithFlagProps {
	nodeRegion: ApiRegion | null;
	nodeName: string;
}
const CountryWithFlag = ({ nodeRegion, nodeName }: CountryWithFlagProps) => (
	<div className={styles.cellContent}>
		<FlagIcon countryCode={nodeRegion?.country || ''} className={styles.flagIcon} />
		<span className={styles.code}>{nodeName}</span>
	</div>
);
const TableHead = () => (
	<AppTable.Head>
		<AppTable.Row>
			<AppTable.Col>Instance</AppTable.Col>
			<AppTable.Col>Status</AppTable.Col>
			<AppTable.Col>Bandwidth</AppTable.Col>
			<AppTable.Col>{''}</AppTable.Col>
		</AppTable.Row>
	</AppTable.Head>
);

const TableFooter: React.FC<{ isReadonlyCluster: boolean }> = ({ isReadonlyCluster }) => {
	const { subscriptionValid } = useBillingInfoSubscriptionStatus();
	return (
		<AppTable.Footer>
			<AppTable.Row className={styles.lastRow}>
				<AppTable.Col>
					<AppButton
						className={classnames(styles.btn, styles.footerBtnWithIcon)}
						onClick={!subscriptionValid || isReadonlyCluster ? () => {} : addNodesWizardModalApi.openModal}
						disabled={!subscriptionValid || isReadonlyCluster}
					>
						<span className={styles.decoratedText}>Add more nodes</span>
						<SvgIcon iconSrc={angleRightDark} className={styles.angleRightDark} />
					</AppButton>
				</AppTable.Col>
				<AppTable.Col>
					<div className={styles.cellContent}>
						<div className={styles.skeletonLine} />
					</div>
				</AppTable.Col>
				<AppTable.Col>
					<div className={styles.cellContent}>
						<div className={styles.skeletonLine} />
					</div>
				</AppTable.Col>
				<AppTable.Col />
			</AppTable.Row>
		</AppTable.Footer>
	);
};

class NodesListCardDumb extends React.Component<Props> {
	state = {
		latestClusterMetricsCalled: false,
	};
	componentDidUpdate(prevProps: Props) {
		const { cluster, latestMetric } = this.props;
		if (
			!this.state.latestClusterMetricsCalled &&
			((!latestMetric.isLoaded && cluster?.name) || prevProps.cluster.id !== cluster.id)
		) {
			this.setState({ latestClusterMetricsCalled: true });
			this.props.getLatestClusterMetrics?.(cluster.name);
		}
	}

	mapNodeToSize = (node: ApiNode): ApiSize | null => {
		const { size: clusterSize, sizes } = this.props;
		return sizes.find(size => size.regions.includes(node.region) && size.code === clusterSize) || null;
	};

	mapNodeToRegion = (node: ApiNode): ApiRegion | null => {
		const { regions } = this.props;
		return regions.find(region => region?.code === node?.region) || null;
	};

	mapNodeToSizeMemo = memo(this.mapNodeToSize);

	mapNodeToRegionMemo = this.mapNodeToRegion;

	getNodesList = ({ nodes, cluster, latestMetric, isReadonlyCluster }: NodesListProps) => {
		return (
			<AppTable className={styles.table}>
				<TableHead />
				<AppTable.Body className={styles.tableBody}>
					{!!nodes?.length ? (
						nodes?.map(node => {
							const bandwidth = latestMetric?.metric?.[node.hostname]?.[`net.bandwidth`] || 0;
							const [data, measurement] = filesize(bandwidth).split(' ');
							return (
								<AppTable.Row key={node.id}>
									<AppTable.Col className={styles.tableCol}>
										<CountryWithFlag
											nodeRegion={this.mapNodeToRegionMemo(node)}
											nodeName={node.name}
										/>
									</AppTable.Col>
									<AppTable.Col className={styles.tableCol}>
										<NodeStatus nodeId={node.id} />
									</AppTable.Col>
									<AppTable.Col className={styles.tableCol}>{`${data}${measurement}`}</AppTable.Col>
									<AppTable.Col className={classnames(styles.noPadding, styles.tableCol)}>
										<NodeOptions
											options={optionsList}
											node={node}
											cluster={cluster}
											isReadonlyCluster={isReadonlyCluster}
										/>
									</AppTable.Col>
								</AppTable.Row>
							);
						})
					) : (
						<AppTable.Row className={styles.table__row}>
							<AppTable.Col className={styles.table__loadingRow} colSpan={4}>
								{this.props.spinner.loading ? (
									<>
										<AppSpinner className={styles.table__loadingRow__icon} />
										<div className={styles.table__loadingRow__label}>Loading Clusters...</div>
									</>
								) : (
									<p className={styles.emptyListText}>This cluster doesn't have any nodes.</p>
								)}
							</AppTable.Col>
						</AppTable.Row>
					)}
				</AppTable.Body>
				<TableFooter isReadonlyCluster={isReadonlyCluster} />
			</AppTable>
		);
	};

	getNodesListMemo = memo(this.getNodesList);

	render() {
		const { nodes, cluster, latestMetric, isReadonlyCluster } = this.props;
		return <>{this.getNodesListMemo({ nodes, cluster, latestMetric, isReadonlyCluster })}</>;
	}
}

export const NodesListCard = withLoadingSpinner<InputProps>(
	connect(null, dispatch => {
		return {
			getLatestClusterMetrics: (clusterName: string) => dispatch(getLatestClusterMetrics({ clusterName })),
		};
	})(NodesListCardDumb),
	{ borderRadius: '6px', minHeight: '240px' }
);
