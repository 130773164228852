import React from 'react';
import io from 'socket.io-client';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import '../../../../../node_modules/xterm/css/xterm.css';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { createTerminal } from '../../../../utils/createTerminal';
import api from '@app/apis/api';

interface ClusterLogsTabProps {
	id: string;
	initTab: boolean;
	hostName: string;
	className: string;
}

export const ClusterLogsTab: React.FC<ClusterLogsTabProps> = ({ id, className, initTab, hostName }) => {
	const [terminal, setTerminal] = React.useState<Terminal | null>(null);
	const [sessionId, setSessionId] = React.useState<string | null>(null);
	const [socket, setSocket] = React.useState<SocketIOClient.Socket | null>(null);
	const token = api.getToken();

	React.useEffect(() => {
		if (terminal && socket && sessionId) {
			socket.on('appfleet.api.v1.connect.Output', (data: { data: string; sessionId: string }) => {
				if (data.sessionId === sessionId) {
					terminal.write(data.data);
				}
			});
		}
	}, [sessionId, socket, terminal]);

	// open socket for HostName
	React.useEffect(() => {
		if (initTab && terminal && !socket) {
			const socketBaseAddr = (
				process.env.REACT_APP_API_URL || 'wss://api-appfleet-com-staging.herokuapp.com'
			).replace('https://', 'wss://');

			const socket = io(`${socketBaseAddr}/api/v1/ws/node/${hostName}/logs`, {
				transports: ['websocket'],
				query: { token },
			});
			terminal.write('Connecting...');
			socket.emit('appfleet.api.v1.connect.SessionStart', {});
			socket.on('connect', () => {});
			socket.on('appfleet.api.v1.connect.SessionStarted', (data: { sessionId: string }) => {
				setSessionId(data.sessionId);
				terminal.write(' Success. New logs will appear below\r\n');
			});
			setSocket(socket);
		}
	}, [socket, setSocket, hostName, initTab, token, terminal]);

	// create terminal instance on did mount
	React.useEffect(() => {
		if (id && initTab && !terminal) {
			const terminal = createTerminal();
			const fitAddon = new FitAddon();
			terminal.loadAddon(fitAddon);
			let terminalElem = document.getElementById(`${id}-container`)!;
			terminal.open(terminalElem);
			fitAddon.fit();
			setTerminal(terminal);
		}
	}, [id, initTab, terminal]);

	React.useEffect(() => {
		return () => {
			if (socket) {
				socket.close();
			}
		};
	}, [socket]);

	return (
		<div id={`${id}-container`} className={classnames(styles.clusterLogs__tab, { [className]: !!className })}></div>
	);
};
