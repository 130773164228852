import { Action } from 'redux';
import { ClusterMetricState, ClusterMetric } from './state';
import {
	setClusterNodesMetricForPeriodLoadedAction,
	setClusterNodeMetricForPeriodAction,
	initClusterMetricAction,
	getLatestClusterMetrics,
	getLatestClusterMetricsSuccess,
	getLatestClusterMetricsFail,
} from './actions';

const INITIAL_CLUSTER_STATE: ClusterMetric = {
	metricForPeriod: {
		loadingStatus: 'INITIAL',
		metric: {} as any,
		metricMaxValues: {} as any,
	},
	latest: {
		isLoaded: false,
		lastUpdate: null,
		metric: {} as any,
	},
	error: '',
};
const INITIAL_STATE = {} as ClusterMetricState;

export function clusterMetricReducer(state = INITIAL_STATE, action: Action) {
	if (initClusterMetricAction.is(action)) {
		return {
			...state,
			[action.clusterName]: INITIAL_CLUSTER_STATE,
		};
	}

	if (setClusterNodesMetricForPeriodLoadedAction.is(action)) {
		return {
			...state,
			[action.clusterName]: {
				...state[action.clusterName],
				metricForPeriod: {
					...(state[action.clusterName]?.metricForPeriod ? state[action.clusterName].metricForPeriod : {}),
					loadingStatus: action.loadingStatus,
				},
			},
		};
	}

	if (setClusterNodeMetricForPeriodAction.is(action)) {
		const { metric, period, clusterName, metricMaxValues } = action;

		return {
			...state,
			[clusterName]: {
				...state[clusterName],
				metricForPeriod: {
					...(state[clusterName]?.metricForPeriod ? state[clusterName].metricForPeriod : {}),
					metric: {
						[period]: metric,
					},
					metricMaxValues: {
						[period]: metricMaxValues,
					}
				},
			},
		};
	}

	if (getLatestClusterMetrics.is(action)) {
		const { clusterName } = action;
		return {
			...state,
			[clusterName]: {
				...(state[clusterName] || INITIAL_CLUSTER_STATE),
				latest: {
					...(state[clusterName] || INITIAL_CLUSTER_STATE).latest,
					isLoaded: false,
				},
			},
		};
	}
	if (getLatestClusterMetricsSuccess.is(action)) {
		const { clusterName, metric } = action;
		return {
			...state,
			[clusterName]: {
				...state[clusterName],
				latest: {
					isLoaded: true,
					lastUpdate: Date.now(),
					metric,
				},
			},
		};
	}
	if (getLatestClusterMetricsFail.is(action)) {
		const { clusterName, error } = action;
		return {
			...state,
			[clusterName]: {
				...state[clusterName],
				latest: {
					...state[clusterName].latest,
					isLoaded: false,
				},
				error,
			},
		};
	}

	return state;
}
