import { createPath } from 'rd-url-utils';

export const CLUSTER_URL = createPath<{ name: string }>('/cluster/:name');

// SUB ROUTES
export const CLUSTER_SETTINGS_URL = createPath<{ name: string }>('/cluster/:name/settings');
export const CLUSTER_NODES_URL = createPath<{ name: string }>('/cluster/:name/nodes');
export const CLUSTER_LOGS_URL = createPath<{ name: string }>('/cluster/:name/logs');
export const CLUSTER_CONSOLE_URL = createPath<{ name: string }>('/cluster/:name/console');
export const CLUSTER_HEALTHCHECK_URL = createPath<{ name: string }>('/cluster/:name/healthcheck');
export const CLUSTER_ACITVITY_LOG_URL = createPath<{ name: string }>('/cluster/:name/activity');
