import { call, put, takeEvery, select } from 'redux-saga/effects';
import { pullNDeployClustersListItemApi } from '../api';
import {
	pullNDeployClustersListItem,
	pullNDeployClustersListItemSucceed,
	pullNDeployClustersListItemFailed,
	resetPullNDeployClustersListItem,
} from '../actions';
import { navigateTo } from '@app/utils/navigate-to';
import { AppState } from '@app/reducers';

export function* watchPullNDeployClustersListItem() {
	yield takeEvery(pullNDeployClustersListItem.TYPE, (action: ReturnType<typeof pullNDeployClustersListItem>) =>
  pullNDeployClustersListItemWorker(action.clusterName, action.url)
	);
}

export function* pullNDeployClustersListItemWorker(clusterName: string, url?: string) {
	try {
		yield call(pullNDeployClustersListItemApi, clusterName);

		yield put(pullNDeployClustersListItemSucceed({ clusterName }));
	} catch (e) {
		let error = "Failed to pull and deploy cluster.Something wen't wrong.";
		if (e.status === 400) {
			error = e.message;
		}
		yield put(pullNDeployClustersListItemFailed({ clusterName, error }));
	} finally {
		const currentPathname = yield select((store: AppState) => store.router.location.pathname);
		if (url && url === currentPathname) {
			// call navigate (to Clusters list) to fetch all updated data such as account, billing info, clusters list with nodes etc.
			yield call(navigateTo(url));
		} else {
			yield put(resetPullNDeployClustersListItem({clusterName}));
		}
	}
}
