import React from 'react';
import styles from './style.module.scss';
import warningIcon from '@icons/intercom.warning.icon.svg';
import closeIcon from '@icons/intercom.close.icon.svg';
import intercomIcon from '@icons/intercom.disabled.icon.svg';
import classnames from 'classnames';
import { useIntercom } from '@app/hooks/useIntercom';
import { SvgIcon } from '@app/shared/SvgIcon';
import { useDispatch, useSelector } from 'react-redux';
import { showIntercomNotification } from './actions';
import { AppState } from '../../reducers';

export const IntercomNotificationDisabled = () => {
	const { intercomConnectFailed } = useIntercom();
	const dispatch = useDispatch();
	const { showNotification } = useSelector((state: AppState) => ({
		showNotification: state.intercom.showNotification,
	}));

	const closeHandler = () => dispatch(showIntercomNotification({ showNotification: false }));
	const notificationDisabledHandler = () => dispatch(showIntercomNotification({ showNotification: true }));

	const renderNotificationCard = () => {
		if (showNotification) {
			return (
				<div className={styles.intercomNotification__card}>
					<div className={styles.intercomNotification__infoBlock}>
						<SvgIcon iconSrc={warningIcon} className={styles.intercomNotification__icon} />
						<div className={styles.intercomNotification__text}>
							Support widget failed to load. Please disable your ad blocker to contact us.
						</div>
					</div>
					<SvgIcon
						iconSrc={closeIcon}
						className={styles.intercomNotification__closeCardIcon}
						onClick={closeHandler}
					/>
				</div>
			);
		}
	};

	return (
		<div
			className={classnames(
				styles.intercomNotification,
				!intercomConnectFailed ? styles.intercomNotification_hidden : ''
			)}
		>
			{renderNotificationCard()}
			<div className={styles.intercomNotification__disabledImgBlock}>
				<SvgIcon
					iconSrc={intercomIcon}
					className={styles.intercomNotification__disabledImg}
					onClick={notificationDisabledHandler}
				/>
			</div>
		</div>
	);
};
