import { createModals } from '@app/utils/createModals';
import { ClusterDeployWizardModal } from '@app/modals/ClusterDeployWizardModal';
import { ChangePlanDeployWizardModal } from './ChangePlanDeployWizardModal';
import { UpdateNodesDeployWizardModal } from '@app/modals/UpdateNodesDeployWizardModal';
import { ChangeBillingInformationWizardModal } from '@app/modals/BillingInformationWizardModal';
import { AddPaymentCardWizardModal } from './AddPaymentCardWizardModal';

export const { ModalContainer, registerModal, unregisterModal } = createModals('modal');

export const deployWizardModalApi = registerModal(ClusterDeployWizardModal);

export const changePlanWizardModalApi = registerModal(ChangePlanDeployWizardModal);

export const addNodesWizardModalApi = registerModal(UpdateNodesDeployWizardModal);

export const changeBillingInformationWizardModalApi = registerModal(ChangeBillingInformationWizardModal);

export const addPaymentCardWizardModalApi = registerModal(AddPaymentCardWizardModal);
