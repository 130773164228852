import { call, put, takeEvery, select } from 'redux-saga/effects';
import { postPullAndDeployClusterApi } from '../api';
import {
	pullAndDeployClusterAction,
	pullAndDeployClusterFailedAction,
	pullAndDeployClusterSuccessAction,
} from '../actions';
import { navigateTo } from '@app/utils/navigate-to';
import { AppState } from '@app/reducers';

export function* watchPullAndDeployCluster() {
	yield takeEvery(pullAndDeployClusterAction.TYPE, (action: ReturnType<typeof pullAndDeployClusterAction>) =>
		pullAndDeployClusterWorker(action.clusterName, action.url)
	);
}

export function* pullAndDeployClusterWorker(clusterName: string, url?: string) {
	try {
		yield call(postPullAndDeployClusterApi, clusterName);

		yield put(pullAndDeployClusterSuccessAction({ clusterName }));

		const currentPathname = yield select((store: AppState) => store.router.location.pathname);
		if (url && url === currentPathname) {
			yield call(navigateTo(url));
		}
	} catch (e) {
		yield put(pullAndDeployClusterFailedAction({ clusterName, error: e.message }));
	}
}
