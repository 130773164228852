import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import azure from '@images/registry-logo/azure.svg';
import dockerHub from '@images/registry-logo/docker-hub.svg';
import quay from '@images/registry-logo/quay.svg';
import git from '@images/registry-logo/git.svg';
import google from '@images/registry-logo/google.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import infoIcon from '@icons/icon.info.svg';

const iconsTypes = {
	'azure': { icon: azure, text: 'Azure Registry' },
	'docker': { icon: dockerHub, text: 'Docker Hub Registry' },
	'quay': { icon: quay, text: 'Quay Registry' },
	'github': { icon: git, text: 'Github Registry' },
	'google cloud': { icon: google, text: 'Google Cloud Registry' },
};

export type IRegistryIcon = keyof typeof iconsTypes;

interface Props {
	type: IRegistryIcon;
	className?: string;
	disableTooltip?: boolean;
}

export const RegistryIcon = ({ className, type, disableTooltip }: Props) => {
	const icon = iconsTypes[type]['icon'] || infoIcon;
	const tooltipText = iconsTypes[type]['text'] || type;
	const [isShow, setIsShow] = React.useState(false);
	return (
		<div
			className={classnames(styles.registryIcon, className)}
			onMouseOver={() => setIsShow(true)}
			onMouseLeave={() => setIsShow(false)}
		>
			<SvgIcon iconSrc={icon} className={styles.icon} />
			{isShow && !disableTooltip ? <div className={classnames(styles.tooltip)}>{tooltipText}</div> : null}
		</div>
	);
};
