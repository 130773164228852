import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styles from './style.module.scss';
import { Grid } from 'semantic-ui-react';
import { createSelector } from 'reselect';
import { DeployWizardStepProps } from '../../interfaces';
import { CenteredContentLayout } from '../../components/layouts/CenteredContentLayout';
import { RegionBar } from '../../components/RegionBar';
import { PriceBarGroup, PriceBar } from '../../components/PriceBar';
import { ApiRegion } from '@app/data-modules/regions';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { confirmationStepMeta, CONFIRMATION_STEP_ID } from './meta';
import { REGIONS_STEP_ID, RegionStepNode } from '../RegionsStep';
import { RESOURCES_STEP_ID } from '@app/modules/deploy-wizard/steps/ResourcesStep';
import { getSizeByCode } from '@app/data-modules/sizes/selectors';
import { AppState } from '@app/reducers';
import { ApiSize } from '@app/data-modules/sizes';
import { ErrorMessage, IErrorMessage } from '@app/shared/errorMessage';
import { getWizardStatus } from '@app/utils/getWizardStatus';
import { DeploymentState } from '@app/modules/deploy-wizard/state/state';
import { formatterUSDCurrency } from '@app/utils/formatterUSDCurrency';
import { clearWizardErrorAction } from '../../state/actions';

const getRegionNodes = createSelector(
	(values: any) => values,
	(values: any): RegionStepNode[] => {
		if (!values) return [];
		const regionList = Object.entries<{ amount: number; region: ApiRegion }>(values.nodes || {});

		if (!regionList.length) return [];

		return regionList.map(([_, region]) => region);
	}
);

interface Props extends DeployWizardStepProps {
	size: ApiSize;
	deploy: DeploymentState;
	error: IErrorMessage;
	clearWizardError: () => void;
}

class ConfirmationStepDumb extends React.Component<Props> {
	mapNodesToPreview = getRegionNodes;

	componentDidUpdate(prevProps: Props) {
		const {
			next,
			step,
			deploy: { completed, failed },
		} = this.props;
		if (prevProps.step.name === step.name && prevProps.deploy.completed !== completed && completed && !failed) {
			next(step.name, {});
		}
	}

	handlePrevStep() {
		const { prev, clearWizardError } = this.props;
		prev();
		clearWizardError();
	}

	render() {
		const { getPrevStep, getNextStep, step, stepValues, size, deploy, done, error } = this.props;
		const prevStep = getPrevStep();
		const nextStep = getNextStep();
		const nodes = this.mapNodesToPreview(stepValues[REGIONS_STEP_ID] || {});

		let totalNodes = 0;
		for (const node of nodes) totalNodes += node.amount;

		const monthlyPrice = formatterUSDCurrency().format((size.monthlyPrice * totalNodes) / 100);
		const hourlyPrice = formatterUSDCurrency({ minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(
			(size.price * totalNodes) / 100
		);

		return (
			<CenteredContentLayout
				title={confirmationStepMeta.title}
				subtitle={confirmationStepMeta.subtitle}
				controls={
					<div className={styles.controls}>
						{prevStep && (
							<StepNavigationButton
								type="prev"
								onClick={() => this.handlePrevStep()}
								stepName={prevStep.meta.btnText}
							/>
						)}
						{nextStep && (
							<StepNavigationButton
								type="final"
								onClick={() => done()}
								statusText={{
									initial: 'Deploy cluster',
									loading: 'Deploying',
									success: 'Deployed',
									error: 'Deploy cluster',
								}}
								status={getWizardStatus(deploy)}
								formId={step.name}
								disabled={deploy.started}
								btnClassName={styles.deployBtn}
							/>
						)}
					</div>
				}
			>
				<Grid columns={1} className={styles.gridWrapper}>
					<Grid.Row>
						<Grid.Column>
							<div className={styles.resourcePreview}>
								<div className={styles.resourcePreview_col}>
									<span className={styles.resourcePreview_title}>memory</span>
									<span className={styles.resourcePreview_value}>{size.ram}GB</span>
								</div>
								<div className={styles.resourcePreview_col}>
									<span className={styles.resourcePreview_title}>CPU Cores</span>
									<span className={styles.resourcePreview_value}>{size.cpu}vCPU</span>
								</div>
								<div className={styles.resourcePreview_col}>
									<span className={styles.resourcePreview_title}>ephemeral ssd</span>
									<span className={styles.resourcePreview_value}>{size.disk}GB</span>
								</div>
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className={styles.contentRow}>
						<Grid.Column style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
							<div className={styles.regionsPreviewGrid}>
								{nodes.map((wizardRegion, index) => {
									const { region, amount } = wizardRegion;
									return (
										<div className={styles.regionsPreviewGrid_item} key={index}>
											<RegionBar countryCode={region.country} label={region.code}>
												<span className={styles.nodeAmountCircle}>{amount}</span>
											</RegionBar>
										</div>
									);
								})}
							</div>
							<PriceBarGroup className={styles.priceBarGroup}>
								<PriceBar
									className={styles.priceBarGroup_bar}
									title={<span className={styles.titleText}>Price per hour</span>}
									price={<span className={styles.priceText}>{hourlyPrice}</span>}
								/>
								<PriceBar
									className={styles.priceBarGroup_bar}
									title={<span className={styles.titleText}>Monthly forecast</span>}
									price={<span className={styles.priceText}>{monthlyPrice}</span>}
								/>
							</PriceBarGroup>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<ErrorMessage
					error={error}
					useDefaultMsg={false}
					withinPortal={false}
					className={styles.errorMessage}
				/>
			</CenteredContentLayout>
		);
	}
}

export const ConfirmationStep = connect(
	(state: AppState, ownProps: DeployWizardStepProps) => {
		const resourceStepValue = ownProps.stepValues[RESOURCES_STEP_ID];
		if (!resourceStepValue) throw new Error(`${CONFIRMATION_STEP_ID} depended on ${RESOURCES_STEP_ID} value.`);

		const selectedSize = resourceStepValue.size;
		const size = getSizeByCode(state, selectedSize);
		if (!size) throw new Error(`Size with code ${selectedSize} not found.`);

		const { deploy, error } = state.wizard;
		return { size, deploy, error };
	},
	(dispatch: Dispatch) => ({
		clearWizardError: () => dispatch(clearWizardErrorAction({})),
	})
)(ConfirmationStepDumb);
