import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { SvgIcon } from '../SvgIcon';
import { RefObject } from 'react';

export const ContextMenu = ({
	menuItems,
	menuClassName = '',
	menuItemClassName = '',
	contextMenuWrapClassName = '',
	contextMenuRef,
}: {
	menuItems: { text: string; handleClick: (_: any) => void; icon?: any; menuItemSpecificClassName?: string }[];
	menuClassName?: string;
	menuItemClassName?: string;
	contextMenuWrapClassName?: string;
	contextMenuRef?: RefObject<HTMLDivElement>;
}) => {
	return (
		<div
			ref={contextMenuRef}
			className={classnames(styles.contextMenuWrapper, {
				[contextMenuWrapClassName]: !!contextMenuWrapClassName,
			})}
		>
			<div className={classnames(styles.contextMenu, { [menuClassName]: !!menuClassName })}>
				{menuItems.map((item, itemIdx) => {
					return (
						<div
							key={itemIdx}
							className={classnames(
								styles.contextMenu__item,
								{ [menuItemClassName]: !!menuItemClassName },
								{ [item.menuItemSpecificClassName || '']: !!item.menuItemSpecificClassName }
							)}
							onClick={item.handleClick}
						>
							{item.icon ? (
								<div>
									<SvgIcon iconSrc={item.icon} />
								</div>
							) : null}
							{item.text}
						</div>
					);
				})}
			</div>
		</div>
	);
};
