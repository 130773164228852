import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '@app/shared/SvgIcon';

interface IconWithTooltipProps {
	tooltipText: string;
	imageSrc: string;
	wrapClassname?: string;
	labelClassname?: string;
	iconWrapClassname?: string;
}

export const IconWithTooltip: React.FC<IconWithTooltipProps> = ({
	tooltipText,
	imageSrc,
	wrapClassname = '',
	labelClassname = '',
	iconWrapClassname = '',
}) => {
	const [isShow, setIsShow] = React.useState(false);

	return (
		<div
			className={classnames(styles.iconWithTooltip, { [wrapClassname]: !!wrapClassname })}
			onMouseOver={() => setIsShow(true)}
			onMouseLeave={() => setIsShow(false)}
		>
			<div className={classnames(styles.iconWithTooltip__iconWrap, { [iconWrapClassname]: !!iconWrapClassname })}>
				<SvgIcon iconSrc={imageSrc} className={styles.iconWithTooltip__icon} />
			</div>
			{isShow ? (
				<div className={classnames(styles.iconWithTooltip__label, { [labelClassname]: !!labelClassname })}>
					{tooltipText}
				</div>
			) : null}
		</div>
	);
};
