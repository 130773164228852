import React from 'react';
import { NavSubMenu, SubMenuItem } from '../navSubMenu';
import styles from './styles.module.scss';
import iconKnoledgeBase from '@icons/icon.knowledge.base.svg';
import iconSuport from '@icons/icon.contact.support.svg';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '@app/reducers';
import { showIntercomNotification } from '@app/modules/intercom-notification-disabled/actions';
import api from '@app/apis/api';

export const SupportSubMenu: React.FC<{}> = () => {
	const dispatch = useDispatch();
	const failedToLoad = useSelector((state: AppState) => state.intercom.failedToLoad);
	const supportk = api.getSupportk();

	const navSubMenuDescr: SubMenuItem[] = [
		{
			name: 'Knowledge Base',
			iconSrc: iconKnoledgeBase,
			handleClick: () => window.open('https://learn.appfleet.com/en', '_blank'),
		},
		{
			name: 'Contact Support',
			iconSrc: iconSuport,
			handleClick: () =>
				supportk
					? () => {}
					: failedToLoad
					? dispatch(showIntercomNotification({ showNotification: true }))
					: (window as any).Intercom('showNewMessage'),
			disabled: supportk ? true : false,
		},
	];

	return <NavSubMenu subMenuDescr={navSubMenuDescr} className={styles.supportSubMenu} />;
};
