import { AppState } from '@app/reducers';
export const getClusterList = (state: AppState) => state.clustersList.clustersList;

export const getClusterByNameSelector = (state: AppState, name: string) => {
	const clusterList = getClusterList(state);

	if (!clusterList) return;

	return clusterList.find(cluster => cluster.name === name);
};
