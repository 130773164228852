import * as React from 'react';
import styles from './style.module.scss';
import { Grid } from 'semantic-ui-react';
import { CenteredContentLayout } from '../../components/layouts/CenteredContentLayout';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { billingCardStepMeta } from './meta';
import { connect } from 'react-redux';
import { BillingWizardStepProps } from '../../state/interfaces';
import { BillingCardForm } from '../../components/forms/BillingCardForm';
import stripeImg from '@images/stripe.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { ErrorMessage } from '../../../../shared/errorMessage/ErrorMessage';
import { BILLING_INFORMATION_STEP_ID } from '../BillingInformation/meta';

interface Props extends BillingWizardStepProps {}

class BillingCardStepDumb extends React.Component<Props> {
	state = {
		disabled: false,
		stripeError: {
			message: '',
		},
	};

	setDisabled = (disabled: boolean) => this.setState({ disabled });

	setStripeError = (errMsg: string) =>
		this.setState(state => ({
			...state,
			stripeError: {
				message: errMsg,
			},
		}));

	render() {
		const { getPrevStep, getNextStep, step, prev, next, stepValues } = this.props;
		const { disabled, stripeError } = this.state;
		const prevStep = getPrevStep();
		const nextStep = getNextStep();

		return (
			<CenteredContentLayout
				title={billingCardStepMeta.title}
				subtitle={billingCardStepMeta.subtitle}
				controls={
					<div className={styles.controls}>
						{prevStep && (
							<StepNavigationButton
								type="prev"
								onClick={prev}
								stepName={prevStep.meta.title}
								disabled={disabled}
							/>
						)}
						{nextStep && (
							<StepNavigationButton
								type="final"
								stepName={nextStep.meta.title}
								formId={step.name}
								disabled={disabled}
							/>
						)}
					</div>
				}
			>
				<Grid columns={1} className={styles.gridWrapper}>
					<Grid.Row>
						<Grid.Column>
							<BillingCardForm
								onSubmit={values => next(step.name, values)}
								formId={step.name}
								onDisable={this.setDisabled}
								setStripeError={this.setStripeError}
								nameOnCard={`${stepValues[BILLING_INFORMATION_STEP_ID].firstName} ${stepValues[BILLING_INFORMATION_STEP_ID].lastName}`}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className={styles.contentRow}>
						<Grid.Column>
							<div className={styles.cardFooterInfo}>
								<SvgIcon iconSrc={stripeImg} className={styles.icon} />
								<p>
									appfleet does not store your credit card information.
									<br />
									Payments are processed by Stripe
								</p>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<ErrorMessage
					className={styles.gridWrapper__errorMessage}
					error={stripeError}
					withinPortal={false}
					useDefaultMsg={false}
				/>
			</CenteredContentLayout>
		);
	}
}

export const BillingCardStep = connect(null, null)(BillingCardStepDumb);
