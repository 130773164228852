import { customYup as yup, yupExceptions } from '@app/utils/customYup';

export default yup.object().shape({
	type: yup.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('Registry is required!'),
	username: yup.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('Login is required!'),
	password: yup.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('Password is required!'),
});
