import React from 'react';
import styles from './style.module.scss';
import { BillinginfoBlockLayout } from '../billingInfoBlockLayout';
import { Form, withFormik, FormikProps } from 'formik';
import { FormLayout, RowWithDoubleCol, Col, Row } from '@app/shared/layouts/FormLayout';
import { InputLabel } from '@app/shared/text/InputLabel';
import { InputErrorText } from '@app/shared/text/InputErrorText';
import { BasicInput } from '@app/shared/inputs/BasicInput';
import { SelectWithSearch } from '@app/shared/selectWithSearch';
// import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import { countries } from 'countries-list';
import { schema } from './schema';
import { AccountBillingInformationForm } from '../../../../state/interfaces';
import { AppButton } from '@app/shared/buttons/AppButton';
// import infoIcon from '@icons/icon.info.svg';
import notificationIcon from '@icons/intercom.warning.icon.svg';
import _ from 'lodash';
import { LdsSpinner } from '@app/shared/ldsSpinner';
import { useCheckerForAppReadonlyUser, useCheckerIsBiller } from '@app/hooks';
import {
	checkVAT,
	austria,
	belgium,
	bulgaria,
	croatia,
	cyprus,
	czechRepublic,
	denmark,
	estonia,
	finland,
	france,
	germany,
	greece,
	hungary,
	ireland,
	italy,
	latvia,
	lithuania,
	luxembourg,
	malta,
	netherlands,
	poland,
	portugal,
	romania,
	slovakiaRepublic,
	slovenia,
	spain,
	sweden,
} from 'jsvat';
import postalCodes from 'postal-codes-js';

interface FormValues {
	firstName: string;
	lastName: string;
	company: string;
	vatId: number;
	address: string;
	city: string;
	zipcode: number;
	country: string;
	isDisabled: boolean;
}

interface State {
	countyList: {
		text: any;
		value: string;
	}[];
	isValidVAT: boolean;
	invalidVATErrorMsg: string;
	zipcodeValidationResult: string | boolean;
	showZipcodeErr: boolean;
	zipcodeErrText: string;
}

type Props = AccountBillingInformationForm & FormikProps<FormValues>;

const EditBillingInfoBtn = ({
	setFieldValue,
	isSubmitting,
}: {
	setFieldValue: (name: string, value: boolean) => void;
	isSubmitting: boolean;
}) => {
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const { isBiller } = useCheckerIsBiller();

	return (
		<AppButton
			theme={AppButton.themes.regular}
			className={styles.billingInformationInfo__closeBtn}
			type="button"
			onClick={
				isSubmitting || (isReadonlyUser && !isBiller) ? () => {} : () => setFieldValue('isDisabled', false)
			}
			disabled={isReadonlyUser && !isBiller}
		>
			Edit billing information
		</AppButton>
	);
};

class BillingInformationInfoDumb extends React.Component<Props> {
	state = {
		countyList:
			(
				countries && Object.keys(countries).map(key => ({ text: (countries as any)[key].name, value: key }))
			).sort((el1, el2) => (el1.text < el2.text ? -1 : 1)) || [],
		isValidVAT: true,
		invalidVATErrorMsg: '',
		zipcodeValidationResult: false,
		showZipcodeErr: false,
		zipcodeErrText: '',
	};

	handleVATChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// check for Valid VAT only is value is exists since it is an optional field
		const isValidVAT = e.target.value
			? checkVAT(e.target.value, [
					austria,
					belgium,
					bulgaria,
					croatia,
					cyprus,
					czechRepublic,
					denmark,
					estonia,
					finland,
					france,
					germany,
					greece,
					hungary,
					ireland,
					italy,
					latvia,
					lithuania,
					luxembourg,
					malta,
					netherlands,
					poland,
					portugal,
					romania,
					slovakiaRepublic,
					slovenia,
					spain,
					sweden,
			  ]).isValid
			: true;
		this.setState((state: State) => ({
			...state,
			isValidVAT,
			invalidVATErrorMsg: isValidVAT ? '' : 'Please enter a valid EU VAT',
		}));
		this.props.handleChange(e);
	};

	handleDiscard = () => {
		const { initialValues, setValues, setErrors, setTouched, setFieldValue, clearAccountBillingError } = this.props;
		setValues(initialValues);
		setErrors({});
		setTouched({});
		setFieldValue('isDisabled', true);
		clearAccountBillingError({});
	};

	getSaveChangesBtnStatus = (errors: any, touched: any) => {
		let found = false;
		const touchedKeys = Object.keys(touched);

		for (let key in touchedKeys) {
			found = Object.keys(errors).some((errorsKey: string) => errorsKey === touchedKeys[key]);
			if (found) {
				return found;
			}
		}

		return found;
	};

	handleSubmitWithValidaition = () => {
		const {
			submitForm,
			values: { country, zipcode },
			errors,
		} = this.props;
		const zipcodeValidationResult = postalCodes.validate(country, zipcode);
		let showZipcodeErr = false;
		let zipcodeErrText = '';
		if (errors['zipcode'] || typeof zipcodeValidationResult === 'string') {
			showZipcodeErr = true;
			zipcodeErrText = errors['zipcode']
				? errors['zipcode']
				: zipcodeValidationResult !== true
				? zipcode
					? 'Invalid zipcode'
					: 'Zipcode is required'
				: '';
		}
		this.setState({ zipcodeValidationResult, showZipcodeErr, zipcodeErrText });
		if (!showZipcodeErr) {
			this.setState({ zipcodeValidationResult: false, showZipcodeErr: false, zipcodeErrText: '' });
			submitForm();
		}
	};

	handleZipcodeChange = (e: React.ChangeEvent<any>) => {
		const { setFieldValue } = this.props;

		this.setState({ zipcodeValidationResult: false, showZipcodeErr: false, zipcodeErrText: '' });
		setFieldValue('zipcode', e.target.value);
	};

	renderRightElem = () => {
		const { setFieldValue, values, isSubmitting, errors, touched } = this.props;
		const { isValidVAT } = this.state;

		if (values.isDisabled) {
			return (
				<div>
					{this.props.loading && <LdsSpinner className={styles.billingInformationInfo__spinner} />}
					<EditBillingInfoBtn setFieldValue={setFieldValue} isSubmitting={isSubmitting} />
				</div>
			);
		}

		return (
			<div className={styles.billingInformationInfo__btnGroup}>
				<AppButton
					theme={AppButton.themes.regular}
					className={styles.billingInformationInfo__closeBtn}
					type="button"
					onClick={this.handleDiscard}
				>
					Discard
				</AppButton>
				<AppButton
					theme={AppButton.themes.action}
					onClick={this.handleSubmitWithValidaition}
					disabled={this.getSaveChangesBtnStatus(errors, touched) || !isValidVAT}
					type="submit"
				>
					Save changes
				</AppButton>
			</div>
		);
	};

	render() {
		const {
			errors,
			touched,
			values,
			handleChange,
			handleBlur,
			setFieldValue,
			loading,
			accountBillingError,
		} = this.props;
		const { countyList, isValidVAT, invalidVATErrorMsg, zipcodeErrText, showZipcodeErr } = this.state;

		return (
			<BillinginfoBlockLayout title="Billing information" rightElem={this.renderRightElem()}>
				<Form>
					<div className={styles.billingInformationInfo}>
						<FormLayout rowGap={14}>
							<Row>
								<div>
									<h3 className={styles.billingInformationInfo__formBlockTitle}>
										Personal information
									</h3>
								</div>
								<div></div>
							</Row>
							<RowWithDoubleCol>
								<Col type="label">
									<InputLabel htmlFor="firstName" text="First Name" />
								</Col>
								<Col
									type="input"
									subContent={
										<InputErrorText
											errorText={
												accountBillingError?.get('name') ||
												(touched['firstName'] ? errors['firstName'] : '')
											}
										/>
									}
								>
									<BasicInput
										id="firstName"
										name="firstName"
										placeholder="First Name"
										value={values.firstName}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											!!accountBillingError?.get('name') ||
											(touched['firstName'] && !!errors['firstName'])
										}
										disabled={values.isDisabled}
										loading={loading}
										setFieldValue={setFieldValue}
									/>
								</Col>
								<Col type="label">
									<InputLabel htmlFor="lastName" text="Last Name" />
								</Col>
								<Col
									type="input"
									subContent={
										<InputErrorText
											errorText={
												accountBillingError?.get('name') ||
												(touched['lastName'] ? errors['lastName'] : '')
											}
										/>
									}
								>
									<BasicInput
										id="lastName"
										name="lastName"
										placeholder="Last Name"
										value={values.lastName}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											!!accountBillingError?.get('name') ||
											(touched['lastName'] && !!errors['lastName'])
										}
										disabled={values.isDisabled}
										loading={loading}
										setFieldValue={setFieldValue}
									/>
								</Col>
							</RowWithDoubleCol>
							<Row>
								<div>
									<h3 className={styles.billingInformationInfo__formBlockTitle}>Company</h3>
								</div>
								<div></div>
							</Row>
							<RowWithDoubleCol>
								<Col type="label">
									<InputLabel htmlFor="company" text="Company" />
								</Col>
								<Col
									type="input"
									subContent={
										<InputErrorText
											errorText={
												accountBillingError?.get('name') ||
												(touched['company'] ? errors['company'] : '')
											}
										/>
									}
								>
									<BasicInput
										id="company"
										name="company"
										placeholder="Company (optional)"
										value={values.company}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											!!accountBillingError?.get('name') ||
											(touched['company'] && !!errors['company'])
										}
										disabled={values.isDisabled}
										loading={loading}
										setFieldValue={setFieldValue}
									/>
								</Col>
								<Col type="label">
									<InputLabel htmlFor="vatId" text="EU VAT" />
								</Col>
								<Col
									type="input"
									subContent={
										<InputErrorText
											errorText={
												accountBillingError?.get('vatId') ||
												(touched['vatId'] ? invalidVATErrorMsg : '')
											}
										/>
									}
								>
									<BasicInput
										id="vatId"
										name="vatId"
										placeholder="EU VAT (optional)"
										autoComplete="off"
										value={values.vatId}
										onChange={this.handleVATChange}
										onBlur={handleBlur}
										error={!!accountBillingError?.get('vatId') || (touched['vatId'] && !isValidVAT)}
										disabled={values.isDisabled}
										loading={loading}
										setFieldValue={setFieldValue}
									/>
								</Col>
							</RowWithDoubleCol>
							<Row>
								<div>
									<h3 className={styles.billingInformationInfo__formBlockTitle}>Address</h3>
								</div>
								<div></div>
							</Row>
							<RowWithDoubleCol>
								<Col type="label">
									<InputLabel htmlFor="address" text="Address" />
								</Col>
								<Col
									type="input"
									subContent={
										<InputErrorText
											errorText={
												accountBillingError?.get('address') ||
												(touched['address'] ? errors['address'] : '')
											}
										/>
									}
								>
									<BasicInput
										id="address"
										name="address"
										placeholder="Address"
										value={values.address}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											!!accountBillingError?.get('address') ||
											(touched['address'] && !!errors['address'])
										}
										disabled={values.isDisabled}
										loading={loading}
										setFieldValue={setFieldValue}
									/>
								</Col>
								<Col type="label">
									<InputLabel htmlFor="city" text="City" />
								</Col>
								<Col
									type="input"
									subContent={
										<InputErrorText
											errorText={
												accountBillingError?.get('city') ||
												(touched['city'] ? errors['city'] : '')
											}
										/>
									}
								>
									<BasicInput
										id="city"
										name="city"
										placeholder="City"
										value={values.city}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											!!accountBillingError?.get('city') || (touched['city'] && !!errors['city'])
										}
										disabled={values.isDisabled}
										loading={loading}
										setFieldValue={setFieldValue}
									/>
								</Col>
							</RowWithDoubleCol>
							<RowWithDoubleCol>
								<Col type="label">
									<InputLabel htmlFor="zipcode" text="Zipcode" />
								</Col>
								<Col
									type="input"
									subContent={
										<InputErrorText
											className={styles.billingInformationInfo__zipcode__label_error}
											errorText={accountBillingError?.get('zipcode') || zipcodeErrText}
										/>
									}
								>
									<BasicInput
										id="zipcode"
										name="zipcode"
										placeholder="Zipcode"
										value={values.zipcode}
										onChange={this.handleZipcodeChange}
										error={!!accountBillingError?.get('zipcode') || showZipcodeErr}
										disabled={values.isDisabled}
										inputErrorClassName={styles.billingInformationInfo__zipcode__input_error}
										customErrorIcon={notificationIcon}
										loading={loading}
										setFieldValue={setFieldValue}
									/>
								</Col>
								<Col type="label">
									<InputLabel htmlFor="country" text="Country" />
								</Col>
								<Col
									type="input"
									subContent={
										<InputErrorText
											errorText={
												accountBillingError?.get('country') ||
												(touched['country'] ? errors['country'] : '')
											}
										/>
									}
								>
									<SelectWithSearch
										selectName="country"
										handleChange={setFieldValue}
										options={countyList}
										wrapClassname={styles.billingInformationInfo__selectWrapper}
										selectClassname={styles.billingInformationInfo__select}
										optionsClassname={styles.billingInformationInfo__selectOptions}
										showSelectedSign={true}
										selectValue={values.country}
										disabled={values.isDisabled}
										disabledInput={values.isDisabled}
										error={
											!!accountBillingError?.get('country') ||
											(touched['country'] && !!errors['country'])
										}
										onBlur={handleBlur}
									/>
								</Col>
							</RowWithDoubleCol>
						</FormLayout>
					</div>
				</Form>
			</BillinginfoBlockLayout>
		);
	}
}

export const BillingInformationInfo = withFormik<AccountBillingInformationForm, any>({
	enableReinitialize: true,
	mapPropsToValues: props => ({
		firstName: props.initialValues?.firstName || '',
		lastName: props.initialValues?.lastName || '',
		company: props.initialValues?.company || '',
		vatId: props.initialValues?.vatId || '',
		address: props.initialValues?.address || '',
		city: props.initialValues?.city || '',
		zipcode: props.initialValues?.zipcode || '',
		country: props.initialValues?.country || '',
		isDisabled: true,
	}),
	handleSubmit: (values, { props, setFieldValue, setSubmitting }) => {
		setSubmitting(true);
		const valuesForCompare = { ...values };
		delete valuesForCompare.isDisabled;

		// since we should send empty string if no vatId and if no vatId back responds with null
		// we should use this check to avoid unneessary request when no vatId
		if (!props.initialValues?.vatId && !valuesForCompare.vatId) {
			valuesForCompare.vatId = props.initialValues?.vatId;
		}

		if (!_.isEqual(props.initialValues, valuesForCompare)) {
			const formValues = {
				name: `${values.firstName} ${values.lastName}${values.company.trim() ? ` (${values.company})` : ''}`,
				addressLine1: values.address,
				city: values.city,
				postalCode: values.zipcode,
				country: values.country,
				vatId: values.vatId,
			};
			props.onSubmit({
				formValues,
				onSuccess: () => {
					setFieldValue('isDisabled', true);
				},
				onFinal: () => setSubmitting(false),
			});
		} else {
			setFieldValue('isDisabled', true);
			setSubmitting(false);
		}
	},
	validationSchema: schema,
})(BillingInformationInfoDumb);
