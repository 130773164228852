import React from 'react';
import io from 'socket.io-client';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import '../../../../../node_modules/xterm/css/xterm.css';
import { createTerminal } from '@app/utils/createTerminal';
import api from '@app/apis/api';

interface ClusterConsoleTabProps {
	id: string;
	className: string;
	termIsActive: boolean;
	hostName: string;
}

export const ClusterConsoleTab: React.FC<ClusterConsoleTabProps> = ({ id, className, termIsActive, hostName }) => {
	const [sessionId, setSessionId] = React.useState('');
	const [socket, setSocket] = React.useState<SocketIOClient.Socket | null>(null);
	const [terminal, setTerminal] = React.useState<Terminal | null>(null);
	const [dataFromClient, setDataFromClient] = React.useState<{ data: string } | null>(null);
	const token = api.getToken();

	const [cmdToInitSocket, setCmdToInitSocket] = React.useState<string | null>(null);
	const [dataFromSock, setDataFromSock] = React.useState<{ data: string } | null>(null);
	const [consoleInited, setConsoleInited] = React.useState(false);

	// create terminal instance on did mount
	React.useEffect(() => {
		if (id && termIsActive && !terminal) {
			const terminal = createTerminal({
				fontFamily: 'courier-new, courier, monospace',
				fontSize: 14,
			});
			const fitAddon = new FitAddon();
			terminal.loadAddon(fitAddon);
			let terminalElem = document.getElementById(`${id}-container`)!;
			terminal.open(terminalElem);
			fitAddon.fit();
			terminal.write('Please enter the command or shell to run e.g. sh, bash\r\n$ ');
			setTerminal(terminal);
		}
	}, [id, termIsActive, terminal]);

	// create socket connection
	React.useEffect(() => {
		if (termIsActive && !socket && cmdToInitSocket !== null) {
			const socketBaseAddr = (
				process.env.REACT_APP_API_URL || 'wss://api-appfleet-com-staging.herokuapp.com'
			).replace('https://', 'wss://');

			const newSocket = io(`${socketBaseAddr}/api/v1/ws/node/${hostName}/console`, {
				transports: ['websocket'],
				query: { token },
			});

			newSocket.emit('appfleet.api.v1.connect.SessionStart', { cmd: cmdToInitSocket });
			newSocket.on('connect', () => {});
			newSocket.on('appfleet.api.v1.connect.SessionStarted', (data: { sessionId: string }) => {
				setSessionId(data.sessionId);
			});
			setSocket(newSocket);
		}
	}, [socket, hostName, token, id, termIsActive, cmdToInitSocket]);

	// write data to terminal from the client
	// only for not inited console
	React.useEffect(() => {
		if (terminal && dataFromClient !== null && !consoleInited) {
			terminal.write(dataFromClient.data);
		}
	}, [terminal, dataFromClient, consoleInited]);

	// write data to terminal from the socket
	// when console is inited
	React.useEffect(() => {
		if (terminal && dataFromSock !== null && consoleInited) {
			terminal.write(dataFromSock.data);
		}
	}, [terminal, consoleInited, dataFromSock]);

	// send data via socket on input
	React.useEffect(() => {
		if (terminal && socket && sessionId) {
			terminal.onData(data => {
				socket.emit('appfleet.api.v1.connect.Input', {
					sessionId: sessionId,
					cmd: data,
				});
			});
		}
	}, [socket, terminal, sessionId]);

	// set socket to handle output response
	React.useEffect(() => {
		if (socket && sessionId) {
			socket.on('appfleet.api.v1.connect.Output', (data: { sessionId: string; data: string }) => {
				if (data.sessionId === sessionId) {
					setDataFromSock({ data: data.data });
				}
			});

			socket.on('disconnect', () => {
				setDataFromSock({ data: '\r\n$ Connection closed..' });
			});
		}
	}, [socket, sessionId]);

	// set terminal handlers for uninitialized terminal
	React.useEffect(() => {
		if (terminal) {
			let currentLine = '';

			terminal.onKey(({ key, domEvent }: { key: string; domEvent: KeyboardEvent }) => {
				if (consoleInited) return false;

				if (domEvent.key === 'Enter') {
					if (currentLine) {
						setCmdToInitSocket(currentLine);
						setDataFromClient({ data: '\r\n' });
						setConsoleInited(true);
						currentLine = '';
					}
					return false;
				} else if (domEvent.key === 'Backspace') {
					if (currentLine.length) {
						currentLine = currentLine.slice(0, currentLine.length - 1);
						setDataFromClient({ data: '\b \b' });
					}
					return false;
				} else {
					currentLine += key;
					setDataFromClient({ data: key });
				}
			});

			terminal.attachCustomKeyEventHandler(e => {
				// if console is inited then data should streaming to the back without any restrictions
				if (consoleInited) return true;
				// if console is NOT inited we should restrict some actions like arrows, end, etc.
				// which leads to issues with term
				if (
					e.ctrlKey ||
					e.altKey ||
					e.metaKey ||
					e.code === 'End' ||
					e.code === 'Home' ||
					e.code === 'Delete' ||
					e.code === 'Left' ||
					e.code === 'ArrowLeft' ||
					e.code === 'Right' ||
					e.code === 'ArrowRight' ||
					e.code === 'Up' ||
					e.code === 'ArrowUp' ||
					e.code === 'Down' ||
					e.code === 'ArrowDown'
				) {
					return false;
				}
				return true;
			});

			terminal.onData(data => {
				if (!consoleInited && data.length > 1) {
					currentLine += data;
					setDataFromClient({ data });
				}
			});
		}
	}, [terminal, consoleInited]);

	// autofocus
	React.useEffect(() => {
		if (terminal && termIsActive) {
			terminal.focus();
		}
	}, [terminal, termIsActive]);

	// unmount handling
	React.useEffect(() => {
		return () => {
			socket?.close();
		};
	}, [socket, id]);

	return <div id={`${id}-container`} className={className}></div>;
};
