import { call, take, takeEvery, put } from 'redux-saga/effects';
import { getRegistriesApi } from '../api';
import { getRegistries, setRegistries, setRegistriesError } from '../actions';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { REGISTRIES_URL } from '../url';

export function* watchGetRegistries() {
	while (true) {
		const action = yield take('*');
		if (getRegistries.is(action)) {
			yield call(getRegistriesWorker);
		}
	}
}

export function* watchGetRegistriesOnLocChange() {
	yield takeEvery(LOCATION_CHANGE, function* onNavigateRegistriesPage(a: LocationChangeAction) {
		const urlMatch = REGISTRIES_URL.match(a.payload.location, true);
		if (!urlMatch.isMatched) {
			return;
		}

		yield call(getRegistriesWorker);
	});
}

function* getRegistriesWorker() {
	try {
		const result = yield call(getRegistriesApi);
		yield put(setRegistries({ registries: result }));
	} catch (e) {
		yield put(setRegistriesError({ error: { message: e.message } }));
	}
}
