import React from 'react';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { LdsSpinner } from '../ldsSpinner';

interface BtnProgressProps {
	isSubmitting: boolean;
	isDisabled: boolean;
	text: string;
	timerSpeed?: number;
	className?: string;
}

export const BtnProgress: React.FC<BtnProgressProps> = (props: BtnProgressProps) => {
	const { isSubmitting, isDisabled, timerSpeed = 500, text, className } = props;
	const [steps, setStepsAmount] = React.useState<null[]>([]);

	React.useEffect(() => {
		if (!isSubmitting) {
			setStepsAmount([]);
		}
	}, [isSubmitting]);

	React.useEffect(() => {
		let interval: any;
		if (isSubmitting && steps.length < 10) {
			interval = setInterval(() => {
				setStepsAmount([...steps, null]);
			}, timerSpeed);
		}
		return () => clearInterval(interval);
	}, [isSubmitting, steps, timerSpeed]);

	return (
		<Button
			disabled={isDisabled}
			type="submit"
			className={classnames('btn-one', styles.btnProgress, className)}
			onClick={isSubmitting ? e => e.preventDefault() : undefined}
		>
			<div className={styles.btnProgressText}>
				<span>{text}</span>
				{isSubmitting && <LdsSpinner className={styles.btnProgressText__spinner} inverted={true} />}
			</div>
			{isSubmitting ? (
				<div className={styles.btnProgressBar}>
					{steps.map((_, stepIdx) => {
						return <div key={stepIdx} className={classnames(styles.progressBarStep)} />;
					})}
				</div>
			) : null}
		</Button>
	);
};
