import React from 'react';
import styles from './style.module.scss';
import { BillinginfoBlockLayout } from '../billingInfoBlockLayout';
import { PaymentInfo } from '../../../../state/interfaces';
import { AddPaymentCardBtn } from './AddPaymentCardBtn';
import { PaymentInfoCard } from './PaymentInfoCard';
import { LdsSpinner } from '@app/shared/ldsSpinner';
import _ from 'lodash';

interface BillingPaymentInfoProps {
	paymentInfo: PaymentInfo;
	handleRemove: ({ id, onFinal }: { id: number; onFinal: () => void }) => void;
	loading: boolean;
	setDefaultBillingCard: ({ cardId, onFinal }: { cardId: number; onFinal?: () => void }) => void;
}

export const BillingPaymentInfo: React.FC<BillingPaymentInfoProps> = ({
	paymentInfo,
	handleRemove,
	loading,
	setDefaultBillingCard,
}) => {
	const [sortedPaymentInfo, setSortedPaymentInfo] = React.useState<PaymentInfo>([]);

	React.useEffect(() => {
		if (paymentInfo.length) {
			const sorted = _.orderBy(paymentInfo, ['default', 'expYear', 'expMonth']).reverse();
			setSortedPaymentInfo(sorted);
		}
	}, [paymentInfo]);

	return (
		<BillinginfoBlockLayout title="Payment" rightElem={loading ? <LdsSpinner /> : <></>}>
			<div className={styles.billingPaymentInfo}>
				{sortedPaymentInfo.map(card => (
					<PaymentInfoCard
						key={card.id}
						paymentInfoCard={card}
						handleRemove={handleRemove}
						handleMakeDefault={setDefaultBillingCard}
					/>
				))}
				<AddPaymentCardBtn />
			</div>
		</BillinginfoBlockLayout>
	);
};
