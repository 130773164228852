import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import errorLogo from '@icons/icon-error.svg';
import { Ref } from 'react';
import { SvgIcon } from '../SvgIcon';
import { AppSkeleton } from '@app/shared/AppSkeleton';

interface LabeledInputRegularProps {
	handleChange?: (_: any) => void;
	inputValue: string | number | undefined;
	inputName: string;
	labelText?: string;
	inputPlaceholder?: string;
	handleBlur?: (_: any) => void;
	errorMsg?: string | undefined;
	isTouched?: boolean | undefined;
	inputType?: 'text' | 'password';
	iconComponent?: ReactNode | null;
	detailComponent?: ReactNode | null;
	showErrLabel?: boolean;
	showErrStyles?: boolean;
	className?: string;
	validateOnBlur?: boolean;
	autoComplete?: string;
	inputAdditional?: ReactNode | null;
	inputRef?: Ref<HTMLInputElement>;
	disabled?: boolean;
	useFocusSelection?: boolean;
	errorLabelClassName?: string;
	loading?: boolean;
	skeletonClassname?: string;
	setFieldValue?: (name: string, value: string) => void;
}

export const LabeledInputRegular: React.FC<LabeledInputRegularProps> = ({
	errorMsg,
	isTouched,
	handleChange = () => {},
	handleBlur = () => {},
	inputValue,
	inputName,
	inputPlaceholder = '',
	inputType = 'text',
	labelText = '',
	iconComponent = null,
	detailComponent = null,
	showErrLabel = true,
	showErrStyles = true,
	className = '',
	validateOnBlur = false,
	autoComplete = '',
	inputAdditional = null,
	inputRef,
	disabled,
	useFocusSelection = false,
	errorLabelClassName = '',
	loading = false,
	skeletonClassname = '',
	setFieldValue = () => {},
}) => {
	const ownHandleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!disabled) {
			e.target.select();
		}
	};

	const ownHandleBlur = (name: string, value: string | number | undefined) => (
		e: React.FocusEvent<HTMLInputElement>
	) => {
		if (setFieldValue) {
			// trim and replace multiwhitespaces with single one
			const prepValue = String(value).trim().replace(/\s\s+/g, ' ');
			setFieldValue(name, prepValue);
		}
		return handleBlur(e);
	};

	return (
		<label
			className={classnames(
				styles.regInputWrap,
				{ [className]: !!className },
				{ [styles.regInputWrap_active]: !disabled }
			)}
		>
			{labelText ? (
				<div className={styles.regInputWrap__text}>
					<span>{labelText}</span>
					{iconComponent}
				</div>
			) : null}

			<input
				className={classnames(
					styles.regInputWrap__input,
					{ [styles.regInputWrap__input_error]: showErrStyles && errorMsg && isTouched },
					{ [styles.regInputWrap__input_disabled]: disabled }
				)}
				type={inputType}
				onChange={handleChange}
				// we shouldn't show error if use just clicked on empty input and blur
				onBlur={
					validateOnBlur
						? ownHandleBlur(inputName, inputValue)
						: inputValue && String(inputValue).length
						? ownHandleBlur(inputName, inputValue)
						: () => {}
				}
				value={loading ? '' : inputValue}
				name={inputName}
				placeholder={loading ? '' : inputPlaceholder}
				autoComplete={autoComplete}
				ref={inputRef}
				onFocus={useFocusSelection ? ownHandleFocus : () => {}}
				disabled={disabled}
			/>

			{loading && (
				<AppSkeleton
					className={classnames(styles.regInputWrap__skeleton, { [skeletonClassname]: !!skeletonClassname })}
				/>
			)}

			{showErrStyles && errorMsg && isTouched ? (
				<SvgIcon iconSrc={errorLogo} className={classnames(styles.regInputWrap__errorLogo)} />
			) : null}

			{detailComponent}

			{showErrLabel && isTouched && errorMsg ? (
				<div
					className={classnames(styles.regInputWrap__errorLabel, {
						[styles.regInputWrap__errorLabel_withDetail]: detailComponent,
						[errorLabelClassName]: !!errorLabelClassName,
					})}
				>
					{errorMsg}
				</div>
			) : null}

			{inputAdditional}
		</label>
	);
};
