import React from 'react';
import { Label } from 'semantic-ui-react';
import styles from './styles.module.scss';
import iconInfo from '@icons/icon.info.svg';
import { SvgIcon } from '@app/shared/SvgIcon';

interface PasswordTooltipProps {}

export const PasswordTooltip: React.FC<PasswordTooltipProps> = () => {
	const [isShow, setIsShow] = React.useState(false);

	return (
		<div className={styles.passTooltip} onMouseOver={() => setIsShow(true)} onMouseLeave={() => setIsShow(false)}>
			<SvgIcon iconSrc={iconInfo} />
			{isShow ? (
				<Label basic pointing="below" className={styles.passTooltip__labelContent}>
					Password need to be at least 8 characters long, contain at least 1 number
				</Label>
			) : null}
		</div>
	);
};
