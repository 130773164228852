import { AppState } from '@app/reducers';
import { ApiRegion } from './entity';
import memo from 'memoize-one';
import { ApiNode } from '../cluster-nodes';

export const getRegionsSelector = (store: AppState): ApiRegion[] => store.regions.regions;

export const getRegionsLoadState = (store: AppState): boolean => store.regions.isLoaded;

export const getRegionByCode = memo((regions: ApiRegion[], code: string): ApiRegion | null => {
	return regions.find(region => region.code === code) || null;
});

export const getRegionByCodeSelector = (store: AppState, code: string): ApiRegion | null => {
	const regions = getRegionsSelector(store);
	return getRegionByCode(regions, code);
};

export const getRegionsByNodes = (store: AppState, nodes: ApiNode[]) => {
	const regions = getRegionsSelector(store);
	const regionsMap: Record<string, ApiRegion> = {};

	for (const node of nodes) {
		if (!regionsMap[node.region]) {
			regionsMap[node.region] = regions.find(r => r.code === node.region) as ApiRegion;
		}
	}

	return Object.values(regionsMap);
};
