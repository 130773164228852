import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
// import { ErrorMessage } from '../../../../shared/errorMessage';
import { AppState } from '@app/reducers';
import {
	CLUSTER_URL,
	CLUSTER_SETTINGS_URL,
	CLUSTER_NODES_URL,
	CLUSTER_LOGS_URL,
	CLUSTER_CONSOLE_URL,
	// CLUSTER_HEALTHCHECK_URL,
	CLUSTER_ACITVITY_LOG_URL,
} from '../../state';
import { ClusterInfo } from '../clusterInfo';
import { ClusterSettings } from '../clusterSettings';
import { ClusterNodes } from '../ClusterNodes';
import { ClusterLogs } from '../clusterLogs';
import { ClusterConsole } from '../clusterConsole';
// import { ClusterHealthcheck } from '../clusterHealthcheck';
import { AppRoute } from '@app/routes';
import styles from './styles.module.scss';
import { SubNavigation } from '@app/shared/subNavigation';
import { SubMenuItem } from '@app/shared/subNavigation';
import { Icon } from 'semantic-ui-react';
import { UrlPath } from 'rd-url-utils';
import { NotFound } from '@app/modules/not-found';
import { AppButton } from '@app/shared/buttons/AppButton';
import { ClusterActivityLog } from '../ClusterActivityLog';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { clearCluster } from '../../state/actions';
import { useCheckerIsClusterReadonly } from '@app/hooks';

export interface IClusterVersion {
	image: string;
	tag: string;
	digest: string;
	createdAt: string;
	deletedAt: string;
	updatedAt: string;
	version: number;
	env: { [key: string]: string };
	ports: { private: number; public: number; protocol: string }[];
	httpsPort: number | null;
}

export interface ClusterDomain {
	id: number;
	name: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	expireAt: string;
	isDnsTarget?: boolean;
}

export interface ICluster {
	id: number;
	name: string;
	description: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	size: string;
	currentVersion: IClusterVersion;
	color: string;
	registry: {
		url: string;
		login: string;
		password: string;
	};
	domains: ClusterDomain[];
}

interface Props {
	location: string;
}

enum Protocols {
	HTTP = 'http',
	HTTPSecure = 'https',
}

export const Cluster: React.FC<{}> = connect((state: AppState) => {
	return { location: state.router.location.pathname };
})((props: Props) => {
	const { cluster, accountPublicId, clusterDomains, getDomainsReqDone } = useSelector((state: AppState) => ({
		cluster: state.cluster.cluster,
		accountPublicId: state.account.info.publicId,
		error: state.cluster.error,
		clusterDomains: state.cluster.clusterDomains,
		getDomainsReqDone: state.cluster.getDomainsReqDone,
	}));
	const dispatch = useDispatch();
	const [menuItems, setMenuItems] = useState<SubMenuItem[]>([]);
	const [activeItemIdx, setActiveItemIndex] = useState(0);
	const [isSettingsDirty, setIsSettingsDirty] = useState(false);
	const [domainToDisplay, setDomainToDisplay] = useState<string | null>(null);
	const { isReadonlyCluster } = useCheckerIsClusterReadonly(cluster.id);
	const [protocol, setProtocol] = useState<Protocols | null>(null);

	useEffect(() => {
		const menuItems = createMenuItems({ ...cluster, isSettingsDirty });
		menuItems.forEach((item, itemIdx) => {
			const urlMatch = item.urlPath.match(props.location, true);
			if (urlMatch.isMatched) {
				setActiveItemIndex(itemIdx);
			}
		});

		setMenuItems(menuItems);
	}, [dispatch, cluster, props.location, isSettingsDirty]);

	// reset to initial state on unmount
	useEffect(() => {
		return () => {
			dispatch(clearCluster({}));
		};
	}, [dispatch]);

	// if there are clusterDomains then user has enabled https and has custom domains
	// so if he has customDomain we show and link to it, otherway to the serviceDomain
	useEffect(() => {
		if (clusterDomains.length) {
			setDomainToDisplay(clusterDomains[0].name);
			setProtocol(Protocols.HTTPSecure);
		} else {
			setDomainToDisplay(`${cluster.name}-${accountPublicId}.${process.env.REACT_APP_HOSTNAME_DOMAIN}`);
			setProtocol(Protocols.HTTP);
		}
	}, [clusterDomains, cluster, accountPublicId]);

	return cluster ? (
		<div className={styles.cluster}>
			{menuItems.length ? (
				<SubNavigation
					className={styles.cluster__subNav}
					items={menuItems}
					activeItemIdx={activeItemIdx}
					button={
						<div className={styles.subnavContent}>
							<AppButton
								className={styles.clusterNavBtn}
								onClick={
									cluster.name
										? () => window.open(`${protocol}://${domainToDisplay}`, '_blank')
										: () => {}
								}
							>
								{cluster.name && getDomainsReqDone ? (
									<React.Fragment>
										<span>{domainToDisplay}</span>
										<Icon name="angle right" />
									</React.Fragment>
								) : (
									<AppSkeleton className={styles.skeleton} />
								)}
							</AppButton>
						</div>
					}
				/>
			) : null}

			<div className={styles.cluster__content}>
				<Switch>
					<AppRoute path={CLUSTER_URL.urlTemplate} exact={true} auth={true}>
						<ClusterInfo cluster={cluster} isReadonlyCluster={isReadonlyCluster} />
					</AppRoute>

					<AppRoute path={CLUSTER_SETTINGS_URL.urlTemplate} exact={true} auth={true}>
						<ClusterSettings getIsDirty={setIsSettingsDirty} isReadonlyCluster={isReadonlyCluster} />
					</AppRoute>

					<AppRoute path={CLUSTER_NODES_URL.urlTemplate} exact={true} auth={true}>
						<ClusterNodes cluster={cluster} isReadonlyCluster={isReadonlyCluster} />
					</AppRoute>

					<AppRoute path={CLUSTER_LOGS_URL.urlTemplate} exact={true} auth={true}>
						<ClusterLogs />
					</AppRoute>

					<AppRoute path={CLUSTER_CONSOLE_URL.urlTemplate} exact={true} auth={true}>
						<ClusterConsole isReadonlyCluster={isReadonlyCluster} />
					</AppRoute>

					{/* temp hidden by 689 task since Healthcheck isn't ready yet */}
					{/* <AppRoute path={CLUSTER_HEALTHCHECK_URL.urlTemplate} exact={true} auth={true}>
						<ClusterHealthcheck />
					</AppRoute> */}

					<AppRoute path={CLUSTER_ACITVITY_LOG_URL.urlTemplate} exact={true} auth={true}>
						<ClusterActivityLog cluster={cluster} isReadonlyCluster={isReadonlyCluster} />
					</AppRoute>

					<Route path="*">
						<NotFound className={styles.notFoundLayout} />
					</Route>
				</Switch>
			</div>

			{/* <ErrorMessage error={error} /> */}
		</div>
	) : null;
});

interface ClusterSubMenuItem extends SubMenuItem {
	urlPath: UrlPath<{}, {}>;
}

const createMenuItems = ({
	id,
	name,
	isSettingsDirty,
}: {
	id: number;
	name: string;
	isSettingsDirty: boolean;
}): ClusterSubMenuItem[] => {
	return [
		{
			name: 'Information',
			url: name && CLUSTER_URL.format({ name }),
			urlPath: CLUSTER_URL,
			params: id,
		},
		{
			name: 'Settings',
			url: name && CLUSTER_SETTINGS_URL.format({ name }),
			urlPath: CLUSTER_SETTINGS_URL,
			params: id,
			isDirty: isSettingsDirty,
		},
		{
			name: 'Resources',
			url: name && CLUSTER_NODES_URL.format({ name }),
			urlPath: CLUSTER_NODES_URL,
			params: id,
		},
		{
			name: 'Logs',
			url: name && CLUSTER_LOGS_URL.format({ name }),
			urlPath: CLUSTER_LOGS_URL,
			params: id,
		},
		{
			name: 'Console',
			url: name && CLUSTER_CONSOLE_URL.format({ name }),
			urlPath: CLUSTER_CONSOLE_URL,
			params: id,
		},

		// temp hidden by 689 task since Healthcheck isn't ready yet
		// {
		// 	name: 'Healthchecks',
		// 	url: name && CLUSTER_HEALTHCHECK_URL.format({ name }),
		// 	urlPath: CLUSTER_HEALTHCHECK_URL,
		// 	params: id,
		// },
		{
			name: 'Activity',
			url: name && CLUSTER_ACITVITY_LOG_URL.format({ name }),
			urlPath: CLUSTER_ACITVITY_LOG_URL,
			params: id,
		},
	];
};
