import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Segment, Button } from 'semantic-ui-react';
import { ErrorMessage } from '@app/shared/errorMessage';
import { AppState } from '@app/reducers';
import { clearRegistriesError } from '../state';
import { EditRegistry } from './editRegistry';
import { EmptyRegistries } from './emptyRegistries';
import { RegistryItem } from './registeryItem';
import classnames from 'classnames';
import { useCheckerForAppReadonlyUser } from '@app/hooks';

export interface Registry {
	id: number;
	type: string;
	username: string;
}

export const Registries: React.FC<{}> = () => {
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const { registries, error } = useSelector((state: AppState) => ({
		error: state.registries.error,
		registries: state.registries.registries,
		snack: state.registries.error.snack,
	}));
	const dispatch = useDispatch();
	const [isShowEdit, setIsShowEdit] = React.useState(false);
	const [initRegistry, setInitRegistry] = React.useState({} as Registry);

	React.useEffect(() => {
		return () => {
			dispatch(clearRegistriesError({}));
		};
	}, [dispatch]);

	const onEditRegistryDismiss = () => {
		setIsShowEdit(false);
		setInitRegistry({} as Registry);
	};

	return (
		<>
			{error.message && error.snack && <ErrorMessage error={error} useDefaultMsg={false} />}

			<Segment clearing basic className={classnames('page-header', 'registries__header')}>
				<Header as="h2" content="Registries" floated="left" />

				<Button
					className="btn-three create-registry-btn"
					onClick={isReadonlyUser ? () => {} : () => setIsShowEdit(true)}
					disabled={isReadonlyUser}
				>
					<span>Add a new registry</span>
				</Button>
			</Segment>

			{registries.length ? (
				<div className="registries">
					{registries.map((registry: Registry) => (
						<RegistryItem key={registry.id} onEdit={() => setInitRegistry({ ...registry })} {...registry} />
					))}
				</div>
			) : (
				<EmptyRegistries handleCtrlClick={() => setIsShowEdit(true)} />
			)}

			{isShowEdit || initRegistry.id ? (
				<EditRegistry initRegistry={initRegistry} onDismiss={onEditRegistryDismiss} />
			) : null}
		</>
	);
};
