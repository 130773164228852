import React from 'react';
import styles from './styles.module.scss';
import logo from '@images/logo.cluster.no.connection.png';

export const NoConnection: React.FC<{}> = () => {
	return (
		<div className={styles.clusterConsole__content__body__noConnection}>
			<div className={styles.clusterConsole__content__body__noConnection__logo}>
				<img src={logo} alt="logo" />
			</div>
			<div className={styles.clusterConsole__content__body__noConnection__header}>
				{'No established connection'}
			</div>
			<div className={styles.clusterConsole__content__body__noConnection__subheader}>
				{'Select one of the nodes from the menu to begin'}
			</div>
		</div>
	);
};
