import { call, put, take } from 'redux-saga/effects';
import { getAccountSeatsApi } from '../api';
import { setAccountSeats, getAccountSeats } from '../actions';

export function* watchGetAccountSeats() {
	while (true) {
		const action = yield take('*');
		if (getAccountSeats.is(action)) {
			yield call(getAccountSeatsWorker);
		}
	}
}

export function* getAccountSeatsWorker() {
	try {
		const result = yield call(getAccountSeatsApi);
		yield put(setAccountSeats({ totalSeatsAmount: result.totalSeatsAmount }));
	} catch (e) {
		console.log('+++ getAccountSeatsWorker e', e);
	}
}
