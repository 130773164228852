import React from 'react';

export const useFocusOut = (ref: any, cb: () => void) => {
	React.useEffect(() => {
		function handleFocusOut(event: Event) {
			if (ref.current && !ref.current.contains(event.target)) {
				cb();
			}
		}

		document.addEventListener('focusout', handleFocusOut);

		return () => {
			document.removeEventListener('focusout', handleFocusOut);
		};
	}, [ref, cb]);
};
