import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	className?: string;
	children: JSX.Element[];
}

export const PriceBarGroup = (props: Props) => {
	return <div className={classnames(styles.priceBarGroup, props.className)}>{props.children}</div>;
};
