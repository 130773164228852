import { call, take, put } from "redux-saga/effects";
import { deleteClusterById, getClustersList, deleteClusterByIdFail } from "../actions";
import { deleteClusterByIdApi } from "../api";

export function* watchDeleteClusterById() {
    while (true) {
        const action = yield take("*");
        if (deleteClusterById.is(action)) {
            yield call(deleteClusterByIdWorker, { id: action.id });
        }
    }
}

function* deleteClusterByIdWorker({ id }: { id: number }) {
    try {
        yield call(deleteClusterByIdApi, { id });

        yield put(getClustersList({}));
    } catch (e) {
        yield put(deleteClusterByIdFail({ error: { message: e.message } }));
    }
}
