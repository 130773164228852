import React from 'react';
import { Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import styles from './styles.module.scss';
import { ClusterMember } from '@app/modules/cluster/state/interfaces';
import { getAvailableMembers, updateMemberSClusterPermission } from '@app/modules/cluster/state/actions';
import { TEAM_URL } from '@app/modules/team';
import { VerticalScroll } from '@app/shared/Scrollbars';
import { AppState } from '@app/reducers';
import { SvgIcon } from '@app/shared/SvgIcon';
import buyMoreSeats from '@icons/buy.more.seats.svg';
import iconClose from '@icons/icon.close.svg';
import { AddPermissionRow, ComparedAvailableMember } from './AddPermissionsRow';
import { PermissionsRole } from '../ClusterSettingsPermissions';

interface AddPermissionsProps {
	onDismiss: () => void;
	clusterMembers: ClusterMember[];
}

export const AddPermissions: React.FC<AddPermissionsProps> = ({ onDismiss, clusterMembers }) => {
	const dispatch = useDispatch();
	const [comparedAvailableMembers, setComparedAvailableMembers] = React.useState<ComparedAvailableMember[]>([]);
	const [modifiedMembers, setModifiedMembers] = React.useState<ComparedAvailableMember[]>([]);
	const { availableMembers, clusterId, clusterName } = useSelector((state: AppState) => ({
		availableMembers: state.cluster.availableMembers,
		clusterId: state.cluster.cluster.id,
		clusterName: state.cluster.cluster.name,
	}));

	// fetch available members(account member the same on team page)
	// should consider to mv team members fetch to datamodules to use them from one point in Team page and here
	React.useEffect(() => {
		dispatch(getAvailableMembers({}));
	}, [dispatch]);

	// compare Cluster members and available within account members to get list of whom has permission for cluster
	React.useEffect(() => {
		if (clusterMembers.length && availableMembers.length) {
			const diff = _.differenceWith(availableMembers, clusterMembers, (avMember, clMember) =>
				avMember.permission.toLowerCase() === PermissionsRole.OWNER ? false : avMember.id === clMember.id
			);
			const comparedAvailableMembers: ComparedAvailableMember[] = diff.map(member => {
				return { ...member, hasClusterPermission: false, modified: false };
			});

			// user can't add biller to cluster permissions
			// since GET /api/account/members returns also users with pending invitation, they have no ID, we don't show them either
			setComparedAvailableMembers(
				comparedAvailableMembers.filter(
					member => member.id && member.permission.toLowerCase() !== PermissionsRole.BILLER
				)
			);
		}
	}, [clusterMembers, availableMembers]);

	// update compared list member hasClusterPermission
	const updateMemberHasClusterPermission = (updMember: ComparedAvailableMember) => {
		const updatedComparedList = comparedAvailableMembers.map(avMember => {
			if (avMember.id === updMember.id) {
				return updMember;
			}
			return avMember;
		});
		setComparedAvailableMembers(updatedComparedList);

		// update list of modified members
		if (modifiedMembers.length) {
			let isAbsent = false;
			const updatedModifiedList = modifiedMembers.map(modMember => {
				if (modMember.id === updMember.id) {
					isAbsent = false;
					return { ...updMember, modified: !modMember.modified };
				}
				isAbsent = true;
				return modMember;
			});
			if (isAbsent) {
				updatedModifiedList.push({ ...updMember, modified: !updMember.modified });
			}
			setModifiedMembers(updatedModifiedList);
		} else {
			setModifiedMembers([...modifiedMembers, { ...updMember, modified: !updMember.modified }]);
		}
	};

	// handle save changes
	const saveAddPermissionsChanges = () => {
		const changes = modifiedMembers.filter(modMember => modMember.modified);
		if (changes.length) {
			dispatch(updateMemberSClusterPermission({ members: changes, clusterId, clusterName, onDismiss }));
		} else {
			onDismiss();
		}
	};

	return (
		<div className={styles.addPerms}>
			<SvgIcon iconSrc={buyMoreSeats} className={styles.addPerms__icon} />
			<SvgIcon iconSrc={iconClose} className={styles.addPerms__close} onClick={onDismiss} />

			<div className={styles.addPerms__header}>{'Add people to cluster'}</div>

			<div className={styles.addPerms__subHeader}>{'Choose members of your team to assign to this cluster'}</div>

			<div className={styles.addPerms__tableWrap}>
				<VerticalScroll
					visible={comparedAvailableMembers.length > 5}
					shiftInPixels={20}
					bottomBorderClassName={styles.addPerms__tableWrap__shadowBlock}
				>
					<div className={styles.addPerms__tableWrap__table}>
						{comparedAvailableMembers.map((item, itemIdx) => {
							return (
								<AddPermissionRow
									updateHasClusterPermission={updateMemberHasClusterPermission}
									member={item}
									key={itemIdx}
								/>
							);
						})}
						{comparedAvailableMembers.length < 2 && (
							<div className={styles.addPerms__tableWrap__table__row}>
								<span
									className={classnames(
										styles.addPerms__subHeader,
										styles.addPerms__tableWrap__table__row__addMembers
									)}
								>
									You can add team members using the <Link to={TEAM_URL.urlTemplate}>team</Link> page
								</span>
							</div>
						)}
					</div>
				</VerticalScroll>
			</div>

			<Button
				className={classnames('btn', 'btn-one', styles.addPerms__saveBtn)}
				onClick={() => saveAddPermissionsChanges()}
			>
				{'Save changes'}
			</Button>
		</div>
	);
};
