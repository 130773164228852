import { call, take, put } from 'redux-saga/effects';
import { redeemCouponApi } from '../api';
import { redeemCoupon, redeemCouponFailed, redeemCouponSucceed } from '../actions';

export function* watchRedeemCoupon() {
	while (true) {
		const action = yield take('*');
		if (redeemCoupon.is(action)) {
			yield call(redeemCouponWorker, action);
		}
	}
}

function* redeemCouponWorker({ code }: { code: string }) {
	try {
		const result = yield call(redeemCouponApi, { code });
		yield put(redeemCouponSucceed({ coupon: result }));
	} catch (error) {
		yield put(redeemCouponFailed({ error }));
	}
}
