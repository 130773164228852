import { call, take, put } from 'redux-saga/effects';
import { getAccountMembersApi } from '../api';
import { getAccountMembersList, setMembersList } from '../actions';

export function* watchGetAccountMembers() {
	while (true) {
		const action = yield take('*');
		if (getAccountMembersList.is(action)) {
			yield call(getAccountMembersWorker);
		}
	}
}

export function* getAccountMembersWorker() {
	try {
		const result = yield call(getAccountMembersApi);
		yield put(setMembersList({ membersList: result }));
	} catch (e) {}
}
