import { call, put } from 'redux-saga/effects';
import { rollbackClusterAction, getClusterActivityLogAction, rollbackClusterDoneAction } from '../actions';
import { rollbackClusterRequest } from '../api';
import { getClusterWorker } from '@app/modules/cluster/state/sagas/getCluster';

export function* rollbackClusterSaga(action: ReturnType<typeof rollbackClusterAction>) {
	try {
		const request = yield call(rollbackClusterRequest, action.clusterName, action.versionId);

		if (request.status >= 400) throw request;
		alert(`Cluster successfully rolled back.`);
		yield put(getClusterActivityLogAction({ clusterName: action.clusterName }));
		yield call(getClusterWorker, { name: action.clusterName });
		yield put(rollbackClusterDoneAction({ clusterName: action.clusterName }));
	} catch (e) {
		alert('Failed to rollback cluster.');
	}
}
