import { all, takeEvery } from 'redux-saga/effects';
import { getClusterActivityLogAction, rollbackClusterAction } from '../actions';
import { fetchLogsSaga } from './fetchLogs';
import { rollbackClusterSaga } from './rollbackCluster';
import { LOCATION_CHANGE } from 'connected-react-router';
import { locationChangeSaga } from './locationChange';

export function* activityLogSaga() {
	yield all([
		takeEvery(getClusterActivityLogAction.TYPE, fetchLogsSaga),
		takeEvery(rollbackClusterAction.TYPE, rollbackClusterSaga),
		takeEvery(LOCATION_CHANGE, locationChangeSaga),
	]);
}
