import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	className?: string;
	countryCode: string;
}

export const FlagIcon = (props: Props) => {
	const { className, countryCode } = props;

	return <i className={classnames('flag-icons square', countryCode, styles.flagIcon, className)} />;
};
