import { BtnStatus } from '../shared/AppRequestButton';

interface DeploymentState {
	started: boolean;
	clusterDeployed: boolean;
	completed: boolean;
	failed: boolean;
}

export const getWizardStatus = <T extends DeploymentState>(deploy: T): BtnStatus => {
	if (!deploy) return 'initial';
	if (deploy.failed) return 'error';
	if (deploy.started) return 'loading';
	if (deploy.clusterDeployed || deploy.completed) return 'success';
	return 'initial';
};
