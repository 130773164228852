
import { call, put, takeLatest, delay, select, takeEvery, SagaReturnType } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getHealthStatusesApi } from '@app/data-modules/healthStatuses';
import { CLUSTERS_LIST_URL } from '@app/modules/clusters-list';
import { CLUSTER_URL } from '@app/modules/cluster';
import { getHealthStatusesAction, setHealthStatusesAction } from '../actions';

const UPDATE_STATUSES_REQUEST_DELAY = 5000;

export function* watchOnLocChangeGetHealthStatuses() {
	yield takeEvery(LOCATION_CHANGE, function* onNavigatePage() {
		yield put(getHealthStatusesAction({}));
	});
}

export function* watchGetHealthStatuses() {
	yield takeLatest(getHealthStatusesAction.TYPE, getHealthStatusesWorker);
}

function* getHealthStatusesWorker() {
	try {
		while(
			CLUSTERS_LIST_URL.match(yield select(store => store.router.location.pathname))!.isMatched
			|| CLUSTER_URL.match(yield select(store => store.router.location.pathname))!.isMatched
		) {
			const result: SagaReturnType<typeof getHealthStatusesApi> = yield call(getHealthStatusesApi);

			yield put(setHealthStatusesAction(result));

			yield delay(UPDATE_STATUSES_REQUEST_DELAY);
		}
	} catch (e) {
		console.log("+++++ getStatusesWorker e", e);
		console.log("______________________");
	}
}
