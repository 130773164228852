import React from 'react';
import { Button } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { PageHeader } from '@app/shared/pageHeader';
import { navigateTo } from '@app/utils/navigate-to';
import { SIGNIN_URL } from '../user-sign-in';

export const DisabledRegistration: React.FC<{}> = () => {
	return (
		<div className={styles.disabledReg}>
			<PageHeader
				btnComponent={
					<Button className="btn-one btn-login" onClick={navigateTo(SIGNIN_URL.urlTemplate)}>
						Log in
					</Button>
				}
				className={styles.disabledReg__header}
			/>
			<div className={styles.disabledReg__logo_mobile}>
				<div />
			</div>

			<div className={styles.disabledReg__messageBlock}>
				<div className={styles.disabledReg__messageBlock__logo}>
					<div />
				</div>

				<div className={styles.disabledReg__messageBlock__title}>
					Registration is disabled until further notice.
				</div>
				<div className={styles.disabledReg__messageBlock__subTitle}>
					Follow us on Twitter to get updates
					<a
						href={'https://twitter.com/appfleetcloud?lang=en'}
						target={'_blank'}
						rel="noreferrer"
						className={styles.disabledReg__messageBlock__subTitle__link}
					>
						{' @appfleetcloud'}
					</a>
				</div>
			</div>

			<div className={styles.disabledReg__backToLoginBtn} onClick={navigateTo(SIGNIN_URL.urlTemplate)}>
				Back to Login
			</div>
		</div>
	);
};
