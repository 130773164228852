import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { SvgIcon } from '../SvgIcon';
import { Button } from 'semantic-ui-react';

interface BtnWithIconProps {
	btnText: string;
	iconSrc: string;
	handleClick: () => void;
	btnWrapClassname?: string;
	iconPosition?: 'right' | 'left';
	disabled?: boolean;
}

export const BtnWithIcon: React.FC<BtnWithIconProps> = ({
	btnText,
	iconSrc,
	handleClick,
	btnWrapClassname = '',
	iconPosition = 'left',
	disabled = false,
}) => {
	return (
		<Button
			onClick={handleClick}
			className={classnames('btn-three', styles.btnWithIcon, { [btnWrapClassname]: !!btnWrapClassname })}
			disabled={disabled}
		>
			<div className={styles.btnWithIcon__content}>
				{iconPosition === 'left' ? (
					<SvgIcon iconSrc={iconSrc} className={styles.btnWithIcon__content__icon_left} />
				) : null}
				{btnText}
				{iconPosition === 'right' ? (
					<SvgIcon iconSrc={iconSrc} className={styles.btnWithIcon__content__icon_right} />
				) : null}
			</div>
		</Button>
	);
};
