import React, { ReactNode } from 'react';
import styles from './styles.module.scss';
import { navigateTo } from '../../utils/navigate-to';
import { SvgIcon } from '../SvgIcon';
import logo from '@icons/icon.appfleet.logo.svg';
import classnames from 'classnames';

interface PageHeaderProps {
	btnComponent?: ReactNode | null;
	className?: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ btnComponent = null, className }) => {
	return (
		<div className={classnames(styles.pageHeader, className)}>
			<div className={styles.logoMain} onClick={navigateTo('/')}>
				<SvgIcon iconSrc={logo} />
			</div>
			{btnComponent}
		</div>
	);
};
