import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

interface CustomModalProps {
	children: JSX.Element;
}

export const CustomModal: React.FC<CustomModalProps> = props => {
	const modalRoot: Element = document.querySelector('#modal')!;

	return ReactDOM.createPortal(
		<div className={styles.customModalWrap}>
			<div onClick={e => e.stopPropagation()} className={styles.customModal}>
				{props.children}
			</div>
		</div>,
		modalRoot
	);
};
