import { Action } from 'redux';
import { ClusterHealthStatus, NodeHealthStatus, setHealthStatusesAction, HealthStatuses } from '@app/data-modules/healthStatuses';

interface HealthStatusesState extends HealthStatuses {}

const INITIAL_STATE = {
	clusters: [] as ClusterHealthStatus[],
	nodes: [] as NodeHealthStatus[],
};

export function healthStatusesReducer(state = INITIAL_STATE, action: Action): HealthStatusesState {
	if (setHealthStatusesAction.is(action)) {
		return {
			...state,
      clusters: action.clusters,
      nodes: action.nodes,
		}
	}

	return state;
}
