import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { Button } from 'semantic-ui-react';
import { NoMembers } from '../noMembers';
import classnames from 'classnames';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import infoIcon from '@icons/icon.info.svg';
import { AppTable } from '@app/shared/AppTable';
import { MembersListRow } from '../membersListRow/MembersListRow';
import { TeamMember } from '../../state/interfaces';
import { EditTeamMemberRole } from '../editTeamMemberRole';
import { SearchInput } from '@app/shared/searchInput';
import { InviteMembersInput } from '../inviteMembersInput';
import { AppState } from '@app/reducers';
import { SortingCtrls } from '@app/shared/sortingCtrls';
import angleRightDark from '@icons/icon-12-arrow-right-dark.svg';
import { compareValues } from '@app/utils/compareValues';
import { SvgIcon } from '@app/shared/SvgIcon';
import { TeamStatsCard } from '@app/modules/team/components/team/cards/TeamStatsCard';
import { AppSpinner } from '@app/shared/AppSpinner';
import { useCheckerForAppReadonlyUser } from '@app/hooks';

export const Team = () => {
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const { membersList, inviteMembersError, accountSeatsAmount, isMembersListLoaded, loggedInUser } = useSelector(
		(state: AppState) => {
			return {
				isMembersListLoaded: state.team.isMembersListLoaded,
				membersList: state.team.membersList,
				inviteMembersError: state.team.inviteMembersError,
				accountSeatsAmount: state.team.accountSeats.totalSeatsAmount,
				loggedInUser: state.account.settings,
			};
		}
	);

	const [showEditRole, setShowEditRole] = React.useState(false);
	const [memberId, setMemberId] = React.useState<null | number>(null);
	const [searchValue, setSearchValue] = React.useState('');
	const [filteredMembersList, setFilteredMembersList] = React.useState(membersList);
	const [inviteMembersTable, setInviteMembersTable] = React.useState<any>(null);

	React.useEffect(() => {
		if (!searchValue) {
			setFilteredMembersList(membersList);
			return;
		}
		setFilteredMembersList(
			membersList.filter(
				(member: TeamMember) =>
					!!(
						String(member.firstName).toLowerCase().match(searchValue) ||
						String(member.lastName).toLowerCase().match(searchValue) ||
						String(member.email).toLowerCase().match(searchValue)
					)
			)
		);
	}, [searchValue, membersList]);

	React.useEffect(() => {
		const inviteMembersTable = document.getElementById('inviteMembersTable')! as HTMLDivElement;
		const inviteMembersTableInput = document.getElementById('inviteMembersInput')! as HTMLInputElement;
		setInviteMembersTable(inviteMembersTable);

		window.addEventListener('scroll', function () {
			if (isInViewport(inviteMembersTable)) {
				inviteMembersTableInput.focus();
			}
		});
	}, []);

	const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const sortByField = (key: string, order?: 'asc' | 'desc') => () => {
		const sortedMembersList = [...filteredMembersList].sort(compareValues({ key, order }));
		setFilteredMembersList(sortedMembersList);
	};

	return (
		<div className={styles.team}>
			<div className={classnames(styles.team__head)}>
				<div className={styles.team__head__header}>{'Team'}</div>
				<div className={styles.team__head__search}>
					<SearchInput
						className={styles.team__head__search__searchInput}
						placeholder={'Search team'}
						value={searchValue}
						searchName={'search'}
						handleChange={handleSearchInput}
						handleClear={() => setSearchValue('')}
					/>
				</div>
			</div>

			<div className={styles.team__utilityTables}>
				<TeamStatsCard
					membersList={membersList}
					accountSeatsAmount={accountSeatsAmount}
					loading={!isMembersListLoaded}
				/>

				{/* INVITE MEMBERS */}
				<div className={styles.team__utilityTables__inviteMembers} id="inviteMembersTable">
					<div className={styles.team__utilityTables__inviteMembers__head}>
						<span>Invite members</span>
						<IconWithTooltip
							imageSrc={infoIcon}
							tooltipText="Invite your co-workers by entering their emails below. Seperate multiple emails with a comma."
						/>
					</div>

					<div className={styles.team__utilityTables__inviteMembers__body}>
						<InviteMembersInput error={inviteMembersError} isReadonlyUser={isReadonlyUser} />

						<div className={styles.team__utilityTables__inviteMembers__body__subHeader}>
							{inviteMembersError.message
								? inviteMembersError.message
								: 'Invite your coworkers and collaborate together!'}
						</div>
					</div>
				</div>
			</div>

			{filteredMembersList.length || !isMembersListLoaded ? (
				<AppTable className={styles.team__table}>
					<AppTable.Head>
						<AppTable.Row>
							<AppTable.Col>
								<span>User</span>
								<SortingCtrls
									sortAsc={sortByField('lastName')}
									sortDesc={sortByField('lastName', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span>Role</span>
								<SortingCtrls
									sortAsc={sortByField('permission')}
									sortDesc={sortByField('permission', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span>Status</span>
								<SortingCtrls
									sortAsc={sortByField('status')}
									sortDesc={sortByField('status', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span>Invited</span>
								<SortingCtrls
									sortAsc={sortByField('createdAt')}
									sortDesc={sortByField('createdAt', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col />
						</AppTable.Row>
					</AppTable.Head>

					<AppTable.Body>
						{isMembersListLoaded ? (
							filteredMembersList.map((member: TeamMember) => (
								<MembersListRow
									member={member}
									// can't use ID as key since we can have pending members with no ID
									// and email should be unique among members
									key={member.email}
									handleContextMenuEdit={() => {
										setShowEditRole(true);
										setMemberId(member.id);
									}}
									loggedInUser={loggedInUser}
									isReadonlyUser={isReadonlyUser}
								/>
							))
						) : (
							<AppTable.Row>
								<AppTable.Col className={styles.team__table__loadingRow} colSpan={5}>
									<AppSpinner className={styles.team__table__loadingRow__icon} />
									<div className={styles.team__table__loadingRow__label}>Loading team members...</div>
								</AppTable.Col>
							</AppTable.Row>
						)}
					</AppTable.Body>

					<AppTable.Footer>
						<AppTable.Col>
							<Button
								className={classnames(
									'btn-three',
									styles.inviteMemberTableBtn,
									styles.team__btnWithSvg
								)}
								onClick={
									isReadonlyUser ? () => {} : () => handleScrollToInviteMembers(inviteMembersTable)
								}
								disabled={isReadonlyUser}
							>
								<span>{'Invite a member'}</span>
								<SvgIcon iconSrc={angleRightDark} className={styles.angleRightDark} />
							</Button>
						</AppTable.Col>
						<AppTable.Col>
							<div className={classnames('mock-label-one')} />
						</AppTable.Col>
						<AppTable.Col>
							<div className={classnames('mock-label-two')} />
						</AppTable.Col>
						<AppTable.Col>
							<div className={classnames('mock-label-three')} />
						</AppTable.Col>
						<AppTable.Col />
					</AppTable.Footer>
				</AppTable>
			) : (
				<NoMembers />
			)}

			{showEditRole ? (
				<EditTeamMemberRole
					member={filteredMembersList.filter(member => member.id === memberId)[0]}
					onDismiss={() => setShowEditRole(false)}
				/>
			) : null}
		</div>
	);
};

const isInViewport = (elem: any): boolean => {
	const bounding = elem.getBoundingClientRect();
	return (
		bounding.top >= 0 &&
		bounding.left >= 0 &&
		bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

const handleScrollToInviteMembers = (elem: HTMLDivElement) => {
	if (isInViewport(elem)) {
		const inviteMembersTableInput = elem.querySelector('#inviteMembersInput')! as HTMLInputElement;
		inviteMembersTableInput.focus();
	} else {
		elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	}
};
