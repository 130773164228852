import { RegionStepNode } from './RegionsStep';

export const regionsProcessor = (data: { nodes: Record<string, RegionStepNode> }) => {
	const regions: { region: string }[] = [];
	Object.values(data.nodes).forEach(node => {
		const { amount, region } = node;

		for (let i = 0; i < amount; i++) {
			regions.push({ region: region.code });
		}
	});
	return { nodes: regions };
};
