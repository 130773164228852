import { Terminal, ITerminalOptions } from 'xterm';

const DEFAULT_TERMINAL_OPTIONS: ITerminalOptions = {
	theme: {
		foreground: '#000000',
		background: '#FFFFFF',
		cursor: '#000000',
		selection: 'rgba(0, 0, 0, 0.5)',
	},
	fontWeight: '400',
	cursorBlink: false,
	fontFamily: 'Menlo, Monaco, Consolas, Courier New, monospace',
	fontSize: 12,
};

export const createTerminal = (options?: ITerminalOptions) => new Terminal({ ...DEFAULT_TERMINAL_OPTIONS, ...options });
