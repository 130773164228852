import * as React from 'react';
import { NodeOptionInputProps } from '../../interface';
import { NodeOptionItem } from '../../NodeOptionItem';

import icon from '@icons/icon.remove.option.svg';

type Props = NodeOptionInputProps;

export const RemoveNodeOption = (props: Props) => {
	const { setShowRemoveMsg } = props;

	return (
		<NodeOptionItem
			iconSrc={icon}
			title={`Remove node`}
			onClick={() => {
				setShowRemoveMsg(true);
			}}
			dangerAction={true}
		/>
	);
};
