import { call, take, put, delay } from 'redux-saga/effects';
import { sendBillingInfoApi } from '../api';
import { sendBillingInfo, setBillingInfoError } from '../actions';
import { BillingInfoFormValues, BillingInfoFormStripeValues } from '../interfaces';
import { getAccountBillingInfoForWizard } from '@app/modules/account/state/actions';

export function* watchSendBillingInfo() {
	while (true) {
		const action = yield take('*');
		if (sendBillingInfo.is(action)) {
			yield call(sendBillingInfoWorker, action);
		}
	}
}

function* sendBillingInfoWorker({
	formValues,
	onSuccess,
	onFinal,
}: {
	formValues: BillingInfoFormValues;
	onSuccess: () => void;
	onFinal: () => void;
}) {
	try {
		const preparedValues: BillingInfoFormStripeValues = {
			name: `${formValues.firstName} ${formValues.lastName}${
				formValues.company ? ` (${formValues.company})` : ''
			}`,
			addressLine1: formValues.address,
			city: formValues.city,
			postalCode: formValues.postalCode,
			country: formValues.country,
			vatId: formValues.vatId || undefined,
		};
		yield call(sendBillingInfoApi, preparedValues);
		onFinal();
		onSuccess();
		// since billing info is not updated momentally then we should delay a little
		// to wait until billing info already updated
		// temp solution
		// while testing 1000 is enough for stripe to respond
		yield delay(1000);
		yield put(getAccountBillingInfoForWizard({}));
	} catch (e) {
		yield put(setBillingInfoError({ error: { message: e.message } }));
		onFinal();
	}
}
