import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface TooltipProps {
	tooltipText: string;
	shouldDisplaed: boolean;
	className?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ tooltipText, shouldDisplaed, className = '' }) => {
	return shouldDisplaed ? (
		<div className={classnames(styles.tooltip, { [className]: !!className })}>{tooltipText}</div>
	) : null;
};
