import * as React from "react";
import { Injectable } from "../entities/Injectable";
import { InjectorObserver } from "../observers/InjectorObserver";

export function createInjectorRegisterer(observer: InjectorObserver) {
  return function (Component: React.ComponentType<any>) {
    const updateFn = (state: boolean) => {
      element.updateRender(state);
      observer.update(element);
    };

    const binderBag = {
      inject: () => updateFn(true),
      eject: () => updateFn(false),
    };

    const element = new Injectable(
      () => <Component binder={binderBag} />,
      false
    );
    observer.register(element);

    return binderBag;
  };
}
