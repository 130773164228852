export const getFlagForNode = (region: string) => {
	switch (region.toLowerCase()) {
		case 'nyc':
		case 'sfo':
			return 'US';
		case 'sin':
			return 'SG';
		case 'ams':
			return 'NL';
		case 'blr':
			return 'IE';
		case 'eu':
			return 'EU';
		case 'lon':
			return 'GB';
		default:
			return '';
	}
};
