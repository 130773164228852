import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './AppRequestButton.module.scss';
import { SvgIcon } from '../SvgIcon';
import successIcon from '@icons/icon-16-check-circle.svg';
import loadingIcon from '@icons/deploying-status-icon.svg';

export type BtnStatus = 'initial' | 'loading' | 'success' | 'error' | 'dirty';
export type BtnStatusText = {
	initial: string;
	loading: string;
	success: string;
	dirty?: string;
	error?: string;
};

interface Props {
	status: BtnStatus;
	statusText: BtnStatusText;
	onClick: (e: any) => void;
	onResetState?: () => void;
	handleDirtyBtn?: (_: any) => void;
	isDirty?: boolean;
	className?: string;
	disabled?: boolean;
}

export const AppRequestButton: React.FC<Props> = ({
	className,
	status,
	statusText,
	disabled,
	onClick,
	onResetState,
	isDirty = false,
	handleDirtyBtn = () => {},
}: Props) => {
	const START_TIMER_TICK_COUNTER_AT = 0;
	const END_TIMER_TICK_COUNTER_AT = 200;
	const TIMER_TICK_COUNTER_STEP = 2;
	const [timerTickCounter, setTimerTickCounter] = useState(START_TIMER_TICK_COUNTER_AT);
	const [visibleProgress, setVisibleProgress] = useState(false);

	useEffect(() => {
		if (status === 'initial' || status === 'error') {
			setTimerTickCounter(START_TIMER_TICK_COUNTER_AT);
			setVisibleProgress(false);

			return;
		}

		if (status === 'loading' && timerTickCounter === START_TIMER_TICK_COUNTER_AT) {
			setVisibleProgress(true);
		}

		if ((status === 'loading' || status === 'success') && timerTickCounter < END_TIMER_TICK_COUNTER_AT) {
			setTimeout(() => {
				setTimerTickCounter(timerTickCounter + TIMER_TICK_COUNTER_STEP);
			}, 100);
		}

		if (status === 'success' && timerTickCounter === END_TIMER_TICK_COUNTER_AT) {
			setVisibleProgress(false);

			setTimeout(() => {
				onResetState && onResetState();
			}, 4000);
		}
	}, [status, timerTickCounter, onResetState]);

	useEffect(() => {
		return () => {
			onResetState && onResetState();
		};
	}, [onResetState]);

	const isDisabled = disabled || status === 'loading' || status === 'success';

	return (
		<div className={styles.appRequestButtonWrapper}>
			{isDirty && status === 'dirty' ? (
				<div className={styles.appRequestButtonWrapper__clearBtn} onClick={handleDirtyBtn}>
					{'Clear'}
				</div>
			) : null}
			<div
				className={classnames(
					styles.appRequestButton,
					{ [styles.appRequestButton_disabled]: isDisabled },
					{ [styles.appRequestButton_active]: status === 'loading' || status === 'success' },
					{ [styles.appRequestButton_dirty]: status === 'dirty' && isDirty },
					className
				)}
				onClick={e => !isDisabled && onClick(e)}
			>
				<div className={styles.appRequestButton__textNIcon}>
					<div className={styles.appRequestButton__textNIcon__textBlock}>
						<div
							className={classnames(styles.appRequestButton__textNIcon__textBlock__btnText, {
								[styles.appRequestButton__textNIcon__textBlock__btnText_visible]:
									status === 'initial' || status === 'dirty' || status === 'error',
							})}
						>
							{isDirty ? statusText['dirty'] : statusText['initial']}
						</div>

						<div
							className={classnames(styles.appRequestButton__textNIcon__textBlock__btnText, {
								[styles.appRequestButton__textNIcon__textBlock__btnText_visible]:
									(status === 'loading' || status === 'success') &&
									timerTickCounter < END_TIMER_TICK_COUNTER_AT,
							})}
						>
							{statusText['loading']}
						</div>

						<div
							className={classnames(styles.appRequestButton__textNIcon__textBlock__btnText, {
								[styles.appRequestButton__textNIcon__textBlock__btnText_visible]:
									status === 'success' && timerTickCounter === END_TIMER_TICK_COUNTER_AT,
							})}
						>
							{statusText['success']}
						</div>
					</div>

					<div
						className={classnames(styles.appRequestButton__textNIcon__btnIconBlock, {
							[styles.appRequestButton__textNIcon__btnIconBlock_visible]:
								status === 'loading' || status === 'success',
						})}
					>
						{(status === 'loading' || status === 'success') &&
							timerTickCounter < END_TIMER_TICK_COUNTER_AT && (
								<SvgIcon
									className={classnames(
										styles.appRequestButton__textNIcon__btnIconBlock__btnIcon,
										styles.appRequestButton__textNIcon__btnIconBlock__btnIcon_loading
									)}
									iconSrc={loadingIcon}
								/>
							)}
						{status === 'success' && timerTickCounter === END_TIMER_TICK_COUNTER_AT && (
							<SvgIcon
								className={classnames(styles.appRequestButton__textNIcon__btnIconBlock__btnIcon)}
								iconSrc={successIcon}
							/>
						)}
					</div>
				</div>

				{visibleProgress ? (
					<div className={styles.appRequestButton__loadingBlock}>
						<div
							style={{ width: `${timerTickCounter / TIMER_TICK_COUNTER_STEP}%` }}
							className={styles.appRequestButton__loadingBlock__loadingProgressBar}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};
