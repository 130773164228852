import { call, put, take } from 'redux-saga/effects';
import { signinUserApi } from '../api';
import { SigninValues } from '../../components';
import { signinUser, setSigninToken, setSigninError } from '../actions';
import { CLUSTERS_LIST_URL } from '../../../clusters-list';
import { navigateTo } from '@app/utils/navigate-to';
import { getAccountSettingsUserInfo } from '@app/modules/account';

export function* watchSigninUser() {
	while (true) {
		const action = yield take('*');
		if (signinUser.is(action)) {
			yield call(signinWorker, {
				email: action.email,
				password: action.password,
			});
		}
	}
}

function* signinWorker(formValues: SigninValues) {
	try {
		const token = yield call(signinUserApi, formValues);
		yield put(setSigninToken({ token }));
		yield put(getAccountSettingsUserInfo({}));
		yield navigateTo(CLUSTERS_LIST_URL.urlTemplate)();
	} catch (e) {
		yield put(setSigninError({ error: { message: e.message } }));
	}
}
