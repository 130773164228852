import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { BillinginfoBlockLayout } from '../billingInfoBlockLayout';
import { InputWithInnerButton } from '@app/shared/inputs/InputWithInnerButton';
import { IErrorMessage } from '@app/shared/errorMessage/ErrorMessage';
import { SvgIcon } from '@app/shared/SvgIcon';
import leftElem from '@images/promo.code.elem.left.svg';
import rightElem from '@images/promo.code.elem.right.svg';
import errorIcon from '@icons/icon.30.error.cross.svg';
import successIcon from '@icons/icon.30.success.svg';
import { AccounBillingCoupon, AccountBillingCouponType } from '../../../../state/interfaces';
import { useCheckerForAppReadonlyUser, useCheckerIsBiller } from '@app/hooks';

export interface BillingCouponState {
	coupon: AccounBillingCoupon;
	request: {
		started: boolean;
		finished: boolean;
	};
	error: IErrorMessage;
}

interface BillingPromoInfoProps {
	redeemCoupon: ({ code }: { code: string }) => void;
	billingCoupon: BillingCouponState;
}

export const BillingPromoInfo: React.FC<BillingPromoInfoProps> = ({
	redeemCoupon,
	billingCoupon: {
		coupon,
		request: { started: reqStarted, finished: reqFinished },
		error: { message: errorMessage },
	},
}) => {
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const { isBiller } = useCheckerIsBiller();
	const [promoCode, setPromoCode] = useState<string>('');
	const [promoHeader, setPromoHeader] = useState<string>('Add your promo code if you have one');
	const [promoMessage, setPromoMessage] = useState<string>(
		'Every promo code you add will be automatically applied to your next invoices until it expires.'
	);

	useEffect(() => {
		if (errorMessage) {
			setPromoHeader('Something went wrong');
			setPromoMessage('We couldn’t add your promo code. It could be wrong or expired.');
		} else if (!errorMessage && reqFinished) {
			let promoHeader = '';
			if (coupon.type === AccountBillingCouponType.PERCENT) {
				promoHeader = `${coupon.description} discount was applied to your account`;
			} else {
				promoHeader = `${coupon.description} was added to your balance`;
			}
			setPromoHeader(promoHeader.replace(/\+/g, ''));
			setPromoMessage('Enjoy using appfleet! You can also add another code');
		}
	}, [errorMessage, reqFinished, coupon]);

	return (
		<BillinginfoBlockLayout title="Promo">
			<div className={styles.billingPromoInfo}>
				<div className={styles.billingPromoInfo__promoInputBlock}>
					<InputWithInnerButton
						id="promoCode"
						className={styles.billingPromoInfo__promoInput}
						name="promoCode"
						placeholder="Promo code"
						value={promoCode}
						onChange={e => setPromoCode(e.target.value || '')}
						onBlur={() => null}
						btnOnClick={() => redeemCoupon({ code: promoCode })}
						btnText="Apply promo code"
						btnDisabled={(!promoCode && !reqStarted) || (isReadonlyUser && !isBiller)}
						disabled={(reqStarted && !reqFinished) || (isReadonlyUser && !isBiller)}
					/>
				</div>

				<div className={styles.billingPromoInfo__contentBlock}>
					<SvgIcon iconSrc={leftElem} className={styles.billingPromoInfo__bgElemLeft} />
					<SvgIcon iconSrc={rightElem} className={styles.billingPromoInfo__bgElemRight} />

					<div className={styles.billingPromoInfo__otpBlock}>
						<div className={styles.billingPromoInfo__otpBlock__otpHeader}>
							<div className={styles.billingPromoInfo__otpBlock__otpHeader__otpHeaderPolygon} />

							{reqFinished && !errorMessage ? (
								<div className={styles.billingPromoInfo__otpBlock__otpHeader__otpHeaderRight}>
									<div />
								</div>
							) : null}
						</div>
						<div className={styles.billingPromoInfo__otpBlock__otpContnet}>
							{coupon.description ? (
								<div className={styles.billingPromoInfo__otpBlock__otpContnet__couponCredit}>
									{coupon.description}
								</div>
							) : (
								<>
									<div
										className={styles.billingPromoInfo__otpBlock__otpContnet__otpContentEmptyBlock}
									/>
									<div className={styles.billingPromoInfo__otpBlock__otpContnet__otpContentSymbol}>
										D
									</div>
									<div className={styles.billingPromoInfo__otpBlock__otpContnet__otpContentSymbol}>
										3
									</div>
									<div className={styles.billingPromoInfo__otpBlock__otpContnet__otpContentSymbol}>
										X
									</div>
								</>
							)}
						</div>

						{reqFinished ? (
							<SvgIcon
								iconSrc={errorMessage ? errorIcon : successIcon}
								className={styles.billingPromoInfo__otpBlock__requestIcon}
							/>
						) : null}
					</div>

					<div className={styles.billingPromoInfo__textBlock}>
						<div className={styles.billingPromoInfo__textBlock__title}>{promoHeader}</div>
						<div className={styles.billingPromoInfo__textBlock__message}>{promoMessage}</div>
					</div>
				</div>
			</div>
		</BillinginfoBlockLayout>
	);
};
