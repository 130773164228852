import React from 'react';
import { connect } from 'react-redux';
import { withFormik, FormikProps, Form } from 'formik';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';
import { LabeledInputRegular } from '@app/shared/labeledInputRegular';
import styles from '../../styles.module.scss';
import { AppState } from '@app/reducers';
import { addDomain, clearDomainsError } from '../../../../state';
import angleDownCircle from '@icons/icon.circle.angle.down.svg';
import ValidationSchema from './validation-schema';
import { SvgIcon } from '@app/shared/SvgIcon';
import iconAddDomain from '@icons/icon.add.domain.svg';
import iconClose from '@icons/icon.close.svg';
import { MetaError } from '@app/types/metaError';

export interface AddDomainValues {
	customDomain: string;
}

interface AddDomainComponentProps {
	domainsError?: MetaError;
	clusterName: string;
	onDismiss: () => void;
	addDomain: ({
		values,
		clusterName,
		handleSuccess,
		handleFinally,
	}: {
		values: AddDomainValues;
		clusterName: string;
		handleSuccess: () => void;
		handleFinally: () => void;
	}) => void;
	dnsTargetName: string;
	clearDomainsError: (_: any) => void;
}

const AddDomainComponent: React.FC<AddDomainComponentProps & FormikProps<AddDomainValues>> = props => {
	const {
		values,
		handleChange,
		handleSubmit,
		handleBlur,
		isSubmitting,
		onDismiss,
		touched,
		errors,
		isValid,
		domainsError,
		dnsTargetName,
		clearDomainsError,
		setFieldValue,
	} = props;
	const { customDomain } = values;
	const [isSubmited, setIsSubmited] = React.useState(false);

	React.useEffect(() => {
		return () => {
			clearDomainsError({});
		};
	}, [clearDomainsError]);

	return (
		<div>
			<div className={styles.addDomain}>
				<SvgIcon iconSrc={iconAddDomain} className={styles.addDomain__icon} />
				<SvgIcon iconSrc={iconClose} className={styles.addDomain__close} onClick={onDismiss} />

				<div className={styles.addDomain__header}>{'Add a custom domain'}</div>

				<div className={styles.addDomain__subHeader}>
					{`Before adding a new custom domain you must first create a DNS record pointing to ${dnsTargetName}`}
				</div>

				{(isSubmited && domainsError?.get('name')) || domainsError?.message ? (
					<div className={styles.addDomain__errMsg}>{domainsError?.get('name') || domainsError?.message}</div>
				) : (
					<div className={styles.addDomain__msg}>
						{'Use a CNAME or ANAME record. A free SSL certificate will be installed automatically.'}
					</div>
				)}

				<Form onSubmit={handleSubmit} autoComplete={'off'}>
					<LabeledInputRegular
						autoComplete={'off'}
						handleBlur={handleBlur}
						handleChange={handleChange}
						inputValue={customDomain}
						inputName="customDomain"
						labelText="Custom Domain"
						inputPlaceholder="www.example.com"
						className={styles.regularInput}
						errorMsg={errors.customDomain}
						isTouched={touched.customDomain}
						showErrLabel={true}
						showErrStyles={true}
						validateOnBlur={true}
						errorLabelClassName={styles.addDomain__errorLabel}
						setFieldValue={setFieldValue}
					/>

					<div className={styles.addDomain__dropDown}>
						<SvgIcon iconSrc={angleDownCircle} />
					</div>

					<div className={styles.addDomain__cnameTarget}>
						<div className={styles.addDomain__cnameTarget__label}>{'DNS Target'}</div>
						<div className={styles.addDomain__cnameTarget__value}>{dnsTargetName}</div>
					</div>

					<Button
						type="submit"
						disabled={isSubmitting || !isValid}
						className={classNames('btn', 'btn-one', styles.addDomain__saveBtn)}
						onClick={() => setIsSubmited(true)}
					>
						{'Add this domain'}
					</Button>
				</Form>
			</div>
		</div>
	);
};

export const AddDomain = connect(
	(state: AppState) => ({
		domainsError: state.cluster.domainsError,
		clusterName: state.cluster.cluster.name,
	}),
	{ addDomain, clearDomainsError }
)(
	withFormik<AddDomainComponentProps, AddDomainValues>({
		mapPropsToValues: () => {
			return {
				customDomain: '',
			};
		},
		validationSchema: ValidationSchema,

		handleSubmit: (values, { setSubmitting, props }) => {
			setSubmitting(true);
			props.addDomain({
				values,
				clusterName: props.clusterName,
				handleSuccess: () => props.onDismiss(),
				handleFinally: () => setSubmitting(false),
			});
		},

		displayName: 'AddDomain',
	})(AddDomainComponent)
);
