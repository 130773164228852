import * as React from "react";
import styles from "../style.module.scss";
import classnames from "classnames";

interface Props {
    children: JSX.Element;
    className?: string;
    type: "label" | "input";
    subContent?: JSX.Element;
}

export const Col = (props: Props) => {
    const { type, className, children, subContent } = props;
    const isLabel = type === "label";
    const isInput = type === "input";
    return (
        <div className={classnames(styles.col, { [styles.labelCol]: isLabel, [styles.inputCol]: isInput }, className)}>
            <div className={styles.contentWrapper}>{children}</div>
            <div className={styles.subContentPlaceholder}>{subContent}</div>
        </div>
    );
};
