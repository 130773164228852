import * as React from 'react';
import styles from './style.module.scss';
import globalStyles from '@app/styles/global.module.scss';
import classnames from 'classnames';

interface State {
	value: number;
}

interface Props {
	onChange: (value: number) => void;
	value?: number;
	className?: string;
	max?: number;
	init?: number;
	overLimit?: boolean;
}

export class DeployWizardIncrementInput extends React.Component<Props, State> {
	ref = React.createRef<HTMLInputElement>();

	state = {
		value: this.props.value || 0,
	};

	update = (value: number) => {
		const { max } = this.props;
		value = +value;
		if (max !== undefined && value > max) return;
		if (Number.isNaN(value) && String(value).length) return;
		if (!Number.isNaN(value) && value >= 0) {
			this.setState({ value }, () => this.props.onChange(+this.state.value));
		}
	};

	componentDidUpdate(prevProps: Props) {
		const { value } = this.props;
		if (prevProps.value !== value) {
			this.setState({ value: value || 0 });
		}
	}

	render() {
		const { init = 0, className } = this.props;
		const { value } = this.state;
		return (
			<div
				className={classnames(
					globalStyles.noTextSelection,
					styles.wrapper,
					{ [styles.wrapper_activeInput]: +value },
					className
				)}
			>
				<div className={styles.sideBtn} onClick={() => this.update(value - 1)} />
				<input
					type="text"
					ref={this.ref}
					onChange={(e: any) => this.update(e.target.value)}
					value={value + init}
				/>
				<div
					className={classnames(styles.sideBtn, styles.sideBtn_plus)}
					onClick={() => this.update(value + 1)}
				/>
			</div>
		);
	}
}
