import React from 'react';
import styles from './styles.module.scss';
import noMembers from '@images/team.no.members.png';

export const NoMembers = () => {
	return (
		<div className={styles.noMembers}>
			<img src={noMembers} alt="no-members" />
			<div className={styles.noMembers__header}>{'There are no members'}</div>
			<div className={styles.noMembers__subHeader}>{'Invite a new member in the window above to start'}</div>
		</div>
	);
};
