import * as React from 'react';
import styles from './style.module.scss';
import { DeployWizardStepProps } from '../../interfaces';
import { StepLayoutWithLogo } from '../../components/layouts/DefaultStepLayout/StepLayoutWithLogo';
import { EnvironmentForm } from '@app/modules/deploy-wizard/components/forms';
import envImg from '@images/env-variables.png';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { environmentStepMeta } from './meta';

export class EnvironmentStep extends React.Component<DeployWizardStepProps> {
	render() {
		const { getPrevStep, getNextStep, next, prev, step, value } = this.props;

		const prevStep = getPrevStep();
		const nextStep = getNextStep();

		return (
			<StepLayoutWithLogo
				image={envImg}
				title={environmentStepMeta.title}
				subtitle={environmentStepMeta.subtitle}
				imgClassName={styles.mainImg}
				controls={
					<div className={styles.controls}>
						{prevStep && (
							<StepNavigationButton type="prev" onClick={prev} stepName={prevStep.meta.btnText} />
						)}
						{nextStep && (
							<StepNavigationButton stepName={nextStep.meta.btnText} type="next" formId={step.name} />
						)}
					</div>
				}
			>
				<div className={styles.wrapper}>
					<div className={styles.content}>
						<EnvironmentForm
							onSubmit={values => next(step.name, { env: values })}
							initialValues={value}
							formId={step.name}
						/>
					</div>
				</div>
			</StepLayoutWithLogo>
		);
	}
}
