import { call, takeEvery, put } from 'redux-saga/effects';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { VERIFY_EMAIL_URL } from '../url';
import { verifyEmailApi } from '../api';
import { setSigninError } from '@app/modules/user-sign-in';
import { SIGNIN_URL } from '@app/modules/user-sign-in';
import { navigateTo } from '@app/utils/navigate-to';

export function* watchVerifyEmailChange() {
	yield takeEvery(LOCATION_CHANGE, function* onNavigateVerifyEmailPage(a: LocationChangeAction) {
		const urlMatch = VERIFY_EMAIL_URL.match(a.payload.location, true);
		if (!urlMatch.isMatched) {
			return;
		}
		const token = (urlMatch.params as { token: string }).token;

		yield call(verifyEmailWorker, token);
	});
}

function* verifyEmailWorker(token: string) {
	try {
		yield call(verifyEmailApi, token);
	} catch (e) {
		yield put(setSigninError({ error: e }));
	} finally {
		yield navigateTo(SIGNIN_URL.urlTemplate)();
	}
}
