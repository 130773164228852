import { take, call } from 'redux-saga/effects';
import { getSizesAction } from '../actions';
import { getSizesSaga } from './getSizesSaga';

export function* watcherSaga() {
	while (true) {
		const action = yield take('*');
		if (getSizesAction.is(action)) {
			yield call(getSizesSaga);
		}
	}
}
