import { Action } from 'redux';
import { IntercomNotificationState } from './state';
import { setIntercomFailed, showIntercomNotification } from './actions';

const INITIAL_STATE: IntercomNotificationState = {
	failedToLoad: null,
	showNotification: false,
};

export function intercomNotificationReducer(
	state: IntercomNotificationState = INITIAL_STATE,
	action: Action
): IntercomNotificationState {
	if (setIntercomFailed.is(action)) {
		return {
			...state,
			failedToLoad: action.failedToLoad,
		};
	}

	if (showIntercomNotification.is(action)) {
		return {
			...state,
			showNotification: action.showNotification,
		};
	}

	return state;
}
