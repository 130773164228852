export function getNestedProperty(obj: any, next: string, ...nested: string[]): any {
	if (obj === undefined) {
		return false;
	}

	if (nested.length === 0 && obj.hasOwnProperty(next)) {
		return obj[next];
	}

	obj = obj[next];
	next = nested[0];
	nested = nested.length === 1 ? [] : nested.splice(0, 1);

	return getNestedProperty(obj, next, ...nested);
}