import React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';
import warningIcon from '@icons/icon.oval.orange.info.svg';
import arrowRightIcon from '@icons/arrow.right.icon.svg';
import { AppButton } from '@app/shared/buttons/AppButton';
import { changeBillingInformationWizardModalApi } from '@app/modals';
import { SvgIcon } from '@app/shared/SvgIcon';

interface AddBillingInfoBannerProps {
	className?: string;
}

export const AddBillingInfoBanner: React.FC<AddBillingInfoBannerProps> = ({ className = '' }) => {
	return (
		<div className={classnames(styles.addBillingInfoBanner, { [className]: !!className })}>
			<div className={styles.addBillingInfoBanner__textBlock}>
				<div className={styles.addBillingInfoBanner__textImgBlock}>
					<SvgIcon iconSrc={warningIcon} />
				</div>
				<div>
					<h3 className={styles.addBillingInfoBanner__textHeader}>{'You are almost production ready!'}</h3>
					<p className={styles.addBillingInfoBanner__textParagraph}>
						{
							'Please fill out your billing information and add a payment method or your cluster might shut down after using up all free credits'
						}
					</p>
				</div>
			</div>
			<div className={styles.addBillingInfoBanner__btnBlock}>
				<AppButton
					theme={AppButton.themes.regular}
					type={'button'}
					onClick={changeBillingInformationWizardModalApi.openModal}
					className={styles.addBillingInfoBanner__btn}
				>
					Finish account setup
					<SvgIcon iconSrc={arrowRightIcon} className={styles.addBillingInfoBanner__btnIcon} />
				</AppButton>
			</div>
		</div>
	);
};
