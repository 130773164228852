import React from 'react';
import io from 'socket.io-client';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import '../../../../../node_modules/xterm/css/xterm.css';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { createTerminal } from '../../../../utils/createTerminal';
import api from '@app/apis/api';

interface ClusterLogsTabCombinedProps {
	id: string;
	className: string;
	initTab: boolean;
	nodes: { hostname: string }[];
}

export const ClusterLogsTabCombined: React.FC<ClusterLogsTabCombinedProps> = ({ id, className, initTab, nodes }) => {
	const [terminal, setTerminal] = React.useState<Terminal | null>(null);
	const [sockets, setSockets] = React.useState<SocketIOClient.Socket[] | []>([]);
	let [connectionStartedCnt, setConnectionStartedCnt] = React.useState<number>(0);
	const token = api.getToken();

	// handle output data from socket to terminal
	React.useEffect(() => {
		if (terminal && sockets.length) {
			sockets.forEach(socket =>
				socket.on('appfleet.api.v1.connect.Output', (data: { data: string; sessionId: string }) => {
					terminal.write(data.data);
				})
			);
		}
	}, [sockets, terminal]);

	// open socket for HostName
	React.useEffect(() => {
		const hosts = nodes.map(nodeRegion => nodeRegion.hostname);
		if (initTab && terminal && !sockets.length) {
			const socketBaseAddr = (
				process.env.REACT_APP_API_URL || 'wss://api-appfleet-com-staging.herokuapp.com'
			).replace('https://', 'wss://');

			terminal.write('Connecting...');
			const sockets = hosts.map((hostname, hostIdx) => {
				const socket = io(`${socketBaseAddr}/api/v1/ws/node/${hostname}/logs`, {
					transports: ['websocket'],
					query: { token },
				});
				socket.emit('appfleet.api.v1.connect.SessionStart', {});
				socket.on('connect', () => {
					setConnectionStartedCnt(++connectionStartedCnt);
				});
				socket.on('appfleet.api.v1.connect.SessionStarted', () => {});
				return socket;
			});

			setSockets(sockets);
		}
	}, [sockets, setSockets, nodes, initTab, token, terminal, connectionStartedCnt]);

	// create terminal instance on did mount
	React.useEffect(() => {
		if (id && initTab && !terminal) {
			const terminal = createTerminal();
			const fitAddon = new FitAddon();
			terminal.loadAddon(fitAddon);
			let terminalElem = document.getElementById(`${id}-container`)!;
			terminal.open(terminalElem);
			fitAddon.fit();
			setTerminal(terminal);
		}
	}, [id, initTab, terminal]);

	// check if all sessions of sockets is started and make output
	React.useEffect(() => {
		if (terminal && sockets.length && connectionStartedCnt && connectionStartedCnt === sockets.length) {
			terminal.write(' Success. New logs will appear below\r\n');
		}
	}, [connectionStartedCnt, sockets, terminal]);

	// close sockets on dismount
	React.useEffect(() => {
		return () => {
			if (sockets.length) {
				sockets.forEach(socket => {
					socket.close();
				});
			}
		};
	}, [sockets]);

	return (
		<div id={`${id}-container`} className={classnames(styles.clusterLogs__tab, { [className]: !!className })}></div>
	);
};
