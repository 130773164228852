import { put } from 'redux-saga/effects';
import { getSizesRequest } from '../requests';
import { setSizesAction } from '../actions';
import { ApiSize } from '../entity';

export function* getSizesSaga() {
	const result = yield getSizesRequest();
	const sizes: ApiSize[] = result.map((item: ApiSize) => {
		const { price, monthlyPrice, ...rest } = item
		return { price: Number(price), monthlyPrice: Number(monthlyPrice) , ...rest };
	});
	yield put(setSizesAction({ payload: sizes }));
}
