import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import classnames from 'classnames';
import styles from '../styles.module.scss';
import { AppState } from '@app/reducers';
import { ClusterSettingsNoUsers } from './ClusterSettingsNoUsers';
import { PermissionsTableItem } from './PermissionsTableItem';
import { AddPermissions } from './addPermissions';
import { SearchInput } from '@app/shared/searchInput';
import { SvgIcon } from '@app/shared/SvgIcon';
import { compareValues } from '@app/utils/compareValues';
import { CustomModal } from '@app/shared/customModal/CustomModal';
import { AppTable } from '@app/shared/AppTable';
import { SortingCtrls } from '@app/shared/sortingCtrls/SortingCtrls';
import angleRightDark from '@icons/icon-12-arrow-right-dark.svg';

export enum PermissionsRole {
	OWNER = 'owner',
	BILLER = 'biller',
	MEMBER = 'member',
}

interface Props {
	isReadonlyCluster: boolean;
}

export const ClusterSettingsPermissions: React.FC<Props> = ({ isReadonlyCluster }) => {
	const { clusterMembers, cluster, loggedInUser } = useSelector((state: AppState) => ({
		clusterMembers: state.cluster.clusterMembers,
		cluster: state.cluster.cluster,
		loggedInUser: state.account.settings,
	}));
	const [filter, setFilter] = useState('');
	const [filterdMembers, setFilteredMembers] = useState(clusterMembers);
	const [showAddPermissions, setShowAddPermissions] = useState(false);
	const [isClusterOwner, setIsClusterOwner] = useState(false);

	useEffect(() => {
		if (loggedInUser.permission === PermissionsRole.OWNER) {
			setIsClusterOwner(true);
		} else {
			setIsClusterOwner(false);
		}
	}, [loggedInUser]);

	useEffect(() => {
		if (filter) {
			const filteredMembers = clusterMembers.filter(
				user => user.firstName.toLowerCase().includes(filter) || user.lastName.toLowerCase().includes(filter)
			);
			setFilteredMembers(filteredMembers);
		} else {
			setFilteredMembers(clusterMembers);
		}
	}, [filter, clusterMembers]);

	const sortByField = (key: string, order?: 'asc' | 'desc') => () => {
		const sortedList = [...filterdMembers].sort(compareValues({ key, order }));
		setFilteredMembers(sortedList);
	};

	return (
		<div id="permissions" className={styles.clusterSettings__content_wrapped__body__permissions}>
			<div className={styles.clusterSettings__content_wrapped__body__blockHead}>
				<span>{'Permissions'}</span>
				<SearchInput
					placeholder={'Search team members'}
					searchName={'search'}
					handleChange={e => setFilter((e.target as any).value)}
					value={filter}
					handleClear={() => setFilter('')}
				/>
			</div>

			{filterdMembers.length ? (
				<AppTable>
					<AppTable.Head>
						<AppTable.Row>
							<AppTable.Col>
								<span>User</span>
								<SortingCtrls
									sortAsc={sortByField('lastName')}
									sortDesc={sortByField('lastName', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span>Role</span>
								<SortingCtrls
									sortAsc={sortByField('permission')}
									sortDesc={sortByField('permission', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span>Status</span>
								<SortingCtrls
									sortAsc={sortByField('active')}
									sortDesc={sortByField('active', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span />
							</AppTable.Col>
						</AppTable.Row>
					</AppTable.Head>

					<AppTable.Body>
						{filterdMembers.map(user => (
							<PermissionsTableItem
								key={user.id}
								user={user}
								clusterId={cluster.id}
								clusterName={cluster.name}
								loggedInUser={loggedInUser}
								isReadonlyCluster={isReadonlyCluster}
							/>
						))}
					</AppTable.Body>

					<AppTable.Footer>
						<AppTable.Row className={styles.clusterSettings__footerRow}>
							<AppTable.Col>
								<Button
									className={classnames('btn-three', styles.clusterSettings__addPeopleBtn)}
									onClick={!isClusterOwner ? () => {} : () => setShowAddPermissions(true)}
									disabled={!isClusterOwner}
								>
									<span>Add people to cluster</span>
									<SvgIcon iconSrc={angleRightDark} />
								</Button>
							</AppTable.Col>

							<AppTable.Col>
								<div className={styles.clusterSettings__content_wrapped__body__permissions__mock_one} />
							</AppTable.Col>

							<AppTable.Col>
								<div className={styles.clusterSettings__content_wrapped__body__permissions__mock_two} />
							</AppTable.Col>

							<AppTable.Col />
						</AppTable.Row>
					</AppTable.Footer>
				</AppTable>
			) : (
				<ClusterSettingsNoUsers />
			)}

			{showAddPermissions ? (
				<CustomModal>
					<AddPermissions onDismiss={() => setShowAddPermissions(false)} clusterMembers={clusterMembers} />
				</CustomModal>
			) : null}
		</div>
	);
};
