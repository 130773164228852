import moment from 'moment';
import { put, select } from 'redux-saga/effects';
import {
	getClusterNodesMetricAction,
	setClusterNodeMetricForPeriodAction,
	setClusterNodesMetricForPeriodLoadedAction,
	initClusterMetricAction,
} from '../actions';
import { ICluster } from '@app/modules/cluster/components';
import { ApiNode, getClusterNodesList } from '@app/data-modules/cluster-nodes';
import { getClusterNodeMetricRequest } from '../api';
import { MetricScale, MetricPeriods } from '../entity';
import { setClusterError } from '@app/modules/cluster';
import { AppState } from '@app/reducers';
import { ApiRegion } from '@app/data-modules/regions';
import { buildDateFromScale } from '@app/utils/buildDateFromScale';

interface ScaleParams {
	scale: MetricScale;
	subtractAmount: number;
	subtractMeasurement: moment.DurationInputArg2;
}

const periodToScaleMap: Record<MetricPeriods, ScaleParams> = {
	'6h': {
		scale: '10m',
		subtractAmount: 6,
		subtractMeasurement: 'hours',
	},
	'24h': {
		scale: '1h',
		subtractAmount: 24,
		subtractMeasurement: 'hours',
	},
	'7d': {
		scale: '2h',
		subtractAmount: 7,
		subtractMeasurement: 'days',
	},
	'30d': {
		scale: '1d',
		subtractAmount: 30,
		subtractMeasurement: 'days',
	},
};

export function* getClusterNodesMetricSaga(action: ReturnType<typeof getClusterNodesMetricAction>) {
	const cluster: ICluster = yield select((store: AppState) => store.cluster.cluster);
	if (!cluster || !Object.entries(cluster).length) return;

	const { clusterName, period } = action;
	yield put(setClusterNodesMetricForPeriodLoadedAction({ clusterName, loadingStatus: 'LOADING' }));

	const clusterMetricPayload = yield select((store: AppState) => store.metric[clusterName]);
	if (!clusterMetricPayload) {
		yield put(initClusterMetricAction({ clusterName }));
	}

	const isNodesLoaded = yield select((store: AppState) => store.clusterNodes.nodesMap[clusterName].isLoaded);
	if (!isNodesLoaded) return;

	const scaleParams = periodToScaleMap[period];
	if (!scaleParams) throw new Error(`Period ${period} not supported.`);

	const date = buildDateFromScale(scaleParams);

	const clusterNodes: ApiNode[] = yield select((store: AppState) => getClusterNodesList(store, clusterName));

	const regions = yield select((store: AppState) => store.regions.regions);
	const nodesMap = new Map();
	clusterNodes.forEach(({ id, name, region }: ApiNode) => {
		const { country } = regions.find(({ code }: ApiRegion) => code === region) || {};
		nodesMap.set(id, { name, country });
	});

	try {
		const response = yield getClusterNodeMetricRequest(String(cluster.id), {
			...date,
			scale: scaleParams.scale,
			groupByNodes: true,
		});
		const nodesMetric: any = {};
		const metricMaxValues: any = {};
		const timestampsSet = new Set();

		Object.entries(response).forEach(([metricName, metricData]: any[]) => {
			if (!nodesMetric[metricName]) nodesMetric[metricName] = {};
			const currentMetric = nodesMetric[metricName];
			metricData.forEach(([timestamp, value, nodeId]: any[]) => {
				timestampsSet.add(timestamp);
				// we have some periods when we have metric for some nodeId but have no node with such id
				// to avoid app crash if nodesMap.get(nodeId) returns undefined we return {}
				const { name, country } = nodesMap.get(nodeId) || {};
				if (!currentMetric[nodeId]) {
					currentMetric[nodeId] = { data: [], label: name, country };
				}
				metricMaxValues[metricName] = Math.max(metricMaxValues[metricName] || 0, value);
				currentMetric[nodeId].data.push({ date: +timestamp, value });
			});
			clusterNodes.forEach(({ id }: ApiNode) => {
				const currentNode = currentMetric[id];
				if (!currentNode) return;
				Array.from(timestampsSet).forEach(timestamp => {
					if (currentNode.data.find(({ date }: any) => date === timestamp)) return;
					currentNode.data.push({ date: timestamp, value: 0 });
				});
			});
		});

		const metrics: any = {};
		if (Object.keys(nodesMetric).length) {
			['disk.used', 'mem.usage', 'cpu.load', 'bandwidth'].forEach(metricName => {
				let metric: any[];
				if (metricName === 'bandwidth') {
					const bandwidthTx = Object.values(nodesMetric['net.tx']).map(({ data, label, country }: any) => {
						return { data, label: `${label} net.tx`, country };
					});
					const bandwidthRx = Object.values(nodesMetric['net.rx']).map(({ data, label, country }: any) => {
						return { data, label: `${label} net.rx`, country };
					});
					metric = [...bandwidthTx, ...bandwidthRx];
					metricMaxValues[metricName] = Math.max(metricMaxValues['net.tx'], metricMaxValues['net.rx']);
				} else {
					metric = Object.values(nodesMetric[metricName]);
				}
				metrics[metricName] = metric.filter((node: any) => node.data.find((point: any) => point.value));
			});
		}

		yield put(setClusterNodeMetricForPeriodAction({
			clusterName: cluster.name,
			metric: Object.keys(metrics).length ? metrics : null,
			metricMaxValues,
			period,
		}));
	} catch (e) {
		yield put(setClusterError({ error: e }));
	} finally {
		yield put(setClusterNodesMetricForPeriodLoadedAction({ clusterName, loadingStatus: 'FINISHED' }));
	}
}
