import { NODE_STATUSES } from '@app/data-modules/healthStatuses';
import styles from './style.module.scss';

export const getStatusText = (status: NODE_STATUSES) => {
	switch(status) {
		case NODE_STATUSES.new: return 'New';
		case NODE_STATUSES.deploying: return 'Deploying';
		case NODE_STATUSES.healthy: return 'Healthy';
		case NODE_STATUSES.unhealthy: return 'Unhealthy';
		case NODE_STATUSES.down: return 'Down';
		default:
			return 'Unknown';
	}
}

export const getStatusStyle = (status: NODE_STATUSES) => {
	switch(status) {
		case NODE_STATUSES.new: return styles.new;
		case NODE_STATUSES.deploying: return styles.deploying;
		case NODE_STATUSES.healthy: return styles.healthy;
		case NODE_STATUSES.unhealthy: return styles.unhealthy;
		case NODE_STATUSES.down: return  styles.down;
		default:
			return styles.unknown;
	}
}

export const getStatusTooltipText = (status: NODE_STATUSES) => {
	switch(status) {
		case NODE_STATUSES.new: return 'New';
		case NODE_STATUSES.deploying: return 'Deploying';
		case NODE_STATUSES.healthy: return 'Operating normally';
		case NODE_STATUSES.unhealthy: return 'Node unhealthy';
		case NODE_STATUSES.down: return  'Failed and disconnected';
		default:
			return 'Status unknown';
	}
}
