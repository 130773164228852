import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { CustomModal } from '@app/shared/customModal';
import { withFormik, FormikProps, Form } from 'formik';
import { AppState } from '@app/reducers';
import { IErrorMessage } from '@app/shared/errorMessage';
import { TeamMember } from '../../state/interfaces';
import { updateAccountMemberRole } from '@app/modules/team/state/actions';
import { SvgIcon } from '@app/shared/SvgIcon';
import iconClose from '@icons/icon.close.svg';
import { SelectWithSearch } from '@app/shared/selectWithSearch';
import { PermissionsRole } from '@app/modules/cluster/components/clusterSettings/clusterSettingsPermissions';
import { UserAvatar } from '@app/shared/userAvatar';

export interface EditTeamMemberRoleValues {
	avaColor: string;
	memberId: number;
	memberPermission: string;
}
interface EditTeamMemberRoleProps {
	error: IErrorMessage;
	onDismiss: () => void;
	member: TeamMember;
	updateAccountMemberRole: ({ id, permission }: Partial<TeamMember>) => void;
}

const mock = [
	{ key: 1, text: 'Biller', value: PermissionsRole.BILLER },
	{ key: 2, text: 'Member', value: PermissionsRole.MEMBER },
];

const EditTeamMemberRoleComponent: React.FC<
	EditTeamMemberRoleProps & FormikProps<EditTeamMemberRoleValues>
> = props => {
	const { values, handleSubmit, isSubmitting, setFieldValue, onDismiss, member } = props;
	const { memberPermission, avaColor } = values;

	return (
		<CustomModal>
			<div className={styles.editRole}>
				<UserAvatar email={member.email} ownAvatarColor={avaColor} className={styles.editRole__icon} />
				<SvgIcon
					iconSrc={iconClose}
					className={classNames(styles.editRole__close, { [styles.hidden]: isSubmitting })}
					onClick={() => {
						onDismiss();
					}}
				/>

				<div className={styles.editRole__header}>{`${member.firstName} ${member.lastName}`}</div>
				<div className={styles.editRole__subHeader}>{member.email}</div>

				<Form onSubmit={handleSubmit} className={styles.editRole__form}>
					<SelectWithSearch
						disabledInput={true}
						selectLabel="User Role"
						selectValue={memberPermission}
						selectName={'memberPermission'}
						handleChange={setFieldValue}
						options={mock}
						wrapClassname={styles.editRole__form__wrapSelect}
						labelClassname={styles.editRole__form__wrapSelect__label}
						selectClassname={styles.editRole__form__wrapSelect__select}
						optionsClassname={styles.editRole__form__wrapSelect__select__options}
						optionClassname={styles.editRole__form__wrapSelect__select__options__option}
						showSelectedSign={true}
					/>

					<div className={styles.editRole__form__msg}>{getRoleDescription(memberPermission)}</div>

					<Button
						type="submit"
						disabled={isSubmitting}
						className={classNames('btn', 'btn-one', styles.editRole__saveBtn)}
					>
						Save role
					</Button>
				</Form>
			</div>
		</CustomModal>
	);
};

export const EditTeamMemberRole = connect(
	(state: AppState) => ({
		error: state.team.error,
	}),
	{ updateAccountMemberRole }
)(
	withFormik<EditTeamMemberRoleProps, EditTeamMemberRoleValues>({
		mapPropsToValues: ({ member }) => {
			return {
				memberPermission: member.permission,
				avaColor: member.color,
				memberId: member.id,
			};
		},

		handleSubmit: (values, { props }) => {
			props.onDismiss();
			props.updateAccountMemberRole({ id: values.memberId, permission: values.memberPermission });
		},
		displayName: 'EditTeamMemberRole',
	})(EditTeamMemberRoleComponent)
);

const getRoleDescription = (userRole: string) => {
	switch (userRole.toLowerCase()) {
		case PermissionsRole.BILLER:
			return 'Biller has full access to the billing settings including credit card management.';

		case PermissionsRole.MEMBER:
			return 'Read-only access to all clusters by default. Add users to clusters to give them management permissions. No billing access.';
	}
};
