import { customYup as Yup, yupExceptions } from '@app/utils/customYup';

export default Yup.object().shape({
	email: Yup.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.max(320)
		.matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, `Please enter a valid email`)
		.required('Email is required'),
	password: Yup.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('Password is required'),
});
