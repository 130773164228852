import { call, take, put } from 'redux-saga/effects';
import { saveAccountSeatsApi } from '../api';
import { saveAccountSeats, setAccountSeatsError, setAccountSeats } from '../actions';
import { MetaError } from '@app/types/metaError';

export function* watchSaveAccountSeats() {
	while (true) {
		const action = yield take('*');
		if (saveAccountSeats.is(action)) {
			yield call(saveAccountSeatsWorker, action);
		}
	}
}

function* saveAccountSeatsWorker({
	totalSeatsAmount,
	onSuccess,
	onFinal,
}: {
	totalSeatsAmount: number;
	onSuccess: () => void;
	onFinal: () => void;
}) {
	try {
		const result = yield call(saveAccountSeatsApi, totalSeatsAmount);
		yield put(setAccountSeats({ totalSeatsAmount: result.totalSeatsAmount }));
		yield call(onSuccess);
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(setAccountSeatsError({ error: new MetaError(meta ? { message: e.message } : e, meta) }));
	} finally {
		yield call(onFinal);
	}
}
