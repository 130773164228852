import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { Col } from './Col';
import { Row } from './Row';
import { Head } from './Head';
import { Footer } from './Footer';
import { Body } from './Body';

interface Props {
	className?: string;
	children: any;
	roundBorders?: boolean;
}

export function AppTable(props: Props) {
	return (
		<table
			className={classnames(styles.appTable, { [styles.roundBorders]: props.roundBorders }, props.className)}
			cellPadding={0}
			cellSpacing={0}
		>
			{props.children}
		</table>
	);
}

AppTable.Col = Col;
AppTable.Row = Row;
AppTable.Head = Head;
AppTable.Body = Body;
AppTable.Footer = Footer;
