import { takeEvery, call, put } from 'redux-saga/effects';
import { getAccountInfoApi } from '../api';
import { setAccountInfo } from '../actions';
import { setSigninToken } from '@app/modules/user-sign-in';

export function* watchGetAccountInfo() {
	yield takeEvery(setSigninToken.TYPE, getAccountInfoWorker);
}

function* getAccountInfoWorker() {
	try {
		const result = yield call(getAccountInfoApi);
		yield put(setAccountInfo({ payload: result }));
	} catch (e) {
		throw new Error(`Failed to fetch account info, response: ${e.response}`);
	}
}
