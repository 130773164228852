import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@app/reducers';
import { ClusterListItem } from '../clusterItem';
import { ErrorMessage } from '@app/shared/errorMessage';
import { clearClusterListError, subscribeClustersMetrics, unsubscribeClustersMetrics } from '../../state';
import { BtnDeployNewCluster } from '@app/shared/BtnDeployNewCluster';
import { NoClusters } from '../noClusters';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { AppTable } from '@app/shared/AppTable';
import { AddBillingInfoBanner } from '@app/modules/information-banner';
import { AppSpinner } from '@app/shared/AppSpinner';
import { SortingCtrls } from '@app/shared/sortingCtrls/SortingCtrls';
import { compareValues } from '@app/utils/compareValues';
import { ClusterListItem as IClusterListItem } from '../../state/interfaces';
import { SearchInput } from '@app/shared/searchInput';
import { useBillingInfoSubscriptionStatus } from '../../../../hooks/useBillingInfoSubscriptionStatus';
import { SubscriptionStatusBanner } from '@app/modules/information-banner/subscriptionStatusBanner';

const subscriberId = 'ClusterList';

export const ClustersList: React.FC<{}> = () => {
	const { clustersList, error, hasPaymentMethod, loading, clustersMetrics } = useSelector((state: AppState) => ({
		clustersList: state.clustersList.clustersList,
		error: state.clustersList.error,
		hasPaymentMethod: state.account.billingInfo.hasPaymentMethod,
		loading: state.clustersList.loading,
		clustersMetrics: state.clustersList.clustersMetrics,
	}));
	const dispatch = useDispatch();
	const [filter, setFilter] = useState('');
	const [filteredClustersList, setFilteredClustersList] = useState(clustersList);
	const [allNodeTypes, setAllNodeTypes] = useState<any>([]);
	const { subscriptionValid, subscriptionStatus, subscriptionStatusReason } = useBillingInfoSubscriptionStatus();

	// handle filtering
	useEffect(() => {
		if (filter) {
			const filteredClustersList = clustersList.filter(item =>
				item.name.toLowerCase().includes(filter.toLowerCase())
			);
			setFilteredClustersList(filteredClustersList);
		} else {
			setFilteredClustersList(clustersList);
		}
	}, [filter, clustersList]);

	// Combine nodes with the same region to get all types of node available on ClustersList page
	useEffect(() => {
		if (clustersList.length) {
			let allNodeTypes: any[] = [];

			clustersList.forEach(cluster => {
				const combinedNodes: any[] = [];
				let existingNodeIdx: number | null = null;
				cluster.nodes.forEach(currentNode => {
					combinedNodes.forEach((node, nodeIdx) => {
						if (node.region === currentNode.region) {
							existingNodeIdx = nodeIdx;
						}
					});

					if (!existingNodeIdx) {
						combinedNodes.push({ ...currentNode });
					}
				});

				if (combinedNodes.length > 1) {
					allNodeTypes = [...allNodeTypes, { region: 'Global', clusterId: cluster.id }];
				} else if (combinedNodes.length === 1) {
					allNodeTypes = [...allNodeTypes, { region: combinedNodes[0].region, clusterId: cluster.id }];
				} else {
					allNodeTypes = [...allNodeTypes, { region: 'None', clusterId: cluster.id }];
				}
			});
			setAllNodeTypes(allNodeTypes);
		}
	}, [clustersList]);

	// handle unmount
	useEffect(() => {
		dispatch(subscribeClustersMetrics({ id: subscriberId }));
		return () => {
			dispatch(clearClusterListError({}));
			dispatch(unsubscribeClustersMetrics({ id: subscriberId }));
		};
	}, [dispatch]);

	// sorting function
	const sortClustersByField = (key: string, order?: 'asc' | 'desc') => () => {
		const sortedClustersList = [...filteredClustersList].sort(compareValues({ key, order }));
		setFilteredClustersList(sortedClustersList);
	};

	const sortNodesByField = (key: string, order?: 'asc' | 'desc') => () => {
		const sortedNodeTypes = [...allNodeTypes].sort(compareValues({ key, order }));
		let orderedClustersList: any = [];
		sortedNodeTypes.forEach(nodeType => {
			filteredClustersList.forEach(cluster => {
				if (cluster.id === nodeType.clusterId) {
					orderedClustersList.push(cluster);
				}
			});
		});

		setAllNodeTypes(sortedNodeTypes);
		setFilteredClustersList(orderedClustersList);
	};

	const sortByMetrics = (order?: 'asc' | 'desc') => () => {
		let sortedClustersList = [...filteredClustersList].sort((c1, c2) => {
			const a = clustersMetrics[c1.id],
				b = clustersMetrics[c2.id];

			if (a) {
				if (b) {
					if (a['cpu.load'] > b['cpu.load']) {
						return 1;
					}

					if (a['cpu.load'] < b['cpu.load']) {
						return -1;
					}

					return 0;
				}

				return 1;
			}

			if (b) {
				return -1;
			}

			return 0;
		});

		if (order === 'desc') {
			sortedClustersList = sortedClustersList.reverse();
		}

		setFilteredClustersList(sortedClustersList);
	};

	return (
		<div className={styles.clustersList}>
			<div className={classnames(styles.clustersList__head)}>
				<div className={styles.clustersList__head__header}>My Clusters</div>
				<div className={styles.clustersList__head__search}>
					<SearchInput
						placeholder={'Search cluster'}
						searchName={'search'}
						handleChange={e => setFilter((e.target as any).value)}
						value={filter}
						handleClear={() => setFilter('')}
					/>
				</div>
			</div>

			{hasPaymentMethod ? null : (
				<AddBillingInfoBanner
					className={classnames({
						[styles.clustersList__addBillingInfoBanner_withMargin]: !subscriptionValid,
					})}
				/>
			)}
			{subscriptionValid ? null : (
				<SubscriptionStatusBanner
					subscriptionStatus={subscriptionStatus}
					subscriptionStatusReason={subscriptionStatusReason}
				/>
			)}

			{loading || clustersList.length ? (
				<AppTable className={styles.clustersList__clustersTable}>
					<AppTable.Head>
						<AppTable.Row>
							<AppTable.Col>
								<span>Global Hostname</span>
								<SortingCtrls
									sortAsc={sortClustersByField('name')}
									sortDesc={sortClustersByField('name', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span>Docker Image</span>
								<SortingCtrls
									sortAsc={sortClustersByField('currentVersion.image')}
									sortDesc={sortClustersByField('currentVersion.image', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span>Nodes</span>
								<SortingCtrls
									sortAsc={sortNodesByField('region')}
									sortDesc={sortNodesByField('region', 'desc')}
								/>
							</AppTable.Col>

							<AppTable.Col>
								<span>Load</span>
								<SortingCtrls sortAsc={sortByMetrics()} sortDesc={sortByMetrics('desc')} />
							</AppTable.Col>

							<AppTable.Col />
						</AppTable.Row>
					</AppTable.Head>
					<AppTable.Body>
						{!loading &&
							clustersList.length &&
							filteredClustersList.map((cluster: IClusterListItem) => (
								<ClusterListItem
									key={cluster.id}
									{...cluster}
									metrics={clustersMetrics[cluster.id]}
									className={styles.clustersList__table__row_hover}
								/>
							))}
						{loading && (
							<AppTable.Row>
								<AppTable.Col colSpan={5} className={styles.loadingRow}>
									<AppSpinner className={styles.loadingRow__icon} />
									<div className={styles.loadingRow__label}>Loading Clusters...</div>
								</AppTable.Col>
							</AppTable.Row>
						)}
					</AppTable.Body>
					<AppTable.Footer>
						<AppTable.Row className={styles.clustersList__lastRow}>
							<AppTable.Col>
								<BtnDeployNewCluster
									iconName="angle right"
									className={classnames(
										'deploy-cluster-btn-table',
										'btn-three',
										styles.clustersList__footerTableBtn
									)}
								/>
							</AppTable.Col>
							<AppTable.Col>
								<div className={classnames('mock-label-one')} />
							</AppTable.Col>
							<AppTable.Col>
								<div className={classnames('mock-label-two')} />
							</AppTable.Col>
							<AppTable.Col>
								<div className={classnames('mock-label-three')} />
							</AppTable.Col>
							<AppTable.Col />
						</AppTable.Row>
					</AppTable.Footer>
				</AppTable>
			) : (
				<NoClusters />
			)}
			<ErrorMessage error={error} />
		</div>
	);
};
