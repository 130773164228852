import { call, take, put } from 'redux-saga/effects';
import { deleteBillingPaymentInfoCardApi } from '../api';
import { removePaymentCard, getPaymentInfo, setAccountBillingError } from '../actions';
import { MetaError } from '@app/types/metaError';

export function* watchRemovePaymentInfoCard() {
	while (true) {
		const action = yield take('*');
		if (removePaymentCard.is(action)) {
			yield call(removePaymentInfoCardWorker, action);
		}
	}
}

function* removePaymentInfoCardWorker({ id, onFinal }: { id: number; onFinal: () => void }) {
	try {
		yield call(deleteBillingPaymentInfoCardApi, { id });
		yield put(getPaymentInfo({}));
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(setAccountBillingError({ error: new MetaError(meta ? { message: '' } : e, meta) }));
	} finally {
		onFinal();
	}
}
