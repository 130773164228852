import * as React from 'react';
import styles from './style.module.scss';
import { AppSpinner } from '@app/shared/AppSpinner';

// TO DO: add transitions

interface Props {
	style?: React.CSSProperties;
}

export const SpinnerComponent = (props: Props) => {
	const { style = {} } = props;

	return (
		<div className={styles.spinnerWrapper} style={style}>
			<AppSpinner className={styles.spinner} />
			We are loading your content...
		</div>
	);
};
