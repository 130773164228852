import React from 'react';
import classNames from 'classnames';
import { CustomModal } from 'src/shared/customModal';
import styles from './styles.module.scss';
import { withFormik, FormikProps, Form } from 'formik';
import { connect } from 'react-redux';
import { editRegistry, clearRegistriesError } from '../../state/actions';
import { AppState } from 'src/reducers';
import { IErrorMessage } from 'src/shared/errorMessage';
import validationSchema from './validation-schema';
import { InputErrorText } from '@app/shared/text/InputErrorText';
import { FormLayout, Col, Row } from '@app/shared/layouts/FormLayout';
import { InputLabel } from '@app/shared/text/InputLabel';
import { BasicInput } from '@app/shared/inputs/BasicInput';
import { Button } from 'semantic-ui-react';
import { SelectWithSearch } from '@app/shared/selectWithSearch';
import { SvgIcon } from '@app/shared/SvgIcon';
import iconAddRegistry from '@icons/icon.add.registry.svg';
import iconClose from '@icons/icon.close.svg';
import { Registry } from '@app/modules/registries';
import { FakeInputsToPreventAutofill } from '@app/shared/fakeInputsToPreventAutofill';

export interface EditRegistryValues {
	id?: number;
	type: string;
	username: string;
	password: string;
}
interface AddRegistryProps {
	error: IErrorMessage;
	onDismiss: () => void;
	editRegistry: ({ values, done }: { values: EditRegistryValues; done: (success: boolean) => void }) => void;
	onUnmount: (...args: any) => void;
	initRegistry?: Registry;
}

const mock = [
	{ key: 1, text: 'Docker Hub', value: 'docker' },
	{ key: 2, text: 'Quay', value: 'quay' },
];

const EditRegistryComponent: React.FC<AddRegistryProps & FormikProps<EditRegistryValues>> = props => {
	const defaultMsg = `Provide your login details to allow our clusters to pull your private container images`;
	const {
		values,
		handleChange,
		handleSubmit,
		handleBlur,
		isSubmitting,
		onDismiss,
		onUnmount,
		setFieldValue,
		errors,
		error,
		touched,
	} = props;
	const { username, password, type } = values;
	return (
		<CustomModal>
			<div className={styles.addRegistry}>
				<SvgIcon iconSrc={iconAddRegistry} className={styles.addRegistry__icon} />
				<SvgIcon
					iconSrc={iconClose}
					className={classNames(styles.addRegistry__close, { [styles.hidden]: isSubmitting })}
					onClick={() => {
						onDismiss();
						onUnmount();
					}}
				/>

				<div className={styles.addRegistry__header}>Select registry</div>

				{error.message ? (
					<div className={styles.addRegistry__errMsg}>{error.message}</div>
				) : (
					<div className={styles.addRegistry__msg}>{defaultMsg}</div>
				)}

				<Form onSubmit={handleSubmit} className={styles.form} autoComplete="off">
					<FakeInputsToPreventAutofill
						fakeInputs={[
							{ inputName: 'username', inputType: 'text' },
							{ inputName: 'password', inputType: 'password' },
						]}
					/>
					<FormLayout rowGap={0} className={styles.addRegistry__form}>
						<Row>
							<Col type="label">
								<InputLabel text="Registry" />
							</Col>
							<Col
								type="input"
								subContent={<InputErrorText errorText={touched['type'] ? errors['type'] : ''} />}
							>
								<SelectWithSearch
									selectLabel="Registry"
									selectName="type"
									handleChange={(fieldName, value) => setFieldValue(fieldName, value)}
									options={mock}
									wrapClassname={styles.addRegistry__wrapSelect}
									labelClassname={styles.addRegistry__wrapSelect__label}
									selectClassname={styles.addRegistry__wrapSelect__select}
									optionsClassname={styles.addRegistry__wrapSelect__select__options}
									optionClassname={styles.addRegistry__wrapSelect__select__options__option}
									showSelectedSign={true}
									selectValue={type}
									disabledInput={true}
								/>
							</Col>
						</Row>
						<Row>
							<Col type="label">
								<InputLabel text="Login" />
							</Col>
							<Col
								type="input"
								subContent={
									<InputErrorText errorText={touched['username'] ? errors['username'] : ''} />
								}
							>
								<BasicInput
									autoComplete="off"
									placeholder="Your login"
									name="username"
									value={username}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched['username'] && !!errors['username']}
									className={styles.addRegistry__regInput}
									setFieldValue={setFieldValue}
								/>
							</Col>
						</Row>
						<Row>
							<Col type="label">
								<InputLabel text="Password" />
							</Col>
							<Col
								type="input"
								subContent={
									<InputErrorText errorText={touched['password'] ? errors['password'] : ''} />
								}
							>
								<BasicInput
									autoComplete="off"
									type="password"
									placeholder="Your password"
									name="password"
									value={password}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched['password'] && !!errors['password']}
									className={styles.addRegistry__regInput}
									inputClassName={styles.addRegistry__regInput_input}
									setFieldValue={setFieldValue}
								/>
							</Col>
						</Row>
					</FormLayout>

					<Button
						type="submit"
						disabled={isSubmitting}
						className={classNames('btn', 'btn-one', styles.addRegistry__saveBtn)}
					>
						Save Credentials
					</Button>
				</Form>
			</div>
		</CustomModal>
	);
};

export const EditRegistry = connect((state: AppState) => ({ error: state.registries.error }), {
	editRegistry,
	onUnmount: clearRegistriesError,
})(
	withFormik<AddRegistryProps, EditRegistryValues>({
		mapPropsToValues: ({ initRegistry: { type, username } = {} }) => {
			return {
				type: type || 'docker',
				username: username || '',
				password: '',
			};
		},

		handleSubmit: (values, { setSubmitting, props }) => {
			if (props.initRegistry?.id) {
				values.id = props.initRegistry.id;
			}
			props.editRegistry({
				values,
				done: success => {
					setSubmitting(false);
					if (success) {
						props.onUnmount();
						props.onDismiss();
					}
				},
			});
		},
		validationSchema: validationSchema,
		displayName: 'AddRegistry',
	})(EditRegistryComponent)
);
