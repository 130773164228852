import { all } from 'redux-saga/effects';
import { watchGetClusterOnLocChange } from './getCluster';
import { watchDeleteClusterNode } from './deleteClusterNode';
import { watchAddNodeToCluster } from './addNodeToCluster';
import { watchAddDomain } from './addDomain';
import { watchDestroyCluster } from './destroyCluster';
import { watchUpdateCluster } from './updateCluster';
import { activityLogSaga } from '@app/data-modules/activity-log/sagas';
import { clusterMetricsSaga } from '@app/data-modules/cluster-metrics/saga';
import { watchPullAndDeployCluster } from './pullAndDeployCluster';
import { watchGetClusterMembers } from './getClusterMembers';
import { watchGetAvailableMembers } from './getAvailableMembers';
import { watchUpdateClusterMembersPerm } from './updateClusterMembersPermission';
import { watchGetClusterDomains } from './getClusterDomains';
import { watchRemoveDomain } from './removeClusterDomain';
import { watchRemoveClusterPermissionsUser } from './removeClusterPermissionsUser';
import { watchRebuildCluster } from './rebuildCluster';
import { watchEnableHttpsPort } from './enableHttpsPort';
import { watchDisableHttpsPort } from './disableHttpsPort';
import { watchRefreshDomain } from './refreshDomain';

export function* clusterSaga() {
	yield all([
		watchGetClusterOnLocChange(),
		watchDeleteClusterNode(),
		watchAddNodeToCluster(),
		watchAddDomain(),
		watchDestroyCluster(),
		watchUpdateCluster(),
		clusterMetricsSaga(),
		activityLogSaga(),
		watchPullAndDeployCluster(),
		watchGetClusterMembers(),
		watchGetAvailableMembers(),
		watchUpdateClusterMembersPerm(),
		watchGetClusterDomains(),
		watchRemoveDomain(),
		watchRemoveClusterPermissionsUser(),
		watchRebuildCluster(),
		watchEnableHttpsPort(),
		watchDisableHttpsPort(),
		watchRefreshDomain(),
	]);
}
