import React from 'react';
import styles from './style.module.scss';
import { InputProps, Input as SemanticInput } from 'semantic-ui-react';
import classnames from 'classnames';
import { NumberInput } from '../NumberInput';
import errorIcon from '@icons/icon-error.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { AppSkeleton } from '@app/shared/AppSkeleton';

interface Props extends InputProps {
	inputClassName?: string;
	inputErrorClassName?: string;
	customErrorIcon?: string;
	placeholder?: string;
	loading?: boolean;
	withErrorIcon?: boolean;
}

export const BasicInput: React.FC<Props> = ({
	className,
	inputClassName,
	inputErrorClassName = '',
	customErrorIcon,
	type: inputType = 'text',
	error,
	disabled,
	loading,
	placeholder,
	withErrorIcon = true,
	name,
	value,
	onBlur = () => {},
	setFieldValue,
	...rest
}) => {
	const Input = inputType === 'number' ? NumberInput : SemanticInput;

	const ownHandleBlur = (name: string, value: string | number | undefined) => (
		e: React.FocusEvent<HTMLInputElement>
	) => {
		if (setFieldValue) {
			// trim and replace multiwhitespaces with single one
			const prepValue = String(value).trim().replace(/\s\s+/g, ' ');
			setFieldValue(name, prepValue);
		}
		return onBlur(e);
	};

	return (
		<div className={classnames(styles.basicInput, className)}>
			{loading && <AppSkeleton className={styles.basicInput__skeleton} />}
			<Input
				name={name}
				value={value}
				className={classnames(
					styles.basicInput__input,
					{ [styles.basicInput__input_withError]: error },
					{ [inputErrorClassName]: error },
					{ [styles.basicInput__input_disabled]: disabled },
					inputClassName
				)}
				type={inputType}
				disabled={disabled}
				placeholder={loading ? '' : placeholder}
				onBlur={ownHandleBlur(name, value)}
				{...rest}
				error={error}
			/>
			{withErrorIcon && error && <SvgIcon iconSrc={customErrorIcon || errorIcon} className={styles.errorHint} />}
		</div>
	);
};
