import * as React from 'react';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { compose } from 'redux';

import { WizardProgressBar, WizardProgressBarStep, StepChild } from '@app/shared/wizardProgressBar';
import { createSelector } from 'reselect';
import styles from '../../style.module.scss';
import classnames from 'classnames';

import brandIcon from '@icons/icon.appfleet.logo.svg';
import { ModalProps } from '@app/utils/createModals';
import { AppButton } from '@app/shared/buttons/AppButton';
import { resetBillingWizardAction } from '../../state/actions';
import { BillingWizardStep, ComposedBillingWizardData } from '../../state/interfaces';
import { SvgIcon } from '@app/shared/SvgIcon';

interface InputProps extends ModalProps {
	steps: BillingWizardStep[];
	initialValues?: Record<string, any>;
	onDone: (composedData: any) => void;
}

interface Props extends InputProps {
	onUnmount: () => void;
}

interface State {
	stepIndex: number;
	steps: BillingWizardStep[];
	stepValues: Record<string, any>;
	stepCompletetion: Record<string, boolean>;
}

class BillingWizardDumb extends React.Component<Props, State> {
	state = {
		stepIndex: 0,
		steps: this.props.steps,
		stepValues: this.props.initialValues || ({} as Record<string, any>),
		stepCompletetion: {} as Record<string, boolean>,
	};

	handlePrevSwitch = () => {
		if (this.getPrevStep()) {
			this.prev();
		}
	};

	handleNextSwitch = (formid: string, values: Record<string, any>) => {
		if (this.getNextStep()) {
			const step = this.state.steps[this.state.stepIndex];

			if (!step) throw new Error('Something went wrong.');

			this.setState(
				{
					stepValues: {
						...this.state.stepValues,
						[step.name]: values,
					},
					stepCompletetion: {
						...this.state.stepCompletetion,
						[step.name]: true,
					},
				},
				this.next
			);
		} else {
			// steps completed, lift up data.WizardProgressBar
		}
	};

	getNextStep = (): BillingWizardStep | undefined => {
		return this.state.steps[this.state.stepIndex + 1];
	};

	getPrevStep = (): BillingWizardStep | undefined => {
		return this.state.steps[this.state.stepIndex - 1];
	};

	prev = () => {
		this.setActiveStep(this.state.stepIndex - 1);
	};

	next = () => {
		this.setActiveStep(this.state.stepIndex + 1);
	};

	setActiveStep = (step: number, cb?: Function) => {
		this.setState({ stepIndex: step }, () => cb && cb());
	};

	onDone = () => {
		const composedData = this.composeStepsData();

		this.props.onDone(composedData);
	};

	composeStepsData = (): ComposedBillingWizardData => {
		let values: Record<string, any> = {};

		this.state.steps.forEach(({ name, processValues }) => {
			const stepValues = this.state.stepValues[name];

			if (!stepValues) return;

			values = {
				...values,
				...(typeof processValues === 'function' ? processValues(stepValues) : stepValues),
			};
		});

		return values as ComposedBillingWizardData;
	};

	mapStepsToProgressBar = createSelector(
		() => this.props.steps,
		(steps: BillingWizardStep[]): WizardProgressBarStep[] =>
			steps.map(step => ({ tooltipText: step.meta.tooltipText }))
	);

	componentWillUnmount() {
		this.props.onUnmount();
	}

	render() {
		const step = this.state.steps[this.state.stepIndex];
		if (!step) throw new Error(`Step list doesnt have step at index: ${this.state.stepIndex - 1}`);

		const stepValue = this.state.stepValues[step.name];
		const StepComponent = step.step;

		const steps = this.mapStepsToProgressBar(this.state.steps);

		return (
			<div className={styles.billingWizard}>
				<div className={styles.headerContentWrapper}>
					<div className={styles.header}>
						<div className={styles.headerLogo}>
							<SvgIcon iconSrc={brandIcon} />
						</div>
						<div className={styles.headerContent}>
							<WizardProgressBar
								className={styles.progressBar}
								progressBarBackgroundFill={'#eaf6ff'}
								progressBarBackgroundHeight={'8px'}
								progressBarFill={'linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%)'}
								progressBarHeight={'8px'}
								steps={steps.length}
								borderRadius={'6px'}
								initialStep={this.state.stepIndex}
								activeStep={this.state.stepIndex}
								disableDotClickHandler={true}
								children={steps.map(step => {
									const renderer: StepChild = ({ isPassed, isActive, index }) => {
										return (
											<Popup
												className={styles.tooltip}
												key={step.tooltipText}
												inverted
												content={step.tooltipText}
												position={'bottom center'}
												trigger={
													<i
														className={classnames(styles.dot, {
															[styles.dot__active]: isActive,
															[styles.dot__not_reached]: !isActive,
															[styles.dot__passed]: isPassed && !isActive,
														})}
													/>
												}
											/>
										);
									};

									return renderer;
								})}
							/>
						</div>
						<AppButton
							theme={AppButton.themes.action}
							onClick={this.props.close}
							className={styles.headerBtn}
						>
							Close
						</AppButton>
					</div>
				</div>
				<div className={styles.content}>
					<StepComponent
						prev={this.handlePrevSwitch}
						next={this.handleNextSwitch}
						getNextStep={this.getNextStep}
						getPrevStep={this.getPrevStep}
						composeStepsData={this.composeStepsData}
						step={step}
						value={stepValue}
						stepValues={this.state.stepValues}
						key={step.name}
						done={this.onDone}
					/>
				</div>
			</div>
		);
	}
}

export const BillingWizard = compose<React.ComponentType<InputProps>>(
	connect(null, dispatch => ({
		onUnmount: () => dispatch(resetBillingWizardAction({})),
	}))
)(BillingWizardDumb);
