import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { Title } from './parts/Title';
import { Head, SectionContainer, Section } from './parts';

interface Props {
	headContent?: JSX.Element;
	bodyContent?: JSX.Element;
	className?: string;
	children?: any;
}

export function AppCard(props: Props) {
	const { className, children } = props;

	return <div className={classnames(styles.appCard, className)}>{children}</div>;
}

AppCard.Title = Title;
AppCard.Head = Head;
AppCard.SectionContainer = SectionContainer;
AppCard.Section = Section;
