import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Form } from 'formik';
import { Button } from 'semantic-ui-react';
import { SvgIcon } from '@app/shared/SvgIcon';
import iconHttpsPort from '@icons/https-port.svg';
import iconClose from '@icons/icon.close.svg';
import { Alert } from '@app/shared/Alert';
import styles from '../styles.module.scss';
import { MetaError } from '@app/types/metaError';
import classnames from 'classnames';

interface PortMappingProps {
	onDismiss: () => void;
	httpsPortFormCurrValue: number | null;
	httpsPortClusterValue: number | null;
	httpsPortValidationError?: string;
	clusterName: string;
	httpsError: MetaError;
	handleChange: (e: React.ChangeEvent<any>) => void;
	handleBlur: (e: React.FocusEvent<any>) => void;
	enableHttpsPort: ({
		httpsPort,
		clusterName,
		onSuccess,
	}: {
		httpsPort: number;
		clusterName: string;
		onSuccess: () => void;
	}) => void;
	disableHttpsPort: ({ clusterName, onSuccess }: { clusterName: string; onSuccess: () => void }) => void;
	setShowPortMapping: (showPortMapping: boolean) => void;
	httpsReqInProgress: boolean;
	settingsUnsaved: boolean;
	onHttpsChanged: (httpsChanged: boolean) => void;
}

export const PortMapping: React.FC<PortMappingProps> = ({
	onDismiss,
	handleChange,
	httpsPortFormCurrValue,
	httpsPortClusterValue,
	httpsPortValidationError = '',
	handleBlur,
	enableHttpsPort,
	disableHttpsPort,
	clusterName,
	httpsError,
	setShowPortMapping,
	httpsReqInProgress,
	settingsUnsaved,
	onHttpsChanged,
}) => {
	const [displayAlert, setDisplayAlert] = useState(false);
	const [errorOccured, setErrorOccured] = useState(false);
	const [disableBtns, setDisableBtns] = useState(true);
	const [disableDisableBtn, setDisableDisableBtn] = useState(false);

	// check whether  errors occurred
	useEffect(() => {
		if (httpsPortValidationError || httpsError) {
			setErrorOccured(true);
		} else {
			setErrorOccured(false);
		}
	}, [httpsPortValidationError, httpsError]);

	// btns should be disabled when no port value or validation error occurred
	useEffect(() => {
		if (
			settingsUnsaved ||
			httpsPortValidationError ||
			!httpsPortFormCurrValue ||
			Number(httpsPortFormCurrValue) === Number(httpsPortClusterValue) ||
			httpsReqInProgress
		) {
			setDisableBtns(true);
		} else {
			setDisableBtns(false);
		}
	}, [httpsPortValidationError, httpsPortFormCurrValue, httpsPortClusterValue, httpsReqInProgress, settingsUnsaved]);

	useEffect(() => {
		if (settingsUnsaved || httpsReqInProgress) {
			setDisableDisableBtn(true);
		} else {
			setDisableDisableBtn(false);
		}
	}, [httpsReqInProgress, settingsUnsaved]);

	// enable https with the specific private port or apply new port value
	const saveHttpsPort = () => {
		// this logic is for presentHash and initialHash of ClusterSettings
		// set httpsChanged to initial value before request
		onHttpsChanged(false);
		enableHttpsPort({
			httpsPort: Number(httpsPortFormCurrValue),
			clusterName,
			onSuccess: () => {
				setShowPortMapping(false);
				// set httpsChanged to TRUE since success
				onHttpsChanged(true);
			},
		});
	};

	const handleDisableHttps = () => {
		// this logic is for presentHash and initialHash of ClusterSettings
		// set httpsChanged to initial value before request
		onHttpsChanged(false);
		disableHttpsPort({
			clusterName,
			onSuccess: () => {
				setShowPortMapping(false);
				// set httpsChanged to TRUE since success
				onHttpsChanged(true);
			},
		});
		setDisplayAlert(false);
	};

	return (
		<div className={styles.portMapping}>
			<SvgIcon iconSrc={iconHttpsPort} className={styles.portMapping__icon} />
			<SvgIcon iconSrc={iconClose} className={styles.portMapping__close} onClick={onDismiss} />
			<div className={styles.portMapping__header}>HTTPS Port Mapping</div>
			{settingsUnsaved ? (
				<div className={classnames(styles.portMapping__msg, styles.portMapping__msg_warning)}>
					<p>Please, deploy your settings first.</p>
				</div>
			) : errorOccured ? (
				<div className={classnames(styles.portMapping__msg, styles.portMapping__msg_error)}>
					<p>
						{httpsPortValidationError ||
							httpsError?.message ||
							(httpsError instanceof MetaError && httpsError.get('port'))}
					</p>
				</div>
			) : (
				<div className={styles.portMapping__msg}>
					<p>
						Enter the private port of your container where your HTTP server is running. We will then open
						443 automatically and install a free SSL certificate for you
					</p>
				</div>
			)}
			{/* on submit we should prevent event default behavior to avoi submitting of the entire ClusterSettings(Pull and Deploy btn)*/}
			<Form className={classNames(styles.portMapping__form)} onSubmit={(e: any) => e.preventDefault()}>
				<div className={classNames(styles.portMapping__form__headers)}>
					<div>Public Port</div>
					<div>Private Port</div>
				</div>
				<div className={classNames(styles.portMapping__form__ports)}>
					<div>443</div>
					<div
						className={classNames(styles.portMapping__form__ports__private, {
							[styles.portMapping__form__ports__private_error]: httpsPortValidationError,
						})}
					>
						<input
							value={httpsPortFormCurrValue || ''}
							onChange={handleChange}
							name="currentVersion.httpsPort"
							type="text"
							placeholder="Enter Private Port"
							onFocus={e => (e.target.placeholder = '')}
							onBlur={e => {
								e.target.placeholder = 'Enter Private Port';
								handleBlur(e);
							}}
						/>
					</div>
				</div>

				<div className={classNames(styles.portMapping__form__ports)}>
					<div>80</div>
					<div>{httpsPortFormCurrValue || 'Enter private port above'}</div>
				</div>

				<div className={classNames(styles.portMapping__form__btnContainer)}>
					{!!httpsPortClusterValue ? (
						<>
							<Button
								onClick={() => setDisplayAlert(true)}
								type="button"
								className={classNames(
									'btn',
									'btn-six',
									styles.portMapping__form__btnContainer__disableBtn
								)}
								disabled={disableDisableBtn}
							>
								Disable
							</Button>
							<Button
								onClick={saveHttpsPort}
								type="submit"
								className={classNames(
									'btn',
									'btn-one',
									styles.portMapping__form__btnContainer__mainBtn
								)}
								disabled={disableBtns}
							>
								Apply changes
							</Button>
						</>
					) : (
						<Button
							onClick={saveHttpsPort}
							type="submit"
							className={classNames('btn', 'btn-one', styles.portMapping__form__btnContainer__mainBtn)}
							disabled={disableBtns}
						>
							Enable HTTPS
						</Button>
					)}
				</div>
			</Form>
			{displayAlert && (
				<Alert
					className={styles.portMapping__disableAlert}
					msg="By disabling HTTPS you will delete all the listed domain names and all certificates.
                    We will also close the ports 80 and 443. This could make your service inaccessible."
					onAgree={handleDisableHttps}
					onDismiss={() => setDisplayAlert(false)}
					btnAgreeText="Disable"
					btnDismissText="Keep"
				/>
			)}
		</div>
	);
};
