import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { navigateTo } from '../../utils/navigate-to';

export interface SubMenuItem {
	name: string;
	url: string;
	params?: any;
	isDirty?: boolean;
}

interface SubNavigationProps {
	items: SubMenuItem[];
	className?: string;
	button?: JSX.Element;
	activeItemIdx?: number;
}

export const SubNavigation: React.FC<SubNavigationProps> = ({ items, activeItemIdx = 0, className = '', button }) => {
	const [activeItem, setActiveItem] = React.useState<SubMenuItem>(items[activeItemIdx]);
	const handleClick = (item: SubMenuItem) => {
		// dirty components should use RouteLeavingGuard
		// so we should allow to navigate and RouteLeavingGuard will deal with it
		// but we should not set activeItem on click, it will be set automatically after
		// RouteLeavingGuard dealed with navigation
		navigateTo(item.url, item.params)();
		if (!activeItem.isDirty) {
			setActiveItem(item);
		}
	};

	React.useLayoutEffect(() => {
		setActiveItem(items[activeItemIdx]);
	}, [activeItemIdx, items]);

	return (
		<div className={classnames(styles.subNav, { [className]: !!className })}>
			<div className={styles.subNav__content}>
				<div className={styles.subNav__content__menu}>
					{items.map((item, itemIdx) => (
						<div
							key={itemIdx}
							className={classnames(styles.subNav__content__menu__item, {
								[styles.subNav__content__menu__item_active]: activeItem.name === item.name,
							})}
							onClick={() => handleClick(item)}
						>
							{item.name}
						</div>
					))}
				</div>

				<div className={styles.subNav__content__ctrls}>{button}</div>
			</div>
		</div>
	);
};
