import { NodeOption } from '../interface';
import { ConsoleOption } from './ConsoleOption';
import { LogsOption } from './LogsOption';
import { RemoveNodeOption } from './RemoveNodeOption';

export const optionsList: NodeOption[] = [
	{
		id: 'console-option',
		component: ConsoleOption,
	},
	{
		id: 'logs-option',
		component: LogsOption,
	},
	{
		id: 'remove-node-option',
		component: RemoveNodeOption,
	},
];
