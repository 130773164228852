import * as React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props {
	title: string;
	subtitle: string;
	className?: string;
}

export const BillingWizardHeader = (props: Props) => {
	return (
		<div className={classnames(styles.wrapper, props.className)}>
			<span className={styles.title}>{props.title}</span>
			<span className={styles.subtitle}>{props.subtitle}</span>
		</div>
	);
};
