export class MetaError extends Error {
	public readonly meta: KeyValue<any>;
	public message: string;

	constructor(err: { message: string }, meta: KeyValue<any> = {}) {
		const message = err?.message;
		super(message);
		this.meta = meta;
		this.message = message;
	}

	public get(type: string) {
		if (!this.meta) return this.message;
		return Object.values(this.meta[type] ?? {}).join(',');
	}
}
