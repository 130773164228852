import { defineActionGroup } from 'rd-redux-utils';
import { ApiActivityLog } from './state';

interface BaseType {
	clusterName: string;
}

const activityLogActionGroup = defineActionGroup<BaseType>('CLUSTER ACTIVITY LOG');

export const getClusterActivityLogAction = activityLogActionGroup.defineAction('GET LOGS');

export const setFetchingActivityLogAction = activityLogActionGroup.defineAction<{ state: boolean }>('SET FETCH STATE');

export const setClusterActivityLogAction = activityLogActionGroup.defineAction<{ log: ApiActivityLog[] }>(
	'SET LOG DATA'
);

export const rollbackClusterAction = activityLogActionGroup.defineAction<{ versionId: number }>('ROLLBACK VERSION');

export const rollbackClusterDoneAction = activityLogActionGroup.defineAction('ROLLBACK REQUEST DONE');

export const setClusterActivityLogPage = activityLogActionGroup.defineAction<{ page: number }>('SET PAGE');
