import api from '@app/apis/api';
import { ApiClusterTotalMetric, MetricScale, ApiNodeMetric } from './entity';
import { MetaError } from '@app/types/metaError';

interface MetricRequestParams {
	start_date: string;
	end_date: string;
	scale: MetricScale;
	node?: string;
	groupByNodes?: boolean;
}

export async function getClusterTotalMetricRequest(clusterId: string): Promise<ApiClusterTotalMetric | null> {
	const result: { [x: string]: any } = await api.get('/metrics/clusters');
	return result[clusterId];
}

export const getClusterNodeMetricRequest = async (
	clusterId: string,
	params: MetricRequestParams
): Promise<ApiNodeMetric> => {
	try {
		return await api.get('/metrics', {
			params: {
				...params,
				cluster_id: +clusterId,
			},
		});
	} catch (e) {
		const meta = e?.response?.data?.errors;
		throw new MetaError(meta ? { message: '' } : e, meta);
	}
};

export const getClusterNodesMetricRequest = async (clusterName: string): Promise<ApiNodeMetric> =>
	await api.get(`/metrics/${clusterName}/nodes`);
