import { Action } from 'redux';
import { ClusterNodesState, ClusterNodesItem } from './state';
import {
	setClusterNodesItemFetchingAction,
	setClusterNodesAction,
	setClusterNodesErrorAction,
	getClusterNodesAction,
	deleteClusterNodeAction,
	deleteClusterNodeSuccessAction,
	deleteClusterNodeFailedAction,
	clearClusterNodesErrorAction,
} from './actions';

const INITIAL_STATE: ClusterNodesState = {
	nodesMap: {} as Record<string, ClusterNodesItem>,
};

export function clusterNodesReducer(state = INITIAL_STATE, action: Action): ClusterNodesState {
	if (getClusterNodesAction.is(action)) {
		return {
			...state,
			nodesMap: {
				...state.nodesMap,
				[action.clusterName]: {
					...state.nodesMap[action.clusterName],
					isLoaded: false,
				},
			},
		};
	}

	if (setClusterNodesItemFetchingAction.is(action)) {
		return {
			...state,
			nodesMap: {
				...state.nodesMap,
				[action.clusterName]: {
					...state.nodesMap[action.clusterName],
					isFetching: action.isFetching,
				},
			},
		};
	}

	if (setClusterNodesAction.is(action)) {
		return {
			...state,
			nodesMap: {
				...state.nodesMap,
				[action.clusterName]: {
					...state.nodesMap[action.clusterName],
					nodes: action.nodes,
					isLoaded: true,
				},
			},
		};
	}

	if (setClusterNodesErrorAction.is(action)) {
		return {
			...state,
			nodesMap: {
				...state.nodesMap,
				[action.clusterName]: {
					...state.nodesMap[action.clusterName],
					error: action.error,
				},
			},
		};
	}

	// rm node on req success
	if (deleteClusterNodeSuccessAction.is(action)) {
		return {
			...state,
			nodesMap: {
				...state.nodesMap,
				[action.clusterName]: {
					...state.nodesMap[action.clusterName],
					nodes: state.nodesMap[action.clusterName].nodes.filter(
						node => node.hostname !== action.nodeHostname
					),
				},
			},
		};
	}

	// set error if failed
	if (deleteClusterNodeFailedAction.is(action)) {
		return {
			...state,
			error: action.error,
		}
	}

	// to clear state frm errors on start of new req or oin ther case
	if (deleteClusterNodeAction.is(action) || clearClusterNodesErrorAction.is(action)) {
		return {
			...state,
			error: INITIAL_STATE.error,
		}
	}

	return state;
}
