import api from '@app/apis/api';
import type { ApiRegistry } from './dto/registry';
import type { EditRegistryValues } from '../components/editRegistry';

export const getRegistriesApi = async (): Promise<ApiRegistry[]> =>
  	await api.get('/api/registry');

export const deleteRegistryApi = async (id: number) =>
  	await api.delete(`/api/registry/${id}`);

export const addRegistryApi = async (values: EditRegistryValues) =>
  	await api.post(`/api/registry`, values);

export const updateRegistryApi = async (id: number, values: EditRegistryValues) =>
	await api.put(`/api/registry/${id}`, values);
