import React from 'react';
import { Label } from 'semantic-ui-react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import alertIcon from '@icons/alert.svg';
import { ClusterListItemNode, CombinedNode } from '../../state/interfaces';
import { SvgIcon } from '@app/shared/SvgIcon';
import iconWorld from '@icons/icon.world.svg';
import { getFlagForNode } from '@app/utils/getFlagClass';

interface ClusterNodesProps {
	nodes: ClusterListItemNode[];
}

export const ClusterNodes: React.FC<ClusterNodesProps> = ({ nodes }) => {
	const [isShow, setIsShow] = React.useState(false);
	const [itemsList, setItemsList] = React.useState<CombinedNode[]>([]);
	const [itemRegion, setItemRegion] = React.useState('None');
	const [itemsAmount, setItemsAmount] = React.useState<number | ''>('');

	React.useEffect(() => {
		if (itemsList.length === 1) {
			setItemRegion(itemsList[0].region);
			setItemsAmount(itemsList[0].amountOfNodes);
		} else if (itemsList.length > 1) {
			setItemRegion('Global');
		} else {
			setItemRegion('None');
		}
	}, [itemsList, setItemRegion, setItemsAmount]);

	// Combine nodes with the same region
	React.useEffect(() => {
		if (nodes.length) {
			const combinedNodes: CombinedNode[] = [];
			let existingNodeIdx: number | null = null;
			nodes.forEach(currentNode => {
				combinedNodes.forEach((node, nodeIdx) => {
					if (node.region === currentNode.region) {
						existingNodeIdx = nodeIdx;
					}
				});

				if (existingNodeIdx !== null) {
					combinedNodes[existingNodeIdx].amountOfNodes += 1;
				} else {
					combinedNodes.push({ ...currentNode, amountOfNodes: 1 });
				}
				existingNodeIdx = null;
			});
			setItemsList(combinedNodes);
		}
	}, [nodes]);

	return (
		<div onMouseOver={() => setIsShow(true)} onMouseLeave={() => setIsShow(false)} className={styles.clusterNodes}>
			{itemsList.length === 1 ? (
				<i
					className={classNames('flag-icons', styles.flagIcons, {
						[getFlagForNode(itemsList[0].region)]: !!itemRegion,
					})}
				></i>
			) : itemsList.length > 1 ? (
				<SvgIcon iconSrc={iconWorld} className={styles.globalNodes} />
			) : (
				<SvgIcon iconSrc={alertIcon} className={styles.noneNodes} />
			)}

			<span>{itemsAmount ? `${itemRegion.toUpperCase()} \u2022 ${itemsAmount}` : itemRegion}</span>

			{isShow && itemsList.length > 1 ? (
				<Label basic pointing="above" className={styles.clusterNodes__descr}>
					{itemsList.map((item, idx: number) => {
						return (
							<div key={idx}>
								<div className={styles.clusterNodes__descr__origin}>
									<i
										className={classNames(
											'flag-icons',
											styles.flagIcons,
											getFlagForNode(item.region)
										)}
									></i>
									{item.region}
								</div>
								<div className={styles.clusterNodes__descr__amount}>{item.amountOfNodes}</div>
								<div style={{ clear: 'both' }}></div>
							</div>
						);
					})}
				</Label>
			) : null}
		</div>
	);
};
