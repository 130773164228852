import React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props {
	iconSrc: string;
	className?: string;
	ref?: any;
	onClick?: (props: any) => void;
	onMouseOver?: (props: any) => void;
	onMouseLeave?: (props: any) => void;
}

export const SvgIcon = ({ iconSrc, className, ...props }: Props) => (
	<span dangerouslySetInnerHTML={{ __html: iconSrc }} className={classnames(styles.svgIcon, className)} {...props} />
);
