import { call, take, all, put } from 'redux-saga/effects';
import { saveAccountInfoApi, saveAccountNewPasswordApi } from '../api';
import { saveAccountSettings, setAccountSettingsUserInfo, setAccountError } from '../actions';
import { AccountSettingsValues } from '../../components/accountSettings';
import { MetaError } from '@app/types/metaError';

export function* watchSaveAccountSettings() {
	while (true) {
		const action = yield take('*');
		if (saveAccountSettings.is(action)) {
			const [userInfoSucced, passSucced]: [boolean, boolean] = yield all([
				call(saveUserInfoWorker, {
					formValues: {
						email: action.formValues.email,
						lastName: action.formValues.lastName,
						firstName: action.formValues.firstName,
					},
				}),
				call(saveNewPassword, {
					onPassError: action.onPassError,
					formValues: {
						oldPassword: action.formValues.oldPassword,
						newPassword: action.formValues.newPassword,
					},
				}),
			]);

			if (userInfoSucced && passSucced) {
				yield call(action.onSuccess);
			}
		}
	}
}

function* saveUserInfoWorker({
	formValues,
}: {
	formValues: Partial<AccountSettingsValues>;
}) {
	try {
		if (Object.values(formValues).some(value => value)) {
			const response = yield call(saveAccountInfoApi, formValues);
			yield put(setAccountSettingsUserInfo(response));
		}
		return true;
	} catch (e) {
		const meta = e?.response?.data?.errors;
		const MetaErr = new MetaError(meta ? e : { message: e.message }, meta);
		yield put(setAccountError({ error: MetaErr}));
		return false;
	}
}

function* saveNewPassword({
	onPassError,
	formValues,
}: {
	onPassError: (errorMsg: string) => void;
	formValues: Partial<AccountSettingsValues>;
}) {
	try {
		if (formValues.oldPassword && formValues.newPassword) {
			yield call(saveAccountNewPasswordApi, formValues);
		}
		return true;
	} catch (e) {
		yield call(onPassError, e.message);
		return false;
	}
}
