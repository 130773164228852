import React from "react";
import { useSelector } from 'react-redux';
import { AppState } from "../reducers";

type SetStateValue = (value: React.SetStateAction<string>) => void;

export const usePathname = (): [string, SetStateValue] => {

    const pathname = useSelector((state: AppState) => {
        return state.router.location.pathname;
    });
    const [activeItem, setActiveItem] = React.useState(pathname);

    React.useEffect(() => {
        setActiveItem(pathname);
    }, [pathname]);

    return [activeItem, setActiveItem];
};
