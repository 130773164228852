import React from 'react';
import { useDispatch } from 'react-redux';
import styles from '../styles.module.scss';
import { ClusterSettingsUserCell } from '../ClusterSettingsUserCell';
import { removeClusterPermissionsUser } from '../../../state/actions';
import { ClusterMember } from '@app/modules/cluster';
import { AppTable } from '@app/shared/AppTable/AppTable';
import { SvgIcon } from '@app/shared/SvgIcon/SvgIcon';
import { RemoveItem } from '@app/shared/removeItem';
import iconRemove from '@icons/icon.remove.svg';
import { PermissionsRole } from '@app/modules/cluster/components/clusterSettings/clusterSettingsPermissions';
import { UserInfo } from '@app/modules/account/state/interfaces';

interface PermissionsTableItemProps {
	user: ClusterMember;
	clusterId: number;
	clusterName: string;
	loggedInUser: UserInfo;
	isReadonlyCluster: boolean;
}

export const PermissionsTableItem: React.FC<PermissionsTableItemProps> = ({
	user,
	clusterId,
	clusterName,
	loggedInUser,
	isReadonlyCluster,
}) => {
	const [isLoggedInUser] = React.useState(user.id === loggedInUser.id);
	const [showRemoveMember, setShowRemoveMember] = React.useState(false);
	const dispatch = useDispatch();

	return (
		<AppTable.Row className={styles.permissionsTableItem}>
			<AppTable.Col>
				<ClusterSettingsUserCell
					userName={`${user.firstName} ${user.lastName} ${isLoggedInUser ? ' (you)' : ''}`}
					userEmail={user.email}
					avaColor={user.color}
				/>
			</AppTable.Col>

			<AppTable.Col>{`${user.permission.charAt(0).toUpperCase()}${user.permission.slice(1)}`}</AppTable.Col>
			<AppTable.Col>
				<div className={styles.clusterSettings__positiveStatus}>{'Joined'}</div>
			</AppTable.Col>

			<AppTable.Col className={styles.permissionsTableItem__col}>
				{/* only owner of the cluster(the owner of the account, currently logged in) can remove users, but he can't removed himeself*/}
				{user.permission.toLowerCase() !== PermissionsRole.OWNER &&
				loggedInUser.permission === PermissionsRole.OWNER ? (
					<SvgIcon
						iconSrc={iconRemove}
						className={styles.permissionsTableItem__col__removeIcon}
						onClick={isReadonlyCluster ? () => {} : () => setShowRemoveMember(true)}
					/>
				) : null}
			</AppTable.Col>

			{showRemoveMember && !isReadonlyCluster ? (
				<RemoveItem
					msg={`Are you sure you want to remove ${user.firstName} ${user.lastName} from permissions list?`}
					onDismiss={() => setShowRemoveMember(false)}
					onAgree={() =>
						dispatch(
							removeClusterPermissionsUser({
								userId: user.id,
								clusterId,
								clusterName,
								onFinal: () => setShowRemoveMember(false),
							})
						)
					}
					btnAgreeText="Remove"
					btnDismissText="Keep"
				/>
			) : null}
		</AppTable.Row>
	);
};
