import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	className?: string;
	children?: any;
}

export const Head = (props: Props) => {
	const { children, className } = props;

	return <div className={classnames(styles.appCardHead, className)}>{children}</div>;
};
