import * as React from "react";
import styles from "../style.module.scss";
import classnames from "classnames";

interface Props {
    className?: string;
    children: JSX.Element[];
}

export const Row = (props: Props) => {
    const { children, className, ...rest } = props;

    const labelCol = children[0];
    const inputCol = children[1];

    if (!labelCol) throw new Error(`Missing label column at index [0]`);
    if (!inputCol) throw new Error(`Missing input column at index [1]`);

    return (
        <div className={classnames(styles.row, className)} {...rest}>
            {labelCol}
            {inputCol}
        </div>
    );
};
