import * as React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props {
	title: string;
	subtitle?: string;
	className?: string;
	customSubtitle?: JSX.Element | React.ReactNode;
	additionalHeaderComponent?: JSX.Element | React.ReactNode;
}

export const DeployWizardHeader = ({
	title,
	subtitle,
	className,
	customSubtitle,
	additionalHeaderComponent,
}: Props) => {
	return (
		<div className={classnames(styles.wrapper, className)}>
			<span className={styles.title}>{title}</span>
			{additionalHeaderComponent && <span className={styles.title}>{additionalHeaderComponent}</span>}
			{customSubtitle ? customSubtitle : <span className={styles.subtitle}>{subtitle}</span>}
		</div>
	);
};
