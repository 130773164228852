import * as React from 'react';
import styles from '../style.module.scss';
import classnames from 'classnames';

interface Props {
	className?: string;
	children: JSX.Element[];
}

export const RowWithDoubleCol = (props: Props) => {
	const { children, className, ...rest } = props;

	const labelCol1 = children[0];
	const inputCol1 = children[1];
	const labelCol2 = children[2];
	const inputCol2 = children[3];

	if (!labelCol1) throw new Error(`Missing label column at index [0]`);
	if (!inputCol1) throw new Error(`Missing input column at index [1]`);
	if (!labelCol2) throw new Error(`Missing label column at index [2]`);
	if (!inputCol2) throw new Error(`Missing input column at index [3]`);

	return (
		<div className={classnames(styles.rowWithDoubleCol, className)} {...rest}>
			{labelCol1}
			{inputCol1}
			<div className={styles.colSpace} />
			{labelCol2}
			{inputCol2}
		</div>
	);
};
