import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { AppState } from '@app/reducers';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { Tooltip } from '@app/shared/tooltip';
import { CopyToClipboardBtn } from '@app/shared/copyToClipboardBtn';

interface GlobalHostnameProps {
	className?: string;
}

export const GlobalHostname: React.FC<GlobalHostnameProps> = ({ className = '' }) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const { clusterName, accountPublicId } = useSelector((state: AppState) => ({
		clusterName: state.cluster.cluster.name,
		accountPublicId: state.account.info.publicId,
	}));
	const hostnameRef = React.useRef<HTMLElement>(null);

	return (
		<div className={classnames(styles.globalHostname, { [className]: !!className })}>
			{clusterName && accountPublicId ? (
				<div className={styles.globalHostname__content}>
					<span
						onMouseOver={() => setShowTooltip(true)}
						onMouseLeave={() => setShowTooltip(false)}
						className={styles.globalHostname__content__hostnameText}
						ref={hostnameRef}
					>
						{`${clusterName}-${accountPublicId}.${process.env.REACT_APP_HOSTNAME_DOMAIN}`}
						<Tooltip tooltipText="Your global hostname" shouldDisplaed={showTooltip} />
					</span>
					<CopyToClipboardBtn
						refToCopyFrom={hostnameRef}
						className={styles.globalHostname__content__copyIcon}
					/>
				</div>
			) : (
				<AppSkeleton className={styles.globalHostname__loadingMock} />
			)}
		</div>
	);
};
