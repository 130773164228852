import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { staticChartOptions } from './staticChartOptions';
import { LoadingSpinnerInjectedProps, withLoadingSpinner } from '@app/shared/hocs/enhancers/withLoadingSpinner';
import { ChartOptions, defaults } from 'chart.js';
import memo from 'memoize-one';

const BlueSet = [
	'#0f6fc6', '#009dd9', '#0bd0d9', '#10cf9b', '#7cca62', '#a5c249', '#3494ba', '#58b6c0', '#75bda7', '#7a8c8e',
	'#84acb6', '#2683c6', '#1cade4', '#2683c6', '#27ced7', '#42ba97', '#3e8853', '#62a39f', '#4a66ac', '#629dd1',
	'#297fd5', '#7f8fa9', '#5aa2ae', '#9d90a0', '#4a66ac', '#629dd1', '#297fd5', '#7f8fa9', '#5aa2ae', '#9d90a0',
];

defaults.global.defaultFontColor = '#545a76';
defaults.global.defaultFontFamily = 'Raleway';
defaults.global.defaultFontSize = 12;
defaults.global.defaultFontStyle = '400';

export interface LineChartMetric {
	label: string;
	country?: string;
	data: {
		value: number;
		date: number;
	}[];
	clusterTotal?: number;
	clusterName?: string;
}

interface InputProps {
	datasets: LineChartMetric[];
	labels: number[] | string[];
	plugins?: object[];
	canvasRef?: React.RefObject<Line>;
	tickMeasurementSuffix: string;
	maxTickValue?: number;
	minTickValue?: number;
	stepSize?: number;
	options?: (defaults: ChartOptions) => ChartOptions;
}

type Props = InputProps & LoadingSpinnerInjectedProps;

class LineChartDumb extends React.PureComponent<Props> {
	private readonly options: ChartOptions;

	constructor(props: Props) {
		super(props);

		const { tickMeasurementSuffix, maxTickValue, minTickValue, stepSize } = props;

		const defaultOptions: ChartOptions = {
			...staticChartOptions,
			maintainAspectRatio: false,
			scales: {
				...staticChartOptions.scales,
				yAxes: [
					{
						gridLines: {
							color: '#d4e2f1',
							zeroLineColor: '#d4e2f1',
						},
						ticks: {
							...(staticChartOptions as any).scales?.yAxes[0].ticks,
							callback: value => `${value}${tickMeasurementSuffix}`,
							...(maxTickValue !== undefined ? { suggestedMax: maxTickValue } : {}),
							...(minTickValue !== undefined ? { suggestedMin: minTickValue } : { c: 1 }),
							stepSize,
							maxTicksLimit: 5,
							fontColor: '#545a76',
							fontFamily: 'Raleway',
							fontSize: 12,
							fontStyle: '400',
						},
					},
				],
				xAxes: [
					{
						gridLines: {
							display: false,
							drawBorder: false,
						},
						ticks: {
							fontColor: '#545a76',
							fontFamily: "'Raleway'",
							fontSize: 12,
							fontStyle: '400',
						},
					},
				],
			},

			...(props.options ? props.options : {}),
		};

		this.options = props.options ? props.options(defaultOptions) : defaultOptions;
	}

	mapDatasets = memo((datasets: LineChartMetric[]) =>
		datasets.map((dataset, i) => {
			const { length } = BlueSet;
			const colorIndex = i < length ? i : i - Math.trunc(i / length) * length;
			return {
				fill: false,
				data: dataset.data.map(data => data.value),
				label: dataset.label,
				country: dataset.country,
				showTooltips: true,
				borderColor: BlueSet[colorIndex],
				pointBackgroundColor: BlueSet[colorIndex],
				pointBorderColor: BlueSet[colorIndex],
			};
		})
	);

	render() {
		const { datasets, labels, plugins = [], canvasRef } = this.props;
		return (
			<Line
				{...(canvasRef ? { ref: canvasRef } : {})}
				data={{
					labels: labels,
					datasets: this.mapDatasets(datasets),
				}}
				options={this.options}
				plugins={plugins}
			/>
		);
	}
}

export const LineChart = withLoadingSpinner(LineChartDumb) as React.ComponentType<InputProps>;
