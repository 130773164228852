export const DEPLOY_STEP_ID = 'DEPLOY_STEP';

export const deployStepMeta = {
	title: 'Deploying “@tag@” in @dockerImage@…',
	subtitle: 'This usually takes between 2 and 3 minutes. (In the near future this will go down to under 10 seconds)',
	btnText: 'Deploy cluster',
	tooltipText: 'Deployment',
};

export const REDEPLOY_STEP_ID = 'REDEPLOY_STEP';

export const redeployStepMeta = {
	title: 'Deploying “@tag@” in @dockerImage@…',
	subtitle: 'This usually takes between 2 and 3 minutes. (In the near future this will go down to under 10 seconds)',
	btnText: 'Deploy cluster',
	tooltipText: 'Deployment',
};

export const addNodesDeployStepMeta = {
	title: 'Modifying your cluster...',
	subtitle: 'This usually takes between 2 and 3 minutes. (In the near future this will go down to under 10 seconds)',
	btnText: 'Deploy cluster',
	tooltipText: 'Deployment',
};
