import { getClusterMembers, setClusterMembers } from '../actions';
import { getClusterMembersApi } from '../api';
import { call, put, take } from 'redux-saga/effects';

export function* watchGetClusterMembers() {
	while (true) {
		const action = yield take('*');
		if (getClusterMembers.is(action)) {
			yield call(getClusterMembersWorker, { name: action.name });
		}
	}
}

export function* getClusterMembersWorker({ name }: { name: string }) {
	try {
		const result = yield call(getClusterMembersApi, name);
		yield put(setClusterMembers({ clusterMembers: result }));
	} catch (e) {}
}
