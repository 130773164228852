import { call, take, put } from 'redux-saga/effects';
import { saveAccountBillingInfoApi } from '../api';
import { saveAccountBillingInfo, setAccountBillingInfo, setAccountBillingError } from '../actions';
import { AccountBillingInfo } from '../interfaces';
import { MetaError } from '@app/types/metaError';

export function* watchSaveAccountBillingInfo() {
	while (true) {
		const action = yield take('*');
		if (saveAccountBillingInfo.is(action)) {
			yield call(saveAccountBillingInfoWorker, action);
		}
	}
}

function* saveAccountBillingInfoWorker({
	onSuccess,
	onFinal,
	formValues,
}: {
	onSuccess: () => void;
	onFinal: () => void;
	formValues: AccountBillingInfo;
}) {
	try {
		yield call(saveAccountBillingInfoApi, formValues);
		yield put(setAccountBillingInfo({ billingInfo: formValues }));
		yield call(onSuccess);
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(setAccountBillingError({ error: new MetaError(meta ? { message: '' } : e, meta) }));
	} finally {
		yield call(onFinal);
	}
}
