import * as React from 'react';
import memo from 'memoize-one';
import { SpinnerComponent } from './SpinnerComponent';

export interface LoadingWrapperInputProps {
	loading?: boolean;
}

export interface LoadingWrapperParams extends LoadingWrapperInputProps {
	style?: React.CSSProperties;
}

interface Props<TComponentProps> extends LoadingWrapperParams {
	component: React.ComponentType<TComponentProps>;
}

export class LoadingWrapper<TComponentProps> extends React.Component<Props<TComponentProps>> {
	buildSpinnerProps = memo((loading?: boolean) => {
		const renderedSpinner = <SpinnerComponent style={this.props.style} />;

		return {
			loading,
			placeholder: loading ? renderedSpinner : null,
			forcedLoader: renderedSpinner,
		};
	});

	render() {
		const { loading, component: Component, ...childProps } = this.props;

		return <Component {...(childProps as TComponentProps)} spinner={this.buildSpinnerProps(loading)} />;
	}
}
