import React from 'react';
import { Menu } from 'semantic-ui-react';
import { CLUSTERS_LIST_URL } from '../../clusters-list';
import { REGISTRIES_URL } from '../../registries';
import { usePathname } from '@app/hooks';
import {
	CLUSTER_URL,
	CLUSTER_SETTINGS_URL,
	CLUSTER_NODES_URL,
	CLUSTER_HEALTHCHECK_URL,
	CLUSTER_CONSOLE_URL,
	CLUSTER_LOGS_URL,
	CLUSTER_ACITVITY_LOG_URL,
} from '../../cluster/state/url';
import { UrlPath, createPath } from 'rd-url-utils';
import { BtnDeployNewCluster } from '@app/shared/BtnDeployNewCluster';
import { NavMenuItem } from './NavMenuItem';
import { navigateTo } from 'src/utils/navigate-to';
import { useHistory } from 'react-router-dom';
import mainLogo from '@icons/icon.appfleet.logo.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { TEAM_URL } from '@app/modules/team/state/url';
import { AccountMenu } from '@app/modules/accountMenu';

// const REFERRAL_URL = createPath('/referral');
export const SUPPORT_URL = createPath('/support');

interface NavMenuProps {}
export interface MenuItem {
	name: string;
	url: string;
	activeFor?: UrlPath<{}, {}>[];
	iconType?: 'angle down';
	clickable: boolean;
}

const menuItems: MenuItem[] = [
	{
		name: 'My Clusters',
		url: CLUSTERS_LIST_URL.urlTemplate,
		activeFor: [
			CLUSTERS_LIST_URL,
			CLUSTER_URL,
			CLUSTER_SETTINGS_URL,
			CLUSTER_NODES_URL,
			CLUSTER_LOGS_URL,
			CLUSTER_HEALTHCHECK_URL,
			CLUSTER_CONSOLE_URL,
			CLUSTER_ACITVITY_LOG_URL,
		],
		clickable: true,
	},
	{
		name: 'Registries',
		url: REGISTRIES_URL.urlTemplate,
		clickable: true,
	},
	{
		name: 'My Team',
		url: TEAM_URL.urlTemplate,
		clickable: true,
	},
	/*
	{
		name: 'Referral',
		url: REFERRAL_URL.urlTemplate,
		clickable: true,
	},
	*/
	{
		name: 'Support',
		url: SUPPORT_URL.urlTemplate,
		iconType: 'angle down',
		clickable: false,
	},
];

export const NavMenu: React.FC<NavMenuProps> = () => {
	const [activeItem, setActiveItem] = usePathname();
	const { location } = useHistory();

	const isClusterPage = CLUSTER_URL.match(location, false).isMatched;

	return (
		<div className="nav-menu">
			<SvgIcon iconSrc={mainLogo} className="logo-main" onClick={navigateTo('/')} />
			<Menu fluid vertical={false} borderless={true} defaultActiveIndex={activeItem}>
				{menuItems.map((item, itemIdx) => (
					<NavMenuItem key={itemIdx} item={item} activeItem={activeItem} setActiveItem={setActiveItem} />
				))}
				<Menu.Menu position="right">{!isClusterPage && <BtnDeployNewCluster />}</Menu.Menu>
				<AccountMenu />
			</Menu>
		</div>
	);
};
