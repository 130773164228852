import * as React from 'react';
import styles from './styles.module.scss';
import { ModalProps } from '@app/utils/createModals';
import { WideScreenModalLayout } from '@app/shared/layouts/WideScreenModalLayout';
import { DeployWizard } from '@app/modules/deploy-wizard';
import { wizardSteps } from './wizard-steps';
import { compose } from 'redux';
import { ApiSize } from '@app/data-modules/sizes';
import { AppState } from '@app/reducers';
import { getSizes } from '@app/data-modules/sizes/selectors';
import { connect } from 'react-redux';
import { ICluster } from '@app/modules/cluster';
import memo from 'memoize-one';
import { REGIONS_STEP_ID } from '@app/modules/deploy-wizard/steps/RegionsStep';
import { getClusterNodesList, ApiNode, ApiNodeDTO, addClusterNodes } from '@app/data-modules/cluster-nodes';
import { RESOURCES_STEP_ID } from '@app/modules/deploy-wizard/steps/ResourcesStep';
import { ApiRegion } from '@app/data-modules/regions';
import { getRegionsSelector } from '@app/data-modules/regions/selectors';

type InputProps = ModalProps;

interface Props extends InputProps {
	sizes: ApiSize[];
	cluster: ICluster;
	nodes: ApiNode[];
	regionsMap: Record<string, ApiRegion>;
	addNodes: (cluster: ICluster, nodes: ApiNodeDTO[]) => void;
	deleteNode: (cluster: ICluster, hostname: string) => void;
}
interface NodesUpdateList {
	addNodeList: ApiNodeDTO[];
}

class UpdateNodesDeployWizardModalDumb extends React.Component<Props> {
	handleDone = (composedData: { nodes: ApiNodeDTO[] }) => {
		const { nodes } = composedData;
		Object.entries(this.initialValues[REGIONS_STEP_ID]).forEach(([region, { amount }]) => {
			while (amount--) nodes.push({ region });
		});
		this.handleNodesChange(composedData.nodes);
	};

	handleNodesChange = (nodes: ApiNodeDTO[]) => {
		const updateList = this.buildNodeUpdateList(nodes);
		if (updateList.addNodeList.length) {
			this.props.addNodes(this.props.cluster, updateList.addNodeList);
		}
	};

	buildNodeUpdateList = (wizardNodesResult: ApiNodeDTO[]): NodesUpdateList => {
		const nodeMapToAmount: Record<string, number> = {};
		const result: NodesUpdateList = { addNodeList: [] };

		wizardNodesResult.forEach(nodeDto => {
			const nodeAmount = nodeMapToAmount[nodeDto.region];
			nodeMapToAmount[nodeDto.region] = nodeAmount === undefined ? 1 : nodeAmount + 1;
		});

		Object.entries(nodeMapToAmount).forEach(([region, amount]) => {
			const userNodes = this.props.nodes.filter(node => node.region === region);
			const userNodesAmount = userNodes.length;

			if (userNodesAmount === amount) return;

			if (amount > userNodesAmount) {
				const dif = amount - userNodesAmount;
				result.addNodeList = [...result.addNodeList, ...new Array(dif).fill({ region })];
			}
		});

		return result;
	};

	getActivePlanIndex = memo((cluster: ICluster, sizes: ApiSize[]) => {
		return sizes.findIndex(size => cluster.size === size.code);
	});

	getInitialWizardValues = memo((nodes: ApiNode[]) => {
		return {
			[RESOURCES_STEP_ID]: {
				size: this.props.cluster.size,
			},
			[REGIONS_STEP_ID]: nodes.reduce((accum, node) => {
				let nodeAmount = accum[node.region];

				const region = this.props.regionsMap[node.region];

				accum[region.code] = {
					amount: !nodeAmount || !nodeAmount.amount ? 1 : nodeAmount.amount + 1,
					region: region || ({} as ApiRegion),
				};

				return accum;
			}, {} as Record<string, { amount: number; region: ApiRegion }>),
		};
	});

	initialValues = this.getInitialWizardValues(this.props.nodes);

	render() {
		const { open, close } = this.props;
		return (
			<WideScreenModalLayout className={styles.updateNodesWrapper} onOuterClick={close}>
				<DeployWizard
					open={open}
					close={close}
					steps={wizardSteps}
					onDone={this.handleDone}
					initialValues={this.initialValues}
				/>
			</WideScreenModalLayout>
		);
	}
}

const createRegionsMap = memo((regions: ApiRegion[]) => {
	return regions.reduce((acc, region) => {
		acc[region.code] = region;
		return acc;
	}, {} as Record<string, ApiRegion>);
});

export const UpdateNodesDeployWizardModal = compose<React.ComponentType<InputProps>>(
	connect(
		(state: AppState) => {
			const nodes = getClusterNodesList(state, state.cluster.cluster.name);
			const regions = getRegionsSelector(state);
			return {
				sizes: getSizes(state),
				cluster: state.cluster.cluster,
				nodes,
				regionsMap: createRegionsMap(regions),
			};
		},
		dispatch => ({
			addNodes: (cluster: ICluster, nodes: ApiNodeDTO[]) =>
				dispatch(addClusterNodes({ clusterName: cluster.name, nodes })),
		})
	)
)(UpdateNodesDeployWizardModalDumb);
