import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '@app/shared/SvgIcon';

interface Props {
	iconSrc: string;
	title: string;
	dangerAction?: boolean;
	onClick: () => void;
}

export const NodeOptionItem = (props: Props) => {
	const { iconSrc, title, onClick, dangerAction } = props;

	return (
		<div className={styles.nodeOptionItem} onClick={onClick}>
			<div
				className={classnames(styles.nodeOptionItem__icon, {
					[styles.nodeOptionItem__icon_danger]: dangerAction,
				})}
			>
				<SvgIcon iconSrc={iconSrc} />
			</div>
			<span className={classnames(styles.title, { [styles.title__danger]: dangerAction })} title={title}>
				{title}
			</span>
		</div>
	);
};
