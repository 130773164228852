import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { AppTable } from '../../../../../../shared/AppTable/AppTable';
import { SortingCtrls } from '../../../../../../shared/sortingCtrls/SortingCtrls';
import { AppSpinner } from '../../../../../../shared/AppSpinner/AppSpinner';
import { compareValues } from '../../../../../../utils/compareValues';
import { InvoiceItem, IInvoiceItem } from '../invoiceItem/InvoiceItem';
import { ErrorMessage } from '@app/shared/errorMessage';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../reducers/index';
import { NoInvoices } from './NoInvoices';

export const InvoicesList: React.FC<{}> = () => {
	const { billingInvoicesError, billingInvoices, loadingInvoices } = useSelector((state: AppState) => ({
		billingInvoicesError: state.account.billingInvoicesError,
		billingInvoices: state.account.billingInvoices,
		loadingInvoices: state.account.billingInvoicesLoading,
	}));
	const [sortedList, setSortedList] = React.useState<IInvoiceItem[]>([]);

	React.useEffect(() => {
		if (billingInvoices.length) {
			setSortedList([...billingInvoices].sort(compareValues({ key: 'paidAt', order: 'desc' })));
		}
	}, [billingInvoices]);

	const sortByField = (key: string, order?: 'asc' | 'desc') => () => {
		const sortedList = [...billingInvoices].sort(compareValues({ key, order }));
		setSortedList(sortedList);
	};

	return (
		<div className={styles.invoices}>
			<div className={classnames(styles.invoicesList__header, styles.invoicesList__contentWrapper)}>
				<div className={styles.invoicesList__headerTitle}>{'Invoices'}</div>
			</div>

			<div className={styles.invoicesList__contentBlock}>
				{loadingInvoices || sortedList.length ? (
					<AppTable className={styles.invoicesList__contentBlock__table}>
						<AppTable.Head>
							<AppTable.Row>
								<AppTable.Col>
									<span>Invoice ID</span>
									<SortingCtrls
										sortAsc={sortByField('number')}
										sortDesc={sortByField('number', 'desc')}
									/>
								</AppTable.Col>

								<AppTable.Col>
									<span>Date</span>
									<SortingCtrls
										sortAsc={sortByField('paidAt')}
										sortDesc={sortByField('paidAt', 'desc')}
									/>
								</AppTable.Col>

								<AppTable.Col>
									<span>Method</span>
								</AppTable.Col>

								<AppTable.Col>
									<span>Amount</span>
									<SortingCtrls
										sortAsc={sortByField('amountRemaining')}
										sortDesc={sortByField('amountRemaining', 'desc')}
									/>
								</AppTable.Col>

								<AppTable.Col>
									<span>Status</span>
									<SortingCtrls
										sortAsc={sortByField('paid')}
										sortDesc={sortByField('paid', 'desc')}
									/>
								</AppTable.Col>

								<AppTable.Col></AppTable.Col>
							</AppTable.Row>
						</AppTable.Head>

						<AppTable.Body>
							{sortedList.map((invoice: IInvoiceItem) => (
								<InvoiceItem key={invoice.id} invoice={invoice} />
							))}

							{loadingInvoices && (
								<AppTable.Row>
									<AppTable.Col colSpan={5} className={styles.loadingRow}>
										<AppSpinner className={styles.loadingRow__icon} />
										<div className={styles.loadingRow__label}>Loading Invoices...</div>
									</AppTable.Col>
								</AppTable.Row>
							)}
						</AppTable.Body>
					</AppTable>
				) : !loadingInvoices && sortedList && !sortedList.length ? (
					<NoInvoices />
				) : (
					<AppSpinner className={styles.spinner} />
				)}
			</div>
			<ErrorMessage error={billingInvoicesError} useDefaultMsg={false} className={styles.invoicesList__error} />
		</div>
	);
};
