import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '@app/shared/SvgIcon';
import blueIcon from '@icons/blue-point.svg';

interface Props extends React.DOMAttributes<HTMLDivElement> {
	className?: string;
	active?: boolean;
	componentRef?: any;
}

export const MapDot = (props: Props) => {
	const { className, active, componentRef: ref, ...rest } = props;

	return (
		<div ref={ref} onClick={() => {}} {...rest}>
			<SvgIcon
				iconSrc={blueIcon}
				className={classnames(styles.dot, { [styles.dot_active]: active }, className)}
			/>
		</div>
	);
};
