import { Action } from 'redux';
import { ClusterWizardState } from './state';
import {
	createCluster,
	createClusterSuccess,
	deployCluster,
	deployClusterSuccess,
	deployClusterFail,
	createClusterNodes,
	createClusterNodesSuccess,
	updateClusterStartedAction,
	updateClusterErrorAction,
	updateClusterCompletedAction,
	resetWizardAction,
	clearWizardErrorAction,
} from './actions';
import {
	pullAndDeployClusterAction,
	pullAndDeployClusterFailedAction,
	pullAndDeployClusterSuccessAction,
	resetPullAndDeployClusterAction,
	updateClusterAction,
	updateClusterSuccessAction,
	updateClusterFailedAction,
	rebuildClusterAction,
	rebuildClusterFailedAction,
	rebuildClusterSuccessAction,
} from '@app/modules/cluster';
import { addClusterNodes, addClusterNodesSuccess, addClusterNodesFail } from '@app/data-modules/cluster-nodes/actions';
import { ICluster } from '../../cluster/components/cluster/Cluster';
import { setDeployWizardCluster } from './actions';

const INITIAL_STATE: ClusterWizardState = {
	deploy: {
		started: false,
		clusterDeployed: false,
		nodesCreated: false,
		completed: false,
		failed: false,
		cluster: {} as ICluster,
	},
	pullAndDeployState: {},
	error: { message: '' },
};

export function deployWizardReducer(state = INITIAL_STATE, action: Action): ClusterWizardState {
	if (pullAndDeployClusterFailedAction.is(action) || rebuildClusterFailedAction.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.clusterName]: {
					deploy: {
						...INITIAL_STATE.deploy,
						failed: true,
						completed: true,
					},
					errorMessage: action.error,
				},
			},
		};
	}
	if (pullAndDeployClusterAction.is(action) || rebuildClusterAction.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.clusterName]: {
					deploy: {
						...INITIAL_STATE.deploy,
						started: true,
					},
				},
			},
		};
	}

	if (updateClusterAction.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.cluster.name]: {
					deploy: {
						...INITIAL_STATE.deploy,
						started: true,
					},
				},
			},
		};
	}

	if (updateClusterSuccessAction.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.cluster.name]: {
					deploy: {
						...state.deploy,
						started: false,
						completed: true,
						clusterDeployed: true,
					},
				},
			},
		};
	}

	if (updateClusterFailedAction.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.cluster.name]: {
					deploy: {
						...INITIAL_STATE.deploy,
						failed: true,
						completed: true,
					},
				},
			},
		};
	}

	if (pullAndDeployClusterSuccessAction.is(action) || rebuildClusterSuccessAction.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.clusterName]: {
					deploy: {
						...state.deploy,
						started: false,
						completed: true,
						clusterDeployed: true,
					},
				},
			},
		};
	}

	if (resetPullAndDeployClusterAction.is(action)) {
		return {
			...state,
			pullAndDeployState: {
				...state.pullAndDeployState,
				[action.clusterName]: {
					deploy: INITIAL_STATE.deploy,
					errorMessage: '',
				},
			},
		};
	}

	if (addClusterNodes.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				started: true,
			},
		};
	}
	if (addClusterNodesFail.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				completed: true,
				failed: true,
			},
			error: action.error,
		};
	}

	if (deployCluster.is(action) || updateClusterStartedAction.is(action)) {
		return {
			...state,
			deploy: {
				...INITIAL_STATE.deploy,
				started: true,
			},
		};
	}

	if (deployClusterFail.is(action) || updateClusterErrorAction.is(action)) {
		return {
			...state,
			deploy: {
				...INITIAL_STATE.deploy,
				failed: true,
				completed: true,
			},
			error: action.error,
		};
	}

	if (createCluster.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				clusterDeployed: false,
			},
		};
	}

	if (createClusterNodes.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				nodesCreated: false,
			},
		};
	}

	if (resetWizardAction.is(action)) {
		return INITIAL_STATE;
	}

	if (createClusterNodesSuccess.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				nodesCreated: true,
			},
		};
	}

	if (deployClusterSuccess.is(action) || updateClusterCompletedAction.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				completed: true,
			},
		};
	}

	if (createClusterSuccess.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				clusterDeployed: true,
			},
		};
	}

	if (addClusterNodesSuccess.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				failed: false,
				completed: true,
			},
		};
	}

	if(setDeployWizardCluster.is(action)) {
		return {
			...state,
			deploy: {
				...state.deploy,
				cluster: action
			}
		}
	}

	if (clearWizardErrorAction.is(action)) {
		return {
			...state,
			error: INITIAL_STATE.error,
		}
	}

	return state;
}
