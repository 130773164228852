import React from 'react';
import { Prompt, Redirect, Route } from 'react-router-dom';
import { WarningDialog } from '../warningDialog';

interface Props {
	block: boolean;
	onAgree?: () => void;
}

interface State {
	isShown: boolean;
	nextLocation: any;
	redirect: boolean;
}

export class RouteLeavingGuard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isShown: false,
			nextLocation: null,
			redirect: false,
		};
	}

	handleBlockedNavigation = (nextLoc: any) => {
		if (!this.state.isShown && this.props.block) {
			this.setState({ isShown: true, nextLocation: nextLoc });
			return false;
		}

		return true;
	};

	// componentDidUpdate = () => {
	//   if (!this.state.isShown && this.props.block) {
	//     window.onbeforeunload = () => true;
	//   }
	// };

	render() {
		const { isShown, nextLocation, redirect } = this.state;
		const { onAgree, block } = this.props;

		return (
			<>
				<Prompt when={block} message={this.handleBlockedNavigation} />
				{isShown && (
					<WarningDialog
						msg={`All changes will be lost, are you sure you want to leave?`}
						onAgree={() => {
							this.setState({ redirect: true });
							onAgree && onAgree();
						}}
						onDismiss={() => {
							this.setState({ isShown: false, nextLocation: '' });
						}}
					/>
				)}
				{redirect && <Route render={() => <Redirect to={nextLocation.pathname} />} />}
			</>
		);
	}
}
