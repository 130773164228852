import React from 'react';
import { Header } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { clearReferralError } from '../state';
import { AppState } from '../../../reducers';
import { ErrorMessage } from '../../../shared/errorMessage';

export const Referral: React.FC<{}> = () => {
	const { error } = useSelector((state: AppState) => ({
		error: state.referral.error,
	}));
	const dispatch = useDispatch();

	React.useEffect(() => {
		return () => {
			dispatch(clearReferralError({}));
		};
	}, [dispatch]);

	return (
		<>
			<Header as="h2" className={''} content="Referral page" />
			<ErrorMessage error={error} />
		</>
	);
};
