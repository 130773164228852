import * as React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props {
	className?: string;
	children: any;
}

export const Content = (props: Props) => {
	const { className, children } = props;

	return <div className={classnames(styles.content, className)}>{children}</div>;
};
