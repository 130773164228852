import * as React from 'react';
import styles from './style.module.scss';
import { Form, FormikProps, withFormik } from 'formik';
import { DeployWizardForm } from '@app/modules/deploy-wizard/interfaces';
import { dockerImageSchema } from '@app/validation-schemas/DockerImageSchema';
import { BasicInput } from '@inputs/BasicInput';
import { Col, FormLayout, Row } from '@app/shared/layouts/FormLayout';
import { LabelWithTooltip } from '@app/shared/text/LabelWithTooltip';
import { InputErrorText } from '@app/shared/text/InputErrorText';
import { InputLabel } from '@app/shared/text/InputLabel';
import { validateImageRequest, ImageValidationStatus } from '@app/modules/deploy-wizard/state/requests';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import iconInfo from '@icons/icon.info.svg';
import { AppButton } from '@app/shared/buttons/AppButton';
import { dockerImageStepMeta } from '@app/modules/deploy-wizard/steps/DockerImageStep';

const getImageValidationErrText = (status: ImageValidationStatus) => {
	switch (status) {
		case ImageValidationStatus.NOT_FOUND: {
			return 'Image not found or is private';
		}
		case ImageValidationStatus.UNAUTHORIZED: {
			return 'We failed to pull the image with error: Wrong credentials';
		}
		default:
			return '';
	}
};

interface FormValues {
	image: string;
	tag: string;
}

interface State {
	disabled: boolean;
}

interface InputProps {
	onImageChange?: (value: string) => void;
	handleDemoDeploy: () => void;
}

type Props = InputProps & DeployWizardForm & FormikProps<FormValues>;

class DockerImageFormDumb extends React.Component<Props, State> {
	state = {
		disabled: false,
	};

	handleSubmit = async (e: any) => {
		e.preventDefault();
		const { props } = this;

		if (props.isSubmitting) return;
		props.setSubmitting(true);
		this.setDisabled(true);

		const validateErrors = await props.validateForm();
		if (Object.keys(validateErrors).length) {
			this.setDisabled(false);
			props.setSubmitting(false);
			return props.handleSubmit();
		}

		const { image, tag } = props.values;
		this.setDisabled(true);
		let pullErrorText, imageValidationStatus;
		try {
			const response = await validateImageRequest(image, tag);

			imageValidationStatus = response.status;
			if (imageValidationStatus === ImageValidationStatus.SUCCESS) {
				this.setDisabled(false);
				props.setSubmitting(false);
				return props.handleSubmit();
			} else {
				pullErrorText = getImageValidationErrText(imageValidationStatus);
			}
		} catch (e) {
			pullErrorText = `Something went wrong, error: ${e.message}. Try again later.`;
		}

		const validateImageErrors = { image: pullErrorText, tag: pullErrorText };
		props.setErrors(validateImageErrors);
		props.setTouched(this.convertErrorsToTouched(validateImageErrors), false);
		this.setDisabled(false);
		props.setSubmitting(false);
	};

	convertErrorsToTouched = (errors: Record<string, string>): Record<string, boolean | undefined> => {
		return Object.entries(errors).reduce((acc, entry) => {
			const [key, value] = entry as [string, string];
			acc[key] = !!value;
			return acc;
		}, {} as Record<string, boolean | undefined>);
	};

	setDisabled = (disabled: boolean) => {
		this.setState({ disabled }, () => this.props.onDisable?.(this.state.disabled));
	};

	handleChange = (e: any) => {
		this.props.handleChange(e);
		if (this.props.onImageChange) this.props.onImageChange(e.target.value);
	};
	demoBtnClick = (e: any) => {
		e.preventDefault();
		const { handleDemoDeploy, setFieldValue } = this.props;
		Object.entries(dockerImageStepMeta.demoValues).forEach(([key, value]) => {
			setFieldValue(key, value);
		});
		handleDemoDeploy();
	};

	render() {
		const { errors, touched, values, handleChange, formId, handleBlur } = this.props;
		const image = values['image'];
		return (
			<Form onSubmit={this.handleSubmit} id={formId} className={styles.dockerImageForm}>
				<FormLayout rowGap={14}>
					<Row>
						<Col type="label">
							<LabelWithTooltip
								inputId="docker-image"
								labelText="Docker Image"
								iconComponent={
									<IconWithTooltip
										imageSrc={iconInfo}
										tooltipText={
											'The path to your Docker image. By default we pull from Docker Hub, unless a different registry is specified. e.g appfleet/http-headers or quay.io/dockercloud/hello-world'
										}
									/>
								}
							/>
						</Col>
						<Col
							type="input"
							subContent={<InputErrorText errorText={touched['image'] ? errors['image'] : ''} />}
						>
							<>
								<BasicInput
									id="docker-image"
									placeholder="appfleet/http-headers"
									name="image"
									value={image}
									onChange={this.handleChange}
									onBlur={handleBlur}
									withErrorIcon={!!image}
									icon
									error={touched['image'] && !!errors['image']}
									setFieldValue={this.props.setFieldValue}
								>
									<input />
									{!image ? (
										<AppButton type="button" className={styles.demoBtn} onClick={this.demoBtnClick}>
											Deploy demo
										</AppButton>
									) : null}
								</BasicInput>
							</>
						</Col>
					</Row>
					<Row>
						<Col type="label">
							<InputLabel htmlFor="tag" text="Tag" />
						</Col>
						<Col
							type="input"
							subContent={<InputErrorText errorText={touched['tag'] ? errors['tag'] : ''} />}
						>
							<BasicInput
								id="tag"
								name="tag"
								placeholder="latest"
								value={values['tag']}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched['tag'] && !!errors['tag']}
								setFieldValue={this.props.setFieldValue}
							/>
						</Col>
					</Row>
				</FormLayout>
			</Form>
		);
	}
}

const defaultValues = { image: '', tag: 'latest' };

export const DockerImageForm = withFormik<DeployWizardForm & InputProps, any>({
	mapPropsToValues: ({ initialValues }) => initialValues || defaultValues,
	handleSubmit: (values, { props: componentProps }) => {
		componentProps.onSubmit(values);
	},
	validationSchema: dockerImageSchema,
})(DockerImageFormDumb);
