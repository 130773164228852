import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import clustSettStyles from '../../styles.module.scss';
import { compareValues } from '@app/utils/compareValues';
import { AppTable } from '@app/shared/AppTable';
import { SortingCtrls } from '@app/shared/sortingCtrls';
import { CustomModal } from '@app/shared/customModal';
import { AddDomain } from '../addDomain';
import { DomainTableItem } from './DomainTableItem';

export enum ClusterDomainStatus {
	// something went wrong
	FAILED = 'failed',
	// waiting for the node and dns propagation
	PENDING = 'pending',
	// certificate is being issued / waiting for Let's Encrypt
	ISSUING = 'issuing',
	// installing certificate to the node
	APPLYING = 'applying',
	// certificate installed and won't expire soon - nothing to do
	VALID = 'valid',
}

export interface ClusterDomain {
	id: number;
	name: string;
	status: string;
	createdAt: string | null;
	expireAt: string | null;
	isDnsTarget?: boolean;
}

interface DomainsTableProps {
	clusterDomains: ClusterDomain[];
	clusterName: string;
	httpsPort: number | null;
	dnsTargetName: string;
	isReadonlyCluster: boolean;
}

export const DomainsTable: React.FC<DomainsTableProps> = ({
	clusterDomains,
	clusterName,
	httpsPort,
	dnsTargetName,
	isReadonlyCluster,
}) => {
	const [itemsList, setItemsList] = useState<ClusterDomain[]>([]);
	// with service domain added if https is enabled
	const [itemsToRender, setItemsToRender] = useState<ClusterDomain[]>([]);
	const [showModal, setShowModal] = useState(false);

	// set initial itemsList
	useEffect(() => {
		setItemsList(clusterDomains);
	}, [clusterDomains]);

	// add serviceDomain if https is enabled
	useEffect(() => {
		const updItemsList = [...itemsList];
		const serviceDomainAdded = itemsList.find(item => item.id === -1);
		if (!!httpsPort && !serviceDomainAdded) {
			updItemsList.unshift({
				id: -1,
				name: dnsTargetName,
				status: ClusterDomainStatus.VALID,
				createdAt: null,
				expireAt: null,
				isDnsTarget: true,
			});
		}
		if (updItemsList.length) {
			setItemsToRender(updItemsList);
		}
	}, [itemsList, dnsTargetName, httpsPort]);

	// sortings should affect only itemsList, serviceDomain always should be first in the table
	const sortByField = (key: string, order?: 'asc' | 'desc') => () => {
		const sortedList = [...itemsList].sort(compareValues({ key, order }));
		setItemsList(sortedList);
	};

	return (
		<AppTable className={styles.domainTable}>
			<AppTable.Head>
				<AppTable.Row>
					<AppTable.Col>
						<span>Domain name</span>
						<SortingCtrls sortAsc={sortByField('name')} sortDesc={sortByField('name', 'desc')} />
					</AppTable.Col>

					<AppTable.Col>
						<span>Private Port</span>
					</AppTable.Col>

					<AppTable.Col>
						<span>Expiration Date</span>
						<SortingCtrls sortAsc={sortByField('expireAt')} sortDesc={sortByField('expireAt', 'desc')} />
					</AppTable.Col>

					<AppTable.Col>
						<span>Status</span>
						<SortingCtrls sortAsc={sortByField('status')} sortDesc={sortByField('status', 'desc')} />
					</AppTable.Col>

					<AppTable.Col>
						<span />
					</AppTable.Col>
				</AppTable.Row>
			</AppTable.Head>

			<AppTable.Body>
				{itemsToRender.map(item => (
					<DomainTableItem
						key={item.id}
						clusterName={clusterName}
						item={item}
						httpsPort={httpsPort}
						isReadonlyCluster={isReadonlyCluster}
					/>
				))}
			</AppTable.Body>

			<AppTable.Footer>
				<AppTable.Row className={styles.domainTable__footerRow}>
					<AppTable.Col>
						<Button
							className={classnames(
								'btn-three',
								clustSettStyles.clusterSettings__content_wrapped__body__blockHead__btn,
								clustSettStyles.clusterSettings__content_wrapped__body__blockHead__btn_addDomain
							)}
							onClick={!httpsPort || isReadonlyCluster ? () => {} : () => setShowModal(true)}
							disabled={!httpsPort || isReadonlyCluster}
						>
							{'Add a domain'}
						</Button>
					</AppTable.Col>

					<AppTable.Col>
						<div className={styles.domainTable__footerRow__mock_one} />
					</AppTable.Col>

					<AppTable.Col>
						<div className={styles.domainTable__footerRow__mock_two} />
					</AppTable.Col>

					<AppTable.Col />
					<AppTable.Col />
				</AppTable.Row>
			</AppTable.Footer>

			{showModal ? (
				<CustomModal>
					<AddDomain onDismiss={() => setShowModal(false)} dnsTargetName={dnsTargetName} />
				</CustomModal>
			) : null}
		</AppTable>
	);
};
