import { PermissionsRole } from '@app/modules/cluster/components/clusterSettings/clusterSettingsPermissions';
import { MetaError } from '@app/types/metaError';

export interface AccountInfo {
	id: number;
	publicId: string;
	refererrer: any;
	createdAt: string;
	updatedAt: string;
}


// contains permitted for current LoggeIn user permissions
export interface UserPermissions {
	// for now only for Cluster
	"Cluster": number[];
}

export interface UserInfo {
	accountId: number;
	active: boolean;
	color: null;
	createdAt: string;
	email: string;
	firstName: string;
	id: number;
	lastLogin: null;
	lastName: string;
	newEmail: string;
	updatedAt: string;
	permission: PermissionsRole;
	permissions: UserPermissions;
}

export interface AccountBillingInformationForm {
	onSubmit: (values: any) => void;
	loading: boolean;
	clearAccountBillingError: (_: any) => void;
	initialValues?: Record<string, any>;
	accountBillingError?: MetaError
}

export interface PaymentInfoObject {
	id: number;
	stripeId: string;
	brand: string;
	expMonth: string;
	expYear: number;
	fingerprint: string;
	last4: string;
	createdAt: string;
	updatedAt: string;
	country: string;
	default: boolean;
}

export type PaymentInfo = PaymentInfoObject[];

export interface AccountBillingInfo {
	stripeId: string;
	name: string;
	email: string;
	addressLine1: string;
	city: string;
	postalCode: string;
	country: string;
	vatId: string;
	createdAt: string;
	updatedAt: string;
	hasPaymentMethod: boolean;
	accountId: number;
	balance: number;
	estimatedMonthlyCharge: number;
	currentExpenses: number;
	prevPeriodExpenses: number;
	isLoaded: boolean;
	subscriptionStatus: BillingInfoSuscriptionStatus;
	subscriptionStatusReason: BillingInfoSubscriptionReason;
}

export enum BillingInfoSuscriptionStatus {
	UNPAID = 'unpaid',
	PAST_DUE = 'past_due',
	TRIAL = 'trial',
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	// only for the cient side, while we have no billing info received yet
	UNINITIALIZED = 'unitialized'
}
export type BillingInfoSubscriptionReason = BillingInfoSubscriptionReasonEnum | null;

export enum BillingInfoSubscriptionReasonEnum {
	CYCLE = "subscription_cycle",
	THRESHOLD = "subscription_threshold",
}

export enum AccountBillingCouponType {
	PERCENT = 'percent',
	AMOUNT = 'amount',
}

export interface AccounBillingCoupon {
	type: AccountBillingCouponType;
	discount: number;
	description: string;
}
