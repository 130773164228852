import { put } from 'redux-saga/effects';
import { LocationChangeAction } from 'connected-react-router';
import { CLUSTER_ACITVITY_LOG_URL, CLUSTER_URL } from '@app/modules/cluster/state/url';
import { getClusterActivityLogAction } from '../actions';

export function* locationChangeSaga(action: LocationChangeAction) {
	const matchedPage = [
		CLUSTER_URL.match(action.payload.location, true),
		CLUSTER_ACITVITY_LOG_URL.match(action.payload.location, true),
	].find(m => m.isMatched);

	if (matchedPage?.isMatched) {
		yield put(getClusterActivityLogAction({ clusterName: matchedPage.params.name }));
	}
}
