import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filesize from 'filesize';
import { setIntercomFailed } from '@app/modules/intercom-notification-disabled';
import { AppState } from '@app/reducers';
import api from "@app/apis/api";
import { getAccountSettingsUserInfo } from '@app/modules/account';

interface IntercomUserData {
	app_id?: string,
	user_id?: number,
	name?: string,
	created_at?: string,
	appfleet_public_id?: string,
	cluster_count?: number,
	account_user_count?: number,
	payment_method_exists?: boolean,
	bandwidth_used?: number,
	// email?: string,
	// unsubscribed_from_emails?: boolean,
	// temporary we don't send company name
	// company?: string,
}

export const useIntercom = () => {
	const supportk = api.getSupportk();
	const token = api.getToken();
	const {
		membersList,
		bandwidth,
		accountInfo,
		billingInfo,
		accountSettings,
		clustersList,
		isClustersListLoaded,
		isMembersListLoaded } = useSelector((state: AppState) => ({
		membersList: state.team.membersList,
		bandwidth: state.account.accountBandwidth.bandwidth,
		accountInfo: state.account.info,
		billingInfo: state.account.billingInfo,
		accountSettings: state.account.settings,
		clustersList: state.clustersList.clustersList,
		isClustersListLoaded: state.clustersList.isClustersListLoaded,
		isMembersListLoaded: state.team.isMembersListLoaded,
	}));
	const dispatch = useDispatch();
	const [intercomConnectFailed, setIntercomConnectFailed] = useState<boolean | null>(null);
	const [intercomBooted, setIntercomBooted] = useState<boolean>(false);
	const [userData, setUserData] = useState<IntercomUserData>({
		app_id: process.env.REACT_APP_INTERCOM_ID
	});

	useEffect(() => {
		if (token && !accountSettings.email) {
			dispatch(getAccountSettingsUserInfo({}));
		}
	}, [token, accountSettings.email, dispatch]);

	useEffect(() => {
		if (isMembersListLoaded) {
			setUserData((userData) => ({...userData, account_user_count: membersList.length}));
		} else {
			setUserData((userData) => {
				delete userData.account_user_count;
				return userData;
			});
		}
	}, [membersList, isMembersListLoaded]);

	useEffect(() => {
		if (bandwidth) {
			let bytes = 0;

			for (const cluster of Object.keys(bandwidth)) {
				for (const item of bandwidth[cluster]) {
					bytes += item[1];
				}
			}

			setUserData((userData) => ({...userData, bandwidth_used: Number(filesize(bytes, {exponent: 3, round: 0}).split(" ")[0]) }));
		} else {
			setUserData((userData) => {
				delete userData.bandwidth_used;
				return userData;
			});
		}
	}, [bandwidth]);

	useEffect(() => {
		if (accountInfo.createdAt && accountInfo.publicId) {
			setUserData((userData) => ({...userData,
				created_at: accountInfo.createdAt,
				appfleet_public_id: accountInfo.publicId,
			}));
		} else {
			setUserData((userData) => {
				delete userData.created_at;
				delete userData.appfleet_public_id;
				return userData;
			});
		}
	}, [accountInfo]);

	useEffect(() => {
			if (billingInfo.isLoaded === true) {
			// temporary we don't send company name
			// const company = billingInfo.name ? billingInfo.name.match(/\(([^)]+)\)/) : null;
			// ...(company && company[1] && company[1] !== null && {
			// 	'company': company[1],
			// })
			setUserData((userData) => ({
				...userData,
				payment_method_exists: billingInfo.hasPaymentMethod ?? false
			}));
		} else {
			setUserData((userData) => {
				delete userData.payment_method_exists;
				return userData;
			});
		}
	}, [billingInfo]);

	useEffect(() => {
		if (Object.keys(accountSettings).length) {
			setUserData((userData) => ({
				...userData,
				user_id: accountSettings.id,
				name: `${accountSettings.firstName} ${accountSettings.lastName}`,
			}));
		} else {
			setUserData((userData) => {
				delete userData.user_id;
				delete userData.name;
				return userData;
			});
		}
	}, [accountSettings]);

	useEffect(() => {
		if (isClustersListLoaded) {
			setUserData((userData) => ({
				...userData,
				cluster_count: clustersList.length,
			}));
		} else {
			setUserData((userData) => {
				delete userData.cluster_count;
				return userData;
			});
		}
	}, [clustersList, isClustersListLoaded]);

	useEffect(() => {
		if (!intercomConnectFailed && !supportk) {
			const w: any = window;
			const ic = w?.Intercom;

			if (typeof ic === 'function') {
				if (intercomBooted) {

					if (!(
						userData.user_id &&
						userData.name &&
						userData.created_at &&
						userData.appfleet_public_id &&
						typeof userData.payment_method_exists !== 'undefined'
					)) {
						return;
					}

					ic('update', userData);
				} else {
					setIntercomBooted(true);
					ic('boot', { app_id: process.env.REACT_APP_INTERCOM_ID });
				}
			} else {
				const d = document;
				const i: any = function() {
					i.c(arguments);
				};
				i.q = [];
				i.c = function(args: any) {
					i.q.push(args);
				};
				w.Intercom = i;
				const l = function() {
					setTimeout(function() {
						const s = d.createElement('script');
						s.type = 'text/javascript';
						s.async = true;
						s.onload = () => {
							setIntercomConnectFailed(false);
						};
						s.onerror = () => {
							setIntercomConnectFailed(true);
						};
						s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_ID}`;
						const x = d.getElementsByTagName('script')[0];
						x.parentNode?.insertBefore(s, x);
					}, 1000);
				};
				if (w.attachEvent) {
					w.attachEvent('onload', l);
				} else {
					w.addEventListener('load', l, false);
				}
			}
		}
	}, [intercomConnectFailed, intercomBooted, userData, supportk]);

	useEffect(() => {
		if (intercomConnectFailed !== null) {
			dispatch(setIntercomFailed({ failedToLoad: intercomConnectFailed }));
		}
	}, [intercomConnectFailed, dispatch]);

	return { intercomConnectFailed };
};
