import { defineAction } from 'rd-redux-utils';
import { IErrorMessage } from '@app/shared/errorMessage';
import { Registry } from '../components';
import { EditRegistryValues } from '../components/editRegistry';

// GET REGISTRIES
export const getRegistries = defineAction('REGISTRIES: GET LIST');
export const setRegistries = defineAction<{ registries: Registry[] }>('REGISTRIES: SET LIST');
export const setRegistriesError = defineAction<{ error: IErrorMessage; snack?: boolean }>('REGISTRIES: SET ERROR');
// CLEAR STATE
export const clearRegistriesError = defineAction('REGISTRIES: CLEAR ERROR');

// DELETE REGISTRY
export const deleteRegistry = defineAction<{ id: number; handleResponse: () => void }>('REGISTRIES: DELETE ITEM');

// EDIT REGISTRY
export const editRegistry = defineAction<{ values: EditRegistryValues; done: (success: boolean) => void }>(
	'REGISTRIES: EDIT ITEM'
);
