import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import { RemoveItem } from '@app/shared/removeItem/RemoveItem';
import { SvgIcon } from '@app/shared/SvgIcon/SvgIcon';
import { removeDomain } from '@app/modules/cluster';
import { ClusterDomain } from './DomainsTable';

interface DomainsRemoveCellProps {
	iconSrc: string;
	domain: ClusterDomain;
	clusterName: string;
}

export const DomainTableItemRemoveCell: React.FC<DomainsRemoveCellProps> = ({ iconSrc, domain, clusterName }) => {
	const [isShow, setIsShow] = React.useState(false);
	const dispatch = useDispatch();

	return (
		<div className={styles.domainRemoveCell}>
			<SvgIcon iconSrc={iconSrc} onClick={() => setIsShow(true)} />

			{isShow ? (
				<RemoveItem
					htmlMsg={
						<p>
							Are you sure you want to remove <b>{domain.name}</b> from this cluster? This action will
							also remove all related SSL certificates.
						</p>
					}
					msg={`Are you sure you want to remove ${domain.name} from this cluster? This action will also remove all related SSL certificates.`}
					onDismiss={() => setIsShow(false)}
					onAgree={() =>
						dispatch(
							removeDomain({
								clusterName,
								domainId: domain.id,
								handleResponse: () => setIsShow(false),
							})
						)
					}
					btnAgreeText="Remove"
					btnDismissText="Keep"
				/>
			) : null}
		</div>
	);
};
