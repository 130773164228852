import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '@app/shared/SvgIcon';

interface Props {
	iconSrc?: string;
	title: string | JSX.Element;
	price: string | JSX.Element;
	className?: string;
}

export const PriceBar = (props: Props) => {
	const { iconSrc, title, price, className } = props;

	return (
		<div className={classnames(styles.priceBar, className)}>
			{iconSrc && (
				<div className={styles.iconWrapper}>
					<SvgIcon iconSrc={iconSrc} className={styles.icon} />
				</div>
			)}
			<div className={styles.titleWrapper}>
				{typeof title === 'string' ? <span className={styles.titleText}>{title}</span> : title}
			</div>
			<div className={styles.priceWrapper}>
				{typeof price === 'string' ? <span className={styles.priceText}>{price}</span> : price}
			</div>
		</div>
	);
};
