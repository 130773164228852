import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { withFormik, FormikProps, Form } from 'formik';
import { Button, Checkbox } from 'semantic-ui-react';
import { AppState } from '@app/reducers';
import { createAccount, clearAccountError, createUser } from '../../state';
import { ErrorMessage } from '@app/shared/errorMessage';
import ValidationSchema from './validation-schema';
import { navigateTo } from '@app/utils/navigate-to';
import { SIGNIN_URL } from '../../../user-sign-in';
import { LabeledInputRegular } from '@app/shared/labeledInputRegular';
import { PasswordTooltip } from '../passwordTooltip';
import { PasswordProgress } from '@app/shared/passwordProgress';
import { PageHeader } from '@app/shared/pageHeader';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { BtnProgress } from '@app/shared/btnProgress';
import { MetaError } from '@app/types/metaError';
import { USER_SIGNUP_URL } from '../../state/url';
import moment from 'moment';

export interface AccountValues {
	password: string;
	repeatPassword?: string;
	firstName: string;
	lastName: string;
	email?: string;
	companyName?: string;
	termsAccepted?: boolean;
	inviteId?: string;
}

interface AccountComponentProps {
	error: MetaError;
	createAccount: ({ values, onSuccess }: { values: AccountValues; onSuccess: () => void }) => void;
	createUser: ({ values, onFinal }: { values: AccountValues; onFinal: () => void }) => void;
	clearAccountError: (_: any) => void;
}
interface HashParams {
	e: string;
	x: string;
}
interface UrlParams {
	inviteId: string;
	hash: string;
}

const AccountComponent: React.FC<AccountComponentProps & FormikProps<AccountValues>> = ({
	values,
	error,
	errors,
	handleChange,
	handleSubmit,
	handleBlur,
	touched,
	isSubmitting,
	clearAccountError,
	isValid,
	setFieldValue,
}) => {
	const { firstName, lastName, email, password, repeatPassword, companyName, termsAccepted, inviteId } = values;
	const [readyToSubmit, setReadyToSubmit] = useState(true);
	const [isLinkExpired, setIsLinkExpired] = useState(false);
	const [handledErr, setHandledErr] = useState<MetaError | null>(null);

	const location = useSelector((state: AppState) => {
		return state.router.location;
	});

	useEffect(() => {
		const urlMatch = USER_SIGNUP_URL.match(location, true);
		if (urlMatch.isMatched) {
			const { inviteId, hash } = urlMatch.params as UrlParams;
			setFieldValue('inviteId', inviteId);
			// try to parse, it is real data hash then set values
			try {
				const { e: email, x: expiresAt } = JSON.parse(window.atob(hash)) as HashParams;
				const presentDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

				setIsLinkExpired(moment(expiresAt).isBefore(presentDate));
				setFieldValue('email', email);
				setReadyToSubmit(true);
			} catch (e) {
				// otherway disable submit possibility
				setReadyToSubmit(false);
			}
		}
	}, [location, setFieldValue]);

	useEffect(() => {
		if (isLinkExpired) {
			setHandledErr(new MetaError({ message: 'Registration link has already expired' }));
		}
	}, [isLinkExpired]);

	useEffect(() => {
		if (error) {
			setHandledErr(error);
		} else {
			setHandledErr(null);
		}
	}, [error]);

	useEffect(() => {
		return () => {
			clearAccountError({});
		};
	}, [clearAccountError]);

	return (
		<div className={styles.account}>
			<PageHeader
				btnComponent={
					<Button className="btn-one btn-login" onClick={navigateTo(SIGNIN_URL.urlTemplate)}>
						Log in
					</Button>
				}
				className={styles.account__header}
			/>
			<div className={styles.account__logo_mobile}>
				<div />
			</div>

			<div className={classnames(styles.accountContent, 'firstContnentElem')}>
				<div className={styles.accountContentTitle}>
					<div className={styles.accountContentTitleHeader}>
						Create your free account and get $10 on your balance
					</div>
					<div
						className={classnames(
							styles.accountContentTitleHeader,
							styles.accountContentTitleHeader_mobile
						)}
					>
						Create an account
					</div>
					<div className={styles.accountContentTitleSubheader}>
						<span>
							Complete our quick sign-up process below and start deploying your globally distributed web
							services in seconds!
						</span>
					</div>

					<div
						className={classnames(
							styles.accountContentTitleSubheader,
							styles.accountContentTitleSubheader_mobile
						)}
					>
						<span>Get your appfleet account up and start deploying</span>
					</div>
				</div>

				<Form onSubmit={handleSubmit} className={styles.accountContentForm}>
					<div className={styles.accountContentFormPair}>
						<LabeledInputRegular
							handleChange={handleChange}
							handleBlur={handleBlur}
							inputValue={firstName}
							inputName="firstName"
							inputPlaceholder="Richard"
							errorMsg={handledErr?.get('firstName') || errors.firstName}
							isTouched={touched.firstName}
							labelText="First name"
							setFieldValue={setFieldValue}
						/>
						<LabeledInputRegular
							handleChange={handleChange}
							handleBlur={handleBlur}
							inputValue={lastName}
							inputName="lastName"
							inputPlaceholder="Hendriks"
							errorMsg={handledErr?.get('lastName') || errors.lastName}
							isTouched={touched.lastName}
							labelText="Last name"
							setFieldValue={setFieldValue}
						/>
					</div>
					<div className={styles.accountContentFormPair}>
						<LabeledInputRegular
							handleChange={handleChange}
							handleBlur={handleBlur}
							inputValue={companyName}
							inputName="companyName"
							inputPlaceholder="Pied Piper (optional)"
							errorMsg={handledErr?.get('companyName') || errors.companyName}
							isTouched={touched.companyName}
							labelText="Company name"
							autoComplete="organization"
							disabled={!!inviteId}
							setFieldValue={setFieldValue}
						/>
						<LabeledInputRegular
							handleChange={handleChange}
							handleBlur={handleBlur}
							inputValue={email}
							inputName="email"
							inputPlaceholder="r@piedpiper.com"
							errorMsg={handledErr?.get('email') || errors.email}
							isTouched={touched.email}
							labelText="Email"
							disabled={!!inviteId}
							setFieldValue={setFieldValue}
						/>
					</div>
					<div className={styles.accountContentFormPair}>
						<LabeledInputRegular
							autoComplete={'new-password'}
							inputType="password"
							handleChange={handleChange}
							handleBlur={handleBlur}
							inputValue={password}
							inputName="password"
							inputPlaceholder="Password"
							errorMsg={handledErr?.get('password') || errors.password}
							isTouched={touched.password}
							labelText="Password"
							iconComponent={<PasswordTooltip />}
							setFieldValue={setFieldValue}
							detailComponent={
								<PasswordProgress
									className={styles.accountContentFormPair__passwordProgress}
									password={password}
									errorMsg={handledErr?.get('password') || errors.password}
									isTouched={touched.password}
								/>
							}
						/>
						<LabeledInputRegular
							autoComplete={'new-password'}
							inputType="password"
							handleChange={handleChange}
							handleBlur={handleBlur}
							inputValue={repeatPassword}
							inputName="repeatPassword"
							inputPlaceholder="Password"
							errorMsg={handledErr?.get('repeatPassword') || errors.repeatPassword}
							isTouched={touched.repeatPassword}
							labelText="Repeat password"
							setFieldValue={setFieldValue}
						/>
					</div>

					<div className={styles.accountContentFormService}>
						<div className={styles.accountContentFormServiceTerms}>
							<div
								className={classnames({
									[styles.accountContentFormServiceTerms_checked]: termsAccepted,
								})}
							>
								<Checkbox
									name="termsAccepted"
									checked={termsAccepted}
									onChange={() => setFieldValue('termsAccepted', !termsAccepted)}
								/>
								<span onClick={() => setFieldValue('termsAccepted', !termsAccepted)}>
									I accept appfleet’s{' '}
								</span>

								<span
									onClick={() => window.open('https://appfleet.com/terms-of-service', '_blank')}
									className={styles.termsLink}
								>
									Terms & Conditions
								</span>
							</div>
						</div>

						<div className={styles.accountContentFormServiceError}>
							<ErrorMessage
								error={handledErr || { message: '' }}
								withinPortal={false}
								useDefaultMsg={false}
							/>
						</div>
					</div>

					<div className={styles.accountContentFormCtrls}>
						<BtnProgress
							className={styles.accountContentFormCtrls__createBtn}
							isSubmitting={isSubmitting}
							text="Create A Free Account"
							isDisabled={!(isValid && termsAccepted && readyToSubmit && !isLinkExpired)}
						/>
						<BtnProgress
							className={styles.accountContentFormCtrls__createBtn_mobile}
							isSubmitting={isSubmitting}
							text="Create an account"
							isDisabled={!(isValid && termsAccepted && readyToSubmit && !isLinkExpired)}
						/>
					</div>
				</Form>
			</div>
		</div>
	);
};

export const Account = compose<AccountComponentProps & FormikProps<AccountValues>, {}>(
	connect(
		(state: AppState) => {
			return {
				error: state.account.error,
			};
		},
		{ createAccount, clearAccountError, createUser }
	),
	withFormik({
		mapPropsToValues: () => ({
			email: '',
			password: '',
			repeatPassword: '',
			lastName: '',
			firstName: '',
			companyName: '',
			termsAccepted: false,
			inviteId: '',
		}),
		validationSchema: ValidationSchema,

		handleSubmit: (values, { setSubmitting, props }) => {
			const reqValues: {
				firstName: string;
				lastName: string;
				password: string;
				email?: string;
				inviteId?: string;
				companyName?: string;
			} = {
				firstName: values.firstName,
				lastName: values.lastName,
				password: values.password,
			};

			if (values.inviteId) {
				reqValues.inviteId = values.inviteId;
				(props as AccountComponentProps).createUser({
					values: reqValues,
					onFinal: () => setSubmitting(false),
				});
			} else {
				reqValues.companyName = values.companyName.trim() || undefined;
				reqValues.email = values.email;
				(props as AccountComponentProps).createAccount({
					values: reqValues,
					onSuccess: () => setSubmitting(false),
				});
			}
		},

		displayName: 'Account',
	})
)(AccountComponent);
