import { call, take, put } from "redux-saga/effects";
import { enableHttpsPortApi } from "../api";
import { enableHttpsPort, enableHttpsPortSucceed, enableHttpsPortFailed } from '../actions';
import { MetaError } from '@app/types/metaError';

export function* watchEnableHttpsPort() {
	while (true) {
		const action = yield take("*");
		if (enableHttpsPort.is(action)) {
			yield call(enableHttpsPortWorker, { httpsPort: action.httpsPort, clusterName: action.clusterName, onSuccess: action.onSuccess });
		}
	}
}

function* enableHttpsPortWorker({ httpsPort, clusterName, onSuccess }: { httpsPort: number; clusterName: string; onSuccess: () => void }) {
	try {
		const result = yield call(enableHttpsPortApi, { httpsPort, clusterName });
		yield put(enableHttpsPortSucceed({ currentVersion: result }));
		yield call(onSuccess);
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(enableHttpsPortFailed({ error: new MetaError(meta ? { message: e.message } : e, meta) }));
	}
}
