import React from 'react';
import { withFormik, FormikProps, Form } from 'formik';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import classnames from 'classnames';
import { compose } from 'recompose';
import styles from './styles.module.scss';
import globalStyles from '@app/styles/global.module.scss';
import { CustomModal } from '@app/shared/customModal';
import { AppState } from '@app/reducers';
import { SvgIcon } from '@app/shared/SvgIcon';
import { MetaError } from '@app/types/metaError';
import buyMoreSeats from '@icons/buy.more.seats.svg';
import increase from '@icons/icon-16-add-dark.svg';
import decrease from '@icons/icon-16-substract-dark.svg';
import iconClose from '@icons/icon.close.svg';
import { saveAccountSeats, clearAccountSeatsError, setAccountSeatsError } from '../../state/actions';
import { FREE_SEATS_AMOUNT } from '../../state/reducer';

export interface BuyNewSeatsValues {
	totalSeatsAmount: number;
	tax: number;
	totalDue: number;
	initialSeats: number;
}

interface BuyNewSeatsProps {
	seatsError: MetaError;
	onDismiss: () => void;
	membersAmount: number;
	initialValues: any;
	saveAccountSeats: ({
		totalSeatsAmount,
		onSuccess,
		onFinal,
	}: {
		totalSeatsAmount: number;
		onSuccess: () => void;
		onFinal: () => void;
	}) => void;
	clearAccountSeatsError: (_: any) => void;
	setAccountSeatsError: ({ error }: { error: MetaError }) => void;
}

const BuyNewSeatsComponent: React.FC<BuyNewSeatsProps & FormikProps<BuyNewSeatsValues>> = props => {
	const {
		values,
		handleSubmit,
		isSubmitting,
		onDismiss,
		setFieldValue,
		seatsError,
		clearAccountSeatsError,
		membersAmount,
		setAccountSeatsError,
	} = props;
	const { totalDue, tax, totalSeatsAmount, initialSeats } = values;
	const [isAdding, setIsAdding] = React.useState(true);

	// update totalDue, check whether removing flow or adding flow
	React.useEffect(() => {
		if (totalSeatsAmount >= initialSeats) {
			setIsAdding(true);
		} else {
			setIsAdding(false);
		}
		setFieldValue('totalDue', (totalSeatsAmount - FREE_SEATS_AMOUNT) * tax);
	}, [totalSeatsAmount, initialSeats, tax, setFieldValue]);

	// handle closing BuyNewSeats
	React.useEffect(() => {
		return () => clearAccountSeatsError({});
	}, [clearAccountSeatsError]);

	const handleSeatsAmountChange = (type: 'dec' | 'inc') => () => {
		let seatsAmount = totalSeatsAmount;

		if (type === 'dec') {
			seatsAmount -= 1;
			if (seatsAmount < membersAmount && seatsAmount >= FREE_SEATS_AMOUNT) {
				setAccountSeatsError({ error: new MetaError({ message: 'You can not have less seats than members' }) });
			} else if (seatsAmount < FREE_SEATS_AMOUNT) {
				seatsAmount = FREE_SEATS_AMOUNT;
			}
		} else if (type === 'inc') {
			seatsAmount += 1;
			if (seatsError.message && seatsAmount >= membersAmount) {
				clearAccountSeatsError({});
			}
		}
		setFieldValue('totalSeatsAmount', seatsAmount);
	};

	return (
		<CustomModal>
			<div className={styles.buySeats}>
				<SvgIcon iconSrc={buyMoreSeats} className={styles.buySeats__icon} />

				<SvgIcon
					iconSrc={iconClose}
					className={classnames(styles.buySeats__close, { [styles.hidden]: isSubmitting })}
					onClick={() => {
						onDismiss();
					}}
				/>

				<div className={styles.buySeats__header}>{'Buy or Remove seats'}</div>
				<div className={styles.buySeats__description}>
					{'Manage your team access to appfleet by buying additional seats to invite your colleagues'}
				</div>

				{seatsError.message ? (
					<div className={styles.buySeats__seatsError}>{seatsError.message}</div>
				) : (
					<div className={styles.buySeats__infoPanel}>{'Charged monthly per seat'}</div>
				)}

				<Form onSubmit={handleSubmit}>
					<div className={styles.buySeats__addSeats}>
						<span>{'Edit seat number'}</span>
						<div className={styles.buySeats__addSeats__counter}>
							<div
								className={classnames(
									globalStyles.noTextSelection,
									styles.buySeats__addSeats__counter__dec
								)}
								onClick={handleSeatsAmountChange('dec')}
							>
								<SvgIcon iconSrc={decrease} />
							</div>

							<div className={styles.buySeats__addSeats__counter__amount}>{totalSeatsAmount}</div>

							<div
								className={classnames(
									globalStyles.noTextSelection,
									styles.buySeats__addSeats__counter__inc
								)}
								onClick={handleSeatsAmountChange('inc')}
							>
								<SvgIcon iconSrc={increase} />
							</div>
						</div>
					</div>

					<div className={styles.buySeats__subHeader}>{'Summary'}</div>

					<div className={styles.buySeats__summaryTable}>
						<div className={styles.buySeats__summaryTable__seats}>
							<span>{`Seats (${FREE_SEATS_AMOUNT} included)`}</span>
							<span>{`${totalSeatsAmount - FREE_SEATS_AMOUNT}`}</span>
						</div>
						<div className={styles.buySeats__summaryTable__tax}>
							<span>{'Price per seat'}</span>
							<span>{`$${tax.toFixed(2)}`}</span>
						</div>
						<div className={styles.buySeats__summaryTable__totalDue}>
							<span>{'Total per month'}</span>
							<span>{`$${totalDue.toFixed(2)}`}</span>
						</div>
					</div>

					{isAdding ? (
						<Button
							type="submit"
							className={classnames('btn-one', styles.buySeats__addSeatsBtn)}
							disabled={isSubmitting || totalSeatsAmount === initialSeats}
						>
							{'Add the seats'}
						</Button>
					) : (
						<Button
							type="submit"
							className={classnames('btn-six', styles.buySeats__rmSeatsBtn)}
							disabled={isSubmitting || totalSeatsAmount < membersAmount}
						>
							{'Remove seats'}
						</Button>
					)}
				</Form>
			</div>
		</CustomModal>
	);
};

export const BuyNewSeats = compose<
	BuyNewSeatsProps & FormikProps<BuyNewSeatsValues>,
	{ onDismiss: () => void; membersAmount: number }
>(
	connect(
		(state: AppState, ownProps: { onDismiss: () => void; membersAmount: number }) => ({
			seatsError: state.team.accountSeats.error || {},
			initialValues: state.team.accountSeats,
			onDismiss: ownProps.onDismiss,
			membersAmount: ownProps.membersAmount,
		}),
		{ saveAccountSeats, clearAccountSeatsError, setAccountSeatsError }
	),
	withFormik({
		mapPropsToValues: ({
			initialValues,
		}: {
			initialValues: { totalSeatsAmount: number; tax: number; totalDue: number };
		}) => {
			return {
				totalSeatsAmount: initialValues.totalSeatsAmount,
				initialSeats: initialValues.totalSeatsAmount,
				tax: initialValues.tax,
				totalDue: initialValues.totalDue,
			};
		},

		handleSubmit: (values, { props, setSubmitting }) => {
			setSubmitting(true);
			(props as BuyNewSeatsProps).saveAccountSeats({
				totalSeatsAmount: values.totalSeatsAmount - FREE_SEATS_AMOUNT,
				onSuccess: (props as BuyNewSeatsProps).onDismiss,
				onFinal: () => setSubmitting(false),
			});
		},
		displayName: 'BuyNewSeats',
	})
)(BuyNewSeatsComponent);
