import * as React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';
import { Content } from './components/Content';
import { Head } from './components/Head';
import { Title } from './components/Title';

interface Props {
	className?: string;
	children: any;
}

export function ClusterPageLayout(props: Props) {
	const { className, children } = props;

	return <div className={classnames(styles.clusterPageLayout, className)}>{children}</div>;
}

ClusterPageLayout.Head = Head;
ClusterPageLayout.Title = Title;
ClusterPageLayout.Content = Content;
