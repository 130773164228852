import React from 'react';
import styles from './styles.module.scss';

interface FakeInput {
	inputType: 'text' | 'password';
	inputName: string;
}

interface Props {
	fakeInputs: FakeInput[];
}

// Don't forget to place me on the top of the Form
export const FakeInputsToPreventAutofill: React.FC<Props> = ({ fakeInputs }) => {
	const fakeInputsRendered = fakeInputs.map((fakeInput, idx) => (
		<input
			key={idx}
			className={styles.autofillFakeInput}
			name={fakeInput.inputName}
			type={fakeInput.inputType}
			autoComplete="new-password"
		/>
	));

	return <>{fakeInputsRendered}</>;
};
