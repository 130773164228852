import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import filesize from 'filesize';
import styles from './styles.module.scss';
import { ICluster } from '../cluster';
import { ResourcesCard } from './cards/ResourcesCard';
import { ClusterUsageCard } from './cards/ClusterUsageCard';
import { NodesListCard } from './cards/NodesListCard';
import { AppState } from '@app/reducers';
import { AppButton } from '@app/shared/buttons/AppButton';
import { ApiSize } from '@app/data-modules/sizes';
import { ApiRegion } from '@app/data-modules/regions';
import { changePlanWizardModalApi, addNodesWizardModalApi } from '@app/modals';
import { ClusterPageLayout } from '../layouts/ClusterPageLayout';
import { ComposedMetric } from '@app/data-modules/cluster-metrics';
import { NodeLocationsCard } from '../shared/NodeLocationsCard';
import { useBillingInfoSubscriptionStatus } from '@app/hooks';
import { ErrorMessage } from '@app/shared/errorMessage';
import { clearClusterNodesErrorAction } from '@app/data-modules/cluster-nodes/actions';
import { ApiNode } from '@app/data-modules/cluster-nodes';
import { ClusterMetric } from '@app/data-modules/cluster-metrics';

interface InputProps {
	cluster: ICluster;
	isReadonlyCluster: boolean;
}

const AddNewBtnWithSubscribeHook: React.FC<{ isReadonlyCluster: boolean }> = ({ isReadonlyCluster }) => {
	const { subscriptionValid: valid } = useBillingInfoSubscriptionStatus();
	return (
		<AppButton
			theme={AppButton.themes.action}
			onClick={!valid || isReadonlyCluster ? () => {} : addNodesWizardModalApi.openModal}
			disabled={!valid || isReadonlyCluster}
		>
			Add a new node
		</AppButton>
	);
};

export const ClusterNodes: React.FC<InputProps> = ({ cluster, isReadonlyCluster }) => {
	const { isSizesLoaded, regions, sizes, metric, error, isRegionsLoaded, nodesMap } = useSelector(
		(state: AppState) => {
			return {
				regions: state.regions.regions,
				isRegionsLoaded: state.regions.isLoaded,
				sizes: state.sizes.sizes,
				isSizesLoaded: state.sizes.isLoaded,
				metric: state.metric,
				error: state.clusterNodes.error,
				nodesMap: state.clusterNodes.nodesMap,
			};
		}
	);
	const dispatch = useDispatch();
	const [handledRegions, setHandledRegions] = useState<ApiRegion[]>([]);
	const [handledNodes, setHandledNodes] = useState<ApiNode[]>([]);
	const [handledSize, setHandledSize] = useState<ApiSize>({} as ApiSize);
	const [bandwidthVal, setBandwidthVal] = useState<string>('');
	const [bandwidthMeasurement, setBandwidthMeasurement] = useState<string>('');
	const [isNodesLoaded, setIsNodesLoaded] = useState(false);
	const [price, setPrice] = useState(0);
	const [monthlyPrice, setMonthlyPrice] = useState(0);
	const [latestMetric, setLatestMetric] = useState<ClusterMetric['latest']>({
		isLoaded: false,
		lastUpdate: null,
		metric: null,
	});

	const getClusterBandwidth = (metric: ComposedMetric): number => {
		return Object.values(metric).reduce((acc, metricData) => {
			if (!Object.keys(metricData).length) return acc;
			return acc + (metricData['net.bandwidth'] || 0);
		}, 0);
	};

	// handle metrics
	useEffect(() => {
		if (cluster.name && metric[cluster.name] && metric[cluster.name].latest) {
			setLatestMetric(metric[cluster.name].latest);
		}
	}, [cluster.name, metric]);

	// handle is Nodes loaded
	useEffect(() => {
		if (cluster.name && nodesMap[cluster.name]) {
			setIsNodesLoaded(nodesMap[cluster.name].isLoaded);
		}
	}, [cluster.name, nodesMap]);

	// handle nodes
	useEffect(() => {
		if (cluster.name && isNodesLoaded) {
			setHandledNodes(nodesMap[cluster.name].nodes);
		}
	}, [cluster.name, nodesMap, isNodesLoaded]);

	// handle regions
	useEffect(() => {
		if (isRegionsLoaded && isNodesLoaded) {
			let handledRegions = [] as ApiRegion[];

			handledNodes.forEach(node => {
				regions.forEach(region => {
					if (region.code === node.region) {
						handledRegions.push(region);
					}
				});
			});

			setHandledRegions(handledRegions);
		}
	}, [isRegionsLoaded, isNodesLoaded, regions, handledNodes]);

	// clear error on unmount
	useEffect(() => {
		return () => {
			dispatch(clearClusterNodesErrorAction({}));
		};
	}, [dispatch]);

	// handle bandwidth val and measurement
	useEffect(() => {
		if (latestMetric.isLoaded) {
			const value = getClusterBandwidth(latestMetric.metric || {});
			const [data, measurement] = filesize(value, { round: 1 }).split(' ');
			setBandwidthVal(data);
			setBandwidthMeasurement(measurement);
		}
	}, [latestMetric]);

	// handle size, price, monthlyPrice
	useEffect(() => {
		if (cluster.size) {
			const size = sizes.find(size => size.code === cluster.size);
			if (size) {
				setHandledSize(size);
				setPrice(size.price);
				setMonthlyPrice(size.monthlyPrice);
			}
		}
	}, [cluster.size, sizes]);

	return (
		<ClusterPageLayout>
			<ClusterPageLayout.Head
				className={styles.clusterNodes__pageHead}
				titleSection={
					<div>
						<ErrorMessage
							useDefaultMsg={false}
							error={{ message: error?.message || '' }}
							withinPortal={false}
							className={styles.clusterNodes__errorMessage}
						/>
						<ClusterPageLayout.Title text="Resources" />
					</div>
				}
				actionSection={<AddNewBtnWithSubscribeHook isReadonlyCluster={isReadonlyCluster} />}
			/>
			<ClusterPageLayout.Content>
				<Grid columns={2}>
					<Grid.Column>
						<ResourcesCard
							loading={!isSizesLoaded}
							onChangePlanClick={changePlanWizardModalApi.openModal}
							size={handledSize}
						/>
					</Grid.Column>
					<Grid.Column>
						<ClusterUsageCard
							nodesAmount={handledNodes.length}
							monthlyPrice={monthlyPrice}
							hourlyPrice={price}
							bandwidth={`${bandwidthVal}${bandwidthMeasurement}`}
							loading={!isNodesLoaded || !latestMetric.isLoaded || !isSizesLoaded}
						/>
					</Grid.Column>
					<Grid.Column>
						<NodesListCard
							loading={!isNodesLoaded || !isSizesLoaded || !isRegionsLoaded}
							nodes={handledNodes}
							regions={isRegionsLoaded ? handledRegions : []}
							sizes={sizes}
							size={cluster.size}
							cluster={cluster}
							latestMetric={latestMetric}
							isReadonlyCluster={isReadonlyCluster}
						/>
					</Grid.Column>
					<Grid.Column>
						<NodeLocationsCard
							regions={isRegionsLoaded ? handledRegions : []}
							cluster={cluster}
							nodes={handledNodes}
							latestMetric={latestMetric}
							isFetching={!isRegionsLoaded || !isNodesLoaded}
						/>
					</Grid.Column>
				</Grid>
			</ClusterPageLayout.Content>
		</ClusterPageLayout>
	);
};
