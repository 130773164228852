import * as React from 'react';
import { AnchorContextProps, AnchorContext } from './context';

export function withAnchorContext<TInputProps, TComponentProps extends AnchorContextProps>(
	Component: React.ComponentType<TComponentProps>
) {
	return function(props: TInputProps) {
		const context = React.useContext(AnchorContext);

		return <Component context={context} {...(props as any)} />;
	};
}
