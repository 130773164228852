import React from 'react';
import styles from './styles.module.scss';
import { PageHeader } from '../../../../shared/pageHeader';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import { navigateTo } from 'src/utils/navigate-to';
import { useHistory } from 'react-router-dom';
import { SIGNIN_URL } from '../../../user-sign-in/state/url';

export const CheckEmail: React.FC<{}> = () => {
	const history = useHistory();
	const [email, setEmail] = React.useState('');
	const [from, setFrom] = React.useState('');
	React.useEffect(() => {
		if (history.location.state) {
			setEmail((history.location.state as { email: string; from: 'reset' | 'create-account' }).email as string);
			setFrom((history.location.state as { email: string; from: 'reset' | 'create-account' }).from as string);
		}
	}, [history.location.state]);

	return email ? (
		<div className={styles.checkEmail}>
			<PageHeader className={styles.checkEmail__header} />

			<div className={styles.checkEmailLogo} />

			<div className={styles.checkEmailContent}>
				<div className={styles.checkEmailContentHeader}>Please check your email</div>

				{from === 'create-account' ? (
					<div className={styles.checkEmailContentSubheader}>
						We’ve sent a verification link to <span className={styles.emailText}>{email}</span>. Follow the
						instructions in the email to complete your registration.
					</div>
				) : null}

				{from === 'reset' ? (
					<div className={styles.checkEmailContentSubheader}>
						Password reset email sent to <span className={styles.emailText}>{email}</span>. Follow the
						instructions in the email to complete the process.
					</div>
				) : null}
			</div>

			<div className={styles.checkEmailCtrls}>
				<Button
					className={classnames('btn-three', styles.checkEmailCtrlsHomeBtn)}
					onClick={navigateTo(SIGNIN_URL.urlTemplate)}
				>
					Go to Login
				</Button>
			</div>
		</div>
	) : null;
};
