import * as React from 'react';
import styles from './style.module.scss';
import { AppCard, Head, Title, Section, SectionContainer } from '@app/shared/AppCard';
import { AppSkeleton } from '@app/shared/AppSkeleton';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import iconInfo from '@icons/icon.info.svg';
import { formatterUSDCurrency } from '@app/utils/formatterUSDCurrency';

const TOOLTIP_TOTAL_CLUSTER_TEXT = 'Total bandwidth used by all nodes including deleted ones in the last 30 days';
interface InputProps {
	monthlyPrice: string | number;
	hourlyPrice: string | number;
	nodesAmount: number;
	bandwidth: number | string;
	loading: boolean;
}

export const ClusterUsageCard = (props: InputProps) => {
	const { bandwidth, monthlyPrice, hourlyPrice, nodesAmount, loading } = props;
	const totalPrice = formatterUSDCurrency().format((Number(monthlyPrice) * nodesAmount) / 100);
	const totalPerHour = formatterUSDCurrency().format((Number(hourlyPrice) * nodesAmount) / 100);

	return (
		<AppCard className={styles.clusterUsageCard}>
			<Head>
				<Title text="Total cluster usage" />
				<IconWithTooltip imageSrc={iconInfo} tooltipText={TOOLTIP_TOTAL_CLUSTER_TEXT} />
			</Head>
			<SectionContainer>
				<Section className={styles.clusterUsageCard__item}>
					<span className={styles.title}>per month</span>
					<span className={styles.value}>
						{loading ? <AppSkeleton className={styles.clusterUsageCard__skeleton} /> : <>{totalPrice}</>}
					</span>
				</Section>
				<Section className={styles.clusterUsageCard__item}>
					<span className={styles.title}>per hour</span>
					<span className={styles.value}>
						{loading ? <AppSkeleton className={styles.clusterUsageCard__skeleton} /> : <>{totalPerHour}</>}
					</span>
				</Section>
				<Section className={styles.clusterUsageCard__item}>
					<span className={styles.title}>nodes</span>
					<span className={styles.value}>
						{loading ? <AppSkeleton className={styles.clusterUsageCard__skeleton} /> : nodesAmount}
					</span>
				</Section>
				<Section className={styles.clusterUsageCard__item}>
					<span className={styles.title}>bandwidth</span>
					<span className={styles.value}>
						{loading ? <AppSkeleton className={styles.clusterUsageCard__skeleton} /> : bandwidth}
					</span>
				</Section>
			</SectionContainer>
		</AppCard>
	);
};
