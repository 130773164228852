import * as React from "react";
import styles from "./style.module.scss";
import classnames from "classnames";

interface Props {
    className?: string;
    rowGap: number;
    children: JSX.Element | JSX.Element[];
}

export const FormLayout = (props: Props) => {
    const { className, children, rowGap } = props;
    const gapInPx = `${rowGap}px`;

    return (
        <div className={classnames(styles.formLayout, className)} style={{ marginTop: `-${gapInPx}`, marginBottom: `-${gapInPx}` }}>
            {React.Children.map(children, (child, i) => {
                const element = React.cloneElement(child, {
                    style: {
                        paddingTop: gapInPx,
                        paddingBottom: gapInPx
                    },
                    key: `child-${i}`
                });

                return element;
            })}
        </div>
    );
};
