const measurementCutters = [
	{ prefix: 'G', limit: 1073741824, divider: 1073741824 },
	{ prefix: 'M', limit: 1048576, divider: 1048576 },
	{ prefix: 'K', limit: 1024, divider: 1024 },
];

interface CutMeasurement {
	data: string;
	measurement: string;
	digitsAfterDot?: number;
}

export const cutMeasurement = (data: string, measurement: string, digitsAfterDot: number = 2): CutMeasurement => {
	const numberData = parseFloat(data || '0');
	let result = numberData;
	for (const cutter of measurementCutters) {
		if (numberData > cutter.limit) {
			result = numberData / cutter.divider;
			measurement = cutter.prefix + measurement;
			break;
		}
	}
	return { data: result.toFixed(digitsAfterDot), measurement };
};
