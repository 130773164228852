import { call, takeEvery, all } from 'redux-saga/effects';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { TEAM_URL } from '../url';
import { getAccountMembersWorker } from './getAccountMembers';
import { getAccountSeatsWorker } from './getAccountSeats';

export function* watchGetTeamOnLocChange() {
	yield takeEvery(LOCATION_CHANGE, function* onNavigateClustersListPage(a: LocationChangeAction) {
		const urlMatch = TEAM_URL.match(a.payload.location, true);
		if (!urlMatch.isMatched) {
			return;
		}

		yield all([call(getAccountSeatsWorker), call(getAccountMembersWorker)]);
	});
}
