import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@app/reducers';

export const useDNSTargetName = (clusterName: string): string => {
	const [dnsTargetName, setDNSTargetName] = React.useState('');
	const accountPublicId = useSelector((state: AppState) => state.account.info.publicId);

	React.useEffect(() => {
		if (clusterName && accountPublicId) {
			setDNSTargetName(`${clusterName}-${accountPublicId}.${process.env.REACT_APP_HOSTNAME_DOMAIN}`);
		}
	}, [clusterName, accountPublicId]);

	return dnsTargetName;
};
