import { call, put, take, takeEvery, all, select } from 'redux-saga/effects';
import { getAccountBillingInfoApi, getAccountClustersApi, getAccountBandwidthApi } from '../api';
import { setAccountBillingInfo, getAccountBillingInfo, setAccountClusters, setAccountBandwidthMetrics, getAccountBillingInfoForWizard } from '../actions';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { ACCOUNT_BILLING_URL } from '../url';
import { setSigninToken } from '@app/modules/user-sign-in';
import moment from 'moment';

// get account billing info on demand
export function* watchGetAccountBillingInfo() {
	while (true) {
		const action = yield take('*');
		if (getAccountBillingInfo.is(action)) {
			yield all([
				call(getAccountBillingInfoWorker),
				call(getAccountBandwidthWorker)
			]);
		}
	}
}

// get billing info for the BillingInformation Step of the wizard
export function* watchGetBillingInfoForWizard() {
	while (true) {
		const action = yield take('*');
		if (getAccountBillingInfoForWizard.is(action)) {
			yield call(getAccountBillingInfoWorker);
		}
	}
}

// get account billing info on start of app (needs for clusters list page)
// get account bandwidth on the start of the app (for intercom)
export function* watchInitialGetAccountBillingInfo() {
	const pathname = yield select(store => store.router.location.pathname);
	// to avoid redundant request when the app starts with the billing page
	if (pathname && ACCOUNT_BILLING_URL.urlTemplate === pathname) {
		return;
	}
	yield takeEvery(setSigninToken.TYPE, getAccountBillingData);
}

export function* getAccountBillingData(){
	yield all([
		call(getAccountBillingInfoWorker),
		call(getAccountBandwidthWorker),
	])
}

// get account billing info on navigating for Billing page
export function* watchGetAccountBillingInfoOnLocChange() {
	yield takeEvery(LOCATION_CHANGE, function* onNavigatePage(a: LocationChangeAction) {
		const urlMatch = ACCOUNT_BILLING_URL.match(a.payload.location, true);
		if (!urlMatch.isMatched) {
			return;
		}

		yield all([
			call(getAccountBillingInfoWorker),
			call(getAccountClustersWorker),
			call(getAccountBandwidthWorker),
		])
	});
}

function* getAccountBillingInfoWorker() {
	try {
		const result = yield call(getAccountBillingInfoApi);
		yield put(setAccountBillingInfo({ billingInfo: result }));
	} catch (e) {
		console.log('+++ getAccountBillingInfoWorker e', e);
	}
}

function* getAccountClustersWorker() {
	try {
			const result = yield call(getAccountClustersApi);
			yield put(setAccountClusters({accountClusters: result}));
	} catch (e) {
		console.log("+++++ getAccountClustersWorker e", e);
	}
}

function* getAccountBandwidthWorker() {
	try {
		const presentDate = moment().format('YYYY-MM-DDTHH:mm:ss');
		const startOfMonth = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss');
		const result = yield call(getAccountBandwidthApi, startOfMonth, presentDate);
		yield put(setAccountBandwidthMetrics({accountBandwidth: result}));
	} catch (e) {
		console.log("+++++ getAccountBandwidthWorker e", e);
	}
}
