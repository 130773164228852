import * as React from 'react';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';
import settingsStyles from '../styles.module.scss';
import portMappingStyles from '@app/shared/PortMappingFieldList/style.module.scss';
import classnames from 'classnames';
import { InputLabel } from '@app/shared/text/InputLabel/InputLabel';
import { BasicInput } from '@app/shared/inputs/BasicInput/BasicInput';
import { AppState } from '@app/reducers';

interface ClusterSettingsHttpsInfoProps {}

export const ClusterSettingsHttpsInfo: React.FC<ClusterSettingsHttpsInfoProps> = () => {
	const httpsPort = useSelector((state: AppState) => state.cluster.cluster.currentVersion?.httpsPort);
	return (
		<div className={classnames(styles.httpsInfo, settingsStyles.ports, portMappingStyles.portMappingFieldList)}>
			<div className={styles.inputsRow}>
				<div className={styles.inputs}>
					<div className={styles.section}>
						<InputLabel text="Public" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput className={styles.input} name={'public'} value={'80'} disabled={true} />
						</div>
					</div>

					<div className={styles.section}>
						<InputLabel text="Private" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput className={styles.input} name={'private'} value={httpsPort} disabled={true} />
						</div>
					</div>

					<div className={styles.section}>
						<InputLabel text="Protocol" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput className={styles.input} name={'protocol'} value={'HTTPS'} disabled={true} />
						</div>
					</div>
				</div>
			</div>

			<div className={styles.inputsRow}>
				<div className={styles.inputs}>
					<div className={styles.section}>
						<InputLabel text="Public" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput className={styles.input} name={'public'} value={'443'} disabled={true} />
						</div>
					</div>

					<div className={styles.section}>
						<InputLabel text="Private" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput className={styles.input} name={'private'} value={httpsPort} disabled={true} />
						</div>
					</div>

					<div className={styles.section}>
						<InputLabel text="Protocol" className={styles.label} />
						<div className={styles.section_inputCol}>
							<BasicInput className={styles.input} name={'protocol'} value={'HTTPS'} disabled={true} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
