import * as React from 'react';
import { NodeOptionInputProps } from '../../interface';
import { NodeOptionItem } from '../../NodeOptionItem';

import icon from '@icons/icon.logs.svg';
import { navigateTo } from '@app/utils/navigate-to';
import { CLUSTER_LOGS_URL } from '@app/modules/cluster/state';

interface Props extends NodeOptionInputProps {}

export const LogsOption = (props: Props) => {
	return (
		<NodeOptionItem
			iconSrc={icon}
			title="Logs"
			onClick={navigateTo(CLUSTER_LOGS_URL.format({ name: props.cluster.name }))}
		/>
	);
};
