import { call, put, takeEvery } from 'redux-saga/effects';
import { getAccountBillingInvoicesApi } from '../api';
import { setAccountBillingInvoices, setAccountBillingInvoicesError } from '../actions';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { ACCOUNT_BILLING_INVOICES_URL } from '../url';

// get account billing invoices on navigating to Account Billing Invoices page
export function* watchGetAccountBillingInvoicesOnLocChange() {
	yield takeEvery(LOCATION_CHANGE, function* onNavigatePage(a: LocationChangeAction) {
		const urlMatch = ACCOUNT_BILLING_INVOICES_URL.match(a.payload.location, true);
		if (!urlMatch.isMatched) {
			return;
		}

		yield call(getAccountBillingInvoicesWorker);
	});
}

function* getAccountBillingInvoicesWorker() {
	try {
		const result = yield call(getAccountBillingInvoicesApi);
		yield put(setAccountBillingInvoices({ billingInvoices: result }));
	} catch (e) {
		console.log('+++ getAccountBillingInvoicesWorker e', e);
		yield put(setAccountBillingInvoicesError({ error: { message: e.message } }));
	}
}
