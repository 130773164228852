import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { withFormik, FormikProps, Form } from 'formik';
import { compose } from 'recompose';
import { AppState } from '../../../../reducers';
import { resetPassword, SIGNIN_URL } from '../../state';
import { navigateTo } from '@app/utils/navigate-to';
import ValidationSchema from './validation-schema';
import { ACCOUNT_SIGNUP_URL } from '../../../account';
import { clearSigninError } from '../../state/actions';
import { LabeledInputRegular } from '@app/shared/labeledInputRegular';
import { PageHeader } from '@app/shared/pageHeader';
import { ErrorMessage, IErrorMessage } from '@app/shared/errorMessage';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '@app/shared/SvgIcon';
import logoSvg from '@images/logo.appfleet.svg';

export interface ResetPassValues {
	email: string;
}
interface ResetPassProps extends ResetPassValues {
	error: IErrorMessage;
	resetPassword: (values: ResetPassValues) => void;
	clearSigninError: (_: any) => void;
}

const ResetPassComponent: React.FC<ResetPassProps & FormikProps<ResetPassValues>> = props => {
	const {
		values,
		error,
		handleChange,
		handleSubmit,
		handleBlur,
		errors,
		touched,
		isSubmitting,
		clearSigninError,
		setFieldValue,
	} = props;
	const { email } = values;

	useEffect(() => {
		return () => clearSigninError({});
	}, [clearSigninError]);

	return (
		<div className={styles.resetPass}>
			<PageHeader
				btnComponent={
					<Button
						className={classnames('btn-one', styles.btnCreateAccount)}
						onClick={navigateTo(ACCOUNT_SIGNUP_URL.urlTemplate)}
					>
						Create an account
					</Button>
				}
				className={styles.resetPass__header}
			/>

			<div className={classnames(styles.resetPass__content, 'firstContnentElem')}>
				<div className={styles.resetPass__content__logo}>
					<div />
				</div>
				<div className={styles.resetPass__content__form}>
					<div className={styles.logoAppfleet}>
						<SvgIcon iconSrc={logoSvg} />
					</div>
					<div className={styles.resetPass__content__form__header}>Reset password</div>
					<div className={styles.resetPass__content__form__subheader}>
						Please fill in the email you’ve used to create an appfleet account
					</div>

					<Form onSubmit={handleSubmit}>
						<div>
							<LabeledInputRegular
								inputType="text"
								handleChange={handleChange}
								handleBlur={handleBlur}
								inputValue={email}
								inputName="email"
								inputPlaceholder="Email"
								errorMsg={errors.email}
								isTouched={touched.email}
								labelText="Your email"
								setFieldValue={setFieldValue}
							/>
						</div>

						<div className={styles.resetPass__content__form__ctrls}>
							<Button
								type="button"
								disabled={isSubmitting}
								className={classnames('btn-three', styles.btnBackToLogin)}
								onClick={navigateTo(SIGNIN_URL.urlTemplate)}
							>
								Back to login
							</Button>
							<Button
								disabled={isSubmitting}
								type="submit"
								className={classnames('btn-one', styles.btnResetPass)}
							>
								Reset my password
							</Button>
						</div>
					</Form>

					<ErrorMessage
						error={error}
						withinPortal={false}
						className={styles.resetPass__content__form__error}
					/>

					<div
						className={styles.resetPass__content__form__createAccBtn_mobile}
						onClick={navigateTo(ACCOUNT_SIGNUP_URL.urlTemplate)}
					>
						Create an account
					</div>
				</div>
			</div>
		</div>
	);
};

export const ResetPass = compose<ResetPassProps & FormikProps<ResetPassValues>, {}>(
	connect(
		(state: AppState) => ({
			error: state.signin.error,
		}),
		{ resetPassword, clearSigninError }
	),
	withFormik({
		mapPropsToValues: () => ({
			email: '',
		}),
		validationSchema: ValidationSchema,

		handleSubmit: (values, { setSubmitting, props }) => {
			(props as ResetPassProps).resetPassword(values);
			setSubmitting(false);
		},

		displayName: 'ResetPass',
	})
)(ResetPassComponent);
