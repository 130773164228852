import { call, take, put } from 'redux-saga/effects';
import { updateClusterApi } from '../api';
import { updateClusterAction, updateClusterSuccessAction, setClusterError, updateClusterFailedAction } from '../actions';
import { ICluster } from '../../components';

export function* watchUpdateCluster() {
	while (true) {
		const action = yield take('*');
		if (updateClusterAction.is(action)) {
			yield call(updateClusterWorker, action.cluster);
		}
	}
}

function* updateClusterWorker(cluster: ICluster) {
	try {
		const result = yield call(updateClusterApi, cluster);
		yield put(updateClusterSuccessAction({ cluster: { ...cluster, currentVersion: result } }));
	} catch (error) {
		yield put(updateClusterFailedAction({cluster}));// handle in the reducer
		yield put(
			setClusterError({
				cluster,
				error,
			})
		);
	}
}
