import { all, takeEvery } from 'redux-saga/effects';
import { getClusterNodesMetricAction, getLatestClusterMetrics } from '../actions';
import { getClusterNodesMetricSaga } from './getClusterNodesMetricSaga';
import { getLatestClusterMetricsSaga } from './getLatestClusterMetricsSaga';

export function* clusterMetricsSaga() {
	yield all([
		takeEvery(getClusterNodesMetricAction.TYPE, getClusterNodesMetricSaga),
		takeEvery(getLatestClusterMetrics.TYPE, getLatestClusterMetricsSaga),
	]);
}
