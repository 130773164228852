import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { RegistryIcon } from '@app/shared/icons/RegistryIcon';
import { ApiRegistry } from '@app/modules/registries/state/dto/registry';
import { AppSpinner } from '@app/shared/AppSpinner';
import addCrossBlueIcon from '@icons/icon-16-add-blue.svg';
import addCrossWhiteIcon from '@icons/icon-24-add.svg';
import { SvgIcon } from '@app/shared/SvgIcon';

interface Props {
	onAddClick: () => void;
	items: ApiRegistry[];
	isFetching: boolean;
	activeRepository?: ApiRegistry | null;
}

export const RepositoriesList: React.FC<Props> = props => {
	const { items, isFetching, activeRepository } = props;
	const [addRepoBtnActive, setAddRepoBtnActive] = React.useState(false);

	return (
		<div className={styles.repositories}>
			<div className={styles.repositoriesHeading}>
				<span className={styles.label}>Repositories</span>
			</div>
			{isFetching && (
				<div className={styles.emptyList}>
					<div className={styles.iconWrapper}>
						<AppSpinner />
					</div>
					<span>Loading repositories...</span>
				</div>
			)}

			<div className={styles.repositoriesList}>
				{!isFetching &&
					items.map((item: ApiRegistry) => (
						<div
							key={item.id}
							className={classnames(styles.iconWrapper, styles.iconWrapper__repository, {
								[styles.icon__active]: activeRepository === item,
							})}
						>
							<RegistryIcon className={styles.icon} type={item.type} />
						</div>
					))}

				<div className={styles.addRepoCtrls}>
					<SvgIcon
						iconSrc={addRepoBtnActive ? addCrossWhiteIcon : addCrossBlueIcon}
						className={classnames(styles.addRepoCtrls__addIcon, {
							[styles.addRepoCtrls__addIcon_active]: addRepoBtnActive,
						})}
						onClick={props.onAddClick}
						onMouseOver={() => setAddRepoBtnActive(true)}
						onMouseLeave={() => setAddRepoBtnActive(false)}
					/>
					{!items.length && <span>No private repositories. Add a new one or use a public image</span>}
				</div>
			</div>
		</div>
	);
};
