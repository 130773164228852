import { defineActionGroup, defineAction } from 'rd-redux-utils';
import { IErrorMessage } from '@app/shared/errorMessage';
import { ClusterListItem } from './interfaces';

// GET CLUSTER LIST
export const getClustersListAG = defineActionGroup('GET CLUSTERS LIST');
export const getClustersList = getClustersListAG.defineAction<{}>('MAKE REQUEST');
export const getClusterListSuccess = getClustersListAG.defineAction<{ clustersList: ClusterListItem[] }>(
	'REQUEST SUCCEED'
);
export const getClustersListFail = getClustersListAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');

// DELETE CLUSTER BY ID
export const deleteClusterByIdAG = defineActionGroup('DELETE CLUSTER BY ID');
export const deleteClusterById = deleteClusterByIdAG.defineAction<{ id: number }>('MAKE REQUEST');
export const deleteClusterByIdFail = deleteClusterByIdAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');

// GET LIST OF REGIONS AVAILABLE FOR DEPLOYING NODES
export const getClusterRegionsAG = defineActionGroup('GET CLUSTERS REGIONS');
export const getClusterRegions = getClusterRegionsAG.defineAction('MAKE REQUEST');
export const getClusterRegionsSuccess = getClusterRegionsAG.defineAction<{ result: any }>('REQUEST SUCCEED');
export const getClusterRegionsFail = getClusterRegionsAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');

// GET LIST OF AVAILABLE NODE SIZES
export const getNodeSizesAG = defineActionGroup('GET LIST OF NODE SIZES');
export const getNodeSizes = getNodeSizesAG.defineAction('MAKE REQUEST');
export const getNodeSizesSuccess = getNodeSizesAG.defineAction<{ result: any }>('REQUEST SUCCEED');
export const getNodeSizesFail = getNodeSizesAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');

// GET METRICS OF CLUSTERS
export const getClustersMetricsAG = defineActionGroup('GET METRICS OF CLUSTERS');
export const subscribeClustersMetrics = getClustersMetricsAG.defineAction<{ id: string }>('SUBSCRIBE COMPONENT');
export const unsubscribeClustersMetrics = getClustersMetricsAG.defineAction<{ id: string }>('UNSUBSCRIBE COMPONENT');
export const getClustersMetrics = getClustersMetricsAG.defineAction('MAKE REQUEST');
export const getClustersMetricsSuccess = getClustersMetricsAG.defineAction<{ clustersMetrics: any }>('REQUEST SUCCEED');
export const getClustersMetricsFail = getClustersMetricsAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');

// CLEAR STATE
export const clearClusterListError = defineAction('CLUSTER LIST: CLEAR STATE');

// PULL AND DEPLOY CLUSTER VIA CLUSTERS LIST
export const pullNDeployClustersListItemAG = defineActionGroup('PULL AND DEPLOY CLUSTERS LIST ITEM');
export const pullNDeployClustersListItem = pullNDeployClustersListItemAG.defineAction<{
	clusterName: string;
	url?: string;
}>('EXECUTE REQUEST');

export const pullNDeployClustersListItemSucceed = pullNDeployClustersListItemAG.defineAction<{ clusterName: string }>(
	'REQUEST SUCCEED'
);

export const pullNDeployClustersListItemFailed = pullNDeployClustersListItemAG.defineAction<{
	clusterName: string;
	error: string;
}>('REQUEST FAILED');

export const resetPullNDeployClustersListItem = pullNDeployClustersListItemAG.defineAction<{ clusterName: string }>('RESET STATE');