import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { TableRowProps, Table } from 'semantic-ui-react';

interface Props extends TableRowProps {
	className?: string;
	children: any;
}

export const Row = (props: Props) => {
	const { className, children, ...rest } = props;

	return (
		<Table.Row className={classnames(styles.appTable__row, props.className)} {...rest}>
			{children}
		</Table.Row>
	);
};
