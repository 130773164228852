import { call, put, take } from 'redux-saga/effects';
import { newPasswordApi } from '../api';
import { setSigninError, setNewPassword } from '../actions';
import { NewPasswordValues } from '@app/modules/user-sign-in';
import { navigateTo } from '@app/utils/navigate-to';
import { SIGNIN_URL } from '../url';

export function* watchNewPassword() {
	while (true) {
		const action = yield take('*');
		if (setNewPassword.is(action)) {
			yield call(newPasswordWorker, {
				newPassword: action.newPassword,
				token: action.token,
			});
		}
	}
}

function* newPasswordWorker(formValues: NewPasswordValues) {
	try {
		yield call(newPasswordApi, formValues);
		navigateTo(SIGNIN_URL.urlTemplate)();
	} catch (e) {
		yield put(setSigninError({ error: { message: e.message } }));
	}
}
