import React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

import icon_404 from '@images/error-404@2x.png';
import { AppButton } from '@app/shared/buttons/AppButton';
import { navigateTo } from '@app/utils/navigate-to';
import { CLUSTERS_LIST_URL } from '@app/modules/clusters-list';

interface Props {
	className?: string;
}

export const NotFound: React.FC<Props> = props => {
	return (
		<div className={classnames(styles.layout, props.className)}>
			<div className={styles.imageWrapper}>
				<img src={icon_404} alt="404 Not found" className={styles.image} />
			</div>

			<h1 className={styles.pageTitle}>This page doesn't exists!</h1>
			<h2 className={styles.subtitle}>Whoops we didn’t find anything under the link you’ve clicked.</h2>
			<AppButton theme={AppButton.themes.action} onClick={navigateTo(CLUSTERS_LIST_URL.urlTemplate)}>
				Go to My Clusters
			</AppButton>
		</div>
	);
};
