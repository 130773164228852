import { all } from 'redux-saga/effects';
// import { useImage } from 'react-image';
import paymentCardImg from '@images/payment-card.png';
import logo from '@images/logo.cluster.no.connection.png';
import noClustersImage from '@images/my-clusters@2x.png';
import deploymentImg from '@images/deployment.png';
import containerImage from '@images/container.png';
import envImg from '@images/env-variables.png';
import img from '@images/name-description.png';
import portMappingImage from '@images/port-mapping.png';
import icon_404 from '@images/error-404@2x.png';
import emptyRegistries from '@images/empty.registries.png';
import noMembers from '@images/team.no.members.png';
import cardBg from '@images/card-bg.png';
import logoCheckEmail from '@images/logo.check.email.png';
import logoReset from '@images/logo.reset.png';
import logoLogin from '@images/logo.login.png';
import deploymentWarning from '@images/deployment-warning@2x.png';

export function* imageCaheSaga() {
	yield all([watchImageCache()]);
}

export function watchImageCache() {
	const imgList = [
		paymentCardImg,
		logo,
		noClustersImage,
		deploymentImg,
		containerImage,
		envImg,
		img,
		portMappingImage,
		icon_404,
		emptyRegistries,
		noMembers,
		cardBg,
		logoCheckEmail,
		logoReset,
		logoLogin,
		deploymentWarning,
	];

	const cache = imgList.map(src => {
		const img = new Image();
		img.src = src;

		return img;
	});

	return cache;
}
