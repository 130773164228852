import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { ButtonProps, Button } from 'semantic-ui-react';
import { SvgIcon } from '@app/shared/SvgIcon';
import loadingIcon from '@icons/deploying-status-icon.svg';

export enum AppButtonThemes {
	regular = 'regular',
	action = 'action',
}

// const themes: Record<keyof typeof AppButtonThemes, string> = {
// 	regular: styles.theme__regular,
// 	action: styles.theme__action,
// };

// const themeMapper = (theme: keyof typeof AppButtonThemes): string => {
// 	return themes[theme];
// };

interface Props extends ButtonProps {
	theme?: keyof typeof AppButtonThemes;
	isSubmitting?: boolean;
}

export const AppButton = (props: Props) => {
	const { className, theme = AppButtonThemes.regular, type = 'button', isSubmitting, children, ...rest } = props;

	return (
		<Button
			className={classnames(
				styles.appButton,
				// themeMapper(theme),
				className,
				{ 'btn-one': theme === 'action' },
				{ 'btn-three': theme === 'regular' }
			)}
			type={type}
			{...rest}
		>
			{children}
			{isSubmitting ? <SvgIcon className={styles.isSubmitting} iconSrc={loadingIcon}/> : null}
		</Button>
	);
};

AppButton.themes = AppButtonThemes;
