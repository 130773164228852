import { customYup as yup, yupExceptions } from '@app/utils/customYup';

export const schema = yup.object().shape({
	nameOnCard: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required(),
	cardDetails: yup.number().positive().min(1000000000000000).max(9999999999999999).required(),
	cardDate: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(/\d\d\/\d\d/)
		.required(),
	cardCVC: yup.number().min(100).max(999).required(),
});
