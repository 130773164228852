import * as React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import styles from './style.module.scss';
import { AppState } from '../../../../reducers';
import { BillingWizardStepProps, BillingInfoFormValues } from '../../state/interfaces';
import { CenteredContentLayout } from '../../components/layouts/CenteredContentLayout';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { billingInformationStepMeta } from './meta';
import { BillingInformationForm } from '../../components/forms/BillingInformationForm';
import { sendBillingInfo } from '../../state/actions';
import { IErrorMessage } from '@app/shared/errorMessage';
import { ErrorMessage } from '@app/shared/errorMessage/ErrorMessage';
import { AccountBillingInfo } from '@app/modules/account/state/interfaces';

interface Props extends BillingWizardStepProps {
	sendBillingInfo: ({
		formValues,
		onSuccess,
		onFinal,
	}: {
		formValues: BillingInfoFormValues;
		onSuccess: () => void;
		onFinal: () => void;
	}) => void;
	billingInfoError: IErrorMessage;
	accountBillingInfo: AccountBillingInfo;
}

class BillingInformationStepDumb extends React.Component<Props> {
	state = {
		disabled: false,
	};

	setDisabled = (disabled: boolean) => this.setState({ disabled });

	render() {
		const { getNextStep, step, next, sendBillingInfo, billingInfoError, accountBillingInfo } = this.props;
		const { disabled } = this.state;
		const nextStep = getNextStep();

		// if company name presents it wrapped with parentheses
		const company = accountBillingInfo.name.substring(
			accountBillingInfo.name.indexOf('(') + 1,
			accountBillingInfo.name.indexOf(')')
		);
		// assumed that firstName is one word without spaces always
		const firstName = accountBillingInfo.name.split(' ')[0];
		// so the lastName starts right after first space in the name string
		const lastNameStarts = accountBillingInfo.name.indexOf(' ');
		// get all to the end of the name string if no companyName presents
		// or till the parenthesis, if companyName presents
		const lastNameEnds =
			accountBillingInfo.name.indexOf('(') !== -1 ? accountBillingInfo.name.indexOf('(') : undefined;
		const lastName = accountBillingInfo.name.substring(lastNameStarts, lastNameEnds).trim();

		const perpInitialValues = {
			firstName,
			lastName,
			company,
			vatId: accountBillingInfo.vatId || '',
			address: accountBillingInfo.addressLine1 || '',
			city: accountBillingInfo.city || '',
			postalCode: accountBillingInfo.postalCode || '',
			country: accountBillingInfo.country || '',
		};

		return (
			<CenteredContentLayout
				title={billingInformationStepMeta.title}
				subtitle={billingInformationStepMeta.subtitle}
				controls={
					<div className={styles.controls}>
						{nextStep && (
							<StepNavigationButton
								type="next"
								stepName={nextStep.meta.title}
								formId={step.name}
								disabled={disabled}
							/>
						)}
					</div>
				}
			>
				<Grid columns={1} className={styles.gridWrapper}>
					<Grid.Row>
						<Grid.Column>
							<BillingInformationForm
								sendBillingInfo={sendBillingInfo}
								onSubmit={values => next(step.name, values)}
								formId={step.name}
								onDisable={this.setDisabled}
								initialValues={perpInitialValues}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<ErrorMessage
					className={styles.gridWrapper__errorMessage}
					error={billingInfoError}
					withinPortal={false}
					useDefaultMsg={false}
				/>
			</CenteredContentLayout>
		);
	}
}

export const BillingInformationStep = connect(
	(state: AppState) => ({
		billingInfoError: state.billingWizard.billingInfo.error,
		accountBillingInfo: state.account.billingInfo,
	}),
	{ sendBillingInfo }
)(BillingInformationStepDumb);
