import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { TableCellProps, Table } from 'semantic-ui-react';

interface Props extends TableCellProps {
	colSpan?: number;
	contentClass?: string;
}

export const Col = (props: Props) => {
	const { className, children, colSpan, contentClass, ...rest } = props;
	return (
		<Table.Cell colSpan={colSpan} className={classnames(styles.appTable__col, props.className)} {...rest}>
			<div className={classnames(styles.appTable__col__content, contentClass)}>{children}</div>
		</Table.Cell>
	);
};
