import { call, take, put } from 'redux-saga/effects';
import { updateAccountMemberRoleApi } from '../api';
import { updateAccountMemberRole, getAccountMembersList } from '../actions';
import { TeamMember } from '../interfaces';

export function* watchUpdateAccountMemberRole() {
	while (true) {
		const action = yield take('*');
		if (updateAccountMemberRole.is(action)) {
			yield call(updateAccountMemberRoleWorker, action);
		}
	}
}

function* updateAccountMemberRoleWorker({ id, permission }: Partial<TeamMember>) {
	try {
		yield call(updateAccountMemberRoleApi, { id, permission });
		yield put(getAccountMembersList({}));
	} catch (e) {}
}
