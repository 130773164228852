import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { Input, Label, InputProps } from 'semantic-ui-react';

interface Props extends Omit<InputProps, 'label'> {
	labelText: string;
}

export const LabeledInput: React.FC<Props> = ({
	name,
	value,
	className,
	labelText,
	error,
	onBlur,
	setFieldValue,
	...rest
}) => {
	const ownHandleBlur = (name: string, value: string | number | undefined) => (
		e: React.FocusEvent<HTMLInputElement>
	) => {
		if (setFieldValue) {
			// trim and replace multiwhitespaces with single one
			const prepValue = String(value).trim().replace(/\s\s+/g, ' ');
			setFieldValue(name, prepValue);
		}
		return onBlur(e);
	};

	return (
		<Input
			className={classnames(styles.inputWithLabel, className)}
			labelPosition={'right'}
			name={name}
			value={value}
			onBlur={ownHandleBlur(name, value)}
			{...rest}
			error={error}
		>
			<input />
			<Label basic className={classnames(styles.label, { [styles.label__error]: error })}>
				{labelText}
			</Label>
		</Input>
	);
};
