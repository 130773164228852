import {
	CONFIRMATION_STEP_ID,
	confirmationStepMeta,
	ConfirmationStep,
} from '@app/modules/deploy-wizard/steps/ConfirmationStep';
import { DEPLOY_STEP_ID, addNodesDeployStepMeta, DeployStep } from '@app/modules/deploy-wizard/steps/DeployStep';
import {
	REGIONS_STEP_ID,
	regionsStepMeta,
	RegionsStep,
	regionsProcessor,
} from '@app/modules/deploy-wizard/steps/RegionsStep';
import { DeployWizardStep } from '@app/modules/deploy-wizard/interfaces';

export const wizardSteps: DeployWizardStep[] = [
	{
		name: REGIONS_STEP_ID,
		meta: regionsStepMeta,
		step: RegionsStep,
		processValues: regionsProcessor,
	},
	{
		name: CONFIRMATION_STEP_ID,
		meta: confirmationStepMeta,
		step: ConfirmationStep,
	},
	{
		name: DEPLOY_STEP_ID,
		meta: addNodesDeployStepMeta,
		step: DeployStep,
	},
];
