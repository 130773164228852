import { ReferralState } from "./state";
import { Action } from "redux";
import { getReferralSuccess, getReferralFail, clearReferralError } from "./actions";

const INITIAL_STATE = {
    error: {
        message: ""
    }
};

export function referralReducer(state: ReferralState = INITIAL_STATE, action: Action): ReferralState {
    if (getReferralSuccess.is(action)) {
        // TODO: what is the response? What should we put to store?
        return state;
    }
    if (getReferralFail.is(action)) {
        return {
            ...state,
            error: action.error
        };
    }
    if (clearReferralError.is(action)) {
        return {
            ...state,
            error: INITIAL_STATE.error
        };
    }
    return state;
}
