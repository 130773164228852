import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '@app/history';
import { signinReducer, signoutUser } from '@app/modules/user-sign-in';
import { accountReducer } from '@app/modules/account';
import { clustersListReducer } from '@app/modules/clusters-list';
import { clusterReducer } from '@app/modules/cluster';
import { registriesReducer } from '@app/modules/registries';
import { referralReducer } from '@app/modules/referral/state/reducer';
import { regionsReducer } from '@app/data-modules/regions';
import { sizesReducer } from '@app/data-modules/sizes';
import { deployWizardReducer } from '@app/modules/deploy-wizard/state/reducer';
import { clusterNodesReducer } from '@app/data-modules/cluster-nodes';
import { activityLogReducer } from '@app/data-modules/activity-log/reducer';
import { clusterMetricReducer } from '@app/data-modules/cluster-metrics/reducer';
import { teamReducer } from '@app/modules/team';
import { intercomNotificationReducer } from '@app/modules/intercom-notification-disabled/reducer';
import { billingWizardReducer } from '@app/modules/billing-wizard';
import { healthStatusesReducer } from '@app/data-modules/healthStatuses';

const reducerMap = {
	router: connectRouter(history),
	signin: signinReducer,
	account: accountReducer,
	clustersList: clustersListReducer,
	cluster: clusterReducer,
	registries: registriesReducer,
	referral: referralReducer,
	regions: regionsReducer,
	sizes: sizesReducer,
	wizard: deployWizardReducer,
	clusterNodes: clusterNodesReducer,
	activity: activityLogReducer,
	metric: clusterMetricReducer,
	team: teamReducer,
	intercom: intercomNotificationReducer,
	billingWizard: billingWizardReducer,
	healthStatuses: healthStatusesReducer,
};

const appReducer = combineReducers(reducerMap);

const rootReducer = (state: any, action: any) => {
	if (signoutUser.is(action)) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;

type FirstArg<TFunction> = TFunction extends (arg: infer TArg, ...rest: any[]) => any ? TArg : any;

type State<TReducerMap> = {
	[P in keyof TReducerMap]: Exclude<FirstArg<TReducerMap[P]>, undefined>;
};

export type AppState = State<typeof reducerMap>;
