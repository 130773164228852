import * as React from 'react';
import styles from './style.module.scss';
import { NodeOption, NodeOptionInputProps } from './interface';

interface Props extends NodeOptionInputProps {
	options: NodeOption[];
}

export const NodeOptionsList = (props: Props) => {
	const { options, ...rest } = props;

	return (
		<ul className={styles.nodeOptionsList}>
			{options.map(option => {
				const Component = option.component;

				return (
					<li className={styles.optionItem} key={option.id}>
						<Component {...rest} />
					</li>
				);
			})}
		</ul>
	);
};
