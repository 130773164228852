import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import mapLayout from '@layouts/world.map.dark.svg';

const networkMapImage = {
	leftLongitude: -167,
	rightLongitude: 193,
	bottomLatitude: -60,
};

interface Coordinates {
	lng: number;
	lat: number;
}

interface Point<TComponentProps> extends Coordinates {
	lng: number;
	lat: number;
	component: React.ComponentType<TComponentProps>;
	componentProps: TComponentProps;
}

interface Props<TComponentProps> {
	points: Point<TComponentProps>[];
	className?: string;
	zoom?: boolean;
}

interface State {
	sizings: {
		width: number;
		height: number;
	} | null;
}

export class MapComponent<TComponentProps> extends React.Component<Props<TComponentProps>, State> {
	state = {
		sizings: null,
	};

	imgRef = React.createRef<HTMLImageElement>();

	mapPointToMapLayout = (point: Point<TComponentProps>) => {
		const { lng, lat } = point;
		const { width, height } = this.state.sizings || { width: 0, height: 0 };

		let mapLonDelta = networkMapImage.rightLongitude - networkMapImage.leftLongitude,
			x = (lng - networkMapImage.leftLongitude) * (width / mapLonDelta),
			worldMapWidth = ((width / mapLonDelta) * 360) / (2 * Math.PI),
			mapLatBottomDegree = (networkMapImage.bottomLatitude * Math.PI) / 180,
			mapOffsetY =
				(worldMapWidth / 2) * Math.log((1 + Math.sin(mapLatBottomDegree)) / (1 - Math.sin(mapLatBottomDegree))),
			y =
				height -
				((worldMapWidth / 2) *
					Math.log((1 + Math.sin((lat * Math.PI) / 180)) / (1 - Math.sin((lat * Math.PI) / 180))) -
					mapOffsetY);

		return { x, y };
	};

	componentDidMount() {
		if (!this.imgRef.current) return;

		this.getWrapperSize();
	}

	componentDidUpdate(prevProps: Props<TComponentProps>, PrevState: State) {
		if (!this.imgRef.current || this.state.sizings) return;

		this.imgRef.current.addEventListener('load', () => {
			this.getWrapperSize();
		});
	}

	getWrapperSize = () => {
		if (!this.imgRef.current) return;

		const width = this.imgRef.current.clientWidth;
		const height = this.imgRef.current.clientHeight;

		this.setState({
			sizings: {
				width,
				height,
			},
		});
	};

	render() {
		const { points, zoom, className } = this.props;
		const dotRadius = 5;

		return (
			<div className={classnames(styles.mapWrapper, className)}>
				<div className={classnames(styles.mapContainer, { [styles.mapContainer__zoom]: zoom })}>
					<div
						dangerouslySetInnerHTML={{ __html: mapLayout }}
						className={classnames(styles.img)}
						ref={this.imgRef}
					/>
					{this.state.sizings &&
						points.map((point, index) => {
							const { componentProps, lat, lng } = point;
							const { x, y } = this.mapPointToMapLayout(point);
							const Component = point.component;
							return (
								<div
									className={styles.point}
									style={{ left: `${x}px`, top: `${y - dotRadius}px` }}
									key={index || String.prototype.concat(String(point.lat), String(point.lng))}
								>
									{<Component index={index} lat={lat} lng={lng} {...componentProps} />}
								</div>
							);
						})}
				</div>
			</div>
		);
	}
}
