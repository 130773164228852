import React from 'react';
import { Icon } from 'semantic-ui-react';
import memo from 'memoize-one';
import * as timeago from 'timeago.js';
import styles from '@app/modules/cluster/components/clusterInfo/styles.module.scss';
import { AppCard, Head, Section, SectionContainer } from '@app/shared/AppCard';
import { ICluster } from '@app/modules/cluster';
import { AppButton } from '@app/shared/buttons/AppButton/AppButton';
import { navigateTo } from '@app/utils/navigate-to';
import { CLUSTER_ACITVITY_LOG_URL } from '../../../../state/url';
import { AppSkeleton } from '@app/shared/AppSkeleton/AppSkeleton';
import { ApiActivityLog } from '@app/data-modules/activity-log/state';
import { SvgIcon } from '@app/shared/SvgIcon';
import dockerLogo from '@images/registry-logo/docker-hub.svg';
import nodejsIcon from '@icons/icon.nodejs.svg';
import { CopyToClipboardBtn } from '@app/shared/copyToClipboardBtn';

interface InputProps {
	cluster: ICluster;
	loading: boolean;
	logs: ApiActivityLog[];
}

export const ImageCard: React.FC<InputProps> = ({ cluster, loading, logs }) => {
	const calculateLastDeployText = memo((logs: ApiActivityLog[]) => {
		if (!logs || !logs.length) return '0';
		return timeago.format(new Date(logs[0].createdAt));
	});

	const containerNameRef = React.useRef<HTMLElement>(null);

	return (
		<AppCard className={styles.imageCard}>
			<Head className={styles.cardHead}>
				<div className={styles.dockerHeader}>
					<span>{'Deployed Application'}</span>
					<AppButton
						onClick={
							cluster && logs && logs.length
								? navigateTo(CLUSTER_ACITVITY_LOG_URL.format({ name: cluster.name }))
								: undefined
						}
						className={styles.dateBtn}
					>
						{loading ? (
							<AppSkeleton className={styles.overviewCard__btnSkeleton} />
						) : (
							<>
								Last deploy: {calculateLastDeployText(logs)}
								<Icon name="angle right" />
							</>
						)}
					</AppButton>
				</div>
			</Head>

			<SectionContainer className={styles.imageCard}>
				<Section className={styles.imageCard__item}>
					<SvgIcon iconSrc={dockerLogo} className={styles.imageCard__item__containerLogo} />
					<div className={styles.imageCard__item__pseudoInput}>
						{cluster.currentVersion?.image ? (
							<>
								<SvgIcon iconSrc={nodejsIcon} className={styles.imageCard__item__pseudoInput__icon} />

								<span
									className={styles.imageCard__item__pseudoInput__containerName}
									ref={containerNameRef}
								>
									{cluster.currentVersion ? cluster.currentVersion.image : ''}
								</span>

								<CopyToClipboardBtn
									refToCopyFrom={containerNameRef}
									className={styles.imageCard__item__pseudoInput__copyBtn}
								/>
							</>
						) : null}
					</div>
				</Section>
			</SectionContainer>
		</AppCard>
	);
};
