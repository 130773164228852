import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { cutMeasurement as cutMeasurementFunc } from '@app/utils/cutMeasurement';

interface Props {
	measurement: string;
	data: string;
	cutMeasurement?: boolean;
	digitsAfterDot?: number;
	className?: string;
}

export const MeasurementDataBar: React.FC<Props> = ({
	className,
	measurement,
	data,
	digitsAfterDot,
	cutMeasurement = true,
}) => {
	let displayData = '-';
	let displayMeasurement = '-';
	if (typeof data !== 'undefined') {
		const cutResult = cutMeasurement
			? cutMeasurementFunc(data, measurement, digitsAfterDot)
			: { data, measurement };
		displayData = cutResult.data;
		displayMeasurement = cutResult.measurement;
	}

	return (
		<div className={classnames(styles.measurementDataBar, className)}>
			<div
				className={classnames(
					styles.measurementDataBar__textContainer,
					styles.measurementDataBar__textContainer_data
				)}
			>
				{displayData}
			</div>
			<div
				className={classnames(
					styles.measurementDataBar__textContainer,
					styles.measurementDataBar__textContainer_measurement
				)}
			>
				{displayMeasurement}
			</div>
		</div>
	);
};
