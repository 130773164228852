import React from 'react';
import { Progress, Label } from 'semantic-ui-react';
import { MetricsDataList } from '@app/modules/cluster/components/MetricsDataList';
import styles from './styles.module.scss';
import { CLUSTER_STASUSES } from '@app/data-modules/healthStatuses';

interface ClusterLoadProps {
	metrics: any;
	status: CLUSTER_STASUSES;
}

export const ClusterLoad: React.FC<ClusterLoadProps> = ({ metrics, status }) => {
	const [isShow, setIsShow] = React.useState(false);

	const cpuLoad = metrics[`cpu.load`].toFixed(0);
	const metricsList = [
		{ title: 'CPU', measurement: '%', value: cpuLoad, digitsAfterDot: 0 },
		{ title: 'RAM', measurement: '%', value: metrics[`mem.usage`].toFixed(0), digitsAfterDot: 0 },
		{ title: 'Bandwidth', measurement: 'B', value: metrics[`net.bandwidth`].toFixed(0) },
		{ title: 'Disk Usage', measurement: '%', value: metrics[`disk.used`].toFixed(0), digitsAfterDot: 0 },
	];

	return (
		<div onMouseOver={() => setIsShow(true)} onMouseLeave={() => setIsShow(false)} className={styles.clusterLoad}>
			<div className={styles.clusterLoad__metrics}>{`${cpuLoad}%`}</div>

			<div className={styles.clusterLoad__progress}>
				<Progress percent={cpuLoad} color={getColorBasedOnStatus(status)} />
			</div>

			{isShow ? (
				<Label basic pointing="above" className={styles.clusterLoad__descr}>
					<MetricsDataList list={metricsList} />
				</Label>
			) : null}
		</div>
	);
};

type StatusColor =
	| 'green'
	| 'orange'
	| 'red'
	| 'grey'
	| 'yellow'
	| 'olive'
	| 'teal'
	| 'blue'
	| 'violet'
	| 'purple'
	| 'pink'
	| 'brown'
	| 'black'
	| undefined;

export const getColorBasedOnStatus = (status: CLUSTER_STASUSES): StatusColor => {
	switch (status) {
		case CLUSTER_STASUSES.degraded:
			return 'orange';
		case CLUSTER_STASUSES.down:
			return 'red';
		case CLUSTER_STASUSES.healthy:
		default:
			return 'green';
	}
};
