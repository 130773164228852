import { all } from 'redux-saga/effects';
import { watchCreateAccount } from './createAccount';
import { watchGetAccountInfo } from './getAccountInfo';
import {
	watchGetAccountBillingInfo,
	watchGetAccountBillingInfoOnLocChange,
	watchInitialGetAccountBillingInfo,
} from './getAccountBillingInfo';
import { watchVerifyEmailChange } from './emailVerification';
import { watchSaveAccountSettings } from './saveAccountSettings';
import { watchGetUserInfo, watchGetUserInfoOnLocChange } from './getUserInfo';
import { watchGetPaymentInfo, watchGetPaymentInfoOnLocChange } from './getPaymentInfo';
import { watchRemovePaymentInfoCard } from './removePaymentInfoCard';
import { watchSaveAccountBillingInfo } from './saveAccountBillingInfo';
import { watchGetAccountBillingInvoicesOnLocChange } from './getAccountBillingInvoices';
import { watchRedeemCoupon } from './redeemCoupon';
import { watchCreateUser } from './createUser';
import { watchGetBillingInfoForWizard } from './getAccountBillingInfo';
import { watchSetDefaultBillingCard } from './setDefaultBillingCard';

export function* accountSaga() {
	yield all([
		watchCreateAccount(),
		watchGetAccountInfo(),
		watchGetAccountBillingInfo(),
		watchGetAccountBillingInfoOnLocChange(),
		watchInitialGetAccountBillingInfo(),
		watchVerifyEmailChange(),
		watchSaveAccountSettings(),
		watchGetUserInfo(),
		watchGetUserInfoOnLocChange(),
		watchGetPaymentInfo(),
		watchGetPaymentInfoOnLocChange(),
		watchRemovePaymentInfoCard(),
		watchSaveAccountBillingInfo(),
		watchGetAccountBillingInvoicesOnLocChange(),
		watchRedeemCoupon(),
		watchCreateUser(),
		watchGetBillingInfoForWizard(),
		watchSetDefaultBillingCard(),
	]);
}
