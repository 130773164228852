import { call, take, put } from 'redux-saga/effects';
import { deleteDomainApi } from '../api';
import { removeDomain, getDomains } from '../actions';

export function* watchRemoveDomain() {
	while (true) {
		const action = yield take('*');
		if (removeDomain.is(action)) {
			yield call(removeDomainWorker, { ...action });
		}
	}
}

function* removeDomainWorker({
	domainId,
	clusterName,
	handleResponse,
}: {
	domainId: number;
	clusterName: string;
	handleResponse: () => void;
}) {
	try {
		yield call(deleteDomainApi, { clusterName, domainId });
		yield put(getDomains({ clusterName }));
	} catch (e) {
		console.log('+++ removeDomainWorker e', e);
	} finally {
		yield call(handleResponse);
	}
}
