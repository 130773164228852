import React from 'react';
import styles from '../styles.module.scss';
import userIcon from '@icons/icon.team.svg';
import { SvgIcon } from '../../../../../shared/SvgIcon';

export const ClusterSettingsNoUsers = () => {
	return (
		<div className={styles.noUsers}>
			<div className={styles.noUsers__icon}>
				<SvgIcon iconSrc={userIcon} />
			</div>
			<div className={styles.noUsers__header}>{'There are no users matching the criteria.'}</div>
		</div>
	);
};
