import { call, take, all, put } from 'redux-saga/effects';
import { updateClusterMembersPermApi } from '../api';
import { updateMemberSClusterPermission, getClusterMembers } from '../actions';
import { ComparedAvailableMember } from '../../components/clusterSettings/clusterSettingsPermissions/addPermissions/AddPermissionsRow';

export function* watchUpdateClusterMembersPerm() {
	while (true) {
		const action = yield take('*');
		if (updateMemberSClusterPermission.is(action)) {
			yield call(updateClusterMembersPermWorker, action);
		}
	}
}

function* updateClusterMembersPermWorker({
	members,
	clusterId,
	clusterName,
	onDismiss,
}: {
	members: ComparedAvailableMember[];
	clusterId: number;
	clusterName: string;
	onDismiss: () => void;
}) {
	try {
		yield all(
			members.map(member => {
				return call(updateClusterMembersPermApi, { member, clusterId });
			})
		);
		yield put(getClusterMembers({ name: clusterName }));
	} catch (e) {
	} finally {
		onDismiss();
	}
}
