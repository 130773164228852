import * as React from 'react';
import { AnchorContextProps } from '../../context';
import { withAnchorContext } from '../../context/withAnchorContext';

type ChildrenFn = (active: boolean, id: string, ref: React.RefObject<any>) => JSX.Element;

interface InputProps {
	children: JSX.Element | ChildrenFn;
	sectionId: string;
	className?: string;
}

type Props = InputProps & AnchorContextProps;

class AnchorSectionDumb extends React.PureComponent<Props> {
	private readonly innerRef = React.createRef<HTMLElement>();

	componentWillUnmount() {
		this.props.context.removeSection(this.props.sectionId);
	}

	componentDidMount() {
		if (!this.innerRef.current) return;

		const { context, sectionId } = this.props;
		const { registerSection } = context;

		registerSection(this.innerRef.current, sectionId);
	}

	render() {
		const { children, sectionId, className } = this.props;
		const isChildrenFn = typeof children === 'function';

		if (isChildrenFn && className) {
		}

		return isChildrenFn ? (
			(children as ChildrenFn)(true, sectionId, this.innerRef)
		) : (
			<section id={sectionId} className={className} ref={this.innerRef}>
				{children}
			</section>
		);
	}
}

export const AnchorSection = withAnchorContext<InputProps, Props>(AnchorSectionDumb);
