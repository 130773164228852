import { call, take, put } from 'redux-saga/effects';
import { getClusterDomainsApi } from '../api';
import { getDomains, setDomains, getDomainsFailed } from '../actions';

export function* watchGetClusterDomains() {
	while (true) {
		const action = yield take('*');
		if (getDomains.is(action)) {
			yield call(getClusterDomainsWorker, action);
		}
	}
}

export function* getClusterDomainsWorker({ clusterName }: { clusterName: string }) {
	try {
		const result = yield call(getClusterDomainsApi, clusterName);
		yield put(setDomains({ clusterDomains: result }));
		// return result for the addDomain, refreshDomain sagas
		return result;
	} catch (e) {
		yield put(getDomainsFailed({}));
	}
}
