import { call, take, put } from 'redux-saga/effects';
import { postRebuildClusterByNameApi } from '../api';
import { rebuildClusterAction, rebuildClusterFailedAction, rebuildClusterSuccessAction, setClusterError } from '../actions';
import { navigateTo } from '@app/utils/navigate-to';
import { MetaError } from '@app/types/metaError';

export function* watchRebuildCluster() {
	while (true) {
		const action = yield take('*');
		if (rebuildClusterAction.is(action)) {
			yield call(rebuildClusterWorker, action.clusterName, action.url);
		}
	}
}

function* rebuildClusterWorker(clusterName: string, url?: string) {
	try {
		yield call(postRebuildClusterByNameApi, clusterName);
		if (url) {
			yield call(navigateTo(url));
		}
		yield put(rebuildClusterSuccessAction({ clusterName }));
	} catch (e) {
		const meta = e?.response?.data?.errors;
		const MetaErr = new MetaError(meta ? e : { message: e.message }, meta);
		yield put(setClusterError({ error: MetaErr}));
		// this is mistake, but now it would not work correctly
		// cluster should not use DeployWizard store at all but it does now
		// i guess it was done by mistake
		// we should separate cluster deploy-recreate logic from wizard in the future
		yield put(
			rebuildClusterFailedAction({
				clusterName,
				error: e.message,
			})
		);
	}
}
