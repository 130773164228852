import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import styles from './style.module.scss';
import { NODE_STATUSES } from '@app/data-modules/healthStatuses';
import { getStatusText, getStatusStyle, getStatusTooltipText } from './statusMap';
import iconDeploying from '@icons/deploying-status-icon.svg';
import iconInfo from '@icons/icon.info.svg';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import { AppState } from '@app/reducers';

interface Props {
	nodeId: number;
	className?: string;
	renderIcon?: boolean;
	// temp prop just for mocked ClusterActivity page until it is ready
	tempPropRmMe?: boolean;
}

export const NodeStatus: React.FC<Props> = ({ nodeId, className, renderIcon = true, tempPropRmMe = false }) => {
	const nodesStatuses = useSelector((state: AppState) => state.healthStatuses.nodes);
	const [nodeStatus, setNodeStatus] = useState<NODE_STATUSES>(NODE_STATUSES.unknown);

	useEffect(() => {
		// starts
		// just a mock for the ClusterActivity page for a while
		// don't forget to rm this crap
		if (tempPropRmMe === true) {
			setNodeStatus(NODE_STATUSES.healthy);
			return;
		}
		// ends
		if (nodesStatuses.length && nodeId) {
			const nodeStatusObj = nodesStatuses.find(nodeStatus => nodeStatus.id === nodeId);
			if (nodeStatusObj) {
				setNodeStatus(nodeStatusObj.status);
			}
		}
	}, [nodesStatuses, nodeId, tempPropRmMe]);

	const statusStyle = getStatusStyle(nodeStatus);

	return (
		<div
			className={classnames(
				styles.nodeStatus,
				statusStyle,
				{ [styles.nodeStatus__noIcon]: !renderIcon },
				className
			)}
		>
			<span className={styles.nodeStatus__text}>{getStatusText(nodeStatus)}</span>
			{renderIcon && (
				<IconWithTooltip
					iconWrapClassname={classnames(styles.nodeStatus__statusIcon, statusStyle)}
					imageSrc={getIcon(nodeStatus)}
					tooltipText={getStatusTooltipText(nodeStatus)}
				/>
			)}
		</div>
	);
};

const getIcon = (status: NODE_STATUSES) => {
	if (status === NODE_STATUSES.deploying) {
		return iconDeploying;
	} else {
		return iconInfo;
	}
};
