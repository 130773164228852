import { call, take, put } from "redux-saga/effects";
import { disableHttpsPortApi } from '../api';
import { disableHttpsPort, disableHttpsPortSucceed, disableHttpsPortFailed, clearDomains } from '../actions';
import { MetaError } from '@app/types/metaError';

export function* watchDisableHttpsPort() {
	while (true) {
		const action = yield take("*");
		if (disableHttpsPort.is(action)) {
			yield call(disableHttpsPortWorker, {clusterName: action.clusterName, onSuccess: action.onSuccess});
		}
	}
}

function* disableHttpsPortWorker({clusterName, onSuccess}: {clusterName: string; onSuccess: () => void;}) {
	try {
		const result = yield call(disableHttpsPortApi, {clusterName});
		yield put(disableHttpsPortSucceed({ currentVersion: result }));
		yield put(clearDomains({}));
		yield call(onSuccess);
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(disableHttpsPortFailed({ error: new MetaError(meta ? { message: e.message } : e, meta) }));
	}
}
