import * as React from 'react';
import { AppButton } from '@app/shared/buttons/AppButton';
import { BtnStatus, BtnStatusText } from '@app/shared/AppRequestButton';

interface Props {
	stepName?: string;
	type: 'next' | 'prev' | 'final';
	formId?: string;
	disabled?: boolean;
	onClick?: () => void;
	status?: BtnStatus;
	statusText?: BtnStatusText;
	btnClassName?: string;
}

const formatButtonText = (type: 'next' | 'prev' | 'final', stepName: string) => {
	return type === 'next' ? `Next: ${stepName}` : type === 'prev' ? `Go back: ${stepName}` : stepName;
};

export const StepNavigationButton = (props: Props) => {
	const { stepName, type, disabled, formId = '', onClick, statusText, status = 'initial', btnClassName } = props;

	const btnText = stepName || statusText?.[status] || '';
	const theme = type === 'prev' ? AppButton.themes.regular : AppButton.themes.action;

	return (
		<AppButton
			theme={theme}
			type={type === 'prev' ? 'button' : 'submit'}
			disabled={disabled}
			form={formId}
			onClick={onClick}
			isSubmitting={status === 'loading'}
			className={btnClassName}
		>
			{formatButtonText(type, btnText)}
		</AppButton>
	);
};
