import * as React from 'react';
import styles from './style.module.scss';
import { Grid } from 'semantic-ui-react';
import { billingConfirmationStepMeta } from './meta';
import { connect } from 'react-redux';
import { AppState } from '@app/reducers';
import { BillingWizardStepProps } from '../../state/interfaces';
import paymentCardImg from '@images/payment-card.png';
import { AppButton } from '@app/shared/buttons/AppButton';
import { StepLayoutWithLogo } from '../../components/layouts/DefaultStepLayout/StepLayoutWithLogo';
import { deployWizardModalApi, changeBillingInformationWizardModalApi } from '@app/modals';
import { ACCOUNT_BILLING_URL } from '@app/modules/account/state/url';
import { navigateTo } from '@app/utils/navigate-to';

interface Props extends BillingWizardStepProps {
	currentLocation: string;
}

class BillingConfirmationStepDumb extends React.Component<Props> {
	deployNewCluster = () => {
		changeBillingInformationWizardModalApi.closeModal();
		deployWizardModalApi.openModal();
	};

	componentWillUnmount() {
		const { currentLocation } = this.props;

		if (ACCOUNT_BILLING_URL.match(currentLocation)) {
			navigateTo(ACCOUNT_BILLING_URL.urlTemplate)();
		}
	}

	render() {
		const { step } = this.props;

		return (
			<StepLayoutWithLogo
				title={billingConfirmationStepMeta.title}
				subtitle={billingConfirmationStepMeta.subtitle}
				image={paymentCardImg}
			>
				<Grid columns={1} className={styles.gridWrapper}>
					<Grid.Row>
						<Grid.Column>
							<div className={styles.paymentBody}>
								<AppButton
									theme={AppButton.themes.regular}
									className={styles.paymentBody__closeBtn}
									type={'button'}
									form={step.name}
									onClick={changeBillingInformationWizardModalApi.closeModal}
								>
									Close
								</AppButton>
								<AppButton
									theme={AppButton.themes.action}
									type={'submit'}
									form={step.name}
									onClick={this.deployNewCluster}
								>
									Deploy a new cluster
								</AppButton>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</StepLayoutWithLogo>
		);
	}
}

export const BillingConfirmationStep = connect((state: AppState) => ({
	currentLocation: state.router.location.pathname,
}))(BillingConfirmationStepDumb);
