import { call, takeEvery, put, select, all } from 'redux-saga/effects';
import { getClusterByIdApi } from '../api';
import { setClusterError, setCluster } from '../actions';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { CLUSTER_URL } from '../url';
import { AppState } from '@app/reducers';
import { getClusterNodesSaga } from '@app/data-modules/cluster-nodes/sagas/getClusterNodes';
import { getClusterByNameSelector } from '@app/modules/clusters-list/state/selector';
import { getClusterMembersWorker } from './getClusterMembers';
import { getClusterDomainsWorker } from './getClusterDomains';
import { navigateTo } from '@app/utils/navigate-to';
import { NOT_FOUND_URL } from '@app/modules/not-found/state/url';
import { getClusterNodesMetricAction } from '@app/data-modules/cluster-metrics/actions';
import { DEFAULT_METRIC_PERIOD } from '../../components/clusterInfo/ClusterInfo';

export function* watchGetClusterOnLocChange() {
	yield takeEvery(LOCATION_CHANGE, onNavigateClusterPage);
}

export function* onNavigateClusterPage(a: LocationChangeAction) {
	const urlMatch = CLUSTER_URL.match(a.payload.location, false);
	if (!urlMatch.isMatched) {
		return;
	}
	const { name } = urlMatch.params;
	const clusterName = yield select((state: AppState) => state.cluster.cluster.name);

	// check whether we still on the same cluster as before and just navigating via inner navigation
	// to avoid unnecessary api calls in such case
	if (name && (!clusterName || String(name) !== String(clusterName))) {
		yield all([
			call(getClusterWorker, { name }),
			call(getClusterNodesSaga, name),
			call(getClusterMembersWorker, { name }),
			call(getClusterDomainsWorker, { clusterName: name }),
		]);
	}
	// call for metrics after cluster and nodes is already fetched
	// since we should always show metrics for the default metric period on the clusterInfo page
	//  and metrics doesn't matter for other tabs we will fetch em only when url is matched exactly to ClusterInfo page
	const infoUrlMatch = CLUSTER_URL.match(a.payload.location, true);
	if (infoUrlMatch.isMatched) {
		yield put(getClusterNodesMetricAction({clusterName: name, period: DEFAULT_METRIC_PERIOD}));
	}
}

export function* getClusterWorker({ name }: { name: string }) {
	try {
		let cluster = yield select(store => getClusterByNameSelector(store, name));

		if (!cluster) {
			cluster = yield call(getClusterByIdApi, name);
		}

		yield put(setCluster({ cluster }));
	} catch (e) {
		if (e && e.status === 404) {
			yield navigateTo(NOT_FOUND_URL.urlTemplate)();
		}

		yield put(setClusterError({ error: e }));
	}
}
