import { all, call, put, take } from 'redux-saga/effects';
import { inviteMembersApi } from '../api';
import { getAccountMembersList, inviteMembers, setInviteMembersError } from '../actions';

export function* watchInviteMembers() {
	while (true) {
		const action = yield take('*');
		if (inviteMembers.is(action)) {
			yield call(inviteMembersWorker, action.listForInvite);
		}
	}
}

function* inviteMembersWorker(listForInvite: { email: string }[]) {
	try {
		yield all(listForInvite.map(member => call(inviteMembersApi, member)));
		yield put(getAccountMembersList({}));
	} catch (e) {
		yield put(setInviteMembersError({ error: { message: e.message } }));
	}
}
