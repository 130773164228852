import React from 'react';
import api from '@app/apis/api';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { usePageTitle } from '@app/hooks/usePageTitle';
import { SIGNIN_URL } from '@app/modules/user-sign-in';
import { CLUSTERS_LIST_URL } from '@app/modules/clusters-list';
import { useViewport } from '@app/hooks/useViewport';

interface AppRouteInterface extends RouteProps {
	auth: boolean;
	children: JSX.Element;
}

export const AppRoute = ({ auth = true, children, ...rest }: AppRouteInterface) => {
	usePageTitle(rest);
	useViewport(auth);
	const token = api.getToken();

	const checkStatus = (auth: boolean, token: string, children: JSX.Element) => {
		if (auth) {
			if (token) {
				return children;
			} else {
				return <Redirect to={{ pathname: SIGNIN_URL.urlTemplate }} />;
			}
		} else {
			if (token) {
				return <Redirect to={{ pathname: CLUSTERS_LIST_URL.urlTemplate }} />;
			} else {
				return children;
			}
		}
	};

	return <Route {...rest} render={() => checkStatus(auth, token, children)} />;
};
