import * as React from 'react';
import { useDispatch } from 'react-redux';
import styles from './style.module.scss';
import { Popup } from 'semantic-ui-react';
import { MapDot } from '@app/shared/MapComponent';
import { FlagIcon } from '@app/shared/icons/FlagIcon';
import { MetricsDataList } from '@app/modules/cluster/components/MetricsDataList';
import { AppSpinner } from '@app/shared/AppSpinner';
import { ApiNode } from '@app/data-modules/cluster-nodes';
import { getLatestClusterMetrics } from '@app/data-modules/cluster-metrics';
import regionsLogo from '@icons/icon.cluster.regions.svg';
import { navigateTo } from '@app/utils/navigate-to';
import { CLUSTER_NODES_URL } from '@app/modules/cluster/state';
import { ICluster } from '@app/modules/cluster/components/cluster';
import { SvgIcon } from '@app/shared/SvgIcon';
import arrowLeft from '@icons/icon-16-arrow-up-dark.svg';
import arrowLeftDisabled from '@icons/arrow.left.disabled.10x16.svg';
import classnames from 'classnames';
import { MetricsListItem } from '@app/modules/cluster/components/MetricsDataList/MetricsDataList';

interface Props {
	countryCode: string;
	nodes: ApiNode[];
	cluster: ICluster;
	renderFooter?: boolean;
	latestMetric?: any;
}

export const NodeLocationMapPoint: React.FC<Props> = props => {
	const {
		countryCode,
		nodes = [],
		cluster,
		renderFooter,
		latestMetric: { isLoaded, lastUpdate, metric },
	} = props;

	const dispatch = useDispatch();
	const [popupOpened, setPopupOpened] = React.useState(false);
	const [activeNodeIdx, setActiveNodeIdx] = React.useState(0);
	const [prepMetricLists, setPrepMetricLists] = React.useState<MetricsListItem[][]>([]);

	const isMetricUpToDate = (): boolean => {
		const oneMinute = 60 * 1000;
		return isLoaded && Date.now() - lastUpdate < oneMinute;
	};

	const handleOpen = () => {
		setPopupOpened(true);
		if (!isMetricUpToDate()) {
			dispatch(getLatestClusterMetrics({ clusterName: cluster.name }));
		}
	};

	const handlePrevNode = () => {
		if (activeNodeIdx === 0) return;
		setActiveNodeIdx(activeNodeIdx - 1);
	};

	const handleNextNode = () => {
		if (activeNodeIdx + 1 === nodes.length) return;
		setActiveNodeIdx(activeNodeIdx + 1);
	};

	React.useEffect(() => {
		if (metric && nodes.length) {
			const prepMetricLists: MetricsListItem[][] = [];
			nodes.forEach((_, idx) => {
				const metricList = [
					{ title: 'CPU', measurement: '%', value: metric[idx]?.[`cpu.load`], digitsAfterDot: 0 },
					{ title: 'RAM', measurement: '%', value: metric[idx]?.[`mem.usage`], digitsAfterDot: 0 },
					{ title: 'Bandwidth', measurement: 'B', value: metric[idx]?.[`net.bandwidth`] },
				];

				prepMetricLists.push(metricList);
			});
			setPrepMetricLists(prepMetricLists);
		}
	}, [metric, nodes]);

	return (
		<Popup
			on="hover"
			hoverable
			trigger={<MapDot active={popupOpened} />}
			onOpen={handleOpen}
			onClose={() => setPopupOpened(false)}
			className={styles.popup}
			style={{
				border: '1px solid #d3e6fb',
				boxShadow: 'none',
			}}
			position={'top center'}
			content={
				<div className={styles.popup}>
					<div className={styles.popup__header}>
						<div className={styles.popup__header_left}>
							<FlagIcon countryCode={countryCode} className={styles.popup__header__flag} />
							<span className={styles.popup__header__nodeName}>{nodes[activeNodeIdx]?.name}</span>
						</div>

						<div className={styles.popup__header_right}>
							<span className={styles.popup__header__nodeCount}>
								{`${activeNodeIdx + 1} of ${nodes.length}`}
							</span>

							<div className={styles.popup__header__ctrl}>
								<SvgIcon
									iconSrc={activeNodeIdx === 0 ? arrowLeftDisabled : arrowLeft}
									className={classnames(styles.popup__header__ctrl_left, {
										[styles.popup__header__ctrl_disabled]: activeNodeIdx === 0,
									})}
									onClick={handlePrevNode}
								/>

								<SvgIcon
									iconSrc={activeNodeIdx + 1 === nodes.length ? arrowLeftDisabled : arrowLeft}
									className={classnames(styles.popup__header__ctrl_right, {
										[styles.popup__header__ctrl_disabled]: activeNodeIdx + 1 === nodes.length,
									})}
									onClick={handleNextNode}
								/>
							</div>
						</div>
					</div>

					<div className={styles.popup__content}>
						{!isMetricUpToDate && (
							<div className={styles.popup__spinnerWrapper}>
								<AppSpinner className={styles.popup__spinner} />
							</div>
						)}

						<MetricsDataList list={prepMetricLists[activeNodeIdx] || []} />

						{renderFooter && (
							<div className={styles.popup__footer}>
								<div className={styles.icon}>
									<SvgIcon iconSrc={regionsLogo} />
								</div>
								<span
									className={styles.text}
									onClick={navigateTo(CLUSTER_NODES_URL.format({ name: cluster.name }))}
								>
									See all nodes
								</span>
							</div>
						)}
					</div>
				</div>
			}
		/>
	);
};
