import { call, put, take, fork, cancel } from "redux-saga/effects";
import { eventChannel } from 'redux-saga'
import { getClustersMetricsApi } from "../api";
import {
    getClustersMetrics,
    subscribeClustersMetrics,
    unsubscribeClustersMetrics,
    getClustersMetricsSuccess,
    getClustersMetricsFail,
} from "../actions";

function intervalUpdate(eventName: string, delay: number) {
    return eventChannel(emitter => {
            const id = setInterval(() => {
                emitter(eventName);
            }, delay);
            return () => {
                clearInterval(id);
            }
        }
    )
}

const intervalGetClustersMetricsAction = 'GetClustersMetrics';

export function* watchGetClustersMetrics() {
    let intervalHandler: any;
    const subscribers = new Set();
    const updateInterval = 60 * 1000; // 1 minute

    while (true) {
        const action = yield take("*");
        if (subscribeClustersMetrics.is(action)) {
            subscribers.add(action.id);
            yield call(getClustersMetricsWorker);
            if (!intervalHandler) {
                intervalHandler = yield fork(watchSubscribeClustersMetrics, updateInterval);
            }
        }
        if (unsubscribeClustersMetrics.is(action)) {
            subscribers.delete(action.id);
            if (!subscribers.size && intervalHandler) {
                yield cancel(intervalHandler);
                intervalHandler = null;
            }
        }
        if (getClustersMetrics.is(action)) {
            yield call(getClustersMetricsWorker);
        }
    }
}

export function* watchSubscribeClustersMetrics(delay: number) {
    const channel = yield call(intervalUpdate, intervalGetClustersMetricsAction, delay);
    while (true) {
        const action = yield take(channel);
        if (action === intervalGetClustersMetricsAction) {
            yield call(intervalUpdateClustersMetricsWorker);
        }
    }
}

function* intervalUpdateClustersMetricsWorker() {
    yield put(getClustersMetrics({}));
}

function* getClustersMetricsWorker() {
    try {
        const result = yield call(getClustersMetricsApi);
        yield put(getClustersMetricsSuccess({ clustersMetrics: result }));
    } catch (e) {
        yield put(getClustersMetricsFail({ error: { message: e.message } }));
    }
}
