import * as React from 'react';
import styles from './style.module.scss';
import { StepLayoutWithLogo } from '../../components/layouts/DefaultStepLayout/StepLayoutWithLogo';
import { DeployWizardStepProps } from '../../interfaces';
import { PortMappingForm } from '@app/modules/deploy-wizard/components/forms';
import portMappingImage from '@images/port-mapping.png';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { portMappingStepMeta } from './meta';

export class PortMappingStep extends React.Component<DeployWizardStepProps> {
	render() {
		const { getPrevStep, getNextStep, step, prev, next, value } = this.props;
		const prevStep = getPrevStep();
		const nextStep = getNextStep();

		return (
			<StepLayoutWithLogo
				image={portMappingImage}
				title={portMappingStepMeta.title}
				subtitle={portMappingStepMeta.subtitle}
				imgClassName={styles.mainImg}
				controls={
					<div className={styles.controls}>
						{prevStep && (
							<StepNavigationButton stepName={prevStep.meta.btnText} type="prev" onClick={prev} />
						)}
						{nextStep && (
							<StepNavigationButton formId={step.name} stepName={nextStep.meta.btnText} type="next" />
						)}
					</div>
				}
			>
				<div className={styles.wrapper}>
					<div className={styles.content}>
						<PortMappingForm
							onSubmit={values => {
								next(step.name, values);
							}}
							formId={step.name}
							initialValues={value}
						/>
					</div>
				</div>
			</StepLayoutWithLogo>
		);
	}
}
