import { all, takeEvery } from 'redux-saga/effects';
import { signinUserSaga } from '../modules/user-sign-in';
import { accountSaga } from '../modules/account';
import { clustersListSaga } from '../modules/clusters-list';
import { clusterSaga } from '../modules/cluster';
import { registriesSaga } from '../modules/registries';
import { referralSaga } from '../modules/referral/state/sagas/index';
import { regionsSaga } from '@app/data-modules/regions';
import { sizesSaga } from '@app/data-modules/sizes/sagas';
import { wizardSaga } from '@app/modules/deploy-wizard/state/saga';
import { clusterNodesSaga } from '@app/data-modules/cluster-nodes/sagas';
import { teamSaga } from '../modules/team';
import { LOCATION_CHANGE } from 'connected-react-router';
import { locationChangeSaga } from './locationChangeSaga';
import { imageCaheSaga } from './imageCacheSaga';
import { billingSaga } from '../modules/billing-wizard';
import { watchGetHealthStatuses, watchOnLocChangeGetHealthStatuses } from '@app/data-modules/healthStatuses';

export default function* rootSaga() {
	yield all([
		signinUserSaga(),
		accountSaga(),
		clustersListSaga(),
		clusterSaga(),
		registriesSaga(),
		referralSaga(),
		regionsSaga(),
		sizesSaga(),
		wizardSaga(),
		clusterNodesSaga(),
		teamSaga(),
		takeEvery(LOCATION_CHANGE, locationChangeSaga),
		imageCaheSaga(),
		billingSaga(),
		watchOnLocChangeGetHealthStatuses(),
		watchGetHealthStatuses(),
	]);
}
