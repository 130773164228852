import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styles from './style.module.scss';
import { Popup } from 'semantic-ui-react';
import { NodeOption } from './interface';
import { NodeOptionsList } from './NodeOptionsList';
import { ApiNode, deleteClusterNodeAction } from '@app/data-modules/cluster-nodes';
import { ICluster } from '@app/modules/cluster/components/cluster';
import classnames from 'classnames';

import dots from '@icons/icon.dots.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { RemoveItem } from '@app/shared/removeItem';
import { compose } from 'redux';
import { connect } from 'react-redux';

interface InputProps {
	options: NodeOption[];
	node: ApiNode;
	cluster: ICluster;
	isReadonlyCluster: boolean;
}

interface Props extends InputProps {
	deleteNode: () => void;
}

const NodeOptionsDumb: React.FC<Props> = ({ options, node, cluster, deleteNode, isReadonlyCluster }) => {
	const [opened, setOpened] = useState<boolean>(false);
	const [showRemoveMsg, setShowRemoveMsg] = useState<boolean>(false);
	const [optionsToRender, setOptionsToRender] = useState<NodeOption[]>([]);
	const controls = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isReadonlyCluster) {
			setOptionsToRender(options.filter(opt => opt.id !== 'remove-node-option'));
		} else {
			setOptionsToRender(options);
		}
	}, [isReadonlyCluster, options]);

	useLayoutEffect(() => {
		const handler = (e: any) => {
			e.preventDefault();
			e.stopPropagation();

			if (
				!(e.target.dataset.stayopen && parseInt(e.target.dataset.nodeid, 10) === node.id) &&
				!controls.current?.contains(e.target)
			) {
				setOpened(false);
			} else {
				setOpened(true);
			}
		};

		document.addEventListener('click', handler);

		return () => {
			document.removeEventListener('click', handler);
		};
	});

	return (
		<>
			<div className={styles.trigger} onClick={() => setOpened(!opened)} ref={controls}>
				<Popup
					content={
						<div>
							<NodeOptionsList
								options={optionsToRender}
								cluster={cluster}
								setShowRemoveMsg={setShowRemoveMsg}
							/>
						</div>
					}
					className={classnames(styles.popup)}
					position={'bottom right'}
					trigger={<SvgIcon iconSrc={dots} />}
					open={opened}
				/>
			</div>
			{showRemoveMsg && (
				<RemoveItem
					msg={`Are you sure want delete node ${node.name}?`}
					onDismiss={() => {
						setShowRemoveMsg(false);
					}}
					onAgree={() => {
						deleteNode();
						setShowRemoveMsg(false);
					}}
					btnAgreeText="Delete"
					btnDismissText="Dismiss"
				/>
			)}
		</>
	);
};

export const NodeOptions = compose<React.ComponentType<InputProps>>(
	connect(null, (dispatch, ownProps: Props) => {
		return {
			deleteNode: () =>
				dispatch(
					deleteClusterNodeAction({
						clusterName: ownProps.cluster.name,
						nodeHostname: ownProps.node.hostname,
					})
				),
			isReadonlyCluster: ownProps.isReadonlyCluster,
		};
	})
)(NodeOptionsDumb);
