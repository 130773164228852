import api from '@app/apis/api';
import { ApiNode, ApiNodeDTO } from './entity';

// GET /api/cluster/{clusterId}/node
export const getClusterNodesRequest = async (id: string): Promise<ApiNode[]> =>
	await api.get(`/api/cluster/${encodeURIComponent(id)}/node`);

// DELETE /api/cluster/{clusterId}/node/{hostname}
export const deleteClusterNodeRequest = async (id: string, hostName: string): Promise<void> =>
	await api.delete(`/api/cluster/${encodeURIComponent(id)}/node/${encodeURIComponent(hostName)}`);

// POST /api/cluster/{clusterId}/node
export const addClusterNodeRequest = async (id: string, nodes: ApiNodeDTO[]): Promise<void> =>
	await api.post(`/api/cluster/${id}/node`, { nodes });
