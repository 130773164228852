import { call, take, put, delay, fork } from 'redux-saga/effects';
import { addDomainApi } from '../api';
import { addDomain, setDomainsError } from '../actions';
import { AddDomainValues } from '../../components/clusterSettings/clusterSettingsDomain/addDomain';
import { MetaError } from '../../../../types/metaError';
import { getClusterDomainsWorker } from './getClusterDomains';
import { watchCancelTaskOnClusterSettingsLeaveWorker } from './watchCancelTaskOnClusterSettingsLeaveWorker';
import { ClusterDomainStatus } from '../../components/clusterSettings/clusterSettingsDomain/domainsTable';

export const REQUEST_DELAY = 3000;

export function* watchAddDomain() {
	while (true) {
		const action = yield take('*');
		if (addDomain.is(action)) {
			const task = yield fork(addDomainWorker, { ...action });
			yield fork(watchCancelTaskOnClusterSettingsLeaveWorker, { task, delayValue: REQUEST_DELAY });
		}
	}
}

function* addDomainWorker({
	values,
	clusterName,
	handleSuccess,
	handleFinally,
}: {
	values: AddDomainValues;
	clusterName: string;
	handleSuccess: () => void;
	handleFinally: () => void;
}) {
	try {
		yield call(addDomainApi, { values, clusterName });
		yield call(handleSuccess);
		// loops request to get domains until the currently created domain is returns with status VALID or FAILED
		while (true) {
			// we have no enpoint to get single domain, only to get the list of
			const res = yield call(getClusterDomainsWorker, { clusterName });
			// so we should fine the one we just created
			const createdDomain = res.find((domain: { name: string }) => domain.name === values.customDomain);
			// and then check the status, if it is neither failed nor valid we should keep calling domains
			if (
				createdDomain?.status === ClusterDomainStatus.VALID ||
				createdDomain?.status === ClusterDomainStatus.FAILED
			) {
				break;
			}
			yield delay(REQUEST_DELAY);
		}
	} catch (e) {
		const meta = e?.response?.data?.errors;
		yield put(setDomainsError({ error: new MetaError(meta ? { message: e.message } : e, meta) }));
	} finally {
		yield call(handleFinally);
	}
}
