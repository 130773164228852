import { customYup as yup, yupExceptions } from '@app/utils/customYup';
import { envVarSchema } from '@app/validation-schemas/EnvironmentVariablesSchema';
import { portsSchema } from '@app/validation-schemas/PortsSchema';
import { dockerImageSchema } from '@app/validation-schemas/DockerImageSchema';

export const validationSchema = yup.object().shape({
	currentVersion: yup.object().shape({
		image: dockerImageSchema.fields.image,
		tag: dockerImageSchema.fields.tag,
		env: envVarSchema,
		ports: portsSchema.validatePortMappingForHttps({tests: ['public']}),
		httpsPort: yup
		.string()
		.matches(yupExceptions.isNotNumber.regex, yupExceptions.isNotNumber.message)
		.matches(yupExceptions.isNotAvailablePort.regex, yupExceptions.isNotAvailablePort.message)
		.nullable()
	})
});
