import * as React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props {
	children: any;
	className?: string;
}

export const SectionContainer = (props: Props) => {
	return <div className={classnames(styles.appCardSectionContainer, props.className)}>{props.children}</div>;
};
