import React, { useCallback, useEffect, useState, useRef } from 'react';
import styles from '../styles.module.scss';
import btnsStyles from '@app/styles/buttons.module.scss';
import { ClusterSettingsDocker } from '../ClusterSettingsDocker';
import { ClusterSettingsDomain } from '../clusterSettingsDomain';
import { ClusterSettingsWebhook } from '../ClusterSettingsWebhook';
import { ClusterSettingsPermissions } from '../clusterSettingsPermissions';
import { ClusterSettingsNavMenu } from '../ClusterSettingsNavMenu';
import { ClusterSettingsLocalCache } from '../clusterSettingsLocalCache';
import { AppState } from '@app/reducers';
import { FormikProps, withFormik, FormikErrors, FormikTouched } from 'formik';
import { compose } from 'recompose';
import { ErrorMessage } from '@app/shared/errorMessage';
import { connect } from 'react-redux';
import { useScrollPosition } from 'react-use-scroll-position';
import classnames from 'classnames';
import { ICluster } from '@app/modules/cluster';
import { AnchorSection, AnchorSectionNavigation } from '@app/shared/AnchorSectionNavigation';
import { ClusterPageLayout } from '../../layouts/ClusterPageLayout';
import { ClusterSettingsRebuildOrDelete } from '../rebuildOrDelete';
import {
	pullAndDeployClusterAction,
	resetPullAndDeployClusterAction,
	updateClusterAction,
	clearClusterError,
	enableHttpsPort,
	disableHttpsPort,
	clearHttpsPortErrorAction,
} from '../../../state/actions';
import { AppRequestButton } from '@app/shared/AppRequestButton';
import { Dispatch } from 'redux';
import { getWizardStatus } from '@app/utils/getWizardStatus';
import { PullAndDeployState } from '@app/modules/deploy-wizard/state/state';
import { CLUSTER_SETTINGS_URL } from '../../../state';
import { RouteLeavingGuard } from '@app/shared/RouteLeavingGuard';
import { ClusterDomain } from '../clusterSettingsDomain/domainsTable';
import hash from 'object-hash';
import _ from 'lodash';
import { validationSchema } from './validation-schema';
import { EnvironmentFieldList, EnvironmentFormValue } from '@app/shared/EnvironmentFieldList/EnvironmentFieldList';
import { LdsSpinner } from '@app/shared/ldsSpinner/LdsSpinner';
import { PortMappingFieldList, PortMappingFormValue } from '@app/shared/PortMappingFieldList/PortMappingFieldList';
import { MetaError } from '@app/types/metaError';
import { BtnStyles, WarningDialog } from '@app/shared/warningDialog/WarningDialog';
import { ClusterSettingsHttpsInfo } from '../clusterSettingsHttpsInfo';

export interface ClusterVersion {
	version: number;
	image: string;
	tag: string;
	digest: string;
	createdAt: string;
	env: EnvironmentFormValue[];
	ports: PortMappingFormValue[];
	httpsPort: number | null;
}

export interface ClusterSettingsInterface {
	id: number;
	name: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	currentVersion: ClusterVersion;
	registry: {
		url: string;
		login: string;
		password: string;
	};
	dirty?: boolean;
}

interface ClusterSettingsProps {
	cluster: ICluster;
	clusterError: MetaError;
	pullAndDeployState: PullAndDeployState;
	clusterDomains: ClusterDomain[];
	pullAndDeploy: (clusterName: string) => void;
	updateCluster: (cluster: ICluster) => void;
	resetPullAndDeployState: (clusterName: string) => void;
	clearClusterError: () => void;
	getIsDirty: (dirty: boolean) => void;
	disableHttpsPort: ({ clusterName, onSuccess }: { clusterName: string; onSuccess: () => void }) => void;
	enableHttpsPort: ({
		httpsPort,
		clusterName,
		onSuccess,
	}: {
		httpsPort: number;
		clusterName: string;
		onSuccess: () => void;
	}) => void;
	httpsError: MetaError;
	clearHttpsPortError: () => void;
	httpsReqInProgress: boolean;
	isReadonlyCluster: boolean;
}

const fixNavbar = 130;
const fixMenuOnY = fixNavbar;

const ClusterSettingsComponent: React.FC<ClusterSettingsProps & FormikProps<ClusterSettingsInterface>> = props => {
	const [showPortsWarning, setShowPortsWarning] = useState(false);
	const [handledErrorMsg, setHandledErrorMsg] = useState({ message: '' });
	const [initialHash, setInitialHash] = useState<string | null>(null);
	const [presentHash, setPresentHash] = useState<string | null>(null);
	const [conservedHash, setConservedHash] = useState<string | null>(null);
	const [httpsChanged, setHttpsChanged] = useState(false);
	const {
		values,
		values: { currentVersion, dirty, name: clusterName },
		handleChange,
		setFieldValue,
		handleSubmit,
		pullAndDeployState,
		cluster,
		resetPullAndDeployState,
		clusterDomains,
		clusterError,
		clearClusterError,
		isSubmitting,
		setSubmitting,
		getIsDirty,
		handleBlur,
		errors,
		touched,
		enableHttpsPort,
		disableHttpsPort,
		httpsError,
		clearHttpsPortError,
		httpsReqInProgress,
		isReadonlyCluster,
		resetForm,
	} = props;
	const { y } = useScrollPosition();
	const checkPortsAndSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		// check if there is no ports
		const isPorts =
			currentVersion?.ports?.length > 0 &&
			typeof currentVersion?.ports[0].private !== 'undefined' &&
			currentVersion?.ports[0].private !== null &&
			currentVersion?.ports[0].private !== '' &&
			typeof currentVersion?.ports[0].public !== 'undefined' &&
			currentVersion?.ports[0].public !== null &&
			currentVersion?.ports[0].public !== '';

		// if dirty then it is Deploy Settings flow and we need to check ports
		// else it is Pull-n-deploy flow and we should proceed submitting
		// if there are errors we just call submit so the form will be validated by formik
		// and errors was shown
		if (dirty && !errors.currentVersion) {
			// if httpsPort is present then we should not show the warning about empty ports
			if (isPorts || currentVersion.httpsPort) {
				handleSubmit(e);
			} else {
				e?.preventDefault();
				setShowPortsWarning(true);
			}
		} else {
			handleSubmit(e);
		}
	};

	const onResetState = useCallback(() => {
		resetPullAndDeployState(cluster.name);
	}, [resetPullAndDeployState, cluster.name]);

	const deployStatus = getWizardStatus(pullAndDeployState[cluster.name]?.deploy);

	// conserve presentHash in case request will be successful to set it as a new initial hash
	useEffect(() => {
		if (isSubmitting && deployStatus === 'initial') {
			setConservedHash(presentHash);
		}
	}, [isSubmitting, deployStatus, presentHash]);

	// handle submitting based on deployStatus
	useEffect(() => {
		if (deployStatus === 'success') {
			setSubmitting(false);
			// on success request set conservedHash as new initial hash
			setInitialHash(conservedHash);
		} else if (deployStatus === 'loading') {
			setSubmitting(true);
		} else {
			setSubmitting(false);
			// clear conserved hash on request failure
			setConservedHash(null);
		}
	}, [deployStatus, setSubmitting, conservedHash]);

	// handle currentVersion hash (present and initial)
	useEffect(() => {
		const createCurrentVersionHash = () => {
			// always get the same sorting before hashing
			let envSorted: any = _.sortBy(currentVersion.env, ['key', 'value']);
			let portsSorted: any = _.sortBy(currentVersion.ports, ['public', 'private', 'protocol']);
			// since  some data came in string and some in numbers and then modified to strings
			// convert all to string to be sure hash is correct for the same objects
			// type swap found on envs and ports values
			envSorted = envSorted.map((env: any) => {
				const envCopy = { ...env };
				for (let key in envCopy) {
					if (envCopy.hasOwnProperty(key)) {
						envCopy[key] = String(envCopy[key]);
					}
				}
				return envCopy;
			});
			portsSorted = portsSorted.map((port: any) => {
				const portCopy = { ...port };
				for (let key in portCopy) {
					if (portCopy.hasOwnProperty(key)) {
						portCopy[key] = String(portCopy[key]);
					}
				}
				return portCopy;
			});

			// since in request we are using envs, ports, tag and image
			return hash([...envSorted, ...portsSorted, currentVersion.tag, currentVersion.image]);
		};
		let currVerHash = null;

		if (currentVersion?.env && currentVersion?.ports) {
			currVerHash = createCurrentVersionHash();
		}

		if (currVerHash && initialHash) {
			// on every change of currentVersion reset presentHash
			setPresentHash(currVerHash);
		} else if (currVerHash && !initialHash) {
			// set initial hash, also set equal presentHash for the initial state of  currentVersion
			setInitialHash(currVerHash);
			setPresentHash(currVerHash);
		}
	}, [currentVersion, initialHash, setInitialHash, setFieldValue]);

	// set dirty or not on hash change
	useEffect(() => {
		setFieldValue('dirty', initialHash !== presentHash);
	}, [initialHash, presentHash, setFieldValue]);

	// allow parent component to get whether settings dirty or not
	useEffect(() => {
		getIsDirty(dirty || false);
	}, [dirty, getIsDirty]);

	// handle errors, show regular err msg, show as regular err msg if EnvVars error
	// show default err msg if no regular msg
	useEffect(() => {
		if (clusterError) {
			setHandledErrorMsg({
				message:
					clusterError?.get('env') ||
					clusterError?.message ||
					(Object.keys(clusterError.meta).length === 0 &&
						'A fatal error occurred. Please try again or contact our support') ||
					'',
			});
		} else {
			setHandledErrorMsg({ message: '' });
		}
	}, [clusterError]);

	useEffect(() => {
		const errMsg = pullAndDeployState[clusterName]?.errorMessage;
		if (errMsg) {
			setHandledErrorMsg({ message: errMsg });
		} else {
			setHandledErrorMsg({ message: '' });
		}
	}, [pullAndDeployState, clusterName]);

	// clear error on page leaving
	useEffect(() => {
		return () => {
			clearClusterError();
		};
	}, [clearClusterError]);

	// to store previous value of httpsChanged
	const usePreviousHttpsChanged = (value: boolean) => {
		const ref = useRef(false);
		useEffect(() => {
			ref.current = value;
		});

		return ref.current;
	};

	const prevHttpsChanged = usePreviousHttpsChanged(httpsChanged);
	// since httpsPort is not a part of hash and request goes to the server separately from the deploy or settings saving
	// we need to detect whether HTTPS was enabled or disabled
	// since now ports is changing on the server after success of disabling or enabling HTTPS
	// we need to equalize initial and present hashes(which changed coz of ports added/removed by the server)
	// so initial hash should be set if  HTTPS port was changed, disabled or enabled
	// but this hook shouldnt be called when user modifies other data(it will try coz of presentHash in the dependencies)
	useEffect(() => {
		if (prevHttpsChanged !== httpsChanged) {
			setInitialHash(presentHash);
		}
	}, [httpsChanged, presentHash, prevHttpsChanged]);

	const inputsHaveError = Object.keys(errors).length > 0;
	return (
		<AnchorSectionNavigation initialActive={'docker'}>
			<RouteLeavingGuard block={dirty || false} onAgree={clearClusterError} />
			<ClusterPageLayout className={styles.clusterSettings}>
				<div
					className={classnames(styles.clusterSettings__content_wrapped__header, {
						[styles.clusterSettings__content_wrapped__header_fixed]: y > fixNavbar,
					})}
				>
					<ClusterPageLayout.Head
						titleSection={
							<div>
								<ErrorMessage
									useDefaultMsg={false}
									error={handledErrorMsg}
									withinPortal={false}
									className={styles.clusterSettings__errorMessage}
								/>
								<ClusterPageLayout.Title text="Settings" />
							</div>
						}
						actionSection={
							<AppRequestButton
								className={classnames(btnsStyles.btnOne, styles.clusterSettings__head__btn)}
								statusText={{
									initial: 'Pull and Deploy',
									dirty: 'Deploy Settings',
									loading: 'Deploying',
									success: 'Deployed',
									error: 'Failed',
								}}
								status={
									dirty && getWizardStatus(pullAndDeployState[cluster.name]?.deploy) === 'initial'
										? 'dirty'
										: getWizardStatus(pullAndDeployState[cluster.name]?.deploy)
								}
								onClick={checkPortsAndSubmit}
								onResetState={onResetState}
								disabled={inputsHaveError || isReadonlyCluster}
								isDirty={dirty}
								handleDirtyBtn={() => {
									clearClusterError();
									resetForm();
								}}
							/>
						}
					/>
				</div>
				<div className={styles.decorBorder} />
				<ClusterPageLayout.Content className={styles.clusterSettings__content}>
					<div className={styles.clusterSettings__content_wrapped}>
						<ClusterSettingsNavMenu
							additionalClass={classnames({
								[styles.clusterSettings__content_wrapped__navMenu_fixed]: y > fixMenuOnY,
							})}
						/>

						<div
							className={classnames(styles.clusterSettings__content_wrapped__scrollWrap, {
								[styles.clusterSettings__content_wrapped__scrollWrap_fixed]: y > fixMenuOnY,
							})}
						>
							<div className={styles.clusterSettings__content_wrapped__body}>
								<AnchorSection sectionId="docker">
									<ClusterSettingsDocker
										handleChange={handleChange}
										image={currentVersion?.image}
										tag={currentVersion?.tag}
										loading={!Object.keys(cluster).length}
										isSubmitting={isSubmitting}
										errors={{
											image: errors.currentVersion?.image || '',
											tag: errors.currentVersion?.tag || '',
										}}
										isReadonlyCluster={isReadonlyCluster}
										reqError={clusterError}
										setFieldValue={setFieldValue}
									/>
								</AnchorSection>

								<AnchorSection sectionId="cache">
									<ClusterSettingsLocalCache
										directory={'/cache'}
										loading={!Object.keys(cluster).length}
										isSubmitting={isSubmitting}
									/>
								</AnchorSection>

								<AnchorSection sectionId="env-vars">
									<div id="env-vars">
										<div className={styles.clusterSettings__content_wrapped__body__blockHead}>
											{'Environment Variables'}
											{!Object.keys(cluster).length && (
												<LdsSpinner
													className={
														styles.clusterSettings__content_wrapped__body__blockHead__spinner
													}
												/>
											)}
										</div>

										<EnvironmentFieldList
											className={styles.envVars}
											values={values}
											onChange={isSubmitting || isReadonlyCluster ? () => {} : handleChange}
											onBlur={isSubmitting || isReadonlyCluster ? () => {} : handleBlur}
											errors={errors as FormikErrors<{ env: EnvironmentFormValue[] }>}
											touched={touched as FormikTouched<{ env: EnvironmentFormValue[] }>}
											pathToValues="currentVersion.env"
											disabled={isSubmitting || isReadonlyCluster}
											setFieldValue={setFieldValue}
										/>
									</div>
								</AnchorSection>

								<AnchorSection sectionId="port">
									<div id="port">
										<div className={styles.clusterSettings__content_wrapped__body__blockHead}>
											{'Port Mapping'}
											{!Object.keys(cluster).length && (
												<LdsSpinner
													className={
														styles.clusterSettings__content__wrapped__body__blockHead__spinner
													}
												/>
											)}
										</div>
										{cluster.currentVersion?.httpsPort ? <ClusterSettingsHttpsInfo /> : null}
										<PortMappingFieldList
											className={styles.ports}
											blurHandler={isSubmitting || isReadonlyCluster ? () => {} : handleBlur}
											touched={touched as FormikTouched<{ ports: PortMappingFormValue[] }>}
											errors={errors as FormikErrors<{ ports: PortMappingFormValue[] }>}
											changeHandler={isSubmitting || isReadonlyCluster ? () => {} : handleChange}
											values={values}
											setFieldValue={isSubmitting || isReadonlyCluster ? () => {} : setFieldValue}
											pathToValues="currentVersion.ports"
											disabled={isSubmitting || isReadonlyCluster}
											requestError={clusterError}
										/>
									</div>
								</AnchorSection>

								<AnchorSection sectionId="domain">
									<ClusterSettingsDomain
										clusterDomains={clusterDomains}
										cluster={cluster}
										handleChange={handleChange}
										isSubmitting={isSubmitting}
										currentVersionValues={currentVersion}
										formErrors={errors}
										handleBlur={handleBlur}
										enableHttpsPort={enableHttpsPort}
										disableHttpsPort={disableHttpsPort}
										httpsError={httpsError}
										setFieldValue={setFieldValue}
										clearHttpsPortError={clearHttpsPortError}
										httpsReqInProgress={httpsReqInProgress}
										settingsUnsaved={initialHash !== presentHash}
										isReadonlyCluster={isReadonlyCluster}
										onHttpsChanged={setHttpsChanged}
									/>
								</AnchorSection>

								<AnchorSection sectionId="webhook">
									<ClusterSettingsWebhook url={'Coming soon'} isReadonlyCluster={isReadonlyCluster} />
								</AnchorSection>

								<AnchorSection sectionId="permissions">
									<ClusterSettingsPermissions isReadonlyCluster={isReadonlyCluster} />
								</AnchorSection>

								<AnchorSection sectionId="rebuildOrDelete">
									<ClusterSettingsRebuildOrDelete
										clusterName={clusterName}
										disabled={isReadonlyCluster}
									/>
								</AnchorSection>
							</div>
						</div>
					</div>
				</ClusterPageLayout.Content>
			</ClusterPageLayout>

			{showPortsWarning ? (
				<WarningDialog
					msg={`Are you sure you want to proceed? If you don’t add any ports your cluster won’t be accessible!`}
					btnAgreeText={'Proceed'}
					btnDismissText={'Go Back'}
					btnAgreeStyle={BtnStyles.AGREE_REGULAR}
					onAgree={e => {
						handleSubmit(e as React.FormEvent<HTMLFormElement>);
						setShowPortsWarning(false);
					}}
					onDismiss={() => {
						setShowPortsWarning(false);
					}}
				/>
			) : null}
		</AnchorSectionNavigation>
	);
};

export const ClusterSettings = compose<
	ClusterSettingsProps & FormikProps<ClusterSettingsInterface>,
	{ getIsDirty: (dirty: boolean) => void; isReadonlyCluster: boolean }
>(
	connect(
		(state: AppState, ownProps: { getIsDirty: (dirty: boolean) => void; isReadonlyCluster: boolean }) => ({
			clusterError: state.cluster.error,
			cluster: state.cluster.cluster,
			pullAndDeployState: state.wizard.pullAndDeployState,
			clusterDomains: state.cluster.clusterDomains,
			getIsDirty: ownProps.getIsDirty,
			httpsError: state.cluster.httpsError,
			httpsReqInProgress: state.cluster.httpsReqInProgress,
			isReadonlyCluster: ownProps.isReadonlyCluster,
		}),
		(dispatch: Dispatch) => ({
			pullAndDeploy: (clusterName: string) =>
				dispatch(
					pullAndDeployClusterAction({ clusterName, url: CLUSTER_SETTINGS_URL.format({ name: clusterName }) })
				),
			resetPullAndDeployState: (clusterName: string) => {
				dispatch(resetPullAndDeployClusterAction({ clusterName }));
			},
			updateCluster: (cluster: ICluster) => dispatch(updateClusterAction({ cluster })),
			clearClusterError: (_: any) => dispatch(clearClusterError({})),
			enableHttpsPort: ({
				httpsPort,
				clusterName,
				onSuccess,
			}: {
				httpsPort: number;
				clusterName: string;
				onSuccess: () => void;
			}) => dispatch(enableHttpsPort({ httpsPort, clusterName, onSuccess })),
			disableHttpsPort: ({ clusterName, onSuccess }: { clusterName: string; onSuccess: () => void }) =>
				dispatch(disableHttpsPort({ clusterName, onSuccess })),
			clearHttpsPortError: () => dispatch(clearHttpsPortErrorAction({})),
		})
	),
	withFormik({
		enableReinitialize: true,
		mapPropsToValues: ({ cluster }: { cluster: ICluster }) => {
			if (Object.keys(cluster).length && cluster.currentVersion) {
				const initialEnv = Object.entries(cluster.currentVersion?.env || {}).map(([key, value]) => ({
					key,
					value,
				}));
				return {
					...cluster,
					currentVersion: {
						...cluster.currentVersion,
						env: [...initialEnv, { key: '', value: '' }],
						ports: [...cluster.currentVersion.ports, { public: '', private: '', protocol: 'tcp' }],
					},
					dirty: false,
				};
			}
			return {};
		},
		validationSchema,

		handleSubmit: (values: any, { props }) => {
			const result = { ...values };
			result.currentVersion = { ...values.currentVersion };
			result.currentVersion.ports = [...values.currentVersion.ports];

			if (!result.dirty) {
				(props as ClusterSettingsProps).pullAndDeploy((props as ClusterSettingsProps).cluster.name);
				return;
			}

			let ports = result.currentVersion.ports;

			for (let i = 0; i < ports.length; i++) {
				if (ports[i].public === '' || ports[i].private === '') {
					ports.splice(i, 1);
				} else {
					ports[i] = {
						public: parseInt(ports[i].public),
						private: parseInt(ports[i].private),
						protocol: ports[i].protocol,
					};
				}
			}

			let envObj: { [key: string]: string } = {};

			for (let env of result.currentVersion.env) {
				if (env.key !== '' && env.value !== '') {
					envObj[env.key] = env.value;
				}
			}

			result.currentVersion.env = envObj;

			delete result.currentVersion.version;
			delete result.currentVersion.digest;
			delete result.currentVersion.createdAt;
			delete result.currentVersion.httpsPort;
			delete result.currentVersion.registry;

			(props as ClusterSettingsProps).updateCluster(result);
		},

		displayName: 'ClusterSettings',
	})
)(ClusterSettingsComponent);
