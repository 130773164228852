import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { Popup } from 'semantic-ui-react';
import { MapDot } from '@app/shared/MapComponent';

interface Props {
	regionName: string;
	flagName: string;
	active?: boolean;
	open?: boolean;
	className?: string;
	onClick?: () => void;
}

function getRandomInt(max: number): number {
	return Math.floor(Math.random() * Math.floor(max));
}

export const RegionMapPoint = (props: Props) => {
	const { regionName, flagName, active, className, open } = props;
	const [pos, updatePos] = React.useState('top center');
	const ref = React.useRef<HTMLDivElement>(null);

	React.useLayoutEffect(() => {
		if (!ref.current) return;
		const { current } = ref;

		const positions: Record<string, string[]> = {
			x: ['left', 'right'],
			y: ['top', 'bottom'],
		};

		const nextState: string[] = ['top', 'center'];

		const siblingDots = Array.from(document.querySelectorAll(`div[data-dot="isDot"]`)).filter(
			node => node !== ref.current
		); // excluding current node

		const { x, y, width } = current.getBoundingClientRect();

		const circle = width;

		const conflictingDots = siblingDots.filter(dot => {
			const rect = dot.getBoundingClientRect();

			const xDistanceAbs = Math.abs(rect.x - x);
			const yDistanceAbs = Math.abs(rect.y - y);

			if (xDistanceAbs <= circle || yDistanceAbs <= circle) return true;
			return undefined;
		});

		for (let dot of conflictingDots) {
			const { x: dot_x, y: dot_y } = dot.getBoundingClientRect();

			const x_res = x - dot_x;
			const y_res = y - dot_y;

			const x_axis = Math.sign(x_res) === -1 ? 'right' : 'left';
			const y_axis = Math.sign(y_res) === -1 ? 'bottom' : 'top';

			if (Math.abs(dot_x - x) <= circle) {
				positions.x = positions.x.filter(xPos => xPos !== x_axis);
			}

			if (Math.abs(dot_y - y) <= circle) {
				positions.y = positions.y.filter(yPos => yPos !== y_axis);
			}
		}

		if (positions.y.length) {
			nextState[0] = positions.y[getRandomInt(positions.y.length - 1)] || 'top';
		} else {
			nextState[0] = positions.x[getRandomInt(positions.x.length - 1)] || 'left';
		}

		updatePos(nextState.join(' '));
	}, [active]);

	const trigger = (
		<MapDot
			className={className}
			data-dot="isDot"
			data-pos={pos}
			onClick={props.onClick}
			componentRef={ref}
			active={active}
		/>
	);

	const isOpened = active || open;
	return (
		<Popup
			trigger={trigger}
			position={pos as any}
			{...(isOpened ? { open: isOpened, 'data-pos': 'rendered' } : {})}
			hoverable
			className={classnames(styles.popup, { [styles.openedAndNotActive]: !active && open })}
		>
			<div className={styles.content}>
				<i className={classnames('flag-icons square', styles.flagIcon, flagName)} />
				<span className={styles.label}>{regionName}</span>
			</div>
		</Popup>
	);
};
