import { Action } from 'redux';
import { getRegionsAction, setRegionsAction } from './actions';
import { RegionsState } from './state';

const INITIAL_STATE = {
	regions: [],
	isLoaded: false,
};

export function regionsReducer(state: RegionsState = INITIAL_STATE, action: Action) {
	if (setRegionsAction.is(action)) {
		return {
			...state,
			isLoaded: true,
			regions: action.payload,
		};
	}

	if (getRegionsAction.is(action)) {
		return {
			...state,
			isLoaded: false,
		};
	}

	return state;
}
