import { Action } from 'redux';
import { BillingWizardState } from './state';
import { resetBillingWizardAction, setStripeClientSecret, setBillingInfoError } from './actions';

const INITIAL_STATE = {
	clientSecret: '',
	errorMessage: '',
	billingInfo: {
		error: {
			message: '',
		},
	},
};

export function billingWizardReducer(state = INITIAL_STATE, action: Action): BillingWizardState {
	if (setStripeClientSecret.is(action)) {
		return {
			...state,
			clientSecret: action.clientSecret,
		};
	}

	if (setBillingInfoError.is(action)) {
		return {
			...state,
			billingInfo: {
				...state.billingInfo,
				error: action.error,
			},
		};
	}

	if (resetBillingWizardAction.is(action)) {
		return INITIAL_STATE;
	}

	return state;
}
