import { customYup as yup, yupExceptions } from '@app/utils/customYup';

export const schema = yup.object().shape({
	firstName: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isFirstNameValid.regex, yupExceptions.isFirstNameValid.message)
		.required('First Name is required'),
	lastName: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isLastNameValid.regex, yupExceptions.isLastNameValid.message)
		.required('Last Name is required'),
	company: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isValidCompanyName.regex, yupExceptions.isValidCompanyName.message),
	vatId: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message),
	address: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('Address is required'),
	city: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('City is required'),
	postalCode: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('Zipcode is required'),
	country: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.required('Country is required'),
});
