import * as React from "react";
import styles from "./style.module.scss";
import classnames from 'classnames';

interface Props {
    className?: string;
    countryCode: string;
    label: string;
    children: JSX.Element;
}

export const RegionBar = (props: Props) => {
    return (
        <div className={classnames(styles.regionBar, props.className)}>
            <div className={styles.labelWrapper}>
            <i className={classnames("flag-icons square", props.countryCode, styles.flagIcon)}></i>
                <span className={styles.label}>{props.label}</span>
            </div>
            <div className={styles.contentWrapper}>{props.children}</div>
        </div>
    );
};
