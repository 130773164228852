import * as React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

interface Props {
	children: JSX.Element;
	onOuterClick?: Function;
	className?: string;
}

export const WideScreenModalLayout = (props: Props) => {
	const { onOuterClick, className, children } = props;
	return (
		<div className={classnames(styles.wrapper, className)} onClick={e => onOuterClick?.(e)}>
			<div className={styles.content} onClick={e => e.stopPropagation()}>
				{children}
			</div>
		</div>
	);
};
