import React from 'react';
import styles from './style.module.scss';
import { SvgIcon } from '@app/shared/SvgIcon';
import cardLayout from '@layouts/card.layout.svg';
import addIcon from '@icons/icon-16-add-blue.svg';
import { useBillingInfoWizardFullPartialOpener } from '@app/hooks';
import { useCheckerForAppReadonlyUser, useCheckerIsBiller } from '@app/hooks';

export const AddPaymentCardBtn: React.FC<{}> = () => {
	const billingOpener = useBillingInfoWizardFullPartialOpener();
	const { isReadonlyUser } = useCheckerForAppReadonlyUser();
	const { isBiller } = useCheckerIsBiller();
	if (isReadonlyUser && !isBiller) {
		return null;
	}

	return (
		<div
			onClick={isReadonlyUser && !isBiller ? () => {} : billingOpener}
			className={styles.addPaymentCardBtn__cardLayout}
		>
			<div className={styles.addPaymentCardBtn__cardLayout__cardStylization}>
				<SvgIcon
					iconSrc={cardLayout}
					className={styles.addPaymentCardBtn__cardLayout__cardStylization__cardLayoutIcon}
				/>

				<div className={styles.addPaymentCardBtn__cardLayout__cardStylization__addIconWrap}>
					<SvgIcon
						iconSrc={addIcon}
						className={styles.addPaymentCardBtn__cardLayout__cardStylization__addIconWrap__icon}
					/>
				</div>
			</div>

			<div className={styles.addPaymentCardBtn__addCardTitle}>{'Add a credit card'}</div>
		</div>
	);
};
