import * as React from 'react';
import styles from './style.module.scss';
import { DeployWizardForm } from '@app/modules/deploy-wizard/interfaces';
import { FormikProps, withFormik, Form } from 'formik';
import { schema } from './schema';
import { EnvironmentFieldList } from '@app/shared/EnvironmentFieldList';

interface FormValues {
	env: EnvironmentValueItem[];
}

export interface EnvironmentValueItem {
	key: string;
	value: string;
}

interface Props extends DeployWizardForm {
	initialValues: EnvironmentValueItem[];
}

const template: EnvironmentValueItem = {
	key: '',
	value: '',
};

class EnvironmentFormDumb extends React.Component<Props & FormikProps<FormValues>> {
	render() {
		const { formId, values, handleSubmit, handleChange, handleBlur, errors, touched, setFieldValue } = this.props;
		return (
			<Form id={formId} onSubmit={handleSubmit} className={styles.environmentListForm}>
				<EnvironmentFieldList
					values={values}
					onChange={handleChange}
					onBlur={handleBlur}
					errors={errors}
					touched={touched}
					pathToValues="env"
					setFieldValue={setFieldValue}
				/>
			</Form>
		);
	}
}

export const EnvironmentForm = withFormik<DeployWizardForm, any>({
	mapPropsToValues: props => {
		const mappedValues = Object.entries(props.initialValues?.env || {}).map(([key, value]) => {
			return { key, value };
		});
		return { env: [...mappedValues, template] };
	},
	validationSchema: schema,
	handleSubmit: (values: FormValues, { props: componentProps }) => {
		const mappedValues = values.env.reduce((obj, { key, value }) => {
			if (!key && !value) return obj;
			obj[key] = value;
			return obj;
		}, {} as any);

		componentProps.onSubmit(mappedValues);
	},
})(EnvironmentFormDumb);
