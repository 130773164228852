import * as React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props {
	className?: string;
	titleSection: JSX.Element;
	actionSection?: JSX.Element;
}

export const Head = (props: Props) => {
	const { className, titleSection, actionSection } = props;

	return (
		<div className={classnames(styles.head, className)}>
			<div className={styles.head__titleSection}>{titleSection}</div>
			{actionSection && <div className={styles.head__actionSection}>{actionSection}</div>}
		</div>
	);
};
