export const DOCKER_IMAGE_STEP_ID = 'DOCKER_IMAGE_STEP';

export const dockerImageStepMeta = {
	title: 'Docker Image',
	subtitle: 'Select what container you want to deploy',
	btnText: 'Docker Image',
	tooltipText: 'Docker Image',
	demoValues: {
		image: 'appfleet/edge-demo',
	},
};
