import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const isDatesInDayRange = (dates: number[]): boolean => {
	const startOfDay = moment().subtract('24', 'hours').startOf('day');
	const endOfDay = moment().endOf('day');
	const range = moment.range(startOfDay, endOfDay);
	for (const date of dates) {
		if (!range.contains(new Date(date))) return false;
	}
	return true;
};

export const toUnixTimestamp = (date: number) => date * 1000;

export const convertDatesToMinutes = (dates: number[]) => dates.map(date => moment(date).format('h:mm A'));

export const convertDatesToHours = (dates: number[]) => dates.map(date => moment(date).format('hh A'));

export const convertDatesToDaysAndHours = (dates: number[]) => {
	return dates.map(date => moment(date).format('DD ddd, hh A'));
};

export const convertDatesToDays = (dates: number[]) => dates.map(date => moment(date).format('DD ddd'));
