import { all, takeEvery } from 'redux-saga/effects';
import { watchGetClusterNodesSaga } from './watcherSaga';
import { deleteClusterNodeAction, addClusterNodes } from '../actions';
import { deleteClusterNodeSaga } from './deleteClusterNode';
import { addClusterNodeSaga } from './addClusterNode';

export function* clusterNodesSaga() {
	yield all([
		watchGetClusterNodesSaga(),
		takeEvery(deleteClusterNodeAction.TYPE, (action: ReturnType<typeof deleteClusterNodeAction>) =>
			deleteClusterNodeSaga(action.clusterName, action.nodeHostname)
		),
		takeEvery(addClusterNodes.TYPE, addClusterNodeSaga),
	]);
}
