import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import buttons from '../../styles/buttons.module.scss';
import globalStyles from '../../styles/global.module.scss';

interface PasswordRevealProps {
	handleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const PasswordReveal: React.FC<PasswordRevealProps> = ({ handleClick }) => {
	return (
		<div
			onClick={handleClick}
			className={classnames(globalStyles.noTextSelection, buttons.btnEight, styles.passwordReveal)}
		>
			{`Reveal`}
		</div>
	);
};
