import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import styles from './styles.module.scss';
import clustSettingsStyles from '../styles.module.scss';
import { RemoveItem } from '@app/shared/removeItem';
import { WarningDialog, BtnStyles } from '@app/shared/warningDialog';
import { destroyCluster, rebuildClusterAction, clearClusterError } from '../../../state/actions';
import { CLUSTER_SETTINGS_URL } from '../../../state/url';

interface ClusterSettingsRebuildOrDeleteProps {
	clusterName: string;
	disabled?: boolean;
}

export const ClusterSettingsRebuildOrDelete: React.FC<ClusterSettingsRebuildOrDeleteProps> = ({
	clusterName,
	disabled,
}) => {
	const dispatch = useDispatch();
	const [isShowRmModal, setIsShowRmModal] = useState(false);
	const [isShowRebuildModal, setIsShowRebuildModal] = useState(false);

	const handleRebuild = () => {
		dispatch(rebuildClusterAction({ clusterName, url: CLUSTER_SETTINGS_URL.format({ name: clusterName }) }));
		setIsShowRebuildModal(false);
	};

	return (
		<div className={styles.rebuildOrDelete}>
			<div className={clustSettingsStyles.clusterSettings__content_wrapped__body__blockHead}>
				{'Rebuild or delete'}
			</div>

			<div className={styles.rebuildOrDelete__logicBlock}>
				<Button
					onClick={
						disabled
							? () => {}
							: () => {
									dispatch(clearClusterError({}));
									setIsShowRebuildModal(true);
							  }
					}
					className={classnames('btn-one', styles.rebuildOrDelete__logicBlock__btn)}
					disabled={disabled}
				>
					{'Rebuild this cluster'}
				</Button>
				<div className={styles.rebuildOrDelete__logicBlock__descr}>
					{'Replace the underlying infrastructure of your cluster without downtime'}
				</div>
			</div>

			<div className={styles.rebuildOrDelete__logicBlock}>
				<Button
					onClick={disabled ? () => {} : () => setIsShowRmModal(true)}
					className={classnames('btn-six', styles.rebuildOrDelete__logicBlock__btn)}
					disabled={disabled}
				>
					{'Delete this cluster'}
				</Button>
				<div className={styles.rebuildOrDelete__logicBlock__descr}>
					{'Delete the cluster, instances and settings without recovery.'}
				</div>
			</div>

			{isShowRmModal && !disabled ? (
				<RemoveItem
					msg={`Are you sure you want to delete the ${clusterName}? You can’t undo this action.`}
					onDismiss={() => setIsShowRmModal(false)}
					onAgree={() =>
						dispatch(destroyCluster({ name: clusterName, handleResponse: () => setIsShowRmModal(false) }))
					}
					btnAgreeText="Delete"
					btnDismissText="Dismiss"
				/>
			) : null}

			{isShowRebuildModal ? (
				<WarningDialog
					msg="This option will create new underlying infrastructure and start your containers on the fresh systems. Once your containers start successfully, all traffic will switch over and all old nodes will be destroyed."
					btnAgreeText={'Rebuild'}
					btnDismissText={'Dismiss'}
					btnAgreeStyle={BtnStyles.AGREE_REGULAR}
					onAgree={handleRebuild}
					onDismiss={() => {
						setIsShowRebuildModal(false);
					}}
				/>
			) : null}
		</div>
	);
};
