import { customYup as yup, yupExceptions } from '@app/utils/customYup';

export const dockerImageSchema = yup.object().shape({
	image: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isInvalidDockerImage.regex, yupExceptions.isInvalidDockerImage.message)
		.required(),
	tag: yup
		.string()
		.matches(yupExceptions.isCyrillic.regex, yupExceptions.isCyrillic.message)
		.matches(yupExceptions.isInvalidDockerImage.regex, yupExceptions.isInvalidDockerImage.message)
		.required(),
});
