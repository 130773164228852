import * as React from 'react';
import memo from 'memoize-one';
import classnames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import styles from './style.module.scss';
import { MeasurementDataBar } from '@app/modules/cluster/components/MeasurementDataBar';
import { FlagIcon } from '@app/shared/icons/FlagIcon';
import iconWorld from '@icons/icon.world.svg';
import { SvgIcon } from '@app/shared/SvgIcon/SvgIcon';

export const GLOBAL_REGION = 'world';

export interface TooltipData {
	title: string;
	data: number;
	color: string;
	country: string;
	tooltipDataId?: number;
	tooltipDataTitle?: string;
}

interface Props<TElem extends HTMLElement> {
	xPosition: number;
	yPosition: number;
	adaptTo: TElem;
	data: TooltipData[];
	measurement: string;
	currentLine: string;
	className?: string;
	hidden?: boolean;
	cutMeasurement?: boolean;
	noBandwidthData?: boolean;
}

interface State {
	x: number;
	reversed: boolean;
}

export class AdaptableChartTooltip<TElem extends HTMLElement> extends React.Component<Props<TElem>, State> {
	private tooltipRef = React.createRef<HTMLDivElement>();
	private wrapperRef = React.createRef<HTMLDivElement>();
	private scrollRef = React.createRef<Scrollbars>();
	private listRefs: any = {};

	state = {
		x: 0,
		reversed: false,
	};

	adaptAndCalculatePosition = memo((x: number): { x: number; reversed: boolean } | void => {
		if (!this.tooltipRef.current) return;
		if (this.props.hidden) return;

		const { adaptTo: adaptElement } = this.props;
		const tooltipElement = this.tooltipRef.current;

		if (!adaptElement || !tooltipElement) return;

		const { width } = tooltipElement.getBoundingClientRect();
		const adaptElementRect = adaptElement.getBoundingClientRect();

		const hasSpaceForward = adaptElementRect.width - x >= width;
		if (hasSpaceForward) {
			return { x: x - 22, reversed: false };
		} else {
			return { x: x - width + 22, reversed: true };
		}
	});

	renderItems = memo((items: TooltipData[], currentLine: string, cutMeasurement?: boolean) => {
		return items
			.sort((a, b) => b.data - a.data)
			.map((dataItem, index) => {
				// if no bandwidth data we should show out mocked datset tooltip
				if (this.props.noBandwidthData) {
					return (
						<div className={styles.dataRow} key={index}>
							0GB
						</div>
					);
				}
				return (
					<div
						className={[
							styles.dataRow,
							(currentLine === dataItem.title || currentLine === String(dataItem.tooltipDataId)) &&
								styles.labelActive,
						].join(' ')}
						key={index}
						ref={ref => {
							this.listRefs[dataItem.title] = ref;
						}}
					>
						<div className={styles.labelWrap}>
							<div className={styles.dataColorCircle} style={{ backgroundColor: dataItem.color }} />

							{dataItem.country !== GLOBAL_REGION ? (
								<FlagIcon countryCode={dataItem.country} className={styles.flagIcon} />
							) : (
								<SvgIcon iconSrc={iconWorld} className={styles.flagIcon} />
							)}

							<span className={styles.label}>{dataItem.tooltipDataTitle || dataItem.title}</span>
						</div>

						<div className={styles.contentWrap}>
							<MeasurementDataBar
								measurement={this.props.measurement}
								data={String(dataItem.data)}
								cutMeasurement={cutMeasurement}
							/>
						</div>
					</div>
				);
			});
	});

	componentDidUpdate(prevProps: Readonly<Props<TElem>>) {
		const { currentLine } = this.props;

		if (currentLine && prevProps.currentLine !== currentLine) {
			const { top: wrapperTop } = this.wrapperRef.current?.getBoundingClientRect() || { top: 0 };
			const element = this.listRefs[currentLine];
			const { top } = element?.getBoundingClientRect() || { top: 0 };
			const offset = top - wrapperTop;
			const scroll = this.scrollRef.current;
			const halfHeight = (scroll?.getClientHeight() || 0) / 2;
			scroll?.scrollTop(offset - halfHeight);
		}
	}

	render() {
		const {
			className,
			hidden,
			xPosition,
			yPosition,
			data,
			currentLine,
			cutMeasurement,
			noBandwidthData = false,
		} = this.props;
		const { x = 0, reversed = false } = this.adaptAndCalculatePosition(xPosition) || {};
		if (!data.length) return null;
		const shift = 30 * (reversed ? -1 : 1);

		return (
			<div
				ref={this.tooltipRef}
				onMouseOver={e => e.stopPropagation()}
				className={classnames(
					styles.tooltip,
					{ [styles.tooltip_hidden]: hidden },
					{ [styles.tooltip__reversed]: reversed },
					{ [styles.tooltip_withScroll]: false },
					{ [styles.tooltip_noBandwidth]: noBandwidthData },
					className
				)}
				style={{
					top: yPosition,
					transform: `translate(${x + shift}px, -1.25rem)`,
				}}
			>
				<div className={styles.tooltipContentWrapper} ref={this.wrapperRef}>
					{this.renderItems(data, currentLine, cutMeasurement)}
				</div>
			</div>
		);
	}
}
