import { SigninState } from './state';
import { Action } from 'redux';
import { setSigninToken, setSigninError, clearSigninError, resetAPITokenFail } from './actions';

const INITIAL_STATE = {
	error: {
		message: '',
	},
};

export function signinReducer(state: SigninState = INITIAL_STATE, action: Action): SigninState {
	if (setSigninToken.is(action)) {
		return {
			...state,
			error: INITIAL_STATE.error,
		};
	}

	if (setSigninError.is(action) || setSigninError.is(action) || resetAPITokenFail.is(action)) {
		return {
			...state,
			error: action.error,
		};
	}

	if (clearSigninError.is(action)) {
		return { ...state, error: INITIAL_STATE.error };
	}

	return state;
}
