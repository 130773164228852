import React, { ReactNode } from 'react';
import { CustomModal } from '../customModal';
import { Button } from 'semantic-ui-react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { SvgIcon } from '../SvgIcon';
import iconError from '@icons/icon-error.svg';

interface RemoveItemProps {
	msg: string;
	btnDismissText?: string;
	btnAgreeText?: string;
	htmlMsg?: ReactNode;
	onDismiss: () => void;
	onAgree: () => void;
}

export const RemoveItem: React.FC<RemoveItemProps> = ({
	msg,
	onDismiss,
	onAgree,
	btnAgreeText = 'Remove',
	btnDismissText = 'Keep',
	htmlMsg,
}) => {
	return (
		<CustomModal>
			<div className={styles.removeItem}>
				<div className={styles.removeItem__msgBlock}>
					<div className={styles.removeItem__msgBlock__iconBlock}>
						<SvgIcon iconSrc={iconError} className={styles.removeItem__msgBlock__icon} />
					</div>
					<div className={styles.removeItem__msgBlock__msg}>{htmlMsg || msg}</div>
				</div>

				<div className={styles.removeItem__ctrls}>
					<Button
						className={classnames('btn', 'btn-three', styles.removeItem__ctrls__dismiss)}
						onClick={onDismiss}
					>
						{btnDismissText}
					</Button>
					<Button className={classnames('btn', 'btn-six', styles.removeItem__ctrls__agree)} onClick={onAgree}>
						{btnAgreeText}
					</Button>
				</div>
			</div>
		</CustomModal>
	);
};
