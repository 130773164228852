import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { AppTable } from '@app/shared/AppTable';
import { TeamMember } from '../../state/interfaces';
import dots from '@icons/icon.dots.svg';
import { ContextMenu } from '@app/shared/contextMenu';
import iconRemove from '@icons/icon.remove.option.svg';
import iconEdit from '@icons/icon.edit.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { removeAccountMember } from '@app/modules/team';
import { RemoveItem } from '@app/shared/removeItem';
import { PermissionsRole } from '@app/modules/cluster/components/clusterSettings/clusterSettingsPermissions';
import { UserInfo } from '@app/modules/account/state/interfaces';
import { UserAvatar } from '@app/shared/userAvatar';

interface MembersListRowProps {
	member: TeamMember;
	loggedInUser: UserInfo;
	handleContextMenuEdit: () => void;
	isReadonlyUser: boolean;
}

export const MembersListRow: React.FC<MembersListRowProps> = ({
	member,
	loggedInUser,
	handleContextMenuEdit,
	isReadonlyUser,
}) => {
	const [isLoggedInUser, setIsLoggedInUser] = React.useState(false);
	const [isMemberOwner, setIsMemberOwner] = React.useState(false);
	const [isPending, setIsPending] = React.useState(false);
	const [showContextMenu, setShowContextMenu] = React.useState(false);
	const [showRemoveMember, setShowRemoveMember] = React.useState(false);
	const dispatch = useDispatch();

	React.useEffect(() => {
		// check if is this member is the same is logged in now
		if (member.id === loggedInUser.id) {
			setIsLoggedInUser(true);
		} else {
			setIsLoggedInUser(false);
		}

		// check if is owner
		if (member?.permission?.toLowerCase() === PermissionsRole.OWNER) {
			setIsMemberOwner(true);
		} else {
			setIsMemberOwner(false);
		}

		// check is pending
		if (member?.status?.toLowerCase() === 'pending') {
			setIsPending(true);
		} else {
			setIsPending(false);
		}
	}, [member, loggedInUser]);

	return (
		<AppTable.Row className={styles.teamMember}>
			<AppTable.Col className={styles.teamMember__userData}>
				<UserAvatar
					email={member.email}
					ownAvatarColor={member.color}
					className={styles.teamMember__userData__avatar}
				/>
				<div className={styles.teamMember__userData__nameEmail}>
					<div>
						{!isPending
							? `${member.firstName} ${member.lastName} ${isLoggedInUser ? '(you)' : ''}`
							: member.email}
					</div>
					{!isPending ? <div>{member.email}</div> : null}
				</div>
			</AppTable.Col>

			<AppTable.Col>
				<div className={styles.teamMember__role}>{member.permission || ''}</div>
			</AppTable.Col>

			<AppTable.Col>
				<div className={classnames(styles.teamMember__status, getStatusClassName(member.status))}>
					{member.status}
				</div>
			</AppTable.Col>

			<AppTable.Col className={styles.teamMember__invited}>
				{moment(member.createdAt).format('DD-MM-YYYY')}
			</AppTable.Col>

			<AppTable.Col>
				<div
					className={styles.teamMember__dots}
					onMouseOver={
						isMemberOwner || isPending || isReadonlyUser ? () => {} : () => setShowContextMenu(true)
					}
					onMouseLeave={
						isMemberOwner || isPending || isReadonlyUser ? () => {} : () => setShowContextMenu(false)
					}
				>
					{isMemberOwner || isPending || isReadonlyUser ? null : (
						<SvgIcon className={styles.teamMember__dots__icon} iconSrc={dots} />
					)}
					{showContextMenu ? (
						<ContextMenu
							contextMenuWrapClassName={styles.teamMember__contextMenuWrapper}
							menuClassName={styles.teamMember__contextMenu}
							menuItemClassName={styles.teamMember__contextMenu__item}
							menuItems={[
								{
									text: 'Edit',
									handleClick: handleContextMenuEdit,
									icon: iconEdit,
									menuItemSpecificClassName: styles.teamMember__contextMenu__item__edit,
								},
								{
									text: 'Remove',
									handleClick: () => {
										setShowRemoveMember(true);
									},
									icon: iconRemove,
									menuItemSpecificClassName: styles.teamMember__contextMenu__item_danger,
								},
							]}
						/>
					) : null}
				</div>
			</AppTable.Col>

			{showRemoveMember ? (
				<RemoveItem
					msg={`Are you sure you want to remove ${member.firstName} ${member.lastName} from your team? You can’t undo this action.`}
					onDismiss={() => setShowRemoveMember(false)}
					onAgree={() =>
						dispatch(
							removeAccountMember({
								memberData: { id: member.id },
								onSuccess: () => setShowRemoveMember(false),
								onFailure: () => setShowRemoveMember(false),
							})
						)
					}
					btnAgreeText="Remove"
					btnDismissText="Keep"
				/>
			) : null}
		</AppTable.Row>
	);
};

const getStatusClassName = (status: string) => {
	switch (status.toLowerCase()) {
		case 'accepted':
			return styles.teamMember__status__accepted;
		case 'pending':
			return styles.teamMember__status__pending;
	}
};
