import * as React from "react";
import styles from "./style.module.scss";
import classnames from "classnames";

export interface InputLabelProps {
    text: string;
    htmlFor?: string;
    children?: string;
    className?: string;
}

export const InputLabel = (props: InputLabelProps) => {
    return (
        <label
            className={classnames(styles.inputLabel, props.className)}
            {...(props.htmlFor ? { htmlFor: props.htmlFor } : {})}
        >
            {props.children}
            {props.text}
        </label>
    );
};
