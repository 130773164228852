import * as React from 'react';
import { LoadingWrapper, LoadingWrapperInputProps } from './LoadingWrapper';
import { CSSProperties } from 'react';

export interface LoadingSpinnerInjectedProps {
	spinner: {
		loading?: boolean;
		placeholder: JSX.Element | null;
		forcedLoader: JSX.Element;
	};
}

export function withLoadingSpinner<TComponentProps>(
	component: React.ComponentType<TComponentProps & LoadingSpinnerInjectedProps>,
	styles?: CSSProperties
) {
	const child: React.ComponentType<TComponentProps & LoadingWrapperInputProps> = props => (
		<LoadingWrapper component={component} style={styles} {...props} />
	);

	return child;
}
