import { defineActionGroup, defineAction } from 'rd-redux-utils';
import { ApiNode, addNodesDTO } from './entity';
import { IErrorMessage } from '@app/shared/errorMessage';
import { MetaError } from '@app/types/metaError';

type BaseActionType = { clusterName: string };

const clusterNodesActions = defineActionGroup<BaseActionType>('CLUSTER-NODES');

export const setClusterNodesItemFetchingAction = clusterNodesActions.defineAction<{ isFetching: boolean }>(
	'SET CLUSTER NODES ITEM FETCHING'
);

export const setClusterNodesAction = clusterNodesActions.defineAction<{ clusterName: string; nodes: ApiNode[] }>(
	'SET CLUSTER NODES'
);

export const setClusterNodesErrorAction = clusterNodesActions.defineAction<{ error: string }>(
	'SET CLUSTER NODES ERROR'
);

export const getClusterNodesAction = clusterNodesActions.defineAction<{}>('GET CLUSTER NODES');

export const deleteClusterNodeAction = clusterNodesActions.defineAction<{ nodeHostname: string }>(
	'DELETE CLUSTER NODE'
);
export const deleteClusterNodeSuccessAction = clusterNodesActions.defineAction<{ nodeHostname: string }>(
	'DELETE CLUSTER NODE SUCCESS'
);
export const deleteClusterNodeFailedAction = defineAction<{ error: MetaError }>(
	'CLUSTER-NODES: DELETE CLUSTER NODE FAILED'
);
export const clearClusterNodesErrorAction = defineAction<{}>('CLUSTER-NODES: CLEAR CLUSTER NODES ERROR');

export const addClusterNodesAG = defineActionGroup('ADD CLUSTER NODES');
export const addClusterNodes = addClusterNodesAG.defineAction<addNodesDTO>('MAKE REQUEST');
export const addClusterNodesSuccess = addClusterNodesAG.defineAction('REQUEST SUCCEED');
export const addClusterNodesFail = addClusterNodesAG.defineAction<{ error: IErrorMessage }>('REQUEST FAILED');
