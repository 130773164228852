import { useSelector } from 'react-redux';
import { AppState } from '../reducers/index';
import { BillingInfoSuscriptionStatus, BillingInfoSubscriptionReason } from '@app/modules/account/state/interfaces';

interface SubscriptionCheck {
	subscriptionValid: boolean;
	subscriptionStatus: BillingInfoSuscriptionStatus;
	subscriptionStatusReason: BillingInfoSubscriptionReason;
}

export const useBillingInfoSubscriptionStatus = (): SubscriptionCheck => {
	const {subscriptionStatus, subscriptionStatusReason} = useSelector((state: AppState) => ({subscriptionStatus: state.account.billingInfo.subscriptionStatus, subscriptionStatusReason: state.account.billingInfo.subscriptionStatusReason}));
	// while we have no billing info received yet we should show subscription as valid to avoid rendering empty banners and staff
	if (typeof subscriptionStatus === 'undefined') {
		return {
			subscriptionValid: true,
			subscriptionStatus: BillingInfoSuscriptionStatus.UNINITIALIZED,
			subscriptionStatusReason: null,
		}
	}
	let subscriptionValid = false;

	switch (subscriptionStatus && subscriptionStatus.toLowerCase()) {
		case BillingInfoSuscriptionStatus.ACTIVE:
		case BillingInfoSuscriptionStatus.TRIAL:
			subscriptionValid = true;
			break;
		default:
			subscriptionValid = false;
	}

	return { subscriptionValid, subscriptionStatus, subscriptionStatusReason };
};
