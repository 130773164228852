import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import alertIcon from '@icons/alert.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import {
	BillingInfoSuscriptionStatus,
	BillingInfoSubscriptionReason,
	BillingInfoSubscriptionReasonEnum,
} from '@app/modules/account/state/interfaces';

interface Props {
	subscriptionStatus: BillingInfoSuscriptionStatus;
	subscriptionStatusReason: BillingInfoSubscriptionReason;
}

const couldnotChargeMeta = {
		title: 'Urgent: Unfortunately we couldn`t charge your card and we have stopped trying.',
		message:
			'Please update your payment method or contact our support to avoid the downtime of your services. Your cluster resources will be destroyed after a short grace period.',
	},
	lastPaymentFailedMeta = {
		title: 'Unfortunately your last payment wasn`t successful.',
		message:
			'We will keep retrying for a short period of time. If needed please update your payment method or contact our support to avoid any downtime.',
	},
	securityDepoFailedMeta = {
		title: 'Unfortunately your last security deposit wasn`t successful.',
		message:
			'Please make sure your card is active. If needed please update your payment method or contact our support to avoid any downtime.',
	},
	markedAsInactiveMeta = {
		title: 'Urgent: Unfortunately we couldn`t charge your card and marked the account as inactive.',
		message:
			'Please update your payment method or contact our support to avoid the downtime of your services. Your cluster resources will be destroyed after a short grace period.',
	};

export const SubscriptionStatusBanner: React.FC<Props> = ({ subscriptionStatus, subscriptionStatusReason }) => {
	const [bannerData, setBannerData] = useState({
		title: '',
		message: '',
	});

	useEffect(() => {
		if (
			(subscriptionStatus === BillingInfoSuscriptionStatus.PAST_DUE ||
				subscriptionStatus === BillingInfoSuscriptionStatus.UNPAID) &&
			subscriptionStatusReason === BillingInfoSubscriptionReasonEnum.THRESHOLD
		) {
			setBannerData(securityDepoFailedMeta);
		} else if (
			subscriptionStatus === BillingInfoSuscriptionStatus.PAST_DUE &&
			(subscriptionStatusReason === BillingInfoSubscriptionReasonEnum.CYCLE || subscriptionStatusReason === null)
		) {
			setBannerData(lastPaymentFailedMeta);
		} else if (
			subscriptionStatus === BillingInfoSuscriptionStatus.UNPAID &&
			(subscriptionStatusReason === BillingInfoSubscriptionReasonEnum.CYCLE || subscriptionStatusReason === null)
		) {
			setBannerData(couldnotChargeMeta);
		} else if (subscriptionStatus === BillingInfoSuscriptionStatus.INACTIVE) {
			setBannerData(markedAsInactiveMeta);
		}
	}, [subscriptionStatus, subscriptionStatusReason]);

	return (
		<div className={styles.banner}>
			<div className={styles.banner__textImgBlock}>
				<SvgIcon iconSrc={alertIcon} className={styles.banner__textImg} />
			</div>
			<div>
				<div className={styles.banner__title}>{bannerData.title}</div>
				<div className={styles.banner__message}>{bannerData.message}</div>
			</div>
		</div>
	);
};
