import React from 'react';
import styles from './style.module.scss';
import { Popup, PopupProps } from 'semantic-ui-react';
import { InputLabel } from '../InputLabel';
import classnames from 'classnames';
import { SvgIcon } from '@app/shared/SvgIcon';
import iconInfo from '@icons/icon.info.svg';

interface Props {
	labelText: string;
	tooltipText?: string;
	inputId?: string;
	className?: string;
	iconClassName?: string;
	popupProps?: PopupProps;
	iconComponent?: React.ReactNode | null;
}

export const LabelWithTooltip: React.FC<Props> = ({
	inputId = '',
	className,
	labelText,
	iconComponent,
	tooltipText = '',
	iconClassName,
	popupProps = {},
}) => {
	return (
		<div className={classnames(styles.labelWithTooltip, className)}>
			<InputLabel htmlFor={inputId} text={labelText} />
			{iconComponent || (
				<Popup
					content={tooltipText}
					trigger={<SvgIcon iconSrc={iconInfo} className={classnames(styles.icon, iconClassName)} />}
					position="top center"
					{...popupProps}
				/>
			)}
		</div>
	);
};
