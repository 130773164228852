import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import styles from './style.module.scss';
import { ClusterStatusDot } from '../clusterStatusDot';
import { navigateTo } from '@app/utils/navigate-to';
import { CLUSTER_URL } from '@app/modules/cluster/state/url';
import { ClusterNodes } from '../clusterNodes';
import { ClusterLoad } from '../clusterLoad';
import { AppState } from '@app/reducers';
import { AppTable } from '@app/shared/AppTable';
import { AppRequestButton } from '@app/shared/AppRequestButton';
import { pullNDeployClustersListItem } from '@app/modules/clusters-list';
import { getWizardStatus } from '@app/utils/getWizardStatus';
import { CLUSTERS_LIST_URL, resetPullNDeployClustersListItem, ClusterListItem as IClusterListItem } from '../../state';
import { useCheckerIsClusterReadonly } from '@app/hooks';
import { CLUSTER_STASUSES } from '@app/data-modules/healthStatuses';

interface ClusterListItemProps extends IClusterListItem {
	metrics?: {
		cpu: number;
		ram: number;
		bandwidth: number;
		disc_usage: number;
	};
	className: string;
}

export const ClusterListItem: React.FC<ClusterListItemProps> = ({
	id,
	name,
	currentVersion: { image, tag },
	domains,
	nodes,
	className,
	metrics = {
		'cpu.load': 0,
		'disk.used': 0,
		'mem.usage': 0,
		'net.bandwidth': 0,
	},
}) => {
	const dispatch = useDispatch();
	const [isHover, setIsHover] = useState(false);
	const { accountPublicId, pullAndDeployState, clusterStatus } = useSelector((state: AppState) => ({
		accountPublicId: state.account.info.publicId,
		pullAndDeployState: state.clustersList.pullAndDeployState,
		clusterStatus: state.healthStatuses.clusters.find(cluster => cluster.id === id)!,
	}));
	const [clusterHref, setClusterHref] = React.useState('');
	const [customDomain, setCustomDomain] = React.useState('');
	const { isReadonlyCluster } = useCheckerIsClusterReadonly(id);
	const isDisabled = clusterStatus?.status === CLUSTER_STASUSES.rebuilding; // 904 WTF

	useEffect(() => {
		if (domains.length) {
			setCustomDomain(domains[0].name);
		}
	}, [domains]);

	useEffect(() => {
		setClusterHref(`/cluster/${name}`);
	}, [name]);

	const pullAndDeployHandler = (clusterName: string) => {
		if (!clusterName) return;
		dispatch(pullNDeployClustersListItem({ clusterName, url: CLUSTERS_LIST_URL.format({}) }));
	};

	const resetPullAndDeployState = useCallback(() => {
		if (!name) return;
		dispatch(resetPullNDeployClustersListItem({ clusterName: name }));
	}, [dispatch, name]);

	return (
		<AppTable.Row
			key={id}
			className={classnames({ [className]: isHover })}
			onMouseOver={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			{/* <Table.Cell className={classnames('with-ellipsis')}> */}
			<AppTable.Col className={styles.cluster__hostnameField}>
				<a
					href={clusterHref}
					onClick={e => {
						e.preventDefault();
						navigateTo(CLUSTER_URL.format({ name }), id)();
					}}
				>
					<ClusterStatusDot status={clusterStatus?.status} />
					<div className={styles.cluster__hostnameField__domains}>
						<div className={styles.cluster__hostnameField__domains__domainName}>
							{customDomain
								? customDomain
								: `${name}-${accountPublicId}.${process.env.REACT_APP_HOSTNAME_DOMAIN}`}
						</div>
						<div className={styles.cluster__hostnameField__domains__customDomainName}>
							{customDomain ? `${name}-${accountPublicId}.${process.env.REACT_APP_HOSTNAME_DOMAIN}` : ''}
						</div>
					</div>
				</a>
			</AppTable.Col>
			{/* Docker image */}
			<AppTable.Col className={classnames(styles.cluster__dockerImage, 'with-ellipsis')}>
				<a
					href={clusterHref}
					onClick={e => {
						e.preventDefault();
						navigateTo(CLUSTER_URL.format({ name }), id)();
					}}
				>
					<div className={classnames('with-ellipsis', 'rtl-text', styles.cluster__dockerImageName)}>
						{image}
					</div>
					<div>{tag}</div>
				</a>
			</AppTable.Col>
			{/* Nodes */}
			<AppTable.Col className={styles.cluster__nodes}>
				<a
					href={clusterHref}
					onClick={e => {
						e.preventDefault();
						navigateTo(CLUSTER_URL.format({ name }), id)();
					}}
				>
					<ClusterNodes nodes={nodes} />
				</a>
			</AppTable.Col>
			{/* Load */}
			<AppTable.Col className={styles.cluster__metrics}>
				<a
					href={clusterHref}
					onClick={e => {
						e.preventDefault();
						navigateTo(CLUSTER_URL.format({ name }), id)();
					}}
				>
					<ClusterLoad metrics={metrics} status={clusterStatus?.status} />
				</a>
			</AppTable.Col>
			{/* Button Deploy */}
			<AppTable.Col textAlign="center" className={styles.cluster__ctrls}>
				<AppRequestButton
					statusText={{
						initial: 'Pull and deploy',
						loading: 'Deploying',
						success: 'Deployed',
						error: 'Failed',
					}}
					status={getWizardStatus(pullAndDeployState[name]?.deploy) || 'initial'}
					className={styles.cluster__pullAndDeployBtn}
					disabled={isDisabled || isReadonlyCluster}
					onClick={isDisabled || isReadonlyCluster ? () => {} : () => pullAndDeployHandler(name)}
					onResetState={resetPullAndDeployState}
				/>
			</AppTable.Col>
		</AppTable.Row>
	);
};
