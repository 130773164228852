import * as React from 'react';
import filesize from 'filesize';
import memo from 'memoize-one';
import moment from 'moment';
import _ from 'lodash';
import styles from './style.module.scss';
import {
	LineChart,
	LineChartMetric,
} from '@app/modules/cluster/components/clusterInfo/components/ClusterMetrics/LineChart';
import { Line } from 'react-chartjs-2';
import {
	LinePointerPlugin,
	ChartElement,
	PointerPosition,
} from '@app/modules/cluster/components/clusterInfo/components/ClusterMetrics/chartjs-plugins/line-pointer-plugin';
import { SelectWithSearch } from '@app/shared/selectWithSearch';
import { ChartTooltipModel } from 'chart.js';
import classnames from 'classnames';
import type { ClusterListItem } from '@app/modules/clusters-list/state/interfaces';
import { SvgIcon } from '@app/shared/SvgIcon/SvgIcon';
import iconWorld from '@icons/icon.world.svg';
import { ApiRegion } from '@app/data-modules/regions/entity';
import { getFlagForNode } from '@app/utils/getFlagClass';
import {
	AdaptableChartTooltip,
	TooltipData,
	GLOBAL_REGION,
} from '@app/modules/cluster/components/clusterInfo/components/ClusterMetrics/AdaptableChartTooltip';
import { ClusterListItemNode } from '@app/modules/clusters-list/state/interfaces';
import { IconWithTooltip } from '@app/shared/iconWithTooltip';
import iconInfo from '@icons/icon.info.svg';
import { AppSpinner } from '@app/shared/AppSpinner';

const TOOLTIP_BANDWIDTH_TEXT =
	'Total bandwidth used by all nodes including deleted ones during the current billing period. Billing periods start on 1st of every month.';

interface Bandwidth {
	[key: string]: Array<Array<number>>;
}
export interface AccountBandwidth {
	bandwidth: Bandwidth;
	start_date: string;
	end_date: string;
}

interface Props {
	accountClusters: Array<Partial<ClusterListItem>>;
	regions: ApiRegion[];
	accountBandwidth: AccountBandwidth;
}

interface State {
	tooltipPosition: { x: number; y: number };
	displayTooltip: boolean;
	activeElements: ChartElement[];
	selectedCluster: string;
	tooltipProps: {
		top: number;
		left: number;
		value: number | string | undefined;
	};
	bandwidthTotal: number | null;
	// unitsExponent and optionsExponent are different because they calculated based on the different values
	// units is calculated by the maximum value among the all clusters bandwidths
	// and options by the maximum values among datasets clusterTotals
	// where clusterTotal is an amount of single cluster bandwidths
	unitsExponent: number | null;
	optionsExponent: number | null;
	// same goes to the chartUnitsOfMeasurement - they should not used for the options, options have own logic
	chartUnitsOfMeasurement: string | null;
	currentLine: string;
}

interface CombinedNode {
	amountOfNodes: number;
	hostname: string;
	region: string;
}

export class BillingBandwidthChart extends React.PureComponent<Props, State> {
	private chart = React.createRef<Line>();
	private plugin: LinePointerPlugin;
	private readonly plugins: any[] = [];
	private readonly chartCustomTooltipProps: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			displayTooltip: false,
			tooltipPosition: { x: 0, y: 0 },
			activeElements: [],
			selectedCluster: '',
			tooltipProps: {
				top: 0,
				left: 0,
				value: 0,
			},
			bandwidthTotal: null,
			chartUnitsOfMeasurement: null,
			unitsExponent: null,
			optionsExponent: null,
			currentLine: '',
		};

		this.plugin = new LinePointerPlugin({
			id: 'line-pointer-plugin',
			appearance: {
				pointerWidth: 2,
				pointerColor: '#0c5ce5',
			},
			handlers: {
				onMove: this.handlePointerMove,
				onMouseLeave: this.handleMouseLeave,
				onMouseIn: this.handleMouseIn,
				afterDraw: this.handleDraw,
			},
		});

		this.plugins = [this.plugin.getPlugin()];

		this.chartCustomTooltipProps = (tooltipModel: ChartTooltipModel) => {
			// if chart is not defined, return
			const chart = this.chart.current;
			if (!chart) {
				return;
			}

			const position = chart.chartInstance.canvas?.getBoundingClientRect() || {
				left: 0,
				top: 0,
			};

			// assuming your tooltip is `position: fixed`
			// set position of tooltip
			const left = position.left + tooltipModel.caretX;
			const top = position.top + tooltipModel.caretY;

			// set value for display of data in the tooltip
			const lines = tooltipModel?.body?.map(bodyItem => bodyItem.lines);
			const data: string = (lines && lines[0] && (lines[0][0] as any)) || '';
			const idx = data.search(/\d+(\.\d+)?$/);
			const value = idx >= 0 ? data.slice(idx) : '';
			const [title] = data.split(':');

			this.handleSetTooltipProps(top, left, value);
			if (title) {
				this.setState({ currentLine: title.trim() });
			}

			// handle hover for tooltip
			if (tooltipModel.opacity === 0) {
				this.setState({ displayTooltip: false });
			}
			if (tooltipModel.opacity === 1) {
				this.setState({ displayTooltip: true });
			}
		};
	}

	private handleDraw = (_: any, pointerPosition: PointerPosition) => {
		this.setState({ tooltipPosition: { x: pointerPosition.x, y: pointerPosition.y } });
	};

	private handleMouseIn = () => {
		this.plugin.enable();
	};

	private handleMouseLeave = () => {
		this.plugin.disable();
		this.redrawCharts();
	};

	private handlePointerMove = (_: any, positions: ChartElement[]) => {
		this.redrawCharts();
		this.setState({ activeElements: positions });
	};

	private handleSetTooltipProps = (top: number, left: number, value: number | string | undefined) => {
		this.setState({ tooltipProps: { top, left, value } });
	};

	private redrawCharts = () => {
		if (!this.chart.current?.chartInstance) return;
		(this.chart.current.chartInstance as any).draw({ lazy: true });
	};

	private getUnitsData = (value: number) => {
		for (let i = 5; i > 0; i--) {
			const res = filesize(value, { exponent: i });
			const scaledValue = Number(res.split(' ')[0]);
			if (Math.trunc(scaledValue) > 0) {
				return { units: res.split(' ')[1], exponent: i, valueUnitsCombined: res, scaledValue };
			}
		}
		return {
			scaledValue: 0,
			valueUnitsCombined: '0GB',
			units: 'KB',
			exponent: 1,
		};
	};

	private getCombinedNodes = memo((nodes: ClusterListItemNode[]) => {
		const combinedNodes: CombinedNode[] = [];
		let existingNodeIdx: number | null = null;
		nodes.forEach((currentNode: ClusterListItemNode) => {
			combinedNodes.forEach((node: CombinedNode, nodeIdx) => {
				if (node.region === currentNode.region) {
					existingNodeIdx = nodeIdx;
				}
			});

			if (existingNodeIdx !== null) {
				combinedNodes[existingNodeIdx].amountOfNodes += 1;
			} else {
				combinedNodes.push({ ...currentNode, amountOfNodes: 1 });
			}
			existingNodeIdx = null;
		});
		return combinedNodes;
	});

	private mapMetricToChart = memo(
		(bandwidth: Bandwidth = {}, accountClusters: Array<Partial<ClusterListItem>>): LineChartMetric[] => {
			let total = 0;
			let units: string | null = null;
			let exponent: number | null = null;

			const datasets = Object.keys(bandwidth).map((key: string) => {
				// find the max value of bandwidth to get proper units of measurement for the chart only
				const bandwidthValues: number[] = [];
				bandwidth[key].forEach((data: number[]) => {
					bandwidthValues.push(data[1]);
				});
				// get exponent for everything(total, chart) except options
				const data = this.getUnitsData(Math.max(...bandwidthValues));
				units = data.units;
				exponent = data.exponent;

				const dataset = {} as LineChartMetric;
				let clusterTotal = 0;
				dataset.data = bandwidth[key].map((data: number[]) => {
					const bandwidthGB = Number(filesize(data[1], { exponent: exponent || undefined }).split(' ')[0]);
					total += data[1];
					clusterTotal += data[1];
					return { date: data[0], value: bandwidthGB };
				});
				const cluster = accountClusters.find(
					(cluster: Partial<ClusterListItem>) => String(cluster.id) === String(key)
				);
				const combinedNodes = this.getCombinedNodes(cluster?.nodes || []);

				dataset.label = key;
				dataset.clusterName = cluster?.name || 'Removed';
				dataset.clusterTotal = clusterTotal;
				dataset.country = combinedNodes.length === 1 ? getFlagForNode(combinedNodes[0].region) : GLOBAL_REGION;

				return dataset;
			});

			// get the exponent for the options
			const datasetValues: number[] = [];
			datasets.forEach(dataset => {
				datasetValues.push(dataset.clusterTotal || 0);
			});
			const optionsExponent = this.getUnitsData(Math.max(...datasetValues)).exponent;

			this.setState({
				bandwidthTotal: total,
				chartUnitsOfMeasurement: units,
				unitsExponent: exponent,
				optionsExponent,
			});
			return datasets;
		}
	);

	private composeDataset = memo((bandwidth: Bandwidth, accountClusters: any): LineChartMetric[] => {
		return this.mapMetricToChart(bandwidth, accountClusters);
	});

	private buildLabelsForChart = (metrics: LineChartMetric[]): string[] => {
		if (!metrics.length) return [];

		const allDates: number[] = [];
		metrics.forEach(metric => {
			metric.data.forEach(dates => {
				allDates.push(dates.date);
			});
		});
		return _.union(allDates.sort((a, b) => a - b).map(date => moment(date * 1000).format('DD ddd')));
	};

	private renderCustomClusterSelectOption = (
		text: string,
		elVal: number,
		region: string,
		unitsOfMeasurement: string
	) => (
		<div className={styles.billingBandwidthChart__clusterSelectOptions__option}>
			<div className={styles.billingBandwidthChart__clusterSelectOptions__option__flag}>
				{region === GLOBAL_REGION ? (
					<SvgIcon iconSrc={iconWorld} className={styles.globalNodes} />
				) : (
					<i className={classnames('flag-icons', region.toUpperCase())} />
				)}
			</div>

			<div className={styles.billingBandwidthChart__clusterSelectOptions__option__clusterName}>{text}</div>

			<div className={styles.billingBandwidthChart__clusterSelectOptions__option__info}>
				<span className={styles.billingBandwidthChart__clusterSelectOptions__option__info__value}>
					{elVal.toFixed(2)}
				</span>
				<span className={styles.billingBandwidthChart__clusterSelectOptions__option__info__units}>
					{unitsOfMeasurement}
				</span>
			</div>
		</div>
	);

	private createClustersOptions = memo(
		(accountClusters: Array<Partial<ClusterListItem>>, datasets: LineChartMetric[], exponent) => {
			const options: Array<{ value: string; text: string; customOpt: JSX.Element }> = [];

			accountClusters.forEach(cluster => {
				const combinedNodes = this.getCombinedNodes(cluster?.nodes || []);
				const clusterDataset = datasets.find(
					(dataset: LineChartMetric) => Number(dataset.label) === cluster.id
				);

				const clusterTotalScaled = filesize(clusterDataset?.clusterTotal || 0, { exponent }).split(' ');
				options.push({
					value: '',
					text: '',
					customOpt: this.renderCustomClusterSelectOption(
						cluster.name!,
						Number(clusterTotalScaled[0]),
						combinedNodes.length === 1 ? getFlagForNode(combinedNodes[0].region) : GLOBAL_REGION,
						clusterTotalScaled[1]
					),
				});
			});

			// check what datasets has data of already removed clusters and count it
			let deletedClustersTotal = 0;
			datasets.forEach((dataset: LineChartMetric) => {
				if (!accountClusters.some(cluster => cluster.id === Number(dataset.label))) {
					deletedClustersTotal += dataset.clusterTotal || 0;
				}
			});

			// create option for deleted clusters
			if (deletedClustersTotal) {
				const deletedClustersUnitsData = this.getUnitsData(deletedClustersTotal);
				options.push({
					value: '',
					text: '',
					customOpt: this.renderCustomClusterSelectOption(
						'Removed clusters',
						deletedClustersUnitsData.scaledValue,
						GLOBAL_REGION,
						deletedClustersUnitsData.units
					),
				});
			}

			return options;
		}
	);

	mapDatasetToTooltip = memo((datasets: any, positions: ChartElement[]): TooltipData[] => {
		return positions.map(position => {
			const { dataIndex, datasetIndex, color } = position;
			const { date, value = 0 } = datasets[datasetIndex].data[dataIndex] || {};
			const { label, country, clusterName } = datasets[datasetIndex];
			return {
				data: value,
				date,
				title: label,
				country,
				color,
				tooltipDataId: label,
				tooltipDataTitle: clusterName,
			};
		});
	});

	componentDidMount() {
		this.plugin.disable();
	}

	render() {
		const { regions, accountClusters, accountBandwidth } = this.props;
		const {
			selectedCluster,
			displayTooltip,
			tooltipProps,
			bandwidthTotal,
			activeElements,
			currentLine,
		} = this.state;
		// if we have no bandwidth data we should set em manualy for mocked chart data
		let { chartUnitsOfMeasurement, unitsExponent, optionsExponent } = this.state;

		let dataset = this.composeDataset(accountBandwidth.bandwidth, accountClusters);
		let noBandwidthData = false;

		// when we have no bandwidth we have no datasets
		// so we should set mocked dataset to show chart with 0GB data
		if (dataset.length === 0) {
			chartUnitsOfMeasurement = 'GB';
			unitsExponent = 2;
			noBandwidthData = true;
			dataset = [
				{
					data: [
						{
							date: Date.now() / 1000,
							value: 0,
						},
					],
					label: '',
					clusterName: '',
					clusterTotal: 0,
					// do not set country so we can detect such mocked data within AdaptableChartTooltip
					// country: undefined,
				},
			];
		}

		// show AppSpinner while requests is not completed yet
		if (!regions.length || !chartUnitsOfMeasurement || !unitsExponent) {
			return (
				<div className={styles.billingBandwidthChart}>
					<div className={styles.billingBandwidthChart__appSpinner}>
						<AppSpinner className={styles.billingBandwidthChart__appSpinner__loadingRow__icon} />
						<div className={styles.billingBandwidthChart__appSpinner__loadingRow__label}>
							Loading Bandwidth...
						</div>
					</div>
				</div>
			);
		}

		const bandwidthTotalRender = this.getUnitsData(bandwidthTotal || 0);

		const labels = this.buildLabelsForChart(dataset);

		const options = this.createClustersOptions(accountClusters, dataset, optionsExponent);

		return (
			<div className={styles.billingBandwidthChart}>
				<div className={styles.billingBandwidthChart__header}>
					<div className={styles.billingBandwidthChart__headerTitle}>
						<span>Bandwidth usage</span>
						<IconWithTooltip imageSrc={iconInfo} tooltipText={TOOLTIP_BANDWIDTH_TEXT} />
					</div>
					<div>
						<SelectWithSearch
							selectName="clusters"
							handleChange={(_, value: string) => this.setState({ selectedCluster: value })}
							placeholder="See all clusters"
							options={options}
							disabledInput={true}
							wrapClassname={styles.billingBandwidthChart__clusterSelectWrapper}
							selectClassname={styles.billingBandwidthChart__clusterSelect}
							optionsClassname={styles.billingBandwidthChart__clusterSelectOptions}
							showSelectedSign={false}
							selectValue={selectedCluster}
							disabled={noBandwidthData}
						/>
					</div>
				</div>
				<div className={styles.billingBandwidthChart__content}>
					<div className={styles.billingBandwidthChart__totalInfo}>
						<div className={styles.billingBandwidthChart__totalInfoLabel}>Total</div>
						<div className={styles.billingBandwidthChart__totalInfoValue}>
							{bandwidthTotalRender.valueUnitsCombined}
						</div>
					</div>
					<div className={styles.metricLayout}>
						<div className={styles.metric}>
							<div className={styles.metricWrapper}>
								<LineChart
									canvasRef={this.chart}
									labels={labels}
									plugins={this.plugins}
									datasets={dataset}
									tickMeasurementSuffix={chartUnitsOfMeasurement}
									options={options => {
										return {
											...options,
											maintainAspectRatio: false,
											scales: {
												xAxes: [
													{
														...(options.scales as any).xAxes[0],
														ticks: {
															padding: 0,
															fontSize: 10,
														},
														gridLines: {
															display: false,
														},
													},
												],
												yAxes: [
													{
														...(options.scales as any).yAxes[0],
														ticks: {
															...(options.scales as any).yAxes[0].ticks,
															maxTicksLimit: 4,
															fontSize: 10,
														},
														gridLines: {
															drawBorder: false,
															drawTicks: false,
														},
													},
												],
											},
											tooltips: {
												enabled: false,
												mode: 'nearest',
												custom: this.chartCustomTooltipProps,
											},
										};
									}}
								/>
								{this.chart.current && displayTooltip && dataset && (
									<AdaptableChartTooltip
										xPosition={tooltipProps.left}
										yPosition={this.chart.current?.chartInstance.chartArea.top || 0}
										adaptTo={this.chart.current?.chartInstance.canvas as HTMLElement}
										hidden={!displayTooltip}
										data={this.mapDatasetToTooltip(dataset, activeElements)}
										measurement={chartUnitsOfMeasurement}
										currentLine={currentLine}
										cutMeasurement={false}
										noBandwidthData={noBandwidthData}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
