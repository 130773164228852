import * as React from 'react';
import styles from './style.module.scss';

interface Props {
    style: React.CSSProperties;
}

export const ScrollThumb = ({ style, ...rest }: Props) => {
    return <div className={styles.scrollThumb} {...rest}/>;
};

export const ScrollTrackHorizontal = (props: Props) => (
    <div {...props} style={{ display: 'none' }} className='track-horizontal' />
);

