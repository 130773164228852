import { getNestedProperty } from './getNestedProperty';

export const compareValues = ({ key, order = 'asc' }: { key: string; order?: 'asc' | 'desc' }) => (a: any, b: any) => {
	let parsedKey = key.split('.');
	let first = parsedKey[0];
	parsedKey.splice(0, 1);

	let aKey = getNestedProperty(a, first, ...parsedKey);
	let bKey = getNestedProperty(b, first, ...parsedKey);

	if (aKey === null && bKey === null) {
		return 0;
	} else if (aKey === null) {
		return 1
	} else if (bKey === null) {
		return -1;
	}

	let varA = typeof aKey === 'string' ? aKey.toUpperCase() : aKey;
	let varB = typeof bKey === 'string' ? bKey.toUpperCase() : bKey;

	// check if date to sort
	if (
		typeof aKey === 'string' &&
		typeof bKey === 'string' &&
		aKey.match(/\d\d-\d\d-\d\d\d\d/) &&
		bKey.match(/\d\d-\d\d-\d\d\d\d/)
	) {
		varA = aKey.split('-').reverse().join('-');
		varB = bKey.split('-').reverse().join('-');
	}

	let comparison = 0;
	if (varA > varB) {
		comparison = 1;
	} else if (varA < varB) {
		comparison = -1;
	}
	return order === 'desc' ? comparison * -1 : comparison;
};
