import { call, take, put } from 'redux-saga/effects';
import { addRegistryApi, getRegistriesApi, updateRegistryApi } from '../api';
import { editRegistry, setRegistriesError, getRegistries } from '../actions';
import { EditRegistryValues } from '../../components/editRegistry';
import { ApiRegistry } from '../dto/registry';

export function* watchEditRegistry() {
	while (true) {
		const action = yield take('*');
		if (editRegistry.is(action)) {
			yield call(editRegistryWorker, { ...action });
		}
	}
}

function* editRegistryWorker({ values, done }: { values: EditRegistryValues; done: (success: boolean) => void }) {
	let success = false;
	try {
		const { id, type, password, username } = values;
		if (id) {
			yield call(updateRegistryApi, id, { type, password, username });
		} else {
			const userRegistries: ApiRegistry[] = yield getRegistriesApi();
			if (userRegistries.find(registry => registry.type === values.type)) {
				yield put(setRegistriesError({ error: { message: 'Duplicate registries are not allowed.' }, snack: false }));
				return;
			}
			yield call(addRegistryApi, values);
		}
		success = true;
		yield put(getRegistries({}));
	} catch (e) {
		const { message } = e;

		yield put(setRegistriesError({ error: { message }, snack: false }));
	} finally {
		yield call(done, success);
	}
}
