import * as React from 'react';
import styles from './style.module.scss';
import { Grid } from 'semantic-ui-react';
import { CenteredContentLayout } from '../../components/layouts/CenteredContentLayout';
import { createSelector } from 'reselect';
import { ApiRegion } from '@app/data-modules/regions';
import { StepNavigationButton } from '../../components/StepNavigationButton';
import { addPaymentCardStepMeta } from './meta';
import { connect } from 'react-redux';
import { AddPaymentCardWizardStepProps } from '../../state/interfaces';
import { BillingCardForm } from '../../components/forms/BillingCardForm';
import stripeImg from '@images/stripe.svg';
import { SvgIcon } from '@app/shared/SvgIcon';
import { ErrorMessage } from '../../../../shared/errorMessage/ErrorMessage';

const getRegionNodes = createSelector(
	(values: any) => values,
	(values: any) => {
		if (!values) return [];
		const regionList = Object.entries<{ amount: number; region: ApiRegion }>(values.nodes || {});

		if (!regionList.length) return [];

		return regionList.map(([_, region]) => region);
	}
);

interface Props extends AddPaymentCardWizardStepProps {}

class AddPaymentCardStepDumb extends React.Component<Props> {
	state = {
		disabled: false,
		stripeError: {
			message: '',
		},
	};
	mapNodesToPreview = getRegionNodes;

	setDisabled = (disabled: boolean) => this.setState({ disabled });

	setStripeError = (errMsg: string) =>
		this.setState(state => ({
			...state,
			stripeError: {
				message: errMsg,
			},
		}));

	render() {
		const { getPrevStep, getNextStep, step, prev, next } = this.props;
		const { disabled, stripeError } = this.state;
		const prevStep = getPrevStep();
		const nextStep = getNextStep();

		return (
			<CenteredContentLayout
				title={addPaymentCardStepMeta.title}
				subtitle={addPaymentCardStepMeta.subtitle}
				controls={
					<div className={styles.controls}>
						{prevStep && (
							<StepNavigationButton
								type="prev"
								onClick={prev}
								stepName={prevStep.meta.btnText}
								disabled={disabled}
							/>
						)}
						{nextStep && (
							<StepNavigationButton
								type="final"
								stepName={nextStep.meta.btnText}
								formId={step.name}
								disabled={disabled}
							/>
						)}
					</div>
				}
			>
				<Grid columns={1} className={styles.gridWrapper}>
					<Grid.Row>
						<Grid.Column>
							<BillingCardForm
								onSubmit={() => next(step.name, {})}
								formId={step.name}
								onDisable={this.setDisabled}
								setStripeError={this.setStripeError}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className={styles.contentRow}>
						<Grid.Column>
							<div className={styles.cardFooterInfo}>
								<SvgIcon iconSrc={stripeImg} className={styles.icon} />
								<p>
									appfleet does not store your credit card information.
									<br />
									Payments are processed by Stripe
								</p>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<ErrorMessage
					className={styles.gridWrapper__errorMessage}
					error={stripeError}
					withinPortal={false}
					useDefaultMsg={false}
				/>
			</CenteredContentLayout>
		);
	}
}

export const AddPaymentCardStep = connect(null, null)(AddPaymentCardStepDumb);
